import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DateTimeAdapter } from 'ng-pick-datetime';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, SuscriptionService, PaymentService, ServicioService, NotificacionService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
declare var Stripe: any;

/**
 * Componente que gestiona la creación de una licitación.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-licitacion-nueva',
  templateUrl: './licitacion-nueva.component.html',
  styleUrls: ['./licitacion-nueva.component.scss']
})
export class LicitacionNuevaComponent implements OnInit {

  /** Variables globales */
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent
  @ViewChild('errorTipoLicitacionSwal', {static: false}) private errorTipoLicitacionSwal: SwalComponent
  @ViewChild('errorInternacionalSwal', {static: false}) private errorInternacionalSwal: SwalComponent

  currentUser: any;
  loading = false;

  servicios = [];
  estados = [];
  estadosAll = [];
  estadosHash = {};

  modo = "NACIONAL";

  paises = [];
  paisesHash = {};
  private unsubscribe: Subject<any>; 
  idGeocoder = "geocoder";
  geocoderObj;

  geocoder;
  geocoder2;
  geocoder3;

  /** Listado de otros servicios ES */
  serviciosOtros = [
    {codigo: "otros_esquela-prensa", nombre: "Esquela en prensa", nombre_en: "Death notice in the press", valor: false},
    {codigo: "otros_esquelas-murales", nombre: "Esquelas murales", nombre_en: "Death notices on outside walls", valor: false},
    {codigo: "otros_esquela-radio", nombre: "Esquelas en radio", nombre_en: "Death notices on radio", valor: false},
    {codigo: "otros_recordatorios", nombre: "Recordatorios", nombre_en: "Memorial reminders", valor: false},
    {codigo: "otros_libro-firmas", nombre: "Libro de firmas", nombre_en: "Guest book", valor: false},
    {codigo: "otros_elementos-emocionales", nombre: "Elementos emocionales (Colgantes o joyas)", nombre_en: "Emotional elements (Necklaces or jewelry)", valor: false},
    {codigo: "otros_acomp-musical", nombre: "Acompañamiento musical", nombre_en: "Musical accompaniment", valor: false},
    {codigo: "otros_lapidas", nombre: "Lápidas granito o mármol", nombre_en: "Granite or marble tombstones", valor: false},
  ]

  /** Listado de otros servicios MX */
  serviciosOtrosMx = [
    {codigo: "otros_acta-defuncion", nombre: "Acta de defunción", nombre_en: "Death certificate", valor: false},
    {codigo: "otros_apertura-fosa", nombre: "Apertura y cierre de fosa", nombre_en: "Opening and closing of grave", valor: false},
    {codigo: "otros_cambio-ataud", nombre: "Cambio de ataúd por complexión", nombre_en: "Change of coffin due to complexion", valor: false},
    {codigo: "otros_camion-foraneo", nombre: "Camión de acompañamiento foráneo", nombre_en: "Foreign accompaniment truck", valor: false},
    {codigo: "otros_camion-local", nombre: "Camión de acompañamiento local", nombre_en: "Local accompaniment truck", valor: false},
    {codigo: "otros_ceremonia", nombre: "Ceremonia religiosa", nombre_en: "Religious ceremony", valor: false},
    {codigo: "otros_certificado", nombre: "Certificado de defunción", nombre_en: "Death certificate", valor: false},
    {codigo: "otros_cirios", nombre: "Cirios", nombre_en: "Large candles", valor: false},
    {codigo: "otros_cremacion", nombre: "Cremación de restos áridos", nombre_en: "Cremation of dry remains", valor: false},
    {codigo: "otros_embalsamado-tesis", nombre: "Embalsamado con tesis", nombre_en: "Embalming with thesis", valor: false},
    {codigo: "otros_embalsamado-recons", nombre: "Embalsamado reconstructivo básico", nombre_en: "Basic reconstructive embalming", valor: false},
    {codigo: "otros_exhumacion", nombre: "Exhumaciones", nombre_en: "Exhumations", valor: false},
    {codigo: "otros_expatriacion", nombre: "Expatriación", nombre_en: "Expatriation", valor: false},
    {codigo: "otros_flores", nombre: "Flores", nombre_en: "Flowers", valor: false},
    {codigo: "otros_fosa-panteon", nombre: "Fosa en panteón privado", nombre_en: "Grave in private pantheon", valor: false},
    {codigo: "otros_gastos-repatriacion", nombre: "Gastos de repatriación", nombre_en: "Repatriation costs", valor: false},
    {codigo: "otros_mejora-ataud", nombre: "Mejora de ataúd", nombre_en: "Coffin improvement", valor: false},
    {codigo: "otros_mejora-sala", nombre: "Mejora de sala de velación", nombre_en: "Improvement of wake room", valor: false},
    {codigo: "otros_mejora-urna", nombre: "Mejora de urna", nombre_en: "Urn improvement", valor: false},
    {codigo: "otros_renta-capilla", nombre: "Renta de capilla", nombre_en: "Renting a chapel", valor: false},
    {codigo: "otros_renta-equipo", nombre: "Renta de equipo de velación", nombre_en: "Renting the wake equipment", valor: false},
    {codigo: "otros_repatriacion", nombre: "Repatriación", nombre_en: "Repatriation", valor: false},
    {codigo: "otros_cafeteria", nombre: "Servicio de cafetería", nombre_en: "Coffee services", valor: false},
    {codigo: "otros_traslado-foraneo", nombre: "Traslado foráneo", nombre_en: "Foreign transfer", valor: false},
    {codigo: "otros_traslado-multiple", nombre: "Traslado múltiple", nombre_en: "Multiple transfer", valor: false},
    {codigo: "otros_velacion", nombre: "Velación extra", nombre_en: "Extra wake", valor: false},
  ]
  
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private servicioService: ServicioService,
    private notificacionService: NotificacionService,
    public translate: TranslateService,
    private countryService: CountryService,
    private ngZone: NgZone,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');
    }


  /**
   * Método que se lanza en el inicio del componente e inicia la carga de maestros.
   */
  async ngOnInit() {
    if(this.router.url === '/crear-traslado-int') {
      this.modo = "INTERNACIONAL";
      this.traslado = true;
      this.cdr.detectChanges();
    }
    this.currentUser = this.authenticationService.currentUserValue;
    this.cdr.detectChanges();
    if(this.currentUser.pais.id == 142) this.serviciosOtros = this.serviciosOtrosMx;
    
    if(!this.currentUser.pais.id) {
      let pais = await this.countryService.getByIdAsync(this.currentUser.pais);
      this.currentUser.pais = pais;
    }

    this.paisOrigen = this.currentUser.pais.id;

    if(this.currentUser.provincia) this.provinciaOrigen = this.currentUser.provincia.id;

    this.loadPaises();
    this.loadProvincias();
    this.loadServicios();

    var calcularDistancia = function() {
      if(this.longitudOrigen && this.latitudOrigen && this.longitudDestino && this.latitudDestino) {
        this.userService.getRoute(this.longitudOrigen, this.latitudOrigen, this.longitudDestino, this.latitudDestino)
        .subscribe(route => {
          if(route.routes && route.routes.length > 0){
            this.km = Math.round(route.routes[0].distance / 1000);
            this.cdr.detectChanges();
          }
        });
      }
    }.bind(this);


    this.geocoder = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: this.currentUser.pais.iso2 || "ES",
      types: 'place, postcode'
    });

    this.geocoder2 = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: this.currentUser.pais.iso2 || "ES",
      types: 'place, postcode'
    });

    this.geocoder3 = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: this.currentUser.pais.iso2 || "ES",
      types: 'place, postcode'
    });

    this.geocoder.on('result', (e) => {
      console.warn(e);
      if(e.result) {
        let res = e.result;
        this.cdr.detectChanges();

        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }

        this.longitudOrigen = res.geometry.coordinates[0];
        this.latitudOrigen = res.geometry.coordinates[1];
        calcularDistancia();

        if(e.result.place_type[0] == "place" || e.result.place_type[0] == "region") {
          this.poblacionOrigen = res.text;
          this.userService.getPostalCode(res.geometry.coordinates[0], res.geometry.coordinates[1])
          .subscribe(res => {
            if(res.features && res.features.length > 0 ) {
              this.cpOrigen = res.features[0].text;
            }
          });
        } else if(e.result.place_type[0] == "postcode") {
          this.cpOrigen = res.text_es;

          for(let item of res.context) {
            if(item.id.includes("place")) this.poblacionOrigen = item.text;
          }
        }
      }
    });
    
    this.geocoder.on('clear', () => {
      this.poblacionOrigen = null;
      this.cpOrigen = null;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });

    this.geocoder2.on('result', (e) => {
      console.warn(e);
      if(e.result) {
        let res = e.result;
        this.cdr.detectChanges();

        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }

        this.longitudDestino = res.geometry.coordinates[0];
        this.latitudDestino = res.geometry.coordinates[1];
        calcularDistancia();

        if(e.result.place_type[0] == "place" || e.result.place_type[0] == "region") {
          this.poblacionDestino = res.text;
          this.userService.getPostalCode(res.geometry.coordinates[0], res.geometry.coordinates[1])
          .subscribe(res => {
            if(res.features && res.features.length > 0 ) {
              this.cpDestino = res.features[0].text;
            }
          });
        } else if(e.result.place_type[0] == "postcode") {
          this.cpDestino = res.text_es;

          for(let item of res.context) {
            if(item.id.includes("place")) this.poblacionDestino = item.text;
          }
        }
      }
    });

    this.geocoder2.on('clear', () => {
      this.poblacionDestino = null;
      this.cpDestino = null;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });

    this.geocoder3.on('result', (e) => {
      console.warn(e);
      if(e.result) {
        let res = e.result;
        this.cdr.detectChanges();

        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }

        this.longitudOrigen = res.geometry.coordinates[0];
        this.latitudOrigen = res.geometry.coordinates[1];

        if(e.result.place_type[0] == "place" || e.result.place_type[0] == "region") {
          this.poblacionOrigen = res.text;
          this.userService.getPostalCode(res.geometry.coordinates[0], res.geometry.coordinates[1])
          .subscribe(res => {
            if(res.features && res.features.length > 0 ) {
              this.cpOrigen = res.features[0].text;
            }
          });
        } else if(e.result.place_type[0] == "postcode") {
          this.cpOrigen = res.text_es;

          for(let item of res.context) {
            if(item.id.includes("place")) this.poblacionOrigen = item.text;
          }
        }
      }
    });
    
    this.geocoder3.on('clear', () => {
      this.poblacionOrigen = null;
      this.cpOrigen = null;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });

    setTimeout(function(){
      //geocoder.addTo('#' + this.idGeocoder);
      this.geocoder.addTo('#geocoder3');
      this.geocoder2.addTo('#geocoder4');
      this.geocoder3.addTo('#geocoder7');
    }.bind(this), 1000);    
  }

  /**
   * Hace scroll hasta el elemento indicado
   * @param id Identificador del elemento.
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  /**
   * Carga el listado completo de comunidades autónomas / estados
   */
  loadProvincias(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        for(let estado of estados) {
          this.estadosHash[estado.id+""] = estado;
        }
        this.cdr.detectChanges();
        this.countryChangedOrigenNew();
        this.countryChangedDestinoNew();
      });
  }

  /**
   * Carga el listado completo de paises
   */
  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      for(let pais of paises) {
        this.paisesHash[pais.id+""] = pais;
      }
      this.cdr.detectChanges();
		});
	}

  /**
   * Carga los servicios del grupo correspondiente
   */
  loadServicios() {
    var grupo = "ES";
    if(this.currentUser.pais.id == 142) grupo = "MX";
		this.servicioService.getAllGrupo(grupo)
	    .subscribe(servicios => {
        console.warn(servicios);
			this.servicios = servicios;
      for(let serv of this.servicios) {
        serv.error = {importeSalida: false, comentarios: false, importeNegativo: false, importeIncorrecto: false, tipoCompleto: false};
        serv.comentarios = null;
        serv.importeSalida = null;
        serv.val = false;
        serv.detalleMostrar = false;
        serv.detalleMostrarCompleto = false;
        serv.completo = false;
        serv.crema = false;
        serv.destemp = false;
        serv.detalleCompleto = null;
        if(serv.abreviatura == "CREMA") {
          this.servicios[0].cremaDetalle = serv;
        } else if(serv.abreviatura == "DESTEMP") {
          this.servicios[0].destempDetalle = serv;
        }
      }
      this.servicios.sort(function (a, b) {
        if (a.orden < b.orden) {
          return -1;
        } else if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      }.bind(this));
      this.cdr.detectChanges();
		});
	}

  /** Variables para la edición */
  referenciaCliente = null;
  tipo = null;
  pujas = null;
  creacion = "UNIDO";
  traslado = false;
  serviciosMostrar = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;
  detalleTraslado = false;

  fechaInicio = null;
  fechaFin = null;
  error = {referencia: false, referenciaDup: false, tipo: false, creacion: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, importeNegativo: false, importeIncorrecto: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  paisOrigen = null;
  paisDestino = null;

  provinciaOrigen = null;
  poblacionOrigen = null;
  cpOrigen = null;
  latitudOrigen = null;
  longitudOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;
  cpDestino = null;
  latitudDestino = null;
  longitudDestino = null;

  estadosOrigenNew = []
  estadosDestinoNew = [];

  cargaInicial = true;

  /**
   * Evento de cambio de país origen.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedOrigenNew() {
    this.estadosOrigenNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigenNew.push(this.estados[i]);
    }

    for(let pais of this.paises) {
      if(pais.id == this.paisOrigen) {
        this.geocoder3.setCountries(pais.iso2);
        this.geocoder.setCountries(pais.iso2);
        break;
      }
    }
    this.cdr.detectChanges();
    this.paisDestino = this.paisOrigen;
    this.countryChangedDestinoNew();

    if(this.cargaInicial) {
      this.cargaInicial = false;
      this.provinciaDestino = this.provinciaOrigen;
    } else {
      this.geocoder3.clear();
      this.geocoder.clear();
      this.provinciaOrigen = null;
      this.provinciaDestino = null;
      this.poblacionOrigen = null;
      this.poblacionDestino = null;
      this.cpOrigen = null;
      this.cpDestino = null;
    }
  }

  /**
   * Evento de cambio de provincia origen
   * Cambia la provincia destino a la misma que origen
   */
  provinciaChangedOrigen(){
    this.provinciaDestino = this.provinciaOrigen;
  }

  /**
   * Evento de cambio de país destino.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedDestinoNew() {
    this.estadosDestinoNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisDestino) this.estadosDestinoNew.push(this.estados[i]);
    }

    for(let pais of this.paises) {
      if(pais.id == this.paisDestino) {
        this.geocoder2.setCountries(pais.iso2);
        break;
      }
    }

    if(!this.cargaInicial) {
      this.geocoder2.clear();
    }

    this.cdr.detectChanges();
  }

  serviciosCompletados = 0;

  /**
   * Comprueba que todo es correcto, guarda y notifica la licitación.
   */
  submitLicit() {
    if(this.loading) return;
    //COMPROBAR ERRORES
    var errors = false;

    if(!this.referenciaCliente || this.referenciaCliente == ""){
      this.error.referencia = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.referencia = false;
    }
    if(!this.tipo){
      this.error.tipo = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.tipo = false;
    }
    if(!this.fechaInicio && this.tipo != "AD"){
      this.error.fechaInicio = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaInicio = false;
    }
    if(!this.fechaFin && this.tipo != "AD"){
      this.error.fechaFin = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaFin = false;
    }
    if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
      this.error.origen = true;
      if(!errors) {
        this.scroll("traslado");
      }
      errors = true;
    } else {
      this.error.origen = false;
    }
    if(this.traslado) {
      if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
        this.error.destino = true;
        if(!errors) {
          this.scroll("traslado");
        }
        errors = true;
      } else {
        this.error.destino = false;
      }
    }
    if(!this.serviciosMostrar && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    var servicioSel = false;
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        servicioSel = true;
        break;
      }
    }
    if(!servicioSel && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        this.scroll("servicios");
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    if(this.tipo != "AD" && moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }
    if(this.tipo != "AD" && moment(this.fechaFin).diff(this.fechaInicio) < 0){
      this.error.fechaOrden = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaOrden = false;
    }
    if(this.serviciosMostrar && this.traslado && !this.creacion) {
      this.error.creacion = true;
      errors = true;
    } else {
      this.error.creacion = false;
    }

    //COMPROBAR SERVICIOS
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(this.tipo != "AD" && !this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
      if(this.tipo != "AD" && (this.importeTraslado || this.importeTraslado == 0) && this.importeTraslado <= 0) {
        this.error.importeNegativo = true;
        errors = true;
      } else {
        this.error.importeNegativo = false;
      }
      if(this.tipo != "AD" && this.importeTraslado && this.importeTraslado > 99999) {
        this.error.importeIncorrecto = true;
        errors = true;
      } else {
        this.error.importeIncorrecto = false;
      }
      if(this.modo == "INTERNACIONAL") {
        if(!this.tipo) {
          KTUtil.scrollTop();
          this.errorTipoLicitacionSwal.fire();
          errors = true;
        }

        if(this.paisOrigen == this.paisDestino && !errors) {
          this.errorInternacionalSwal.fire();
          errors = true;
        }
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        /*if(this.servicios[i].abreviatura == "OTROS") {
          if(this.servicios[i].comentarios && this.servicios[i].comentarios != "") {
            this.servicios[i].error.comentarios = false;
          } else {
            this.servicios[i].error.comentarios = true;
            errors = true;
          }
        } else */if(this.servicios[i].abreviatura == "SVFUN") {
          if(this.servicios[i].tipo) {
            this.servicios[i].error.tipoCompleto = false;
          } else {
            this.servicios[i].error.tipoCompleto = true;
            errors = true;
          }
        }
        if(this.tipo != "AD" && !this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
        if(this.tipo != "AD" && (this.servicios[i].importeSalida || this.servicios[i].importeSalida == 0) && this.servicios[i].importeSalida <= 0) {
          this.servicios[i].error.importeNegativo = true;
          errors = true;
        } else {
          this.servicios[i].error.importeNegativo = false;
        }
        if(this.tipo != "AD" && this.servicios[i].importeSalida && this.servicios[i].importeSalida > 99999) {
          this.servicios[i].error.importeIncorrecto = true;
          errors = true;
        } else {
          this.servicios[i].error.importeIncorrecto = false;
        }
      }
    }
    if(errors) {
      console.warn(this.poblacionDestino, this.provinciaDestino, this.paisDestino);
      console.warn(this.error);
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    

    var continuarGuardado = function () {
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          delete this.servicios[i].destemp;
          delete this.servicios[i].crema;
          delete this.servicios[i].cremaDetalle;
          delete this.servicios[i].destempDetalle;
          if(this.servicios[i].abreviatura == "OTROS") {
            this.servicios[i].detalleOtros = this.serviciosOtros;
          }
          servicios.push(this.servicios[i]);
        }
      }
      if(servicios.length == 0) {
        this.loading = false;
        this.confirmationAddSwal.fire();
        //this.router.navigate(['/mislicitaciones']);
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();

        return;
      }
      var poblacion = "", poblacionDestino = "";
      var provincia = null, provinciaDestino = null;
      var pais = null, paisDestino = null;
      var comentarios = "", tipo = "", km = 0, tipoTraslado = "";
      if(this.traslado && this.serviciosMostrar && this.creacion == "SEPARADO") {
        poblacion = this.poblacionDestino;
        provincia = this.provinciaDestino;
        pais = this.paisDestino;
        tipo = "SERVICIO";
      } else if(this.traslado && this.serviciosMostrar && this.creacion == "UNIDO") {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
        poblacionDestino = this.poblacionDestino;
        provinciaDestino = this.provinciaDestino;
        paisDestino = this.paisDestino;
        total += this.importeTraslado;
        comentarios = this.comentariosTraslado || "";
        tipo = "AMBOS";
        km = this.km;
        tipoTraslado = this.tipoTraslado;
      } else {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
        tipo = "SERVICIO";
      }
      var demo = null;
      if(this.currentUser.demo) demo = this.currentUser.id;
      var licit = {
        referencia: "WFN" + moment().format("YY") + (Math.random()*100).toFixed() + this.currentUser.id + (moment().unix()+"").substring(2),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio || new Date(),
        endDate: this.fechaFin || moment().add(4, 'seconds'),
        abierta: true,
        pujas: this.pujas,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: comentarios,
        creador: this.currentUser.id,
        poblacionDestino: poblacionDestino,
        provinciaDestino: provinciaDestino,
        paisDestino: paisDestino,
        km: km,
        tipoTraslado: tipoTraslado,
        servicios: servicios,
        tipo: tipo,
        divisa: this.currentUser.divisa,
        demo: this.currentUser.demo,
        usuarioDemo: demo,
        ad: this.tipo == "AD" ? true : false
      }


      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY HH:mm");
        resData.suscripcion = true;

        for(var i = 0; i < this.servicios.length; i++){
          this.servicios[i].val = false;
          this.servicios[i].comentarios = null;
          this.servicios[i].importeSalida = null;
          this.servicios[i].error = {importeSalida: false};
        }

        var serviciosStr = "";
        for(var i = 0; i < resData.servicios.length; i++) {
          var servicio = resData.servicios[i];
          if(this.translate.currentLang == "es") serviciosStr += servicio.nombre
          else if(this.translate.currentLang == "en") serviciosStr += servicio.nombre_en
          else if(this.translate.currentLang == "de") serviciosStr += servicio.nombre_de
          else if(this.translate.currentLang == "fr") serviciosStr += servicio.nombre_fr;
          if(i < resData.servicios.length-1) serviciosStr += ", ";
        }
        
        this.loading = false;
        this.confirmationAddSwal.fire();

        var divisa = "€";
        if(this.currentUser.divisa != "EUR") divisa = this.currentUser.divisa;

        var destino = null;
        if(resData.tipo == "AMBOS") {
          destino = resData.poblacionDestino + " (" + resData.provinciaDestino.name + " - " + resData.paisDestino.name + ")";
          serviciosStr = this.translate.instant('LICIT.CARD.TRASLADO') + ", " + serviciosStr;
        }

        //Envio mail/telegram confirmación
        var mail = {
          usuario: this.currentUser.id, 
          referencia: resData.referencia, 
          referenciaCliente: resData.referenciaCliente,
          importe: resData.importeSalida, 
          divisa: divisa,
          servicios: serviciosStr,
          origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
          destino: destino,
          fechaFin: moment(resData.endDate).format("DD/MM/YYYY HH:mm")
        }

        this.notificacionService.nueva_licitacion(mail)
        .pipe(first())
        .subscribe(
          data => {
            this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
          },
          error => {
            console.error(error);
        });
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    }

    //GUARDAR
    var checkRef = function(res){
      if(res.length > 0) {
        this.error.referenciaDup = true;
        KTUtil.scrollTop();
        this.loading = false;
        this.cdr.detectChanges();
        return;
      }

      if(this.tipo == "CON") {
        this.pujas = true;
      } else {
        this.pujas = false;
      }

      if(this.traslado && (!this.serviciosMostrar || (this.serviciosMostrar && this.creacion == "SEPARADO"))) {
        var demo = null;
        if(this.currentUser.demo) demo = this.currentUser.id;
        var licit = {
          referencia: "WFN" + moment().format("YY") + (Math.random()*100).toFixed() + this.currentUser.id + (moment().unix()+"").substring(2),
          referenciaCliente: this.referenciaCliente || "",
          startDate: this.fechaInicio,
          endDate: this.fechaFin,
          abierta: true,
          pujas: this.pujas,
          importeSalida: this.importeTraslado || 0,
          poblacionOrigen: this.poblacionOrigen,
          provinciaOrigen: this.provinciaOrigen,
          paisOrigen: this.paisOrigen,
          comentarios: this.comentariosTraslado || "",
          creador: this.currentUser.id,
          poblacionDestino: this.poblacionDestino,
          provinciaDestino: this.provinciaDestino,
          paisDestino: this.paisDestino,
          km: this.km,
          tipoTraslado: this.tipoTraslado,
          tipo: "TRASLADO",
          divisa: this.currentUser.divisa,
          demo: this.currentUser.demo,
          usuarioDemo: demo
        }
        var completado = function (resData){
          //Añadir al listado activo
          resData.precio = resData.importeSalida;
          resData.estado = "ABIERTA";
          resData.fecha = moment(resData.startDate).format("DD/MM/YYYY HH:mm");
          resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
          resData.suscripcion = true;
          var fn = continuarGuardado.bind(this);
          fn();

          var divisa = "€";
          if(this.currentUser.divisa != "EUR") divisa = this.currentUser.divisa;
  
          //Envio mail/telegram confirmación
          var mail = {
            usuario: this.currentUser.id, 
            referencia: resData.referencia, 
            referenciaCliente: resData.referenciaCliente,
            importe: resData.importeSalida, 
            divisa: divisa,
            servicios: this.translate.instant('LICIT.CARD.TRASLADO'),
            origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
            destino: resData.poblacionDestino + " (" + resData.provinciaDestino.name + " - " + resData.paisDestino.name + ")",
            fechaFin: resData.fechaFin
          }
  
          this.notificacionService.nueva_licitacion(mail)
          .pipe(first())
          .subscribe(
            data => {
            },
            error => {
              console.error(error);
          });
        }
        self.io.socket.post('/licitacion', licit, completado.bind(this));
  
        
      } else {
        var fn = continuarGuardado.bind(this);
        fn();
      }  
    }
    self.io.socket.get('/licitacion?referenciaCliente=' + this.referenciaCliente, checkRef.bind(this));
      
  }

  /**
   * Evento de selección de un servicio. Prepara sus formularios dependiendo del tipo.
   * @param serv Objeto del servicio pulsado
   */
  servicioPulsado(serv) {
    if(serv.disabled) return;
    if(serv.abreviatura == "SVFUN" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "FLOR" || serv.abreviatura == "TANAT" || serv.abreviatura == "COCHE" || serv.abreviatura == "GEST" || serv.abreviatura == "CREMA" || serv.abreviatura == "DESTEMP") {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = true;
          serv.disabled = false;
        }
      }
    } else if(serv.abreviatura == "SVFUN" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.completo) {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = false;
          serv.disabled = false;
        }
      }
    } else if(serv.abreviatura == "CREMA" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "DESTEMP") {
          serv.disabled = false;
          break;
        }
      }
    } else if(serv.abreviatura == "CREMA" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "DESTEMP") {
          serv.disabled = true;
          break;
        }
      }
    } else if(serv.abreviatura == "DESTEMP" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "CREMA") {
          serv.disabled = false;
          break;
        }
      }
    } else if(serv.abreviatura == "DESTEMP" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "CREMA") {
          serv.disabled = true;
          break;
        }
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Evento de selección de un servicio completo. 
   * @param serv Objeto del servicio
   * @param opcion Opción seleccionada
   */
  completoPulsado(serv, opcion) {
    serv.crema = false;
    serv.destemp = false;
    this.cdr.detectChanges();
    if(opcion == "CREMA") {
      serv.crema = true;
    } else {
      serv.destemp = true;
    }
    serv.tipo = opcion;
    this.cdr.detectChanges();
  }

  checkTipo() {
    if(!this.tipo) {
      this.traslado = false;
      this.serviciosMostrar = false;
      this.cdr.detectChanges();
      this.errorTipoLicitacionSwal.fire();
    }
  }
  

}
