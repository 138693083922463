import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { AuthenticationService, UserService, TicketService, NotificacionService } from '../../../_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kt-admin-soporte',
  templateUrl: './admin-soporte.component.html',
  styleUrls: ['./admin-soporte.component.scss']
})
export class AdminSoporteComponent implements OnInit {

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('finalizarSwal', {static: false}) private finalizarSwal: SwalComponent
  @ViewChild('confirmationFinalizarSwal', {static: false}) private confirmationFinalizarSwal: SwalComponent
  @ViewChild("popupDetalle", { static: false }) popupDetalle: DxPopupComponent

  popupVisible = false;
  popupDetalleVisible = false;
  currentUser: any;

  ticketsCreados = [];
  ticketsRecibidos = [];
  pendientesCreados = 0;
  pendientesRecibidos = 0;
  usuarios = [];
  usuariosHash = {};
  detalle = "creados";

  usuarioTicket = null;

  tipos = [
    {codigo: "VENTA", abreviatura: "VTA", nombre: this.translate.instant('SOPORTE.TIPO.VENTA')}, 
    {codigo: "POSTVENTA", abreviatura: "PVT", nombre: this.translate.instant('SOPORTE.TIPO.POSTVENTA')}, 
    {codigo: "ADMINISTRACION", abreviatura: "ADM", nombre: this.translate.instant('SOPORTE.TIPO.ADMINISTRACION')}, 
    {codigo: "SAT", abreviatura: "SAT", nombre: this.translate.instant('SOPORTE.TIPO.SAT')}, 
    {codigo: "OTRO", abreviatura: "OTR", nombre: this.translate.instant('SOPORTE.TIPO.OTRO')}
  ]
  
  dropdownSettings = null;

  constructor(private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private notificacionService: NotificacionService,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    public translate: TranslateService) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.tiposHash = {};
    for(let tipo of this.tipos) {
      this.tiposHash[tipo.codigo + ""] = tipo;
    }

    this.route.paramMap.subscribe(params => {
      if(params.get("usuario")) this.usuarioTicket = params.get("usuario");

      this.loadUsuarios();
      this.loadTickets();

    });
  }

  selectMenu(menu: any) {
    this.detalle = menu;
    this.cdr.detectChanges();
  }

  loadTickets() {
    this.ticketsCreados = [];
    this.ticketsRecibidos = [];
    this.pendientesCreados = 0;
    this.pendientesRecibidos = 0;
    this.ticketService.getByCreador(this.currentUser.id)
    .subscribe(tickets => {
      for(let ticket of tickets) {
        ticket.fechaStr = moment(ticket.fecha).format("DD/MM/YYYY HH:mm");
        ticket.fechaUltimoMensajeStr = moment(ticket.fechaUltimoMensaje).format("DD/MM/YYYY HH:mm");
        if(ticket.fechaFin) ticket.fechaFinStr = moment(ticket.fechaFin).format("DD/MM/YYYY HH:mm");

        if(!ticket.leido && !ticket.ultimoMensajeCreador) this.pendientesCreados++;

        for(let mensaje of ticket.mensajes) {
          mensaje.fechaStr = moment(mensaje.fecha).format("DD/MM/YYYY HH:mm");
        }
      }
      //if(item.status == "INVITADO") mostrar = false;
      this.ticketsCreados = tickets;

      console.warn(tickets);
      
      this.cdr.detectChanges();
    });

    this.ticketService.getByDestinatario(this.currentUser.id)
    .subscribe(tickets => {
      for(let ticket of tickets) {
        ticket.fechaStr = moment(ticket.fecha).format("DD/MM/YYYY HH:mm");
        ticket.fechaUltimoMensajeStr = moment(ticket.fechaUltimoMensaje).format("DD/MM/YYYY HH:mm");
        if(ticket.fechaFin) ticket.fechaFinStr = moment(ticket.fechaFin).format("DD/MM/YYYY HH:mm");

        if(!ticket.leido && ticket.ultimoMensajeCreador) this.pendientesRecibidos++;

        for(let mensaje of ticket.mensajes) {
          mensaje.fechaStr = moment(mensaje.fecha).format("DD/MM/YYYY HH:mm");
        }
      }
      this.ticketsRecibidos = tickets;
      
      this.cdr.detectChanges();
    });
  }

  loadUsuarios() {
    this.userService.getAll()
	    .subscribe(users => {
        //if(item.status == "INVITADO") mostrar = false;
        for(let user of users) {
          if(user.status != "INVITADO") {
            this.usuarios.push(user);
            this.usuariosHash[user.id] = user;
          } 
        }
        console.warn(this.usuarios);

        if(this.usuarioTicket) {
          this.nuevoTicket();
        }
        
        this.cdr.detectChanges();
      });
  }



  // --- NUEVO TICKET ---

  nuevo = {} as any;
  errorNuevo = {} as any;
  loadingSubmit = false;
  tiposHash = {};

  nuevoTicket() {
    this.nuevo = {
      codigo: null,
      creador: this.currentUser.id, // Default
      destinatario: '',
      fecha: null,
      tipo: '',
      asunto: null,
      mensaje: null,
      estado: "NUEVO", // Default
      fechaUltimoMensaje: null,
      ultimoMensajeCreador: true, // Default
      leido: false, // Default
      creadorAdmin: true, // Default
      abierto: true // Default
    }

    if(this.usuarioTicket) this.nuevo.destinatario = this.usuarioTicket;
    this.cdr.detectChanges();

    this.errorNuevo = {
      destinatario: null,
      tipo: null,
      asunto: null,
      mensaje: null
    }

    this.popupVisible = true;
  }

  enviarTicket() {
    var error = false;
    if(!this.nuevo.tipo) {
      this.errorNuevo.tipo = true;
      error = true;
    } else this.errorNuevo.tipo = false;

    if(!this.nuevo.destinatario) {
      this.errorNuevo.destinatario = true;
      error = true;
    } else this.errorNuevo.destinatario = false;

    if(!this.nuevo.asunto) {
      this.errorNuevo.asunto = true;
      error = true;
    } else this.errorNuevo.asunto = false;

    if(!this.nuevo.mensaje) {
      this.errorNuevo.mensaje = true;
      error = true;
    } else this.errorNuevo.mensaje = false;

    if(error) return;

    console.warn(this.nuevo);
    this.loadingSubmit = true;

    let tipoStr = this.nuevo.tipo

    let tipo = this.tiposHash[tipoStr];

    this.nuevo.tipo = tipo.codigo;
    

    this.nuevo.codigo = tipo.abreviatura + moment().format("YY") + "0" + (moment().unix()+"").substring(2);
    this.nuevo.fecha = new Date();
    this.nuevo.fechaUltimoMensaje = new Date();

    this.cdr.detectChanges();

    console.warn(this.nuevo);

    this.ticketService.post(this.nuevo)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          
          var mensaje = {user: this.currentUser.id, ticket: data.id, mensaje: this.nuevo.mensaje, fecha: new Date(), leido: false, fechaLectura: null}

          this.ticketService.postMensaje(mensaje)
          .pipe(first())
          .subscribe(
            data => {
              this.nuevo.fechaStr = moment(this.nuevo.fecha).format("DD/MM/YYYY HH:mm");

              var info = {
                ticket: this.nuevo,
              }
      
              this.notificacionService.nuevo_ticket(info)
              .pipe(first())
              .subscribe(
                data => {
                  this.loadingSubmit = false;
                  this.cdr.detectChanges();
                  this.popupVisible = false;
                  this.detalle = "creados";
                  this.cdr.detectChanges();
                  this.confirmationSwal.fire();
                  this.loadTickets();
                },
                error => {
                  console.error(error);
              });
            },
            error => {
              console.error(error);
              this.loadingSubmit = false;
              this.cdr.detectChanges();
            });


          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingSubmit = false;
          this.cdr.detectChanges();
        });




  }

  cancelarTicket() {
    this.popupVisible = false;
    this.usuarioTicket = null;
  }

  // --- NUEVO MENSAJE ---

  detalleTicket = null;
  nuevoMensaje = "";
  loadingEnviar = false;
  loadingFinalizar = false;
  
  verTicket(row: any) {
    this.detalleTicket = row;

    this.nuevoMensaje = "";

    this.popupDetalle.instance.repaint();

    this.popupDetalleVisible = true;


    if(!this.detalleTicket.leido && ((this.detalleTicket.creadorAdmin && !this.detalleTicket.ultimoMensajeCreador) || (!this.detalleTicket.creadorAdmin && this.detalleTicket.ultimoMensajeCreador))) {
      // Marcar todo como leido
      var editTicket = {id: this.detalleTicket.id, leido: true};
      this.ticketService.update(editTicket)
        .pipe(first())
        .subscribe(
          data => {
            for(let mensaje of this.detalleTicket.mensajes) {
              if(!mensaje.leido) {
                let editMensaje = {id: mensaje.id, leido: true, fechaLectura: new Date()};
                this.ticketService.updateMensaje(editMensaje)
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.error(error);
                  });
              }
            }
          },
          error => {
            console.error(error);
          });
    }
  }

  enviarMensaje() {
    if(this.nuevoMensaje != '') {
      this.loadingEnviar = true;
      this.cdr.detectChanges();

      var mensaje = {
        user: this.currentUser.id,
        ticket: this.detalleTicket.id,
        mensaje: this.nuevoMensaje,
        fecha: new Date(),
        leido: false,
        fechaLectura: null,
        fechaStr: ""
      }

      this.ticketService.postMensaje(mensaje)
      .pipe(first())
      .subscribe(
        data => {
          mensaje.fechaStr = moment(mensaje.fecha).format("DD/MM/YYYY HH:mm");
          this.detalleTicket.mensajes.push(mensaje);

          var ultimoMensajeCreador = false;
          if(this.currentUser.id == this.detalleTicket.creador.id) ultimoMensajeCreador = true;

          var editTicket = {id: this.detalleTicket.id, fechaUltimoMensaje: data.fecha, ultimoMensajeCreador: ultimoMensajeCreador, leido: false, estado: "EN CURSO"};
          this.ticketService.update(editTicket)
            .pipe(first())
            .subscribe(
              data => {
                var info = {
                  ticket: this.detalleTicket,
                  mensaje: mensaje
                }

                this.notificacionService.nuevo_mensaje(info)
                .pipe(first())
                .subscribe(
                  data => {
                    this.nuevoMensaje = '';
                    this.loadingEnviar = false;
                    this.cdr.detectChanges();
                  },
                  error => {
                    console.error(error);
                });                
              },
              error => {
                console.error(error);
              });
          

        },
        error => {
          console.error(error);
          this.loadingEnviar = false;
          this.cdr.detectChanges();
        });
    }

    
  }

  cerrarMensaje() {
    this.popupDetalleVisible = false;
    this.loadTickets();
  }

  finalizarTicket() {
    this.finalizarSwal.fire();
  }

  finalizar() {
    var editTicket = {id: this.detalleTicket.id, estado: "FINALIZADO", abierto: false, fechaFin: new Date()};
      this.ticketService.update(editTicket)
        .pipe(first())
        .subscribe(
          data => {
            this.popupDetalleVisible = false;
            this.loadTickets();
            this.confirmationFinalizarSwal.fire();
          },
          error => {
            console.error(error);
          });
  }
  

}
