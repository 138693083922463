import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de funerarias.
 */
@Injectable()
export class B2bService {
    constructor(private http: HttpClient) { }

    generarCodigo(cliente: any) {
      return this.http.get<any>(`${environment.apiUrl}/generarCodigoB2B?cliente=${cliente}`).toPromise();
    }

    getAll() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?sort=fechaEntrada DESC`).toPromise();
    }

    getAllOrigen(origen: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?origen=${origen}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllOrigenPage(origen: any, skip: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?origen=${origen}&limit=100&skip=${skip}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllOrigenPage500(origen: any, skip: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?origen=${origen}&populate=false&limit=500&skip=${skip}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllOrigenPage1000(origen: any, skip: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?origen=${origen}&populate=false&limit=1000&skip=${skip}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllCliente(cliente: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?cliente=${cliente}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllUserCliente(cliente: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?userCliente=${cliente}&populate=false&sort=fechaEntrada DESC`).toPromise();
    }

    getAllIrisBoreaCompletados() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?proveedor=BOREA&estado=COMPLETADA&populate=false&sort=fechaEntrada DESC`).toPromise();
    }

    getAllProveedor(cliente: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?userAsignado=${cliente}&populate=false&sort=fechaEntrada DESC`).toPromise();
    }
    
    getB2BActivas() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?where={"origen":["WFN","FP"],"estado":["PENDIENTE","ACEPTADA","ASIGNADA","PRESUPUESTAR","LLAMADA"]}&sort=fechaEntrada DESC`);
    }
    
    getBoreaActivas() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?where={"origen":"BOREA","cliente":"BOREA","estado":["PENDIENTE","ACEPTADA","ASIGNADA","PRESUPUESTAR","LLAMADA"]}&sort=fechaEntrada DESC`);
    }
    
    getProvBoreaActivas() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?where={"proveedor":"BOREA","estado":["PENDIENTE","ACEPTADA","ASIGNADA","PRESUPUESTAR","LLAMADA"]}&sort=fechaEntrada DESC`).toPromise();
    }
    
    getPetsActivas() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?where={"origen":"PETS","cliente":"BOREA","estado":["PENDIENTE","ACEPTADA","ASIGNADA","PRESUPUESTAR","LLAMADA"]}&sort=fechaEntrada DESC`);
    }

    getFusionados(servicio: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?fusion=${servicio}&sort=fechaEntrada DESC`).toPromise();
    }

    getAllPetsCementerio() {
      return this.http.get<any[]>(`${environment.apiUrl}/pets_cementerio?omit=imagen`).toPromise();
    }

    getPetCementerio(id: any) {
      return this.http.get<any>(`${environment.apiUrl}/pets_cementerio/${id}`).toPromise();
    }

    postPetCementerio(pet: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/pets_cementerio`, pet, httpOptions).toPromise();
    }

    updatePetCementerio(pet: any) {
      return this.http.patch<any>(`${environment.apiUrl}/pets_cementerio/${pet.id}`, pet).toPromise();
    }
    
    deletePetCementerio(id: number) {
        return this.http.delete(`${environment.apiUrl}/pets_cementerio/${id}`).toPromise();
    }

    /**
     * Realiza una petición `POST` para guardar un servicio
     * @param cert Contenido del servicio
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postSolicitud(solicitud: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/b2b_solicitud`, solicitud, httpOptions).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un certificado
     * @param cert Contenido del certificado a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updateSolicitud(solicitud: any) {
      return this.http.patch<any>(`${environment.apiUrl}/b2b_solicitud/${solicitud.id}`, solicitud).toPromise();
    }

    postComentario(comentario: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/b2b_solicitud_comentario`, comentario, httpOptions).toPromise();
    }

    updateComentario(comentario: any) {
      return this.http.patch<any>(`${environment.apiUrl}/b2b_solicitud_comentario/${comentario.id}`, comentario).toPromise();
    }

    getComentarios(solicitud: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud_comentario?solicitud=${solicitud}&sort=fecha ASC`).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un comentario
     * @param id Identificador del comentario a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteComentario(id: number) {
      return this.http.delete(`${environment.apiUrl}/b2b_solicitud_comentario/${id}`).toPromise();
    }

    /**
     * Realiza una petición `POST` para guardar un documento
     * @param cert Contenido del documento
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postDocumento(documento: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/b2b_solicitud_documento`, documento, httpOptions).toPromise();
    }

    updateDocumento(documento: any) {
      return this.http.patch<any>(`${environment.apiUrl}/b2b_solicitud_documento/${documento.id}`, documento).toPromise();
    }

    getDocumentos(solicitud: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud_documento?solicitud=${solicitud}&omit=contenido&sort=fecha DESC`).toPromise();
    }

    getDocumentoContenido(id: any) {
      return this.http.get<any>(`${environment.apiUrl}/b2b_solicitud_documento/${id}`).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un documento
     * @param id Identificador del documento a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteDocumento(id: number) {
        return this.http.delete(`${environment.apiUrl}/b2b_solicitud_documento/${id}`).toPromise();
    }

    getInformes(servicio) {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_informe?servicio=${servicio}&sort=id DESC`).toPromise();
    }

    /**
     * Realiza una petición `POST` para guardar un documento
     * @param cert Contenido del documento
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postSAP(sap: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/postSAP`, sap, httpOptions).toPromise();
    }
}