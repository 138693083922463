import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

import { UserService, AuthenticationService, PaymentService } from '../../../_services';

declare var Stripe: any;

/**
 * Componente que gestiona el proceso de confirmación y validación de cuenta
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.scss']
})
export class ConfirmacionComponent implements OnInit {

  /** Variables globales */
  hash = null;
  email = null;
  validada = false;
  loading = true;
  errorHash = false;
  confirmacion = false;
  currentUser = null;
  pago = false;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
		private http: HttpClient,
    private cdr: ChangeDetectorRef) { 
    }

  /**
   * Método que se lanza en el inicio del componente y carga las vistas y maestros necesarios.
   * Procesa el estado del usuario y dependiendo de su pais/estado lo lleva a la plataforma, vlaida su cuenta o envia mail de confirmación.
   */
  ngOnInit() {
    console.warn("INIT CONFIRMACION");
    this.route.queryParams.subscribe(params => {
      this.hash = params["hash"];
      this.email = params["email"];

      this.currentUser = this.authenticationService.currentUserValue;
      var token = this.currentUser.token;

      this.userService.getById(this.currentUser.id)
      .subscribe(user => {
        this.currentUser = user;
        this.currentUser.token = token;
        this.cdr.detectChanges();
        this.authenticationService.changeUser(this.currentUser);

        if(this.currentUser.status == "CONF-PENDIENTE" || this.currentUser.status == "CONF-RECHAZADO") {
          this.confirmacion = true;
          this.loading = false;
          this.pago = false;
          this.cdr.detectChanges();
          return;
        } else if(this.currentUser.status == "CONF-OK") {
          this.loadValidacion();
          return;
        }

        if(this.currentUser.pais.id == 142) { //PRE-REGISTRO
          this.userService.checkMexico(this.currentUser.cif)
          .subscribe(regs => {
            var confiable = true;
            if(regs.length == 0) confiable = false;
            for(let reg of regs) {
              if(reg.calidad == "NO CONFIABLE") confiable = false;
            }

            if(confiable) {
              this.loadValidacion();
            } else {  //MAIL Y MOSTRAR MENSAJE DE ESPERA
              var updateObj = {id: this.currentUser.id, status: "CONF-PENDIENTE"};
              this.userService.update(updateObj)
              .pipe(first())
              .subscribe(
                data => {
                  this.currentUser.status = "CONF-PENDIENTE";
                  this.authenticationService.changeUser(this.currentUser);
                  this.http.post(`${environment.apiUrl}/mailcheckregistro`, {user: this.currentUser})
                  .pipe(first())
                  .subscribe(
                    data => {
                      this.confirmacion = true;
                      this.loading = false;
                      this.pago = false;
                      this.cdr.detectChanges();
                      //console.log("OK MAIL", data);
                    },
                    error => {
                      console.error(error);
                      this.loading = false;
                    });
                });
            }
          })
        } else if(this.currentUser.pais.id != 207 && this.currentUser.actividad != "Funerarias"){ // aseguradoras no-españolas
          var updateObj = {id: this.currentUser.id, status: "CONF-PENDIENTE"};
          this.userService.update(updateObj)
          .pipe(first())
          .subscribe(
            data => {
              this.currentUser.status = "CONF-PENDIENTE";
              this.authenticationService.changeUser(this.currentUser);
              this.http.post(`${environment.apiUrl}/mailcheckregistro`, {user: this.currentUser})
              .pipe(first())
              .subscribe(
                data => {
                  this.confirmacion = true;
                  this.loading = false;
                  this.pago = false;
                  this.cdr.detectChanges();
                  //console.log("OK MAIL", data);
                },
                error => {
                  console.error(error);
                  this.loading = false;
                });
            });
        } else {
          this.loadValidacion();
        }

        

      });

    })
  }

  /**
   * Crea un nuevo cliente en la plataforma de pagos, actualiza su información 
   * y le lleva a la página de pagos (Excepto aseguradoras no-mexico).
   */
  loadValidacion = function() {
    this.paymentService.createCustomer(this.currentUser.email, this.currentUser.empresa, this.currentUser.telefono)
    .then(function(result) {
      this.currentUser.stripeCustomer = result.customerId;
      var updateObj = {id: this.currentUser.id, stripeCustomer: this.currentUser.stripeCustomer, status: "ACTIVO", hashUser: null, tarifaTemp: null};
      this.userService.update(updateObj)
      .pipe(first())
      .subscribe(
        data => {
          this.currentUser.status = data.status;
          this.currentUser.hashUser = data.hashUser;
          this.authenticationService.changeUser(this.currentUser);

          if(this.currentUser.actividad == "Funerarias") {
            this.loading = false;
            this.pago = true;
            this.cdr.detectChanges();
            setTimeout(()=>{
              this.router.navigate(['/pago']);
            }, 2000);
          } else {
            if(this.currentUser.pais.id == 142) {
              setTimeout(()=>{
                this.router.navigate(['/pago']);
              }, 2000);
            } else {
              this.loading = false;
              setTimeout(()=>{
                this.router.navigate(['/home']);
              }, 3000);
            }
          }
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    }.bind(this));

		
  }.bind(this);

}
