import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, CentroService, LocalizacionService, PrecioService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'kt-admin-b2b-localizacion',
  templateUrl: './admin-b2b-localizacion.component.html',
  styleUrls: ['./admin-b2b-localizacion.component.scss']
})
export class AdminB2bLocalizacionComponent implements OnInit {

  @ViewChild('modalGrupos', {static: false}) private modalGrupos: NgbModal;
  @ViewChild('modalCentros', {static: false}) private modalCentros: NgbModal;
  @ViewChild('modalCentro', {static: false}) private modalCentro: NgbModal;
  @ViewChild('modalTarifas', {static: false}) private modalTarifas: NgbModal;
  @ViewChild('confirmationDeleteGrupoSwal', {static: false}) private confirmationDeleteGrupoSwal: SwalComponent
  @ViewChild('deleteGrupoAskSwal', {static: false}) private deleteGrupoAskSwal: SwalComponent
  @ViewChild('confirmationCreateGrupoSwal', {static: false}) private confirmationCreateGrupoSwal: SwalComponent
  @ViewChild('deleteCentroAskSwal', {static: false}) private deleteCentroAskSwal: SwalComponent
  @ViewChild('confirmationDeleteCentroSwal', {static: false}) private confirmationDeleteCentroSwal: SwalComponent
  @ViewChild('confirmationEditCentroSwal', {static: false}) private confirmationEditCentroSwal: SwalComponent
  @ViewChild('confirmationCreateCentroSwal', {static: false}) private confirmationCreateCentroSwal: SwalComponent
  @ViewChild('deleteCentroSwal', {static: false}) private deleteCentroSwal: SwalComponent
  @ViewChild('tipoCentroSwal', {static: false}) private tipoCentroSwal: SwalComponent
  @ViewChild('cpCentroSwal', {static: false}) private cpCentroSwal: SwalComponent
  @ViewChild('confirmationTarifasSwal', {static: false}) private confirmationTarifasSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private localizacionService: LocalizacionService,
    private precioService: PrecioService,
    private centroService: CentroService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadLocalizaciones();
  }



  async loadLocalizaciones() {
    let locs = await this.localizacionService.getAll();
    this.originalRows = locs;
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      
      return mostrar;
    }.bind(this));
  }

  // ----------- GESTIONAR LOCALIZACIONES -------------

  currentLoc = null;
  grupos = [];
  centros = [];
  precios = {};
  loadingCrearGrupo = false;
  loadingCrearCentro = false;

  // ----- GRUPOS -----

  async editarGrupos(row: any) {
    this.currentLoc = row;
    let precios = await this.precioService.getByLocalizacionB2BAsync(row.id);
    console.warn(precios);
    this.grupos = [];

    let hashGr = {};
    this.grupos.push({name: "Principal", editable: false});;
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }

    this.modalService.open(this.modalGrupos, { centered: true, size: 'md' } );
  }

  objEliminarGrupo = null;
  nuevoGrupo = null;

  async eliminarGrupo(row: any) {
    this.objEliminarGrupo = row;
    this.deleteGrupoAskSwal.fire();
  }

  async continuarEliminarGrupo() {
    // Eliminar tarifas
    let precios = await this.precioService.getByLocalizacionGrupoB2BAsync(this.currentLoc.id, "\"" + this.objEliminarGrupo.name + "\"");

    for await(let precio of precios) {
      await this.precioService.deleteB2B(precio.id);
    }

    // Quitar parámetro del centro
    let centros = await this.centroService.getB2BByCPGrupo(this.currentLoc.cpDesde, this.currentLoc.cpHasta, this.objEliminarGrupo.name);
    console.warn(centros);

    for await(let centro of centros) {
      let objEdit = {
        id: centro.id,
        grupo: null
      }

      await this.centroService.updateB2B(objEdit);
    }


    this.confirmationDeleteGrupoSwal.fire();

    this.modalService.dismissAll();

    this.editarGrupos(this.currentLoc);
  }

  async guardarPrecio(servicio: any) {
    return this.precioService.postB2B({
      servicio: servicio,
      localizacion: this.currentLoc.id,
      importe: 0,
      divisa: "EUR",
      impuesto: this.currentLoc.impuesto == "IVA" ? 21 : 0,
      grupo: this.nuevoGrupo
    })
  }

  async crearGrupo() {
    if(!this.nuevoGrupo) return;
    let enc = false;
    for(let grupo of this.grupos) {
      if(grupo.name == this.nuevoGrupo) enc = true;
    }
    if(enc) return;
    this.loadingCrearGrupo = true;

    await this.guardarPrecio(55);
    await this.guardarPrecio(56);
    await this.guardarPrecio(57);
    await this.guardarPrecio(58);
    await this.guardarPrecio(59);
    await this.guardarPrecio(60);
    await this.guardarPrecio(61);

    this.confirmationCreateGrupoSwal.fire();

    this.modalService.dismissAll();
    this.loadingCrearGrupo = false;
    this.nuevoGrupo = null;

    this.editarGrupos(this.currentLoc);
  }

  // ----- CENTROS -----

  modoCentros = "";

  async editarCentros(row: any, modo: any) {
    this.currentLoc = row;
    this.modoCentros = modo;

    if(this.modoCentros == "FUNERARIAS") {
      this.centros = await this.centroService.getB2BByCPFunerariaAsync(this.currentLoc.cpDesde, this.currentLoc.cpHasta);
    } else if(this.modoCentros == "TANATORIOS") {
      this.centros = await this.centroService.getB2BByCPTanatorioAsync(this.currentLoc.cpDesde, this.currentLoc.cpHasta);
    }

    this.modalService.open(this.modalCentros, { centered: true, size: 'xl' } );

    let precios = await this.precioService.getByLocalizacionB2BAsync(row.id);
    this.grupos = [];

    let hashGr = {};
    //this.grupos.push({name: "Principal", editable: false});
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }
  }

  objEliminarCentro = null;

  async eliminarCentro(row: any) {
    for(let centro of this.centros) {
      if(centro.centroRelacionado && centro.centroRelacionado.id == row.id) {
        this.deleteCentroSwal.fire();
        return;
      }
    }
    this.objEliminarCentro = row;
    this.deleteCentroAskSwal.fire();
  }

  async continuarEliminarCentro() {
    let obj = {
      id: this.objEliminarCentro.id,
      activo: 0
    }

    await this.centroService.updateB2B(obj);

    this.confirmationDeleteCentroSwal.fire();

    this.modalService.dismissAll();
    
    this.editarCentros(this.currentLoc, this.modoCentros);
  }

  async editarCentro(row: any) {
    this.centroEdit = JSON.parse(JSON.stringify(row));
    if(!this.centroEdit.grupo) this.centroEdit.grupo = "";
    if(!this.centroEdit.centroRelacionado) this.centroEdit.centroRelacionado = ""
    else this.centroEdit.centroRelacionado = this.centroEdit.centroRelacionado.id;
    this.modalService.open(this.modalCentro, { centered: true, size: 'md' } );
  }

  centroEdit = null;
  centroEditError = {} as any;

  async nuevoCentro() {
    this.centroEdit = {
      grupo: "",
      centroRelacionado: "",
      tanatorio: false,
      crematorio: false,
      funeraria: false,
      albia: false,
      activo: true
    } as any;
    this.modalService.open(this.modalCentro, { centered: true, size: 'md' } );
  }

  async guardarCentro() {
    let errors = false;
    if(!this.centroEdit.nombre || this.centroEdit.nombre == "") {
      this.centroEditError.nombre = true;
      errors = true;
    } else {
      this.centroEditError.nombre = false;
    }
    if(!this.centroEdit.direccion || this.centroEdit.direccion == "") {
      this.centroEditError.direccion = true;
      errors = true;
    } else {
      this.centroEditError.direccion = false;
    }
    if(!this.centroEdit.poblacion || this.centroEdit.poblacion == "") {
      this.centroEditError.poblacion = true;
      errors = true;
    } else {
      this.centroEditError.poblacion = false;
    }
    if(!this.centroEdit.cp || this.centroEdit.cp == "") {
      this.centroEditError.cp = true;
      errors = true;
    } else {
      this.centroEditError.cp = false;
    }
    if(!this.centroEdit.crematorio && !this.centroEdit.centroRelacionado && this.modoCentros == "TANATORIOS") {
      this.centroEditError.centroRelacionado = true;
      errors = true;
    } else {
      this.centroEditError.centroRelacionado = false;
    }
    this.cdr.detectChanges();
    if(errors) return;

    if(!this.centroEdit.tanatorio && !this.centroEdit.crematorio && this.modoCentros == "TANATORIOS") {
      this.tipoCentroSwal.fire();
      return;
    }

    if(this.centroEdit.cp < this.currentLoc.cpDesde || this.centroEdit.cp > this.currentLoc.cpHasta) {
      this.centroEditError.cpLoc = true;
      this.cpCentroSwal.fire();
      return;
    } else {
      this.centroEditError.cpLoc = false;
    }

    this.loadingCrearCentro = true;
    this.cdr.detectChanges();

    if(this.centroEdit.grupo == "") this.centroEdit.grupo = null;
    if(this.centroEdit.centroRelacionado == "" || this.centroEdit.crematorio) this.centroEdit.centroRelacionado = null;

    if(this.modoCentros == "FUNERARIAS") this.centroEdit.funeraria = true;

    if(this.centroEdit.id) {  // Actualización
      await this.centroService.updateB2B(this.centroEdit);
      this.confirmationEditCentroSwal.fire();
    } else {  // Crear nuevo
      await this.centroService.postB2B(this.centroEdit);
      this.confirmationCreateCentroSwal.fire();
    }

    this.modalService.dismissAll();
    
    this.editarCentros(this.currentLoc, this.modoCentros);
  }

  // ----- TARIFAS -----

  grupoSel = "";
  loadingTarifas = false;
  loadingGuardarTarifa = false;

  async editarTarifas(row: any) {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    if(this.currentLoc && row.id != this.currentLoc.id) this.grupoSel = "";
    this.currentLoc = row;

    let precios = await this.precioService.getByLocalizacionB2BAsync(this.currentLoc.id);

    this.grupos = [];

    let hashGr = {};
    //this.grupos.push({name: "Principal", editable: false});
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }

    this.modalService.open(this.modalTarifas, { centered: true, size: 'lg' } );

    this.mostrarTarifasGrupo()
  }

  async mostrarTarifasGrupo() {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    let precios = await this.precioService.getByLocalizacionGrupoB2BAsync(this.currentLoc.id, this.grupoSel == "" ? null : "\"" + this.grupoSel + "\"");
    let preciosBase = [];
    if(this.grupoSel != "") preciosBase = await this.precioService.getByLocalizacionGrupoB2BAsync(this.currentLoc.id, null);
    let baseHash = {};
    for(let precio of preciosBase) {
      baseHash[precio.servicio.abreviatura] = precio;
    }

    console.warn(precios);

    this.precios = {};

    for(let precio of precios) {
      this.precios[precio.servicio.abreviatura] = precio;
    }
    if(!this.precios['WFN_PACK1_CREMA']) {
      this.precios['WFN_PACK1_CREMA'] = { 
        servicio: 55,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK1_CREMA'] ? baseHash['WFN_PACK1_CREMA'].importe : 0, 
        impuesto: baseHash['WFN_PACK1_CREMA'] ? baseHash['WFN_PACK1_CREMA'].impuesto : 21
      };
    }
    if(!this.precios['WFN_PACK2_CREMA']) {
      this.precios['WFN_PACK2_CREMA'] = { 
        servicio: 56,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK2_CREMA'] ? baseHash['WFN_PACK2_CREMA'].importe : 0, 
        impuesto: baseHash['WFN_PACK2_CREMA'] ? baseHash['WFN_PACK2_CREMA'].impuesto : 21
      };
    }
    if(!this.precios['WFN_PACK3_CREMA']) {
      this.precios['WFN_PACK3_CREMA'] = { 
        servicio: 57,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK3_CREMA'] ? baseHash['WFN_PACK3_CREMA'].importe : 0, 
        impuesto: baseHash['WFN_PACK3_CREMA'] ? baseHash['WFN_PACK3_CREMA'].impuesto : 21
      };
    }
    if(!this.precios['WFN_PACK1_ENTIERRO']) {
      this.precios['WFN_PACK1_ENTIERRO'] = { 
        servicio: 58,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK1_ENTIERRO'] ? baseHash['WFN_PACK1_ENTIERRO'].importe : 0, 
        impuesto: baseHash['WFN_PACK1_ENTIERRO'] ? baseHash['WFN_PACK1_ENTIERRO'].impuesto : 21
      };
    }
    if(!this.precios['WFN_REPATRIACION']) {
      this.precios['WFN_REPATRIACION'] = { 
        servicio: 59,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_REPATRIACION'] ? baseHash['WFN_REPATRIACION'].importe : 0, 
        impuesto: baseHash['WFN_REPATRIACION'] ? baseHash['WFN_REPATRIACION'].impuesto : 0
      };
    }
    if(!this.precios['WFN_PACK2_ENTIERRO']) {
      this.precios['WFN_PACK2_ENTIERRO'] = { 
        servicio: 60,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK2_ENTIERRO'] ? baseHash['WFN_PACK2_ENTIERRO'].importe : 0, 
        impuesto: baseHash['WFN_PACK2_ENTIERRO'] ? baseHash['WFN_PACK2_ENTIERRO'].impuesto : 21
      };
    }
    if(!this.precios['WFN_PACK3_ENTIERRO']) {
      this.precios['WFN_PACK3_ENTIERRO'] = { 
        servicio: 61,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFN_PACK3_ENTIERRO'] ? baseHash['WFN_PACK3_ENTIERRO'].importe : 0, 
        impuesto: baseHash['WFN_PACK3_ENTIERRO'] ? baseHash['WFN_PACK3_ENTIERRO'].impuesto : 21
      };
    }

    this.loadingTarifas = false;
    this.cdr.detectChanges();
  }

  async guardadoTarifa(obj: any) {
    console.warn(obj);
    if(obj.id) {
      delete obj.servicio;
      delete obj.localizacion;
      return this.precioService.updateB2B(obj);
    } else {
      return this.precioService.postB2B(obj);
    }
  }

  async guardarTarifa() {
    if(this.loadingGuardarTarifa) return;

    this.loadingGuardarTarifa = true;
    this.cdr.detectChanges();

    await this.guardadoTarifa(this.precios['WFN_PACK1_CREMA']);
    await this.guardadoTarifa(this.precios['WFN_PACK2_CREMA']);
    await this.guardadoTarifa(this.precios['WFN_PACK3_CREMA']);
    await this.guardadoTarifa(this.precios['WFN_PACK1_ENTIERRO']);
    await this.guardadoTarifa(this.precios['WFN_PACK2_ENTIERRO']);
    await this.guardadoTarifa(this.precios['WFN_PACK3_ENTIERRO']);
    await this.guardadoTarifa(this.precios['WFN_REPATRIACION']);

    this.modalService.dismissAll();

    this.editarTarifas(this.currentLoc);

    this.confirmationTarifasSwal.fire();

    this.loadingGuardarTarifa = false;
    this.cdr.detectChanges();

  }



}
