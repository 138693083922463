import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SolicitudService } from '../../../_services';
import moment from 'moment';
import { interval } from 'rxjs';

@Component({
  selector: 'kt-admin-estadisticas-wfp',
  templateUrl: './admin-estadisticas-wfp.component.html',
  styleUrls: ['./admin-estadisticas-wfp.component.scss']
})
export class AdminEstadisticasWfpComponent implements OnInit {

  constructor(private solicitudService: SolicitudService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
        
    this.interval = setInterval(() => { 
      this.esperar();
      this.cdr.detectChanges();
    }, 30000);
    
    this.cargarRegistro();
    console.log(this.estadisticas);
    this.cdr.detectChanges();
  }

  ngOnDestroy(){
    clearInterval(this.interval);
    //if(this.interval) this.interval.unsubscribe();
  }

  showXAxis = true;
  showYAxis = true;
  gradient2 = false;
  showLegend2 = true;
  showXAxisLabel = true;
  xAxisLabel = 'Items A';
  showYAxisLabel = true;
  rotateXAxisTicks = true;
  yAxisLabel = 'Items B';
  timeline: boolean = true;
  originalRows = [];
  loading = false;
  loadingVer = false;

  estadisticas = {
    arrayHoras: [],
    solicitudesProceso: 0,
    solicitudesEnviado: 0,
    solicitudesFormulario: 0,
    solicitudesEnviadoHoras: 0,
    solicitudesProcesoHoras: 0,
    solicitudesFormularioHoras: 0,
    ultimos7Dias: [],
    solicitudesGrafico2: [],
    solicitudesGrafico2Proceso: [],
    solicitudesGrafico2Form: [],
    solicitudesGrafico1: [],
    solicitudesGrafico1Proceso: [],
    solicitudesGrafico1Form: []
  };
  interval: any;

  colorScheme2 = {
    domain: ['#007A53']
  };

  tagsGraph: any[] = [];
  tagsGraph2: any[] = [];
  tagsGraph3: any[] = [];
  valuesGraph: any[] = [];
  valuesGraph2: any[] = [];


  loadSolicitudes() {
      this.solicitudService.getAll()
	    .subscribe(solicitudes => {

        for(let solic of solicitudes) {
          solic.fechaStr = moment(solic.createdAt).format("DD/MM/YY HH:mm");
        }
        this.originalRows = solicitudes;
        this.cdr.detectChanges();
      });
  }

  cargarRegistro() {
    //0
    this.solicitudService.getCountSolicitudesGraficoHoras("ENVIADO")
      .subscribe(solicitudesGrafico1 =>{
        for(let i=0;i<24;i++){
          this.estadisticas.solicitudesGrafico1[i]=solicitudesGrafico1.solicitudesCount[i];
        }
        this.cdr.detectChanges();


        this.solicitudService.getCountSolicitudesGraficoHoras("FORMULARIO")
        .subscribe(solicitudesGrafico1Form =>{
          for(let i=0;i<24;i++){
            this.estadisticas.solicitudesGrafico1Form[i]=solicitudesGrafico1Form.solicitudesCount[i];
          }
          this.cdr.detectChanges();


          this.solicitudService.getCountSolicitudesGraficoHoras("PROCESO")
          .subscribe(solicitudesGrafico1Proceso =>{
            for(let i=0;i<24;i++){
              this.estadisticas.solicitudesGrafico1Proceso[i]=solicitudesGrafico1Proceso.solicitudesCount[i];
            }
            this.cdr.detectChanges();

            this.solicitudService.getCountSolicitudesGraficoSemana("ENVIADO")
              .subscribe(arrayNumSolicitudes => {
                for(let i = 0 ; i<7 ; i++){
                  this.estadisticas.solicitudesGrafico2.push(arrayNumSolicitudes.solicitudesCount[i]);      
                }
                this.cdr.detectChanges();

                this.solicitudService.getCountSolicitudesGraficoSemana("FORMULARIO")
                .subscribe(arrayNumSolicitudesForm => {
                  for(let i = 0 ; i<7 ; i++){
                    this.estadisticas.solicitudesGrafico2Form.push(arrayNumSolicitudesForm.solicitudesCount[i]);      
                  }
                  this.cdr.detectChanges();

                  this.solicitudService.getCountSolicitudesGraficoSemana("PROCESO")
                  .subscribe(solicitudesGrafico2Proceso => {
                    console.log(solicitudesGrafico2Proceso);
                    for(let i = 0 ; i<7 ; i++){
                      this.estadisticas.solicitudesGrafico2Proceso.push(solicitudesGrafico2Proceso.solicitudesCount[i]);      
                    }
                    this.cdr.detectChanges();


                    this.solicitudService.getArrayHoras()
                    .subscribe(arrayHoras=> {
                      for(let i = 0;i<24;i++){
                        this.estadisticas.arrayHoras[i]=arrayHoras.arrayHoras[i];
                      }
                      this.cdr.detectChanges
                      this.solicitudService.getArrayDias()
                      .subscribe(arrayDias => {
                        console.log(arrayDias);
                        console.log("asd");
                        for(let i= 0 ; i<7;i++){
                          this.estadisticas.ultimos7Dias.push(arrayDias.arrayDias[i]);
                        }
                        this.cdr.detectChanges();
                        this.esperar();
                      });


                      this.solicitudService.getCountSolicitudes("PROCESO")
                      .subscribe(solicitudesProceso => {
                        this.estadisticas.solicitudesProceso = solicitudesProceso.solicitudesCount;
                        this.cdr.detectChanges();
                      });
                      this.solicitudService.getCountSolicitudesHoras("PROCESO")
                      .subscribe(solicitudesProcesoHoras => {
                        this.estadisticas.solicitudesProcesoHoras = solicitudesProcesoHoras.solicitudesCount;
                        this.cdr.detectChanges();
                      });

                      this.solicitudService.getCountSolicitudes("ENVIADO")
                      .subscribe(solicitudesEnviado => {
                        this.estadisticas.solicitudesEnviado = solicitudesEnviado.solicitudesCount;
                        this.cdr.detectChanges();
                      });
                      this.solicitudService.getCountSolicitudesHoras("ENVIADO")
                      .subscribe(solicitudesEnviadoHoras => {
                        this.estadisticas.solicitudesEnviadoHoras = solicitudesEnviadoHoras.solicitudesCount;
                        this.cdr.detectChanges();
                      })   

                      this.solicitudService.getCountSolicitudes("FORMULARIO")
                      .subscribe(solicitudes => {
                        this.estadisticas.solicitudesFormulario = solicitudes.solicitudesCount;
                        this.cdr.detectChanges();
                      });
                      this.solicitudService.getCountSolicitudesHoras("FORMULARIO")
                      .subscribe(solicitudes => {
                        this.estadisticas.solicitudesFormularioHoras = solicitudes.solicitudesCount;
                        this.cdr.detectChanges();
                      })   


                    });
                  });

                });
            });
          });  

      });
    });
  }
  
  
  esperar(){
    var columnas = "";
    var enc = false;

    this.tagsGraph = [];
    this.tagsGraph3 = [];

    this.tagsGraph.push({
      value: "SOLICITUDES FINALIZADAS",
      name: "FINALIZADAS"
    });

    this.tagsGraph.push({
      value: "SOLICITUDES NO FINALIZADAS",
      name: "NO FINALIZADAS"
    });

    this.tagsGraph.push({
      value: "FORMULARIO",
      name: "FORMULARIO"
    });


    this.setGraphValues();

    this.tagsGraph3.push({
      value: "SOLICITUDES FINALIZADAS",
      name: "FINALIZADAS"
    });
    this.tagsGraph3.push({
      value: "SOLICITUDES NO FINALIZADAS",
      name: "NO FINALIZADAS"
    });
    this.tagsGraph3.push({
      value: "FORMULARIO",
      name: "FORMULARIO"
    });
    this.setGraph2Values();
}

  setGraphValues(){
    for(let i = 0;i<24;i++){
      var fechaac = moment(this.estadisticas.arrayHoras[i]).format('HH:mm');
      this.valuesGraph[i] = {
        fecha: fechaac,
        "SOLICITUDES FINALIZADAS": this.estadisticas.solicitudesGrafico1[i],
        "SOLICITUDES NO FINALIZADAS": this.estadisticas.solicitudesGrafico1Proceso[i],
        "FORMULARIO": this.estadisticas.solicitudesGrafico1Form[i]
      }
    }
  }
  setGraph2Values(){
    for(let i = 0;i<7;i++){
      var fechaac = moment(this.estadisticas.ultimos7Dias[i]).format('DD/MM/YY');
      this.valuesGraph2[i] ={
        fecha: fechaac,
        "SOLICITUDES FINALIZADAS": this.estadisticas.solicitudesGrafico2[i],
        "SOLICITUDES NO FINALIZADAS": this.estadisticas.solicitudesGrafico2Proceso[i],
        "FORMULARIO": this.estadisticas.solicitudesGrafico2Form[i]
      }
    }
  }
  
  customizeTooltip(arg: any) {
    var items = arg.valueText.split("\n"),
      color = arg.point.getColor();
    items.forEach(function(item, index) {
      if(item.indexOf(arg.seriesName) === 0) {
        var element = document.createElement("span");
        //sadas
        
        element.textContent = item;
        element.style.color = color;
        element.className = "active";

        items[index] = element.outerHTML;
      }
    });
    return { text: "<b>" + arg.argumentText + "</b><br/>" + items.join("\n") };
  }

  async exportar(){
    if(this.loading || this.loadingVer) return;
    this.loading = true;
    this.cdr.detectChanges();

    //let workbook = new ExcelJS.Workbook();


  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
   thousands_separators(num)
   {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
      else return num_parts[0];
       
    } else {
      return '0';
    }
     
  }

}
