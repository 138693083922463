import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de localizaciones
 */
@Injectable()
export class PrecioService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/precio`);
    }
 
    getByLocalizacion(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/precio?localizacion=${loc}`);
    }
 
    getByLocalizacionAsync(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/precio?localizacion=${loc}`).toPromise()
    }
 
    getByLocalizacionGrupoAsync(loc: any, grupo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/precio?where={"localizacion":"${loc}","grupo":${grupo}}`).toPromise();
    }
 
    getByLocalizacionPETS(loc: any, peso: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/pets_precio?where={"zona":"${loc}","pesoMin":{"<=":${peso}},"pesoMax":{">=":${peso}}}`);
    }
 
    getByLocalizacionPETSAsync(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/pets_precio?where={"zona":"${loc}"}`).toPromise();
    }

    getByLocalizacionB2B(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_precio?localizacion=${loc}`);
    }

    getByLocalizacionB2BAsync(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_precio?localizacion=${loc}`).toPromise();
    }
 
    getByLocalizacionGrupoB2BAsync(loc: any, grupo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_precio?where={"localizacion":"${loc}","grupo":${grupo}}`).toPromise();
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/precio/${id}`);
    }
    
    /**
     * Realiza una petición `POST` para guardar un precio BOREA
     * @param fune Contenido del precio
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(precio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/precio`, precio, httpOptions).toPromise();
    }
    
    /**
     * Realiza una petición `POST` para guardar un precio BOREA PETS
     * @param fune Contenido del precio
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postPETS(precio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/pets_precio`, precio, httpOptions).toPromise();
    }
    
    /**
     * Realiza una petición `POST` para guardar un precio B2B
     * @param fune Contenido del precio
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postB2B(precio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/b2b_precio`, precio, httpOptions).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un precio BOREA
     * @param cert Contenido del precio a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(precio: any) {
        return this.http.patch<any>(`${environment.apiUrl}/precio/${precio.id}`, precio).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un precio BOREA PETS
     * @param cert Contenido del precio a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updatePETS(precio: any) {
      return this.http.patch<any>(`${environment.apiUrl}/pets_precio/${precio.id}`, precio).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un precio BOREA
     * @param cert Contenido del precio a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updateB2B(precio: any) {
        return this.http.patch<any>(`${environment.apiUrl}/b2b_precio/${precio.id}`, precio).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un precio BOREA
     * @param id Identificador del precio a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    delete(id: any) {
        return this.http.delete(`${environment.apiUrl}/precio/${id}`).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un precio BOREA PETS
     * @param id Identificador del precio a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deletePETS(id: any) {
        return this.http.delete(`${environment.apiUrl}/pets_precio/${id}`).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un precio B2B
     * @param id Identificador del precio a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteB2B(id: any) {
        return this.http.delete(`${environment.apiUrl}/b2b_precio/${id}`).toPromise();
    }
}