// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../core/_config/layout.config';
import { MenuConfig } from '../../../core/_config/menu.config';
import { PageConfig } from '../../../core/_config/page.config';
// User permissions
import { NgxPermissionsService } from 'ngx-permissions';
import { currentUserPermissions, Permission } from '../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';
import { AuthenticationService } from '../../../_services';
import { GlobalVariables } from '../../../_common/global-variables';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

declare function initDropdown(): any;

/**
 * Componente base de la aplicación.
 * Gestiona el modo de plataforma y el menu.
 */
@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;
	fluid: boolean;

	user$: Observable<any>;
	currentUser: any;
	currentRouteUrl: any = '';

	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private currentUserPermissions$: Observable<Permission[]>;

	modoPlataforma = null;
	hoverModo1 = false;
	hoverModo2 = false;
	hoverModo3 = false;


	@ViewChild('errorModoSwal', {static: false}) private errorModoSwal: SwalComponent

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 * @param store
	 * @param permissionsService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService,
		private store: Store<AppState>,
		private router: Router,
		private el: ElementRef,
		private cdr: ChangeDetectorRef,
		private permissionsService: NgxPermissionsService,
		private authenticationService: AuthenticationService) {
		this.loadRolesWithPermissions();

		// register configs by demos
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		this.menuConfigService.loadConfigs(new MenuConfig().configs);
		this.pageConfigService.loadConfigs(new PageConfig().configs);

		// setup element classes
		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
		this.fluid = objectPath.get(config, 'content.width') === 'fluid';

		// let the layout type change
		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(subscr);

		this.user$ = this.authenticationService.currentUser;
		this.user$.subscribe((value) => {
			initDropdown();
			this.currentUser = value;
		});
		initDropdown();

		this.currentRouteUrl = this.router.url;
		if(localStorage.getItem('modo')) GlobalVariables.modoPlataforma = localStorage.getItem('modo')
		else {
			localStorage.setItem('modo', "WFN");
			GlobalVariables.modoPlataforma = "WFN";
		}

		this.modoPlataforma = GlobalVariables.modoPlataforma;

		if(this.currentUser.proveedor && this.modoPlataforma == "WFN" && !this.currentUser.wfn && this.currentUser.wfp) this.changeModo("WFP")
		else if(this.currentUser.proveedor && this.modoPlataforma == "WFP" && !this.currentUser.wfp && this.currentUser.wfn) this.changeModo("WFN")
		else if(this.currentUser.ofertante && this.modoPlataforma == "WFP") this.changeModo("WFN");

		if(this.currentUser.actividad == "WFP-AD") this.changeModo("WFP-AD")
		else if(this.currentUser.actividad == "WFN-AD") this.changeModo("WFN-AD")
		else if(this.currentUser.actividad == "WFP-PRES") this.changeModo("WFP-PRES");

		console.warn(this.currentUser.actividad);
		console.warn("MODO: " + this.modoPlataforma);

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				console.warn("ROUTE CHANGE!!!!");
				this.currentRouteUrl = this.router.url;
				initDropdown();
				this.cdr.markForCheck();
				
				if(localStorage.getItem('modo')) GlobalVariables.modoPlataforma = localStorage.getItem('modo')
				else {
					localStorage.setItem('modo', "WFN");
					GlobalVariables.modoPlataforma = "WFN";
				}

				this.modoPlataforma = GlobalVariables.modoPlataforma;

				let myTag = this.el.nativeElement.querySelector(".hs-sub-menu-opened");
				myTag.classList.remove("hs-sub-menu-opened");
				console.warn(myTag);

			});
	}

	/**
	 * Cambia el modo de la plataforma entre WFN / WFP
	 * @param modo Identificador del modo a cambiar
	 */
	changeModo(modo: any) {
		if(modo == "WFN" && !this.currentUser.wfn) {
			this.errorModoSwal.fire();
			return;
		} else if(modo == "WFP" && !this.currentUser.wfp) {
			this.errorModoSwal.fire();
			return;
		}

		localStorage.setItem('modo', modo);
		GlobalVariables.modoPlataforma = modo;
		this.modoPlataforma = GlobalVariables.modoPlataforma;

		this.router.navigateByUrl('/perfil', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/']);
		});
		
	}

	changeModoNavigate(modo: any, url?: any) {
		if(modo == "WFN" && !this.currentUser.wfn) {
			this.errorModoSwal.fire();
			return;
		} else if(modo == "WFP" && !this.currentUser.wfp) {
			this.errorModoSwal.fire();
			return;
		}

		localStorage.setItem('modo', modo);
		GlobalVariables.modoPlataforma = modo;
		this.modoPlataforma = GlobalVariables.modoPlataforma;

		this.router.navigateByUrl('/perfil', { skipLocationChange: true }).then(() => {
			this.router.navigate([url]);
		});
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	/**
	 * NGX Permissions, init roles
	 */
	loadRolesWithPermissions() {
		this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
		const subscr = this.currentUserPermissions$.subscribe(res => {
			if (!res || res.length === 0) {
				return;
			}

			this.permissionsService.flushPermissions();
			res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * Log out
	 */
	logout() {
		this.authenticationService.logout();
	}

	logoutDemo() {
		this.authenticationService.logoutDemo();
	}

	contratar() {
		this.authenticationService.contratar();
	}
}
