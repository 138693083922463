import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DateTimeAdapter } from 'ng-pick-datetime';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, SuscriptionService, PaymentService, ServicioService, NotificacionService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
declare var Stripe: any;

/**
 * Componente que gestiona la edición de una licitación.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-licitacion-editar',
  templateUrl: './licitacion-editar.component.html',
  styleUrls: ['./licitacion-editar.component.scss']
})
export class LicitacionEditarComponent implements OnInit {

  /** Variables globales */
  @ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  @ViewChild('errorLicitacionSwal', {static: false}) private errorLicitacionSwal: SwalComponent

  currentUser: any;
  loading = false;

  servicios = [];
  estados = [];
  estadosAll = [];
  estadosHash = {};

  paises = [];
  paisesHash = {};

  editID = null;
  row = null;
  private unsubscribe: Subject<any>; 

  /** Listado de otros servicios ES */
  serviciosOtros = [
    {codigo: "otros_esquela-prensa", nombre: "Esquela en prensa", nombre_en: "Death notice in the press", valor: false},
    {codigo: "otros_esquelas-murales", nombre: "Esquelas murales", nombre_en: "Death notices on outside walls", valor: false},
    {codigo: "otros_esquela-radio", nombre: "Esquelas en radio", nombre_en: "Death notices on radio", valor: false},
    {codigo: "otros_recordatorios", nombre: "Recordatorios", nombre_en: "Memorial reminders", valor: false},
    {codigo: "otros_libro-firmas", nombre: "Libro de firmas", nombre_en: "Guest book", valor: false},
    {codigo: "otros_elementos-emocionales", nombre: "Elementos emocionales (Colgantes o joyas)", nombre_en: "Emotional elements (Necklaces or jewelry)", valor: false},
    {codigo: "otros_acomp-musical", nombre: "Acompañamiento musical", nombre_en: "Musical accompaniment", valor: false},
    {codigo: "otros_lapidas", nombre: "Lápidas granito o mármol", nombre_en: "Granite or marble tombstones", valor: false},
  ]

  /** Listado de otros servicios MX */
  serviciosOtrosMx = [
    {codigo: "otros_acta-defuncion", nombre: "Acta de defunción", nombre_en: "Death certificate", valor: false},
    {codigo: "otros_apertura-fosa", nombre: "Apertura y cierre de fosa", nombre_en: "Opening and closing of grave", valor: false},
    {codigo: "otros_cambio-ataud", nombre: "Cambio de ataúd por complexión", nombre_en: "Change of coffin due to complexion", valor: false},
    {codigo: "otros_camion-foraneo", nombre: "Camión de acompañamiento foráneo", nombre_en: "Foreign accompaniment truck", valor: false},
    {codigo: "otros_camion-local", nombre: "Camión de acompañamiento local", nombre_en: "Local accompaniment truck", valor: false},
    {codigo: "otros_ceremonia", nombre: "Ceremonia religiosa", nombre_en: "Religious ceremony", valor: false},
    {codigo: "otros_certificado", nombre: "Certificado de defunción", nombre_en: "Death certificate", valor: false},
    {codigo: "otros_cirios", nombre: "Cirios", nombre_en: "Large candles", valor: false},
    {codigo: "otros_cremacion", nombre: "Cremación de restos áridos", nombre_en: "Cremation of dry remains", valor: false},
    {codigo: "otros_embalsamado-tesis", nombre: "Embalsamado con tesis", nombre_en: "Embalming with thesis", valor: false},
    {codigo: "otros_embalsamado-recons", nombre: "Embalsamado reconstructivo básico", nombre_en: "Basic reconstructive embalming", valor: false},
    {codigo: "otros_exhumacion", nombre: "Exhumaciones", nombre_en: "Exhumations", valor: false},
    {codigo: "otros_expatriacion", nombre: "Expatriación", nombre_en: "Expatriation", valor: false},
    {codigo: "otros_flores", nombre: "Flores", nombre_en: "Flowers", valor: false},
    {codigo: "otros_fosa-panteon", nombre: "Fosa en panteón privado", nombre_en: "Grave in private pantheon", valor: false},
    {codigo: "otros_gastos-repatriacion", nombre: "Gastos de repatriación", nombre_en: "Repatriation costs", valor: false},
    {codigo: "otros_mejora-ataud", nombre: "Mejora de ataúd", nombre_en: "Coffin improvement", valor: false},
    {codigo: "otros_mejora-sala", nombre: "Mejora de sala de velación", nombre_en: "Improvement of wake room", valor: false},
    {codigo: "otros_mejora-urna", nombre: "Mejora de urna", nombre_en: "Urn improvement", valor: false},
    {codigo: "otros_renta-capilla", nombre: "Renta de capilla", nombre_en: "Renting a chapel", valor: false},
    {codigo: "otros_renta-equipo", nombre: "Renta de equipo de velación", nombre_en: "Renting the wake equipment", valor: false},
    {codigo: "otros_repatriacion", nombre: "Repatriación", nombre_en: "Repatriation", valor: false},
    {codigo: "otros_cafeteria", nombre: "Servicio de cafetería", nombre_en: "Coffee services", valor: false},
    {codigo: "otros_traslado-foraneo", nombre: "Traslado foráneo", nombre_en: "Foreign transfer", valor: false},
    {codigo: "otros_traslado-multiple", nombre: "Traslado múltiple", nombre_en: "Multiple transfer", valor: false},
    {codigo: "otros_velacion", nombre: "Velación extra", nombre_en: "Extra wake", valor: false},
  ]
  
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private ngZone: NgZone,
    private servicioService: ServicioService,
    private notificacionService: NotificacionService,
    public translate: TranslateService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');
    }

  /**
   * Método que se lanza en el inicio del componente, tras comprobar que hay parámetro inicia la carga de maestros.
   */
  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    if(this.currentUser.pais.id == 142) this.serviciosOtros = this.serviciosOtrosMx;
    this.route.paramMap.subscribe(params => {
      this.editID = params.get("id");
      this.loadPaises();
    })
  }

  /**
   * Hace scroll hasta el elemento indicado
   * @param id Identificador del elemento.
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  /**
   * Carga mediante websocket la licitación a editar y prepara los datos para su edición
   */
  loadLicitacion() {
    var loadedLicitacion = function(resData){
      this.row = resData;

      if(this.row.creador.id != this.currentUser.id || (!this.row.abierta && !this.row.validada) || (!this.row.abierta && this.row.validada && this.row.licitacionElegida)) {
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      } else if(this.row.ultimaLicitacion){
        this.errorLicitacionSwal.fire();
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      } 

      this.referenciaCliente = this.row.referenciaCliente;
      if(this.row.pujas) this.tipo = "CON"
      else this.tipo = "SIN";

      this.fechaInicio = this.row.startDate;
      this.fechaFin = this.row.endDate;

      if(this.row.tipo == "TRASLADO") {
        this.traslado = true;
        this.tipoTraslado = this.row.tipoTraslado;
        this.km = this.row.km;
        this.importeTraslado = this.row.importeSalida;
        this.comentariosTraslado = this.row.comentarios;

        this.paisDestino = this.row.paisDestino.id;
        this.provinciaDestino = this.row.provinciaDestino.id;
        this.countryChangedDestinoNew();
        this.poblacionDestino = this.row.poblacionDestino;
      } else {
        this.traslado = false;
        this.serviciosMostrar = true;
        this.tipoTraslado = null;
        this.km = null;
        this.importeTraslado = null;
        this.comentariosTraslado = null;
        this.provinciaDestino = null;
        this.poblacionDestino = null;

        var completo = false;

        if(this.row.tipo == "AMBOS") {
          this.traslado = true;
          this.tipoTraslado = this.row.tipoTraslado;
          this.km = this.row.km;
          this.importeTraslado = this.row.importeSalida;
          this.comentariosTraslado = this.row.comentarios;

          this.paisDestino = this.row.paisDestino.id;
          this.provinciaDestino = this.row.provinciaDestino.id;
          this.countryChangedDestinoNew();
          this.poblacionDestino = this.row.poblacionDestino;
        }
        
        var totalServicios = 0;
        for(let serv of this.servicios) {
          serv.error = {importeSalida: false, comentarios: false, importeNegativo: false, importeIncorrecto: false, tipoCompleto: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          for(let servLic of this.row.servicios) {
            if(serv.id == servLic.id) {
              serv.comentarios = servLic.comentarios;
              serv.importeSalida = servLic.importeSalida;
              totalServicios += servLic.importeSalida;
              serv.val = true;
              if(serv.abreviatura == "SVFUN") {
                completo = true;
                if(servLic.tipo && servLic.tipo == "CREMA") serv.crema = true
                else if(servLic.tipo && servLic.tipo == "DESTEMP") serv.destemp = true;
                serv.tipo = servLic.tipo;
              } else if(serv.abreviatura == "OTROS") {
                if(servLic.detalleOtros) this.serviciosOtros = servLic.detalleOtros;
              } else if(serv.abreviatura == "CREMA") {
                for(let serv1 of this.servicios) {
                  if(serv1.abreviatura == "DESTEMP") {
                    serv1.disabled = true;
                    break;
                  }
                }
              } else if(serv.abreviatura == "DESTEMP") {
                for(let serv1 of this.servicios) {
                  if(serv1.abreviatura == "CREMA") {
                    serv1.disabled = true;
                    break;
                  }
                }
              }
              break;
            }
          }          
        }

        if(this.row.tipo == "AMBOS") {
          this.importeTraslado -= totalServicios;
        }


        if(completo) {
          for(let serv of this.servicios) {
            if(serv.abreviatura == "FLOR" || serv.abreviatura == "TANAT" || serv.abreviatura == "COCHE" || serv.abreviatura == "GEST" || serv.abreviatura == "CREMA" || serv.abreviatura == "DESTEMP") {
              serv.error = {importeSalida: false, comentarios: false};
              serv.comentarios = null;
              serv.importeSalida = null;
              serv.val = false;
              serv.completo = true;
            }
          }
        }
      }

      this.paisOrigen = this.row.paisOrigen.id;
      this.provinciaOrigen = this.row.provinciaOrigen.id;
      this.countryChangedOrigenNew();
      this.poblacionOrigen = this.row.poblacionOrigen;

      this.cdr.detectChanges();
    }
    self.io.socket.get('/licitacion/' + this.editID, loadedLicitacion.bind(this));
  }

  /**
   * Carga el listado completo de comunidades autónomas / estados
   */
  loadProvincias(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        for(let estado of estados) {
          this.estadosHash[estado.id+""] = estado;
        }
        this.loadServicios();
        this.cdr.detectChanges();
      });
  }

  /**
   * Carga el listado completo de paises
   */
  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      for(let pais of paises) {
        this.paisesHash[pais.id+""] = pais;
      }
      this.loadProvincias();
      this.cdr.detectChanges();
		});
	}

  /**
   * Carga los servicios del grupo correspondiente
   */
  loadServicios() {
		var grupo = "ES";
    if(this.currentUser.pais.id == 142) grupo = "MX";
		this.servicioService.getAllGrupo(grupo)
	    .subscribe(servicios => {
			this.servicios = servicios;
      for(let serv of this.servicios) {
        serv.error = {importeSalida: false, comentarios: false, importeNegativo: false, importeIncorrecto: false, tipoCompleto: false};
        serv.comentarios = null;
        serv.importeSalida = null;
        serv.val = false;
        serv.detalleMostrar = false;
        serv.detalleMostrarCompleto = false;
        serv.completo = false;
        serv.crema = false;
        serv.destemp = false;
        serv.detalleCompleto = null;
        if(serv.abreviatura == "CREMA") {
          this.servicios[0].cremaDetalle = serv;
        } else if(serv.abreviatura == "DESTEMP") {
          this.servicios[0].destempDetalle = serv;
        }
      }
      this.servicios.sort(function (a, b) {
        if (a.orden < b.orden) {
          return -1;
        } else if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      }.bind(this));
      this.loadLicitacion();
      this.cdr.detectChanges();
		});
	}

  /** Variables para la edición */
  referenciaCliente = null;
  tipo = null;
  traslado = false;
  serviciosMostrar = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;
  detalleTraslado = false;

  fechaInicio = null;
  fechaFin = null;
  error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, importeNegativo: false, importeIncorrecto: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  paisOrigen = null;
  paisDestino = null;

  provinciaOrigen = null;
  poblacionOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;

  estadosOrigenNew = []
  estadosDestinoNew = [];

  /**
   * Evento de cambio de país origen.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedOrigenNew() {
    this.estadosOrigenNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigenNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  /**
   * Evento de cambio de país destino.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedDestinoNew() {
    this.estadosDestinoNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisDestino) this.estadosDestinoNew.push(this.estados[i]);
    }
  }

  /**
   * Activa el evento de cambios en el componente.
   */
  detectChanges(){
    this.cdr.detectChanges();
  }

  serviciosCompletados = 0;

  /**
   * Comprueba que todo es correcto y guarda la licitación.
   */
  submitLicit() {
    //COMPROBAR ERRORES
    var errors = false;
    //error = {referencia: false, origen: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

    if(!this.referenciaCliente || this.referenciaCliente == ""){
      this.error.referencia = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.referencia = false;
    }
    if(!this.tipo){
      this.error.tipo = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.tipo = false;
    }
    if(!this.fechaInicio){
      this.error.fechaInicio = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaInicio = false;
    }
    if(!this.fechaFin){
      this.error.fechaFin = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaFin = false;
    }
    if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
      this.error.origen = true;
      if(!errors) {
        this.scroll("traslado");
      }
      errors = true;
    } else {
      this.error.origen = false;
    }
    if(this.traslado) {
      if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
        this.error.destino = true;
        if(!errors) {
          this.scroll("traslado");
        }
        errors = true;
      } else {
        this.error.destino = false;
      }
    }
    if(!this.serviciosMostrar && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    var servicioSel = false;
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        servicioSel = true;
        break;
      }
    }
    if(!servicioSel && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        this.scroll("servicios");
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }
    if(moment(this.fechaFin).diff(this.fechaInicio) < 0){
      this.error.fechaOrden = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaOrden = false;
    }

    //COMPROBAR SERVICIOS
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
      if((this.importeTraslado || this.importeTraslado == 0) && this.importeTraslado <= 0) {
        this.error.importeNegativo = true;
        errors = true;
      } else {
        this.error.importeNegativo = false;
      }
      if(this.importeTraslado && this.importeTraslado > 99999) {
        this.error.importeIncorrecto = true;
        errors = true;
      } else {
        this.error.importeIncorrecto = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        /*if(this.servicios[i].abreviatura == "OTROS") {
          if(this.servicios[i].comentarios && this.servicios[i].comentarios != "") {
            this.servicios[i].error.comentarios = false;
          } else {
            this.servicios[i].error.comentarios = true;
            errors = true;
          }
        } else */if(this.servicios[i].abreviatura == "SVFUN") {
          if(this.servicios[i].tipo) {
            this.servicios[i].error.tipoCompleto = false;
          } else {
            this.servicios[i].error.tipoCompleto = true;
            errors = true;
          }
        }
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
        if((this.servicios[i].importeSalida || this.servicios[i].importeSalida == 0) && this.servicios[i].importeSalida <= 0) {
          this.servicios[i].error.importeNegativo = true;
          errors = true;
        } else {
          this.servicios[i].error.importeNegativo = false;
        }
        if(this.servicios[i].importeSalida && this.servicios[i].importeSalida > 99999) {
          this.servicios[i].error.importeIncorrecto = true;
          errors = true;
        } else {
          this.servicios[i].error.importeIncorrecto = false;
        }
      }
    }
    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    var loadedLicitacion = function(resData){
      //this.row = resData;

      //comprobamos que no hayan llegado licitaciones
      if(resData.ultimaLicitacion){ 
        this.errorLicitacionSwal.fire();
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      }

      this.loading = true;
      this.cdr.detectChanges();

      if(moment(this.fechaInicio).diff() < 0){
        this.fechaInicio = new Date();
      }

      if(this.tipo == "CON") {
        this.tipo = true;
      } else {
        this.tipo = false;
      }
      
      //GUARDAR
      if(this.traslado && !this.serviciosMostrar) {
        var licit = {
          referenciaCliente: this.referenciaCliente || "",
          startDate: this.fechaInicio,
          endDate: this.fechaFin,
          abierta: true,
          validada: false,
          pujas: this.tipo,
          importeSalida: this.importeTraslado || 0,
          poblacionOrigen: this.poblacionOrigen,
          provinciaOrigen: this.provinciaOrigen,
          paisOrigen: this.paisOrigen,
          comentarios: this.comentariosTraslado || "",
          poblacionDestino: this.poblacionDestino,
          provinciaDestino: this.provinciaDestino,
          paisDestino: this.paisDestino,
          km: this.km,
          tipoTraslado: this.tipoTraslado
        }
        var completado = function (resData){
          this.confirmationEditSwal.fire();
          this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, licit, completado.bind(this));
      } else {
        var total = 0;
        var servicios = [];
        for(var i = 0; i < this.servicios.length; i++){
          if(this.servicios[i].val) {
            total += this.servicios[i].importeSalida;
            delete this.servicios[i].val;
            delete this.servicios[i].error;
            delete this.servicios[i].destemp;
            delete this.servicios[i].crema;
            delete this.servicios[i].cremaDetalle;
            delete this.servicios[i].destempDetalle;
            if(this.servicios[i].abreviatura == "OTROS") {
              this.servicios[i].detalleOtros = this.serviciosOtros;
            }
            servicios.push(this.servicios[i]);
          }
        }

        var poblacion = "", poblacionDestino = "";
        var provincia = null, provinciaDestino = null;
        var pais = null, paisDestino = null;
        var comentarios = "", km = 0, tipoTraslado = "";

        if(this.traslado && this.serviciosMostrar) {
          poblacion = this.poblacionOrigen;
          provincia = this.provinciaOrigen;
          pais = this.paisOrigen;
          poblacionDestino = this.poblacionDestino;
          provinciaDestino = this.provinciaDestino;
          paisDestino = this.paisDestino;
          total += this.importeTraslado;
          comentarios = this.comentariosTraslado || "";
          km = this.km;
          tipoTraslado = this.tipoTraslado;
        } else {
          poblacion = this.poblacionOrigen;
          provincia = this.provinciaOrigen;
          pais = this.paisOrigen;
        }

        var licit2 = {
          referenciaCliente: this.referenciaCliente || "",
          startDate: this.fechaInicio,
          endDate: this.fechaFin,
          abierta: true,
          validada: false,
          pujas: this.tipo,
          importeSalida: total || 0,
          poblacionOrigen: poblacion,
          provinciaOrigen: provincia,
          paisOrigen: pais,
          comentarios: comentarios,
          poblacionDestino: poblacionDestino,
          provinciaDestino: provinciaDestino,
          paisDestino: paisDestino,
          km: km,
          tipoTraslado: tipoTraslado,
          servicios: servicios
        }
        var completado = function (resData){
          this.confirmationEditSwal.fire();
          this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, licit2, completado.bind(this));
      }  






    }
    self.io.socket.get('/licitacion/' + this.editID, loadedLicitacion.bind(this));

      
  }

  /**
   * Evento de selección de un servicio. Prepara sus formularios dependiendo del tipo.
   * @param serv Objeto del servicio pulsado
   */
  servicioPulsado(serv) {
    if(serv.disabled) return;
    if(serv.abreviatura == "SVFUN" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "FLOR" || serv.abreviatura == "TANAT" || serv.abreviatura == "COCHE" || serv.abreviatura == "GEST" || serv.abreviatura == "CREMA" || serv.abreviatura == "DESTEMP") {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = true;
          serv.disabled = false;
        }
      }
    } else if(serv.abreviatura == "SVFUN" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.completo) {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = false;
          serv.disabled = false;
        }
      }
    } else if(serv.abreviatura == "CREMA" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "DESTEMP") {
          serv.disabled = false;
          break;
        }
      }
    } else if(serv.abreviatura == "CREMA" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "DESTEMP") {
          serv.disabled = true;
          break;
        }
      }
    } else if(serv.abreviatura == "DESTEMP" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "CREMA") {
          serv.disabled = false;
          break;
        }
      }
    } else if(serv.abreviatura == "DESTEMP" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "CREMA") {
          serv.disabled = true;
          break;
        }
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Evento de selección de un servicio completo. 
   * @param serv Objeto del servicio
   * @param opcion Opción seleccionada
   */
  completoPulsado(serv, opcion) {
    serv.crema = false;
    serv.destemp = false;
    this.cdr.detectChanges();
    if(opcion == "CREMA") {
      serv.crema = true;
    } else {
      serv.destemp = true;
    }
    serv.tipo = opcion;
    this.cdr.detectChanges();
  }

  

}
