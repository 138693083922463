import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, B2bService, PaymentService, SolicitudService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import { Router } from '@angular/router';

import * as ExcelJS from 'exceljs/dist/exceljs';

import * as logoFile from './logo.js';
import * as logoPets from './logoPets.js';
import * as logoBorea from './logoBorea.js';
import html2canvas from 'html2canvas';

@Component({
  selector: 'kt-admin-informe-mensual',
  templateUrl: './admin-informe-mensual.component.html',
  styleUrls: ['./admin-informe-mensual.component.scss']
})
export class AdminInformeMensualComponent implements OnInit {

  currentUser: any;

  rows = [];
  originalRows = [];

  servicio = "BOREA";

  constructor(
    private authenticationService: AuthenticationService,
    private b2bService: B2bService,
    private paymentService: PaymentService,
    private router: Router,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadInformes();
  }

  async loadInformes() {
    this.rows = [];
    this.cdr.detectChanges();

    let informes = await this.b2bService.getInformes(this.servicio);
    for(let inf of informes) {
      inf.graficaServicios = [];
      inf.graficaEntierros = [];
      inf.graficaCremaciones = [];
      inf.graficaLocalizaciones = [];

      if(this.servicio == 'BOREA') {
        inf.graficaServicios.push({
          servicio: "Cremaciones",
          valor: inf.servicios.WFP_PACK1_CREMA + inf.servicios.WFP_PACK2_CREMA + inf.servicios.WFP_PACK3_CREMA
        });
        inf.graficaServicios.push({
          servicio: "Entierros",
          valor: inf.servicios.WFP_PACK1_ENTIERRO + inf.servicios.WFP_PACK2_ENTIERRO + inf.servicios.WFP_PACK3_ENTIERRO
        });
        inf.graficaEntierros.push({
          servicio: "Entierros directos",
          valor: inf.servicios.WFP_PACK1_ENTIERRO
        });
        inf.graficaEntierros.push({
          servicio: "Entierro con velación",
          valor: inf.servicios.WFP_PACK2_ENTIERRO
        });
        inf.graficaEntierros.push({
          servicio: "Entierro, velación y flores",
          valor: inf.servicios.WFP_PACK3_ENTIERRO
        });
        inf.graficaCremaciones.push({
          servicio: "Cremaciones directas",
          valor: inf.servicios.WFP_PACK1_CREMA
        });
        inf.graficaCremaciones.push({
          servicio: "Cremación con velación",
          valor: inf.servicios.WFP_PACK2_CREMA
        });
        inf.graficaCremaciones.push({
          servicio: "Cremación, velación y flores",
          valor: inf.servicios.WFP_PACK3_CREMA
        });
      } else if(this.servicio == 'WFN' || this.servicio == 'FP') {
        inf.graficaServicios.push({
          servicio: "Cremaciones",
          valor: inf.servicios.WFN_PACK1_CREMA + inf.servicios.WFN_PACK2_CREMA + inf.servicios.WFN_PACK3_CREMA
        });
        inf.graficaServicios.push({
          servicio: "Entierros",
          valor: inf.servicios.WFN_PACK1_ENTIERRO + inf.servicios.WFN_PACK2_ENTIERRO + inf.servicios.WFN_PACK3_ENTIERRO
        });
        inf.graficaServicios.push({
          servicio: "Repatriaciones",
          valor: inf.servicios.WFN_REPATRIACION
        });
        inf.graficaEntierros.push({
          servicio: "Entierros directos",
          valor: inf.servicios.WFN_PACK1_ENTIERRO
        });
        inf.graficaEntierros.push({
          servicio: "Entierro con velación",
          valor: inf.servicios.WFN_PACK2_ENTIERRO
        });
        inf.graficaEntierros.push({
          servicio: "Entierro, velación y serv.relig.",
          valor: inf.servicios.WFN_PACK3_ENTIERRO
        });
        inf.graficaCremaciones.push({
          servicio: "Cremaciones directas",
          valor: inf.servicios.WFN_PACK1_CREMA
        });
        inf.graficaCremaciones.push({
          servicio: "Cremación con velación",
          valor: inf.servicios.WFN_PACK2_CREMA
        });
        inf.graficaCremaciones.push({
          servicio: "Cremación, velación y serv.relig.",
          valor: inf.servicios.WFN_PACK3_CREMA
        });
      } else if(this.servicio == 'PETS') {
        inf.graficaServicios.push({
          servicio: "Individual",
          valor: inf.servicios.PETS_CREMA_INDIV
        });
        inf.graficaServicios.push({
          servicio: "Colectiva",
          valor: inf.servicios.PETS_CREMA_COLECT
        });
      }

      for(let item in inf.localizaciones) {
        inf.graficaLocalizaciones.push({
          localizacion: item,
          valor: inf.localizaciones[item]
        });
      }
    }
    this.originalRows = informes;
    this.filterDatatable();
    console.warn(informes);
    this.cdr.detectChanges();
  }

  cambioServicio() {
    this.loadInformes();
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      //if(item.status == "INVITADO") mostrar = false;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }
  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
      else return num_parts[0];
      
    } else {
      return '0';
    }
    
  }



  loadingExportar = false;

  async exportar() {
    
    if(this.loadingExportar) return;
  
    this.loadingExportar = true;
    this.cdr.detectChanges();
    this.cdr.detectChanges();

    // Async timeout
    await new Promise(resolve => setTimeout(resolve, 2000)); 

    if(this.servicio == 'PETS') {
      this.continuarExportarPETS();
    } else if(this.servicio == 'BOREA') {
      this.continuarExportarBOREA();
    } else {
      this.continuarExportarWFN();
    }

  }

  setBorder(worksheet, cell, type) {
    worksheet.getCell(cell).border = { top: {style:type}, left: {style:type}, bottom: {style:type}, right: {style:type} };
  }

  setBackground(worksheet, cell, color) {
    worksheet.getCell(cell).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:color},
      bgColor:{argb:color}
    }
  }

  async continuarExportarBOREA() {
    function colName(n) {
      var ordA = 'A'.charCodeAt(0);
      var ordZ = 'Z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
    
      var s = "";
      while(n >= 0) {
          s = String.fromCharCode(n % len + ordA) + s;
          n = Math.floor(n / len) - 1;
      }
      return s;
    }


    let workbook = new ExcelJS.Workbook();

    for await(let row of this.rows) {
      
      let worksheet = workbook.addWorksheet(row.mes + "-" + row.anyo);

      /*worksheet.getColumn(1).width = 13;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;*/

      worksheet.getColumn(2).width = 30;
      for(let j = 3; j < 21; j++) {
        worksheet.getColumn(j).width = 13;
      }

      let logo = workbook.addImage({
        base64: logoBorea.logoBase64,
        extension: 'png',
      });
      var image = worksheet.addImage(logo, {
        tl: { col: 0.5, row: 1 },
        ext: { width: 400, height: 90 }
      });

      let canvasEntierros = await html2canvas(document.querySelector("#graficaEntierros" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaEntierros' + row.id).style.opacity = '1';
        }
      });
      const graficaEntierros = canvasEntierros.toDataURL("image/png");

      let wkGraficaEntierros = workbook.addImage({
        base64: graficaEntierros,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaEntierros, {
        tl: { col: 10, row: 3 },
        ext: { width: 500, height: 400 }
      });

      let canvasCremaciones = await html2canvas(document.querySelector("#graficaCremaciones" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaCremaciones' + row.id).style.opacity = '1';
        }
      });
      const graficaCremaciones = canvasCremaciones.toDataURL("image/png");

      let wkGraficaCremaciones = workbook.addImage({
        base64: graficaCremaciones,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaCremaciones, {
        tl: { col: 16, row: 2 },
        ext: { width: 500, height: 400 }
      });

      let canvasServicios = await html2canvas(document.querySelector("#graficaServicios" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaServicios' + row.id).style.opacity = '1';
        }
      });
      const graficaServicios = canvasServicios.toDataURL("image/png");

      let wkGraficaServicios = workbook.addImage({
        base64: graficaServicios,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaServicios, {
        tl: { col: 10, row: 26 },
        ext: { width: 500, height: 400 }
      });

      let canvasLocalizaciones = await html2canvas(document.querySelector("#graficaLocalizaciones" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaLocalizaciones' + row.id).style.opacity = '1';
        }
      });
      const graficaLocalizaciones = canvasLocalizaciones.toDataURL("image/png");

      let wkGraficaLocalizaciones = workbook.addImage({
        base64: graficaLocalizaciones,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaLocalizaciones, {
        tl: { col: 2, row: 54 },
        ext: { width: 1400, height: 400 }
      });


      for(let i = 0; i < 80; i++) {
        for(let j = 0; j < 100; j++) {
          this.setBackground(worksheet, colName(i) + (1 + j), 'F4CECE');
        }
      }

      const cell = worksheet.getCell('F2');
      cell.value = 'Informe - Servicios ' + this.servicio;
      cell.font = { size: 16, bold: true };

      worksheet.getCell('F4').value = 'Fecha Informe: ';
      worksheet.getCell('F4').font = { bold: true };
      worksheet.getCell('G4').value = moment().format("DD/MM/YYYY HH:mm");

      worksheet.getCell('B8').value = 'TODOS LOS LEADS';
      worksheet.getCell('B8').font = { bold: true };
      this.setBackground(worksheet, 'B8', '00efff');
      this.setBorder(worksheet, 'B8', 'medium');
      
      worksheet.getCell('C8').value = row.leads.total;
      worksheet.getCell('C8').font = { bold: true };
      worksheet.getCell('C8').alignment = { horizontal: 'center' };
      worksheet.mergeCells('C8:G8');
      this.setBackground(worksheet, 'C8', '00efff');
      this.setBorder(worksheet, 'C8', 'medium');

      let addLeads = function (row, title, value, bg) {
        worksheet.getCell('B' + row).value = title;
        this.setBackground(worksheet, 'B' + row, bg);
        this.setBorder(worksheet, 'B' + row, 'thin');
        worksheet.getCell('C' + row).value = value;
        this.setBackground(worksheet, 'C' + row, bg);
        this.setBorder(worksheet, 'C' + row, 'thin');
        worksheet.getCell('C' + row).alignment = { horizontal: 'center' };
        worksheet.getCell('D' + row).value = {formula: 'C' + row + '*100%/C8'};
        worksheet.getCell('D' + row).numFmt = '0.00%';
        this.setBackground(worksheet, 'D' + row, bg);
        this.setBorder(worksheet, 'D' + row, 'thin');
        worksheet.getCell('D' + row).alignment = { horizontal: 'center' };
      }.bind(this);

      addLeads(9, 'NO CONTACTABLES', row.leads.NOCONT, 'ff6e6e');
      addLeads(10, 'SIN INTERÉS', row.leads.INTERES, 'ff6e6e');
      addLeads(11, 'DATOS FICTICIOS', row.leads.FICTICIO, 'ff6e6e');
      addLeads(12, 'LLAMADA ERRÓNEA', row.leads.ERROR, 'ff6e6e');
      addLeads(13, 'COMPETENCIA', row.leads.COM_PRO + row.leads.COM_PRE + row.leads.COM_LEN + row.leads.COM_ASE, 'ffa500');
      addLeads(14, 'OTRA RAZÓN', row.leads.OTRO, 'ffa500');
      addLeads(15, 'FANTASMA', row.leads.FANTASMA, 'ffa500');
      addLeads(16, 'LEAD TIENE SEGURO DECESOS', row.leads.SEGURO, 'ffa500');
      addLeads(17, 'SEG.DEC.(PP) OFRECIDO Y PERDIDO', row.leads.PP, 'ffa500');
      addLeads(18, 'PR.ÚN.(PU) OFRECIDA Y PERDIDO', row.leads.PU, 'ffa500');
      addLeads(19, 'FALTA DE COBERTURA GEOGRÁFICA', row.leads.SIN_GEO, 'ffa500');
      addLeads(20, 'FALTA SERVICIO', row.leads.SIN_SERV, 'ffa500');
      addLeads(21, 'QUIERE PLANIFICACIÓN', row.leads.SIN_PLAN, 'ffa500');
      addLeads(22, 'EN PROCESO CONTACTAR', row.leads.PENDIENTE, 'fd00ff');
      addLeads(23, 'PENDIENTE PRESUPUESTAR', row.leads.PRESUPUESTAR, 'fd00ff');
      addLeads(24, 'PENDIENTE LLAMADA CLIENTE', row.leads.LLAMADA, 'fd00ff');
      addLeads(25, 'PRESUP. FIRMADO (Cli.Ganado)', row.leads.ACEPTADA, '00AAFF');
      addLeads(26, 'SERVICIO REALIZADO', row.leads.COMPLETADA, '00ff00');
      worksheet.getCell('B26').font = { bold: true };
      worksheet.getCell('C26').font = { bold: true };
      worksheet.getCell('D26').font = { bold: true };

      worksheet.getCell('E9').value = { formula: 'SUM(C9:C12)'};
      this.setBackground(worksheet, 'E9', 'ffffff');
      this.setBorder(worksheet, 'E9', 'medium');
      worksheet.getCell('E9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E9:E12');
      worksheet.getCell('F9').value = { formula: 'SUM(D9:D12)'};
      worksheet.getCell('F9').numFmt = '0.00%';
      this.setBackground(worksheet, 'F9', 'ffffff');
      this.setBorder(worksheet, 'F9', 'medium');
      worksheet.getCell('F9').font = { bold: true, color: { argb: 'FF0000' }, };
      worksheet.getCell('F9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F9:F12');
      worksheet.getCell('G9').value = "LEADS NO VÁLIDOS";
      this.setBackground(worksheet, 'G9', 'ffffff');
      this.setBorder(worksheet, 'G9', 'medium');
      worksheet.getCell('G9').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G9:G12');

      worksheet.getCell('E13').value = { formula: 'SUM(C13:C26)'};
      this.setBackground(worksheet, 'E13', 'ffffff');
      this.setBorder(worksheet, 'E13', 'medium');
      worksheet.getCell('E13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E13:E26');
      worksheet.getCell('F13').value = { formula: 'SUM(D13:D26)'};
      worksheet.getCell('F13').numFmt = '0.00%';
      this.setBackground(worksheet, 'F13', 'ffffff');
      this.setBorder(worksheet, 'F13', 'medium');
      worksheet.getCell('F13').font = { bold: true };
      worksheet.getCell('F13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F13:F26');
      worksheet.getCell('G13').value = "SERVICIOS PERDIDOS";
      this.setBackground(worksheet, 'G13', 'ffffff');
      this.setBorder(worksheet, 'G13', 'medium');
      worksheet.getCell('G13').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G13:G21');
      worksheet.getCell('G22').value = "POTENCIALES CLIENTES";
      this.setBackground(worksheet, 'G22', 'ffffff');
      this.setBorder(worksheet, 'G22', 'medium');
      worksheet.getCell('G22').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G22:G25');
      worksheet.getCell('G26').value = "CLIENTES";
      this.setBackground(worksheet, 'G26', 'ffffff');
      this.setBorder(worksheet, 'G26', 'medium');
      worksheet.getCell('G26').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      
      worksheet.getCell('H11').value = "RATIO DE CONVERSIÓN";
      this.setBackground(worksheet, 'H11', 'ffffff');
      this.setBorder(worksheet, 'H11', 'medium');
      worksheet.getCell('H11').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('H11:H12');
      worksheet.getCell('H13').value = { formula: 'C26*100/(E13-C16-C17-C18-C14)'};
      //worksheet.getCell('H13').numFmt = '0,00';
      this.setBackground(worksheet, 'H13', 'ffffff');
      this.setBorder(worksheet, 'H13', 'medium');
      worksheet.getCell('H13').font = { bold: true };
      worksheet.getCell('H13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('H13:H26');

      worksheet.getCell('B28').value = "INVERSIÓN GOOGLE Y BING";
      this.setBackground(worksheet, 'B28', 'ffffff');
      this.setBorder(worksheet, 'B28', 'medium');
      worksheet.getCell('C28').value = row.inversion;
      this.setBackground(worksheet, 'C28', 'ffffff');
      this.setBorder(worksheet, 'C28', 'medium');
      worksheet.getCell('C28').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C28').numFmt = '#,##0.00"€"';

      worksheet.getCell('E28').value = "TICKET MEDIO";
      this.setBackground(worksheet, 'E28', 'ffffff');
      this.setBorder(worksheet, 'E28', 'medium');
      worksheet.getCell('F28').value = row.ticket;
      this.setBackground(worksheet, 'F28', 'ffffff');
      this.setBorder(worksheet, 'F28', 'medium');
      worksheet.getCell('F28').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('F28').numFmt = '#,##0.00"€"';

      // SERVICIOS
      {
        worksheet.getCell('B30').value = "Cremaciones directas";
        worksheet.getCell('C30').value = { formula: 'D30*100%/F30'};
        worksheet.getCell('C30').numFmt = '0.00%';
        worksheet.getCell('C30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D30').value = row.servicios.WFP_PACK1_CREMA;
        worksheet.getCell('D30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B31').value = "Cremación con velación";
        worksheet.getCell('C31').value = { formula: 'D31*100%/F30'};
        worksheet.getCell('C31').numFmt = '0.00%';
        worksheet.getCell('C31').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D31').value = row.servicios.WFP_PACK2_CREMA;
        worksheet.getCell('D31').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B32').value = "Cremación con velación y flores";
        worksheet.getCell('C32').value = { formula: 'D32*100%/F30'};
        worksheet.getCell('C32').numFmt = '0.00%';
        worksheet.getCell('C32').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D32').value = row.servicios.WFP_PACK3_CREMA;
        worksheet.getCell('D32').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B33').value = "Entierros directos";
        worksheet.getCell('C33').value = { formula: 'D33*100%/F30'};
        worksheet.getCell('C33').numFmt = '0.00%';
        worksheet.getCell('C33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D33').value = row.servicios.WFP_PACK1_ENTIERRO;
        worksheet.getCell('D33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B34').value = "Entierros con velación";
        worksheet.getCell('C34').value = { formula: 'D34*100%/F30'};
        worksheet.getCell('C34').numFmt = '0.00%';
        worksheet.getCell('C34').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D34').value = row.servicios.WFP_PACK2_ENTIERRO;
        worksheet.getCell('D34').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B35').value = "Entierros con velación y flores";
        worksheet.getCell('C35').value = { formula: 'D35*100%/F30'};
        worksheet.getCell('C35').numFmt = '0.00%';
        worksheet.getCell('C35').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D35').value = row.servicios.WFP_PACK3_ENTIERRO;
        worksheet.getCell('D35').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B36').value = "Traslados";
        worksheet.getCell('C36').value = "";
        worksheet.getCell('D36').value = row.servicios.WFP_TRASLADO;
        worksheet.getCell('D36').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell('E30').value = "Cremaciones";
        worksheet.getCell('E30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('E30:E32');
        worksheet.getCell('F30').value = { formula: 'SUM(D30:D32)'};
        worksheet.getCell('F30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('F30:F32');
        worksheet.getCell('G30').value = { formula: '100%*F30/SUM(F30:F35)'};
        worksheet.getCell('G30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G30').numFmt = '0.00%';
        worksheet.mergeCells('G30:G32');

        worksheet.getCell('E33').value = "Entierros";
        worksheet.getCell('E33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('E33:E35');
        worksheet.getCell('F33').value = { formula: 'SUM(D33:D35)'};
        worksheet.getCell('F33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('F33:F35');
        worksheet.getCell('G33').value = { formula: '100%*F33/SUM(F30:F35)'};
        worksheet.getCell('G33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G33').numFmt = '0.00%';
        worksheet.mergeCells('G33:G35');
      }
      {
        this.setBackground(worksheet, 'B30', 'ffffff');
        this.setBackground(worksheet, 'B31', 'ffffff');
        this.setBackground(worksheet, 'B32', 'ffffff');
        this.setBackground(worksheet, 'B33', 'ffffff');
        this.setBackground(worksheet, 'B34', 'ffffff');
        this.setBackground(worksheet, 'B35', 'ffffff');
        this.setBackground(worksheet, 'B36', 'ffffff');
        this.setBackground(worksheet, 'C30', 'ffffff');
        this.setBackground(worksheet, 'C31', 'ffffff');
        this.setBackground(worksheet, 'C32', 'ffffff');
        this.setBackground(worksheet, 'C33', 'ffffff');
        this.setBackground(worksheet, 'C34', 'ffffff');
        this.setBackground(worksheet, 'C35', 'ffffff');
        this.setBackground(worksheet, 'C36', 'ffffff');
        this.setBackground(worksheet, 'D30', 'ffffff');
        this.setBackground(worksheet, 'D31', 'ffffff');
        this.setBackground(worksheet, 'D32', 'ffffff');
        this.setBackground(worksheet, 'D33', 'ffffff');
        this.setBackground(worksheet, 'D34', 'ffffff');
        this.setBackground(worksheet, 'D35', 'ffffff');
        this.setBackground(worksheet, 'D36', 'ffffff');
        this.setBackground(worksheet, 'E30', 'ffffff');
        this.setBackground(worksheet, 'E33', 'ffffff');
        this.setBackground(worksheet, 'F30', 'ffffff');
        this.setBackground(worksheet, 'F33', 'ffffff');
        this.setBackground(worksheet, 'G30', 'ffffff');
        this.setBackground(worksheet, 'G33', 'ffffff');

        worksheet.getCell('B30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B31').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B32').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B34').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B35').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B36').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('C30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C31').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C32').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C33').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C34').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C35').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C36').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('D30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D31').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D32').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D33').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D34').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D35').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D36').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'medium'} };
        
        worksheet.getCell('E30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('E33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('F30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('F33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('G30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('G33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
      }

      // SERVICIOS POR PROVINCIAS
      worksheet.getCell('B38').value = "SERVICIOS POR PROVINCIAS";
      this.setBackground(worksheet, 'B38', 'ffffff');
      this.setBorder(worksheet, 'B38', 'medium');

      let index = 0;
      for(let item in row.localizaciones) {
        worksheet.getCell('B' + (39 + index)).value = item;
        worksheet.getCell('C' + (39 + index)).value = row.localizaciones[item];
        worksheet.getCell('D' + (39 + index)).value = {formula: 'C' + (39 + index) + '*100%/C26'};

        this.setBackground(worksheet, 'B' + (39 + index), 'ffffff');
        this.setBackground(worksheet, 'C' + (39 + index), 'ffffff');
        this.setBackground(worksheet, 'D' + (39 + index), 'ffffff');
        this.setBorder(worksheet, 'B' + (39 + index), 'thin');
        this.setBorder(worksheet, 'C' + (39 + index), 'thin');
        this.setBorder(worksheet, 'D' + (39 + index), 'thin');
        worksheet.getCell('C' + (39 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (39 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (39 + index)).numFmt = '0.00%';

        index++;
      }

    }


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-BOREA' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  async continuarExportarPETS() {
    function colName(n) {
      var ordA = 'A'.charCodeAt(0);
      var ordZ = 'Z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
    
      var s = "";
      while(n >= 0) {
          s = String.fromCharCode(n % len + ordA) + s;
          n = Math.floor(n / len) - 1;
      }
      return s;
    }


    let workbook = new ExcelJS.Workbook();

    for await(let row of this.rows) {
        
      let worksheet = workbook.addWorksheet(row.mes + "-" + row.anyo);

      /*worksheet.getColumn(1).width = 13;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;*/

      worksheet.getColumn(2).width = 30;
      for(let j = 3; j < 21; j++) {
        worksheet.getColumn(j).width = 13;
      }

      let logo = workbook.addImage({
        base64: logoPets.logoBase64,
        extension: 'png',
      });
      var image = worksheet.addImage(logo, {
        tl: { col: 0.5, row: 1 },
        ext: { width: 400, height: 90 }
      });

      let canvasServicios = await html2canvas(document.querySelector("#graficaServicios" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaServicios' + row.id).style.opacity = '1';
        }
      });
      const graficaServicios = canvasServicios.toDataURL("image/png");

      let wkGraficaServicios = workbook.addImage({
        base64: graficaServicios,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaServicios, {
        tl: { col: 10, row: 3 },
        ext: { width: 500, height: 400 }
      });

      let canvasLocalizaciones = await html2canvas(document.querySelector("#graficaLocalizaciones" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaLocalizaciones' + row.id).style.opacity = '1';
        }
      });
      const graficaLocalizaciones = canvasLocalizaciones.toDataURL("image/png");

      let wkGraficaLocalizaciones = workbook.addImage({
        base64: graficaLocalizaciones,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaLocalizaciones, {
        tl: { col: 2, row: 54 },
        ext: { width: 1400, height: 400 }
      });


      for(let i = 0; i < 80; i++) {
        for(let j = 0; j < 100; j++) {
          this.setBackground(worksheet, colName(i) + (1 + j), 'F4CECE');
        }
      }

      const cell = worksheet.getCell('F2');
      cell.value = 'Informe - Servicios ' + this.servicio;
      cell.font = { size: 16, bold: true };

      worksheet.getCell('F4').value = 'Fecha Informe: ';
      worksheet.getCell('F4').font = { bold: true };
      worksheet.getCell('G4').value = moment().format("DD/MM/YYYY HH:mm");

      worksheet.getCell('B8').value = 'TODOS LOS LEADS';
      worksheet.getCell('B8').font = { bold: true };
      this.setBackground(worksheet, 'B8', '00efff');
      this.setBorder(worksheet, 'B8', 'medium');
      
      worksheet.getCell('C8').value = row.leads.total;
      worksheet.getCell('C8').font = { bold: true };
      worksheet.getCell('C8').alignment = { horizontal: 'center' };
      worksheet.mergeCells('C8:G8');
      this.setBackground(worksheet, 'C8', '00efff');
      this.setBorder(worksheet, 'C8', 'medium');

      let addLeads = function (row, title, value, bg) {
        worksheet.getCell('B' + row).value = title;
        this.setBackground(worksheet, 'B' + row, bg);
        this.setBorder(worksheet, 'B' + row, 'thin');
        worksheet.getCell('C' + row).value = value;
        this.setBackground(worksheet, 'C' + row, bg);
        this.setBorder(worksheet, 'C' + row, 'thin');
        worksheet.getCell('C' + row).alignment = { horizontal: 'center' };
        worksheet.getCell('D' + row).value = {formula: 'C' + row + '*100%/C8'};
        worksheet.getCell('D' + row).numFmt = '0.00%';
        this.setBackground(worksheet, 'D' + row, bg);
        this.setBorder(worksheet, 'D' + row, 'thin');
        worksheet.getCell('D' + row).alignment = { horizontal: 'center' };
      }.bind(this);

      addLeads(9, 'NO CONTACTABLES', row.leads.NOCONT, 'ff6e6e');
      addLeads(10, 'SIN INTERÉS', row.leads.INTERES, 'ff6e6e');
      addLeads(11, 'DATOS FICTICIOS', row.leads.FICTICIO, 'ff6e6e');
      addLeads(12, 'LLAMADA ERRÓNEA', row.leads.ERROR, 'ff6e6e');
      addLeads(13, 'COMPETENCIA', row.leads.COM_PRO + row.leads.COM_PRE + row.leads.COM_LEN + row.leads.COM_ASE, 'ffa500');
      addLeads(14, 'OTRA RAZÓN', row.leads.OTRO, 'ffa500');
      addLeads(15, 'FANTASMA', row.leads.FANTASMA, 'ffa500');
      addLeads(16, 'LEAD TIENE SEGURO DECESOS', row.leads.SEGURO, 'ffa500');
      addLeads(17, 'SEG.DEC.(PP) OFRECIDO Y PERDIDO', row.leads.PP, 'ffa500');
      addLeads(18, 'PR.ÚN.(PU) OFRECIDA Y PERDIDO', row.leads.PU, 'ffa500');
      addLeads(19, 'FALTA DE COBERTURA GEOGRÁFICA', row.leads.SIN_GEO, 'ffa500');
      addLeads(20, 'FALTA SERVICIO', row.leads.SIN_SERV, 'ffa500');
      addLeads(21, 'QUIERE PLANIFICACIÓN', row.leads.SIN_PLAN, 'ffa500');
      addLeads(22, 'EN PROCESO CONTACTAR', row.leads.PENDIENTE, 'fd00ff');
      addLeads(23, 'PENDIENTE PRESUPUESTAR', row.leads.PRESUPUESTAR, 'fd00ff');
      addLeads(24, 'PENDIENTE LLAMADA CLIENTE', row.leads.LLAMADA, 'fd00ff');
      addLeads(25, 'PRESUP. FIRMADO (Cli.Ganado)', row.leads.ACEPTADA, '00AAFF');
      addLeads(26, 'SERVICIO REALIZADO', row.leads.COMPLETADA, '00ff00');
      worksheet.getCell('B26').font = { bold: true };
      worksheet.getCell('C26').font = { bold: true };
      worksheet.getCell('D26').font = { bold: true };

      worksheet.getCell('E9').value = { formula: 'SUM(C9:C12)'};
      this.setBackground(worksheet, 'E9', 'ffffff');
      this.setBorder(worksheet, 'E9', 'medium');
      worksheet.getCell('E9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E9:E12');
      worksheet.getCell('F9').value = { formula: 'SUM(D9:D12)'};
      worksheet.getCell('F9').numFmt = '0.00%';
      this.setBackground(worksheet, 'F9', 'ffffff');
      this.setBorder(worksheet, 'F9', 'medium');
      worksheet.getCell('F9').font = { bold: true, color: { argb: 'FF0000' }, };
      worksheet.getCell('F9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F9:F12');
      worksheet.getCell('G9').value = "LEADS NO VÁLIDOS";
      this.setBackground(worksheet, 'G9', 'ffffff');
      this.setBorder(worksheet, 'G9', 'medium');
      worksheet.getCell('G9').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G9:G12');

      worksheet.getCell('E13').value = { formula: 'SUM(C13:C26)'};
      this.setBackground(worksheet, 'E13', 'ffffff');
      this.setBorder(worksheet, 'E13', 'medium');
      worksheet.getCell('E13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E13:E26');
      worksheet.getCell('F13').value = { formula: 'SUM(D13:D26)'};
      worksheet.getCell('F13').numFmt = '0.00%';
      this.setBackground(worksheet, 'F13', 'ffffff');
      this.setBorder(worksheet, 'F13', 'medium');
      worksheet.getCell('F13').font = { bold: true };
      worksheet.getCell('F13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F13:F26');
      worksheet.getCell('G13').value = "SERVICIOS PERDIDOS";
      this.setBackground(worksheet, 'G13', 'ffffff');
      this.setBorder(worksheet, 'G13', 'medium');
      worksheet.getCell('G13').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G13:G21');
      worksheet.getCell('G22').value = "POTENCIALES CLIENTES";
      this.setBackground(worksheet, 'G22', 'ffffff');
      this.setBorder(worksheet, 'G22', 'medium');
      worksheet.getCell('G22').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G22:G25');
      worksheet.getCell('G26').value = "CLIENTES";
      this.setBackground(worksheet, 'G26', 'ffffff');
      this.setBorder(worksheet, 'G26', 'medium');
      worksheet.getCell('G26').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      
      worksheet.getCell('H11').value = "RATIO DE CONVERSIÓN";
      this.setBackground(worksheet, 'H11', 'ffffff');
      this.setBorder(worksheet, 'H11', 'medium');
      worksheet.getCell('H11').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('H11:H12');
      worksheet.getCell('H13').value = { formula: 'C26*100/(E13-C16-C17-C18-C14)'};
      //worksheet.getCell('H13').numFmt = '0,00';
      this.setBackground(worksheet, 'H13', 'ffffff');
      this.setBorder(worksheet, 'H13', 'medium');
      worksheet.getCell('H13').font = { bold: true };
      worksheet.getCell('H13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('H13:H26');

      worksheet.getCell('B28').value = "INVERSIÓN GOOGLE Y BING";
      this.setBackground(worksheet, 'B28', 'ffffff');
      this.setBorder(worksheet, 'B28', 'medium');
      worksheet.getCell('C28').value = row.inversion;
      this.setBackground(worksheet, 'C28', 'ffffff');
      this.setBorder(worksheet, 'C28', 'medium');
      worksheet.getCell('C28').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C28').numFmt = '#,##0.00"€"';

      worksheet.getCell('E28').value = "TICKET MEDIO";
      this.setBackground(worksheet, 'E28', 'ffffff');
      this.setBorder(worksheet, 'E28', 'medium');
      worksheet.getCell('F28').value = row.ticket;
      this.setBackground(worksheet, 'F28', 'ffffff');
      this.setBorder(worksheet, 'F28', 'medium');
      worksheet.getCell('F28').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('F28').numFmt = '#,##0.00"€"';

      // SERVICIOS
      {
        worksheet.getCell('B30').value = "Cremaciones individuales";
        worksheet.getCell('C30').value = { formula: 'D30*100%/(D30+D31)'};
        worksheet.getCell('C30').numFmt = '0.00%';
        worksheet.getCell('C30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D30').value = row.servicios.PETS_CREMA_INDIV;
        worksheet.getCell('D30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B31').value = "Cremaciones colectivas";
        worksheet.getCell('C31').value = { formula: 'D31*100%/(D30+D31)'};
        worksheet.getCell('C31').numFmt = '0.00%';
        worksheet.getCell('C31').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D31').value = row.servicios.PETS_CREMA_COLECT;
        worksheet.getCell('D31').alignment = { vertical: 'middle', horizontal: 'center' };
        
      }
      {
        this.setBackground(worksheet, 'B30', 'ffffff');
        this.setBackground(worksheet, 'B31', 'ffffff');
        this.setBackground(worksheet, 'C30', 'ffffff');
        this.setBackground(worksheet, 'C31', 'ffffff');
        this.setBackground(worksheet, 'D30', 'ffffff');
        this.setBackground(worksheet, 'D31', 'ffffff');

        worksheet.getCell('B30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B31').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('C30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C31').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('D30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('D31').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'medium'} };
      }

      // SERVICIOS POR PROVINCIAS
      worksheet.getCell('B33').value = "SERVICIOS POR PROVINCIAS";
      this.setBackground(worksheet, 'B33', 'ffffff');
      this.setBorder(worksheet, 'B33', 'medium');

      let index = 0;
      for(let item in row.localizaciones) {
        worksheet.getCell('B' + (34 + index)).value = item;
        worksheet.getCell('C' + (34 + index)).value = row.localizaciones[item];
        worksheet.getCell('D' + (34 + index)).value = {formula: 'C' + (34 + index) + '*100%/C26'};

        this.setBackground(worksheet, 'B' + (34 + index), 'ffffff');
        this.setBackground(worksheet, 'C' + (34 + index), 'ffffff');
        this.setBackground(worksheet, 'D' + (34 + index), 'ffffff');
        this.setBorder(worksheet, 'B' + (34 + index), 'thin');
        this.setBorder(worksheet, 'C' + (34 + index), 'thin');
        this.setBorder(worksheet, 'D' + (34 + index), 'thin');
        worksheet.getCell('C' + (34 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (34 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (34 + index)).numFmt = '0.00%';

        index++;
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-PETS' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  async continuarExportarWFN() {
    function colName(n) {
      var ordA = 'A'.charCodeAt(0);
      var ordZ = 'Z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
    
      var s = "";
      while(n >= 0) {
          s = String.fromCharCode(n % len + ordA) + s;
          n = Math.floor(n / len) - 1;
      }
      return s;
    }


    let workbook = new ExcelJS.Workbook();

    for await (let row of this.rows) {
        

      let worksheet = workbook.addWorksheet(row.mes + "-" + row.anyo);

      /*worksheet.getColumn(1).width = 13;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;*/

      worksheet.getColumn(2).width = 30;
      for(let j = 3; j < 21; j++) {
        worksheet.getColumn(j).width = 13;
      }

      let logo = workbook.addImage({
        base64: logoFile.logoBase64,
        extension: 'png',
      });
      var image = worksheet.addImage(logo, {
        tl: { col: 0.5, row: 1 },
        ext: { width: 500, height: 90 }
      });

      let canvasEntierros = await html2canvas(document.querySelector("#graficaEntierros" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaEntierros' + row.id).style.opacity = '1';
        }
      });
      const graficaEntierros = canvasEntierros.toDataURL("image/png");

      let wkGraficaEntierros = workbook.addImage({
        base64: graficaEntierros,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaEntierros, {
        tl: { col: 10, row: 3 },
        ext: { width: 500, height: 400 }
      });

      let canvasCremaciones = await html2canvas(document.querySelector("#graficaCremaciones" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaCremaciones' + row.id).style.opacity = '1';
        }
      });
      const graficaCremaciones = canvasCremaciones.toDataURL("image/png");

      let wkGraficaCremaciones = workbook.addImage({
        base64: graficaCremaciones,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaCremaciones, {
        tl: { col: 16, row: 2 },
        ext: { width: 500, height: 400 }
      });

      let canvasServicios = await html2canvas(document.querySelector("#graficaServicios" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaServicios' + row.id).style.opacity = '1';
        }
      });
      const graficaServicios = canvasServicios.toDataURL("image/png");

      let wkGraficaServicios = workbook.addImage({
        base64: graficaServicios,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaServicios, {
        tl: { col: 10, row: 26 },
        ext: { width: 500, height: 400 }
      });

      let canvasLocalizaciones = await html2canvas(document.querySelector("#graficaLocalizaciones" + row.id), {
        onclone: function (clonedDoc) {
            clonedDoc.getElementById('graficaLocalizaciones' + row.id).style.opacity = '1';
        }
      });
      const graficaLocalizaciones = canvasLocalizaciones.toDataURL("image/png");

      let wkGraficaLocalizaciones = workbook.addImage({
        base64: graficaLocalizaciones,
        extension: 'png',
      });

      worksheet.addImage(wkGraficaLocalizaciones, {
        tl: { col: 2, row: 58 },
        ext: { width: 1400, height: 400 }
      });


      for(let i = 0; i < 80; i++) {
        for(let j = 0; j < 100; j++) {
          this.setBackground(worksheet, colName(i) + (1 + j), 'b8c4ce');
        }
      }

      const cell = worksheet.getCell('F2');
      cell.value = 'Informe - Servicios ' + this.servicio;
      cell.font = { size: 16, bold: true };

      worksheet.getCell('F4').value = 'Fecha Informe: ';
      worksheet.getCell('F4').font = { bold: true };
      worksheet.getCell('G4').value = moment().format("DD/MM/YYYY HH:mm");

      worksheet.getCell('B8').value = 'TODOS LOS LEADS';
      worksheet.getCell('B8').font = { bold: true };
      this.setBackground(worksheet, 'B8', '00efff');
      this.setBorder(worksheet, 'B8', 'medium');
      
      worksheet.getCell('C8').value = row.leads.total;
      worksheet.getCell('C8').font = { bold: true };
      worksheet.getCell('C8').alignment = { horizontal: 'center' };
      worksheet.mergeCells('C8:G8');
      this.setBackground(worksheet, 'C8', '00efff');
      this.setBorder(worksheet, 'C8', 'medium');

      let addLeads = function (row, title, value, bg) {
        worksheet.getCell('B' + row).value = title;
        this.setBackground(worksheet, 'B' + row, bg);
        this.setBorder(worksheet, 'B' + row, 'thin');
        worksheet.getCell('C' + row).value = value;
        this.setBackground(worksheet, 'C' + row, bg);
        this.setBorder(worksheet, 'C' + row, 'thin');
        worksheet.getCell('C' + row).alignment = { horizontal: 'center' };
        worksheet.getCell('D' + row).value = {formula: 'C' + row + '*100%/C8'};
        worksheet.getCell('D' + row).numFmt = '0.00%';
        this.setBackground(worksheet, 'D' + row, bg);
        this.setBorder(worksheet, 'D' + row, 'thin');
        worksheet.getCell('D' + row).alignment = { horizontal: 'center' };
      }.bind(this);

      addLeads(9, 'NO CONTACTABLES', row.leads.NOCONT, 'ff6e6e');
      addLeads(10, 'SIN INTERÉS', row.leads.INTERES, 'ff6e6e');
      addLeads(11, 'DATOS FICTICIOS', row.leads.FICTICIO, 'ff6e6e');
      addLeads(12, 'LLAMADA ERRÓNEA', row.leads.ERROR, 'ff6e6e');
      addLeads(13, 'COMPETENCIA', row.leads.COM_PRO + row.leads.COM_PRE + row.leads.COM_LEN + row.leads.COM_ASE, 'ffa500');
      addLeads(14, 'OTRA RAZÓN', row.leads.OTRO, 'ffa500');
      addLeads(15, 'FANTASMA', row.leads.FANTASMA, 'ffa500');
      addLeads(16, 'LEAD TIENE SEGURO DECESOS', row.leads.SEGURO, 'ffa500');
      addLeads(17, 'SEG.DEC.(PP) OFRECIDO Y PERDIDO', row.leads.PP, 'ffa500');
      addLeads(18, 'PR.ÚN.(PU) OFRECIDA Y PERDIDO', row.leads.PU, 'ffa500');
      addLeads(19, 'FALTA DE COBERTURA GEOGRÁFICA', row.leads.SIN_GEO, 'ffa500');
      addLeads(20, 'FALTA SERVICIO', row.leads.SIN_SERV, 'ffa500');
      addLeads(21, 'QUIERE PLANIFICACIÓN', row.leads.SIN_PLAN, 'ffa500');
      addLeads(22, 'PENDIENTE ACEPTACIÓN', row.leads.PENDIENTE, 'fd00ff');
      addLeads(23, 'ACEPTADA', row.leads.ACEPTADA, '00AAFF');
      addLeads(24, 'SERVICIO REALIZADO', row.leads.COMPLETADA, '00ff00');
      worksheet.getCell('B24').font = { bold: true };
      worksheet.getCell('C24').font = { bold: true };
      worksheet.getCell('D24').font = { bold: true };

      worksheet.getCell('E9').value = { formula: 'SUM(C9:C12)'};
      this.setBackground(worksheet, 'E9', 'ffffff');
      this.setBorder(worksheet, 'E9', 'medium');
      worksheet.getCell('E9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E9:E12');
      worksheet.getCell('F9').value = { formula: 'SUM(D9:D12)'};
      worksheet.getCell('F9').numFmt = '0.00%';
      this.setBackground(worksheet, 'F9', 'ffffff');
      this.setBorder(worksheet, 'F9', 'medium');
      worksheet.getCell('F9').font = { bold: true, color: { argb: 'FF0000' }, };
      worksheet.getCell('F9').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F9:F12');
      worksheet.getCell('G9').value = "LEADS NO VÁLIDOS";
      this.setBackground(worksheet, 'G9', 'ffffff');
      this.setBorder(worksheet, 'G9', 'medium');
      worksheet.getCell('G9').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G9:G12');

      worksheet.getCell('E13').value = { formula: 'SUM(C13:C24)'};
      this.setBackground(worksheet, 'E13', 'ffffff');
      this.setBorder(worksheet, 'E13', 'medium');
      worksheet.getCell('E13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('E13:E24');
      worksheet.getCell('F13').value = { formula: 'SUM(D13:D26)'};
      worksheet.getCell('F13').numFmt = '0.00%';
      this.setBackground(worksheet, 'F13', 'ffffff');
      this.setBorder(worksheet, 'F13', 'medium');
      worksheet.getCell('F13').font = { bold: true };
      worksheet.getCell('F13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('F13:F24');
      worksheet.getCell('G13').value = "SERVICIOS PERDIDOS";
      this.setBackground(worksheet, 'G13', 'ffffff');
      this.setBorder(worksheet, 'G13', 'medium');
      worksheet.getCell('G13').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G13:G21');
      worksheet.getCell('G22').value = "POTENCIALES CLIENTES";
      this.setBackground(worksheet, 'G22', 'ffffff');
      this.setBorder(worksheet, 'G22', 'medium');
      worksheet.getCell('G22').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('G22:G23');
      worksheet.getCell('G24').value = "CLIENTES";
      this.setBackground(worksheet, 'G24', 'ffffff');
      this.setBorder(worksheet, 'G24', 'medium');
      worksheet.getCell('G24').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      
      worksheet.getCell('H11').value = "RATIO DE CONVERSIÓN";
      this.setBackground(worksheet, 'H11', 'ffffff');
      this.setBorder(worksheet, 'H11', 'medium');
      worksheet.getCell('H11').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.mergeCells('H11:H12');
      worksheet.getCell('H13').value = { formula: 'C24*100/(E13-C16-C17-C18-C14)'};
      //worksheet.getCell('H13').numFmt = '0,00';
      this.setBackground(worksheet, 'H13', 'ffffff');
      this.setBorder(worksheet, 'H13', 'medium');
      worksheet.getCell('H13').font = { bold: true };
      worksheet.getCell('H13').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('H13:H24');

      worksheet.getCell('B26').value = "INVERSIÓN GOOGLE Y BING";
      this.setBackground(worksheet, 'B26', 'ffffff');
      this.setBorder(worksheet, 'B26', 'medium');
      worksheet.getCell('C26').value = row.inversion;
      this.setBackground(worksheet, 'C26', 'ffffff');
      this.setBorder(worksheet, 'C26', 'medium');
      worksheet.getCell('C26').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C26').numFmt = '#,##0.00"€"';

      worksheet.getCell('E26').value = "TICKET MEDIO";
      this.setBackground(worksheet, 'E26', 'ffffff');
      this.setBorder(worksheet, 'E26', 'medium');
      worksheet.getCell('F26').value = row.ticket;
      this.setBackground(worksheet, 'F26', 'ffffff');
      this.setBorder(worksheet, 'F26', 'medium');
      worksheet.getCell('F26').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('F26').numFmt = '#,##0.00"€"';

      // SERVICIOS
      {
        worksheet.getCell('B30').value = "Cremaciones directas";
        worksheet.getCell('C30').value = { formula: 'D30*100%/F30'};
        worksheet.getCell('C30').numFmt = '0.00%';
        worksheet.getCell('C30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D30').value = row.servicios.WFN_PACK1_CREMA;
        worksheet.getCell('D30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B31').value = "Cremación con velación";
        worksheet.getCell('C31').value = { formula: 'D31*100%/F30'};
        worksheet.getCell('C31').numFmt = '0.00%';
        worksheet.getCell('C31').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D31').value = row.servicios.WFN_PACK2_CREMA;
        worksheet.getCell('D31').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B32').value = "Cremación con velación y serv. religioso";
        worksheet.getCell('C32').value = { formula: 'D32*100%/F30'};
        worksheet.getCell('C32').numFmt = '0.00%';
        worksheet.getCell('C32').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D32').value = row.servicios.WFN_PACK3_CREMA;
        worksheet.getCell('D32').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B33').value = "Entierros directos";
        worksheet.getCell('C33').value = { formula: 'D33*100%/F30'};
        worksheet.getCell('C33').numFmt = '0.00%';
        worksheet.getCell('C33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D33').value = row.servicios.WFN_PACK1_ENTIERRO;
        worksheet.getCell('D33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B34').value = "Entierros con velación";
        worksheet.getCell('C34').value = { formula: 'D34*100%/F30'};
        worksheet.getCell('C34').numFmt = '0.00%';
        worksheet.getCell('C34').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D34').value = row.servicios.WFN_PACK2_ENTIERRO;
        worksheet.getCell('D34').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B35').value = "Entierros con velación y serv. religioso";
        worksheet.getCell('C35').value = { formula: 'D35*100%/F30'};
        worksheet.getCell('C35').numFmt = '0.00%';
        worksheet.getCell('C35').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D35').value = row.servicios.WFN_PACK3_ENTIERRO;
        worksheet.getCell('D35').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B36').value = "Traslados";
        worksheet.getCell('C36').value = "";
        worksheet.getCell('D36').value = row.servicios.WFN_TRASLADO;
        worksheet.getCell('D36').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B37').value = "Repatriaciones";
        worksheet.getCell('C37').value = "";
        worksheet.getCell('D37').value = row.servicios.WFN_REPATRIACION;
        worksheet.getCell('D37').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell('E30').value = "Cremaciones";
        worksheet.getCell('E30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('E30:E32');
        worksheet.getCell('F30').value = { formula: 'SUM(D30:D32)'};
        worksheet.getCell('F30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('F30:F32');
        worksheet.getCell('G30').value = { formula: '100%*F30/SUM(F30:F35)'};
        worksheet.getCell('G30').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G30').numFmt = '0.00%';
        worksheet.mergeCells('G30:G32');

        worksheet.getCell('E33').value = "Entierros";
        worksheet.getCell('E33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('E33:E35');
        worksheet.getCell('F33').value = { formula: 'SUM(D33:D35)'};
        worksheet.getCell('F33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('F33:F35');
        worksheet.getCell('G33').value = { formula: '100%*F33/SUM(F30:F35)'};
        worksheet.getCell('G33').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G33').numFmt = '0.00%';
        worksheet.mergeCells('G33:G35');
      }
      {
        this.setBackground(worksheet, 'B30', 'ffffff');
        this.setBackground(worksheet, 'B31', 'ffffff');
        this.setBackground(worksheet, 'B32', 'ffffff');
        this.setBackground(worksheet, 'B33', 'ffffff');
        this.setBackground(worksheet, 'B34', 'ffffff');
        this.setBackground(worksheet, 'B35', 'ffffff');
        this.setBackground(worksheet, 'B36', 'ffffff');
        this.setBackground(worksheet, 'B37', 'ffffff');
        this.setBackground(worksheet, 'C30', 'ffffff');
        this.setBackground(worksheet, 'C31', 'ffffff');
        this.setBackground(worksheet, 'C32', 'ffffff');
        this.setBackground(worksheet, 'C33', 'ffffff');
        this.setBackground(worksheet, 'C34', 'ffffff');
        this.setBackground(worksheet, 'C35', 'ffffff');
        this.setBackground(worksheet, 'C36', 'ffffff');
        this.setBackground(worksheet, 'C37', 'ffffff');
        this.setBackground(worksheet, 'D30', 'ffffff');
        this.setBackground(worksheet, 'D31', 'ffffff');
        this.setBackground(worksheet, 'D32', 'ffffff');
        this.setBackground(worksheet, 'D33', 'ffffff');
        this.setBackground(worksheet, 'D34', 'ffffff');
        this.setBackground(worksheet, 'D35', 'ffffff');
        this.setBackground(worksheet, 'D36', 'ffffff');
        this.setBackground(worksheet, 'D37', 'ffffff');
        this.setBackground(worksheet, 'E30', 'ffffff');
        this.setBackground(worksheet, 'E33', 'ffffff');
        this.setBackground(worksheet, 'F30', 'ffffff');
        this.setBackground(worksheet, 'F33', 'ffffff');
        this.setBackground(worksheet, 'G30', 'ffffff');
        this.setBackground(worksheet, 'G33', 'ffffff');

        worksheet.getCell('B30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B31').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B32').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B34').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('B35').border = { top: {style:'thin'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B36').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('B37').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('C30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C31').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C32').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C33').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C34').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        worksheet.getCell('C35').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C36').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        worksheet.getCell('C37').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'thin'} };
        
        worksheet.getCell('D30').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D31').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D32').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D33').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D34').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D35').border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'medium'} };
        worksheet.getCell('D36').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('D37').border = { top: {style:'medium'}, left: {style:'thin'}, bottom: {style:'medium'}, right: {style:'medium'} };
        
        worksheet.getCell('E30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('E33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('F30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('F33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('G30').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
        worksheet.getCell('G33').border = { top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'} };
      }

      // SERVICIOS POR PROVINCIAS
      worksheet.getCell('B39').value = "SERVICIOS POR PROVINCIAS";
      this.setBackground(worksheet, 'B39', 'ffffff');
      this.setBorder(worksheet, 'B39', 'medium');

      let index = 0;
      for(let item in row.localizaciones) {
        worksheet.getCell('B' + (40 + index)).value = item;
        worksheet.getCell('C' + (40 + index)).value = row.localizaciones[item];
        worksheet.getCell('D' + (40 + index)).value = {formula: 'C' + (40 + index) + '*100%/C24'};

        this.setBackground(worksheet, 'B' + (40 + index), 'ffffff');
        this.setBackground(worksheet, 'C' + (40 + index), 'ffffff');
        this.setBackground(worksheet, 'D' + (40 + index), 'ffffff');
        this.setBorder(worksheet, 'B' + (40 + index), 'thin');
        this.setBorder(worksheet, 'C' + (40 + index), 'thin');
        this.setBorder(worksheet, 'D' + (40 + index), 'thin');
        worksheet.getCell('C' + (40 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (40 + index)).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D' + (40 + index)).numFmt = '0.00%';

        index++;
      }

    }


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-B2B' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  // GRAFICAS
  customizeLabel(arg) {
    return `${arg.argumentText}  (${arg.percentText})`;
  }

}
