import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { LocalizacionService, AuthenticationService, StateService, LicitaService, UserService, NotificacionService, CertificadoService, PrecioService, SolicitudService, CentroService, B2bService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { TranslationService } from '../../../core/_base/layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxHtmlEditorComponent } from 'devextreme-angular';

declare function initDropdown(): any;
/**
 * Componente que contiene el proceso de presupuestar servicios de B2B.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-admin-b2b-presupuesto',
  templateUrl: './admin-b2b-presupuesto.component.html',
  styleUrls: ['./admin-b2b-presupuesto.component.scss']
})
export class AdminB2bPresupuestoComponent implements OnInit {

  mapbox = (mapboxgl as typeof mapboxgl);    

  /** Variables globales */
  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('locSwal', {static: false}) private locSwal: SwalComponent
  @ViewChild('pesoSwal', {static: false}) private pesoSwal: SwalComponent
  @ViewChild('emailSwal', {static: false}) private emailSwal: SwalComponent
  @ViewChild('content9', {static: false}) private modalNegocio: NgbModal
  @ViewChild('content10', {static: false}) private modalPaquete: NgbModal
  @ViewChild('content8', {static: false}) private modalCentro: NgbModal

  currentUser: any;
  solicitudId = null;
  solicitudObj = null;

  objectId = null;
  dealId = null;

  negocios = [];
  allNegocios = [];
  negocioObj = null;
  proveedores = [];
  proveedoresHash = {};

  tanatorios = [];
  crematorios = [];
  centros = [];
  allCentros = [];
  loadingCentros = false;
  tanatorio = null;
  crematorio = null;
  
  loading = false;
  step = 1;
  version = null;

  wiz1Form: FormGroup;

  vista = "presupuesto";
  idGeocoder = "geocoder";
  logo = "WFN";

  geocoderObj;

	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private ngZone: NgZone,
    private location: Location,
    private userService: UserService,
    private notificacionService: NotificacionService,
    private centroService: CentroService,
    private precioService: PrecioService,
		private translate: TranslateService,
		private translationService: TranslationService,
    private licitaService: LicitaService,
    private localizacionService: LocalizacionService,
    private b2bService: B2bService,
    private locationSt: LocationStrategy,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      history.pushState(null, null, window.location.href);

      // check if back or forward button is pressed.
      this.locationSt.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
	  }

  /**
   * Método que se lanza en el inicio del componente y carga los formularios y los maestros necesarios.
   * Comprueba si hay login y si hay licitación abierta.
   */
	async ngOnInit() {
    console.warn("<<<<<<<<INIT>>>>>>>>");

    this.idGeocoder = "geocoder" + moment().unix();
    this.currentUser = this.authenticationService.currentUserValue;

    this.proveedores = await this.userService.getB2BPres();
    for(let item of this.proveedores) {
      this.proveedoresHash[item.b2b_cliente + "-" + item.b2b_tipo] = item;
    }
    this.cdr.detectChanges();


    const geocoder = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: "ES",
      types: 'place, postcode'
    });
      
    const geocoder2 = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: "ES",
      types: 'place, postcode'
    });

    setTimeout(function(){
      geocoder.addTo('#' + this.idGeocoder);
      geocoder2.addTo('#geocoder2');
    }.bind(this), 1000);      

    // Add geocoder result to container.
    geocoder.on('result', (e) => {
      console.warn(e);
      this.paso2.localizacion = e.result.place_name_es;
      this.paso2.cp = null;
      this.paso2.lat = e.result.center[1];
      this.paso2.long = e.result.center[0];
      this.tipoServicio = "";
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }

      if(e.result.place_type[0] != "postcode") {
        this.paso2.localidad = e.result.text_es;
        this.userService.getPostalCode(this.paso2.long, this.paso2.lat)
        .subscribe(res => {
          console.warn(res);
          if(res.features && res.features.length > 0 ) {
            this.paso2.cp = res.features[0].text_es;

            this.localizacionService.getByCP(this.paso2.cp)
            .subscribe(loc => {
              if(loc.length > 0) {
                this.localizacionObj = loc[0];
                this.loadCentros();
                if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
                  this.loadPrecios();
                }
              } else {
                this.errorSwal.fire();
                this.paso2.localizacion = null;
                this.paso2.cp = null;
                this.paso2.lat = null;
                this.paso2.long = null;
                this.paso2.provincia = null;
                this.paso2.localidad = null;
                geocoder.clear();
              } 

            });
          }
        });
      } else {
        this.paso2.cp = e.result.text_es;
        for(let item of e.result.context) {
          if(item.id.includes("place")) {
            this.paso2.localidad = item.text_es;
          }
        }

        this.localizacionService.getByCP(this.paso2.cp)
          .subscribe(loc => {
            if(loc.length > 0) {
              console.warn(loc);
              this.localizacionObj = loc[0];
              this.loadCentros();
              if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
                this.loadPrecios();
              }
            } else {
              this.errorSwal.fire();
              this.paso2.localizacion = null;
              this.paso2.cp = null;
              this.paso2.lat = null;
              this.paso2.long = null;
              geocoder.clear();
            } 

          });
      }

      for(let item of e.result.context) {
        if(item.id.includes("region")) {
          //this.paso2.provincia = item.text_es;

          if(item.text_es.includes("Álava")) this.paso2.provincia = "Araba"
          else if(item.text_es.includes("Islas Baleares")) this.paso2.provincia = "Baleares"
          else if(item.text_es.includes("La Coruña")) this.paso2.provincia = "A Coruña"
          else if(item.text_es.includes("Gerona")) this.paso2.provincia = "Girona"
          else if(item.text_es.includes("Guipúzcoa")) this.paso2.provincia = "Gipuzkoa"
          else if(item.text_es.includes("Lérida")) this.paso2.provincia = "Lleida"
          else if(item.text_es.includes("Orense")) this.paso2.provincia = "Ourense"
          else if(item.text_es.includes("Vizcaya")) this.paso2.provincia = "Bizkaia"
          else this.paso2.provincia = item.text_es.replace("provincia de ", "");
        }
      }
    });


      
    // Clear results container when search is cleared.
    geocoder.on('clear', () => {
      this.paso2.localizacion = null;
      this.paso2.cp = null;
      this.paso2.provincia = null;
      this.paso2.localidad = null;
      this.paso2.lat = null;
      this.paso2.long = null;
      this.paso2.peso = null;
      this.tipoServicio = "";
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });

    // Add geocoder result to container.
    geocoder2.on('result', (e) => {
      this.paso2.localizacionFallecido = e.result.place_name_es;
      this.paso2.cpFallecido = null;
      this.paso2.latFallecido = e.result.center[1];
      this.paso2.longFallecido = e.result.center[0];
      this.tipoServicio = "";
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }

      if(e.result.place_type[0] != "postcode") {
        this.userService.getPostalCode(this.paso2.longFallecido, this.paso2.latFallecido)
        .subscribe(res => {
          if(res.features && res.features.length > 0 ) {
            this.paso2.cpFallecido = res.features[0].text_es;

            if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
              this.loadPrecios();
            }
          }
        });
      } else {
        this.paso2.cpFallecido = e.result.text_es;

        if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
          this.loadPrecios();
        }
      }
    });
      
      // Clear results container when search is cleared.
    geocoder2.on('clear', () => {
      this.paso2.localizacionFallecido = null;
      this.paso2.cpFallecido = null;
      this.paso2.latFallecido = null;
      this.paso2.longFallecido = null;
      this.tipoServicio = "";
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });

    this.geocoderObj = geocoder;

    this.b2bService.getB2BActivas()
      .subscribe(deals => {
        console.warn(deals);
        for(let deal of deals) {
          deal.fechaStr = moment(deal.fechaEntrada).format("DD/MM/YYYY");
        }
        this.allNegocios = deals;
        this.filterDatatable2();

        this.route.paramMap.subscribe(params => {
          if(params.get("id")) {
            for(let negocio of this.allNegocios) {
              console.warn(negocio.id, params.get("id"));
              if(negocio.id == params.get("id")) {
                setTimeout(function() {
                  this.selectNegocio(negocio);
                }.bind(this), 1000);
              }
            }
          }
    
        });
    });
  }

  ngOnDestroy() {
    console.log("DESTROY");
    this.cdr.detach(); // do this
  }

  changeLogo(logo: any) {
    this.logo = logo;
    this.cdr.detectChanges();
  }

  tipoServicio = null;

  selTipoServicio(tipo: any) {
    if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }

      if(tipo != null) {
        this.tipoServicio = tipo;

        if(this.packSeleccionado) {
          this.loadPrecios();
        } else {
          if(tipo == "REPATRIACION") this.seleccionarPack(1);
        }
      }
  
      this.cdr.detectChanges();
    } else {
      this.locSwal.fire();
    }
  }

  loadCentros() {
    this.loadingCentros = true;
    this.cdr.detectChanges();
    this.tanatorios = [];
    this.crematorios = [];
    this.tanatorio = null;
    this.crematorio = null;
    this.centroService.getB2BByCP(this.localizacionObj.cpDesde, this.localizacionObj.cpHasta)
      .subscribe(centros => {
        
        for(let centro of centros) {
          if(centro.tanatorio) {
            this.tanatorios.push(centro);
          }
          if(centro.crematorio) {
            this.crematorios.push(centro);
          }
        }
        
        this.loadingCentros = false;
        this.cdr.detectChanges();
      });
  }

  loadPrecios() {
    this.precioPersonalizado1 = false;
    this.precioPersonalizado2 = false;
    this.precioPersonalizado3 = false;

    this.preciosHash = {};
    this.preciosObjHash = {};
    this.servicioHash = {};
    this.servicioNombreHash = {};

    //this.loading = true;
    this.cdr.detectChanges();

    this.precioService.getByLocalizacionB2B(this.localizacionObj.id)
    .subscribe(precios => {
      console.warn(precios);
      for(let precio of precios) {
        if(!precio.grupo) {
          this.preciosHash[precio.servicio.abreviatura] = precio.importe

          this.preciosObjHash[precio.servicio.abreviatura] = precio;
          this.servicioHash[precio.servicio.abreviatura] = precio.servicio.id;
          this.servicioNombreHash[precio.servicio.id] = precio.servicio.nombre;
        }
      }

      if(this.tanatorio && this.tanatorio.grupo) {
        for(let precio of precios) {
          if(precio.grupo == this.tanatorio.grupo) {
            this.preciosHash[precio.servicio.abreviatura] = precio.importe;
  
            this.preciosObjHash[precio.servicio.abreviatura] = precio;
            this.servicioHash[precio.servicio.abreviatura] = precio.servicio.id;
            this.servicioNombreHash[precio.servicio.id] = precio.servicio.nombre;
          }
        }
      } else if(!this.tanatorio && this.crematorio && this.crematorio.grupo) {
        for(let precio of precios) {
          if(precio.grupo == this.crematorio.grupo) {
            this.preciosHash[precio.servicio.abreviatura] = precio.importe;
  
            this.preciosObjHash[precio.servicio.abreviatura] = precio;
            this.servicioHash[precio.servicio.abreviatura] = precio.servicio.id;
            this.servicioNombreHash[precio.servicio.id] = precio.servicio.nombre;
          }
        }
      }

      if(this.paso2.traslado == "SI") {
        console.warn(this.paso2);
        this.userService.getRoute(this.paso2.longFallecido, this.paso2.latFallecido, this.paso2.long, this.paso2.lat)
        .subscribe(route => {
          if(route.routes && route.routes.length > 0){
            this.paso2.distancia = route.routes[0].distance / 1000 * 2;
            let tarifaTraslado = 0;
            if(this.paso2.distancia < 100) tarifaTraslado = this.preciosHash["WFN_TRASLADO_0"]
            else if(this.paso2.distancia < 200) tarifaTraslado = this.preciosHash["WFN_TRASLADO_100"]
            else if(this.paso2.distancia < 400) tarifaTraslado = this.preciosHash["WFN_TRASLADO_200"]
            else if(this.paso2.distancia < 600) tarifaTraslado = this.preciosHash["WFN_TRASLADO_400"]
            else if(this.paso2.distancia < 800) tarifaTraslado = this.preciosHash["WFN_TRASLADO_600"]
            else if(this.paso2.distancia < 1000) tarifaTraslado = this.preciosHash["WFN_TRASLADO_800"]
            else if(this.paso2.distancia < 1200) tarifaTraslado = this.preciosHash["WFN_TRASLADO_1000"]
            else if(this.paso2.distancia < 1400) tarifaTraslado = this.preciosHash["WFN_TRASLADO_1200"]
            else if(this.paso2.distancia < 1600) tarifaTraslado = this.preciosHash["WFN_TRASLADO_1400"]
            else if(this.paso2.distancia < 1800) tarifaTraslado = this.preciosHash["WFN_TRASLADO_1600"]
            else tarifaTraslado = this.preciosHash["WFN_TRASLADO_1800"];
            
            this.precioTraslado = this.preciosHash["WFN_TRASLADO"] + tarifaTraslado;
            
            this.totalServicios = 0;
            this.totalEsencial = 0;
            this.totalAdicionales = 0;
            this.totalSuplidos = 0;
            this.cdr.detectChanges();
            //this.siguiente();

          }
        });
      } else {
        this.totalServicios = 0;
        this.totalEsencial = 0;
        this.totalAdicionales = 0;
        this.totalSuplidos = 0;
        this.precioTraslado = 0;
        this.cdr.detectChanges();
        //this.siguiente();
      }

      

      if(this.packSeleccionado && this.tipoServicio == "REPATRIACION") this.seleccionarPack(1)
      else this.packSeleccionado = null;

      
      this.cdr.detectChanges();
    });
  }

  // ------- MODAL CENTROS -------
  tipoModal = "";
  filters = {nombre: "", direccion: "", poblacion: "", cp: "", albia: false}

  openDialog(content, tipo) {
    window.scrollTo(0,1);
    this.modalService.open(this.modalCentro, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
    this.tipoModal = tipo;
    this.filterDatatable();
  }

  selectTanatorio(content) {
    this.allCentros = this.tanatorios;
    this.filterDatatable();
    this.openDialog(content, "tanatorio");
  }

  selectCrematorio(content) {
    this.allCentros = this.crematorios;
    this.filterDatatable();
    this.openDialog(content, "crematorio");
  }

  selectCentro(row: any) {
    if(this.tipoModal == "tanatorio") {
      this.tanatorio = row;
      if(this.tipoServicio == 'CREMACION'){
        if(row.crematorio) this.crematorio = row
        else if(row.centroRelacionado.crematorio) this.crematorio = row.centroRelacionado;
      }
    } else {  // crematorio
      this.crematorio = row;
    }
    this.modalService.dismissAll();
    this.loadPrecios();
  }

  deleteTanatorio() {
    this.tanatorio = null;
    this.loadPrecios();
    this.cdr.detectChanges();
  }

  deleteCrematorio() {
    this.crematorio = null;
    this.loadPrecios();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    this.centros = this.allCentros.filter(function(item){
      var mostrar = true;

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.direccion && this.filters.direccion != null && this.filters.direccion != "") {
        if(!item.direccion.toLowerCase().includes(this.filters.direccion.toLowerCase())) mostrar = false;
      }

      if(this.filters.poblacion && this.filters.poblacion != null && this.filters.poblacion != "") {
        if(!item.poblacion.toLowerCase().includes(this.filters.poblacion.toLowerCase())) mostrar = false;
      }

      if(this.filters.cp && this.filters.cp != null && this.filters.cp != "") {
        if(!(item.cp + "").toLowerCase().includes(this.filters.cp.toLowerCase())) mostrar = false;
      }

      if(this.filters.albia) {
        if(!item.albia || item.grupo) mostrar = false;
      }

      if(this.tipoModal == "crematorio" && this.tanatorio && this.tanatorio.grupo && item.grupo && item.grupo != this.tanatorio.grupo) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
    this.cdr.detectChanges();
  }


  resetFilters() {
    this.filters = {nombre: "", direccion: "", poblacion: "", cp: "", albia: false};
    this.filterDatatable();
  }


  /////////// Modal negocios ////////////

  filters2 = {negocio: "", contacto: "", localizacion: ""}

  openNegocio() {
    window.scrollTo(0,1);
    this.modalService.open(this.modalNegocio, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
    this.filterDatatable2();
  }

  loadingNegocio = false;

  selectNegocio(deal: any) {
    console.warn(deal);

    this.modalService.dismissAll();


    this.paso2.nombre = deal.contactoNombre;
    this.paso2.telefono = deal.contactoTelefono;
    this.paso2.email = deal.contactoMail;
    this.paso2.userCliente = deal.cliente + "-" + deal.origen;
    this.negocioObj = deal;
    this.clienteChanged();

    if(deal.fallecidoLocalidad) {
      this.geocoderObj.query(deal.fallecidoLocalidad + ". " + deal.fallecidoCP + ". " + deal.fallecidoProvincia);
      this.loadingNegocio = false;
      this.cdr.detectChanges();
      this.cdr.detectChanges();
  
      setTimeout(function() {
        $('.mapboxgl-ctrl-geocoder--input').focus();
        setTimeout(function() {
            $('#inputEmail').focus();
  
        }, 1000);
      }, 300);
    }
  
    this.cdr.detectChanges();
    this.cdr.detectChanges();
    
    
  }

  clienteChanged() {
    let cliente = this.proveedoresHash[this.paso2.userCliente];
    this.changeLogo(cliente.b2b_tipo);
  }

  filterDatatable2() {
    this.negocios = this.allNegocios.filter(function(item){
      var mostrar = true;

      if(this.filters2.negocio && this.filters2.negocio != null && this.filters2.negocio != "") {
        if(!item.referencia || !item.referencia.toLowerCase().includes(this.filters2.negocio.toLowerCase())) mostrar = false;
      }

      if(this.filters2.contacto && this.filters2.contacto != null && this.filters2.contacto != "") {
        if(!item.contactoNombre || !(item.contactoNombre + (item.contactoApellido1 ||'')).toLowerCase().includes(this.filters2.contacto.toLowerCase())) mostrar = false;
      }

      if(this.filters2.localizacion && this.filters2.localizacion != null && this.filters2.localizacion != "") {
        if(!item.fallecidoLocalidad || !(item.fallecidoLocalidad + (item.fallecidoProvincia ||'')).toLowerCase().includes(this.filters2.localizacion.toLowerCase())) mostrar = false;
      }
      
      return mostrar;
    }.bind(this));
    
    this.cdr.detectChanges();
  }


  resetFilters2() {
    this.filters2 = {negocio: "", contacto: "", localizacion: ""};
    this.filterDatatable2();
  }


  /* --------------
      INFO SERVICIO
  -------------- */

  paso2 = {userCliente: "", nombre: null, localizacion: null, localidad: null, provincia: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: null, telefono: null, politica: false, firma: "NO", sepa: "NO"} as any;
  //paso2 = {nombre: "Prueba", localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: "borrar@test.com", telefono: "666555666", politica: false} as any;
  paso2_error = {localizacionFallecido: false, userCliente: false} as any;
  vermas = [false, false, false, false]
  paso2_validator: any;

  packSeleccionado = null;
  objEsencial = null;
  preciosHash = {};
  preciosObjHash = {};
  servicioHash = {};
  servicioNombreHash = {};
  precioTraslado = 0;

  localizacionObj = null;

  isControlHasErrorPaso2(controlName: string, validationType: string): boolean {
    if(!this.paso2_validator) return false;

		const control = this.paso2_validator.controls[controlName];
		if (!control) return false;

		if(!validationType) {
			const result = control.invalid;
			return result;
		} else {
			const result = control.hasError(validationType);
			return result;
		}
  }

  seleccionarPack(pack: any) {
    if(this.packSeleccionado != pack && pack != null) {
      this.totalAdicionales = 0;
    }
    if(pack != null) this.packSeleccionado = pack;

    if(this.packSeleccionado == 1) {
      if(this.tipoServicio == 'CREMACION') {
        this.totalEsencial = this.preciosHash['WFN_PACK1_CREMA'];
        this.objEsencial = this.preciosObjHash['WFN_PACK1_CREMA'];
      } else if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFN_PACK1_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFN_PACK1_ENTIERRO'];
      } else if(this.tipoServicio == 'REPATRIACION') {
        this.totalEsencial = this.preciosHash['WFN_REPATRIACION'];
        this.objEsencial = this.preciosObjHash['WFN_REPATRIACION'];
      }
    } else if(this.packSeleccionado == 2) {
      if(this.tipoServicio == 'CREMACION') {
        this.totalEsencial = this.preciosHash['WFN_PACK2_CREMA'];
        this.objEsencial = this.preciosObjHash['WFN_PACK2_CREMA'];
      } else if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFN_PACK2_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFN_PACK2_ENTIERRO'];
      }
    } else if(this.packSeleccionado == 3) {
      if(this.tipoServicio == 'CREMACION') {
        this.totalEsencial = this.preciosHash['WFN_PACK3_CREMA'];
        this.objEsencial = this.preciosObjHash['WFN_PACK3_CREMA'];
      } else if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFN_PACK3_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFN_PACK3_ENTIERRO'];
      }
    }

    this.calculoLineas();

    this.cdr.detectChanges();
  }

  /* --------------
      PAGINA 3
  -------------- */

  detalleServicio = false;

  totalServicios = 0;
  totalEsencial = 0;
  totalAdicionales = 0;
  totalSuplidos = 0;
  //totalBase21 = 0;
  //totalBase10 = 0;
  //totalBase7 = 0;
  totalBases = {};
  

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
   thousands_separators(num)
   {
     if(num){ 
       var num_parts = num.toFixed(2).split(".");
       num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
       if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
       else return num_parts[0];
       
     } else {
       return '0';
     }
     
   }

  cambioTraslado() {
    this.tipoServicio = "";
    this.packSeleccionado = null;
    this.cdr.detectChanges();
  }


  /* ****************************************************************************************** */
  /* ****************************************************************************************** */
  /* ****************************************************************************************** */
  /* ****************************************************************************************** */


  lineasAdicionales = [];
  verAdicionales = false;
  verSuplidos = false;

  cambioAdicionales(valor: any) {
    this.verAdicionales = valor;
    this.cdr.detectChanges();
  }

  cambioSuplidos(valor: any) {
    this.verSuplidos = valor;
    this.cdr.detectChanges();
  }

  addLinea(suplido: any) {
    let impuesto = 0;
    if(!suplido) {
      if(this.objEsencial.impuesto >= 10 || this.objEsencial.impuesto == 0) impuesto = 21
      else if(this.objEsencial.impuesto < 10) impuesto = 3;
    }

    this.lineasAdicionales.push({
      concepto: "",
      importe: null,
      suplido: suplido,
      impuesto: impuesto
    })
    this.cdr.detectChanges();
  }

  borrarLinea(index: any) {
    this.lineasAdicionales.splice(index, 1);
    this.cdr.detectChanges();
    this.calculoLineas();
  }

  calculoLineas() {
    this.totalAdicionales = 0;
    this.totalSuplidos = 0;
    this.totalBases = {};

    this.totalBases[this.objEsencial.impuesto] = this.objEsencial.importe / (this.objEsencial.impuesto / 100 + 1);

    for(let linea of this.lineasAdicionales) {
      if(linea.importe && !linea.suplido) {
        this.totalAdicionales += linea.importe;

        if(!this.totalBases[linea.impuesto]) this.totalBases[linea.impuesto] = 0;
        let multiplicador = 1 + (parseInt(linea.impuesto)/100);
        this.totalBases[linea.impuesto] += linea.importe / multiplicador;
      } else if(linea.importe && linea.suplido) {
        this.totalSuplidos += linea.importe;
      }
    }

    this.totalServicios = this.totalEsencial + this.totalAdicionales + this.totalSuplidos + this.precioTraslado;

    this.cdr.detectChanges();
  }

  // -----------------------------------

  personalizarImporte = 0;
  personalizarImporteTraslado = 0;  
  precioPersonalizado1 = false;
  precioPersonalizado2 = false;
  precioPersonalizado3 = false;

  htmlEditPers = "";

  personalizarPack() {
    console.warn(this.objEsencial);

    this.objEsencial.servicio.detalle = this.objEsencial.servicio.detalle.replaceAll(" -", ">-").replaceAll("<p>", "").replaceAll("</p>", "");
    this.htmlEditPers = this.objEsencial.servicio.detalle;

    this.personalizarImporte = this.totalEsencial;

    if(this.paso2.traslado == "SI") {
      this.personalizarImporteTraslado = parseFloat(this.precioTraslado.toFixed(2));
    }
    
    this.modalService.open(this.modalPaquete, { centered: true, size: 'md' } );
    this.cdr.detectChanges();
  }

  calculoPersonalizado() {
    if(this.packSeleccionado == 1) {
      this.precioPersonalizado1 = true;
      this.preciosHash['WFN_PACK1_ENTIERRO'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK1_ENTIERRO'].importe = this.personalizarImporte;
      this.preciosHash['WFN_PACK1_CREMA'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK1_CREMA'].importe = this.personalizarImporte;
      this.preciosHash['WFN_REPATRIACION'] = this.personalizarImporte;
      this.preciosObjHash['WFN_REPATRIACION'].importe = this.personalizarImporte;
    } else if(this.packSeleccionado == 2) {
      this.precioPersonalizado2 = true;
      this.preciosHash['WFN_PACK2_ENTIERRO'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK2_ENTIERRO'].importe = this.personalizarImporte;
      this.preciosHash['WFN_PACK2_CREMA'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK2_CREMA'].importe = this.personalizarImporte;
    } else if(this.packSeleccionado == 3) {
      this.precioPersonalizado3 = true;
      this.preciosHash['WFN_PACK3_ENTIERRO'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK3_ENTIERRO'].importe = this.personalizarImporte;
      this.preciosHash['WFN_PACK3_CREMA'] = this.personalizarImporte;
      this.preciosObjHash['WFN_PACK3_CREMA'].importe = this.personalizarImporte;
    }

    if(this.paso2.traslado == "SI") {
      this.precioTraslado = this.personalizarImporteTraslado;
    }

    this.seleccionarPack(null);
  }

  htmlChanged2(event) {
    console.warn(event.value);
    this.htmlEditPers = event.value.replace("<p>", "").replaceAll("<p>", "<br>").replaceAll("</p>", "");
  }

  cerrarPersonalizacion() {
    this.modalService.dismissAll();
    this.objEsencial.servicio.detalle = this.htmlEditPers;
  }



  // -----------------------------------

  verNotasAdicionales = false;
  notasAdicionales = null;

  cambioNotasAdicionales(valor: any) {
    this.verNotasAdicionales = valor;
    this.cdr.detectChanges();
  }

  // -----------------------------------

  guardarServicio(servicio: any) {
    this.solicitudService.postServicio(servicio)
    .pipe(first())
    .subscribe(
      data => {
      },
      error => {
        console.error(error);
        this.loading = false;
      });
  }

  async enviarPresupuesto() {
    this.paso2_validator = new FormGroup({
      nombre: new FormControl(this.paso2.nombre, Validators.required),
      //email: new FormControl(this.paso2.email, Validators.compose([Validators.required,Validators.email])),
      telefono: new FormControl(this.paso2.telefono, Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")]))
    });
    
    if(!this.paso2.userCliente || this.paso2.userCliente == "") this.paso2_error.userCliente = true
    else this.paso2_error.userCliente = false;

    if(this.paso2_validator.invalid || this.paso2_error.userCliente) return;

    this.loading = true;

    // Generamos el negocio
    if(!this.negocioObj) { 
      await this.crearNegocio();
    }

    // Obtenemos código
    this.solicitudService.generarCodigo()
    .subscribe(codigo => {
      console.warn(codigo);

      //Guardamos el presupuesto

      var solicitud = {
        telefono: this.paso2.telefono,
        email: this.paso2.email,
        status: "PRESUPUESTO",
        nombre: this.paso2.nombre,
        localizacion: this.paso2.localizacion,
        cp: this.paso2.cp,
        lat: this.paso2.lat,
        long: this.paso2.long,
        traslado: this.paso2.traslado == "SI" ? true : false,
        localizacionFallecido: this.paso2.localizacionFallecido,
        cpFallecido: this.paso2.cpFallecido,
        latFallecido: this.paso2.latFallecido,
        longFallecido: this.paso2.longFallecido,
        distancia: this.paso2.distancia,
        tipo: this.tipoServicio,
        importe: this.totalServicios,
        divisa: "EUR",
        oculta: false,
        enviado: true,
        numero: codigo.numero,
        anyo: parseInt(moment().format("YY")),
        presupuesto: codigo.codigo,
        //base21: this.totalBases[21],
        //base10: this.totalBases[10],
        //base7: this.totalBases[7],
        fecha: new Date(),
        tipoServ: "B2B",
        b2bSolicitud: null,
        
      }
      if(this.negocioObj) solicitud.b2bSolicitud = this.negocioObj.id;

      this.solicitudService.post(solicitud)
      .pipe(first())
      .subscribe(
        data => {
          this.solicitudId = data.id;
          this.solicitudObj = data;
          //this.loading = false;
          this.envioServicios();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    });
  }

  async crearNegocio() {
    let cliente = this.proveedoresHash[this.paso2.userCliente];
    let codigo = await this.b2bService.generarCodigo(cliente.b2b_cliente);
    var nueva = {
      referencia: codigo.codigo,
      numero: codigo.numero,
      anyo: codigo.anyo,
      estado: "PENDIENTE",
      origen: cliente.b2b_tipo, 
      cliente: cliente.b2b_cliente,
      proveedor: cliente.b2b_cliente == "IRIS" ? "ALBIA" : null,
      userCliente: cliente.id,
      tipo: this.tipoServicio,
      fechaEntrada: new Date(),
      creador: this.currentUser.empresa,
      contactoNombre: this.paso2.nombre,
      contactoTelefono: this.paso2.telefono,
      contactoMail: this.paso2.email,
      contactoProvincia: this.paso2.provincia,
      contactoLocalidad: this.paso2.localidad,
      contactoCP: this.paso2.cp,
      fallecidoProvincia: this.paso2.provincia,
      fallecidoLocalidad: this.paso2.localidad,
      fallecidoCP: this.paso2.cp,
      velacionLugar: this.tanatorio ? this.tanatorio.nombre : null,
      velacionLocalidad: this.tanatorio ? this.tanatorio.poblacion : null,
      velacionCP: this.tanatorio ? this.tanatorio.cp : null,
      tanatorio: this.tanatorio ? this.tanatorio.id : null,
      sepelioLugar: this.crematorio ? this.crematorio.nombre : null,
      sepelioLocalidad: this.crematorio ? this.crematorio.poblacion : null,
      sepelioCP: this.crematorio ? this.crematorio.cp : null,
      crematorio: this.crematorio ? this.crematorio.id : null,
      source: "PRESUPUESTO",
      traslado: this.paso2.traslado == "SI" ? true : false,
      serviciosAdd: {
          "velatorio": this.packSeleccionado >= 2 ? true : false,
          "flores": false,
          "repatriacion": false,
          "despedida": this.packSeleccionado == 3 ? true : false
      }
    }

    this.negocioObj = await this.b2bService.postSolicitud(nueva);
  }


  /**
   * Envio de servicios y preparación de objetos para PDF
   */
  envioServicios() {
    let idServicioEsencial = this.objEsencial.servicio.id;
    let servicios = [];

    let objServicioEsencial = {
      servicio: idServicioEsencial,
      solicitud: this.solicitudId,
      importe: this.totalEsencial,
      divisa: "EUR",
      nombre: this.objEsencial.servicio.nombre,
      impuesto: this.objEsencial.impuesto,
      base: this.totalEsencial / (this.objEsencial.impuesto / 100 + 1)
    }
    this.guardarServicio(objServicioEsencial);

    servicios.push({
      nombre: this.objEsencial.servicio.nombre,
      detalle: this.objEsencial.servicio.detalle,
      importe: this.thousands_separators(this.totalEsencial),
      base: this.thousands_separators(this.totalEsencial / (this.objEsencial.impuesto / 100 + 1)),
      iva: this.objEsencial.impuesto
    });

    // Traslado

    //this.preciosObjHash['WFN_TRASLADO']
    if(this.paso2.traslado == "SI") {
      let objTraslado = null;//precio.servicio.id
      if(this.paso2.distancia < 100) objTraslado = this.preciosObjHash["WFN_TRASLADO_0"]
      else if(this.paso2.distancia < 200) objTraslado = this.preciosObjHash["WFN_TRASLADO_100"]
      else if(this.paso2.distancia < 400) objTraslado = this.preciosObjHash["WFN_TRASLADO_200"]
      else if(this.paso2.distancia < 600) objTraslado = this.preciosObjHash["WFN_TRASLADO_400"]
      else if(this.paso2.distancia < 800) objTraslado = this.preciosObjHash["WFN_TRASLADO_600"]
      else if(this.paso2.distancia < 1000) objTraslado = this.preciosObjHash["WFN_TRASLADO_800"]
      else if(this.paso2.distancia < 1200) objTraslado = this.preciosObjHash["WFN_TRASLADO_1000"]
      else if(this.paso2.distancia < 1400) objTraslado = this.preciosObjHash["WFN_TRASLADO_1200"]
      else if(this.paso2.distancia < 1600) objTraslado = this.preciosObjHash["WFN_TRASLADO_1400"]
      else if(this.paso2.distancia < 1800) objTraslado = this.preciosObjHash["WFN_TRASLADO_1600"]
      else objTraslado = this.preciosObjHash["WFN_TRASLADO_1800"];
      
      let objServicioTraslado = {
        servicio: objTraslado.servicio.id,
        solicitud: this.solicitudId,
        importe: this.precioTraslado,
        divisa: "EUR",
        nombre: "TRASLADO (" + parseInt(this.paso2.distancia) + "km.)",
        impuesto: objTraslado.impuesto,
        base: this.precioTraslado / (objTraslado.impuesto / 100 + 1)
      }
      this.guardarServicio(objServicioTraslado);

      servicios.push({
        nombre: "TRASLADO",
        importe: this.thousands_separators(this.precioTraslado),
        base: this.precioTraslado / (objTraslado.impuesto / 100 + 1),
        iva: objTraslado.impuesto
      }); 
    }

    // Líneas adicionales
    if(this.lineasAdicionales.length > 0) {
      for(let linea of this.lineasAdicionales) {
        let multiplicador = 1 + (parseInt(linea.impuesto)/100);

        let objServicioLinea = {
          servicio: null,
          solicitud: this.solicitudId,
          importe: linea.importe,
          divisa: "EUR",
          base21: linea.importe / multiplicador,
          nombre: linea.concepto
        }
        this.guardarServicio(objServicioLinea);

        servicios.push({
          nombre: linea.concepto,
          importe: this.thousands_separators(linea.importe),
          base: this.thousands_separators(linea.importe / multiplicador),
          iva: linea.impuesto,
          suplido: linea.suplido
        });        
      }
    }

    this.generarPDF(servicios);

  }
  
  presupuestosGenerados = [];

  generarPDF(servicios: any) {
    let impuestos = [];
    let totalBases = 0;
    let numIVAs = 0;

    console.warn(this.totalBases);

    for (let key in this.totalBases) {
      let iva = parseInt(key);
      if(parseInt(key) == 7) iva = 3;
      let multiplicador = 1 + (iva/100);


      if(this.totalBases[key] > 0) {
        totalBases += this.totalBases[key];

        impuestos.push({
          base: this.thousands_separators(this.totalBases[key]),
          iva: iva,
          importe: this.thousands_separators(this.totalBases[key] * multiplicador - this.totalBases[key])
        });
        numIVAs++;
      }
        
    }

    console.warn(impuestos);

    console.warn(this.totalBases)

    let envio = {
      fecha: moment().format("DD/MM/YYYY"),
      presupuesto: this.solicitudObj.presupuesto,
      logo: this.logo,
      solicitud: {
        nombre: this.solicitudObj.nombre,
        multiplesIVA: numIVAs > 1 ? true : false,
        servicios: servicios,
        total: this.thousands_separators(this.totalServicios),
        base: this.thousands_separators(totalBases/*this.totalBase21 + this.totalBase10 + this.totalBase7*/),
        iva: this.thousands_separators(this.totalServicios - totalBases - this.totalSuplidos),
        suplidos: this.totalSuplidos > 0 ? this.thousands_separators(this.totalSuplidos) : null,
        impuestos: impuestos,
        notas: this.notasAdicionales,
        impuesto: this.localizacionObj.impuesto || "IVA",
        telefono: this.solicitudObj.telefono
      },
      firma: this.paso2.firma == "SI" ? true : false,
      sepa: this.paso2.sepa == "SI" ? true : false,
    }

    console.warn(envio);

    this.solicitudService.generaPresupuestoB2B(envio)
    .subscribe(
      data => {
        console.error(data);
        let firma = "";
        let docSEPA = "";
        let docPRES = "";
        if(data.firma && data.firma.documentsbyoperation[0]) {
          firma = data.firma.documentsbyoperation[0].operationUuid;

          if(data.firma.documentsbyoperation[0].documents.length > 1) {
            if(data.firma.documentsbyoperation[0].documents[1].reference == "presupuesto") {
              docSEPA = data.firma.documentsbyoperation[0].documents[0].documentUuid;
              docPRES = data.firma.documentsbyoperation[0].documents[1].documentUuid;
            } else {
              docSEPA = data.firma.documentsbyoperation[0].documents[1].documentUuid;
              docPRES = data.firma.documentsbyoperation[0].documents[0].documentUuid;
            }
          }

          let updateObj = {
            id: this.solicitudId,
            firmaEstado: "PENDIENTE",
            firmaUUID: firma,
            firmaSEPA: docSEPA,
            firmaPRES: docPRES
          }

          this.solicitudService.update(updateObj)
          .pipe(first())
          .subscribe(
            data => {
            },
            error => {
              console.error(error);
              this.loading = false;
            });
          
        } 

        

        this.presupuestosGenerados.push({
          nombre: this.solicitudObj.presupuesto + " - " +  this.objEsencial.servicio.nombre,
          codigo: this.solicitudObj.presupuesto,
          importe: this.solicitudObj.importe,
          file: data.file,
          envio: true,
          firma: firma
        })

        //if(this.paso2.email.toLowerCase().includes("prueba")){
          this.downloadPDF(this.presupuestosGenerados[this.presupuestosGenerados.length-1].file, this.presupuestosGenerados[this.presupuestosGenerados.length-1].codigo);
          this.loading = false;
          this.prepararEmail();
          this.vista = "resultado";
          this.cdr.detectChanges();
        /*} else {
          if(!this.dealId) {
            this.envioHubspot();
          } else {
            this.enviarPresupuestoHubspot();
          }
        }*/

        //this.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        console.error(error);
        this.loading = false;
      });

  }

  emailContent = "";

  prepararEmail () {
    var hora = moment().hour();
    var saludo = "";

    if(hora >= 6 && hora <= 12) saludo = "Buenos días"
    else if(hora >= 13 && hora <= 20) saludo = "Buenas tardes"
    else saludo = "Buenas noches";

    this.emailContent = saludo + ", D/Dña " + this.paso2.nombre + ",<br>" + 
    "Muchas gracias por su contacto con WFuneralNet.<br>" + 
    "Como hemos hablado por teléfono, le enviamos nuestro presupuesto para la despedida que ha escogido.<br>";

    if(this.paso2.firma == "SI") {
      this.emailContent += "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para ello, pulse sobre el botón de firma y rellene los campos solicitados. Nuestro número de teléfono es 683 67 39 57.<br>" +
      "[FIRMA]<br>";
    } else {
      this.emailContent += "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para hacérnoslo llegar puede utilizar esta misma vía (correo electrónico). Nuestro número de teléfono es 683 67 39 57.<br>";
    }
    
    this.emailContent += "Atentamente,<br>" +
    this.currentUser.empresa + " de WFuneralNet";
  }

  downloadPDF(pdf, presupuesto) {
    const linkSource = `${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = presupuesto + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  nuevoPresupuesto() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['admin-pets-presupuesto']);
    });
  }

  volverPresupuesto() {
    this.vista = "presupuesto";
    this.cdr.detectChanges();
  }

  abrirPDF() {
    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(this.presupuestosGenerados[this.presupuestosGenerados.length-1].file) + "'></iframe>"
    )
  }
  abrirPresPDF(presupuesto: any) {
    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(presupuesto.file) + "'></iframe>"
    )
  }

  // Envio EMAIL
  loadingEmail = false;
  emailEnviado = false;
  textHTML = "";

  sendEmail() {
    if(this.textHTML.includes("[TU NOMBRE]")) {
      this.emailSwal.fire();
      return;
    }

    if(this.textHTML.includes("[FIRMA]")) {
      let firma = "";

      for(let pres of this.presupuestosGenerados) {
        firma += "<a href='https://albia.galeonsoftware.com/customer-info/?uuid=" + pres.firma + "&d=5'>Firmar presupuesto - " + pres.codigo + "</a><br>"
      }

      this.textHTML = this.textHTML.replace("[FIRMA]", firma);
    }


    this.loadingEmail = true;
    this.cdr.detectChanges();

    let adjuntos = [];
    for(let pres of this.presupuestosGenerados) {
      if(pres.envio) {
        adjuntos.push({
          nombre: pres.nombre,
          codigo: pres.codigo,
          id: pres.id
        })
      }
      
    }

    let mail = {
      mensaje: this.textHTML,
      email: this.paso2.email,
      adjuntos: adjuntos,
      deal: this.dealId,
      tipo: "B2B",
      logo: this.logo
    }

    this.solicitudService.envioPresupuesto(mail)
    .pipe(first())
    .subscribe(
      data => {
        this.loadingEmail = false;
        this.emailEnviado = true;
        this.cdr.detectChanges();
      },
      error => {
        console.error(error);
        this.loading = false;
      });

    
  }

  htmlChanged(event) {
    this.textHTML = event.value;
  }

  

}
