import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de certificados.
 * @author Informática Integral Vasca
 */
@Injectable()
export class CertificadoService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el certificado por su ID
     * @param id Identificador del certificado a obtener
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/certificado/${id}`);
    }

    /**
     * Realiza una petición `GET` para obtener los certificados de un usuario sin contenido
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/certificado?user=${user}&omit=contenido`);
    }
    
    /**
     * Realiza una petición `GET` para obtener todos los certificados sin contenido
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getListUser() {
        return this.http.get<any[]>(`${environment.apiUrl}/certificado?omit=contenido`);
    }

    /**
     * Realiza una petición `POST` para guardar un certificado
     * @param cert Contenido del certificado
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(cert: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/certificado`, cert, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar un certificado
     * @param cert Contenido del certificado a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(cert: any) {
        return this.http.patch(`${environment.apiUrl}/certificado/${cert.id}`, cert);
    }

    /**
     * Realiza una petición `DELETE` para borrar un certificado
     * @param id Identificador del certificado a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/certificado/${id}`);
    }
}