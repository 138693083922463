import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de localizaciones
 */
@Injectable()
export class LocalizacionService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/localizacion?activo=1`).toPromise();
    }
 
    getAllPets() {
        return this.http.get<any[]>(`${environment.apiUrl}/pets_localizacion?activo=1`).toPromise();
    }
 
    getByCP(cp: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/localizacion?where={"activo":1,"cpDesde":{"<=":"${cp}"},"cpHasta":{">=":"${cp}"}}`);
    }
 
    getByCPPets(cp: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/pets_localizacion?where={"activo":1,"cpDesde":{"<=":"${cp}"},"cpHasta":{">=":"${cp}"}}`);
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/localizacion/${id}`);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una localización BOREA PETS
     * @param cert Contenido de la localización a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updatePETS(loc: any) {
        return this.http.patch<any>(`${environment.apiUrl}/pets_localizacion/${loc.id}`, loc).toPromise();
    }
}