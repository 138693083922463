import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { environment } from '../../../../environments/environment';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, SuscriptionService, PaymentService, ServicioService, NotificacionService, B2bService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
declare var Stripe: any;

/**
 * Componente que gestiona la creación de una licitación.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-servicios-nuevo',
  templateUrl: './servicios-nuevo.component.html',
  styleUrls: ['./servicios-nuevo.component.scss']
})
export class ServiciosNuevoComponent implements OnInit {

  /** Variables globales */
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent

  currentUser: any;
  loading = false;
  error = {} as any;

  servicios = [];
  estados = [];
  estadosAll = [];
  estadosHash = {};

  paises = [];
  paisesHash = {};
  serv = null;
  private unsubscribe: Subject<any>; 

  idGeocoder = "geocoder";

  geocoderObj;
  proveedores = [];
  proveedoresHash = {};

  crema = false;
  destemp = false;
  repat = false;
  serviciosAdd = {
    velatorio: false,
    flores: false,
    traslado: false,
    repatriacion: false,
    despedida: false,
    judicial: false
  }

  servicio: any = {
    referenciaCliente: null,
    cliente: "",
    userCliente: "",
    traslado: false
  }

  // Array de provincias españolas
  provincias = [
    {id: 0, nombre: ""},
    {id: 1, nombre: "Araba"},
    {id: 2, nombre: "Albacete"},
    {id: 3, nombre: "Alicante"},
    {id: 4, nombre: "Almería"},
    {id: 5, nombre: "Ávila"},
    {id: 6, nombre: "Badajoz"},
    {id: 7, nombre: "Baleares"},
    {id: 8, nombre: "Barcelona"},
    {id: 9, nombre: "Burgos"},
    {id: 10, nombre: "Cáceres"},
    {id: 11, nombre: "Cádiz"},
    {id: 12, nombre: "Castellón"},
    {id: 13, nombre: "Ciudad Real"},
    {id: 14, nombre: "Córdoba"},
    {id: 15, nombre: "A Coruña"},
    {id: 16, nombre: "Cuenca"},
    {id: 17, nombre: "Girona"},
    {id: 18, nombre: "Granada"},
    {id: 19, nombre: "Guadalajara"},
    {id: 20, nombre: "Gipuzkoa"},
    {id: 21, nombre: "Huelva"},
    {id: 22, nombre: "Huesca"},
    {id: 23, nombre: "Jaén"},
    {id: 24, nombre: "León"},
    {id: 25, nombre: "Lleida"},
    {id: 26, nombre: "La Rioja"},
    {id: 27, nombre: "Lugo"},
    {id: 28, nombre: "Madrid"},
    {id: 29, nombre: "Málaga"},
    {id: 30, nombre: "Murcia"},
    {id: 31, nombre: "Navarra"},
    {id: 32, nombre: "Ourense"},
    {id: 33, nombre: "Asturias"},
    {id: 34, nombre: "Palencia"},
    {id: 35, nombre: "Las Palmas"},
    {id: 36, nombre: "Pontevedra"},
    {id: 37, nombre: "Salamanca"},
    {id: 38, nombre: "Santa Cruz de Tenerife"},
    {id: 39, nombre: "Cantabria"},
    {id: 40, nombre: "Segovia"},
    {id: 41, nombre: "Sevilla"},
    {id: 42, nombre: "Soria"},
    {id: 43, nombre: "Tarragona"},
    {id: 44, nombre: "Teruel"},
    {id: 45, nombre: "Toledo"},
    {id: 46, nombre: "Valencia"},
    {id: 47, nombre: "Valladolid"},
    {id: 48, nombre: "Bizkaia"},
    {id: 49, nombre: "Zamora"},
    {id: 50, nombre: "Zaragoza"},
    {id: 51, nombre: "Ceuta"},
    {id: 52, nombre: "Melilla"}
  ];
  
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private servicioService: ServicioService,
    private b2bService: B2bService,
    public translate: TranslateService,
    private countryService: CountryService,
    private ngZone: NgZone,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');

      this.provincias.sort((a,b) => a.nombre.localeCompare(b.nombre));
    }


  /**
   * Método que se lanza en el inicio del componente e inicia la carga de maestros.
   */
  async ngOnInit() {
    //this.idGeocoder = "geocoder" + moment().unix();
    this.currentUser = this.authenticationService.currentUserValue;

    if(this.currentUser.b2b_cliente) {
      this.servicio.cliente = this.currentUser.b2b_cliente;
      this.servicio.userCliente = this.currentUser.id;
    }

    this.proveedores = await this.userService.getB2BAll();
    for(let item of this.proveedores) {
      this.proveedoresHash[item.id + ""] = item;
    }
    this.cdr.detectChanges();

    const geocoder = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: "ES",
      types: 'address, postcode, place'
    });

    geocoder.on('result', (e) => {
      console.warn(e);
      if(e.result) {
        let res = e.result;
        if(res.address) this.servicio.contactoNumero = res.address;

        if(res.id.includes("address")) this.servicio.contactoCalle = res.text;
        if(res.id.includes("place")) this.servicio.contactoLocalidad = res.text;
        if(res.id.includes("postcode")) this.servicio.contactoCP = res.text;

        for(let item of res.context) {
          if(item.id.includes("place")) this.servicio.contactoLocalidad = item.text;
          if(item.id.includes("postcode")) this.servicio.contactoCP = item.text;
          if(item.id.includes("region")) {
            console.warn(item.text);
            if(item.text.includes("Álava")) this.servicio.contactoProvincia = "Araba"
            else if(item.text.includes("Islas Baleares")) this.servicio.contactoProvincia = "Baleares"
            else if(item.text.includes("La Coruña")) this.servicio.contactoProvincia = "A Coruña"
            else if(item.text.includes("Gerona")) this.servicio.contactoProvincia = "Girona"
            else if(item.text.includes("Guipúzcoa")) this.servicio.contactoProvincia = "Gipuzkoa"
            else if(item.text.includes("Lérida")) this.servicio.contactoProvincia = "Lleida"
            else if(item.text.includes("Orense")) this.servicio.contactoProvincia = "Ourense"
            else if(item.text.includes("Vizcaya")) this.servicio.contactoProvincia = "Bizkaia"
            else this.servicio.contactoProvincia = item.text.replace("provincia de ", "");
          }
        }

        console.warn(this.servicio.contactoProvincia);

        if(!this.servicio.fallecidoLocalidad) this.servicio.fallecidoLocalidad = this.servicio.contactoLocalidad;
        if(!this.servicio.fallecidoProvincia) this.servicio.fallecidoProvincia = this.servicio.contactoProvincia;
        if(!this.servicio.fallecidoCP) this.servicio.fallecidoCP = this.servicio.contactoCP;
        this.cdr.detectChanges();
      }
    });

    // Clear results container when search is cleared.
    geocoder.on('clear', () => {
      this.servicio.contactoNumero = null;
      this.servicio.contactoCalle = null;
      this.servicio.contactoLocalidad = null;
      this.servicio.contactoCP = null;
      this.servicio.contactoProvincia = null;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    
    const geocoder2 = new MapboxGeocoder({
      accessToken: environment.mapBoxToken,
      placeholder: 'Buscar',
      proximity: "ip",
      language: "es",
      countries: "ES",
      types: 'address, poi, postcode, place'
    });

    geocoder2.on('result', (e) => {
      console.warn(e);
      if(e.result) {
        let res = e.result;
        if(res.address) this.servicio.fallecidoNumero = res.address;
        if(res.id.includes("address") || res.id.includes("poi")) this.servicio.fallecidoCalle = res.text;
        if(res.id.includes("place")) this.servicio.fallecidoLocalidad = res.text;
        if(res.id.includes("postcode")) this.servicio.fallecidoCP = res.text;
        for(let item of res.context) {
          if(item.id.includes("place")) this.servicio.fallecidoLocalidad = item.text;
          if(item.id.includes("postcode")) this.servicio.fallecidoCP = item.text;
          if(item.id.includes("region")) {
            if(item.text.includes("Álava")) this.servicio.fallecidoProvincia = "Araba"
            else if(item.text.includes("Islas Baleares")) this.servicio.fallecidoProvincia = "Baleares"
            else if(item.text.includes("La Coruña")) this.servicio.fallecidoProvincia = "A Coruña"
            else if(item.text.includes("Gerona")) this.servicio.fallecidoProvincia = "Girona"
            else if(item.text.includes("Guipúzcoa")) this.servicio.fallecidoProvincia = "Gipuzkoa"
            else if(item.text.includes("Lérida")) this.servicio.fallecidoProvincia = "Lleida"
            else if(item.text.includes("Orense")) this.servicio.fallecidoProvincia = "Ourense"
            else if(item.text.includes("Vizcaya")) this.servicio.fallecidoProvincia = "Bizkaia"
            else this.servicio.fallecidoProvincia = item.text.replace("provincia de ", "");
          }          
        }
        this.cdr.detectChanges();
      }
    });

    setTimeout(function(){
      //geocoder.addTo('#' + this.idGeocoder);
      geocoder.addTo('#geocoder3');
      geocoder2.addTo('#geocoder4');
    }.bind(this), 1000);    
  }



  tipoPulsado(tipo: any) {
    this.servicio.tipo = tipo;
    if(tipo == "CREMACION") {
      this.crema = true;
      this.destemp = false;
      this.repat = false;
    } else if(tipo == "ENTIERRO") {
      this.crema = false;
      this.destemp = true;
      this.repat = false;
    } else if(tipo == "REPATRIACION") {
      this.crema = false;
      this.destemp = false;
      this.repat = true;
    }
  }

  /**
   * Comprueba que todo es correcto, guarda y notifica la licitación.
   */
  async submitLicit() {
    if(this.loading) return;
    //COMPROBAR ERRORES
    var errors = false;

    if(this.currentUser.b2b_cliente) {
      this.servicio.cliente = this.currentUser.b2b_cliente;
      this.servicio.userCliente = this.currentUser.id;
    }
    
    if(!this.servicio.userCliente || this.servicio.userCliente == "") {
      this.error.cliente = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.cliente = false;
    }

    if(!this.servicio.contactoNombre || this.servicio.contactoNombre == "") {
      this.error.contactoNombre = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.contactoNombre = false;
    }

    /*if(!this.servicio.contactoApellido1 || this.servicio.contactoApellido1 == "") {
      this.error.contactoApellido1 = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.contactoApellido1 = false;
    }*/

    if(!this.servicio.contactoTelefono || this.servicio.contactoTelefono == "") {
      this.error.contactoTelefono = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.contactoTelefono = false;
    }

    /*if(!this.servicio.contactoLocalidad || this.servicio.contactoLocalidad == "") {
      this.error.contactoLocalidad = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.contactoLocalidad = false;
    }

    if(!this.servicio.contactoProvincia || this.servicio.contactoProvincia == "") {
      this.error.contactoProvincia = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.contactoProvincia = false;
    }*/

    /*if(!this.servicio.fallecidoLocalidad || this.servicio.fallecidoLocalidad == "") {
      this.error.fallecidoLocalidad = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fallecidoLocalidad = false;
    }*/

    if(!this.servicio.fallecidoProvincia || this.servicio.fallecidoProvincia == "") {
      this.error.fallecidoProvincia = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fallecidoProvincia = false;
    }

    if(!this.servicio.fallecidoCP || this.servicio.fallecidoCP == "") {
      this.error.fallecidoCP = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fallecidoCP = false;
    }

    if(!this.servicio.fallecidoFecha || this.servicio.fallecidoFecha == "") {
      this.error.fallecidoFecha = true;
      if(!errors) {
        this.scroll("datoscontacto");
      }
      errors = true;
    } else {
      this.error.fallecidoFecha = false;
    }

    if(!this.servicio.fallecidoTipoLugar || this.servicio.fallecidoTipoLugar == "") {
      this.error.fallecidoTipoLugar = true;
      if(!errors) {
        this.scroll("datoscontacto");
      }
      errors = true;
    } else {
      this.error.fallecidoTipoLugar = false;
    }

    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    this.servicio.fechaEntrada = new Date();
    this.servicio.estado = "PENDIENTE";
    this.servicio.origen = this.proveedoresHash[this.servicio.userCliente].b2b_tipo;

    this.servicio.cliente = this.proveedoresHash[this.servicio.userCliente].b2b_cliente;
    let codigo = await this.b2bService.generarCodigo(this.servicio.cliente);

    this.servicio.referencia = codigo.codigo;
    this.servicio.numero = codigo.numero;
    this.servicio.anyo = codigo.anyo;
    this.servicio.serviciosAdd = this.serviciosAdd;
    this.servicio.creador = this.currentUser.empresa;

    let servicio = await this.b2bService.postSolicitud(this.servicio);

    console.warn(servicio);

    this.confirmationAddSwal.text = "Referencia: " + this.servicio.referencia;

    this.confirmationAddSwal.fire();

    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }



  /**
   * Hace scroll hasta el elemento indicado
   * @param id Identificador del elemento.
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  /**
   * Carga los servicios del grupo correspondiente
   */
  loadServicios() {
    var grupo = "ES";
    if(this.currentUser.pais.id == 142) grupo = "MX";
		this.servicioService.getAllGrupo(grupo)
	    .subscribe(servicios => {
        console.warn(servicios);
			this.servicios = servicios;
      for(let serv of this.servicios) {
        serv.error = {importeSalida: false, comentarios: false, importeNegativo: false, importeIncorrecto: false, tipoCompleto: false};
        serv.comentarios = null;
        serv.importeSalida = null;
        serv.val = false;
        serv.detalleMostrar = false;
        serv.detalleMostrarCompleto = false;
        serv.completo = false;
        serv.crema = false;
        serv.destemp = false;
        serv.repat = false;
        serv.detalleCompleto = null;
        if(serv.abreviatura == "CREMA") {
          this.servicios[0].cremaDetalle = serv;
        } else if(serv.abreviatura == "DESTEMP") {
          this.servicios[0].destempDetalle = serv;
        }
      }
      this.servicios.sort(function (a, b) {
        if (a.orden < b.orden) {
          return -1;
        } else if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      }.bind(this));
      this.cdr.detectChanges();
		});
	}

  /** Variables para la edición */
  referenciaCliente = null;
  tipo = null;
  creacion = null;
  traslado = false;
  serviciosMostrar = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;
  detalleTraslado = false;

  fechaInicio = null;
  fechaFin = null;
  

  paisOrigen = null;
  paisDestino = null;

  provinciaOrigen = null;
  poblacionOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;

  estadosOrigenNew = []
  estadosDestinoNew = [];

  cargaInicial = true;

  /**
   * Evento de cambio de país origen.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedOrigenNew() {
    this.estadosOrigenNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigenNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
    this.paisDestino = this.paisOrigen;
    this.countryChangedDestinoNew();
    if(this.cargaInicial) {
      this.cargaInicial = false;
      this.provinciaDestino = this.provinciaOrigen;
    } else {
      this.provinciaOrigen = null;
      this.provinciaDestino = null;
    }
  }

  /**
   * Evento de cambio de provincia origen
   * Cambia la provincia destino a la misma que origen
   */
  provinciaChangedOrigen(){
    this.provinciaDestino = this.provinciaOrigen;
  }

  /**
   * Evento de cambio de país destino.
   * Carga las comunidades / estados del pais seleccionado
   */
  countryChangedDestinoNew() {
    this.estadosDestinoNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisDestino) this.estadosDestinoNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  fallecidoProvinciaChanged() {
    for(let prov of this.provincias) {
      if(prov.nombre == this.servicio.fallecidoProvincia) {
        this.servicio.fallecidoCP = prov.id;
      }
    }
  }

  contactoProvinciaChanged() {
    for(let prov of this.provincias) {
      if(prov.nombre == this.servicio.contactoProvincia) {
        this.servicio.contactoCP = prov.id;
      }
    }
  }
  

}
