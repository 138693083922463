// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
//import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, AuthGuard, authReducer } from '../../../core/auth';
import { GuestComponent } from './guest/guest.component';

import { NgxDropzoneModule } from 'ngx-dropzone';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AngularIbanModule } from 'angular-iban';
import { RecoverComponent } from './recover/recover.component';
import { ValidarComponent } from './validar/validar.component';
import { DemoComponent } from './demo/demo.component';
import { PartialsModule } from '../../partials/partials.module';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'login/:tarifa',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{ 
				path: 'demo', 
				component: DemoComponent
			},
			{ 
				path: 'demo/:tipo', 
				component: DemoComponent
			},
			{
				path: 'register',
				//component: RegisterComponent
				component: GuestComponent
			},
			{
				path: 'register/:tarifa',
				//component: RegisterComponent
				component: GuestComponent
			},
			{
				path: 'guest',
				component: GuestComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'recover',
				component: RecoverComponent
			},
			{
				path: 'validar',
				component: ValidarComponent
			},
		]
	}
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		SweetAlert2Module.forRoot(),
		NgxDropzoneModule,
		AngularIbanModule,
		PartialsModule
	],
	providers: [
		/*InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},*/
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		GuestComponent,
		RecoverComponent,
		ValidarComponent,
		DemoComponent
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				//AuthService,
				AuthGuard
			]
		};
	}
}
