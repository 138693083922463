import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de eventos.
 * @author Informática Integral Vasca
 */
@Injectable()
export class LogService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el listado completo de eventos
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/log`);
    }

    /**
     * Realiza una petición `GET` para obtener los eventos de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAllGrupo(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/log?user=${user}`);
    }

    /**
     * Realiza una petición `GET` para obtener el evento por su ID
     * @param id Identificador del evento a obtener
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/log/${id}`);
    }

    /**
     * Realiza una petición `POST` para guardar un evento
     * @param log Contenido del evento
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(log: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/log`, log, httpOptions);
    }

    
    getEventos(user:any) {
        return this.http.get<any>(`${environment.apiUrl}/evento?user=${user}`);
      }
}