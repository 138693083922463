import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, B2bService, PaymentService, SolicitudService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-admin-pago-listado',
  templateUrl: './admin-pago-listado.component.html',
  styleUrls: ['./admin-pago-listado.component.scss']
})
export class AdminPagoListadoComponent implements OnInit {

  @ViewChild('confirmationDeleteSwal', {static: false}) private confirmationDeleteSwal: SwalComponent
  @ViewChild('deleteAskSwal', {static: false}) private deleteAskSwal: SwalComponent
  //@ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  //@ViewChild('errorEmailSwal', {static: false}) private errorEmailSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private paymentService: PaymentService,
    private router: Router,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadPagos();
  }

  async loadPagos() {
    this.rows = [];
    this.cdr.detectChanges();
    let pagos = await this.solicitudService.getPagos();
    for(let pago of pagos) {
      pago.pagoFechaCreacionStr = moment(pago.pagoFechaCreacion).format("DD/MM/YYYY");
      if(pago.pagoFecha) pago.pagoFechaStr = moment(pago.pagoFecha).format("DD/MM/YYYY");
    }
    this.originalRows = pagos;
    this.filterDatatable();
    console.warn(pagos);
    this.cdr.detectChanges();
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      //if(item.status == "INVITADO") mostrar = false;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

  nuevo() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['admin-pago-nuevo']);
    });
  }

  detallePago(row: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['admin-pago-nuevo/' + row.presupuesto]);
    });
  }


  deleteObj = null;

  async cancelarPago(row) {
    this.deleteObj = row;
    this.deleteAskSwal.fire();
  }

  async continuarCancelarPago() {
    /*await this.b2bService.deletePetCementerio(this.deletePetObj.id);
    this.confirmationDeleteSwal.fire();
    this.loadPets();*/

    this.paymentService.updatePayment(this.deleteObj.pagoStripe, { active: false }).then(async function(data) {
      this.confirmationDeleteSwal.fire();
      let objEdit = {
        id: this.deleteObj.id,
        pagoEstado: "CANCELADO"
      }
      await this.solicitudService.updateSolicitud(objEdit);
      this.cdr.detectChanges();
      this.loadPagos();
    }.bind(this));
  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
      else return num_parts[0];
      
    } else {
      return '0';
    }
    
  }

}
