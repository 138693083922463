import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DxPopupComponent } from 'devextreme-angular';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, NotificacionService,
  SuscriptionService, PaymentService, CertificadoService, FunerariaService, Imagen_userService, TicketService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
declare var Stripe: any;

import { environment } from '../../../../environments/environment';
import { DateTimeAdapter } from 'ng-pick-datetime';

/**
 * Componente que gestiona el perfil del usuario
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  /** Variables globales */
  currentUser: any;
  loading = false;
  loadingPaymentMethod = false;
  submitted = false;
  pagosPendientes = false;
  pago = null;
  cambio = null;
  prefijo = null;
  modoAdmin = false;

  estados = [];
  bloqueos = [];
  estadosAll = [];
  estadosHash = {};
  paises = [];
  paisesHash = {};
  dispositivos = [];
  eventos = [];
  datosForm: FormGroup;

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('cancelarSuscripSwal', {static: false}) private cancelarSuscripSwal: SwalComponent
  @ViewChild('cancelarSuscripOkSwal', {static: false}) private cancelarSuscripOkSwal: SwalComponent
  @ViewChild('tarifaCambiadaSwal', {static: false}) private tarifaCambiadaSwal: SwalComponent
  @ViewChild('errorTelegramSwal', {static: false}) private errorTelegramSwal: SwalComponent
  @ViewChild('cambioLocSwal', {static: false}) private cambioLocSwal: SwalComponent
  @ViewChild('borrarCambioSwal', {static: false}) private borrarCambioSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('questionDeleteFileSwal', {static: false}) private questionDeleteFileSwal: SwalComponent
  @ViewChild('confirmationDeleteFileSwal', {static: false}) private confirmationDeleteFileSwal: SwalComponent
  @ViewChild('confirmationFileSwal', {static: false}) private confirmationFileSwal: SwalComponent
  @ViewChild('questionDeleteFunSwal', {static: false}) private questionDeleteFunSwal: SwalComponent
  @ViewChild('confirmationDeleteFunSwal', {static: false}) private confirmationDeleteFunSwal: SwalComponent
  @ViewChild('confirmationFunSwal', {static: false}) private confirmationFunSwal: SwalComponent
  @ViewChild('borrarSwal', {static: false}) private borrarSwal: SwalComponent
  @ViewChild('borrarConfirmSwal', {static: false}) private borrarConfirmSwal: SwalComponent
  @ViewChild('borrarDispSwal', {static: false}) private borrarDispSwal: SwalComponent
  @ViewChild('borrarDispConfirmSwal', {static: false}) private borrarDispConfirmSwal: SwalComponent

  vista = "datos";

  /** Mensajes vacíos para los listados */
  msg_bloqueo = {
    'emptyMessage': 'No hay bloqueos',
    'totalMessage': 'total'
  };
  msg_pagos = {
    'emptyMessage': 'No hay pagos',
    'totalMessage': 'total'
  };
  msg_cert = {
    'emptyMessage': 'No hay certificaciones',
    'totalMessage': 'total'
  };
  msg_disp = {
    'emptyMessage': 'No hay dispositivos',
    'totalMessage': 'total'
  };
  msg_fun = {
    'emptyMessage': 'No hay funerarias',
    'totalMessage': 'total'
  };
  msg_event = {
    'emptyMessage': 'No hay eventos',
    'totalMessage': 'total'
  };

  private unsubscribe: Subject<any>; 

  constructor(private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    public router: Router,
    private cdr: ChangeDetectorRef,
	  private modalService: NgbModal,
    private stateService: StateService,
    public translate: TranslateService,
    private paymentService: PaymentService,
    private tarifaService: TarifaService,
    private suscriptionService: SuscriptionService,
    private certificadoService: CertificadoService,
    private image_userService: Imagen_userService,
    private funerariaService: FunerariaService,
    private notificacionService: NotificacionService,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private countryService: CountryService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');
    }

  /**
   * Método que se lanza al iniciar el componente. 
   * Prepara los formularios y carga los maestros necesarios.
   */
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if(params.get("tab")) this.vista = params.get("tab");

      let userParam = this.authenticationService.currentUserValue.id;
      if(params.get("user")) {
        userParam = params.get("user");
        this.modoAdmin = true;
      }
    
      var token = this.authenticationService.currentUserValue.token;

      this.datosForm = this.fb.group({
        email: [null, Validators.compose([Validators.required])],
        cif: [null, Validators.compose([Validators.required])],
        licencia: [null],
        domicilio: [null, Validators.compose([Validators.required])],
        poblacion: [null, Validators.compose([Validators.required])],
        cp: [null, Validators.compose([Validators.required])],
        telefono: [null, Validators.compose([Validators.required])],
        telefono2: [null],
        pais: [null, Validators.compose([Validators.required])],
        provincia: [null, Validators.compose([Validators.required])],
        empresa: [null, Validators.compose([Validators.required])],
        actividad: [null, Validators.compose([Validators.required])],
        paginaweb: [null],
        notif_email: [null],
        notif_telegram: [null],
      });

      this.userService.getById(userParam)
      .subscribe(user => {
        this.currentUser = user;
        this.currentUser.token = token;
        this.prefijo = this.currentUser.prefijo;
        this.cdr.detectChanges();
        if(!this.modoAdmin) this.authenticationService.changeUser(this.currentUser);

        if(!this.currentUser.codigo_telegram) this.currentUser.codigo_telegram = [""];
        if(!this.currentUser.telefonos_sms) this.currentUser.telefonos_sms = [""];
        //if(!this.currentUser.notif_) this.currentUser.telefonos_sms = [""];
        if(!this.currentUser.notif_provincias) this.currentUser.notif_provincias = [""];
        if(!this.currentUser.telefonos_llamadas) this.currentUser.telefonos_llamadas = [""];
        if(this.currentUser.facturacion_aseg) this.step3.facturacion = this.currentUser.facturacion_aseg;
        if(this.currentUser.notif_llamadas_dia == null) this.currentUser.notif_llamadas_dia = true;


        //this.currentUser = this.authenticationService.currentUserValue;
        console.warn(this.currentUser);
        if(this.currentUser.suscripciones)
          for(let suscrip of this.currentUser.suscripciones) {
            suscrip.startDateStr = moment(suscrip.startDate).format("DD/MM/YYYY");
            suscrip.endDateStr = moment(suscrip.endDate).format("DD/MM/YYYY");
          }

        this.initForm();
        // Carga de toda la información para el usuario.
        this.loadProvinciasAll();
        this.loadPaises();
        this.loadBloqueos();
        this.loadDispositivos();
        this.loadFunerarias();
        this.loadCertificaciones();
        this.loadImagenes();
        this.loadCambios();
        this.loadAlertas();
        this.loadTickets();
        this.loadEventos();

        this.tiposHash = {};
        for(let tipo of this.tipos) {
          this.tiposHash[tipo.codigo + ""] = tipo;
        }

        this.route.queryParams.subscribe(params => {
          var session_id = params["session_id"];
          if(session_id) {  //Si hay session_id, cargamos los nuevos datos y los asociamos a la suscripcion
            this.loadingPaymentMethod = true;
            this.cdr.detectChanges();
    
            this.paymentService.getSession(session_id)
            .subscribe(
              session => {
                var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
                var modif = {customer: this.currentUser.stripeCustomer, paymentMethod: session.setup_intent.payment_method, subscription: suscrip.stripeSusc};
                this.paymentService.setPaymentMethod(modif)
                  .pipe(first())
                  .subscribe(
                    data => {
                      this.loadSuscripcion();
                    },
                    error => {
                      console.error(error);
                      this.loading = false;
                  });              
              },
              error => {
                console.error(error);
                this.loadingPaymentMethod = false;
                this.cdr.detectChanges();
            });
          } else {  //Sino, hacemos la carga
            this.loadSuscripcion();
          }
        });
      });

    });
  }

  /**
   * Carga la información de la suscripción activa del usuario y sus pagos.
   */
  loadSuscripcion() {
    if(this.currentUser.proveedor && this.currentUser.suscripciones && this.currentUser.suscripciones.length > 0) {
      var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
      if(!suscrip.pagado) {
        this.pagosPendientes = true;
        this.cdr.detectChanges();
      }
      if(suscrip.stripeSusc) {
        this.paymentService.getSubscription(suscrip.stripeSusc)
          .subscribe(
            subscription => {
              if(subscription.status == "active") {
                suscrip.startDateStr = moment(subscription.current_period_start*1000).format("DD/MM/YYYY");
                suscrip.endDateStr = moment(subscription.current_period_end*1000).format("DD/MM/YYYY");
                suscrip.suscObj = subscription;
                this.currentUser.suscripcion = suscrip;

                this.cdr.detectChanges();
                try {
                    if(this.currentUser.suscripcion.provincias) this.currentUser.suscripcion.provinciasObj = JSON.parse(this.currentUser.suscripcion.provincias);
                    if(this.currentUser.suscripcion.paises) this.currentUser.suscripcion.paisesObj = JSON.parse(this.currentUser.suscripcion.paises);
                  } catch (e) {
                }

                this.loadTarifas();

                //Cogemos los datos de pago
                this.paymentService.getPaymentMethod(subscription.default_payment_method)
                .subscribe(
                  payment => {
                    this.pago = payment;
                    this.loadingPaymentMethod = false;
                    this.cdr.detectChanges();
                  });
              }
            });
      } else {
        this.loadTarifas();
      }

      if(this.currentUser.stripeCustomer) {
        console.log("INVOICES")
        this.paymentService.getInvoices(this.currentUser.stripeCustomer)
        .subscribe(
          invoices => {
            console.warn(invoices);
            this.currentUser.pagos = invoices.data;
            for(let pago of this.currentUser.pagos) {
              pago.startDateStr = moment(pago.lines.data[pago.lines.data.length-1].period.start*1000).format("DD/MM/YYYY");
              pago.endDateStr = moment(pago.lines.data[pago.lines.data.length-1].period.end*1000).format("DD/MM/YYYY");
            }
            //Cogemos las facturas del ERP
            if(this.currentUser.pagos.length > 0) {
              this.paymentService.getFacturas(this.currentUser.id)
                .subscribe(
                  facturas => {
                    for(let factura of facturas) {
                      for(let pago of this.currentUser.pagos) {
                        if(pago.number == factura.StripeNumber && factura.RutaPdf) {
                          pago.facturaDisponible = true;
                          pago.facturaId = factura.id;
                          this.cdr.detectChanges();
                          break;
                        }
                      }
                    }
                    
                  },error => {
                    console.error(error);
                    this.loadingPaymentMethod = false;
                    this.cdr.detectChanges();
                });
            }
          },error => {
            console.error(error);
            this.loadingPaymentMethod = false;
            this.cdr.detectChanges();
        });
      }

      
      
    } else {
      this.loadTarifas();
    }
  }

  /**
   * Carga los cambios de tarifa realizados por el usuario
   */
  loadCambios(){
    this.suscriptionService.getCambiosByUser(this.currentUser.id)
    .subscribe(cambios => {
      if(cambios.length > 0) {
        this.cambio = cambios[0];
        if(this.cambio.provincias) this.cambio.provinciasObj = JSON.parse(this.cambio.provincias);
        if(this.cambio.paises) this.cambio.paisesObj = JSON.parse(this.cambio.paises);
        this.cambio.fechaCambio = moment(this.cambio.fechaCambio).format("DD/MM/YYYY HH:00");
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Cambia la vista mostrada al usuario en el panel de la derecha.
   * @param vista Vista a mostrar.
   */
  cambioVista(vista: any) {
    this.vista = vista;
  }

	/*
   * Evento de destrucción del componente.
   */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  /**
   * Evento de cambio de pais. Llama a la carga de comunidades
   * @param event Información del evento.
   */
  onChangePais(event){
    this.datosForm.controls["pais"].disable();
    this.datosForm.controls["provincia"].disable();
    this.loadProvincias(event.target.value);
    this.prefijo = "+" + this.paisesHash[event.target.value].phonecode;
  }

  /**
   * Carga las comunidades del país seleccionado
   * @param pais Pais del que cargar las comunidades.
   */
  loadProvincias(pais){
		this.stateService.getAllByCountry(pais)
	    .subscribe(estados => {
        this.estados = estados;
        if(!this.modoAdmin) {
          this.datosForm.controls["pais"].enable();
          this.datosForm.controls["provincia"].enable();
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * Carga el listado completo de comunidades.
   */
  loadProvinciasAll(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
      });
  }

  /**
   * Carga el listado completo de paises.
   */
  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
      }
		});
	}

  /**
   * Carga el país del usuario logueado.
   */
  loadPais(){
    this.countryService.getById(this.currentUser.pais.id)
    .subscribe(pais => {
      this.currentUser.paisStr = pais["name"];
      this.cdr.detectChanges();
    });
  }

  tarifas = [];
  tarifasHash = {};
  tarifasHashStripe = {};

  /**
   * Carga el listado completo de tarifas y configura las variables para notificaciones.
   */
  loadTarifas(){
		this.tarifaService.getAll()
	    .subscribe(tarifas => {
        this.tarifas = tarifas;
        this.cdr.detectChanges();
        for(var i = 0; i < tarifas.length; i++){
          this.tarifasHash[tarifas[i].id+""] = tarifas[i];
          this.tarifasHashStripe[tarifas[i].stripeMensual+""] = tarifas[i];
          this.tarifasHashStripe[tarifas[i].stripeAnual+""] = tarifas[i];
        }

        this.loadZonasInicio();
      });
  }
  
  /**
   * Inicializa el formulario de información personal del usuario
   */
  initForm() {
    if(this.currentUser.status == "INVITADO") {
      this.datosForm = this.fb.group({
        email: [this.currentUser.email, Validators.compose([Validators.required])],
        cif: [this.currentUser.cif, Validators.compose([Validators.required])],
        empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
        actividad: [this.currentUser.actividad, Validators.compose([Validators.required])]
      });
    } else {
      this.datosForm = this.fb.group({
        email: [this.currentUser.email, Validators.compose([Validators.required])],
        cif: [this.currentUser.cif, Validators.compose([Validators.required])],
        licencia: [this.currentUser.licencia],
        domicilio: [this.currentUser.domicilio, Validators.compose([Validators.required])],
        poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
        cp: [this.currentUser.cp, Validators.compose([Validators.required])],
        telefono: [this.currentUser.telefono, Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])],
        telefono2: [this.currentUser.telefono2, [Validators.pattern("[0-9]{9,11}")]],
        pais: [this.currentUser.pais.id, Validators.compose([Validators.required])],
        provincia: [this.currentUser.provincia.id, Validators.compose([Validators.required])],
        empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
        actividad: [{value: this.currentUser.actividad, disabled: true}, Validators.compose([Validators.required])],
        paginaweb: [this.currentUser.paginaweb],
        notif_email: [this.currentUser.notif_email],
        notif_telegram: [this.currentUser.notif_telegram]
      });

      this.datosForm.controls["empresa"].disable();

      //console.error(this.modoAdmin);
      if(this.modoAdmin) {
        this.datosForm.disable();
        this.cdr.detectChanges();
        this.datosForm.disable();
      }
      this.cdr.detectChanges();
      //this.datosForm.disable();
    }
		
    if(this.currentUser.pais) this.loadProvincias(this.currentUser.pais.id);
  }

  /**
   * Control de errores en el formulario personal.
   */
  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.datosForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }

  error = {notificaciones: false};

  /**
	 * Guarda la información del formulario personal.
	 */
	onSubmit() {
    if(this.loading) return;

    this.submitted = true;
    
    const controls = this.datosForm.controls;
    // check form
		if (this.datosForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    var continuarGuardado = function (){
      var currentUser = this.authenticationService.currentUserValue;
      var form = this.datosForm.value;
      
      form.id = currentUser.id;    
      form.prefijo = this.prefijo;
      
      this.userService.update(form)
        .pipe(first())
        .subscribe(
          data => {
            currentUser.email = form.email;
            if(form.empresa) currentUser.empresa = form.empresa;
            if(form.actividad) currentUser.actividad = form.actividad;
            if(form.cif) currentUser.cif = form.cif;
            if(form.cp) currentUser.cp = form.cp;
            if(form.pais) currentUser.pais = form.pais;
            if(form.prefijo) currentUser.prefijo = form.prefijo;
            if(form.domicilio) currentUser.domicilio = form.domicilio;
            if(form.licencia) currentUser.licencia = form.licencia;
            if(form.poblacion) currentUser.poblacion = form.poblacion;
            if(form.provincia) currentUser.provincia = form.provincia;
            if(form.telefono) currentUser.telefono = form.telefono;
            if(form.paginaweb) currentUser.paginaweb = form.paginaweb;
  
            this.currentUser = currentUser;
  
            this.authenticationService.changeUser(currentUser);
  
            this.loading = false;
            this.cdr.detectChanges();
            this.confirmationSwal.fire();
          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }.bind(this);
    
    if(this.currentUser.email != this.datosForm.value.email) {
      this.userService.check(this.datosForm.value.email)
        .pipe(first())
        .subscribe(
          data => {
            continuarGuardado();
            this.cdr.detectChanges();
          },
          error => {
            console.log(error);
            this.errorSwal.fire();
            this.loading = false;
            this.cdr.detectChanges();
        });
    } else {
      continuarGuardado();
    }

		
  }
  
  // ---- GESTIÓN TARIFA ----

  tarifaNueva = null;
  loadingCancelar = false;

  /**
   * Confirma la cancelación de la suscripción
   */
  cancelarSuscripcion() {
    this.cancelarSuscripSwal.fire();
  }

  /**
   * Cancela la suscripción al final del periodo.
   */
  cancelarSuscripcion1() {
    this.loadingCancelar = true;
    this.cdr.detectChanges();
    var modif = {id: this.currentUser.suscripcion.stripeSusc, cancel_at_period_end: true};

    this.paymentService.updateSubscription(modif)
    .pipe(first())
      .subscribe(
        subscription => {
          if(subscription.status == "active") {
            this.currentUser.suscripcion.suscObj = subscription;
            this.authenticationService.changeUser(this.currentUser);
            
            this.loadingCancelar = false;
            this.cancelarSuscripOkSwal.fire();
            this.cdr.detectChanges();
          }
        },
        error => {
          console.error(error);
          this.loadingCancelar = false;
        });

    /*var modif = {id: this.currentUser.suscripcion.id, renovable: false};
    this.suscriptionService.update(modif)
      .pipe(first())
      .subscribe(
        data => {
          this.currentUser.suscripcion.renovable = false;

          this.currentUser.suscripciones[this.currentUser.suscripciones.length-1].renovable = false;
          this.authenticationService.changeUser(this.currentUser);

          this.loadingCancelar = false;
          this.cancelarSuscripOkSwal.fire();
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingCancelar = false;
        });*/
    
  }

  step2 = {ofertante: false, ofertanteConfirm: false, proveedor: false, proveedorTarifa: null, proveedorPago: null};

  errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};

  tarifa = null;
  cambioFacturacion = null;
  cambioSuperior = false;

  provinciasSel = [];
  paisesSel = [];
  
  vistaTarifas = "mensual";

  /**
   * Hace scroll hasta el elemento indicado
   * @param id Identificador del elemento
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  /**
   * Cambio de vista para cambio de tarifa
   * @param vista Tarifa a la que hacer el cambio
   */
  cambiarVistaTarifas(vista: any) {
    this.scroll("tarifas");
    this.vistaTarifas = vista;
  }

  /**
   * Evento de cambio de pais. Carga las comunidades y reinicia los selectores.
   * @param provincia Selector accionado
   */
  onChangePaisTarifa(provincia){
    this.provinciasSel[provincia.num-1].lista = [];
    this.provinciasSel[provincia.num-1].val = "";
    this.cdr.detectChanges();
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == provincia.pais) {
        lista.push(estado);
      }
    }
    this.provinciasSel[provincia.num-1].lista = lista;
    this.cdr.detectChanges();
  }

  /**
   * Evento de cambio de tarifa. Carga los selectores correspondientes
   * @param tarifa Tarifa a la que hacer el cambio
   */
  onChangeTarifa(tarifa){
    this.tarifa = tarifa;
    this.cambioFacturacion = this.vistaTarifas;
    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.currentUser.pais.id) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.currentUser.pais.id, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }
    this.scroll("localizacionTarifa");

    if(this.cambioFacturacion == "mensual") {
      if(this.tarifa.precioMensual > this.currentUser.suscripcion.precio) this.cambioSuperior = true
      else this.cambioSuperior = false;
    } else {
      if(this.tarifa.precioAnual > this.currentUser.suscripcion.precio) this.cambioSuperior = true
      else this.cambioSuperior = false;
    }
    this.cdr.detectChanges();
    this.calcularProrrateo();
  }
  
  /**
   * Lanza evento de cambio existente en el componente.
   */
  modelChange() {
    this.cdr.detectChanges();
  }

  pagoProrrateo = null;
  prorrateoLimite = null;
  pagoDespues = null;
  /**
   * Solicita el cálculo del prorrateo en cambio de tarifa.
   */
  calcularProrrateo() {
    this.pagoProrrateo = null;
    if(this.tarifa) {
      var tarifaStripe = null
      if(this.cambioFacturacion == 'mensual') {
        tarifaStripe = this.tarifa.stripeMensual;
        this.pagoDespues = this.tarifa.precioMensual;
      } else {
        tarifaStripe = this.tarifa.stripeAnual;
        this.pagoDespues = this.tarifa.precioAnual;
      }
      var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
      this.paymentService.getProrrationPreview(suscrip.stripeSusc, tarifaStripe, this.currentUser.stripeCustomer)
      .subscribe(
        resData => {
          this.pagoProrrateo = resData.total / 100;
          this.prorrateoLimite = moment(resData.lines.data[1].period.end*1000).format("DD/MM/YYYY");
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingPaymentMethod = false;
          this.cdr.detectChanges();
      });
    }
  }

  changeTarifa() {
    if(this.loadingCambioLoc) return;
    this.cdr.detectChanges();

    var validLoc = true;
    for(var i = 0; i < this.provinciasSel.length; i++) {
      if(!this.provinciasSel[i].val) {
        validLoc = false;
        this.provinciasSel[i].error = true;
      } else {
        this.provinciasSel[i].error = false;
      }
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      if(!this.paisesSel[i].val) {
        validLoc = false;
        this.paisesSel[i].error = true;
      } else {
        this.paisesSel[i].error = false;
      }
    }

    if(!validLoc) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    var tarifaStripe = null
    if(this.cambioFacturacion == 'mensual') {
      tarifaStripe = this.tarifa.stripeMensual;
    } else {
      tarifaStripe = this.tarifa.stripeAnual;
    }

    var provincias = [];
    var paises = [];

    for(var i = 0; i < this.provinciasSel.length; i++) {
      provincias.push(this.estadosHash[this.provinciasSel[i].val]);
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      paises.push(this.paisesHash[this.paisesSel[i].val]);
    }

    if(!this.cambioSuperior) {  //Si es una tarifa inferior, guardamos para el CRON.
      this.loadingCambioLoc = true;
      this.cdr.detectChanges();

      // Crear Suscripción
      var cambio = {
        user: this.authenticationService.currentUserValue.id,
        suscripcion: this.currentUser.suscripcion.id,
        fechaCambio: moment(this.currentUser.suscripcion.suscObj.current_period_end*1000),
        provincias: JSON.stringify(provincias),
        paises: JSON.stringify(paises),
        tarifa: this.tarifa.id,
        procesado: false,
        facturacion: this.cambioFacturacion,
        stripeProd: tarifaStripe,
        tipo: "TARIFA"
      };
      
      this.suscriptionService.postCambio(cambio)
      .pipe(first())
      .subscribe(
        data => {
          this.tarifaCambiadaSwal.text = "Su nueva tarifa se aplicará en la próxima renovación (" + this.currentUser.suscripcion.endDateStr + ").";
          this.tarifaCambiadaSwal.fire();
          this.vista = "suscripcion";
          this.loading = false;
          this.cdr.detectChanges();
          this.loadCambios();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    } else {
      var modif = {
        id: this.currentUser.suscripcion.stripeSusc,
        proration_behavior: 'always_invoice',
        billing_cycle_anchor: 'now',
        items: [{
          id: this.currentUser.suscripcion.suscObj.items.data[0].id,
          price: tarifaStripe, // Switch to new price
          quantity: 1
        }]
      }
      this.paymentService.updateSubscription(modif)
      .pipe(first())
        .subscribe(
          subscription => {
            if(subscription.status == "active") {
              this.currentUser.suscripcion.suscObj = subscription;
              this.authenticationService.changeUser(this.currentUser);

              //Ponemos la linea actual a finalizada. 
              var modif = {
                id: this.currentUser.suscripcion.id,
                activa: false,
                endDate: new Date(),
                razon: "tarifa_sup"
              };
              this.suscriptionService.update(modif)
                .pipe(first())
                .subscribe(
                  data => {
                    var precio = null
                    if(this.cambioFacturacion == "anual") {
                      precio = this.tarifa.precioAnual;
                    } else if(this.cambioFacturacion == "mensual") {
                      precio = this.tarifa.precioMensual;
                    }
                      
                    //Creamos una nueva línea de suscripción.
                    var susc = {
                      startDate: new Date(),
                      facturacion: this.cambioFacturacion,
                      provincias: JSON.stringify(provincias),
                      paises: JSON.stringify(paises),
                      precio: precio,
                      stripeProd: tarifaStripe,
                      stripeSusc: this.currentUser.suscripcion.stripeSusc,
                      tarifa: this.tarifa.id,
                      user: this.currentUser.id,
                      pagado: true,
                      activa: true,
                    };
                    
                    this.suscriptionService.post(susc)
                    .pipe(first())
                    .subscribe(
                      data1 => {
                        this.tarifaCambiadaSwal.text = this.translate.instant('PERFIL.SWAL.tarifaCambiadaSwal_TEXT2');
                        this.tarifaCambiadaSwal.fire();
                        this.vista = "suscripcion";
                        this.loading = false;
                        this.cdr.detectChanges();
                        this.ngOnInit();
                      },
                      error => {
                        console.error(error);
                        this.loading = false;
                        this.cdr.detectChanges();
                      });
                    
                  },
                  error => {
                    console.error(error);
                    this.loading = false;
                    this.cdr.detectChanges();
                  });
              
            }
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
          });
    }
  }

  //Cambio de modo de pago
  loadingCambio = false;
  /**
   * Crea una sesión checkout para el cambio de método de pago.
   */
  cambiarPago(){
    this.loadingCambio = true;
    this.cdr.detectChanges();
    var stripe = Stripe(environment.stripe);

    this.paymentService.createSetupCheckoutSession(this.currentUser.stripeCustomer, this.currentUser.suscripcion.stripeSusc).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
        });
    });
  } 

  // METODO DE PAGO - ASEGURADORA

  ibanForm = null;
  step3 = {titular: null, iban: null, facturacion: null};
  errors3 = {titular: false, iban: false, ibanFormat: false};

  /**
	 * Guarda el método de pago IBAN (forma antigua sin Stripe)
	 */
	onSubmitMetPago() {
    if(this.loading) return;

    this.submitted = true;

    var currentUser = this.authenticationService.currentUserValue;
    
    var update: any = {};
    update.id = currentUser.id;
    update.facturacion_aseg = this.step3.facturacion;

    if(this.step3.titular || this.ibanForm) {
      if(!this.step3.titular) {
        this.errors3.titular = true;
        this.cdr.detectChanges();
        return;
      } else this.errors3.titular = false;
      if(!this.ibanForm || this.ibanForm.invalid) {
        this.cdr.detectChanges();
        return;
      }
      update.iban_titular = this.step3.titular;
      update.iban = this.step3.iban;
    }
    
    this.loading = true;
    this.cdr.detectChanges();
    
    this.userService.update(update)
      .pipe(first())
      .subscribe(
        data => {
          if(update.iban_titular) currentUser.iban_titular = update.iban_titular;
          if(update.iban) currentUser.iban = update.iban;
          if(update.facturacion_aseg) currentUser.facturacion_aseg = update.facturacion_aseg;

          this.currentUser = currentUser;

          this.authenticationService.changeUser(currentUser);

          this.loading = false;
          this.cdr.detectChanges();
          this.confirmationSwal.fire();
        },
        error => {
          console.error(error);
          this.loading = false;
        });

		
  }

  /**
   * Carga la vista de cambio de localización
   */
  loadCambioLocalizacion() {
    //tarifasHash[currentUser.suscripcion.tarifa].paises != -1
    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.currentUser.pais.id) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifasHash[this.currentUser.suscripcion.tarifa].provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.currentUser.pais.id, lista: lista});
    }
    for(var i = 0; i < this.tarifasHash[this.currentUser.suscripcion.tarifa].paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }


    this.vista = 'cambio_loc';
    this.cdr.detectChanges();
  }

  loadingCambioLoc = false;

  /**
   * Procesa el cambio de localización de tarifa.
   */
  changeLoc() {
    if(this.loadingCambioLoc) return;
    this.cdr.detectChanges();

    var validLoc = true;
    for(var i = 0; i < this.provinciasSel.length; i++) {
      if(!this.provinciasSel[i].val) {
        validLoc = false;
        this.provinciasSel[i].error = true;
      } else {
        this.provinciasSel[i].error = false;
      }
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      if(!this.paisesSel[i].val) {
        validLoc = false;
        this.paisesSel[i].error = true;
      } else {
        this.paisesSel[i].error = false;
      }
    }

    if(!validLoc) {
      this.cdr.detectChanges();
      return;
    }

    this.loadingCambioLoc = true;
    this.cdr.detectChanges();

    var provincias = [];
    var paises = [];

    for(var i = 0; i < this.provinciasSel.length; i++) {
      provincias.push(this.estadosHash[this.provinciasSel[i].val]);
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      paises.push(this.paisesHash[this.paisesSel[i].val]);
    }

    // Crear Suscripción
    var cambio = {
      user: this.authenticationService.currentUserValue.id,
      suscripcion: this.currentUser.suscripcion.id,
      fechaCambio: moment().add(2,'days'),
      provincias: JSON.stringify(provincias),
      paises: JSON.stringify(paises),
      tarifa: this.currentUser.suscripcion.tarifa,
      procesado: false,
      tipo: "LOC"
    };
    
    this.suscriptionService.postCambio(cambio)
    .pipe(first())
    .subscribe(
      data => {
        this.cambioLocSwal.fire();
        this.vista = "suscripcion";
        this.loadingCambioLoc = false;
        this.cdr.detectChanges();
        this.loadCambios();
      },
      error => {
        console.error(error);
        this.loadingCambioLoc = false;
      });


  }

  /**
   * Cancela un cambio de localización o tarifa
   */
  cancelarCambio(){
    this.loadingCambioLoc = true;
    this.cdr.detectChanges();
    this.suscriptionService.deleteCambio(this.cambio.id)
    .pipe(first())
    .subscribe(
      data => {
        this.borrarCambioSwal.fire();
        this.cambio = null;
        this.loadingCambioLoc = false;
        this.cdr.detectChanges();
      },
      error => {
          console.error(error);
          this.loading = false;
          this.cdr.detectChanges();
      });
  }

  // ---- BLOQUEOS ----

  /**
   * Carga los bloqueos realizados por el usuario.
   */
  loadBloqueos() {
    this.userService.getBloqueosByUser(this.currentUser.id)
	    .subscribe(bloqueos => {
      for(let bloqueo of bloqueos){
        bloqueo.fechaStr = moment(bloqueo.fecha).format("DD/MM/YYYY HH:mm")
      }
			this.bloqueos = bloqueos;
      this.cdr.detectChanges();
		});
  }

  bloqueoBorrar = null;

  /**
   * Confirmación de borrado de bloqueo
   */
  borrarBloqueo(row: any) {
    this.bloqueoBorrar = row;
    this.borrarSwal.fire();
  }

  /**
   * Borra un bloqueo
   */
  borrarBloqueo1(){
    this.userService.deleteBloqueo(this.bloqueoBorrar.id)
      .pipe(first())
      .subscribe(
        data => {
          this.borrarConfirmSwal.fire();
          for(var i = 0; i < this.bloqueos.length; i++) {
            if(this.bloqueos[i].id == this.bloqueoBorrar.id){
              this.bloqueos.splice(i, 1);
              var datos = [...this.bloqueos];
              this.bloqueos = [];
              this.bloqueos = datos;
              this.cdr.detectChanges();

              this.loadBloqueos();
              break;
            }
          }
        },
        error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
        });
  }

  // ---- ALERTAS ----

  errors4 = {alerta: false, telegram: false, telegramCode: false};
  errorTelegram = []; 
  errorTelegramCode = [];
  errorSMS = []; 
  errorLoc = []; 
  errorLlamadas = []; 

  /**
   * Carga la configuración del sistema de alertas.
   */
  loadAlertas() {
    if(this.currentUser.notificaciones) {
      for(let alerta of this.currentUser.notificaciones) {
        if(this.currentUser.proveedor) {
          for(let notif of this.alertasFun) {
            if(alerta.codigo == notif.codigo) {
              notif.activa = alerta.activa;
              break;
            }
          }
        } else if(this.currentUser.ofertante) {
          for(let notif of this.alertasAseg) {
            if(alerta.codigo == notif.codigo) {
              notif.activa = alerta.activa;
              break;
            }
          }
        }        
      }
    }
  }
  /** Alertas disponibles para funerarias */
  alertasFun = [
    {codigo: "NUEVA", nombre_es: "Nueva licitación disponible", nombre_en: "New tender available", activa: true},
    {codigo: "PUJA", nombre_es: "Puja superada", nombre_en: "Bidding passed", activa: true},
    {codigo: "FINAL", nombre_es: "Licitación concluída y pendiente de adjudicar", nombre_en: "Tender concluded and awaiting award", activa: true},
    {codigo: "ADJUD", nombre_es: "Licitación adjudicada", nombre_en: "Tender awarded", activa: true},
    {codigo: "NO_ADJUD", nombre_es: "Licitación no adjudicada", nombre_en: "Tender not awarded", activa: true},
    {codigo: "CANCELADA", nombre_es: "Licitación cancelada", nombre_en: "Tender canceled", activa: true},
  ]
  /** Alertas disponibles para aseguradoras */
  alertasAseg = [
    {codigo: "CREADA", nombre_es: "Licitación creada correctamente", nombre_en: "Tender successfully created", activa: true},
    {codigo: "INSCRITO", nombre_es: "Nuevo participante inscrito (Licit. precio fijo)", nombre_en: "New registrant (Fixed-price tender)", activa: true},
    {codigo: "PUJA", nombre_es: "Nueva puja registrada (Licit. estándar)", nombre_en: "New Registered Bid (Standard tender)", activa: true},
    {codigo: "FINAL", nombre_es: "Licitación concluída y pendiente de adjudicar", nombre_en: "Tender concluded and awaiting award", activa: true},
    {codigo: "ADJUD", nombre_es: "Licitación adjudicada correctamente", nombre_en: "Tender awarded correctly", activa: true},
  ]

  trackByFn(index: any, item: any) {
    return index;
  }

  /** 
   * Añade un nuevo dipositivo de telegram 
   */
  addTelegram() {
    this.currentUser.codigo_telegram.push("");
    console.warn(this.currentUser.codigo_telegram);
    this.errorTelegram = []; 
    this.errorTelegramCode = [];
    this.cdr.detectChanges();
  }
  /**
   * Elimina un dispositivo de telegram
   */
  removeTelegram() {
    this.currentUser.codigo_telegram.splice(this.currentUser.codigo_telegram.length-1, 1);
  }

  /** 
   * Añade un nuevo dipositivo de SMS 
   */
  addSMS() {
    this.currentUser.telefonos_sms.push("");
    console.warn(this.currentUser.telefonos_sms);
    this.errorSMS = [];
    this.cdr.detectChanges();
  }
  /**
   * Elimina un dispositivo de SMS
   */
  removeSMS() {
    this.currentUser.telefonos_sms.splice(this.currentUser.telefonos_sms.length-1, 1);
  }

  /** 
   * Añade una nueva zona 
   */
   addZona() {
    this.currentUser.notif_provincias.push("");
    console.warn(this.currentUser.notif_provincias);
    this.errorSMS = [];
    this.cdr.detectChanges();
  }
  /**
   * Elimina una zona
   */
  removeZona() {
    this.currentUser.notif_provincias.splice(this.currentUser.notif_provincias.length-1, 1);
  }

  /** 
   * Añade un nuevo dipositivo de Llamadas 
   */
  addLlamadas() {
    this.currentUser.telefonos_llamadas.push("");
    console.warn(this.currentUser.telefonos_llamadas);
    this.errorLlamadas = [];
    this.cdr.detectChanges();
  }
  /**
   * Elimina un dispositivo de Llamadas
   */
  removeLlamadas() {
    this.currentUser.telefonos_llamadas.splice(this.currentUser.telefonos_llamadas.length-1, 1);
  }

  /**
   * Carga inicial de las zonas y sus comunidades.
   */
  loadZonasInicio() {
    // Configuración por defecto en notificaciones
    if(!this.currentUser.notif_pais) {
      if(this.tarifasHash[this.currentUser.suscripcion.tarifa].paises == 1) {
        this.currentUser.notif_pais = this.currentUser.suscripcion.paisesObj[0].id;
      } else {
        this.currentUser.notif_pais = this.currentUser.pais.id;
      }
    }

    this.loadComunidadesZona();


  }

  estadosZona = [];

  loadComunidadesZona() {
    this.estadosZona = [];
    this.cdr.detectChanges();
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.currentUser.notif_pais) {
        this.estadosZona.push(estado);
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Guarda la configuración de alertas.
   * Comprueba los códigos de Telegram introducidos.
   */
  onSubmitAlertas() {
    this.errors4 = {alerta: false, telegram: false, telegramCode: false};
    if(!this.currentUser.notif_telegram && !this.currentUser.notif_email && !this.currentUser.notif_app && !this.currentUser.notif_llamadas && !this.currentUser.notif_sms) {
      this.errors4.alerta = true;
      this.cdr.detectChanges();
      return;
    }
    if(this.currentUser.notif_telegram) {
      var errorTel = false;
      this.errorTelegram = [];
      for(let codigo of this.currentUser.codigo_telegram) {
        if(codigo == "") {
          this.errorTelegram.push(true);
          errorTel = true;
        } else this.errorTelegram.push(false);
      }
      this.cdr.detectChanges();
      if(errorTel) return;
    }
    if(this.currentUser.notif_sms) {
      var errorSMS = false;
      this.errorSMS = [];
      for(let codigo of this.currentUser.telefonos_sms) {
        if(codigo == "") {
          this.errorSMS.push(true);
          errorSMS = true;
        } else this.errorSMS.push(false);
      }
      this.cdr.detectChanges();
      if(errorSMS) return;
    }
    if(this.currentUser.notif_llamadas) {
      var errorLlam = false;
      this.errorLlamadas = [];
      for(let codigo of this.currentUser.telefonos_llamadas) {
        if(codigo == "") {
          this.errorLlamadas.push(true);
          errorLlam = true;
        } else this.errorLlamadas.push(false);
      }
      this.cdr.detectChanges();
      if(errorLlam) return;

      if(!this.currentUser.notif_llamadas_dia) {
        this.currentUser.notif_llamadas_ini_proc = moment(this.currentUser.notif_llamadas_ini || new Date()).startOf('second').utc();
        this.currentUser.notif_llamadas_fin_proc = moment(this.currentUser.notif_llamadas_fin || new Date()).startOf('second').utc();
      }
    }
    if(!this.currentUser.notif_zonas) {
      var errorLoc = false;
      this.errorLoc = [];
      for(let codigo of this.currentUser.notif_provincias) {
        if(codigo == "") {
          this.errorLoc.push(true);
          errorLoc = true;
        } else this.errorLoc.push(false);
      }
      this.cdr.detectChanges();
      if(errorLoc) return;
    }
    
    this.loading = true;
    this.cdr.detectChanges();

    var continuarGuardado = function (){
      var alertas = null;
      if(this.currentUser.proveedor) alertas = this.alertasFun
      else alertas = this.alertasAseg;

      var form = {
        id: this.currentUser.id, 
        telefonos_sms: this.currentUser.telefonos_sms, 
        telefonos_llamadas: this.currentUser.telefonos_llamadas, 
        codigo_telegram: this.currentUser.codigo_telegram, 
        notif_telegram: this.currentUser.notif_telegram, 
        notif_email: this.currentUser.notif_email, 
        notif_sms: this.currentUser.notif_sms, 
        notif_app: this.currentUser.notif_app, 
        notif_llamadas: this.currentUser.notif_llamadas,
        notif_llamadas_dia: this.currentUser.notif_llamadas_dia,
        notif_llamadas_ini: this.currentUser.notif_llamadas_ini_proc,
        notif_llamadas_fin: this.currentUser.notif_llamadas_fin_proc,
        notif_zonas: this.currentUser.notif_zonas,
        notif_pais: this.currentUser.notif_pais,
        notif_provincias: this.currentUser.notif_provincias,
        avisos: this.currentUser.avisos, 
        notificaciones: alertas};
      
      this.userService.update(form)
        .pipe(first())
        .subscribe(
          data => {
            var currentUser = this.authenticationService.currentUserValue;
            if(form.notif_telegram) currentUser.notif_telegram = form.notif_telegram;
            if(form.codigo_telegram) currentUser.codigo_telegram = form.codigo_telegram;
            if(form.telefonos_sms) currentUser.telefonos_sms = form.telefonos_sms;
            if(form.notif_email) currentUser.notif_email = form.notif_email;
            if(form.avisos) currentUser.avisos = form.avisos;
            if(form.notificaciones) currentUser.notificaciones = form.notificaciones;
            if(form.telefonos_llamadas) currentUser.telefonos_llamadas = form.telefonos_llamadas;
            if(form.notif_sms) currentUser.notif_sms = form.notif_sms;
            if(form.notif_llamadas) currentUser.notif_llamadas = form.notif_llamadas;
            if(form.notif_llamadas_dia) currentUser.notif_llamadas_dia = form.notif_llamadas_dia;
            if(form.notif_llamadas_ini) currentUser.notif_llamadas_ini = form.notif_llamadas_ini;
            if(form.notif_llamadas_fin) currentUser.notif_llamadas_fin = form.notif_llamadas_fin;
            if(form.notif_app) currentUser.notif_app = form.notif_app;
            if(form.notif_zonas) currentUser.notif_zonas = form.notif_zonas;
            if(form.notif_pais) currentUser.notif_pais = form.notif_pais;
            if(form.notif_provincias) currentUser.notif_provincias = form.notif_provincias;
            this.loadAlertas();
  
            this.currentUser = currentUser;
  
            this.authenticationService.changeUser(currentUser);
  
            this.loading = false;
            this.confirmationSwal.fire();
            this.cdr.detectChanges();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
          });
    }.bind(this);

    var checkTelegram = function (index: any) {
      console.warn(index);
      this.userService.checkTelegram({code: this.currentUser.codigo_telegram[index], lang: this.translate.currentLang})
      .pipe(first())
      .subscribe(
        data => {
          this.errorTelegramCode.push(false);
          if(index+1 == this.currentUser.codigo_telegram.length) {
            continuarGuardado();
          } else {
            checkTelegram(index+1);
          }
        },
        error => {
          this.loading = false;
          this.errorTelegramCode.push(true);
          this.errorTelegramSwal.fire();
          this.cdr.detectChanges();
      });
    }.bind(this);

    if(this.currentUser.notif_telegram) {
      this.errorTelegramCode = [];
      checkTelegram(0);
      this.cdr.detectChanges();
    } else {
      continuarGuardado();
    }

  }

  // ---- CERTIFICACIONES ----
  certificados = [];
  certificadoEditar = false;
  certificadoEditarObj = null;

  puntuaciones = [
    {texto_es: "Introduce la puntuación % NPS certificada", texto_en: "Enter the certified NPS % score", codigo: "% NPS", valor: null, error: false},
    {texto_es: "Introduce la puntuación NPS sectorial de tu país", texto_en: "Enter your country's sectorial NPS score", codigo: "NPS", valor: null, error: false}
  ]
  puntuacionOtro = null;
  puntuacionOtroValor = null;
  puntuacionOtroError = false;

  /**
   * Carga las certificados y puntuaciones del usuario
   */
  loadCertificaciones() {
    if(this.currentUser.certificados) {
      for(let cert of this.certificados) {
        for(let certUser of this.currentUser.certificados) {
          if(certUser.codigo == cert.codigo) {
            cert.valor = certUser.valor;
            break;
          }
        }
      }
    }
    if(this.currentUser.puntuaciones) {
      for(let puntUser of this.currentUser.puntuaciones) {
        var enc = false;
        for(let punt of this.puntuaciones) {
          if(puntUser.codigo == punt.codigo) {
            enc = true;
            punt.valor = puntUser.valor;
            break;
          }
        }
        if(!enc) {
          this.puntuacionOtro = puntUser.codigo;
          this.puntuacionOtroValor = puntUser.valor;
        }
      }
    }


    this.certificadoService.getByUser(this.currentUser.id)
    .subscribe(data => {

      for(let doc of data) {
        doc.fecha_subidaStr = moment(doc.fecha_subida).format("DD/MM/YYYY HH:mm");
        if(doc.fecha_validez) doc.fecha_validezStr = moment(doc.fecha_validez).format("DD/MM/YYYY");
        this.certificados.push(doc);
      }
      var datos = [...this.certificados];
      this.certificados = [];
      this.certificados = datos;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  /**
   * Guarda los certificados y puntuaciones del usuario
   */
  onSubmitCertificaciones(){
    //comprobamos -100 < x < 100
    var error = false;
    for(let punt of this.puntuaciones) {
      if(punt.valor != null && (punt.valor < -100 || punt.valor > 100)) {
        punt.error = true;
        error = true;
      } else punt.error = false;
    }
    if(this.puntuacionOtro && this.puntuacionOtroValor != null && (this.puntuacionOtroValor < -100 || this.puntuacionOtroValor > 100)) {
      this.puntuacionOtroError = true;
      error = true;
    } else this.puntuacionOtroError = false;

    this.cdr.detectChanges();
    if(error) return;

    this.loading = true;
    this.cdr.detectChanges();
    var form = {id: this.currentUser.id, /*certificados: this.certificados, */puntuaciones: JSON.parse(JSON.stringify(this.puntuaciones))};
    if(this.puntuacionOtro && this.puntuacionOtroValor) {
      form.puntuaciones.push({texto_es: "", codigo: this.puntuacionOtro, valor: this.puntuacionOtroValor})
    }
    
    this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          var currentUser = this.authenticationService.currentUserValue;
          //if(form.certificados) currentUser.certificados = form.certificados;
          if(form.puntuaciones) {
            currentUser.puntuaciones = form.puntuaciones;
          }

          this.currentUser = currentUser;

          this.authenticationService.changeUser(currentUser);

          this.loading = false;
          this.confirmationSwal.fire();
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
          this.cdr.detectChanges();
        });
  }

  /** Variables para la gestión de los certificados */
  files: File[] = [];
  image: any;
  newcert = {nombre: null, validez: null};
  errorCert = {nombre: false}
  loadingCert = false;
  nuevaVisible = false;

  /**
   * Cambia la visibilidad del cuadro de subida de documentos
   * @param estado Visibilidad del panel
   */
  mostrarSubida(estado) {
    this.nuevaVisible = estado;
    this.cdr.detectChanges();
  }

  /**
   * Evento de nuevo documento
   * @param event Información del fichero
   */
  onSelect(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);

    console.log(event.addedFiles[0]);
  }

  /**
   * Evento de documento borrado
   * @param event Información del fichero
   */
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  /**
   * Guarda o actualiza el certificado en base de datos
   */
  subirCertificado() {
    if(this.loadingCert) return;
    if(this.files.length == 0) {
      this.errorNotFileSwal.fire();
      return;
    }
    if(!this.newcert.nombre) {
      this.errorCert.nombre = true;
      this.cdr.detectChanges();
      return;
    } else {
      this.errorCert.nombre = false;
      this.cdr.detectChanges();
    }
    this.loadingCert = true;
    this.cdr.detectChanges();
    var doc = this.files[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = function (e:any) {
      console.log(myReader);

      if(!this.certificadoEditar) {
        var newDoc = {contenido: myReader.result, nombre: this.newcert.nombre, name: doc.name, fecha_subida: new Date(), fecha_validez: this.newcert.validez, estado: "REVISION", user: this.currentUser.id};

        this.certificadoService.post(newDoc)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
  
              data.fecha_subidaStr = moment(data.fecha_subida).format("DD/MM/YYYY HH:mm");
              if(data.fecha_validez) data.fecha_validezStr = moment(data.fecha_validez).format("DD/MM/YYYY");
              this.certificados = [...this.certificados, data];
              this.loadingCert = false;
              this.confirmationFileSwal.fire();
              this.filesDocs = [];
              this.files = [];
              this.newcert = {nombre: null, validez: null};
              this.errorCert = {nombre: false}
              this.mostrarSubida(false);
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loading = false;
            });
      } else {
        var newDoc2 = {id: this.certificadoEditarObj.id, contenido: myReader.result, nombre: this.newcert.nombre, motivo_rechazo: null, name: doc.name, fecha_subida: new Date(), fecha_validez: this.newcert.validez, estado: "REVISION", user: this.currentUser.id};

        this.certificadoService.update(newDoc2)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
  
              data.fecha_subidaStr = moment(data.fecha_subida).format("DD/MM/YYYY HH:mm");
              if(data.fecha_validez) data.fecha_validezStr = moment(data.fecha_validez).format("DD/MM/YYYY");
              //this.certificados = [...this.certificados, data];
              this.loadingCert = false;
              this.confirmationFileSwal.fire();
              this.filesDocs = [];
              this.files = [];
              this.newcert = {nombre: null, validez: null};
              this.errorCert = {nombre: false}
              this.mostrarSubida(false);
              this.certificados = [];
              this.loadCertificaciones();
              this.cdr.detectChanges();
              
            },
            error => {
              console.error(error);
              this.loading = false;
            });
      }

      
    }.bind(this)
    myReader.readAsDataURL(doc);
  }

  loadingBorrar = false;
  docBorrar = null;

  /**
   * Confirmación de borrado de certificado
   * @param doc 
   */
  borrarCert(doc: any) {
    this.docBorrar = doc;
    this.questionDeleteFileSwal.fire();
  }

  /**
   * Borra el certificado de la base de datos
   */
  borrarCert1() {
    this.docBorrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.certificadoService.delete(this.docBorrar.id)
    .subscribe(data => {
      console.log("OK", data);
      for(var i = 0; i < this.certificados.length; i++) {
        if(this.certificados[i].id == this.docBorrar.id){
          this.certificados.splice(i, 1);
          var datos = [...this.certificados];
          this.certificados = [];
          this.certificados = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteFileSwal.fire();
      this.loadingBorrar = false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Prepara las variables para la actualización de un certificado
   * @param row Objeto del certificado a actualizar
   */
  actualizarCert(row: any){
    this.certificadoEditar = true;
    this.certificadoEditarObj = row;
    this.nuevaVisible = true;
    this.newcert = {nombre: row.nombre, validez: row.fecha_validez};
    this.errorCert = {nombre: false}
    this.cdr.detectChanges();
  }

  // ---- FACTURAS ----

  /**
   * Descarga y visualiza una factura
   * @param row Factura a mostrar
   */
  viewInvoice(row: any) {
    this.paymentService.getFactura(row.facturaId)
      .subscribe(
        factura => {
          var win = window.open("_blank");
          win.document.write('<iframe src="' + factura.file  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        },error => {
          console.error(error);
          this.cdr.detectChanges();
      });
  }

  /**
   * Descarga una factura
   * @param row Factura a descargar
   */
  downloadInvoice(row: any) {
    this.paymentService.getFactura(row.facturaId)
      .subscribe(
        factura => {
          var a = document.createElement("a");
          a.href = factura.file;
          a.download = factura.Codigo + ".pdf";
          a.click();
        },error => {
          console.error(error);
          this.cdr.detectChanges();
      });
  }

  // ---- DISPOSITIVOS ----

  /**
   * Carga los dispositivos asociados
   */
  loadDispositivos() {
    this.userService.getDispositivos(this.currentUser.id)
	    .subscribe(disps => {
      for(let disp of disps){
        disp.fechaStr = moment(disp.fechaRegistro).format("DD/MM/YYYY HH:mm")
      }
			this.dispositivos = disps;
      this.cdr.detectChanges();
		});
  }

  dispositivoBorrar = null;

  /**
   * Confirmación de borrado de dispositivo
   * @param row Dispositivo a borrar
   */
  borrarDispositivo(row: any) {
    this.dispositivoBorrar = row;
    this.borrarDispSwal.fire();
  }

  /**
   * Borra un dispositivo asociado
   */
  borrarDispositivo1(){
    this.userService.deleteDispositivo(this.dispositivoBorrar.id)
      .pipe(first())
      .subscribe(
        data => {
          this.borrarDispConfirmSwal.fire();
          for(var i = 0; i < this.dispositivos.length; i++) {
            if(this.dispositivos[i].id == this.dispositivoBorrar.id){
              this.dispositivos.splice(i, 1);
              var datos = [...this.dispositivos];
              this.dispositivos = [];
              this.dispositivos = datos;
              this.cdr.detectChanges();

              this.loadDispositivos();
              break;
            }
          }
        },
        error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
        });
  }

  // ---- FUNERARIAS ----

  funerarias = [];
  funerariaEditar = false;
  funerariaEditarObj = null;
  funerariaNotif = false;
  funerariaNotifNombre = "";

  /**
   * Carga el listado de funerarias del usuario
   */
  loadFunerarias() {
    this.funerariaService.getByUser(this.currentUser.id)
    .subscribe(data => {
      this.funerarias = data;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  /** Variables para la gestión de funerarias */
  newFun = {nombre: null, user: null, email: null, domicilio: null, poblacion: null, cp: null, telefono: null, provincia: null, pais: null, paginaweb: null, borrado: false};
  errorFun = {nombre: false, email: false, domicilio: false, poblacion: false, cp: false, telefono: false, provincia: false, pais: false, paginaweb: false};
  loadingFun = false;
  nuevaVisibleFun = false;

  /**
   * Cambia la visibilidad del cuadro de creación de funeraria
   * @param estado Visibilidad del panel
   */
  mostrarNuevaFun(estado) {
    this.newFun = {nombre: null, user: this.currentUser.id, email: null, domicilio: null, poblacion: null, cp: null, telefono: null, provincia: null, pais: this.currentUser.pais.id, paginaweb: null, borrado: false};
    this.errorFun = {nombre: false, email: false, domicilio: false, poblacion: false, cp: false, telefono: false, provincia: false, pais: false, paginaweb: false};
    this.nuevaVisibleFun = estado;
    this.onChangePaisFun(null);
    this.cdr.detectChanges();
  }

  /**
   * Crea o edita una funeraria en la base de datos
   */
  guardarFuneraria() {
    if(this.loadingFun) return;
    var err = false;
    if(!this.newFun.nombre) { this.errorFun.nombre = true; err = true; 
    } else { this.errorFun.nombre = false; }
    if(!this.newFun.email) { this.errorFun.email = true; err = true; 
    } else { this.errorFun.email = false; }
    if(!this.newFun.domicilio) { this.errorFun.domicilio = true; err = true; 
    } else { this.errorFun.domicilio = false; }
    if(!this.newFun.poblacion) { this.errorFun.poblacion = true; err = true; 
    } else { this.errorFun.poblacion = false; }
    if(!this.newFun.cp) { this.errorFun.cp = true; err = true; 
    } else { this.errorFun.cp = false; }
    if(!this.newFun.telefono) { this.errorFun.telefono = true; err = true; 
    } else { this.errorFun.telefono = false; }
    if(!this.newFun.provincia) { this.errorFun.provincia = true; err = true; 
    } else { this.errorFun.provincia = false; }
    if(!this.newFun.pais) { this.errorFun.pais = true; err = true; 
    } else { this.errorFun.pais = false; }
    if(!this.newFun.paginaweb) { this.errorFun.paginaweb = true; err = true; 
    } else { this.errorFun.paginaweb = false; }

    if(err) {
      this.cdr.detectChanges();
      return;
    }
    this.loadingFun = true;
    this.cdr.detectChanges();

      if(!this.funerariaEditar) {
        this.funerariaService.post(this.newFun)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);

              this.funerarias = [...this.funerarias, data];
              this.loadingFun = false;
              this.confirmationFunSwal.fire();
              this.mostrarNuevaFun(false);
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loading = false;
            });
      } else {
        var newFun2 = this.newFun;
        newFun2["id"] = this.funerariaEditarObj.id;

        this.funerariaService.update(newFun2)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
  
              this.loadingFun = false;
              this.confirmationFunSwal.fire();
              this.mostrarNuevaFun(false);
              this.funerarias = [];
              this.loadFunerarias();
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loading = false;
            });
      }
  }

  loadingBorrarFun = false;
  funBorrar = null;

  /**
   * Confirmación de borrado de funeraria
   * @param fun Funeraria a borrar
   */
  borrarFun(fun: any) {
    this.funBorrar = fun;
    this.questionDeleteFunSwal.fire();
  }

  /**
   * Borrado de funeraria (Actualización a borrado: true)
   */
  borrarFun1() {
    this.funBorrar.loadingBorrarFun = true;
    this.cdr.detectChanges();

    this.loadingBorrarFun = true;

    var newFun2 = {id: this.funBorrar.id, borrado: true}

    this.funerariaService.update(newFun2)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);

          this.confirmationDeleteFunSwal.fire();
          this.funerarias = [];
          this.loadFunerarias();
          this.loadingBorrarFun = false;
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
        });


    return;

    this.certificadoService.delete(this.docBorrar.id)
    .subscribe(data => {
      console.log("OK", data);
      for(var i = 0; i < this.certificados.length; i++) {
        if(this.certificados[i].id == this.docBorrar.id){
          this.certificados.splice(i, 1);
          var datos = [...this.certificados];
          this.certificados = [];
          this.certificados = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteFileSwal.fire();
      this.loadingBorrar = false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Preparación del panel de funeraria para edición
   * @param row Funeraria a editar
   */
  actualizarFun(row: any) {
    this.funerariaNotif = false;
    this.funerariaEditar = true;
    this.funerariaEditarObj = row;
    this.nuevaVisibleFun = true;
    this.newFun = {nombre: row.nombre, user: row.user.id, email: row.email, domicilio: row.domicilio, poblacion: row.poblacion, cp: row.cp, telefono: row.telefono, provincia: row.provincia.id, pais: row.pais.id, paginaweb: row.paginaweb, borrado: false};
    this.onChangePaisFun(null);
    this.errorFun = {nombre: false, email: false, domicilio: false, poblacion: false, cp: false, telefono: false, provincia: false, pais: false, paginaweb: false};
    this.cdr.detectChanges();
  }

  /**
   * Preparación del panel de funeraria para edición
   * @param row Funeraria a editar
   */
  notificacionesFun(row: any) {
    this.funerariaNotifNombre = row.nombre;
    this.funerariaNotif = true;
    this.cdr.detectChanges();
  }

  estadosFun = [];

  /**
   * Evento de cambio de pais. Carga de comunidades.
   * @param event Información del evento.
   */
  onChangePaisFun(event){
    this.estadosFun = [];
    if(!this.funerariaEditar) this.newFun.provincia = '';
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.newFun.pais) {
        this.estadosFun.push(estado);
      }
    }
  } 

  // ---- ENTIDAD CORPORATIVA ----
  imgLogo = null;
  imgPresentacion = null;
  filesLogo: File[] = [];
  filesPresentacion: File[] = [];
  loadingLogo = false;
  loadingPresentacion = false;

  /**
   * Carga las imágenes de logo y presentación asociadas al usuario
   */
  loadImagenes() {
    if(this.currentUser.imgLogo) {
      this.image_userService.getById(this.currentUser.imgLogo)
      .subscribe(data => {
        this.imgLogo = data;
        this.cdr.detectChanges();
      });
    }
    if(this.currentUser.imgPresentacion) {
      this.image_userService.getById(this.currentUser.imgPresentacion)
      .subscribe(data => {
        this.imgPresentacion = data;
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Evento de selección de imagen logo
   * @param event Información del fichero
   */
  onSelectLogo(event) {
    console.log(event);
    if(event.addedFiles[0].type != "image/jpeg" && event.addedFiles[0].type != "image/jpg" && event.addedFiles[0].type != "image/gif" && event.addedFiles[0].type != "image/png"){
      this.errorFileSwal.fire();
      return;
    }
    this.filesLogo = [];
    this.filesLogo.push(...event.addedFiles);

    setTimeout(()=>{ 
      this.cdr.detectChanges()
    }, 150)
  }

  /**
   * Evento de borrado de imagen logo
   * @param event Información del fichero
   */
  onRemoveLogo(event) {
    this.filesLogo.splice(this.filesLogo.indexOf(event), 1);
  }

  /**
   * Evento de selección de imagen presentación
   * @param event Información del fichero
   */
  onSelectPresentacion(event) {
    console.log(event);
    if(event.addedFiles[0].type != "image/jpeg" && event.addedFiles[0].type != "image/jpg" && event.addedFiles[0].type != "image/gif" && event.addedFiles[0].type != "image/png"){
      this.errorFileSwal.fire();
      return;
    }
    this.filesPresentacion = [];
    this.filesPresentacion.push(...event.addedFiles);

    setTimeout(()=>{ 
      this.cdr.detectChanges()
    }, 150)
  }

  /**
   * Evento de borrado de imagen presentación
   * @param event Información del fichero
   */
  onRemovePresentacion(event) {
    this.filesPresentacion.splice(this.filesPresentacion.indexOf(event), 1);
  }

  /**
   * Sube las imágenes de logo o presentación a la base de datos
   * @param tipo Tipo de imagen a subir (LOGO/PRESENTACION)
   */
  subirImagen(tipo: any) {
    if(this.loadingLogo || this.loadingPresentacion) return;

    var doc = null;

    if(tipo == "LOGO") {
      if(this.filesLogo.length == 0) {
        this.errorNotFileSwal.fire();
        return;
      }
      this.loadingLogo = true;
      doc = this.filesLogo[0];
    } else if(tipo == "PRESENTACION") {
      if(this.filesPresentacion.length == 0) {
        this.errorNotFileSwal.fire();
        return;
      }
      this.loadingPresentacion = true;
      doc = this.filesPresentacion[0];
    }
    this.cdr.detectChanges();

    var myReader:FileReader = new FileReader();
    myReader.onloadend = function (e:any) {
      console.log(myReader);

      var newImg = {nombre: doc.name, contenido: myReader.result, fecha: new Date(), user: this.currentUser.id, tipo: tipo};
      
      this.image_userService.post(newImg)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);

            if(tipo == "LOGO") {
              this.imgLogo = data;
              this.filesLogo = [];

              var editUser = {id: this.currentUser.id, imgLogo: data.id}; 
              
              this.userService.update(editUser)
                .pipe(first())
                .subscribe(
                  data => {
                    this.loadingLogo = false;
                    this.cdr.detectChanges();
                  },
                  error => {
                    console.error(error);
                    this.loadingLogo = false;
                    this.cdr.detectChanges();
                  });
            } else {
              this.imgPresentacion = data;
              this.filesPresentacion = [];
          
              var editUser2 = {id: this.currentUser.id, imgPresentacion: data.id}; 
              
              this.userService.update(editUser2)
                .pipe(first())
                .subscribe(
                  data => {
                    this.loadingPresentacion = false;
                    this.cdr.detectChanges();
                  },
                  error => {
                    console.error(error);
                    this.loadingPresentacion = false;
                    this.cdr.detectChanges();
                  });
            }
          },
          error => {
            console.error(error);
            this.loading = false;
          });

      
    }.bind(this, tipo, doc)
    myReader.readAsDataURL(doc);
  }

  /**
   * Borra una imagen guardada de logo o presentación
   * @param tipo Tipo de imagen a borrar (LOGO/PRESENTACION)
   */
  borrarImagen(tipo: any) {
    var idBorrar = null;
    if(tipo == "LOGO") {
      idBorrar = this.imgLogo.id;
      this.loadingLogo = true;
    } else if(tipo == "PRESENTACION") {
      idBorrar = this.imgPresentacion.id;
      this.loadingPresentacion = true;
    }
    this.cdr.detectChanges();
    this.image_userService.delete(idBorrar)
    .subscribe(data => {
      console.log("OK", data);

      if(tipo == "LOGO") {
        this.imgLogo = null;
        
        var editUser = {id: this.currentUser.id, imgLogo: null}; 
              
        this.userService.update(editUser)
          .pipe(first())
          .subscribe(
            data => {
              this.loadingLogo = false;
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loadingLogo = false;
              this.cdr.detectChanges();
            });


      } else if(tipo == "PRESENTACION") {
        this.imgPresentacion = null;

        var editUser2 = {id: this.currentUser.id, imgPresentacion: null}; 
              
        this.userService.update(editUser2)
          .pipe(first())
          .subscribe(
            data => {
              this.loadingPresentacion = false;
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loadingPresentacion = false;
              this.cdr.detectChanges();
            });



      }
    });
  }


  // ---- SOPORTE TICKETS ----

  @ViewChild('confirmationTicketSwal', {static: false}) private confirmationTicketSwal: SwalComponent
  @ViewChild("popupDetalle", { static: false }) popupDetalle: DxPopupComponent
  tickets = [];

  tipos = [
    {codigo: "VENTA", abreviatura: "VTA", nombre: this.translate.instant('SOPORTE.TIPO.VENTA')}, 
    {codigo: "POSTVENTA", abreviatura: "PVT", nombre: this.translate.instant('SOPORTE.TIPO.POSTVENTA')}, 
    {codigo: "ADMINISTRACION", abreviatura: "ADM", nombre: this.translate.instant('SOPORTE.TIPO.ADMINISTRACION')}, 
    {codigo: "SAT", abreviatura: "SAT", nombre: this.translate.instant('SOPORTE.TIPO.SAT')}, 
    {codigo: "OTRO", abreviatura: "OTR", nombre: this.translate.instant('SOPORTE.TIPO.OTRO')}
  ]

  popupVisible = false;
  popupDetalleVisible = false;



  loadTickets() {
    this.tickets = [];
    this.ticketService.getByCreador(this.currentUser.id)
    .subscribe(tickets => {
      this.ticketService.getByDestinatario(this.currentUser.id)
      .subscribe(tickets2 => {

        let ticketsAll = tickets.concat(tickets2);

        for(let ticket of ticketsAll) {
          ticket.fechaStr = moment(ticket.fecha).format("DD/MM/YYYY HH:mm");
          ticket.fechaUltimoMensajeStr = moment(ticket.fechaUltimoMensaje).format("DD/MM/YYYY HH:mm");

          for(let mensaje of ticket.mensajes) {
            mensaje.fechaStr = moment(mensaje.fecha).format("DD/MM/YYYY HH:mm");
          }
        }
        //if(item.status == "INVITADO") mostrar = false;
        this.tickets = ticketsAll;

        console.warn(ticketsAll);
        
        this.cdr.detectChanges();
      });
    });
  }

  // --- NUEVO TICKET ---

  nuevo = {} as any;
  errorNuevo = {} as any;
  loadingSubmit = false;
  tiposHash = {};

  nuevoTicket() {
    this.nuevo = {
      codigo: null,
      creador: this.currentUser.id, // Default
      destinatario: 7,
      fecha: null,
      tipo: '',
      asunto: null,
      mensaje: null,
      estado: "NUEVO", // Default
      fechaUltimoMensaje: null,
      ultimoMensajeCreador: true, // Default
      leido: false, // Default
      creadorAdmin: false, // Default
      abierto: true // Default
    }

    this.errorNuevo = {
      tipo: null,
      asunto: null,
      mensaje: null
    }

    this.popupVisible = true;
  }

  enviarTicket() {
    var error = false;
    if(!this.nuevo.tipo) {
      this.errorNuevo.tipo = true;
      error = true;
    } else this.errorNuevo.tipo = false;

    if(!this.nuevo.asunto) {
      this.errorNuevo.asunto = true;
      error = true;
    } else this.errorNuevo.asunto = false;

    if(!this.nuevo.mensaje) {
      this.errorNuevo.mensaje = true;
      error = true;
    } else this.errorNuevo.mensaje = false;

    if(error) return;

    console.warn(this.nuevo);
    this.loadingSubmit = true;

    let tipoStr = this.nuevo.tipo

    let tipo = this.tiposHash[tipoStr];

    this.nuevo.tipo = tipo.codigo;
    
    this.nuevo.codigo = tipo.abreviatura + moment().format("YY") + this.currentUser.id + (moment().unix()+"").substring(2);
    this.nuevo.fecha = new Date();
    this.nuevo.fechaUltimoMensaje = new Date();

    this.cdr.detectChanges();

    console.warn(this.nuevo);

    this.ticketService.post(this.nuevo)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          
          var mensaje = {user: this.currentUser.id, ticket: data.id, mensaje: this.nuevo.mensaje, fecha: new Date(), leido: false, fechaLectura: null}

          this.ticketService.postMensaje(mensaje)
          .pipe(first())
          .subscribe(
            data => {
              this.nuevo.fechaStr = moment(this.nuevo.fecha).format("DD/MM/YYYY HH:mm");

              var info = {
                ticket: this.nuevo,
              }
      
              this.notificacionService.nuevo_ticket(info)
              .pipe(first())
              .subscribe(
                data => {
                  this.loadingSubmit = false;
                  this.cdr.detectChanges();
                  this.popupVisible = false;
                  this.cdr.detectChanges();
                  this.confirmationSwal.fire();
                  this.loadTickets();
                },
                error => {
                  console.error(error);
              });
            },
            error => {
              console.error(error);
              this.loadingSubmit = false;
              this.cdr.detectChanges();
            });


          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingSubmit = false;
          this.cdr.detectChanges();
        });




  }

  cancelarTicket() {
    this.popupVisible = false;
  }

  // --- NUEVO MENSAJE ---

  detalleTicket = null;
  nuevoMensaje = "";
  loadingEnviar = false;

  verTicket(row: any) {


    this.detalleTicket = row;

    this.nuevoMensaje = "";

    this.popupDetalle.instance.repaint();

    this.popupDetalleVisible = true;

    if(!this.detalleTicket.leido && ((this.detalleTicket.creadorAdmin && this.detalleTicket.ultimoMensajeCreador) || (!this.detalleTicket.creadorAdmin && !this.detalleTicket.ultimoMensajeCreador))) {
      // Marcar todo como leido
      var editTicket = {id: this.detalleTicket.id, leido: true};
      this.ticketService.update(editTicket)
        .pipe(first())
        .subscribe(
          data => {
            for(let mensaje of this.detalleTicket.mensajes) {
              if(!mensaje.leido) {
                let editMensaje = {id: mensaje.id, leido: true, fechaLectura: new Date()};
                this.ticketService.updateMensaje(editMensaje)
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.error(error);
                  });
              }
            }
          },
          error => {
            console.error(error);
          });
    }
  }

  enviarMensaje() {
    if(this.nuevoMensaje != '') {
      this.loadingEnviar = true;
      this.cdr.detectChanges();

      var mensaje = {
        user: this.currentUser.id,
        ticket: this.detalleTicket.id,
        mensaje: this.nuevoMensaje,
        fecha: new Date(),
        leido: false,
        fechaLectura: null,
        fechaStr: ""
      }

      this.ticketService.postMensaje(mensaje)
      .pipe(first())
      .subscribe(
        data => {
          mensaje.fechaStr = moment(mensaje.fecha).format("DD/MM/YYYY HH:mm");
          this.detalleTicket.mensajes.push(mensaje);

          var ultimoMensajeCreador = false;
          if(this.currentUser.id == this.detalleTicket.creador.id) ultimoMensajeCreador = true;

          var editTicket = {id: this.detalleTicket.id, fechaUltimoMensaje: data.fecha, ultimoMensajeCreador: ultimoMensajeCreador, leido: false, estado: "EN CURSO"};
          this.ticketService.update(editTicket)
            .pipe(first())
            .subscribe(
              data => {
                var info = {
                  ticket: this.detalleTicket,
                  mensaje: mensaje
                }

                this.notificacionService.nuevo_mensaje(info)
                .pipe(first())
                .subscribe(
                  data => {
                    this.nuevoMensaje = '';
                    this.loadingEnviar = false;
                    this.cdr.detectChanges();
                  },
                  error => {
                    console.error(error);
                });                
              },
              error => {
                console.error(error);
              });
          

        },
        error => {
          console.error(error);
          this.loadingEnviar = false;
          this.cdr.detectChanges();
        });
    }

    
  }

  cerrarMensaje() {
    this.popupDetalleVisible = false;
    this.loadTickets();
  }

  /** EVENTOS */


  loadEventos(){
    this.userService.getEventos(this.currentUser.id)
	    .subscribe(event => {
      for(let ev of event){
        ev.fechaStr = moment(ev.fecha).format("DD/MM/YYYY HH:mm")
      }
			this.eventos = event;
      this.cdr.detectChanges();
		});
  }

  nuevoTicketAdmin(user: any) {
    this.ngZone.run(() => this.router.navigate(['/admin-soporte/nuevo/' + user])).then();
  }


}
