/**
 * Variables globales utilizadas en el código de la aplicación
 * @author Informática Integral Vasca
 */
export class GlobalVariables {
    /** Objeto que mantiene los filtros al cambiar entre páginas */
    public static filters: any = {};
    /** Objeto que mantiene el orden de licitaciones al cambiar entre páginas */
    public static orden: any = {};
    /** Objeto que guarda el modo activo de la plataforma (WFN/WFP) */
    public static modoPlataforma = null;
}