import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-admin-blog-wfp',
  templateUrl: './admin-blog-wfp.component.html',
  styleUrls: ['./admin-blog-wfp.component.scss']
})
export class AdminBlogWfpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
