import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, B2bService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'kt-admin-pets-cementerio',
  templateUrl: './admin-pets-cementerio.component.html',
  styleUrls: ['./admin-pets-cementerio.component.scss']
})
export class AdminPetsCementerioComponent implements OnInit {

  @ViewChild('content10', {static: false}) private modalCliente: NgbModal
  @ViewChild('content9', {static: false}) private modalImagen: NgbModal
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('confirmationDeleteSwal', {static: false}) private confirmationDeleteSwal: SwalComponent
  @ViewChild('deleteAskSwal', {static: false}) private deleteAskSwal: SwalComponent
  //@ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  //@ViewChild('errorEmailSwal', {static: false}) private errorEmailSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private b2bService: B2bService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadPets();
  }



  async loadPets() {
    let pets = await this.b2bService.getAllPetsCementerio();
    for(let pet of pets) {
      pet.fechaStr = moment(pet.fecha).format("DD/MM/YYYY");
    }
    this.originalRows = pets;
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      //if(item.status == "INVITADO") mostrar = false;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

  nuevo() {
    this.editRow = {
      nombre: "",
      descripcion: null,
      imagen: null,
      fecha: null,
      mostrar: true,
    };
    this.edicion = false;
    this.modalService.open(this.modalCliente, { centered: true, size: 'md' } );
  }

  editRow = null as any;
  error = {} as any;
  edicion = false;
  loading = false;
  files: File[] = [];
  imgDetalle = null;
  

  async guardar() {
    let errors = false;
    if(!this.editRow.nombre || this.editRow.nombre == "") {
      this.error.nombre = true;
      errors = true;
    } else {
      this.error.nombre = false;
    }
    if(!this.editRow.descripcion || this.editRow.descripcion == "") {
      this.error.descripcion = true;
      errors = true;
    } else {
      this.error.descripcion = false;
    }
    if(this.files.length == 0) {
      this.errorNotFileSwal.fire();
      errors = true;
    }
    this.cdr.detectChanges();
    if(errors) return;

    this.loading = true;
    this.cdr.detectChanges();


    if(!this.edicion) { // GUARDAR NUEVO

      this.editRow.fecha = new Date();

      var doc = this.files[0];
      var myReader:FileReader = new FileReader();
      myReader.onloadend = async function (e:any) {

        this.editRow.imagen = myReader.result;

        let pet = await this.b2bService.postPetCementerio(this.editRow);

        //var newDoc = {contenido: myReader.result, comentario: this.documento, nombre: doc.name, fecha: new Date(), solicitud: this.currentRow.id};
        this.loading = false;

        this.modalService.dismissAll();
        this.confirmationAddSwal.fire();
        this.loadPets();

        this.files = [];
        
      }.bind(this)
      myReader.readAsDataURL(doc);

      

    }
    
  }

  onSelect(event) {
    if(event.addedFiles[0].type != "image/png" && event.addedFiles[0].type != "image/jpg" && event.addedFiles[0].type != "image/jpeg"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);
    this.cdr.detectChanges();
  }

  /**
   * Evento de documento borrado
   * @param event Información del fichero
   */
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.cdr.detectChanges();
  }

  async modifView(row: any) {
    row.mostrar = !row.mostrar;
    this.cdr.detectChanges();
    let edit = {mostrar: row.mostrar, id: row.id};
    await this.b2bService.updatePetCementerio(edit);
  }

  async verImagen(row: any) {
    this.modalService.open(this.modalImagen, { centered: true, size: 'lg' } );
    let img = await this.b2bService.getPetCementerio(row.id);
    this.imgDetalle = img.imagen;
  }


  deletePetObj = null;

  async deletePet(row) {
    this.deletePetObj = row;
    this.deleteAskSwal.fire();
  }

  async continuarDeletePet() {
    await this.b2bService.deletePetCementerio(this.deletePetObj.id);
    this.confirmationDeleteSwal.fire();
    this.loadPets();
  }

}
