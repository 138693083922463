import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'kt-admin-b2b-proveedores',
  templateUrl: './admin-b2b-proveedores.component.html',
  styleUrls: ['./admin-b2b-proveedores.component.scss']
})
export class AdminB2bProveedoresComponent implements OnInit {

  @ViewChild('content10', {static: false}) private modalCliente: NgbModal
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent
  @ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  @ViewChild('errorEmailSwal', {static: false}) private errorEmailSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadUsuarios();
  }



  async loadUsuarios() {
    let users = await this.userService.getB2BProveedores();
    this.originalRows = users;
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      if(item.status == "INVITADO") mostrar = false;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

  nuevo() {
    this.editRow = {
      isActive: true,
      b2b_tipo: "PETS",
      empresa: null,
      email: null,
      b2b_cliente: null,
      password: null,
      ofertante: false,
      proveedor: true,
    };
    this.edicion = false;
    this.modalService.open(this.modalCliente, { centered: true, size: 'md' } );
  }

  editRow = null as any;
  error = {} as any;
  edicion = false;
  loading = false;

  edit(row: any) {
    this.editRow = JSON.parse(JSON.stringify(row));
    this.edicion = true;

    this.modalService.open(this.modalCliente, { centered: true, size: 'md' } );
  }

  async guardar() {
    let errors = false;
    if(!this.editRow.empresa || this.editRow.empresa == "") {
      this.error.empresa = true;
      errors = true;
    } else {
      this.error.empresa = false;
    }
    if(!this.editRow.email || this.editRow.email == "") {
      this.error.email = true;
      errors = true;
    } else {
      this.error.email = false;
    }
    if(!this.editRow.b2b_tipo || this.editRow.b2b_tipo == "") {
      this.error.b2b_tipo = true;
      errors = true;
    } else {
      this.error.b2b_tipo = false;
    }
    this.cdr.detectChanges();
    if(errors) return;

    this.loading = true;
    this.cdr.detectChanges();

    if(!this.edicion) { // GUARDAR NUEVO

      try {
        await this.userService.registerB2B(this.editRow);
      } catch(e) {
        this.errorEmailSwal.fire();
        this.loading = false;
        return;
      }

      this.loading = false;

      this.modalService.dismissAll();
      this.confirmationAddSwal.fire();
      this.loadUsuarios();

    } else {
      try {
        await this.userService.updateB2B(this.editRow);
      } catch(e) {
        this.errorEmailSwal.fire();
        this.loading = false;
        return;
      }

      this.loading = false;

      this.modalService.dismissAll();
      this.confirmationEditSwal.fire();
      this.loadUsuarios();
    }
    
  }

}
