// Angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
// RxJS
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { environment } from '../../../../../environments/environment';

import { UserService, AuthenticationService, CountryService } from '../../../../_services';

@Component({
  selector: 'kt-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	role = null;
	submitted = false;

	paises = [];
	paisesHash = {};

	tarifa = null;
	tarifaStr = "";

	correcto = false;
	modo = "WFN";

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private userService: UserService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private countryService: CountryService
	) {
		this.unsubscribe = new Subject();

		this.route.paramMap.subscribe(params => {
			this.tarifa = params.get("tarifa");
			if(this.tarifa == "wfp") {
				this.tarifa = null;
				this.modo = "WFP";
			}

			if (this.authenticationService.currentUserValue) { 
				if(this.tarifa) {
					this.router.navigate(['/start/' + this.tarifa]);
				} else {
					this.router.navigate(['/']);
				}

			} else {
				if(this.tarifa && this.tarifa != "Aseguradora") {
					this.tarifaStr = this.tarifa.split('-')[0];
				}
			}

			
		})
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
		this.loadPaises();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
			this.cdr.detectChanges();
			for(var i = 0; i < paises.length; i++){
				this.paisesHash[paises[i].iso2+""] = paises[i];
			}

			this.http.get<any>(`https://ipapi.co/json/`)
			.subscribe(data => {
				console.warn(data);
				if(this.paisesHash[data.country_code]) {
					this.registerForm.get('pais').setValue(this.paisesHash[data.country_code].id);
				}
			});
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			pais: ['', Validators.compose([Validators.required])],
			//empresa: ['', Validators.compose([Validators.required])],
			actividad: ['', Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		if(this.loading) return;

		if((this.tarifa && this.tarifa != "Aseguradora") || this.modo == "WFP") {
			this.registerForm.controls['actividad'].setValue("Funerarias");
		}
		
		this.submitted = true;
		
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		var user = this.registerForm.value;
		user.cif = '';
		user.empresa = '';
		user.lang = this.translate.currentLang;
		user.status = "PENDIENTE";
		user.tarifaTemp = this.tarifa || '';
		user.demo = false;

		if(this.modo == "WFP") {
			user.wfp = true;
			localStorage.setItem('modo', "WFP");
		} else {
			user.wfn = true;
			localStorage.setItem('modo', "WFN");
		}

		this.userService.register(user)
            .pipe(first())
            .subscribe(
                data => {
					this.correcto = true;
					this.loading = false;
					this.cdr.detectChanges();

					this.hash = data["hashUser"];
					this.email = user.email;

					this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: user.email, actividad: user.actividad, hash: data["hashUser"], lang: this.translate.currentLang})
					.pipe(first())
					.subscribe(
						data => {
							//console.log("OK MAIL", data);
						},
						error => {
							console.error(error);
							this.loading = false;
						});
                },
                error => {
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
					this.loading = false;
                });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}

	/* VALIDAR CUENTA */

	email = null;
	hash = null;
	codigoValidacion = null;
	errorRequired = false;
	errorCodigo = false;

	validarCuenta() {
		var code = this.hash.substring(0,6);

		if(!this.codigoValidacion) {
			this.errorRequired = true;
			this.errorCodigo = false;
			return;
		}
		if(this.codigoValidacion != code) {
			this.errorRequired = false;
			this.errorCodigo = true;
			return;
		}

		this.router.navigateByUrl('/auth/validar?email=' + this.email + '&hash=' + this.hash);
		this.cdr.detectChanges();
	}

}
