import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';

/** 
 * Comprueba los condicionantes del estado de usuario antes de visitar las páginas.
 * @author Informática Integral Vasca
 */
@Injectable()
export class StartGuard implements CanActivate {
 
    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        private ngZone: NgZone) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser.status == "CONF-PENDIENTE" || currentUser.status == "CONF-OK" || currentUser.status == "CONF-RECHAZADO") {
            this.router.navigate(['/confirmacion']);
            console.warn("LINK");
            return false;
        } else if (currentUser.status != "INVITADO") {
            console.warn("LINK2");
            if(currentUser.pais && currentUser.pais.id == 142 && currentUser.ofertante && !currentUser.iban && currentUser.status == "ACTIVO") {
                this.router.navigate(['/pago']);
                return false;
            } else {
                return true;
            }
        } else {
            console.warn("LINK3");
            if(currentUser.tarifaTemp) {
                this.router.navigate(['/start/' + currentUser.tarifaTemp]);
                return false;
            } else {    //Siempre redirigimos al registro. No hay invitados.
                this.router.navigate(['/start']);
                return false;
            }
        }
    }
}