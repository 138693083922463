import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de tarifas.
 * @author Informática Integral Vasca
 */
@Injectable()
export class TarifaService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el listado completo de tarifas
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/tarifa`);
    }

    /**
     * Realiza una petición `GET` para obtener la tarifa por su ID
     * @param id Identificador de la tarifa a obtener
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/tarifa/${id}`);
    }
}