import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de tickets.
 * @author Informática Integral Vasca
 */
@Injectable()
export class TicketService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el ticket por su ID
     * @param id Identificador del ticket a obtener
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/ticket/${id}`);
    }

    /**
     * Realiza una petición `GET` para obtener los tickets de un usuario creador
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByCreador(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/ticket?creador=${user}&omit=mensajes`);
    }

    /**
     * Realiza una petición `GET` para obtener los tickets de un usuario destinatario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByDestinatario(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/ticket?destinatario=${user}&omit=mensajes`);
    }

    /**
     * Realiza una petición `POST` para guardar un ticket
     * @param ticket Contenido del ticket
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(ticket: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post<any>(`${environment.apiUrl}/ticket`, ticket, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar un ticket
     * @param ticket Contenido del ticket a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(ticket: any) {
        return this.http.patch(`${environment.apiUrl}/ticket/${ticket.id}`, ticket);
    }

    /**
     * Realiza una petición `DELETE` para borrar un ticket
     * @param id Identificador del ticket a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/ticket/${id}`);
    }

    /// ************************************ MENSAJES ************************************

    /**
     * Realiza una petición `GET` para obtener los mensajes de un ticket
     * @param ticket Identificador del ticket
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getMensajes(ticket: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/ticket_mensaje?ticket=${ticket}`);
    }

    /**
     * Realiza una petición `POST` para guardar un mensaje
     * @param mensaje Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postMensaje(mensaje: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post<any>(`${environment.apiUrl}/ticket_mensaje`, mensaje, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar un mensaje
     * @param mensaje Contenido del mensaje a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updateMensaje(mensaje: any) {
        return this.http.patch(`${environment.apiUrl}/ticket_mensaje/${mensaje.id}`, mensaje);
    }

    /**
     * Realiza una petición `DELETE` para borrar un mensaje
     * @param id Identificador del mensaje a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteMensaje(id: number) {
        return this.http.delete(`${environment.apiUrl}/ticket_mensaje/${id}`);
    }
}