import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de usuarios.
 * @author Informática Integral Vasca
 */
@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `POST` para registrar un nuevo usuario
     * @param user Contenido del usuario
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    register(user: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/register`, user, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para comprobar si un email está utilizado
     * @param email Dirección de correo a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    check(email: any) {
        console.log("CHECK");
        return this.http.patch(`${environment.apiUrl}/api/users/check`, { email: email }, {observe: 'response'});
    }

    /**
     * Realiza una petición `GET` para obtener el usuario por su ID
     * @param id Identificador del usuario a obtener
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/user/${id}`);
    }
    getByIdAsync(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/user/${id}`).toPromise();
    }
    
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/user?demo=0&sort=empresa ASC`);
    }
    
    getB2BAll() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?where={"b2b_tipo":["WFN","FP","BOREA"],"ofertante":1,"isActive":1}&sort=empresa ASC`).toPromise();
    }
    
    getB2BPres() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?where={"b2b_tipo":["WFN","FP"],"ofertante":1,"isActive":1}&sort=empresa ASC`).toPromise();
    }
    
    getB2B(tipo: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/user?b2b_tipo=${tipo}&ofertante=1&isActive=1&sort=empresa ASC`).toPromise();
    }


    
    /*getB2BActivas() {
      return this.http.get<any[]>(`${environment.apiUrl}/b2b_solicitud?where={"origen":["WFN","FP"],"estado":["PENDIENTE","ACEPTADA","ASIGNADA","PRESUPUESTAR","LLAMADA"]}&sort=fechaEntrada DESC`);
    }*/
    
    getB2BPets() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?b2b_tipo=PETS&ofertante=1&isActive=1&sort=empresa ASC`).toPromise();
    }
    
    getB2BPetsProv() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?b2b_tipo=PETS&proveedor=1&isActive=1&sort=empresa ASC`).toPromise();
    }
    
    getB2BClientes() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?actividad=WFN-AD&ofertante=1&sort=empresa ASC`).toPromise();
    }
    
    getB2BProveedores() {
      return this.http.get<any[]>(`${environment.apiUrl}/user?actividad=WFN-AD&proveedor=1&sort=empresa ASC`).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un usuario
     * @param user Contenido del usuario a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(user: any) {
        return this.http.patch(`${environment.apiUrl}/user/${user.id}`, user);
    }

    /**
     * Realiza una petición `GET` para obtener los bloqueos de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getBloqueosByUser(user: any) {
        return this.http.get<any>(`${environment.apiUrl}/user_bloqueo?user=${user}`);
    }

    /**
     * Realiza una petición `POST` para guardar un bloqueo
     * @param cert Contenido del bloqueo
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postBloqueo(bloqueo: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/user_bloqueo`, bloqueo, httpOptions);
    }

    /**
     * Realiza una petición `DELETE` para borrar un bloqueo
     * @param id Identificador del bloqueo a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteBloqueo(id: any) {
        return this.http.delete(`${environment.apiUrl}/user_bloqueo/${id}`);
    }

    /**
     * Realiza una petición `POST` para comprobar un código de telegram
     * @param info Información del código a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    checkTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para confirmar la utilización de un código de Telegram
     * @param info Información del código a confirmar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    confirmacionTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro_confirmacion`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para comprobar la utilización de un teléfono en Telegram
     * @param info Información del teléfono a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    checkPhoneTelegram(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/telegram/checkPhone`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para enviar códigos de confirmación vía SMS
     * @param info Información para el envío de SMS
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    confirmacionSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/confirmacion`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para comprobar un código SMS introducido
     * @param info Información del código a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    checkSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/check_code`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para enviar el correo de recuperación de contraseña
     * @param info Información de la cuenta a recuperar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    recovery(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recovery`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para comprobar la recuperación de la contraseña de una cuenta
     * @param info Información de la recuperación + nueva contraseña
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    recoveryCheck(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recoveryCheck`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para validar una cuenta
     * @param info Información de la cuenta a validar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    validar(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/validar`, info, httpOptions);
    }

    /**
     * Realiza una petición `GET` para comprobar si un usuario está autorizado en MX
     * @param cif Identificador del cliente (RFC) a comprobar
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    checkMexico(cif: any) {
      return this.http.get<any>(`${environment.apiUrl}/user_registro?RFC=${cif}`);
    }

    /**
     * Realiza una petición `GET` para obtener los dispositivos asociados a un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getDispositivos(user: any) {
      return this.http.get<any>(`${environment.apiUrl}/dispositivo?user=${user}`);
    }

    /**
     * Realiza una petición `DELETE` para borrar un dispositivo
     * @param id Identificador del dispositivo a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteDispositivo(id: any) {
      return this.http.delete(`${environment.apiUrl}/dispositivo/${id}`);
    }

    getEventos(user:any) {
      return this.http.get<any>(`${environment.apiUrl}/evento?user=${user}`);
    }


    registerB2B(user: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/api/users/registerB2B`, user, httpOptions).toPromise();
    }
    
    updateB2B(user: any) {
        return this.http.patch(`${environment.apiUrl}/api/users/updateB2B`, user, {observe: 'response'}).toPromise();
    }
    
    
    getPostalCode(long: any, lat: any) {
        return this.http.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?limit=1&types=place&language=es&access_token=${environment.mapBoxToken}&types=postcode`);
    }
    
    getRoute(longOrig: any, latOrig: any, longDest: any, latDest: any) {
        return this.http.get<any>(`https://api.mapbox.com/directions/v5/mapbox/driving/${longOrig},${latOrig};${longDest},${latDest}?access_token=${environment.mapBoxToken}`);
    }
}