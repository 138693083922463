import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de imágenes de usuario.
 * @author Informática Integral Vasca
 */
@Injectable()
export class Imagen_userService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener la imagen por su ID
     * @param id Identificador de la imagen a obtener
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/imagen_user/${id}`);
    }

    /**
     * Realiza una petición `GET` para obtener las imágenes de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/imagen_user?user=${user}`);
    }

    /**
     * Realiza una petición `POST` para guardar una imagen
     * @param img Contenido de la imagen
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(img: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/imagen_user`, img, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una imagen
     * @param img Contenido de la imagen a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(img: any) {
        return this.http.patch(`${environment.apiUrl}/imagen_user/${img.id}`, img);
    }

    /**
     * Realiza una petición `DELETE` para borrar una imagen
     * @param id Identificador de la imagen a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/imagen_user/${id}`);
    }
}