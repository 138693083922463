// Italiano
export const locale = {
	lang: 'it',
	data: {
		MENU: {
			SEGUIMIENTO: 'monitoraggio<br>delle mie offerte',
			SEGUIMIENTO2: 'monitoraggio delle mie offerte',
			EJECUCION: 'in<br>esecuzione',
			EJECUCION2: 'in esecuzione',
			TODAS: 'vedi tutte<br>le offerte',
			TODAS2: 'vedi tutte le offerte',
			CREAR: 'creare<br>appalto',
			CREAR2: 'creare appalto',
			CREAR_NAC: 'Servizio / Trasferimento nazionale',
			CREAR_INT: 'Trasferimento internazionale',
			CREAR_SERV: 'creare<br>servizio',
			CREAR2_SERV: 'creare servizio',
			EXPLORAR: 'esplorare i miei<br>appalti',
			EXPLORAR2: 'esplorare i miei appalti',
			EXPLORAR_SERV: 'esplorare i miei<br>servizi',
			EXPLORAR2_SERV: 'esplorare i miei servizi',
			PENDIENTES: 'in attesa<br>di assegnazione',
			PENDIENTES2: 'in attesa di assegnazione',
			TODAS_LIC: 'vedi tutti<br>gli appalti',
			TODAS_LIC2: 'vedi tutti gli appalti',
			CONTRATAR: 'ASSUMERE',
			PERFIL: 'Il mio profilo',
			LOGOUT: 'Chiudi sessione',
			MODO_WFN: 'WFN<br>Appalti',
			MODO_WFN2: 'WFN Appalti',
			MODO_INT: 'WFN Trasferimenti<br>Internazionali',
			MODO_INT2: 'WFN Trasferimenti Internazionali',
			MODO_WFP: 'WFP<br>Privati',
			MODO_WFP2: 'WFP Privati',
			MODO_PLANS: ' WFP<br>Piani Funerari',
			MODO_PLANS2: 'WFP Piani Funerari',
			MODO_PETS: 'WFP<br>Animali domestici',
			MODO_PETS2: 'WFP Animali domestici',

		},
		FOOTER: {
			CONTACTA: 'Contattaci',
			SEDES: 'Sedi a: Madrid, Messico, Lisbona e Colonia.',
			AVISO_LEGAL: 'Note legali',
			POLITICA: 'Politica sulla privacy'
		},
		HOME: {
			BIENVENIDA: 'Benvenut@ nell\'area appalti di WFN',
			BIENVENIDA2: 'Benvenut@ nell\'area appalti di WFP',
			BIENVENIDA3: 'Benvenut@ nell\'area richieste di BOREA',
			INVITADO1: 'Stai accedendo alla nostra piattaforma come ospite.',
			INVITADO2: 'Ciò significa che potrai vedere gli appalti in corso, ma non potrai fare offerte o pubblicarne di nuovi.',
			DEMO: 'Stai accedendo alla nostra piattaforma demo. Ciò significa che potrai testare tutte le sue funzionalità con esempi fittizi di appalti.',
			CONTRATAR: 'ASSUMERE ORA',
			PAGO: 'Pagamento dell\'abbonamento pendente.',
			PAGO2: 'Non ci hai ancora fornito i dati di pagamento del tuo abbonamento. Clicca sul pulsante per accedere alla piattaforma di pagamento sicuro.',
			PLATAFORMA_PAGO: 'PIATTAFORMA DI PAGAMENTO SICURO'
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Accedi',
				BUTTON: 'Accedi',
				BIENVENIDA1: 'Benvenuto al processo di assunzione della tua tariffa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Accedi per associare il tuo account a questa tariffa.',
				BIENVENIDA2: 'Benvenuto al processo di assunzione di WFN',
				BIENVENIDA2_SUB: 'Accedi per continuare.',
				FORGOT_BUTTON: 'Hai dimenticato la password?',
				REGISTER: 'Non hai un account?',
				REGISTER_BUTTON: 'Registrati',
				VOLVER: 'Torna al login'
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Conferma Password'
			},
			FORGOT: {
				TITLE: 'Recupera password',
				DESC: 'Inserisci di seguito il codice ricevuto via email e la nuova password',
				SUCCESS: 'La tua password è stata recuperata correttamente. Accedi con le tue nuove credenziali per continuare.',
				INCORRECTO: 'Il codice di recupero inserito non è corretto.',
				INVALID: 'Questo link di recupero non è valido o è già stato utilizzato.',
				LOGIN: 'ACCEDI',
				CODIGO: 'Codice di recupero (4 cifre)',
				BUTTON: 'RECUPERA PASSWORD',
				EMAIL: 'Se l\'email inserita è registrata nel sistema, ti è stata inviata un\'email di recupero. Segui le istruzioni per continuare.'
			},
			REGISTER: {
				TITLE: 'Registrati',
				BIENVENIDA1: 'Benvenuto al processo di assunzione della tua tariffa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Prima di tutto creeremo un account associato alla tua tariffa.',
				BIENVENIDA2: 'Benvenuto al processo di assunzione di WFN',
				BIENVENIDA2_SUB: 'Prima di tutto creeremo un account.',
				EMAIL_CORP: 'Solo email aziendali (es. @tua-azienda.com) sono accettate',
				PAIS: 'Paese',
				PAIS_SELECT: 'Seleziona un paese...',
				PERTENEZCO: 'Appartengo a',
				PERTENEZCO_SELECT: 'Seleziona un settore...',
				SECTOR1: 'Pompe funebri',
				SECTOR2: 'Compagnie di assicurazione',
				SECTOR3: 'Fondi pensione',
				SECTOR4: 'Banca assicurazioni',
				SECTOR5: 'Compagnie di previdenza e prenecesità',
				SECTOR6: 'Ambasciate',
				SECTOR7: 'Gruppi con copertura funeraria',
				SECTOR8: 'Altri',
				BUTTON: 'REGISTRARMI',
				LOGIN: 'Hai già un account?',
				LOGIN_BUTTON: 'Accedi',
				SUCCESS: 'Registrazione completata',
				SUCCESS_SUB: 'Ti diamo il benvenuto in World Funeral Net!',
				SUCCESS_MAIL: 'Abbiamo inviato un\'email di conferma all\'indirizzo di registrazione. Per favore, inserisci il codice di seguito o clicca sul link incluso nell\'email per accedere a tutte le funzionalità della piattaforma.',
				SUCCESS_MAIL2: 'Abbiamo inviato un\'email di conferma all\'indirizzo di registrazione. Per favore, inserisci il codice di seguito o clicca sul link incluso nell\'email per procedere con l\'assunzione della tua tariffa.',
				ERROR_CODE: 'Il codice inserito non è corretto.',
				VALIDAR_BUTTON: 'VALIDARE ACCOUNT',
				GRACIAS: 'Grazie mille per la tua fiducia.'
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: 'Validando il tuo account, un momento per favore...',
				SUCCESS: 'Account validato',
				SUCCESS_DESC: 'Il tuo account è stato validato correttamente. Procederemo con la registrazione in pochi secondi...',
				ERROR: 'Errore nella validazione',
				ERROR_DESC: 'Il tuo link di validazione non è corretto, controlla la tua email e usa l\'ultimo link generato.'
			},
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} deve essere inserito',
				MIN_LENGTH: 'La lunghezza minima di {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare i termini e le condizioni',
				NOT_FOUND: 'Il {{name}} richiesto non è stato trovato',
				INVALID_LOGIN: 'I dati inseriti non sono corretti. Riprova.',
				INACTIVE: 'Il tuo account è inattivo. Controlla la tua email e clicca sul link di validazione.',
				REQUIRED_FIELD: 'Devi compilare questo campo',
				MIN_LENGTH_FIELD: 'La lunghezza minima di questo campo è:',
				MAX_LENGTH_FIELD: 'La lunghezza massima di questo campo è:',
				INVALID_FIELD: 'Questo campo non è valido',
				MATCH: 'La password e la sua conferma non corrispondono',
				INVALID_REGISTER: 'L\'email inserita è già registrata. Riprova o accedi.'
			}
		},
		DEMO: {
			LOADING: 'Preparando la piattaforma, un momento per favore...',
			BIENVENIDA: 'Ti diamo il benvenuto in WFN',
			SECTOR: 'Seleziona il settore di appartenenza per accedere alla piattaforma dimostrativa.',
			FUNERARIA: 'SONO UN OPERATORE FUNEBRE',
			ASEGURADORA: 'SONO UNA COMPAGNIA DI ASSICURAZIONI, AZIENDA O GRUPPO',
			HOME: {
				STEP1: 'Benvenut@ in WFN! Questa è la piattaforma dimostrativa. Scopriamo le funzionalità della piattaforma e poi potrai interagire con essa e fare tutte le prove che desideri.',
				STEP2: 'Questo è il menu principale. Da qui potrai accedere alle diverse pagine disponibili.',
				STEP3: 'Qui potrai monitorare gli appalti a cui hai partecipato.',
				STEP4: 'Qui vengono mostrati gli appalti che ti sono stati assegnati. In essi potrai consultare le informazioni del vincitore e compilare la tracciabilità.',
				STEP5: 'Infine, qui viene mostrato l\'elenco completo degli appalti disponibili. Andiamo in questa pagina per vederli più nel dettaglio.',
				STEP6: 'Potrai offrire i tuoi appalti in questo menu. Inserisci i dettagli e verranno pubblicati nell\'elenco. Potrai provarlo al termine di questa dimostrazione.',
				STEP7: 'Una volta creati, troverai in questa pagina tutti i tuoi appalti.',
				STEP8: 'Quando un appalto sarà concluso, potrai trovare tutte quelle in attesa di assegnazione a un\'impresa funebre in questa schermata.',
				STEP9: 'Qui vengono mostrati gli appalti che hai già assegnato a un\'impresa funebre e sono in esecuzione. In essi potrai consultare le informazioni dell\'impresa funebre e visualizzare la sua tracciabilità.',
				STEP10: 'Infine, qui viene mostrato l\'elenco completo degli appalti disponibili. Andiamo in questa pagina per vederli più nel dettaglio.',
			},
			LICIT: {
				STEP1: 'Benvenut@ in WFN! Questa è la piattaforma dimostrativa. Scopriamo le funzionalità della piattaforma e poi potrai interagire con essa e fare tutte le prove che desideri.',
				STEP2: 'Ogni "card" che vedi è un appalto messo da una compagnia di assicurazioni o gruppo. In essa troverai tutte le informazioni e dettagli sui servizi, come la localizzazione, il tempo rimanente, l\'importo attuale, lo stato del servizio, ecc.',
				STEP3: 'Nella parte superiore troverai il riferimento dell\'appalto, insieme alla data di creazione. Inoltre, potrai eliminarle in qualsiasi momento (i partecipanti saranno avvisati) e modificarle quando non ci sono ancora partecipazioni. Se l\'appalto rimane deserto, potrai anche modificarlo una volta concluso.',
				STEP4: 'Nella parte superiore troverai il riferimento assegnato dal sistema all\'appalto, insieme alla data di creazione.',
				STEP5: "Questi sono i servizi che devono essere forniti in un appalto. Cliccandoci sopra potrai vedere il loro dettaglio generale. I servizi con l'icona <b style='color: red'>+</b> indicano che l'offerente ha incluso un dettaglio specifico",
				STEP6: 'Successivamente viene indicata la localizzazione in cui verrà realizzato il servizio o l\'origine e la destinazione nei trasferimenti',
				STEP7: 'Questo è il tempo rimanente per la conclusione dell\'appalto e quindi il tempo per fare un\'offerta.',
				STEP8: 'In questo spazio osserverai lo stato attuale dell\'appalto. Questo può essere aperto, in attesa di assegnazione, in attesa di esecuzione, completato, chiuso o deserto.',
				STEP9: 'Infine troverai l\'importo attuale dell\'appalto e potrai accedere al dettaglio delle sue offerte o iscritti.',
				STEP10: 'Una volta assegnato un appalto potrai vedere il progresso della tracciabilità e i dettagli dell\'impresa funebre assegnata.',
				STEP11: 'Inoltre, nel seguente menu potrai vedere il dettaglio della tracciabilità che sarà compilato dall\'impresa funebre.',
				STEP12: 'In questo spazio osserverai lo stato attuale dell\'appalto. Questo può essere aperto (vincendo/perdendo/iscritto), in corso, in esecuzione, completato o deserto.',
				STEP13: 'Infine troverai l\'importo attuale dell\'appalto e potrai fare un\'offerta o iscriverti allo stesso.',
				STEP14: 'Una volta che ti è stato assegnato un appalto potrai vedere i dettagli dell\'azienda aggiudicatrice.',
				STEP15: 'Se l\'appalto è in esecuzione potrai compilare le informazioni sulla tracciabilità del servizio.',
				STEP16: 'Potrai personalizzare la vista delle card in qualsiasi momento con i diversi filtri disponibili.',
				STEP17: 'Questo è tutto. Ricorda che ti trovi nella piattaforma dimostrativa. Potrai interagire con gli appalti disponibili, crearne di nuovi e fare tutte le prove che desideri.',
				STEP18: 'Questo è tutto. Ricorda che ti trovi nella piattaforma dimostrativa. Potrai interagire con gli appalti disponibili, fare offerte o iscriverti e fare tutte le prove che desideri.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Elaborando l\'assunzione...',
			SUCCESS: 'Assunzione completata',
			SUCCESS_SUB: 'Sarai reindirizzato alla piattaforma in pochi secondi...',
			PENDING: 'Conferma dell\'account pendente',
			PENDING_SUB: 'Prima di continuare, il tuo account deve essere confermato dall\'organizzazione. L\'organizzazione si riserva il diritto di ammissione, valuteremo il tuo ingresso in 24-48 ore e potrai completare il processo di assunzione.',
			PAGO: 'Accedendo alla conferma del pagamento...'
		},
		PAGO: {
			LOADING: 'Accedendo alla piattaforma di pagamento sicuro...',
			RESULT_LOADING: 'Caricando i dati dell\'abbonamento...',
			METODO: 'Selezione del metodo di pagamento',
			METODO_SUB: 'Seleziona il tuo metodo di pagamento per completare l\'attivazione del tuo account.',
			METODO_TARJETA: 'PAGAMENTO CON CARTA DI CREDITO',
			METODO_SEPA: 'PAGAMENTO CON DOMICILIAZIONE BANCARIA (SEPA)',
			SEPA_TITLE: 'Pagamento con domiciliazione bancaria - SEPA',
			SEPA_NOMBRE: 'Nome del titolare',
			SEPA_IBAN: 'Numero di conto (IBAN)',
			SEPA_CODIGO: 'Codice promozionale',
			SEPA_ERRORCOD: 'Il codice inserito non è corretto',
			SEPA_BUTTON: 'Iscriviti a WFuneralNet',
			SEPA_LOADING: 'Elaborazione dei dati...',
			SEPA_LEGAL: 'Fornendo le tue informazioni di pagamento e confermando questo pagamento, autorizzi WFuneralNet e Stripe, il nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per addebitare l\'importo sul tuo conto e alla tua banca per addebitare l\'importo sul tuo conto secondo tali istruzioni. Come parte dei tuoi diritti, hai il diritto a un rimborso dalla tua banca secondo i termini e le condizioni del tuo accordo con la tua banca. I tuoi diritti sono spiegati in una dichiarazione che puoi ottenere dalla tua banca. Accetti di ricevere notifiche di addebiti futuri fino a 2 giorni prima che avvengano.',
			SUCCESS: 'Il tuo abbonamento è stato attivato correttamente.',
			SUCCESS_SUB: 'Puoi iniziare a utilizzare la piattaforma con tutte le sue funzionalità. Clicca sul pulsante seguente per accedere all\'inizio.',
			SUCCESS_BUTTON: 'INIZIA A UTILIZZARE WORLD FUNERAL NET',
			CANCEL: 'Hai annullato il processo di abbonamento.',
			CANCEL_SUB: 'Se si è verificato un errore o desideri riprovare, clicca sul pulsante seguente per accedere alla piattaforma di pagamento sicuro.',
			CANCEL_BUTTON: 'CONTINUA ALLA PIATTAFORMA DI PAGAMENTO SICURO',
			WFP: 'Nessun addebito verrà effettuato da WFP fino alla realizzazione del servizio. Pagherai il 10% del prezzo a cui è stata aggiudicata l\'offerta.',
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra a tuo piacimento',
				INVITADO: 'I filtri sono disattivati per l\'account ospite.',
				CONTRATAR: 'Assumere',
				REFERENCIA: 'Riferimento',
				SERVICIO: 'Servizio',
				LOC_ORIGEN: 'Localizzazione di origine',
				LOC_DESTINO: 'Localizzazione di destinazione',
				TODOS: 'Tutti',
				PAIS: 'Paese',
				COMUNIDAD: 'Comunità autonoma',
				ESTADO: 'Stato',
				FECHA_DESDE: 'Data da',
				FECHA_HASTA: 'Data a',
				PRECIO_MIN: 'Prezzo min.',
				PRECIO_MAX: 'Prezzo max.',
				ESTADO_LIC: 'Stato dell\'appalto',
				ESTADOS: {
					PENDIENTE_INI: 'In attesa di inizio',
					ABIERTA: 'Aperta',
					PENDIENTE_ADJ: 'In attesa di assegnazione',
					ADJ_TRAM: 'Adj. in corso',
					ADJ_PEN: 'Adj. e in attesa',
					EJEC: 'In esecuzione',
					COMPLETADA: 'Completata',
					CERRADA: 'Chiusa',
					DESIERTA: 'Deserta'
				},
				REESTABLECER: 'Reimposta filtri'
			},
			ORDEN: {
				ESTADO: 'Ordina per stato dell\'appalto',
				ESTADO_ABIERTAS: 'Mostra prima aperte',
				ESTADO_PENDIENTE: 'Mostra prima in attesa',
				ESTADO_ADJUD: 'Mostra prima assegnate',
				FECHA: 'Ordina per data di inizio',
				FECHA_ASC: 'Ascendente',
				FECHA_DESC: 'Discendente',
				TIEMPO: 'Ordina per tempo fino alla conclusione',
				TIEMPO_ASC: 'Ascendente',
				TIEMPO_DESC: 'Discendente'
			},
			LOADING: 'Caricamento appalti...',
			LOADING_AD: 'Caricamento servizi...',
			NOHAY: 'Non ci sono appalti',
			CARD: {
				ORIGEN: 'Origine',
				DESTINO: 'Destinazione',
				TRASLADO: 'Trasferimento',
				TRASLADO_INT: 'Int.',
				LOCALIZ: 'Localizzazione',
				LOC_WFP1: 'Localizzazione deceduto',
				LOC_WFP2: 'Localizzazione camera ardente',
				SIN_PRECIO: 'In corso',
				PUJA: 'offerta',
				OFERTA: 'offerta',
				PUJAR: 'fare un\'offerta',
				OFERTAR: 'fare un\'offerta',
				LA: 'La',
				TIME: 'Tempo rimanente per',
				COMIENZA: 'inizia in',
				VER_ASEG: 'vedi assicuratore',
				VER_FUN: 'vedi impresa funebre',
				VER_PART: 'vedi privato',
				VER_DETALLES: 'vedi dettagli',
				ESTADOS: {
					PENDIENTE_INI: 'In attesa di inizio',
					ABIERTA: 'Aperta',
					INSCRITO: 'Iscritto',
					GANANDO: 'Vincendo',
					PERDIENDO: 'Perdendo',
					CONTU: 'con la tua',
					PUJA: 'offerta',
					OFERTA: 'offerta',
					DE: 'di',
					PENDIENTE_ADJ: 'In attesa',
					PENDIENTE_ADJ2: 'di assegnazione',
					CERRADA: 'Chiusa',
					ADJ_TRAM: 'Assegnazione in corso',
					ADJ_PEN: 'Assegnata e',
					ADJ_PEN2: 'in attesa di esecuzione',
					ADJ_PEN3: 'in attesa di conferma',
					ADJ_PEN4: 'rifiutata dall\'impresa funebre',
					PEND_ACEPT: 'In attesa di conferma',
					EJEC: 'In esecuzione',
					COMPLETADA: 'Completata',
					NO_ADJ: 'Non assegnato',
					RECHAZADA: 'Rifiutata',
					DESIERTA: 'Deserta'
				},
				PUJA_SALIDA: 'Offerta iniziale',
				OFERTA_SALIDA: 'Offerta iniziale',
				PUJA_ACTUAL: 'Offerta attuale',
				OFERTA_ACTUAL: 'Offerta attuale',
				PUJA_GANADORA: 'Offerta vincente',
				OFERTA_GANADORA: 'Offerta vincente',
				PUJA_DESIERTA: 'Offerta deserta',
				OFERTA_DESIERTA: 'Offerta deserta',
				PUJA_ADJUDIC: 'Offerta assegnata',
				OFERTA_ADJUDIC: 'Offerta assegnata',
				PRECIO_FIJO: 'Prezzo fisso',
				ASIG_DIRECTA: 'Assegnazione diretta',
				VER_PUJAS: 'VEDI OFFERTE',
				VER_OFERTAS: 'VEDI OFFERTE',
				INSCRITOS: 'ISCRITTI',
				ADJUDICAR: 'ASSEGNARE',
				TRAZABILIDAD: 'TRAZABILITÀ',
				INSCRÍBETE: 'ISCRIVITI',
				ACEPTAR: 'ACCETTARE',
				RECHAZAR: 'RIFIUTARE',
				PUJAR2: 'FARE UN\'OFFERTA',
				OFERTAR2: 'FARE UN\'OFFERTA',
				VOLVER_PUJAR: 'FARE UN\'ALTRA OFFERTA',
				VOLVER_OFERTAR: 'FARE UN\'ALTRA OFFERTA',
				IVA_INC: 'IVA non inclusa',
				HIST: {
					HISTORICO_PUJAS: 'Storico delle offerte',
					HISTORICO_OFERTAS: 'Storico delle offerte',
					HISTORICO_INSC: 'Storico degli iscritti',
					IMPORTE_SALIDA: 'Importo iniziale',
					ACTUALIZAR: 'aggiornare',
					TOTAL: 'Totale',
					FECHA: 'Data',
					USUARIO: 'Utente',
					IMPORTE: 'Importo',
					DESIERTA: 'Appalto deserto',
					CREADOR: 'CREATORE',
				},
				CERRAR: 'chiudi',
				ASIG: {
					NOMBRE: 'Nome',
					LICENCIA: 'Licenza',
					DOMICILIO: 'Domicilio',
					TELEFONO: 'Telefono',
					EMAIL: 'Email',
					WEB: 'Pagina Web',
					CERTIFICACIONES: 'Certificazioni',
					PUNTUACIONES: 'Punteggi',
					IMPORTE: 'Importo appalto',
					ADJUDICAR: 'ASSEGNARE',
					EXCLUIR: 'ESCLUDERE',
					EXCLUIR_TODAS: 'ESCLUDERE TUTTI',
					BLOQUEAR: 'BLOCCARE FORNIT.',
					VER_DETALLES: 'vedi dettagli',
					CERRAR_DETALLES: 'chiudi dettagli',
					DETALLE_ASIG1: 'L\'assicurazione si riserva il diritto di assegnazione valutando la qualità dei servizi offerti dalla funeraria.',
					DETALLE_ASIG11: 'Il privato si riserva il diritto di assegnazione valutando la qualità dei servizi offerti dalla funeraria.',
					DETALLE_ASIG2: 'È importante che completi il tuo profilo con valutazioni e certificati di qualità.',
					DETALLE_ASIG_BTN: 'Completare il profilo',
					REFERENCIA: 'Riferimento appalto WFN',
					REFERENCIA2: 'Riferimento appalto creatore',
					SIGUIENTES_PUJAS: 'Offerte successive',
					SIGUIENTES_OFERTAS: 'Offerte successive',
					FUNERARIA: 'Funeraria offerente',
					PARTICULAR: 'Informazioni sul privato'
				},
				PUJAS: {
					PUJA_RAPIDA: 'Offerta rapida',
					OFERTA_RAPIDA: 'Offerta rapida',
					PUJA_MANUAL: 'o inserisci manualmente la tua offerta',
					OFERTA_MANUAL: 'o inserisci manualmente la tua offerta',
					PUJA_WFP: 'Inserisci la tua offerta',
					OFERTA_WFP: 'Inserisci la tua offerta',
					PUJAR: 'FARE UN\'OFFERTA',
					OFERTAR: 'FARE UN\'OFFERTA',
					AVISO: 'Avviso',
					PRORROGA3_PUJA: 'L\'appalto verrà prorogato di 3 minuti se fai un\'offerta durante l\'ultimo minuto',
					PRORROGA3_OFERTA: 'L\'appalto verrà prorogato di 3 minuti se fai un\'offerta durante l\'ultimo minuto',
					PRORROGA1_PUJA: 'L\'appalto verrà prorogato di 1 minuto se fai un\'offerta durante l\'ultimo minuto',
					PRORROGA1_OFERTA: 'L\'appalto verrà prorogato di 1 minuto se fai un\'offerta durante l\'ultimo minuto',
					PROCESO_PUJA: 'La tua offerta è in fase di elaborazione. Un momento per favore...',
					PROCESO_OFERTA: 'La tua offerta è in fase di elaborazione. Un momento per favore...',
					HISTORICO_PUJAS: 'Vedi storico delle offerte',
					HISTORICO_OFERTAS: 'Vedi storico delle offerte',
					FUNERARIA: 'Seleziona la funeraria',
					FUNERARIA2: 'Funeraria selezionata'
				},
				TRAZA: {
					INCIDENCIA: 'Incidente nel servizio',
					VALIDAR: 'VALIDARE',
					CANCELAR: 'ANNULLARE',
					FECHA: 'Data',
					LUGAR: 'Luogo'
				},
				SERVICIO: {
					TIPO_TRASLADO: 'Tipo di trasferimento',
					CARRETERA: 'Strada da punto a punto',
					AEROPUERTO: 'Aeroporto',
					INCLUIDOS: 'Servizi inclusi',
					TIPO_COMPLETO: 'Tipo di servizio completo',
					CREMACION: 'Cremazione + Urna',
					DESTFIN: 'Sepoltura',
					DETALLE_ESP: 'Dettaglio specifico del servizio',
					ESPEC_GEN: 'Specifiche generali del servizio',
					TRASLADO1: 'Il decesso e la destinazione finale (sepoltura o incinerazione) avvengono in località diverse.',
					TRASLADO2: 'Elementi e servizi abituali del trasferimento:',
					TRASLADO3: 'Cassa per trasferimento: Nei casi in cui il Regolamento di Polizia Sanitaria Mortuaria (RPSM) lo richieda, deve essere dotata inoltre di un dispositivo di filtraggio dell\'aria o altro per equilibrare la pressione interna ed esterna.',
					TRASLADO4: 'Nolo aereo se necessario, Km percorsi su strada e gestioni e procedure specifiche.',
					WFP_TIPO: 'Tipologia di servizio',
					WFP_TIPO_CREMA: 'Cremazione',
					WFP_TIPO_INHUM: 'Sepoltura',
					WFP_CEREM: 'Cerimonia',
					WFP_CIVIL: 'Civile',
					WFP_RELIG: 'Religiosa',
					WFP_CATERING: 'Nº di persone',
					WFP_CORONAS: 'Nº di corone',
					WFP_OTROS: 'Altre specifiche',
					WFP_MULTIPLE: 'Decesso multiplo',
					WFP_PERSONAS: 'Persone'
				}
			},
			SWAL: { 
				CANCELAR: 'Annulla',
				registerSwal: 'Assunzione necessaria',
				registerSwal_TEXT: 'Questo contenuto non è disponibile con un account ospite. Assumi una tariffa per continuare.',
				registerSwal_BTN1: 'Assumere',
				suscriptionSwal: 'Appalto non disponibile',
				suscriptionSwal_TEXT: 'Questo contenuto non è disponibile con il tuo abbonamento. Controlla la tua tariffa per accedere.',
				suscriptionSwal_BTN1: 'Il mio profilo',
				confirmationSwal: 'Offerta corretta',
				confirmationSwal_TEXT: 'Il tuo importo è stato offerto correttamente',
				errorSwal: 'Importo non corretto',
				errorSwal_TEXT: 'L\'importo deve essere inferiore all\'attuale appalto.',
				errorNegativeSwal_TEXT: 'L\'importo dell\'appalto non può essere negativo.',
				error2Swal_TEXT: 'L\'importo dell\'appalto è cambiato e ora è inferiore alla tua proposta. Riprova con un altro importo.',
				confirmaSwal: 'Conferma',
				confirmaSwal_TEXT1: 'Confermi l\'offerta per {{importeLicitar}} {{divisa}}?',
				confirmaSwal_TEXT2: 'Confermi l\'iscrizione all\'appalto? Riceverai una notifica se sarai il prescelto al termine.',
				confirmaSwal_BTN1: 'Sì, confermo',
				confirmaElegidoSuccessSwal: 'Adjudicatario scelto correttamente.',
				confirmaElegidoSuccessSwal_TEXT: 'L\'adjudicatario è stato assegnato correttamente. Di seguito puoi vedere i suoi dettagli.',
				confirmaElegidoSwal_TEXT1: 'Confermi {{empresa}} come adjudicatario dell\'appalto?',
				confirmaSaltarSwal_TEXT1: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto e di rifiutarlo per le future gare? Non ci sono più esclusioni, l\'appalto rimarrà deserto. Questo partecipante non vedrà più le tue gare',
				confirmaSaltarSwal_TEXT2: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto e di rifiutarlo per le future gare? Questo partecipante non vedrà più le tue gare. Esclusione {{pasadas}}/2',
				confirmaSaltarSwal_TEXT3: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto? Non ci sono più esclusioni, l\'appalto rimarrà deserto.',
				confirmaSaltarSwal_TEXT4: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto? Non potrai tornare a questo utente. Esclusione {{pasadas}}/2',
				confirmaSaltarSwal_TEXT5: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto e di rifiutarlo per le future gare? Non ci sono più partecipanti, l\'appalto rimarrà deserto. Questo partecipante non vedrà più le tue gare',
				confirmaSaltarSwal_TEXT6: 'Confermi di escludere {{empresa}} come adjudicatario dell\'appalto e di rifiutarlo per le future gare? Questo partecipante non vedrà più le tue gare.',
				confirmaSaltarSwal_TEXT7: 'Confermi di escludere tutti i partecipanti? L\'appalto rimarrà deserto.',
				confirmaSaltarSuccessSwal1: 'Partecipante escluso correttamente.',
				confirmaSaltarSuccessSwal2: 'Partecipante bloccato correttamente.',
				confirmaSaltarSuccessSwal_TEXT1: 'Il partecipante è stato escluso. Ricorda che puoi escludere solo 2 volte per appalto.',
				confirmaSaltarSuccessSwal_TEXT2: 'Il partecipante è stato escluso. L\'appalto è rimasto deserto.',
				confirmaSaltarSuccessSwal_TEXT3: 'Il partecipante è stato escluso dall\'appalto.',
				confirmaSaltarSuccessSwal_TEXT4: 'Il partecipante è stato escluso e non vedrà più le tue gare. L\'appalto è rimasto deserto.',
				confirmaSaltarSuccessSwal_TEXT5: 'Il partecipante è stato escluso e non vedrà più le tue gare.',
				confirmaTrazaSwal: 'Conferma della tracciabilità',
				confirmaTrazaSwal_TEXT: 'Vuoi confermare questa traccia? Sarà notificato al licitante.',
				confirmaTrazaSwal_BTN1: 'Sì, confermo',
				errorTrazaSwal: 'Errore nella tracciabilità',
				errorTrazaSwal_TEXT: 'Devi inserire un commento sul servizio in caso di incidente.',
				errorTrazaSwal_TEXT2: 'Devi inserire la data del servizio.',
				errorTrazaSwal_TEXT3: 'Devi inserire tutte le informazioni riguardanti il luogo del servizio.',
				confirmaTrazaSuccessSwal: 'Tracciabilità confermata correttamente.',
				confirmaTrazaSuccessSwal_TEXT: 'La traccia selezionata è stata confermata correttamente.',
				cancelaTrazaSwal: 'Cancellazione della tracciabilità',
				cancelaTrazaSwal_TEXT: 'Vuoi cancellare questa traccia? Tutte le informazioni verranno eliminate.',
				cancelaTrazaSwal_BTN1: 'Sì, confermo',
				cancelaTrazaSuccessSwal: 'Tracciabilità cancellata correttamente.',
				cancelaTrazaSuccessSwal_TEXT: 'La traccia selezionata è stata cancellata correttamente.',
				borrarSwal: 'Eliminare l\'appalto',
				borrarSwal_TEXT: 'Sei sicuro di voler eliminare questo appalto? Non potrai recuperarne il contenuto.',
				borrarSwal_BTN1: 'Sì, elimina',
				borrarConfirmSwal: 'Appalto eliminato correttamente',
				borrarConfirmSwal_TEXT: 'L\'appalto è stato eliminato correttamente.',
				conexionSwal: 'Limite di connessioni superato',
				conexionSwal_TEXT: 'È stato superato il numero di connessioni simultanee disponibili. Chiudi alcune delle sessioni attive per continuare.',
				errorFileSwal: 'Formato non corretto',
				errorFileSwal_TEXT: 'Il documento deve essere caricato in formato PDF',
				questionDeleteFileSwal: "Elimina documento",
				questionDeleteFileSwal_TEXT: "Sei sicuro di voler eliminare questo documento? Non sarà possibile recuperarlo.",
				questionFusionarSwal: "Unire servizi",
				questionFusionarSwal_TEXT: "Sei sicuro di voler unire questi servizi? Non sarà possibile recuperare il servizio originale e i loro dati, documenti e commenti verranno combinati.",
				questionFusionarSwal_BTN1: "Sì, unisci",
				questionDeleteComentarioSwal: "Elimina commento",
				questionDeleteComentarioSwal_TEXT: "Sei sicuro di voler eliminare questo commento? Non sarà possibile recuperarlo.",

				confirmacionAcepSwal: 'Accettare servizio',
				confirmacionAcepSwal_TEXT: 'Sei sicuro di voler accettare il servizio? Non potrai modificarlo successivamente.',
				confirmacionAcepSwal_BTN1: "Sì, accetta",
				confirmacionRechSwal: 'Rifiutare Servizio',
				confirmacionRechSwal_TEXT: 'Sei sicuro di voler rifiutare il servizio? Non potrai recuperarlo successivamente.',
				confirmacionRechSwal_BTN1: "Sì, rifiuta"
			},
			TUTORIAL: 'VEDI DI NUOVO IL TUTORIAL'
		},
		GLOBAL: {
			PAGO: 'Pagamento dell\'abbonamento pendente.',
			PAGO_SUB: 'Non ci hai ancora fornito i dati di pagamento del tuo abbonamento. Clicca sul pulsante per accedere alla piattaforma di pagamento sicuro.',
			PAGO_BUTTON: 'PIATTAFORMA DI PAGAMENTO SICURO',
			errorModoSwal: 'Piattaforma non disponibile',
			errorModoSwal_TEXT: 'Questo contenuto non è disponibile con il tuo abbonamento.'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} deve essere inserito',
				MIN_LENGTH: 'La lunghezza minima di {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare i termini e le condizioni',
				NOT_FOUND: 'Il {{name}} richiesto non è stato trovato',
				INVALID_LOGIN: 'I dati inseriti non sono corretti. Riprova.',
				REQUIRED_FIELD: 'Devi compilare questo campo',
				MIN_LENGTH_FIELD: 'La lunghezza minima di questo campo è:',
				MAX_LENGTH_FIELD: 'La lunghezza massima di questo campo è:',
				INVALID_FIELD: 'Questo campo non è valido',
				MATCH: 'La password e la sua conferma non corrispondono',
				INVALID_REGISTER: 'L\'email inserita è già registrata. Riprova o accedi.'
			}
		},
		CREAR: {
			TITULO: 'Crea appalto',
			TITULO_NAC: 'Servizio / Trasferimento Nazionale',
			TITULO_INT: 'Trasferimento Internazionale',
			TITULO_EDIT: 'Modifica appalto',
			REF: 'Riferimento del cliente',
			REF2: '(non verrà mostrato pubblicamente)',
			REF_ERROR: 'Il riferimento è già registrato nel sistema.',
			TIPO: 'Tipo di appalto',
			ESTANDAR: 'Appalto standard.',
			ESTANDAR_DESC: 'Introduci una cifra di partenza. Le funerarie faranno offerte al ribasso per aggiudicarsi il servizio o il trasferimento.',
			FIJO: 'Appalto a prezzo fisso.',
			FIJO_DESC: 'Il servizio verrà aggiudicato al prezzo stabilito tra tutte le funerarie che lo hanno richiesto.',
			AD: 'Assegnazione diretta.',
			AD_DESC: 'Il servizio verrà aggiudicato automaticamente alla funeraria secondo il criterio di scelta e l\'importo pre-stabilito.',
			FECHA_INI: 'Data di inizio/ora',
			FECHA_FIN: 'Data di fine/ora',
			FECHA_ERROR: 'La data di fine non può essere precedente alla data di inizio.',
			OPT_TRASLADO: 'Appalto per trasferimento nazionale',
			OPT_SERVICIO: 'Appalto per servizio',
			IMPORTE: 'Importo di partenza',
			IMPORTE_ERROR1: 'L\'importo del trasferimento non può essere negativo',
			IMPORTE_ERROR2: 'L\'importo del trasferimento deve essere inferiore a 100.000',
			DETALLE: 'Dettaglio specifico del servizio',
			ORIGEN: 'ORIGINE',
			PAIS: 'Paese',
			COMUNIDAD: 'Comunità autonoma',
			ESTADO: 'Stato',
			POBLACION: 'Popolazione',
			DESTINO: 'DESTINAZIONE',
			KMS: 'Km alla destinazione',
			OPCIONAL: '(opzionale)',
			LOCALIZACION: 'LOCALIZZAZIONE',
			DESTINO_TRASLADO: '(Destinazione del trasferimento)',
			PENDIENTE_DESTINO: 'In attesa di selezionare la destinazione del trasferimento',
			SERVICIO_ERROR: 'Devi selezionare almeno un servizio da appaltare',
			IMPORTE_ERROR3: 'L\'importo del trasferimento non può essere negativo',
			IMPORTE_ERROR4: 'L\'importo del trasferimento deve essere inferiore a 100.000',
			INCLUIDO_EN: 'Incluso in',
			INCLUIDO_EN2: 'Servizio Completo',
			SELECT_OPCION: 'Seleziona un\'opzione',
			OPCION_ERROR: 'Devi selezionare un\'opzione',
			OTROS_OPCION: 'Seleziona altri servizi da includere',
			CREACION: 'Formato di creazione',
			UNIDO: 'Stesso appalto.',
			UNIDO_DESC: 'Il trasferimento e i servizi verranno offerti nello stesso appalto. La funeraria aggiudicataria sarà la stessa per entrambi.',
			SEPARADO: 'Appalti separati.',
			SEPARADO_DESC: 'Il trasferimento e i servizi verranno offerti in appalti separati. La funeraria aggiudicataria può essere diversa in entrambi.',
			BOTON: 'AGGIUNGI APPALTO',
			BOTON_EDIT: 'MODIFICA APPALTO',
			SWAL: {
				confirmationAddSwal: 'Appalto aggiunto',
				confirmationAddSwal_TEXT: 'I servizi selezionati sono stati aggiunti correttamente all\'elenco degli appalti',
				confirmationEditSwal: 'Appalto modificato',
				confirmationEditSwal_TEXT: 'L\'appalto è stato modificato correttamente',
				errorLicitacionSwal: 'Appalto con offerte',
				errorLicitacionSwal_TEXT: 'Questo appalto ha già ricevuto offerte. Non è possibile fare modifiche, puoi eliminarlo e crearne uno nuovo.',
				errorTipoLicitacionSwal: 'Selezione del tipo',
				errorTipoLicitacionSwal_TEXT: 'Devi selezionare un tipo di appalto prima di continuare.',
				errorInternacionalSwal: 'Errore in Origine/Destinazione',
				errorInternacionalSwal_TEXT: 'I paesi di origine e destinazione devono essere diversi.'
			}
		},
		START: {
			PASOS: {
				DATOS: 'Dati del contraente',
				VALIDACION: 'Validazione dei dati',
				ADICIONAL: 'Informazioni aggiuntive',
				PAGO: 'Dati di pagamento',
				CONFIRMACION: 'Conferma',
				TARIFA: 'Selezione della tariffa',
				TARIFA2: 'Informazioni sulla tariffa'
			},
			TARIFA_LOC: 'Indica la localizzazione di applicazione della tariffa',
			TARIFA_LOC_WFP: 'Indica l\'ambito geografico dei tuoi servizi',			
			CANALES: 'Voglio essere notificato attraverso i seguenti canali: <i>(almeno uno)</i>',
			PLATAFORMAS: 'Voglio accedere alle seguenti piattaforme',
			WFN: 'Fai crescere il tuo business funerario accedendo a centinaia di appalti pubblicati da Assicurazioni, Aziende e Collettivi.',
			WFP: 'Fai crescere il tuo business funerario accedendo a centinaia di richieste da privati.',
			INFO: 'Maggiori informazioni.',
			VOLVER: 'TORNA INDIETRO',
			CONTINUAR: 'CONTINUA',
			VALIDACION: 'Validazione dei dati',
			SMS: {
				TITULO: 'Validazione del numero di cellulare',
				MENSAJE: 'Abbiamo inviato un SMS al numero {{telefono}} con un codice di conferma. Inseriscilo di seguito.',
				CODIGO: 'Codice di conferma',
				CODIGO_ERROR: 'Il codice inserito non è valido',
				REENVIAR: 'Reinvia codice',
				REENVIADO: 'Messaggio reinviato'
			},
			CERTIFICADOS: 'Informazioni aggiuntive - Certificazioni e valutazioni di qualità',
			PAGO: 'Inserisci i dati bancari',
			CONFIRMACION: 'Conferma',
			PERSONAL: 'Informazioni Personali',
			TARIFA: 'Tariffa',
			SUSCRIPCION: 'Abbonamento',
			AMBITO: 'Ambito geografico',
			WFP_RESUMEN: 'Pagherai il 10% del prezzo a cui è stata aggiudicata l\'offerta.',
			ALTA: 'Registrazione come',
			OFERTANTE: 'Offerente',
			PROVEEDOR: 'Fornitore',
			SERVICIOS: 'di trasferimenti e servizi funerari',
			DATOS_PAGO: 'Dati di pagamento',
			CONFIRMAR: 'CONFERMA',
			CONFIRMAR_PAGAR: 'CONFERMA E PAGA',
			WFP_PAGO: 'Nel prossimo passo ti chiederemo i dati della carta di credito, sulla quale non verrà effettuato alcun addebito fino a quando non verrà realizzato un servizio su WFP (privati).',
			SWAL: {
				errorSMSSwal: 'Codice SMS errato',
				errorSMSSwal_TEXT: 'Il codice di validazione del telefono inviato tramite SMS è errato. Controllalo e inseriscilo nuovamente.',
				errorTelegramSwal: 'Codice Telegram errato',
				errorTelegramSwal_TEXT: 'Il codice di validazione di Telegram inserito è errato. Controllalo e inseriscilo nuovamente.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Abbonamento fino a',
			GUARDAR: 'SALVA INFORMAZIONI',
			DATOS: {
				TITULO: 'Il mio profilo',
				TITULO2: 'Informazioni utente',
				EMAIL: 'Email',
				EMPRESA: 'Azienda/Collettivo',
				EMPRESA2: 'Nome dell\'azienda o del collettivo',
				ACTIVIDAD: 'Attività',
				LICENCIA: 'Nº Licenza',
				PAIS: 'Paese',
				COMUNIDAD: 'Comunità autonoma/Stato',
				DIRECCION: 'Indirizzo',
				POBLACION: 'Località',
				CP: 'CAP',
				MOVIL: 'Telefono Cellulare',
				NOTIFICACIONES: 'Notifiche',
				TELEFONO: 'Telefono',
				WEB: 'Pagina Web',
			},
			SUSCRIPCION: {
				TITULO: 'Abbonamento',
				NO_HAY: 'Non c\'è nessun abbonamento attivo',
				CAMBIO: 'Elaborazione del cambio di metodo di pagamento...',
				TARIFA: 'Tariffa',
				FECHA_INICIO: 'Data Inizio',
				FECHA_FIN: 'Data Fine',
				PROX_RENOV: 'Pross. Rinnovo',
				FACTURACION: 'Fatturazione',
				PRECIO: 'Prezzo',
				LOCALIZACION: 'Localizzazione della tariffa',
				ESTADO: 'Stato della tariffa',
				RENOV_AUTO: 'Rinnovo automatico',
				RENOV_CANCEL: 'Rinnovo annullato',
				PROX_CAMBIO: 'Prossimo cambio nella tariffa',
				FECHA_EFECT: 'Data Effettiva',
				LOCALIZACION2: 'Localizzazione',
				CANCELAR_CAMBIO: 'Annulla cambio',
				CAMBIO_PROCESO: 'Elaborazione dell\'annullamento...',
				METODO_PAGO: 'Metodo di pagamento',
				EXPIRACION: 'Scadenza',
				PAGO_CAMBIO: 'Cambia dati di pagamento',
				PAGO_LOADING: 'Caricamento della piattaforma di pagamento...',
				BTN_LOCALIZACION: 'CAMBIA LOCALIZZAZIONE',
				BTN_TARIFA: 'CAMBIA TARIFFA',
				BTN_SUSC: 'ANNULLA ABBONAMENTO',
				BTN_CANCEL: 'ELABORAZIONE DELL\'ANNULLAMENTO...'
			},
			METODO_PAGO: {
				TITULO: 'Metodo di pagamento',
				TITULAR: 'Titolare',
				FACTURACION: 'Fatturazione',
				MENSUAL: 'Mensile',
				LICITA: 'Per appalto',
				CAMBIAR: 'Cambia dati di pagamento',
				NOMBRE: 'Nome del titolare',
				IBAN: 'Numero di conto (IBAN)'
			},
			PAGOS: {
				TITULO: 'Storico pagamenti',
				TITULO2: 'Storico pagamenti - fatture',
				IMPORTE: 'Importo',
				ESTADO: 'Stato',
				VER: 'Visualizza',
				DESCARGA: 'Scarica'
			},
			BLOQUEO: {
				TITULO: 'Lista blocco',
				FECHA: 'Data blocco'
			},
			CERTIFICACIONES: {
				TITULO: 'Le mie certificazioni e valutazioni',
				TITULO2: 'Certificazioni/Valutazioni di qualità dell\'azienda',
				TITULO3: 'Aggiungi una nuova certificazione',
				TITULO5: 'Aggiorna certificazione',
				TITULO4: 'Certificazioni',
				NPS: 'Inserisci le valutazioni NPS',
				OTRO: 'Altro indice di misurazione della qualità (identificazione)',
				OTRO1: 'Inserisci il punteggio % di',
				OTRO2: 'Inserisci il punteggio % di un altro indice',
                NOMBRE: 'Nome',
                ESTADO: 'Stato',
                SUBIDA: 'Data di caricamento',
                VALIDEZ: 'Data di validità',
				BORRAR: 'Elimina',
                BOTON: 'Aggiungi certificazione',
				BOTON2: 'Aggiorna certificazione',
				CANCELAR: 'Annulla',
                GUARDAR: 'Salva certificazione',
                PROCESO: 'Elaborazione certificazione',
                ESTADO_CORRECTO: 'Corretto',
                ESTADO_REVISION: 'In revisione',
                ESTADO_ERROR: 'Non valido',
                ESTADO_CADUCADO: 'Scaduto',
				ACTUALIZAR: 'Aggiorna',
				SUBIR_ERR: 'Formato non corretto',
				SUBIR_ERR_TEXT: 'La certificazione deve essere caricata in formato PDF',
				SUBIR_ERR2: 'File non selezionato',
				SUBIR_ERR2_TEXT: 'Devi selezionare o trascinare un file nella zona superiore',
				ARRASTRA: 'Trascina o clicca qui per caricare la tua certificazione (PDF)',
				SUBIR_OK: 'Certificazione salvata',
				SUBIR_OK_TEXT: 'La certificazione è stata salvata con successo',
				BORRAR_PREG: 'Elimina certificazione',
				BORRAR_PREG_TEXT: 'Sei sicuro di voler eliminare questa certificazione? Non sarà possibile recuperarla.',
				BORRAR_BOTON: 'Sì, elimina',
				BORRAR_BOTON2: 'Annulla',
				BORRAR_OK: 'Certificazione eliminata',
				BORRAR_OK_TEXT: 'La certificazione è stata eliminata con successo.'
			},
            FUNERARIAS: {
                TITULO: 'Le mie funerarie',
				TITULO2: 'Funerarie gestite dall\'azienda',
				TITULO3: 'Aggiungi una nuova funeraria',
				TITULO4: 'Aggiorna funeraria',
				BOTON: 'Aggiungi funeraria',
				BOTON2: 'Aggiorna funeraria',
				CANCELAR: 'Annulla',
                GUARDAR: 'Salva funeraria',
                PROCESO: 'Elaborazione funeraria',
				BORRAR: 'Elimina',
				ACTUALIZAR: 'Aggiorna',
				NOMBRE: 'Nome',
				SUBIR_OK: 'Funeraria salvata',
				SUBIR_OK_TEXT: 'La funeraria è stata salvata con successo',
				BORRAR_PREG: 'Elimina funeraria',
				BORRAR_PREG_TEXT: 'Sei sicuro di voler eliminare questa funeraria? Non sarà possibile recuperarla.',
				BORRAR_BOTON: 'Sì, elimina',
				BORRAR_BOTON2: 'Annulla',
				BORRAR_OK: 'Funeraria eliminata',
				BORRAR_OK_TEXT: 'La funeraria è stata eliminata con successo.',
				NOTIFICACIONES: 'Notifiche'
            },
			ALERTAS: {
				TITULO: 'Il mio sistema di allerte',
				FRECUENCIA: 'Seleziona la frequenza di ricezione delle notifiche di nuovi appalti.',
				INMEDIATO: 'Immediato',
				MINUTOS: 'Ogni 15 minuti',
				NUNCA: 'Mai',
				MODO: 'Regola il modo in cui sarai notificato dei messaggi della piattaforma.',
				LLAMADA: 'Chiamata telefonica',
				MODO_ERROR: 'Devi selezionare almeno un\'opzione',
				TELEGRAM_TITULO: 'Attivazione delle notifiche di Telegram',
				TELEGRAM1: 'Segui le seguenti istruzioni per attivare le notifiche sull\'applicazione Telegram:',
				TELEGRAM2: '1. Apri l\'applicazione <b>Telegram</b> sul tuo dispositivo mobile o installala dallo store di applicazioni (App Store - Apple o Google Play - Android)',
				TELEGRAM3: '2. Cerca l\'utente <b>WFuneralNetBot</b> nella barra di ricerca nella parte superiore. Alternativamente: Se hai Telegram installato sul tuo dispositivo attuale, puoi accedere a questo link <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> e aprire l\'applicazione.',
				TELEGRAM4: '3. Premi sul pulsante <b>Inizia</b> che apparirà quando troverai l\'utente nell\'applicazione.',
				TELEGRAM5: '4. Inserisci di seguito il codice di validazione che apparirà nella conversazione.',
				COD_DISPOSITIVO: 'Codice dispositivo',
				COD_ERROR: 'Il codice inserito non è valido',
				NOTIFICACIONES: 'Personalizza le notifiche che riceverai dall\'attività della piattaforma',
				SMS_TITULO: 'Configurazione delle notifiche via SMS',
				SMS1: 'Inserisci i numeri di telefono sui quali desideri ricevere le notifiche via SMS',
				COD_SMS: 'Telefono SMS',
				LLAMADAS_TITULO: 'Configurazione delle notifiche via chiamata telefonica',
				LLAMADAS1: 'Inserisci i numeri di telefono sui quali desideri ricevere le chiamate telefoniche. Attraverso questo mezzo riceverai solo avvisi di nuovi appalti.',
				LLAMADAS2: 'Stabilisci l\'intervallo orario in cui desideri ricevere chiamate.',
				COD_LLAMADAS: 'Telefono Chiamate',
				LLAMADAS_DIA: 'Senza limite di orario',
				LLAMADAS_INI: 'Ora Inizio',
				LLAMADAS_FIN: 'Ora Fine',
				ZONAS: 'Seleziona le zone in cui notificare gli eventi della piattaforma.',
				ZONAS_TODAS: 'Notifica tutte le zone'
			},
			CAMBIO: {
				AVISO: 'AVVISO: Il cambio di localizzazione avrà effetto nelle prossime 48 ore.',
				BTN_CANCELAR: 'ANNULLA CAMBIO',
				BTN_LOC: 'CAMBIA LOCALIZZAZIONE',
				BTN_TARIFA: 'CAMBIA TARIFFA',
				BTN_PROCESO: 'PROCESSANDO IL CAMBIO...',
				SELECCIONE_TARIFA: 'Seleziona la tariffa',
				PAGAR: 'Importo da pagare',
				AVISO2: 'AVVISO: La nuova tariffa verrà applicata immediatamente.'
			},
			TODO_MUNDO: 'Tutti',
			DISPOSITIVOS: {
				TITULO: 'Dispositivi registrati',
				NOMBRE: 'Nome dispositivo',
				FECHA: 'Data di registrazione'
			},
			EVENTOS: {
				TITULO: 'I miei eventi',
				EVENTO: 'Evento',
				FECHA: 'Data evento',
				DETALLE: 'Dettaglio evento'

			},
			ENTIDAD: {
				TITULO: 'Entità aziendale',
				LOGO: 'Logo dell\'azienda',
				PRESENTACION: 'Immagine di presentazione',
				ARRASTRA: 'Trascina o clicca qui per caricare la tua immagine',
				SUBIR: 'Salva immagine',
				BORRAR: 'Elimina immagine'
			},
			SWAL: {
				confirmationSwal: 'Dati Salvati',
				confirmationSwal_TEXT: 'I dati sono stati salvati correttamente',
				borrarSwal: 'Elimina Blocco',
				borrarSwal_TEXT: 'Vuoi eliminare questo blocco?',
				borrarSwal_BTN1: 'Sì, elimina',
				borrarSwal_BTN2: 'Annulla',
				borrarConfirmSwal: 'Blocco eliminato correttamente',
				borrarConfirmSwal_TEXT: 'Il blocco è stato eliminato correttamente.',
				cancelarSuscripSwal: 'Annulla Abbonamento',
				cancelarSuscripSwal_TEXT: 'Sei sicuro di voler annullare il tuo abbonamento? Potrai continuare a utilizzare i servizi di WFN fino alla data di fine attuale della tua tariffa.',
				cancelarSuscripSwal_BTN1: 'Sì, annulla',
				cancelarSuscripSwal_BTN2: 'Annulla',
				cancelarSuscripOkSwal: 'Abbonamento annullato correttamente',
				cancelarSuscripOkSwal_TEXT: 'L\'abbonamento è stato annullato correttamente. Potrai riattivare la tariffa dal tuo profilo.',
				tarifaCambiadaSwal: 'Cambio di tariffa corretto',
				tarifaCambiadaSwal_TEXT: 'La tariffa è stata cambiata correttamente',
				tarifaCambiadaSwal_TEXT2: 'La tua nuova tariffa verrà applicata immediatamente.',
				errorSwal: 'Email errata',
				errorSwal_TEXT: 'L\'email inserita è già registrata nel sistema.',
				errorTelegramSwal: 'Codice Telegram errato',
				errorTelegramSwal_TEXT: 'Il codice di validazione di Telegram inserito è errato. Controllalo e inseriscilo nuovamente.',
				cambioLocSwal: 'Localizzazione cambiata correttamente',
				cambioLocSwal_TEXT: 'La tua nuova localizzazione tariffaria sarà effettiva in 48 ore.',
				borrarCambioSwal: 'Cambio cancellato correttamente',
				borrarCambioSwal_TEXT: 'Il cambio selezionato è stato annullato correttamente.',
				borrarDispSwal: 'Elimina Dispositivo',
				borrarDispSwal_TEXT: 'Vuoi eliminare questo dispositivo?',
				borrarDispSwal_BTN1: 'Sì, elimina',
				borrarDispSwal_BTN2: 'Annulla',
				borrarDispConfirmSwal: 'Dispositivo eliminato correttamente',
				borrarDispConfirmSwal_TEXT: 'Il dispositivo è stato eliminato correttamente.',
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Piani e prezzi per',
			TITULO_FUN2: 'operatori funebri',
			SELECCIONA: 'Seleziona il piano che meglio si adatta alla tua attività di operatore funebre. Avrai sempre la flessibilità di aumentare o diminuire il tuo piano.',
			TITULO_WFP: 'Piani per',
			SELECCIONA_WFP: 'Seleziona l\'ambito geografico che meglio si adatta alla tua attività di operatore funebre.',
			SELECCIONA_WFP2: 'Solo su WFP pagherai per i servizi che realizzi, il 10% del prezzo a cui è stata aggiudicata ogni offerta.',
			SELECCIONA_WFN: 'Su WFN non pagherai nulla in nessun caso.',
			PAGO_ANUAL: 'PAGAMENTO ANNUALE',
			AHORRO: 'RISPARMIO DEL 10%',
			PAGO_MENSUAL: 'PAGAMENTO MENSILE',
			POPULAR: 'PIÙ POPOLARE',
			PUJA_EN: 'Fai un\'offerta in',
			OFERTA_PARA: 'Offri per',
			TARIFA1: 'tutto il mondo',
			TARIFA2: 'tutto il paese',
			TARIFA3: '5 comunità',
			TARIFA4: 'una comunità',
			TARIFA1_MX: 'Messico',
			TARIFA2_MX: 'Messico, Stati Uniti e Canada',
			TARIFA3_MX: 'tutto il mondo',
			MES: 'mese',
			AÑO: 'anno',
			IVA: 'IVA',
			TAX: 'Tasse',
			OFERTA: 'Offerta di lancio',
			CONTRATAR: 'ASSUMERE',
			SELECCIONAR: 'SELEZIONARE',
			TEXTO1: 'Accesso alle informazioni sugli appalti che si svolgono in qualsiasi parte del mondo.',
			TEXTO2: 'Accesso Premium alla rivista funeraria internazionale mensile Wfuneralnews.',
			TEXTO3: 'Supporto personalizzato dal nostro team.',
			TEXTO4: 'Sistema di allerte sul tuo cellulare o email per gli appalti, le offerte o altre informazioni rilevanti che ritieni importanti.',

			TITULO_ASEG1: 'Prezzi per',
			TITULO_ASEG2: 'assicurazioni, aziende e collettivi',
			TITULO_ASEG3: 'WFN Assicurazioni e collettivi',
			ASEG1: 'Metti a gara sulla nostra piattaforma i tuoi servizi o trasferimenti funerari.',
			ASEG2: 'Ottieni il miglior prezzo per il servizio.',
			ASEG3: 'Accesso al nostro database di 60.000 funerarie in 194 paesi.',
			ASEG4: 'Segmentazione per criteri di qualità ed eccellenza (ISO, UNE, NPS).',
			ASEG5: 'Informazioni sulla tracciabilità del servizio contrattato.',
			ASEG6: 'Accesso alle informazioni sugli appalti che si svolgono in qualsiasi parte del mondo.',
			ASEG7: 'Accesso Premium alla rivista funeraria internazionale mensile Wfuneralnews.',
			ASEG8: 'Supporto personalizzato dal nostro team.',
			PAGANOS: 'Pagaci solo per quello che contratti.',
			PRECIO: 'del prezzo a cui è stata aggiudicata l\'offerta finale.',
			FUNERARIA: 'Paga direttamente alla funeraria il servizio contrattato.',
			GRATIS: 'GRATUITO'
		},
		SOPORTE: {
			TIPO: {
				VENTA: 'Vendite',
				POSTVENTA: 'Post-vendita',
				ADMINISTRACION: 'Amministrazione',
				SAT: 'Servizio Tecnico',
				OTRO: 'Altro'
			},
			MENU: 'Supporto WFuneralNet'
		},
		PETS: {
			CREAR: {
				TITULO: "Crea servizio PETS",
				CLIENTE: "Cliente",
				CLIENTE_SELECT: "Seleziona un cliente",
				EXPEDIENTE: "Nº pratica",
				ORIGEN: "Origine",
				ORIGEN_SELECT: "Seleziona un'origine",
				DATOS_PROP: "Dati del proprietario",
				DATOS_MASC: "Dati dell'animale domestico",
				NOMBRE: "Nome",
				APELLIDO1: "Cognome 1",
				APELLIDO2: "Cognome 2",
				DNI: "DNI/NIE/PASSAPORTO",
				TELEFONO: "Telefono",
				EMAIL: "Email",
				DIRECCION_CONT: "Indirizzo di contatto",
				LOCALIDAD: "Località",
				PROVINCIA: "Provincia",
				CP: "CAP",
				NUMERO: "Numero",
				PISO: "Scala/Piano/Porta",
				PESO: "Peso",
				TIPO_ANIMAL: "Tipo di animale",
				CHIP: "Nº Chip",
				FECHA_FALL: "Data di decesso",
				LUGAR_FALL: "Tipo di luogo di decesso",
				LUGAR_FALL_SELECT: "Seleziona un luogo",
				LUGAR_FALL_TIPO: {
					DOMICILIO: "Domicilio",
					VETERINARIO: "Veterinario",
					REFUGIO: "Rifugio",
					OTRO: "Altro"
				},
				DIRECCION_FALL: "Indirizzo di decesso/ritiro",
				INFO_SERV: "Informazioni sul servizio",
				OBSERVACIONES: "Osservazioni",
				SUBMIT: "CREA SERVIZIO",
				SWAL: {
					confirmationAddSwal: "Servizio creato",
					confirmationAddSwal_TEXT: "Riferimento:"
				}
			},
			PRESUPUESTO: {
				DATOS_CLIENTE: "Dati del cliente",
				SERVICIO: "Servizio",
				SERVICIO_BUSCAR: "Cerca servizio",
				OPCIONAL: "opzionale",
				FIRMA: "Attivare la firma elettronica?",
				FIRMA_SI: "Sì",
				FIRMA_NO: "No",
				LOCALIDAD_FALL: "Località di decesso",
				SEL_SERVICIOS: "Selezione dei servizi",
				PERSONALIZAR: "Personalizza pacchetto",
				RESTAURAR: "Ripristina prezzi",
				ADICIONALES: "Linee aggiuntive",
				CONCEPTO: "Concetto",
				IMPUESTO: "Imposta",
				IMPORTE: "Importo",
				IMPORTE2: "(Imp. Incl.)",
				ADD_LINEA: "Aggiungi linea",
				SUPLIDOS: "Supplenze",
				ADD_SUPLIDO: "Aggiungi supplenza",
				NOTAS: "Note aggiuntive",
				TOTAL: "Totale",
				TOTAL_PAQUETE: "Pacchetto selezionato",
				TOTAL_SERVICIOS: "Servizi aggiuntivi",
				TOTAL_LINEAS: "Linee aggiuntive",
				TOTAL_SUPLIDOS: "Supplenze",
				SUBMIT: "GENERA PREVENTIVO",
				RES: {
					TITULO: "Preventivo generato correttamente",
					TEXTO: "Il PDF del preventivo è stato scaricato. Lo trovi anche nell'elenco dei Servizi.",
					CODIGO: "Codice del preventivo",
					BTN1: "NUOVO PREVENTIVO - NUOVO CLIENTE",
					BTN2: "NUOVO PREVENTIVO - STESSO CLIENTE",
					EMAIL: "Invia email al cliente",
					ADJUNTOS: "Preventivi allegati",
					PRESUPUESTO: "Preventivo",
					IMPORTE: "Importo",
					ENVIAR: "INVIA EMAIL",
					ENVIADO: "Email inviata correttamente",
					EMAIL_TEXT1: "Buongiorno",
					EMAIL_TEXT2: "Buon pomeriggio",
					EMAIL_TEXT3: "Buonasera",
					EMAIL_TEXT4: "Sig./Sig.ra",
					EMAIL_TEXT5: "La ringraziamo per aver contattato BOREA.",
					EMAIL_TEXT6: "Come abbiamo discusso al telefono, le inviamo il nostro preventivo per l'addio che ha scelto.",
					EMAIL_TEXT7: "Le ricordiamo che questo preventivo ha una validità di 90 giorni se decide di firmarlo. Per farlo, clicchi sul pulsante di firma e compili i campi richiesti. Il nostro numero di telefono è 683 67 39 57.",
					EMAIL_TEXT8: "Le ricordiamo che questo preventivo ha una validità di 90 giorni se decide di firmarlo. Per inviarcelo, può utilizzare questa stessa via (email). Il nostro numero di telefono è 683 67 39 57.",
					EMAIL_TEXT9: "Cordiali saluti",
					EMAIL_TEXT10: "da parte di BOREA",
				},
				MODAL_PERS: {
					TITULO: "Personalizzazione del pacchetto",
					NOMBRE: "Nome (Linea)",
					DETALLE: "Dettaglio",
					IMPORTE: "Importo Servizi",
					IMPORTE2: "(Imp. Incl.)",
					CERRAR: "Chiudi",
				},
				MODAL_SERV: {
					TITULO: "Seleziona un servizio",
					REFERENCIA: "Riferimento",
					FECHA: "Data",
					ORIGEN: "Origine",
					SERVICIO: "Servizio",
					LOCALIZACION: "Localizzazione",
					NOMBRE: "Nome Animale",
					SELECCIONAR: "Seleziona"
				},
				SWAL: {
					errorSwal: "Ci dispiace",
					errorSwal_TEXT: "Le tariffe della localizzazione inserita non sono disponibili.",
					locSwal: "Errore",
					locSwal_TEXT: "Devi inserire la localizzazione per visualizzare i servizi.",
					emailSwal: "Errore",
					emailSwal_TEXT: "Devi personalizzare il testo <b>[IL TUO NOME]</b> incluso nella parte inferiore dell'email."
				}
			},
			RAZONES: {
				NOCONT: "Lead non contattabile",
				FICTICIO: "Dati fittizi",
				INTERES: "Senza interesse",
				SEGURO: "Il lead ha un'assicurazione sulla vita",
				FANTASMA: "Fantasma (Il cliente smette di rispondere)",
				COM_PRO: "Concorrente - Proposta di servizio insoddisfacente",
				COM_PRE: "Concorrente - Prezzo alto",
				COM_LEN: "Concorrente - Lentezza nel rispondere",
				COM_ASE: "Concorrente - Il consulente si presenta di persona",
				PP: "Assicurazione sulla vita (PP) offerta e persa",
				PU: "Pagamento unico (PU) offerto e perso",
				SIN_GEO: "Impossibilità di servire - Mancanza di copertura geografica",
				SIN_SERV: "Impossibilità di servire - Mancanza di servizio",
				SIN_PLAN: "Impossibilità di servire - Il cliente desidera una pianificazione",
				ERROR: "Chiamata errata",
				OTRO: "Altra ragione",
			},
			TIPOS: {
				PERRO: "Cane",
				GATO: "Gatto",
				OTROS: "Altri",
			},
			ORIGEN: {
				LLAMADA: "Chiamata",
				SOL_LLAMADA: "Richiesta di chiamata",
				FORMULARIO: "Form",
				WHATSAPP: "Whatsapp",
				CHAT: "Chat",
				NO: "Non disponibile",
			},
			ORIGEN_MAYUS: {
				LLAMADA: "CHIAMATA",
				SOL_LLAMADA: "RICHIESTA DI CHIAMATA",
				FORMULARIO: "FORM",
				WHATSAPP: "WHATSAPP",
				CHAT: "CHAT",
			},
			ESTADO: {
				PENDIENTE: "Nuovo Lead",
				RECHAZADA: "Serv. Perso",
				PRESUPUESTAR: "Pend. Preventivo",
				LLAMADA: "Chiamata Cliente",
				ACEPTADA: "Preventivo Firmato",
				ASIGNADA: "Cliente Acquisito",
				COMPLETADA: "Serv. Realizzato"
			},
			SERVICIOS: {
				CREMA_IND: "Cremazione INDIVIDUALE",
				CREMA_COL: "Cremazione COLLETTIVA",
				ADICIONALES: "Servizi aggiuntivi",
				EUTANASIA: "Eutanasia e cancellazione del chip",
				PSICO: "Assistenza Psicologica",
				VELATORIO: "Camera ardente nell'inceneritore",
				URGENCIA_INC: "Urgenza nell'inceneritore",
				HUELLA: "Impronta dell'animale",
				URGENCIA_VET: "Urgenza Veterinaria",
				REC_DESCOMP: "Ritiro in caso di decomposizione",
			},
			FILTROS: {
				REFERENCIA: "Riferimento",
				FECHA_DESDE: "Data Da",
				FECHA_HASTA: "Data A",
				LOCALIZACION: "Localizzazione",
				ORIGEN: "Origine",
				TODOS: "TUTTI",
				PROVEEDOR: "Fornitore",
				ESTADO: "Stato",
				OCULTO: "Mostra Nascosti",
				REINICIAR: "Reimposta Filtri"
			},
			LISTADO: {
				TITULO: "Elenco dei servizi",
				ACTUALIZAR: "Aggiorna",
				VER: "Visualizza Filtri",
				CERRAR: "Chiudi Filtri",
				EXPORTAR: "Esporta",
				NO: "Non ci sono servizi",
				CONTACTO: "Nome contatto",
				FALLECIDO: "Nome defunto",
				MASCOTA: "Nome animale domestico",
				NO_DISP: "Non disponibile",
				LOC_FALL: "Localizzazione defunto",
				LOC_MAS: "Localizzazione animale domestico",
				LOC_CONT: "Localizzazione contatto",
				LOC_IP: "Localizzazione IP",
				LOC: "Localizzazione",
				SERVICIO: "Servizio",
				PENDIENTE: "In attesa",
				TRASLADO: "TRASFERIMENTO",
				SERV_IND: "CREM.IND.",
				SERV_COL: "CREM.COL.",
				INFO_CLI: "Informazioni sul cliente",
				INFO_CONT: "Dati di contatto",
				INFO_FALL: "Dati del defunto",
				INFO_MASC: "Dati sull'animale domestico",
				CREADOR: "Creatore",
				DETALLES_VISIT: "Dettagli visita",
				VISIT_URL: "URL",
				VISIT_IP_LOC: "Localizzazione IP",
				VISIT_DISP: "Dispositivo",
				VISIT_PC: "PC",
				VISIT_MOVIL: "Cellulare",
				VISIT_TABLET: "Tablet",
				VISIT_ORIGEN: "Origine",
				VISIT_MEDIO: "Mezzo",
				VISIT_CAMP: "Campagna",
				VISIT_KW: "Parola chiave",
				FUSION: "Servizi fusi",
				EDICION: "Modifica",
				COMENTARIOS: "Commenti",
				DOCUMENTOS: "Documenti",
				COM_NUEVO: "Nuovo commento",
				COM_PL: "Commento sul servizio",
				COM_ENVIAR: "Invia commento",
				COM_LISTADO: "Elenco commenti",
				COM_NO: "Non ci sono commenti",
				DOC_NUEVO: "Nuovo documento",
				DOC_DROP: "Clicca o trascina qui il documento",
				DOC_NOMBRE: "Nome del documento",
				DOC_LISTADO: "Elenco documenti",
				DOC_NO: "Non ci sono documenti",
				DOC_BORRAR: "Elimina",
				DOC_SUBIR: "Carica documenti",
				PRES_NUEVO: "Nuovo preventivo",
				PRES_LISTADO: "Elenco preventivi",
				PRES_NO: "Non ci sono preventivi",
				ACEPTAR_PREGUNTA: "Accetti il servizio?",
				ACEPTAR_LOAD: "Un momento...",
				ACEPTAR_SI: "ACCETTA",
				ACEPTAR_NO: "RIFIUTA",
				RECH1: "Servizio rifiutato",
				RECH2: "Servizio perso",
				RECH_MOTIVO: "Motivo della perdita",
				RECH_SEL: "Seleziona un motivo",
				RECH_REACTIVAR: "RIATTIVA SERVIZIO",
				RECH_OCULTAR: "NASCONDI SERVIZIO",
				RECH_MOSTRAR: "MOSTRA SERVIZIO",
				RECH_FUSIONAR: "FONDI SERVIZIO",
				RECH_FUSIONANDO: "Fondendo servizi...",
				SERV_DETALLE: "Dettagli del servizio",
				SERV_TIPO: "Tipo di servizio",
				SERV_PROV: "Fornitore del servizio",
				SERV_PROV_SEL: "Seleziona un fornitore",
				SERV_PRESTADO: "Servizio fornito",
				FIRM_CONTROL: "Controllo della richiesta",
				FIRM_LOADING: "Un momento...",
				FIRM_LLAMADA: "ATTESA CHIAMATA CLIENTE",
				FIRM_PRES: "ATTESA PREVENTIVO",
				FIRM_FIRMADO: "PREVENTIVO FIRMATO",
				FIRM_CANCELAR: "CANCELLA SERVIZIO",
				COMIEN_TRAZA: "Tracciabilità del servizio",
				COMIEN_LOADING: "Un momento...",
				COMIEN_SI: "INIZIA SERVIZIO",
				COMIEN_NO: "CANCELLA SERVIZIO",
				TRAZAS: {
					TITULO: "Tracciabilità",
					TEXT1: "Il centro ha contattato la famiglia.",
					TEXT2: "Verificati i dati e i requisiti per l'eutanasia",
					TEXT3: "E' stata effettuata l'eutanasia.",
					TEXT4: "Ritirato o consegnato il defunto.",
					TEXT5: "Il defunto è arrivato al centro.",
					TEXT6: "Stabiliti data e luogo della cremazione/dell'addio",
					TEXT7: "Effettuata la veglia funebre",
					TEXT8: "Effettuata la cremazione",
					TEXT9: "Consegnate le ceneri e la documentazione",
					TEXT10: "Consegnata la documentazione",
					COMPLETADO: "Completato"
				},
				ESTADO_PEND: "In attesa di accettazione del servizio",
				ESTADO_RECH: "Servizio rifiutato",
				ESTADO_ASIG: "In attesa di assegnazione del servizio",
				CARGAR: "CARICA PIÙ SERVIZI",
				MODAL_FUSIONAR: {
					TITULO: "Seleziona un servizio a cui fondere (destinazione)",
					REFERENCIA: "Riferimento",
					FECHA: "Data",
					ORIGEN: "Origine",
					SERVICIO: "Servizio",
					LOCALIZACION: "Localizzazione",
					NOMBRE: "Nome Animale",
					ESTADO: "Stato",
					SELECCIONAR: "Seleziona"
				}
			}
		}
	}
};
