import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, CentroService, LocalizacionService, PrecioService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'kt-admin-pets-localizacion',
  templateUrl: './admin-pets-localizacion.component.html',
  styleUrls: ['./admin-pets-localizacion.component.scss']
})
export class AdminPetsLocalizacionComponent implements OnInit {

  @ViewChild('modalTarifas', {static: false}) private modalTarifas: NgbModal;
  @ViewChild('confirmationTarifasSwal', {static: false}) private confirmationTarifasSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private localizacionService: LocalizacionService,
    private precioService: PrecioService,
    private centroService: CentroService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadLocalizaciones();
  }

  async loadLocalizaciones() {
    let locs = await this.localizacionService.getAllPets();
    this.originalRows = locs;
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      
      return mostrar;
    }.bind(this));
  }

  // ----------- GESTIONAR LOCALIZACIONES -------------

  currentLoc = null;
  precios = {};

  // ----- ZONAS -------

  async guardarZona(row: any) {
    let objUpd = {
      id: row.id,
      zona: row.zona
    }

    await this.localizacionService.updatePETS(objUpd);
  }

  // ----- TARIFAS -----

  loadingTarifas = false;
  loadingGuardarTarifa = false;

  async editarTarifas(zona: any) {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    this.currentLoc = zona;

    this.modalService.open(this.modalTarifas, { centered: true, size: 'lg' } );

    this.mostrarTarifasZona()
  }

  async mostrarTarifasZona() {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    let precios = await this.precioService.getByLocalizacionPETSAsync(this.currentLoc);

    console.warn(precios);

    this.precios = {};

    for(let precio of precios) {
      if(!this.precios[precio.servicio.abreviatura]) this.precios[precio.servicio.abreviatura] = { };
      this.precios[precio.servicio.abreviatura][precio.pesoMin] = precio;
    }

    this.loadingTarifas = false;
    this.cdr.detectChanges();
  }

  async guardadoTarifa(obj: any) {
    //if(obj.id) {
      delete obj.servicio;
      delete obj.zona;
      return this.precioService.updatePETS(obj);
    //} else {
    //  return this.precioService.post(obj);
    //}
  }

  async guardarTarifa() {
    if(this.loadingGuardarTarifa) return;

    this.loadingGuardarTarifa = true;
    this.cdr.detectChanges();

    await this.guardadoTarifa(this.precios['PETS_CREMA_INDIV'][0]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_INDIV'][11]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_INDIV'][21]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_INDIV'][31]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_COLECT'][0]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_COLECT'][11]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_COLECT'][21]);
    await this.guardadoTarifa(this.precios['PETS_CREMA_COLECT'][31]);
    await this.guardadoTarifa(this.precios['PETS_EUTANASIA'][0]);
    await this.guardadoTarifa(this.precios['PETS_DESPEDIDA'][0]);
    await this.guardadoTarifa(this.precios['PETS_HUELLA'][0]);
    await this.guardadoTarifa(this.precios['PETS_PSICO'][0]);
    await this.guardadoTarifa(this.precios['PETS_URG_VETERINARIO'][0]);
    await this.guardadoTarifa(this.precios['PETS_URG_INCINERADORA'][0]);
    await this.guardadoTarifa(this.precios['PETS_REC_DESC'][0]);

    this.modalService.dismissAll();

    this.editarTarifas(this.currentLoc);

    this.confirmationTarifasSwal.fire();

    this.loadingGuardarTarifa = false;
    this.cdr.detectChanges();

  }



}
