// Spain
export const locale = {
	lang: 'es',
	data: {
		MENU: {
			SEGUIMIENTO: 'seguimiento<br>de mis pujas',
			SEGUIMIENTO2: 'seguimiento de mis pujas',
			EJECUCION: 'en<br>ejecución',
			EJECUCION2: 'en ejecucion',
			TODAS: 'ver todas<br>las pujas',
			TODAS2: 'ver todas las pujas',
			CREAR: 'crear<br>licitación',
			CREAR2: 'crear licitación',
			CREAR_NAC: 'Servicio / Traslado nacional',
			CREAR_INT: 'Traslado internacional',
			CREAR_SERV: 'crear<br>servicio',
			CREAR2_SERV: 'crear servicio',
			EXPLORAR: 'explorar mis<br>licitaciones',
			EXPLORAR2: 'explorar mis licitaciones',
			EXPLORAR_SERV: 'explorar mis<br>servicios',
			EXPLORAR2_SERV: 'explorar mis servicios',
			PENDIENTES: 'pendientes<br>de adjudicar',
			PENDIENTES2: 'pendientes de adjudicar',
			TODAS_LIC: 'ver todas<br>las licitaciones',
			TODAS_LIC2: 'ver todas las licitaciones',
			CONTRATAR: 'CONTRATAR',
			PERFIL: 'Mi Perfil',
			LOGOUT: 'Cerrar Sesión',
			MODO_WFN: 'WFN<br>Licitaciones',
			MODO_WFN2: 'WFN Licitaciones',
			MODO_INT: 'WFN Traslados<br>Internacionales',
			MODO_INT2: 'WFN Traslados Internacionales',
			MODO_WFP: 'WFP<br>Particulares',
			MODO_WFP2: 'WFP Particulares',
			MODO_PLANS: ' WFP<br>Funeral Plans',
			MODO_PLANS2: 'WFP Funeral Plans',
			MODO_PETS: 'WFP<br>Mascotas',
			MODO_PETS2: 'WFP Mascotas',

		},
		FOOTER: {
			CONTACTA: 'Contacta con nosotros',
			SEDES: 'Sedes en: Madrid, Mexico, Lisboa y Colonia.',
			AVISO_LEGAL: 'Aviso legal',
			POLITICA: 'Política de privacidad'
		},
		HOME: {
			BIENVENIDA: 'Bienvenid@ al área de licitaciones de WFN',
			BIENVENIDA2: 'Bienvenid@ al área de licitaciones de WFP',
			BIENVENIDA3: 'Bienvenid@ al área de solicitudes de BOREA',
			INVITADO1: 'Estás accediendo a nuestra plataforma en modo invitado.',
			INVITADO2: 'Esto significa que podrás conocer las licitaciones en curso, pero no pujar por ellas ni publicar otras nuevas.',
			DEMO: 'Estás accediendo a nuestra plataforma de demostración. Esto significa que podrás probar toda su funcionalidad con ejemplos ficticios de licitaciones.',
			CONTRATAR: 'CONTRATAR AHORA',
			PAGO: 'Pago de suscripción pendiente.',
			PAGO2: 'Aún no nos has proporcionado los datos de pago de tu suscripción. Pulsa el botón para acceder a la plataforma segura de pagos.',
			PLATAFORMA_PAGO: 'PLATAFORMA SEGURA DE PAGOS'
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Iniciar sesión',
				BUTTON: 'Iniciar Sesión',
				BIENVENIDA1: 'Bienvenido al proceso de contratación de tu tarifa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Inicia sesión para asociar tu cuenta a esta tarifa.',
				BIENVENIDA2: 'Bienvenido al proceso de contratación de WFN',
				BIENVENIDA2_SUB: 'Inicia sesión para continuar.',
				FORGOT_BUTTON: '¿Olvidaste tu contraseña?',
				REGISTER: '¿No tienes una cuenta?',
				REGISTER_BUTTON: 'Regístrate',
				VOLVER: 'Volver al inicio de sesión'
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Contraseña',
				CONFIRM_PASSWORD: 'Confirmar Contraseña'
			},
			FORGOT: {
				TITLE: 'Recuperar contraseña',
				DESC: 'Introduce a continuación el código del email recibido y la nueva contraseña',
				SUCCESS: 'Se ha recuperado correctamente su contraseña. Inicie sesión con sus nuevas credenciales para continuar.',
				INCORRECTO: 'El código de recuperación introducido no es correcto.',
				INVALID: 'Este enlace de recuperación no es válido o ya ha sido utilizado.',
				LOGIN: 'INICIAR SESIÓN',
				CODIGO: 'Código de recuperación (4 cifras)',
				BUTTON: 'RECUPERAR CONTRASEÑA',
				EMAIL: 'Se ha enviado un correo de recuperación al email introducido si este se encuentra registrado en el sistema. Sigue las instrucciones del mismo para continuar.'
			},
			REGISTER: {
				TITLE: 'Regístrate',
				BIENVENIDA1: 'Bienvenido al proceso de contratación de tu tarifa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Antes de nada crearemos una cuenta asociada a tu tarifa.',
				BIENVENIDA2: 'Bienvenido al proceso de contratación de WFN',
				BIENVENIDA2_SUB: 'Antes de nada crearemos una cuenta.',
				EMAIL_CORP: 'Sólo email corporativos (ej. @tu-empresa.com) son aceptados',
				PAIS: 'País',
				PAIS_SELECT: 'Seleccionar país...',
				PERTENEZCO: 'Pertenezco a',
				PERTENEZCO_SELECT: 'Seleccionar sector...',
				SECTOR1: 'Funerarias',
				SECTOR2: 'Compañías de seguros',
				SECTOR3: 'Fondos de pensiones',
				SECTOR4: 'Banca seguros',
				SECTOR5: 'Compañías de previsión y prenecesidad',
				SECTOR6: 'Embajadas',
				SECTOR7: 'Colectivos con cobertura funeraria',
				SECTOR8: 'Otros',
				BUTTON: 'REGISTRARME',
				LOGIN: '¿Ya tienes una cuenta?',
				LOGIN_BUTTON: 'Inicia Sesión',
				SUCCESS: 'Registro correcto',
				SUCCESS_SUB: '¡Te damos la bienvenida a World Funeral Net!',
				SUCCESS_MAIL: 'Hemos enviado un correo electrónico de confirmación a la dirección de registro. Por favor, introduce a continuación el código o pulsa en el enlace incluído en el correo para acceder a todas las funcionalidades de la plataforma.',
				SUCCESS_MAIL2: 'Hemos enviado un correo electrónico de confirmación a la dirección de registro. Por favor, introduce a continuación el código o pulsa en el enlace incluído en el correo para proceder a la contratación de tu tarifa.',
				ERROR_CODE: 'El código introducido no es correcto.',
				VALIDAR_BUTTON: 'VALIDAR CUENTA',
				GRACIAS: 'Muchas gracias por tu confianza.'
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: 'Validando su cuenta, un momento por favor...',
				SUCCESS: 'Cuenta validada',
				SUCCESS_DESC: 'Su cuenta ha sido validada correctamente. Continuaremos el registro en unos segundos...',
				ERROR: 'Error en la validación',
				ERROR_DESC: 'Su enlace de validación no es correcto, revise su correo y utilice el último enlace generado.'
			},
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				INACTIVE: 'Su cuenta se encuentra inactiva. Revisa tu correo y pulsa en el enlace de validación.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		DEMO: {
			LOADING: 'Preparando la plataforma, un momento por favor...',
			BIENVENIDA: 'Le damos la bienvenida a WFN',
			SECTOR: 'Seleccione el sector al que pertenece para acceder a la plataforma de demostración.',
			FUNERARIA: 'SOY OPERADOR FUNERARIO',
			ASEGURADORA: 'SOY ASEGURADORA, EMPRESA O COLECTIVO',
			HOME: {
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Este es el menú principal. Desde aquí podrás acceder a las diferentes páginas disponibles.',
				STEP3: 'Aquí podrás hacer el seguimiento de las licitaciones en las que has participado.',
				STEP4: 'Aquí se muestran las licitaciones que te han sido asignadas. En ellas podrás consultar la información del adjudicatario y rellenar la trazabilidad.',
				STEP5: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
				STEP6: 'Podrás ofertar tus licitaciones en este menu. Insertarás sus detalles y serán publicadas en el listado. Podrás probarlo al finalizar esta demostración.',
				STEP7: 'Una vez creadas, encontrarás en esta página todas tus licitaciones.',
				STEP8: 'Cuando una licitación haya finalizado, podrás encontrar todas las que estén pendientes de asignar a una funeraria en esta pantalla.',
				STEP9: 'Aquí se muestran las licitaciones que ya has asignado a una funeraria y se encuentran en ejecución. En ellas podrás consultar la información de la funeraria y ver su trazabilidad.',
				STEP10: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
			},
			LICIT: {
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Cada “card” que ves, es una licitación puesta por una aseguradora o colectivo. En ella encontrarás toda información y detalles de los servicios, tales como localización, el tiempo restante, el importe actual, estado del servicio etc.',
				STEP3: 'En la zona superior encontrarás la referencia de la licitación, junto a la fecha de creación. Así mismo, podrás eliminarlas en cualquier momento (se avisará a los participantes) y editarlas cuando aún no hayan participado en ella. Si la licitación se queda desierta podrás editarla también una vez finalizada.',
				STEP4: 'En la zona superior encontrarás la referencia que el sistema ha asignado a la licitación, junto a la fecha de creación.',
				STEP5: "Estos son los servicios que hay que prestar en una licitación. Al pulsar sobre ellos podrás ver su detalle general. Los servicios con el icono <b style='color: red'>+</b> indican que el licitador ha incluído un detalle específico",
				STEP6: 'A continuación se indica la localización en la que se realizará el servicio o el origen y destino en los traslados',
				STEP7: 'Este es el tiempo restante para que finalice la licitación y por tanto el tiempo para pujar.',
				STEP8: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta, pendiente de adjudicación, pendiente de ejecución, completada, cerrada o desierta.',
				STEP9: 'Finalmente encontrarás el importe actual de la licitación y podrás acceder al detalle de sus pujas o inscritos.',
				STEP10: 'Una vez se haya asignado una licitación podrás ver el progreso de la trazabilidad y los detalles de la funeraria adjudicada.',
				STEP11: 'Además, en el siguiente menu podrás ver el detalle de la trazabilidad que rellenará la funeraria.',
				STEP12: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta (ganando/perdiendo/inscrito), en trámite, en ejecución, completada o desierta.',
				STEP13: 'Finalmente encontrarás el importe actual de la licitación y podrás pujar o inscribirte en la misma.',
				STEP14: 'Una vez te hayan asignado una licitación podrás ver los detalles de la empresa adjudicadora.',
				STEP15: 'Si la licitación se encuentra en ejecución podrás rellenar la información de trazabilidad del servicio.',
				STEP16: 'Podrás personalizar la vista de cards en todo momento con los diferentes filtros disponibles.',
				STEP17: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, crear nuevas y hacer todas las pruebas que necesites.',
				STEP18: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, pujar o inscribirte en ellas y hacer todas las pruebas que necesites.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Procesando la contratación...',
			SUCCESS: 'Contratación completada',
			SUCCESS_SUB: 'Serás redirigido a la plataforma en unos segundos...',
			PENDING: 'Confirmación de cuenta pendiente',
			PENDING_SUB: 'Antes de continuar, su cuenta debe ser confirmada por la organización. La organización se reserva el derecho de admisión, valoraremos su ingreso en 24 – 48 H y podrá finalizar el proceso de contratación.',
			PAGO: 'Accediendo a la confirmación de pago...'
		},
		PAGO: {
			LOADING: 'Accediendo a plataforma segura de pagos...',
			RESULT_LOADING: 'Cargando datos de suscripción...',
			METODO: 'Selección de método de pago',
			METODO_SUB: 'Seleccione su método de pago para finalizar la activación de su cuenta.',
			METODO_TARJETA: 'PAGO CON TARJETA DE CRÉDITO',
			METODO_SEPA: 'PAGO CON DOMICILIACIÓN BANCARIA (SEPA)',
			SEPA_TITLE: 'Pago con domiciliación bancaria - SEPA',
			SEPA_NOMBRE: 'Nombre Titular',
			SEPA_IBAN: 'Número de cuenta (IBAN)',
			SEPA_CODIGO: 'Código de promoción',
			SEPA_ERRORCOD: 'El código introducido no es correcto',
			SEPA_BUTTON: 'Suscribirme a WFuneralNet',
			SEPA_LOADING: 'Procesando datos...',
			SEPA_LEGAL: 'Al proporcionar su información de pago y confirmar este pago, usted autoriza a WFuneralNet y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para que cargue el importe en su cuenta y a su banco para que cargue el importe en su cuenta de acuerdo con dichas instrucciones. Como parte de sus derechos, tiene derecho a un reembolso por parte de su banco según los términos y condiciones de su acuerdo con su banco. Sus derechos se explican en una declaración que puede obtener de su banco. Usted acepta recibir notificaciones de futuros cargos hasta 2 días antes de que se produzcan.',
			SUCCESS: 'Su suscripción ha sido activada correctamente.',
			SUCCESS_SUB: 'Puedes comenzar a usar la plataforma con toda su funcionalidad. Pulsa en el siguiente botón para acceder al inicio.',
			SUCCESS_BUTTON: 'COMENZAR A UTILIZAR WORLD FUNERAL NET',
			CANCEL: 'Has cancelado el proceso de suscripción.',
			CANCEL_SUB: 'Si ha ocurrido un error o quieres volver a intentarlo, pulsa en el siguiente botón para acceder a la plataforma segura de pagos.',
			CANCEL_BUTTON: 'CONTINUAR A PLATAFORMA SEGURA DE PAGO',
			WFP: 'No se hará ningún cargo de WFP hasta realizar el servicio. Pagarás el 10% del precio al que se haya adjudicado la licitación.',
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra a tu medida',
				INVITADO: 'Los filtros se encuentran desactivados en la cuenta de invitado.',
				CONTRATAR: 'Contratar',
				REFERENCIA: 'Referencia',
				SERVICIO: 'Servicio',
				LOC_ORIGEN: 'Localización origen',
				LOC_DESTINO: 'Localización destino',
				TODOS: 'Todos',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma',
				ESTADO: 'Estado',
				FECHA_DESDE: 'Fecha desde',
				FECHA_HASTA: 'Fecha hasta',
				PRECIO_MIN: 'Precio min.',
				PRECIO_MAX: 'Precio max.',
				ESTADO_LIC: 'Estado licitación',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente Inicio',
					ABIERTA: 'Abierta',
					PENDIENTE_ADJ: 'Pendiente adjudicación',
					ADJ_TRAM: 'Adjud. en trámite',
					ADJ_PEN: 'Adjud. y pendiente',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					CERRADA: 'Cerrada',
					DESIERTA: 'Desierta'
				},
				REESTABLECER: 'Reestablecer filtros'
			},
			ORDEN: {
				ESTADO: 'Ordenar por estado de licitación',
				ESTADO_ABIERTAS: 'Mostrar primero abiertas',
				ESTADO_PENDIENTE: 'Mostrar primero pendientes',
				ESTADO_ADJUD: 'Mostrar primero adjudicadas',
				FECHA: 'Ordenar por fecha de inicio',
				FECHA_ASC: 'Ascendente',
				FECHA_DESC: 'Descendente',
				TIEMPO: 'Ordenar por tiempo hasta finalización',
				TIEMPO_ASC: 'Ascendente',
				TIEMPO_DESC: 'Descendente'
			},
			LOADING: 'Cargando licitaciones...',
			LOADING_AD: 'Cargando servicios...',
			NOHAY: 'No hay licitaciones',
			CARD: {
				ORIGEN: 'Origen',
				DESTINO: 'Destino',
				TRASLADO: 'Traslado',
				TRASLADO_INT: 'Int.',
				LOCALIZ: 'Localización',
				LOC_WFP1: 'Localización fallecido',
				LOC_WFP2: 'Localización tanatorio',
				SIN_PRECIO: 'En curso',
				PUJA: 'puja',
				OFERTA: 'oferta',
				PUJAR: 'pujar',
				OFERTAR: 'ofertar',
				LA: 'La',
				TIME: 'Tiempo restante para',
				COMIENZA: 'comienza en',
				VER_ASEG: 'ver aseguradora',
				VER_FUN: 'ver funeraria',
				VER_PART: 'ver particular',
				VER_DETALLES: 'ver detalles',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente de inicio',
					ABIERTA: 'Abierta',
					INSCRITO: 'Inscrito',
					GANANDO: 'Ganando',
					PERDIENDO: 'Perdiendo',
					CONTU: 'con tu',
					PUJA: 'puja',
					OFERTA: 'oferta',
					DE: 'de',
					PENDIENTE_ADJ: 'Pendiente',
					PENDIENTE_ADJ2: 'adjudicación',
					CERRADA: 'Cerrada',
					ADJ_TRAM: 'Adjudicación en trámite',
					ADJ_PEN: 'Adjudicada y',
					ADJ_PEN2: 'pendiente de ejecución',
					ADJ_PEN3: 'pendiente de confirmación',
					ADJ_PEN4: 'rechazada por funeraria',
					PEND_ACEPT: 'Pendiente confirmación',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					NO_ADJ: 'No adjudicado',
					RECHAZADA: 'Rechazada',
					DESIERTA: 'Desierta'
				},
				PUJA_SALIDA: 'Puja salida',
				OFERTA_SALIDA: 'Oferta salida',
				PUJA_ACTUAL: 'Puja actual',
				OFERTA_ACTUAL: 'Oferta actual',
				PUJA_GANADORA: 'Puja ganadora',
				OFERTA_GANADORA: 'Oferta ganadora',
				PUJA_DESIERTA: 'Puja desierta',
				OFERTA_DESIERTA: 'Oferta desierta',
				PUJA_ADJUDIC: 'Puja adjudic.',
				OFERTA_ADJUDIC: 'Oferta adjudic.',
				PRECIO_FIJO: 'Precio fijo',
				ASIG_DIRECTA: 'Asig. directa',
				VER_PUJAS: 'VER PUJAS',
				VER_OFERTAS: 'VER OFERTAS',
				INSCRITOS: 'INSCRITOS',
				ADJUDICAR: 'ADJUDICAR',
				TRAZABILIDAD: 'TRAZABILIDAD',
				INSCRÍBETE: 'INSCRÍBETE',
				ACEPTAR: 'ACEPTAR',
				RECHAZAR: 'RECHAZAR',
				PUJAR2: 'PUJAR',
				OFERTAR2: 'OFERTAR',
				VOLVER_PUJAR: 'VOLVER A PUJAR',
				VOLVER_OFERTAR: 'VOLVER A OFERTAR',
				IVA_INC: 'IVA no incluído',
				HIST: {
					HISTORICO_PUJAS: 'Histórico de pujas',
					HISTORICO_OFERTAS: 'Histórico de ofertas',
					HISTORICO_INSC: 'Histórico de inscritos',
					IMPORTE_SALIDA: 'Importe salida',
					ACTUALIZAR: 'actualizar',
					TOTAL: 'Total',
					FECHA: 'Fecha',
					USUARIO: 'Usuario',
					IMPORTE: 'Importe',
					DESIERTA: 'Licitación desierta',
					CREADOR: 'CREADOR',
				},
				CERRAR: 'cerrar',
				ASIG: {
					NOMBRE: 'Nombre',
					LICENCIA: 'Licencia',
					DOMICILIO: 'Domicilio',
					TELEFONO: 'Teléfono',
					EMAIL: 'Email',
					WEB: 'Página Web',
					CERTIFICACIONES: 'Certificaciones',
					PUNTUACIONES: 'Puntuaciones',
					IMPORTE: 'Importe licitación',
					ADJUDICAR: 'ADJUDICAR',
					EXCLUIR: 'EXCLUIR',
					EXCLUIR_TODAS: 'EXCLUIR TODAS',
					BLOQUEAR: 'BLOQUEAR PROV.',
					VER_DETALLES: 'ver detalles',
					CERRAR_DETALLES: 'cerrar detalles',
					DETALLE_ASIG1: 'La Aseguradora se reserva el derecho de adjudicación valorando la calidad de los servicios ofrecidos por la Funeraria.',
					DETALLE_ASIG11: 'El particular se reserva el derecho de adjudicación valorando la calidad de los servicios ofrecidos por la Funeraria.',
					DETALLE_ASIG2: 'Es importante que completes tu perfil con puntuaciones y certificados de calidad.',
					DETALLE_ASIG_BTN: 'Completar perfil',
					REFERENCIA: 'Referencia licit. WFN',
					REFERENCIA2: 'Referencia licit. creador',
					SIGUIENTES_PUJAS: 'Siguientes pujas',
					SIGUIENTES_OFERTAS: 'Siguientes ofertas',
					FUNERARIA: 'Funeraria licitadora',
					PARTICULAR: 'Información del particular'
				},
				PUJAS: {
					PUJA_RAPIDA: 'Puja rápida',
					OFERTA_RAPIDA: 'Oferta rápida',
					PUJA_MANUAL: 'o introduce manualmente tu puja',
					OFERTA_MANUAL: 'o introduce manualmente tu oferta',
					PUJA_WFP: 'Introduce tu puja',
					OFERTA_WFP: 'Introduce tu oferta',
					PUJAR: 'PUJAR',
					OFERTAR: 'OFERTAR',
					AVISO: 'Aviso',
					PRORROGA3_PUJA: 'La licitación se extenderá en 3 minutos al pujar durante el minuto final',
					PRORROGA3_OFERTA: 'La licitación se extenderá en 3 minutos al ofertar durante el minuto final',
					PRORROGA1_PUJA: 'La licitación se extenderá en 1 minuto al pujar durante el minuto final',
					PRORROGA1_OFERTA: 'La licitación se extenderá en 1 minuto al ofertar durante el minuto final',
					PROCESO_PUJA: 'Su puja se está procesando. Un momento por favor...',
					PROCESO_OFERTA: 'Su oferta se está procesando. Un momento por favor...',
					HISTORICO_PUJAS: 'Ver histórico de pujas',
					HISTORICO_OFERTAS: 'Ver histórico de ofertas',
					FUNERARIA: 'Seleccione la funeraria',
					FUNERARIA2: 'Funeraria seleccionada'
				},
				TRAZA: {
					INCIDENCIA: 'Incidencia en el servicio',
					VALIDAR: 'VALIDAR',
					CANCELAR: 'CANCELAR',
					FECHA: 'Fecha',
					LUGAR: 'Lugar'
				},
				SERVICIO: { //NUEVO WFP
					TIPO_TRASLADO: 'Tipo traslado',
					CARRETERA: 'Carretera punto a punto',
					AEROPUERTO: 'Aeropuerto',
					INCLUIDOS: 'Servicios Incluídos',
					TIPO_COMPLETO: 'Tipo Serv. Completo',
					CREMACION: 'Cremación + Urna',
					DESTFIN: 'Inhumación',
					DETALLE_ESP: 'Detalle específico del servicio',
					ESPEC_GEN: 'Especificaciones generales del servicio',
					TRASLADO1: 'El fallecimiento y el destino final (inhumación o incineración) se produce en localidades distintas.',
					TRASLADO2: 'Elementos y servicios habituales del traslado:',
					TRASLADO3: 'Arca de traslado : En aquellos casos en los que el Reglamento de Policía Sanitaria Mortuoria (RPSM) lo exija deberá estar provisto adicionalmente de un dispositivo de filtrado de aire u otro para equilibrar la presión interior y exterior.',
					TRASLADO4: 'Flete aéreo en su caso, Kms. realizados por carretera y gestiones y trámites específicos.',
					WFP_TIPO: 'Tipología servicio',
					WFP_TIPO_CREMA: 'Cremación',
					WFP_TIPO_INHUM: 'Inhumación',		
					WFP_CEREM: 'Ceremonia',
					WFP_CIVIL: 'Civil',
					WFP_RELIG: 'Religiosa',
					WFP_CATERING: 'Núm. de personas',
					WFP_CORONAS: 'Núm. de coronas',
					WFP_OTROS: 'Otras especificaciones',
					WFP_MULTIPLE: 'Fallecimiento múltiple',
					WFP_PERSONAS: 'Personas'
				}
			},
			SWAL: { 
				CANCELAR: 'Cancelar',
				registerSwal: 'Contratación Necesaria',
				registerSwal_TEXT: 'Este contenido no se encuentra disponible con una cuenta de invitado. Contrata una tarifa para continuar.',
				registerSwal_BTN1: 'Contratar',
				suscriptionSwal: 'Licitación no disponible',
				suscriptionSwal_TEXT: 'Este contenido no se encuentra disponible con tu suscripción. Revisa tu tarifa para acceder.',
				suscriptionSwal_BTN1: 'Mi Perfil',
				confirmationSwal: 'Licitación Correcta',
				confirmationSwal_TEXT: 'Tu importe se ha licitado correctamente',
				errorSwal: 'Importe Incorrecto',
				errorSwal_TEXT: 'El importe debe ser inferior al actual de la licitación.',
				errorNegativeSwal_TEXT: 'El importe de la licitación no puede ser negativo.',
				error2Swal_TEXT: 'El importe de la licitación ha cambiado y ahora es inferior a tu propuesta. Inténtelo de nuevo con otro importe.',
				confirmaSwal: 'Confirmación',
				confirmaSwal_TEXT1: '¿Confirmas la licitación por {{importeLicitar}} {{divisa}} ?',
				confirmaSwal_TEXT2: '¿Confirmas la inscripción a la licitación? Recibirás una notificación si eres el elegido al finalizar.',
				confirmaSwal_BTN1: 'Si, confirmo',
				confirmaElegidoSuccessSwal: 'Usuario elegido correctamente.',
				confirmaElegidoSuccessSwal_TEXT: 'El adjudicatario se ha asignado correctamente. A continuación puedes ver sus detalles.',
				confirmaElegidoSwal_TEXT1: '¿Confirmas a {{empresa}} como adjudicatario de la licitación?',
				confirmaSaltarSwal_TEXT1: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más exlusiones, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT2: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT3: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No hay más exlusiones, la licitación se quedará desierta.',
				confirmaSaltarSwal_TEXT4: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No podrás volver a este usuario. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT5: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más participantes, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT6: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones.',
				confirmaSaltarSwal_TEXT7: '¿Confirmas excluir a todos los participantes? La licitación se quedará desierta.',
				confirmaSaltarSuccessSwal1: 'Participante excluido correctamente.',
				confirmaSaltarSuccessSwal2: 'Participante bloqueado correctamente.',
				confirmaSaltarSuccessSwal_TEXT1: 'Se ha excluido al participante. Recuerda que solo puedes excluir 2 veces por licitación.',
				confirmaSaltarSuccessSwal_TEXT2: 'Se ha excluido al participante. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT3: 'Se ha excluido al participante de la licitación.',
				confirmaSaltarSuccessSwal_TEXT4: 'Se ha excluído al participante y no volverá a ver tus licitaciones. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT5: 'Se ha excluído al participante y no volverá a ver tus licitaciones.',
				confirmaTrazaSwal: 'Confirmación de traza',
				confirmaTrazaSwal_TEXT: '¿Deseas confirmar esta traza? Se informará al licitador.',
				confirmaTrazaSwal_BTN1: 'Si, confirmo',
				errorTrazaSwal: 'Error en la traza',
				errorTrazaSwal_TEXT: 'Debes introducir un comentario del servicio al haber una incidencia en el mismo.',
				errorTrazaSwal_TEXT2: 'Debes introducir la fecha del servicio.',
				errorTrazaSwal_TEXT3: 'Debes introducir toda la información acerca del lugar del servicio.',
				confirmaTrazaSuccessSwal: 'Traza confirmada correctamente.',
				confirmaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha confirmado correctamente.',
				cancelaTrazaSwal: 'Cancelación de traza',
				cancelaTrazaSwal_TEXT: '¿Deseas cancelar esta traza? Se borrará toda la información de la misma.',
				cancelaTrazaSwal_BTN1: 'Si, confirmo',
				cancelaTrazaSuccessSwal: 'Traza cancelada correctamente.',
				cancelaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha cancelado correctamente.',
				borrarSwal: 'Eliminar Licitación',
				borrarSwal_TEXT: '¿Estás seguro de eliminar esta licitación? No podrás recuperar su contenido.',
				borrarSwal_BTN1: 'Si, eliminar',
				borrarConfirmSwal: 'Licitación eliminada correctamente',
				borrarConfirmSwal_TEXT: 'Se ha eliminado correctamente la licitación.',
				conexionSwal: 'Límite de conexiones superado',
				conexionSwal_TEXT: 'Se ha superado el número de conexiones simultáneas disponible. Cierra alguna de las sesiones activas para continuar.',
				errorFileSwal: 'Formato incorrecto',
				errorFileSwal_TEXT: 'El documento debe subirse en formato PDF',
				questionDeleteFileSwal: "Borrar Documento",
				questionDeleteFileSwal_TEXT: "¿Estás seguro de borrar este documento? No se podrá recuperar.",
				questionFusionarSwal: "Fusionar Servicios",
				questionFusionarSwal_TEXT: "¿Estás seguro de fusionar estos servicios? No podrás recuperar el servicio original y se combinarán sus datos, documentos y comentarios.",
				questionFusionarSwal_BTN1: "Si, fusionar",
				questionDeleteComentarioSwal: "Borrar Comentario",
				questionDeleteComentarioSwal_TEXT: "¿Estás seguro de borrar este comentario? No se podrá recuperar.",

				confirmacionAcepSwal: 'Aceptar Servicio',
				confirmacionAcepSwal_TEXT: '¿Estás seguro de aceptar el servicio? No podrás modificarlo posteriormente.',
				confirmacionAcepSwal_BTN1: "Si, aceptar",
				confirmacionRechSwal: 'Rechazar Servicio',
				confirmacionRechSwal_TEXT: '¿Estás seguro de rechazar el servicio? No podrás recuperarlo posteriormente.',
				confirmacionRechSwal_BTN1: "Si, rechazar"
			},
			TUTORIAL: 'VER TUTORIAL DE NUEVO'
		},
		GLOBAL: {
			PAGO: 'Pago de suscripción pendiente.',
			PAGO_SUB: 'Aún no nos has proporcionado los datos de pago de tu suscripción. Pulsa el botón para acceder a la plataforma segura de pagos.',
			PAGO_BUTTON: 'PLATAFORMA SEGURA DE PAGOS',
			errorModoSwal: 'Plataforma no disponible',
			errorModoSwal_TEXT: 'Este contenido no se encuentra disponible con tu suscripción.'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		CREAR: {
			TITULO: 'Crear licitación',
			TITULO_NAC: 'Servicio / Traslado Nacional',
			TITULO_INT: 'Traslado Internacional',
			TITULO_EDIT: 'Editar licitación',
			REF: 'Referencia del cliente',
			REF2: '(no será mostrado públicamente)',
			REF_ERROR: 'La referencia ya registrada en el sistema.',
			TIPO: 'Tipo de licitación',
			ESTANDAR: 'Licitación estándar.',
			ESTANDAR_DESC: 'Introduce una cifra de salida. Las funerarias pujarán a la baja para adjudicarse el servicio o traslado.',
			FIJO: 'Licitación a precio fijo.',
			FIJO_DESC: 'El servicio se adjudicará al precio establecido entre todas las funerarias que lo hayan solicitado.',
			AD: 'Asignación directa.',
			AD_DESC: 'El servicio se adjudicará automáticamente a la funeraria según el criterio de elección e importe pre-establecido.',
			FECHA_INI: 'Fecha de inicio/hora',
			FECHA_FIN: 'Fecha de finalización/hora',
			FECHA_ERROR: 'La fecha de finalización no puede ser anterior a la fecha inicio.',
			OPT_TRASLADO: 'Licitar traslado nacional',
			OPT_SERVICIO: 'Licitar servicio',
			IMPORTE: 'Importe de salida',
			IMPORTE_ERROR1: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR2: 'El importe del traslado debe ser inferior a 100.000',
			DETALLE: 'Detalle específico del servicio',
			ORIGEN: 'ORIGEN',
			PAIS: 'País',
			COMUNIDAD: 'Comunidad autónoma',
			ESTADO: 'Estado',
			POBLACION: 'Población',
			DESTINO: 'DESTINO',
			KMS: 'KMs a destino',
			OPCIONAL: '(opcional)',
			LOCALIZACION: 'LOCALIZACIÓN',
			DESTINO_TRASLADO: '(Destino del traslado)',
			PENDIENTE_DESTINO: 'Pendiente seleccionar destino del traslado',
			SERVICIO_ERROR: 'Debes seleccionar al menos un servicio a licitar',
			IMPORTE_ERROR3: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR4: 'El importe del traslado debe ser inferior a 100.000',
			INCLUIDO_EN: 'Incluído en',
			INCLUIDO_EN2: 'Servicio Completo',
			SELECT_OPCION: 'Seleccione una opción',
			OPCION_ERROR: 'Debes seleccionar una opción',
			OTROS_OPCION: 'Seleccione otros servicios a incluir',
			CREACION: 'Formato de creación',
			UNIDO: 'Misma licitación.',
			UNIDO_DESC: 'El traslado y los servicios se ofertarán en una misma licitación. La funeraria adjudicada será la misma para ambos.',
			SEPARADO: 'Licitaciones separadas.',
			SEPARADO_DESC: 'El traslado y los servicios se ofertarán en licitaciones separadas. La funeraria adjudicada puede ser distinta en ambas.',
			BOTON: 'AÑADIR LICITACIÓN',
			BOTON_EDIT: 'EDITAR LICITACIÓN',
			SWAL: {
				confirmationAddSwal: 'Licitación Añadida',
				confirmationAddSwal_TEXT: 'Los servicios seleccionados se han añadido correctamente al listado de licitaciones',
				confirmationEditSwal: 'Licitación Editada',
				confirmationEditSwal_TEXT: 'La licitación se ha editado correctamente',
				errorLicitacionSwal: 'Licitación con pujas',
				errorLicitacionSwal_TEXT: 'Esta licitación ya ha recibido pujas. No es posible hacer modificaciones, puedes borrarla y crearla de nuevo.',
				errorTipoLicitacionSwal: 'Selección de tipo',
				errorTipoLicitacionSwal_TEXT: 'Debes seleccionar un tipo de licitación antes de continuar.',
				errorInternacionalSwal: 'Error en Origen/Destino',
				errorInternacionalSwal_TEXT: 'Los países de origen y destino deben ser diferentes.'
			}
		},
		START: {
			PASOS: {
				DATOS: 'Datos de contratante',
				VALIDACION: 'Validación de datos',
				ADICIONAL: 'Información adicional',
				PAGO: 'Datos de pago',
				CONFIRMACION: 'Confirmación',
				TARIFA: 'Selección de tarifa',
				TARIFA2: 'Información de tarifa'
			},
			TARIFA_LOC: 'Indique la localización de aplicación de tarifa',
			TARIFA_LOC_WFP: 'Indique el ámbito geográfico de sus servicios',			
			CANALES: 'Quiero ser notificado a través de los siguientes canales: <i>(al menos uno)</i>',
			PLATAFORMAS: 'Quiero acceder a las siguientes plataformas',
			WFN: 'Haz crecer tu negocio funerario accediendo a cientos de licitaciones publicadas por Aseguradoras, Empresas y Colectivos.',
			WFP: 'Haz crecer tu negocio funerario accediendo a cientos de solicitudes de particulares.',
			INFO: 'Más info.',
			VOLVER: 'VOLVER',
			CONTINUAR: 'CONTINUAR',
			VALIDACION: 'Validación de datos',
			SMS: {
				TITULO: 'Validación de teléfono móvil',
				MENSAJE: 'Le hemos enviado un SMS al teléfono {{telefono}} con un código de confirmación. Introdúzcalo a continuación.',
				CODIGO: 'Código de confirmación',
				CODIGO_ERROR: 'El código introducido no es válido',
				REENVIAR: 'Reenviar código',
				REENVIADO: 'Mensaje reenviado'
			},
			CERTIFICADOS: 'Información adicional - Certificados y punt. calidad',
			PAGO: 'Introduce los datos bancarios',
			CONFIRMACION: 'Confirmación',
			PERSONAL: 'Información Personal',
			TARIFA: 'Tarifa',
			SUSCRIPCION: 'Suscripción',
			AMBITO: 'Ámbito geográfico',
			WFP_RESUMEN: 'Paga el 10% del precio al que se haya adjudicado la licitación.',
			ALTA: 'Alta como',
			OFERTANTE: 'Ofertante',
			PROVEEDOR: 'Proveedor',
			SERVICIOS: 'de traslados y servicios funerarios',
			DATOS_PAGO: 'Datos de pago',
			CONFIRMAR: 'CONFIRMAR',
			CONFIRMAR_PAGAR: 'CONFIRMAR Y PAGAR',
			WFP_PAGO: 'En el siguiente paso te pediremos los datos de la tarjeta de crédito, en la que no se hará ningún cargo mientras no se haga ningún servicio en WFP (particulares).',
			SWAL: {
				errorSMSSwal: 'Código SMS incorrecto',
				errorSMSSwal_TEXT: 'El código de validación de teléfono enviado por SMS es incorrecto. Revíselo e introdúzcalo de nuevo.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Suscripción hasta',
			GUARDAR: 'GUARDAR INFORMACIÓN',
			DATOS: {
				TITULO: 'Mi perfil',
				TITULO2: 'Información del usuario',
				EMAIL: 'Email',
				EMPRESA: 'Empresa/Colectivo',
				EMPRESA2: 'Nombre de la empresa o colectivo',
				ACTIVIDAD: 'Actividad',
				LICENCIA: 'Nº Licencia',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma/Estado',
				DIRECCION: 'Dirección',
				POBLACION: 'Población',
				CP: 'CP',
				MOVIL: 'Teléfono Movil',
				NOTIFICACIONES: 'Notificaciones',
				TELEFONO: 'Teléfono',
				WEB: 'Página Web',
			},
			SUSCRIPCION: {
				TITULO: 'Suscripción',
				NO_HAY: 'No hay ninguna suscripción activa',
				CAMBIO: 'Procesando cambio de método de pago...',
				TARIFA: 'Tarifa',
				FECHA_INICIO: 'Fecha Inicio',
				FECHA_FIN: 'Fecha Fin',
				PROX_RENOV: 'Próx.Renovación',
				FACTURACION: 'Facturación',
				PRECIO: 'Precio',
				LOCALIZACION: 'Localización de la tarifa',
				ESTADO: 'Estado tarifa',
				RENOV_AUTO: 'Renovación automática',
				RENOV_CANCEL: 'Renovación cancelada',
				PROX_CAMBIO: 'Próximo cambio en la tarifa',
				FECHA_EFECT: 'Fecha Efectiva',
				LOCALIZACION2: 'Localización',
				CANCELAR_CAMBIO: 'Cancelar cambio',
				CAMBIO_PROCESO: 'Procesando cancelación...',
				METODO_PAGO: 'Método de pago',
				EXPIRACION: 'Expiración',
				PAGO_CAMBIO: 'Cambiar método de pago',
				PAGO_LOADING: 'Cargando plataforma de pago...',
				BTN_LOCALIZACION: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_SUSC: 'CANCELAR SUSCRIPCIÓN',
				BTN_CANCEL: 'PROCESANDO CANCELACIÓN...'
			},
			METODO_PAGO: {
				TITULO: 'Método de pago',
				TITULAR: 'Titular',
				FACTURACION: 'Facturación',
				MENSUAL: 'Mensual',
				LICITA: 'Por licitación',
				CAMBIAR: 'Cambiar datos de pago',
				NOMBRE: 'Nombre Titular',
				IBAN: 'Número de cuenta (IBAN)'
			},
			PAGOS: {
				TITULO: 'Historial de pagos',
				TITULO2: 'Historial de pagos - facturas',
				IMPORTE: 'Importe',
				ESTADO: 'Estado',
				VER: 'Ver',
				DESCARGA: 'Descarga'
			},
			BLOQUEO: {
				TITULO: 'Lista de bloqueo',
				FECHA: 'Fecha Bloqueo'
			},
			CERTIFICACIONES: {
				TITULO: 'Mis certificaciones y puntuaciones',
				TITULO2: 'Certificaciones/Puntuaciones de calidad de la empresa',
				TITULO3: 'Añade una nueva certificación',
				TITULO5: 'Actualizar certificación',
				TITULO4: 'Certificaciones',
				NPS: 'Introduce las puntuaciones NPS',
				OTRO: 'Otro índice de medición de calidad (identificación)',
				OTRO1: 'Introduce la puntuación % de',
				OTRO2: 'Introduce la puntuación % de otro índice',
                NOMBRE: 'Nombre',
                ESTADO: 'Estado',
                SUBIDA: 'Fecha Subida',
                VALIDEZ: 'Fecha Validez',
				BORRAR: 'Borrar',
                BOTON: 'Añadir certificación',
				BOTON2: 'Actualizar certificación',
				CANCELAR: 'Cancelar',
                GUARDAR: 'Guardar certificación',
                PROCESO: 'Procesando certificación',
                ESTADO_CORRECTO: 'Correcto',
                ESTADO_REVISION: 'En revisión',
                ESTADO_ERROR: 'No válido',
                ESTADO_CADUCADO: 'Caducado',
				ACTUALIZAR: 'Actualizar',
				SUBIR_ERR: 'Formato Incorrecto',
				SUBIR_ERR_TEXT: 'La certificación debe subirse en formato PDF',
				SUBIR_ERR2: 'Archivo no seleccionado',
				SUBIR_ERR2_TEXT: 'Debes seleccionar o arrastrar un archivo en el cuadro de la zona superior',
				ARRASTRA: 'Arrastra o pulsa aquí para subir tu certificación (PDF)',
				SUBIR_OK: 'Certificación guardada',
				SUBIR_OK_TEXT: 'La certificación se ha guardado con éxito',
				BORRAR_PREG: 'Borrar certificación',
				BORRAR_PREG_TEXT: '¿Estás seguro de borrar esta certificación? No se podrá recuperar.',
				BORRAR_BOTON: 'Si, borrar',
				BORRAR_BOTON2: 'Cancelar',
				BORRAR_OK: 'Certificación borrada',
				BORRAR_OK_TEXT: 'La certificación se ha borrado con éxito.'
			},
            FUNERARIAS: {
                TITULO: 'Mis funerarias',
				TITULO2: 'Funerarias gestionadas por la empresa',
				TITULO3: 'Añade una nueva funeraria',
				TITULO4: 'Actualizar funeraria',
				BOTON: 'Añadir funeraria',
				BOTON2: 'Actualizar funeraria',
				CANCELAR: 'Cancelar',
                GUARDAR: 'Guardar funeraria',
                PROCESO: 'Procesando funeraria',
				BORRAR: 'Borrar',
				ACTUALIZAR: 'Actualizar',
				NOMBRE: 'Nombre',
				SUBIR_OK: 'Funeraria guardada',
				SUBIR_OK_TEXT: 'La funeraria se ha guardado con éxito',
				BORRAR_PREG: 'Borrar funeraria',
				BORRAR_PREG_TEXT: '¿Estás seguro de borrar esta funeraria? No se podrá recuperar.',
				BORRAR_BOTON: 'Si, borrar',
				BORRAR_BOTON2: 'Cancelar',
				BORRAR_OK: 'Funeraria borrada',
				BORRAR_OK_TEXT: 'La funeraria se ha borrado con éxito.',
				NOTIFICACIONES: 'Notificaciones'
            },
			ALERTAS: {
				TITULO: 'Mi sistema de alertas',
				FRECUENCIA: 'Selecciona la frecuencia de recepción de notificaciones de nuevas licitaciones.',
				INMEDIATO: 'Inmediato',
				MINUTOS: 'Cada 15 minutos',
				NUNCA: 'Nunca',
				MODO: 'Ajusta el modo en el que serás notificado de los mensajes de la plataforma.',
				LLAMADA: 'Llamada telefónica',
				MODO_ERROR: 'Debes seleccionar al menos una opción',
				TELEGRAM_TITULO: 'Activación de notificaciones de Telegram',
				TELEGRAM1: 'Siga las siguientes instrucciones para activar las notificaciones en la aplicación Telegram:',
				TELEGRAM2: '1. Abre la aplicación <b>Telegram</b> en tu dispositivo movil o instálala desde la tienda de aplicaciónes (App Store - Apple o Google Play - Android)',
				TELEGRAM3: '2. Busca el usuario <b>WFuneralNetBot</b> en la barra de búsqueda de la zona superior. Alternativa: Si tienes Telegram instalado en tu dispositivo actual puedes acceder a este enlace <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> y abrir la aplicación.',
				TELEGRAM4: '3. Pulse sobre el botón <b>Iniciar</b> que le aparecerá al encontrar el usuario en la aplicación.',
				TELEGRAM5: '4. Introduzca a continuación el código de validación que le aparecerá en la conversación.',
				COD_DISPOSITIVO: 'Código dispositivo',
				COD_ERROR: 'El código introducido no es válido',
				NOTIFICACIONES: 'Personaliza las notificaciones que recibirás de la actividad de la plataforma',
				SMS_TITULO: 'Configuración de notificaciones por SMS',
				SMS1: 'Introduce los números de teléfono en los que quieres recibir las notificaciones por SMS',
				COD_SMS: 'Teléfono SMS',
				LLAMADAS_TITULO: 'Configuración de notificaciones por llamada telefónica',
				LLAMADAS1: 'Introduce los números de teléfono en los que quieres recibir las llamadas telefónicas. Por este medio solo recibirás avisos de nuevas licitaciones.',
				LLAMADAS2: 'Establece el rango de horas en el que quieres recibir llamadas.',
				COD_LLAMADAS: 'Teléfono Llamadas',
				LLAMADAS_DIA: 'Sin límite de horas',
				LLAMADAS_INI: 'Hora Inicio',
				LLAMADAS_FIN: 'Hora Fin',
				ZONAS: 'Seleccione las zonas en las que notificar los eventos de la plataforma.',
				ZONAS_TODAS: 'Notificar todas las zonas'
			},
			CAMBIO: {
				AVISO: 'AVISO: El cambio de localización será efectivo en las próximas 48 horas.',
				BTN_CANCELAR: 'CANCELAR CAMBIO',
				BTN_LOC: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_PROCESO: 'PROCESANDO CAMBIO...',
				SELECCIONE_TARIFA: 'Seleccione la tarifa',
				PAGAR: 'Cantidad a pagar',
				AVISO2: 'AVISO: La nueva tarifa se aplicará inmediatamente.'
			},
			TODO_MUNDO: 'Todo el mundo',
			DISPOSITIVOS: {
				TITULO: 'Dispositivos registrados',
				NOMBRE: 'Nombre dispositivo',
				FECHA: 'Fecha registro'
			},
			EVENTOS: {
				TITULO: 'Mis eventos',
				EVENTO: 'Evento',
				FECHA: 'Fecha evento',
				DETALLE: 'Detalle evento'

			},
			ENTIDAD: {
				TITULO: 'Entidad corporativa',
				LOGO: 'Logo de la empresa',
				PRESENTACION: 'Imagen de presentación',
				ARRASTRA: 'Arrastra o pulsa aquí para subir tu imagen',
				SUBIR: 'Guardar imagen',
				BORRAR: 'Borrar imagen'
			},
			SWAL: {
				confirmationSwal: 'Datos Guardados',
				confirmationSwal_TEXT: 'Los datos se han guardado correctamente',
				borrarSwal: 'Borrar Bloqueo',
				borrarSwal_TEXT: '¿Deseas borrar este bloqueo?',
				borrarSwal_BTN1: 'Si, borrar',
				borrarSwal_BTN2: 'Cancelar',
				borrarConfirmSwal: 'Bloqueo borrado correctamente',
				borrarConfirmSwal_TEXT: 'Se ha borrado el bloqueo correctamente.',
				cancelarSuscripSwal: 'Cancelar Suscripción',
				cancelarSuscripSwal_TEXT: '¿Estás seguro de cancelar tu suscripción? Podrás seguir utilizando los servicios de WFN hasta la fecha de finalización actual de tu tarifa.',
				cancelarSuscripSwal_BTN1: 'Si, cancelar',
				cancelarSuscripSwal_BTN2: 'Cancelar',
				cancelarSuscripOkSwal: 'Suscripción cancelada correctamente',
				cancelarSuscripOkSwal_TEXT: 'Se ha cancelado correctamente la suscripción. Podrás volver a activar la tarifa desde tu perfil.',
				tarifaCambiadaSwal: 'Cambio correcto de tarifa',
				tarifaCambiadaSwal_TEXT: 'La tarifa se ha cambiado correctamente',
				tarifaCambiadaSwal_TEXT2: 'Su nueva tarifa se aplicará inmediatamente.',
				errorSwal: 'Email incorrecto',
				errorSwal_TEXT: 'El email introducido ya se encuentra registrado en el sistema.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.',
				cambioLocSwal: 'Localización cambiada correctamente',
				cambioLocSwal_TEXT: 'Su nueva localización de tarifa será efectiva en 48 horas.',
				borrarCambioSwal: 'Cambio borrado correctamente',
				borrarCambioSwal_TEXT: 'Se ha cancelado el cambio seleccionado correctamente.',
				borrarDispSwal: 'Borrar Dispositivo',
				borrarDispSwal_TEXT: '¿Deseas borrar este dispositivo?',
				borrarDispSwal_BTN1: 'Si, borrar',
				borrarDispSwal_BTN2: 'Cancelar',
				borrarDispConfirmSwal: 'Dispositivo borrado correctamente',
				borrarDispConfirmSwal_TEXT: 'Se ha borrado el dispositivo correctamente.',
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Planes y precios para',
			TITULO_FUN2: 'operadores funerarios',
			SELECCIONA: 'Selecciona el plan que mejor se adapta a tu negocio de operador funerario. Siempre tendrás la flexibilidad para subir o bajar tu plan.',
			TITULO_WFP: 'Planes para',
			SELECCIONA_WFP: 'Selecciona el ambito geográfico que mejor se adapta a tu negocio de operador funerario.',
			SELECCIONA_WFP2: 'Solo en WFP pagarás por los servicios que realices, el 10% del precio al que se haya adjudicado cada servicio.',
			SELECCIONA_WFN: 'En WFN no pagarás nada en ningún caso.',
			PAGO_ANUAL: 'PAGO ANUAL',
			AHORRO: 'AHORRO 10%',
			PAGO_MENSUAL: 'PAGO MENSUAL',
			POPULAR: 'MÁS POPULAR',
			PUJA_EN: 'Puja en',
			OFERTA_PARA: 'Oferta para',
			TARIFA1: 'todo el mundo',
			TARIFA2: 'todo el país',
			TARIFA3: '5 comunidades',
			TARIFA4: 'una comunidad',
			TARIFA1_MX: 'Mexico',
			TARIFA2_MX: 'Mexico, EEUU y Canadá',
			TARIFA3_MX: 'todo el mundo',
			MES: 'mes',
			AÑO: 'año',
			IVA: 'IVA',
			TAX: 'TAX',
			OFERTA: 'Oferta de lanzamiento',
			CONTRATAR: 'CONTRATAR',
			SELECCIONAR: 'SELECCIONAR',
			TEXTO1: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			TEXTO2: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			TEXTO3: 'Soporte personalizado de nuestro equipo.',
			TEXTO4: 'Sistema de alertas en tu móvil o email, de las licitaciones, pujas u otra información relevante que consideres.',

			TITULO_ASEG1: 'Precios para',
			TITULO_ASEG2: 'aseguradoras, empresas y colectivos',
			TITULO_ASEG3: 'WFN Aseguradoras y colectivos',
			ASEG1: 'Licita en nuestra plataforma tus servicios o traslados funerarios.',
			ASEG2: 'Obtén el mejor precio para el servicio.',
			ASEG3: 'Acceso a nuestra base de datos de 60.000 funerarias en 194 países.',
			ASEG4: 'Segmentación por criterios de calidad y excelencia (ISO, UNE, NPS).',
			ASEG5: 'Ten información sobre la trazabilidad del servicio contratado.',
			ASEG6: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			ASEG7: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			ASEG8: 'Soporte personalizado de nuestro equipo.',
			PAGANOS: 'Páganos a nosotros solo por lo contratado.',
			PRECIO: 'del precio al que se haya adjudicado la licitación final.',
			FUNERARIA: 'Pagas directamente a la funeraria el servicio contratado.',
			GRATIS: 'GRATIS'
		},
		SOPORTE: {
			TIPO: {
				VENTA: 'Ventas',
				POSTVENTA: 'Postventa',
				ADMINISTRACION: 'Administración',
				SAT: 'Servicio Técnico',
				OTRO: 'Otro'
			},
			MENU: 'Soporte WFuneralNet'
		},
		PETS: {
			CREAR: {
				TITULO: "Crear servicio PETS",
				CLIENTE: "Cliente",
				CLIENTE_SELECT: "Seleccione un cliente",
				EXPEDIENTE: "Nº de expediente",
				ORIGEN: "Origen",
				ORIGEN_SELECT: "Seleccione un origen",
				DATOS_PROP: "Datos del propietario",
				DATOS_MASC: "Datos de la mascota",
				NOMBRE: "Nombre",
				APELLIDO1: "Apellido 1",
				APELLIDO2: "Apellido 2",
				DNI: "DNI/NIE/PASAPORTE",
				TELEFONO: "Telefono",
				EMAIL: "Email",
				DIRECCION_CONT: "Dirección de contacto",
				LOCALIDAD: "Localidad",
				PROVINCIA: "Provincia",
				CP: "C.P.",
				NUMERO: "Número",
				PISO: "Escal./Piso/Puerta",
				PESO: "Peso",
				TIPO_ANIMAL: "Tipo Animal",
				CHIP: "Núm. Chip",
				FECHA_FALL: "Fecha Fallecimiento",
				LUGAR_FALL: "Tipo Lugar Fall.",
				LUGAR_FALL_SELECT: "Seleccione un lugar",
				LUGAR_FALL_TIPO: {
					DOMICILIO: "Domicilio",
					VETERINARIO: "Veterinario",
					REFUGIO: "Refugio",
					OTRO: "Otro"
				},
				DIRECCION_FALL: "Dirección de fallecimiento/recogida",
				INFO_SERV: "Información del servicio",
				OBSERVACIONES: "Observaciones",
				SUBMIT: "CREAR SERVICIO",
				SWAL: {
					confirmationAddSwal: "Servicio creado",
					confirmationAddSwal_TEXT: "Referencia:"
				}
			},
			PRESUPUESTO: {
				DATOS_CLIENTE: "Datos del cliente",
				SERVICIO: "Servicio",
				SERVICIO_BUSCAR: "Buscar Servicio",
				OPCIONAL: "opcional",
				FIRMA: "¿Activar firma electrónica?",
				FIRMA_SI: "Si",
				FIRMA_NO: "No",
				LOCALIDAD_FALL: "Localidad de fallecimiento",
				SEL_SERVICIOS: "Selección de servicios",
				PERSONALIZAR: "Personalizar paquete",
				RESTAURAR: "Restaurar precios",
				ADICIONALES: "Líneas adicionales",
				CONCEPTO: "Concepto",
				IMPUESTO: "Impuesto",
				IMPORTE: "Importe",
				IMPORTE2: "(Imp.Inc.)",
				ADD_LINEA: "Añadir línea",
				SUPLIDOS: "Suplidos",
				ADD_SUPLIDO: "Añadir suplido",
				NOTAS: "Notas adicionales",
				TOTAL: "Total",
				TOTAL_PAQUETE: "Paquete seleccionado",
				TOTAL_SERVICIOS: "Servicios adicionales",
				TOTAL_LINEAS: "Líneas adicionales",
				TOTAL_SUPLIDOS: "Suplidos",
				SUBMIT: "GENERAR PRESUPUESTO",
				RES: {
					TITULO: "Presupuesto generado correctamente",
					TEXTO: "Se ha descargado el PDF del presupuesto. También dispones del mismo en el listado de Servicios.",
					CODIGO: "Código de presupuesto",
					BTN1: "NUEVO PRESUPUESTO - NUEVO CLIENTE",
					BTN2: "NUEVO PRESUPUESTO - MISMO CLIENTE",
					EMAIL: "Enviar email a cliente",
					ADJUNTOS: "Presupuestos adjuntos",
					PRESUPUESTO: "Presupuesto",
					IMPORTE: "Importe",
					ENVIAR: "ENVIAR EMAIL",
					ENVIADO: "Email enviado correctamente",
					EMAIL_TEXT1: "Buenos días",
					EMAIL_TEXT2: "Buenas tardes",
					EMAIL_TEXT3: "Buenas noches",
					EMAIL_TEXT4: "D/Dña",
					EMAIL_TEXT5: "Muchas gracias por su contacto con BOREA.",
					EMAIL_TEXT6: "Como hemos hablado por teléfono, le enviamos nuestro presupuesto para la despedida que ha escogido.",
					EMAIL_TEXT7: "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para ello, pulse sobre el botón de firma y rellene los campos solicitados. Nuestro número de teléfono es 683 67 39 57.",
					EMAIL_TEXT8: "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para hacérnoslo llegar puede utilizar esta misma vía (correo electrónico). Nuestro número de teléfono es 683 67 39 57.",
					EMAIL_TEXT9: "Atentamente",
					EMAIL_TEXT10: "de BOREA",
				},
				MODAL_PERS: {
					TITULO: "Personalización de paquete",
					NOMBRE: "Nombre (Línea)",
					DETALLE: "Detalle",
					IMPORTE: "Importe Servicios",
					IMPORTE2: "(Imp.Inc.)",
					CERRAR: "Cerrar",
				},
				MODAL_SERV: {
					TITULO: "Selecciona un servicio",
					REFERENCIA: "Referencia",
					FECHA: "Fecha",
					ORIGEN: "Origen",
					SERVICIO: "Servicio",
					LOCALIZACION: "Localización",
					NOMBRE: "Nombre Animal",
					SELECCIONAR: "Selelccionar"
				},
				SWAL: {
					errorSwal: "Lo sentimos",
					errorSwal_TEXT: "Las tarifas de la localización introducida no están disponibles.",
					locSwal: "Error",
					locSwal_TEXT: "Debes introducir la localización para visualizar los servicios.",
					emailSwal: "Error",
					emailSwal_TEXT: "Debes personalizar el texto <b>[TU NOMBRE]</b> incluído en la zona inferior del email."
				}
			},
			RAZONES: {
				NOCONT: "Lead no contactable",
				FICTICIO: "Datos ficticios",
				INTERES: "Sin interés",
				SEGURO: "Lead tiene seguro de decesos",
				FANTASMA: "Fantasma (Cliente deja de responder)",
				COM_PRO: "Competidor - Propuesta del servicio no satisface",
				COM_PRE: "Competidor - Precio alto",
				COM_LEN: "Competidor - Lentitud en responder",
				COM_ASE: "Competidor - Asesor acude en persona",
				PP: "Seguro decesos (PP) ofrecido y perdido",
				PU: "Prima única (PU) ofrecida y perdido",
				SIN_GEO: "Sin posibilidad de servir - Falta de cobertura geográfica",
				SIN_SERV: "Sin posibilidad de servir - Falta de servicio",
				SIN_PLAN: "Sin posibilidad de servir - Quiere planificación",
				ERROR: "Llamada Errónea",
				OTRO: "Otra razón",
			},
			TIPOS: {
				PERRO: "Perro",
				GATO: "Gato",
				OTROS: "Otros",
			},
			ORIGEN: {
				LLAMADA: "Llamada",
				SOL_LLAMADA: "Sol.Llamada",
				FORMULARIO: "Formulario",
				WHATSAPP: "Whatsapp",
				CHAT: "Chat",
				NO: "No disponible",
			},
			ORIGEN_MAYUS: {
				LLAMADA: "LLAMADA",
				SOL_LLAMADA: "SOL. LLAMADA",
				FORMULARIO: "FORMULARIO",
				WHATSAPP: "WHATSAPP",
				CHAT: "CHAT",
			},
			ESTADO: {
				PENDIENTE: "Nuevo Lead",
				RECHAZADA: "Serv. Perdido",
				PRESUPUESTAR: "Pend. Presupuestar",
				LLAMADA: "Llamada Cliente",
				ACEPTADA: "Presup. Firmado",
				ASIGNADA: "Cliente Ganado",
				COMPLETADA: "Serv. Realizado"
			},
			SERVICIOS: {
				CREMA_IND: "Cremación INDIVIDUAL",
				CREMA_COL: "Cremación COLECTIVA",
				ADICIONALES: "Servicios adicionales",
				EUTANASIA: "Eutanasia y baja del chip",
				PSICO: "Atención Psicológica",
				VELATORIO: "Velatorio Incineradora",
				URGENCIA_INC: "Urgencia Incineradora",
				HUELLA: "Huella de la mascota",
				URGENCIA_VET: "Urgencia Veterinario",
				REC_DESCOMP: "Recogida Descomposición",
			},
			FILTROS: {
				REFERENCIA: "Referencia",
				FECHA_DESDE: "Fecha Desde",
				FECHA_HASTA: "Fecha Hasta",
				LOCALIZACION: "Localización",
				ORIGEN: "Origen",
				TODOS: "TODOS",
				PROVEEDOR: "Proveedor",
				ESTADO: "Estado",
				OCULTO: "Mostrar Ocultos",
				REINICIAR: "Reiniciar Filtros"
			},
			LISTADO: {
				TITULO: "Listado de servicios",
				ACTUALIZAR: "Actualizar",
				VER: "Ver Filtros",
				CERRAR: "Cerrar Filtros",
				EXPORTAR: "Exportar",
				NO: "No hay servicios",
				CONTACTO: "Nombre contacto",
				FALLECIDO: "Nombre fallecido",
				MASCOTA: "Nombre mascota",
				NO_DISP: "No disponible",
				LOC_FALL: "Localización fallecido",
				LOC_MAS: "Localización mascota",
				LOC_CONT: "Localización contacto",
				LOC_IP: "Localización IP",
				LOC: "Localización",
				SERVICIO: "Servicio",
				PENDIENTE: "Pendiente",
				TRASLADO: "TRASLADO",
				SERV_IND: "CREM.INDIV.",
				SERV_COL: "CREM.COLEC.",
				INFO_CLI: "Información del cliente",
				INFO_CONT: "Datos de contacto",
				INFO_FALL: "Datos del fallecido",
				INFO_MASC: "Datos de la mascota",
				CREADOR: "Creador",
				DETALLES_VISIT: "Detalles visita",
				VISIT_URL: "URL",
				VISIT_IP_LOC: "IP Loc.",
				VISIT_DISP: "Disp.",
				VISIT_PC: "PC",
				VISIT_MOVIL: "Móvil",
				VISIT_TABLET: "Tablet",
				VISIT_ORIGEN: "Origen",
				VISIT_MEDIO: "Medio",
				VISIT_CAMP: "Campaña",
				VISIT_KW: "KW",
				FUSION: "Servicios Fusionados",
				EDICION: "Edición",
				COMENTARIOS: "Comentarios",
				DOCUMENTOS: "Documentos",
				COM_NUEVO: "Nuevo comentario",
				COM_PL: "Comentario del servicio",
				COM_ENVIAR: "Enviar comentario",
				COM_LISTADO: "Listado comentarios",
				COM_NO: "No hay comentarios",
				DOC_NUEVO: "Nuevo documento",
				DOC_DROP: "Pulsa o arrastra aquí el documento",
				DOC_NOMBRE: "Nombre del documento",
				DOC_LISTADO: "Listado documentos",
				DOC_NO: "No hay documentos",
				DOC_BORRAR: "Borrar",
				DOC_SUBIR: "Subir Documentos",
				PRES_NUEVO: "Nuevo Presupuesto",
				PRES_LISTADO: "Listado Presupuestos",
				PRES_NO: "No hay presupuestos",
				ACEPTAR_PREGUNTA: "¿Aceptas el servicio?",
				ACEPTAR_LOAD: "Un momento...",
				ACEPTAR_SI: "ACEPTAR",
				ACEPTAR_NO: "RECHAZAR",
				RECH1: "Servicio rechazado",
				RECH2: "Servicio perdido",
				RECH_MOTIVO: "Motivo de cierre perdido",
				RECH_SEL: "Seleccione una razón",
				RECH_REACTIVAR: "REACTIVAR SERVICIO",
				RECH_OCULTAR: "OCULTAR SERVICIO",
				RECH_MOSTRAR: "MOSTRAR SERVICIO",
				RECH_FUSIONAR: "FUSIONAR SERVICIO",
				RECH_FUSIONANDO: "Fusionando servicios...",
				SERV_DETALLE: "Detalles del servicio",
				SERV_TIPO: "Tipo de servicio",
				SERV_PROV: "Proveedor del servicio",
				SERV_PROV_SEL: "Seleccione un proveedor",
				SERV_PRESTADO: "Servicio prestado",
				FIRM_CONTROL: "Control Solicitud",
				FIRM_LOADING: "Un momento...",
				FIRM_LLAMADA: "PEND.LLAMADA CLIENTE",
				FIRM_PRES: "PEND. PRESUPUESTAR",
				FIRM_FIRMADO: "PRESUPUESTO FIRMADO",
				FIRM_CANCELAR: "CANCELAR SERVICIO",
				COMIEN_TRAZA: "Trazabilidad del servicio",
				COMIEN_LOADING: "Un momento...",
				COMIEN_SI: "COMENZAR SERVICIO",
				COMIEN_NO: "CANCELAR SERVICIO",
				TRAZAS: {
					TITULO: "Trazabilidad",
					TEXT1: "El centro ha contactado con la familia.",
					TEXT2: "Datos y requisitos de eutanasia verificados",
					TEXT3: "Se ha realizado la eutanasia.",
					TEXT4: "Se ha recogido o entregado el fallecido.",
					TEXT5: "El fallecido ha llegado al centro.",
					TEXT6: "Fecha y lugar de cremación/despedida establecidos",
					TEXT7: "Velatorio realizado",
					TEXT8: "Cremación realizada",
					TEXT9: "Cenizas y documentación entregadas",
					TEXT10: "Documentación entregada",
					COMPLETADO: "Completado"
				},
				ESTADO_PEND: "Pendiente aceptación del servicio",
				ESTADO_RECH: "Servicio rechazado",
				ESTADO_ASIG: "Pendiente asignación del servicio",
				CARGAR: "CARGAR MÁS SERVICIOS",
				MODAL_FUSIONAR: {
					TITULO: "Selecciona un servicio al que fusionar (destino)",
					REFERENCIA: "Referencia",
					FECHA: "Fecha",
					ORIGEN: "Origen",
					SERVICIO: "Servicio",
					LOCALIZACION: "Localización",
					NOMBRE: "Nombre Mascota",
					ESTADO: "Estado",
					SELECCIONAR: "Seleccionar"
				}
			}
		}
	}
};
