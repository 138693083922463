import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService, SuscriptionService } from '../../../_services';

import { environment } from '../../../../environments/environment';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

declare var Stripe: any;

/**
 * Componente que gestiona los pagos de WFN
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {

  /** Variables globales */
  @ViewChild('ibanElement', {static: false}) ibanElement: ElementRef;

  hash = null;
  email = null;
  loading = false;
  resultado = false;
  estado = "select";
  currentUser = null;
  titular = null;    
  errors = {titular: false, iban: false, coupon: false};
  stripe = null;
  coupon = null;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private suscriptionService: SuscriptionService,
    private cdr: ChangeDetectorRef) { 
    }

  /**
   * Método que se lanza en el inicio del componente y carga los datos del usuario y el estado (si existe)
   */
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.currentUser = this.authenticationService.currentUserValue;
      var estado = params.get("estado");
      this.stripe = Stripe(environment.stripe);
      if(estado) this.loadResultado(estado)
      else this.loadPago();
    });

    
  }

  /**
   * Procesa el resultado de una operación en Stripe.
   * Gestiona y crea la suscripción.
   * @param estado 
   */
  loadResultado(estado: any) {
    this.resultado = true;
    this.loading = true;
    if(estado == "success") {
      this.estado = "success";
      //Comprobamos si el sessionid es correcto
      this.route.queryParams.subscribe(params => {
        var session_id = params["session_id"];

        //Si existe la sesión...
        this.paymentService.getSession(session_id)
        .subscribe(
          session => {
            if(this.currentUser.proveedor && this.currentUser.wfn) {
              //Si existe la suscripción y está activa...
              this.paymentService.getSubscription(session["subscription"])
              .subscribe(
                subscription => {
                  console.warn(subscription);
                  if(subscription["status"] == "active") {
                    var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
                    var modif = {id: suscrip.id, pagado: true, activa: true, stripeSusc: subscription["id"], startDate: new Date()};
                    this.suscriptionService.update(modif)
                      .pipe(first())
                      .subscribe(
                        data => {
                          console.log("OK", data);
                          this.loading = false;
                          this.estado = "success";
                          this.cdr.detectChanges();
                        },
                        error => {
                          console.error(error);
                          this.loading = false;
                      });
                  } else {
                    this.router.navigate(['/home']);
                  }
                },
                error => {
                  console.error(error);
                  this.router.navigate(['/home']);
              });
            } else {
              var modif = {customer: this.currentUser.stripeCustomer, paymentMethod: session.setup_intent.payment_method};
              this.paymentService.setPaymentMethod(modif)
                .pipe(first())
                .subscribe(
                  data => {
                    console.log("OK", data);
                    if(this.currentUser.wfp) {
                      //if(this.currentUser.wfn) {
                        //this.pagarGratis(); //
                      //} else {
                        var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
                        var modif = {id: suscrip.id, pagado: true, activa: true, startDate: new Date()};
                        this.suscriptionService.update(modif)
                          .pipe(first())
                          .subscribe(
                            data => {
                              console.log("OK", data);
                              this.loading = false;
                              this.estado = "success";
                              this.cdr.detectChanges();
                            },
                            error => {
                              console.error(error);
                              this.loading = false;
                          });
                      //}
                      
                    } else {
                      var updateObj = {id: this.currentUser.id, iban: "STRIPE"};
                      this.userService.update(updateObj)
                      .pipe(first())
                      .subscribe(
                        data => {
                          this.currentUser.iban = "STRIPE";
                          this.authenticationService.changeUser(this.currentUser);
                          this.loading = false;
                          this.estado = "success";
                          this.cdr.detectChanges();
                        });
                    }
                    
                  },
                  error => {
                    console.error(error);
                    this.loading = false;
                });       
            }
          },
          error => {
            console.error(error);
            this.router.navigate(['/home']);
        });



        

      });


    } else {
      this.estado = "fail";
      this.loading = false;
    }
  }

  /**
   * Carga el método correspondiente de pago dependiendo de las características del usuario.
   */
  loadPago() {
    this.userService.getById(this.currentUser.id)
    .subscribe(user => {
      this.currentUser = user;
      console.warn(this.currentUser);
      this.cdr.detectChanges();

      if(this.currentUser.proveedor && this.currentUser.suscripciones.length > 0 && !this.currentUser.suscripciones[this.currentUser.suscripciones.length-1].pagado) {
        //Tiene pago pendiente
        //Versión con pagos $$
        if(this.currentUser.wfp && !this.currentUser.wfn) {  
          this.pagoTarjetaWFP();  //Los de WFP siempre van al pago con tarjeta
        } else if(this.currentUser.pais.id == 142) {  
          this.pagoTarjeta(); // Mexico forzamos al pago con tarjeta. No hay SEPA.
        }
        // - El resto, les dejamos elegir SEPA o TARJETA.

        //Versión gratuita - Si se quisiera poner. Solo para pruebas.
        /*if(this.currentUser.wfp) {
          this.pagoTarjetaWFP();
        } else {
          this.pagarGratis();
        }*/
      } else if(this.currentUser.ofertante && this.currentUser.pais.id == 142) {
        this.pagoTarjetaAseg();
      } else {
        this.router.navigate(['/home']);
      }

      
    });
  }

  /**
   * Crea una sesión de pago con tarjeta llevando al usuario a la pasarela segura de pagos.
   */
  pagoTarjeta() {
    this.loading = true;
    this.cdr.detectChanges();
    var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];


    this.paymentService.createCheckoutSession(suscrip.stripeProd, this.currentUser.stripeCustomer, this.currentUser.pais.id).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      console.warn(data);
      this.stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
          console.warn(res);
        });
    }.bind(this));
  }

  iban = null;
  loadingIban = false;

  /**
   * Muestra el formulario de pago SEPA y gestiona sus eventos.
   */
  pagoIban() {
    this.estado = "iban";
    this.cdr.detectChanges();
    var elements = this.stripe.elements();

    var style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    };
    
    var options = {
      style: style,
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: 'ES',
    };
    
    // Create an instance of the IBAN Element
    this.iban = elements.create('iban', options);

    this.iban.on('change', function(event) {
      var displayError = document.getElementById('error-message');
      if (event.error) {
        displayError.textContent = event.error.message;
        this.errors.iban = true;
      } else {
        displayError.textContent = '';
        this.errors.iban = false;
      }
      this.cdr.detectChanges();
    }.bind(this));
    
    // Add an instance of the IBAN Element into the `iban-element` <div>
    //iban.mount('#iban-element');
    this.iban.mount(this.ibanElement.nativeElement);

    this.iban.addEventListener('change', ({ error }) => {
        //this.cardErrors = error && error.message;
    });
  }

  /**
   * Procesa el pago SEPA gestionando el método de pago y creando la suscripción.
   */
  pagarIban() {
    if(!this.titular) this.errors.titular = true
    else this.errors.titular = false;
    this.cdr.detectChanges();

    if(this.errors.titular || this.errors.iban) return false;

    this.loadingIban = true;
    this.cdr.detectChanges();

    this.paymentService.createSetupIntent(this.currentUser.stripeCustomer).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      console.warn(data);

      this.stripe.confirmSepaDebitSetup(
        data.clientSecret,
        {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: {
              name: this.titular,
              email: this.currentUser.email,
            },
          },
        }
      ).then(function(result) {
        // Handle result.error or result.setupIntent
        console.warn(result)
        if(result.setupIntent.status == "succeeded") {
          var modif = {customer: this.currentUser.stripeCustomer, paymentMethod: result.setupIntent.payment_method, subscription: null};
          this.paymentService.setPaymentMethod(modif)
            .pipe(first())
            .subscribe(
              data => {
                var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
                this.paymentService.createSubscription(suscrip.stripeProd, this.currentUser.stripeCustomer, this.coupon, this.currentUser.pais.id).then(function(data1) {
                  console.warn(data1);

                  if(data1.subscription) {
                    var modif = {id: suscrip.id, pagado: true, activa: true, stripeSusc: data1.subscription.id, startDate: new Date()};
                    this.suscriptionService.update(modif)
                      .pipe(first())
                      .subscribe(
                        data => {
                          console.log("OK", data);
                          this.loading = false;
                          this.estado = "success";
                          this.cdr.detectChanges();
                        },
                        error => {
                          console.error(error);
                          this.loading = false;
                      });
                  } else {
                    this.loadingIban = false;
                    this.errors.coupon = true;
                    this.cdr.detectChanges();
                  }
                }.bind(this));
              },
              error => {
                console.error(error);
            });        
        }



      }.bind(this));
    }.bind(this));
  }

  /**
   * Crea una suscripción gratuita. Para pruebas.
   */
  pagarGratis() {
    this.resultado = true;
    this.loading = true;
    this.cdr.detectChanges();
    var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
    this.paymentService.createSubscription(suscrip.stripeProd, this.currentUser.stripeCustomer, "WFNFREE", this.currentUser.pais.id).then(function(data1) {
      console.warn(data1);

      if(data1.subscription) {
        var modif = {id: suscrip.id, pagado: true, activa: true, stripeSusc: data1.subscription.id, startDate: new Date()};
        this.suscriptionService.update(modif)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
              this.loading = false;
              this.estado = "success";
              this.cdr.detectChanges();
            },
            error => {
              console.error(error);
              this.loading = false;
          });
      } else {
        this.loadingIban = false;
        this.errors.coupon = true;
        this.cdr.detectChanges();
      }
    }.bind(this));
  }

  /**
   * MEXICO: Ir a página de pago con tarjeta
   */
  pagoTarjetaAseg() {
    this.loading = true;
    this.cdr.detectChanges();

    this.paymentService.createSetupCheckoutSessionAseg(this.currentUser.stripeCustomer).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      this.stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
          console.warn(res);
        });
    }.bind(this));
  }

  /**
   * WFP: Ir a página de pago con tarjeta
   */
  pagoTarjetaWFP() {
    this.loading = true;
    this.resultado = false;
    this.cdr.detectChanges();

    this.paymentService.createSetupCheckoutSessionWFP(this.currentUser.stripeCustomer, 'card').then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      this.stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
          console.warn(res);
        });
    }.bind(this));
  }

  /**
   * WFP: Ir a página de pago con SEPA
   */
   pagoSepaWFP() {
    this.loading = true;
    this.cdr.detectChanges();

    this.paymentService.createSetupCheckoutSessionWFP(this.currentUser.stripeCustomer, 'sepa_debit').then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      this.stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
          console.warn(res);
        });
    }.bind(this));
  }

}
