import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, CentroService, LocalizacionService, PrecioService, UserService } from '../../../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'kt-admin-wfp-localizacion',
  templateUrl: './admin-wfp-localizacion.component.html',
  styleUrls: ['./admin-wfp-localizacion.component.scss']
})
export class AdminWfpLocalizacionComponent implements OnInit {

  @ViewChild('modalGrupos', {static: false}) private modalGrupos: NgbModal;
  @ViewChild('modalCentros', {static: false}) private modalCentros: NgbModal;
  @ViewChild('modalCentro', {static: false}) private modalCentro: NgbModal;
  @ViewChild('modalTarifas', {static: false}) private modalTarifas: NgbModal;
  @ViewChild('confirmationDeleteGrupoSwal', {static: false}) private confirmationDeleteGrupoSwal: SwalComponent
  @ViewChild('deleteGrupoAskSwal', {static: false}) private deleteGrupoAskSwal: SwalComponent
  @ViewChild('confirmationCreateGrupoSwal', {static: false}) private confirmationCreateGrupoSwal: SwalComponent
  @ViewChild('deleteCentroAskSwal', {static: false}) private deleteCentroAskSwal: SwalComponent
  @ViewChild('confirmationDeleteCentroSwal', {static: false}) private confirmationDeleteCentroSwal: SwalComponent
  @ViewChild('confirmationEditCentroSwal', {static: false}) private confirmationEditCentroSwal: SwalComponent
  @ViewChild('confirmationCreateCentroSwal', {static: false}) private confirmationCreateCentroSwal: SwalComponent
  @ViewChild('deleteCentroSwal', {static: false}) private deleteCentroSwal: SwalComponent
  @ViewChild('tipoCentroSwal', {static: false}) private tipoCentroSwal: SwalComponent
  @ViewChild('cpCentroSwal', {static: false}) private cpCentroSwal: SwalComponent
  @ViewChild('confirmationTarifasSwal', {static: false}) private confirmationTarifasSwal: SwalComponent

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private localizacionService: LocalizacionService,
    private precioService: PrecioService,
    private centroService: CentroService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadLocalizaciones();
  }



  async loadLocalizaciones() {
    let locs = await this.localizacionService.getAll();
    this.originalRows = locs;
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

  // ----------- GESTIONAR LOCALIZACIONES -------------

  currentLoc = null;
  grupos = [];
  centros = [];
  precios = {};
  loadingCrearGrupo = false;
  loadingCrearCentro = false;

  // ----- GRUPOS -----

  async editarGrupos(row: any) {
    this.currentLoc = row;
    let precios = await this.precioService.getByLocalizacionAsync(row.id);
    console.warn(precios);
    this.grupos = [];

    let hashGr = {};
    this.grupos.push({name: "Principal", editable: false});;
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }

    this.modalService.open(this.modalGrupos, { centered: true, size: 'md' } );
  }

  objEliminarGrupo = null;
  nuevoGrupo = null;

  async eliminarGrupo(row: any) {
    this.objEliminarGrupo = row;
    this.deleteGrupoAskSwal.fire();
  }

  async continuarEliminarGrupo() {
    // Eliminar tarifas
    let precios = await this.precioService.getByLocalizacionGrupoAsync(this.currentLoc.id, "\"" + this.objEliminarGrupo.name + "\"");

    for await(let precio of precios) {
      await this.precioService.delete(precio.id);
    }

    // Quitar parámetro del centro
    let centros = await this.centroService.getByCPGrupo(this.currentLoc.cpDesde, this.currentLoc.cpHasta, this.objEliminarGrupo.name);
    console.warn(centros);

    for await(let centro of centros) {
      let objEdit = {
        id: centro.id,
        grupo: null
      }

      await this.centroService.update(objEdit);
    }


    this.confirmationDeleteGrupoSwal.fire();

    this.modalService.dismissAll();

    this.editarGrupos(this.currentLoc);
  }

  async guardarPrecio(servicio: any) {
    return this.precioService.post({
      servicio: servicio,
      localizacion: this.currentLoc.id,
      importe: 0,
      divisa: "EUR",
      base21: this.currentLoc.impuesto == "IVA" ? 0 : null,
      base10: 0,
      base7: this.currentLoc.impuesto != "IVA" ? 0 : null,
      importeTercero: 0,
      base21Tercero: this.currentLoc.impuesto == "IVA" ? 0 : null,
      base10Tercero: 0,
      base7Tercero: this.currentLoc.impuesto != "IVA" ? 0 : null,
      grupo: this.nuevoGrupo
    })
  }

  async crearGrupo() {
    if(!this.nuevoGrupo) return;
    let enc = false;
    for(let grupo of this.grupos) {
      if(grupo.name == this.nuevoGrupo) enc = true;
    }
    if(enc) return;
    this.loadingCrearGrupo = true;

    await this.guardarPrecio(20);
    await this.guardarPrecio(21);
    await this.guardarPrecio(22);
    await this.guardarPrecio(23);
    await this.guardarPrecio(24);
    await this.guardarPrecio(25);
    await this.guardarPrecio(45);


    this.confirmationCreateGrupoSwal.fire();

    this.modalService.dismissAll();
    this.loadingCrearGrupo = false;
    this.nuevoGrupo = null;

    this.editarGrupos(this.currentLoc);
  }

  // ----- CENTROS -----

  modoCentros = "";

  async editarCentros(row: any, modo: any) {
    this.currentLoc = row;
    this.modoCentros = modo;

    if(this.modoCentros == "FUNERARIAS") {
      this.centros = await this.centroService.getByCPAsyncFuneraria(this.currentLoc.cpDesde, this.currentLoc.cpHasta);
    } else if(this.modoCentros == "TANATORIOS") {
      this.centros = await this.centroService.getByCPAsyncTanatorio(this.currentLoc.cpDesde, this.currentLoc.cpHasta);
    }

    this.modalService.open(this.modalCentros, { centered: true, size: 'xl' } );

    let precios = await this.precioService.getByLocalizacionAsync(row.id);
    this.grupos = [];

    let hashGr = {};
    //this.grupos.push({name: "Principal", editable: false});
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }
  }

  objEliminarCentro = null;

  async eliminarCentro(row: any) {
    for(let centro of this.centros) {
      if(centro.centroRelacionado && centro.centroRelacionado.id == row.id) {
        this.deleteCentroSwal.fire();
        return;
      }
    }
    this.objEliminarCentro = row;
    this.deleteCentroAskSwal.fire();
  }

  async continuarEliminarCentro() {
    let obj = {
      id: this.objEliminarCentro.id,
      borrado: 1
    }

    await this.centroService.update(obj);

    this.confirmationDeleteCentroSwal.fire();

    this.modalService.dismissAll();
    
    this.editarCentros(this.currentLoc, this.modoCentros);
  }

  async editarCentro(row: any) {
    this.centroEdit = JSON.parse(JSON.stringify(row));
    if(!this.centroEdit.grupo) this.centroEdit.grupo = "";
    if(!this.centroEdit.centroRelacionado) this.centroEdit.centroRelacionado = ""
    else this.centroEdit.centroRelacionado = this.centroEdit.centroRelacionado.id;
    this.modalService.open(this.modalCentro, { centered: true, size: 'md' } );
  }

  centroEdit = null;
  centroEditError = {} as any;

  async nuevoCentro() {
    this.centroEdit = {
      grupo: "",
      centroRelacionado: "",
      tanatorio: false,
      crematorio: false,
      funeraria: false,
      albia: false,
      borrado: false
    } as any;
    this.modalService.open(this.modalCentro, { centered: true, size: 'md' } );
  }

  async guardarCentro() {
    let errors = false;
    if(!this.centroEdit.nombre || this.centroEdit.nombre == "") {
      this.centroEditError.nombre = true;
      errors = true;
    } else {
      this.centroEditError.nombre = false;
    }
    if(!this.centroEdit.domicilio || this.centroEdit.domicilio == "") {
      this.centroEditError.domicilio = true;
      errors = true;
    } else {
      this.centroEditError.domicilio = false;
    }
    if(!this.centroEdit.poblacion || this.centroEdit.poblacion == "") {
      this.centroEditError.poblacion = true;
      errors = true;
    } else {
      this.centroEditError.poblacion = false;
    }
    if(!this.centroEdit.cp || this.centroEdit.cp == "") {
      this.centroEditError.cp = true;
      errors = true;
    } else {
      this.centroEditError.cp = false;
    }
    if(!this.centroEdit.crematorio && !this.centroEdit.centroRelacionado && this.modoCentros == "TANATORIOS") {
      this.centroEditError.centroRelacionado = true;
      errors = true;
    } else {
      this.centroEditError.centroRelacionado = false;
    }
    this.cdr.detectChanges();
    if(errors) return;

    if(!this.centroEdit.tanatorio && !this.centroEdit.crematorio && this.modoCentros == "TANATORIOS") {
      this.tipoCentroSwal.fire();
      return;
    }

    if(this.centroEdit.cp < this.currentLoc.cpDesde || this.centroEdit.cp > this.currentLoc.cpHasta) {
      this.centroEditError.cpLoc = true;
      this.cpCentroSwal.fire();
      return;
    } else {
      this.centroEditError.cpLoc = false;
    }

    this.loadingCrearCentro = true;
    this.cdr.detectChanges();

    if(this.centroEdit.grupo == "") this.centroEdit.grupo = null;
    if(this.centroEdit.centroRelacionado == "" || this.centroEdit.crematorio) this.centroEdit.centroRelacionado = null;

    if(this.modoCentros == "FUNERARIAS") this.centroEdit.funeraria = true;

    if(this.centroEdit.id) {  // Actualización
      await this.centroService.update(this.centroEdit);
      this.confirmationEditCentroSwal.fire();
    } else {  // Crear nuevo
      await this.centroService.post(this.centroEdit);
      this.confirmationCreateCentroSwal.fire();
    }

    this.modalService.dismissAll();
    
    this.editarCentros(this.currentLoc, this.modoCentros);
  }

  // ----- TARIFAS -----

  grupoSel = "";
  loadingTarifas = false;
  loadingGuardarTarifa = false;

  async editarTarifas(row: any) {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    if(this.currentLoc && row.id != this.currentLoc.id) this.grupoSel = "";
    this.currentLoc = row;

    let precios = await this.precioService.getByLocalizacionAsync(this.currentLoc.id);

    this.grupos = [];

    let hashGr = {};
    //this.grupos.push({name: "Principal", editable: false});
    for(let precio of precios) {
      if(precio.grupo && !hashGr[precio.grupo]) {
        this.grupos.push({name: precio.grupo, editable: true});
        hashGr[precio.grupo] = true;
      }
    }

    this.modalService.open(this.modalTarifas, { centered: true, size: 'lg' } );

    this.mostrarTarifasGrupo()
  }

  async mostrarTarifasGrupo() {
    this.loadingTarifas = true;
    this.cdr.detectChanges();
    let precios = await this.precioService.getByLocalizacionGrupoAsync(this.currentLoc.id, this.grupoSel == "" ? null : "\"" + this.grupoSel + "\"");
    let preciosBase = [];
    if(this.grupoSel != "") preciosBase = await this.precioService.getByLocalizacionGrupoAsync(this.currentLoc.id, null);
    let baseHash = {};
    for(let precio of preciosBase) {
      baseHash[precio.servicio.abreviatura] = precio;
    }

    console.warn(precios);

    this.precios = {};

    for(let precio of precios) {
      this.precios[precio.servicio.abreviatura] = precio;
    }
    if(!this.precios['WFP_PACK1_ENTIERRO']) {
      this.precios['WFP_PACK1_ENTIERRO'] = { 
        servicio: 20,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK1_ENTIERRO'] ? baseHash['WFP_PACK1_ENTIERRO'].importe : 0, 
        importeTercero: baseHash['WFP_PACK1_ENTIERRO'] ? baseHash['WFP_PACK1_ENTIERRO'].importeTercero : 0
      };
    }
    if(!this.precios['WFP_PACK2_ENTIERRO']) {
      this.precios['WFP_PACK2_ENTIERRO'] = { 
        servicio: 21,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK2_ENTIERRO'] ? baseHash['WFP_PACK2_ENTIERRO'].importe : 0, 
        importeTercero: baseHash['WFP_PACK2_ENTIERRO'] ? baseHash['WFP_PACK2_ENTIERRO'].importeTercero : 0
      };
    }
    if(!this.precios['WFP_PACK3_ENTIERRO']) {
      this.precios['WFP_PACK3_ENTIERRO'] = { 
        servicio: 22,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK3_ENTIERRO'] ? baseHash['WFP_PACK3_ENTIERRO'].importe : 0, 
        importeTercero: baseHash['WFP_PACK3_ENTIERRO'] ? baseHash['WFP_PACK3_ENTIERRO'].importeTercero : 0
      };
    }
    if(!this.precios['WFP_PACK1_CREMA']) {
      this.precios['WFP_PACK1_CREMA'] = { 
        servicio: 23,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK1_CREMA'] ? baseHash['WFP_PACK1_CREMA'].importe : 0, 
        importeTercero: baseHash['WFP_PACK1_CREMA'] ? baseHash['WFP_PACK1_CREMA'].importeTercero : 0
      };
    }
    if(!this.precios['WFP_PACK2_CREMA']) {
      this.precios['WFP_PACK2_CREMA'] = { 
        servicio: 24,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK2_CREMA'] ? baseHash['WFP_PACK2_CREMA'].importe : 0, 
        importeTercero: baseHash['WFP_PACK2_CREMA'] ? baseHash['WFP_PACK2_CREMA'].importeTercero : 0
      };
    }
    if(!this.precios['WFP_PACK3_CREMA']) {
      this.precios['WFP_PACK3_CREMA'] = { 
        servicio: 25,
        localizacion: this.currentLoc.id,
        divisa: "EUR",
        grupo: this.grupoSel == "" ? null : this.grupoSel,
        importe: baseHash['WFP_PACK3_CREMA'] ? baseHash['WFP_PACK3_CREMA'].importe : 0, 
        importeTercero: baseHash['WFP_PACK3_CREMA'] ? baseHash['WFP_PACK3_CREMA'].importeTercero : 0
      };
    }

    this.loadingTarifas = false;
    this.cdr.detectChanges();
  }

  async guardadoTarifa(obj: any) {
    if(obj.id) {
      delete obj.servicio;
      delete obj.localizacion;
      return this.precioService.update(obj);
    } else {
      return this.precioService.post(obj);
    }
  }

  async guardarTarifa() {
    if(this.loadingGuardarTarifa) return;

    this.loadingGuardarTarifa = true;
    this.cdr.detectChanges();

    if(this.currentLoc.impuesto == "IVA") {
      // WFP_PACK1_ENTIERRO
      this.precios['WFP_PACK1_ENTIERRO'].base21 = this.precios['WFP_PACK1_ENTIERRO'].importe / 1.21;
      this.precios['WFP_PACK1_ENTIERRO'].base21Tercero = this.precios['WFP_PACK1_ENTIERRO'].importeTercero / 1.21;
    
      await this.guardadoTarifa(this.precios['WFP_PACK1_ENTIERRO']);

      // WFP_PACK2_ENTIERRO
      this.precios['WFP_PACK2_ENTIERRO'].base21 = this.precios['WFP_PACK2_ENTIERRO'].importe / 1.21;
      this.precios['WFP_PACK2_ENTIERRO'].base21Tercero = this.precios['WFP_PACK2_ENTIERRO'].importeTercero / 1.21;
    
      await this.guardadoTarifa(this.precios['WFP_PACK2_ENTIERRO']);

      // WFP_PACK3_ENTIERRO
      if(this.currentLoc.id != 3 && this.currentLoc.id != 46 && this.currentLoc.id != 47 && this.currentLoc.id != 48) {
        this.precios['WFP_PACK3_ENTIERRO'].base21 = this.precios['WFP_PACK2_ENTIERRO'].importe / 1.21;
        this.precios['WFP_PACK3_ENTIERRO'].base21Tercero = this.precios['WFP_PACK2_ENTIERRO'].importeTercero / 1.21;

        this.precios['WFP_PACK3_ENTIERRO'].base10 = (this.precios['WFP_PACK3_ENTIERRO'].importe - this.precios['WFP_PACK2_ENTIERRO'].importe) / 1.10;
        this.precios['WFP_PACK3_ENTIERRO'].base10Tercero = (this.precios['WFP_PACK3_ENTIERRO'].importeTercero - this.precios['WFP_PACK2_ENTIERRO'].importeTercero) / 1.10;
      } else {
        this.precios['WFP_PACK3_ENTIERRO'].base21 = this.precios['WFP_PACK3_ENTIERRO'].importe / 1.21;
        this.precios['WFP_PACK3_ENTIERRO'].base21Tercero = this.precios['WFP_PACK3_ENTIERRO'].importeTercero / 1.21;
      }

      await this.guardadoTarifa(this.precios['WFP_PACK3_ENTIERRO']);

      // WFP_PACK1_CREMA
      this.precios['WFP_PACK1_CREMA'].base21 = this.precios['WFP_PACK1_CREMA'].importe / 1.21;
      this.precios['WFP_PACK1_CREMA'].base21Tercero = this.precios['WFP_PACK1_CREMA'].importeTercero / 1.21;
    
      await this.guardadoTarifa(this.precios['WFP_PACK1_CREMA']);

      // WFP_PACK2_CREMA
      this.precios['WFP_PACK2_CREMA'].base21 = this.precios['WFP_PACK2_CREMA'].importe / 1.21;
      this.precios['WFP_PACK2_CREMA'].base21Tercero = this.precios['WFP_PACK2_CREMA'].importeTercero / 1.21;
    
      await this.guardadoTarifa(this.precios['WFP_PACK2_CREMA']);

      // WFP_PACK3_CREMA
      if(this.currentLoc.id != 3 && this.currentLoc.id != 46 && this.currentLoc.id != 47 && this.currentLoc.id != 48) {
        this.precios['WFP_PACK3_CREMA'].base21 = this.precios['WFP_PACK2_CREMA'].importe / 1.21;
        this.precios['WFP_PACK3_CREMA'].base21Tercero = this.precios['WFP_PACK2_CREMA'].importeTercero / 1.21;

        this.precios['WFP_PACK3_CREMA'].base10 = (this.precios['WFP_PACK3_CREMA'].importe - this.precios['WFP_PACK2_CREMA'].importe) / 1.10;
        this.precios['WFP_PACK3_CREMA'].base10Tercero = (this.precios['WFP_PACK3_CREMA'].importeTercero - this.precios['WFP_PACK2_CREMA'].importeTercero) / 1.10;
      } else {
        this.precios['WFP_PACK3_CREMA'].base21 = this.precios['WFP_PACK3_CREMA'].importe / 1.21;
        this.precios['WFP_PACK3_CREMA'].base21Tercero = this.precios['WFP_PACK3_CREMA'].importeTercero / 1.21;
      }

      await this.guardadoTarifa(this.precios['WFP_PACK3_CREMA']);
    } else {  // CANARIAS
      // WFP_PACK1_ENTIERRO
      this.precios['WFP_PACK1_ENTIERRO'].base7 = this.precios['WFP_PACK1_ENTIERRO'].importe / 1.03;
      this.precios['WFP_PACK1_ENTIERRO'].base7Tercero = this.precios['WFP_PACK1_ENTIERRO'].importeTercero / 1.03;
    
      await this.guardadoTarifa(this.precios['WFP_PACK1_ENTIERRO']);

      // WFP_PACK2_ENTIERRO
      this.precios['WFP_PACK2_ENTIERRO'].base7 = this.precios['WFP_PACK2_ENTIERRO'].importe / 1.03;
      this.precios['WFP_PACK2_ENTIERRO'].base7Tercero = this.precios['WFP_PACK2_ENTIERRO'].importeTercero / 1.03;
    
      await this.guardadoTarifa(this.precios['WFP_PACK2_ENTIERRO']);

      // WFP_PACK3_ENTIERRO
      this.precios['WFP_PACK3_ENTIERRO'].base7 = this.precios['WFP_PACK3_ENTIERRO'].importe / 1.03;
      this.precios['WFP_PACK3_ENTIERRO'].base7Tercero = this.precios['WFP_PACK3_ENTIERRO'].importeTercero / 1.03;
      
      await this.guardadoTarifa(this.precios['WFP_PACK3_ENTIERRO']);

      // WFP_PACK1_CREMA
      this.precios['WFP_PACK1_CREMA'].base7 = this.precios['WFP_PACK1_CREMA'].importe / 1.03;
      this.precios['WFP_PACK1_CREMA'].base7Tercero = this.precios['WFP_PACK1_CREMA'].importeTercero / 1.03;
    
      await this.guardadoTarifa(this.precios['WFP_PACK1_CREMA']);

      // WFP_PACK2_CREMA
      this.precios['WFP_PACK2_CREMA'].base7 = this.precios['WFP_PACK2_CREMA'].importe / 1.03;
      this.precios['WFP_PACK2_CREMA'].base7Tercero = this.precios['WFP_PACK2_CREMA'].importeTercero / 1.03;
    
      await this.guardadoTarifa(this.precios['WFP_PACK2_CREMA']);

      // WFP_PACK3_CREMA
      this.precios['WFP_PACK3_CREMA'].base7 = this.precios['WFP_PACK3_CREMA'].importe / 1.03;
      this.precios['WFP_PACK3_CREMA'].base7Tercero = this.precios['WFP_PACK3_CREMA'].importeTercero / 1.03;
      
      await this.guardadoTarifa(this.precios['WFP_PACK3_CREMA']);
    }

    this.modalService.dismissAll();

    this.editarTarifas(this.currentLoc);

    this.confirmationTarifasSwal.fire();

    this.loadingGuardarTarifa = false;
    this.cdr.detectChanges();

  }



}
