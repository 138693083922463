import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, TarifaService, UserService, SuscriptionService, NotificacionService, CertificadoService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import{ GlobalVariables } from '../../../_common/global-variables';

declare function initDropdown(): any;

/**
 * Componente que gestiona el formulario de registro inicial.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  /** Variables globales */
  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSMSSwal', {static: false}) private errorSMSSwal: SwalComponent
  @ViewChild('errorTelegramSwal', {static: false}) private errorTelegramSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('questionDeleteFileSwal', {static: false}) private questionDeleteFileSwal: SwalComponent
  @ViewChild('confirmationDeleteFileSwal', {static: false}) private confirmationDeleteFileSwal: SwalComponent
  @ViewChild('confirmationFileSwal', {static: false}) private confirmationFileSwal: SwalComponent

  model: any = {};
  currentUser: any;
  tarifaPar = null;
  paises: any;
  paisesHash = {};
  estados: any;
  estadosHash = {};
  estadosAll: any;
  tarifas = [];
  tarifasHash = {};
  vistaTarifas = "anual";
  wiz3Oculto = true;
  modo = { wfn: false, wfp: false }
  loading = false;
  wiz1Form: FormGroup;
  modoPlataforma = null;


	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificacionService: NotificacionService,
    private userService: UserService,
    private stateService: StateService,
		private translate: TranslateService,
    private tarifaService: TarifaService,
    private countryService: CountryService,
    private suscriptionService: SuscriptionService,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
	}

  /**
   * Preparación del componente
   */
	ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tarifaPar = params.get("tarifa");
      this.currentUser = this.authenticationService.currentUserValue;
      this.modoPlataforma = GlobalVariables.modoPlataforma;
      
      this.modo.wfn = true;
      this.modo.wfp = true;
      
      if(this.currentUser.status == "ACTIVO") {
        this.router.navigate(['/']);
      } else {
        if(this.currentUser.pais && !this.currentUser.pais.id) {
          this.currentUser.pais = {id: this.currentUser.pais};
        }
        this.initWiz1Form();
        //this.loadPais();
        this.loadTarifas();
        this.loadCertificados();
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 100);
        initDropdown();
        if(this.currentUser.pais.id == 142) this.vistaTarifas = 'mensual';
      }
    })
    
  }

  /**
   * Hace scroll hasta el elemento
   * @param id Identificador del elemento al que hacer scroll
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  /**
   * Vuelve a la vista de tarifas
   */
  volverTarifa(){
    this.wizard.goTo(1);
    this.wizard.goTo(1);
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Evento de cambio de pais en el formulario
   * @param event Objeto con la información del evento
   */
  onChangePais(event){
    console.warn(event);
    //this.wiz1Form.controls["pais"].disable();
    this.wiz1Form.controls["provincia"].disable();
    this.loadProvincias(event.target.value);

    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.controls["pais"].value) {
        lista.push(estado);
      }
    }
    console.warn(this.tarifa);
    if(this.tarifa && this.currentUser.pais.id != 142) {
      for(var i = 0; i < this.tarifa.provincias; i++) {
        this.provinciasSel.push({num: i+1, val: "", pais: this.wiz1Form.controls["pais"].value, lista: lista});
      }
      for(var i = 0; i < this.tarifa.paises; i++) {
        this.paisesSel.push({num: i+1, val: ""});
      }
    } else if(this.tarifa && this.currentUser.pais.id == 142) {
      if(this.tarifa.id == 10 || this.tarifa.id == 11) {
        this.paisesSel.push({num: 1, val: "142"});
        if(this.tarifa.id == 11) {
          this.paisesSel.push({num: 2, val: "233"});
          this.paisesSel.push({num: 3, val: "39"});
        }
      }
      

    }
  }

  /**
   * Carga de las provincias de un país
   * @param pais Identificador del país
   */
  loadProvincias(pais){
		this.stateService.getAllByCountry(pais)
	    .subscribe(estados => {
        this.estados = estados;
        //this.wiz1Form.controls["pais"].enable();
        this.wiz1Form.controls["provincia"].enable();
        this.cdr.detectChanges();
      });
  }

  /**
   * Carga del listado completo de provincias
   */
  loadProvinciasAll(){
    console.log("loadProvinciasAll()");
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }

        this.loadPaises();
      });
  }

  /**
   * Carga del listado completo de países
   */
  loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	  .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
      }

      if(this.currentUser.pais && this.currentUser.pais.id) {
        this.wiz1Form.get('pais').setValue(this.currentUser.pais.id);
        this.onChangePais({target: {value: this.currentUser.pais.id}});
      } else if(this.currentUser.pais) {
        this.wiz1Form.get('pais').setValue(this.currentUser.pais);
        this.onChangePais({target: {value: this.currentUser.pais}});
      }
		});
	}

  /**
   * Carga del país del usuario logeado
   */
  loadPais(){
    this.countryService.getById(this.currentUser.pais)
    .subscribe(pais => {
      this.currentUser.paisStr = pais["name"];
      this.cdr.detectChanges();
    });
  }

  /**
   * Carga de los maestros de tarifas.
   */
  loadTarifas(){
		this.tarifaService.getAll()
	    .subscribe(tarifas => {
        this.tarifas = tarifas;
        this.cdr.detectChanges();
        var nombreTarifa = "";
        var frecTarifa = "";
        var pais = "ES";
        if(this.tarifaPar) {
          nombreTarifa = this.tarifaPar.split('-')[0];
          frecTarifa = this.tarifaPar.split('-')[1];
          if(this.currentUser.pais.id == 142) pais = "MX";
        }
        for(var i = 0; i < tarifas.length; i++){
          this.tarifasHash[tarifas[i].id+""] = tarifas[i];
          if(this.tarifaPar && tarifas[i].nombre == nombreTarifa && tarifas[i].pais == pais) {
            this.tarifa = tarifas[i];

            this.step2.proveedorTarifa = tarifas[i].id;
            this.step2.proveedorPago = frecTarifa;
          }
        }

        this.loadProvinciasAll();
      });
  }

  /**
   * Cambia la vista de tarifas entre mensual y anual
   * @param vista Vista a mostrar
   */
  cambiarVistaTarifas(vista: any) {
    this.scroll("tarifas");
    this.vistaTarifas = vista;
  }

  /**
   * Carga de los certificados de un usuario por si ha accedido anteriormente
   */
  loadCertificados() {
    this.certificadoService.getByUser(this.currentUser.id)
    .subscribe(data => {

      for(let doc of data) {
        doc.fecha_subidaStr = moment(doc.fecha_subida).format("DD/MM/YYYY HH:mm");
        if(doc.fecha_validez) doc.fecha_validezStr = moment(doc.fecha_validez).format("DD/MM/YYYY");
        this.certificados.push(doc);
      }
      var datos = [...this.certificados];
      this.certificados = [];
      this.certificados = datos;
      console.warn(this.certificados);
      this.cdr.detectChanges();
    });
  }
  
  /**
   * Inicializa el formulario de datos del usuario
   */
  initWiz1Form() {
		this.wiz1Form = this.fb.group({
      cif: [this.currentUser.cif, Validators.compose([Validators.required])],
      licencia: [''],
      domicilio: ['', Validators.compose([Validators.required])],
      poblacion: ['', Validators.compose([Validators.required])],
      cp: ['', Validators.compose([Validators.required])],
      telefono: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])],
      telefono2: ['', [Validators.pattern("[0-9]{9,11}")]],
      pais: [this.currentUser.pais.id, Validators.compose([Validators.required])],
      provincia: ['', Validators.compose([Validators.required])],
      empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
      actividad: [this.currentUser.actividad, Validators.compose([Validators.required])],
      paginaweb: [''],
      notif_email: [false],
      notif_telegram: [false],
		});

    //this.wiz1Form.controls["pais"].disable();
  }

  error = {notificaciones: false, cif: false, sms: false, smsCode: false, telegram: false, telegramCode: false};
  wizard = null;

  /**
   * Eventos de cambio de página en wizard. 
   * NO UTILIZADO
   */
	ngAfterViewInit(): void {
		// Initialize form wizard
		this.wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
			clickableSteps: false
		});

    const wizard = this.wizard;

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      console.log("STEP: " + wizard.getStep());
      if(wizard.getStep() == 1) {
        console.log(this.wiz1Form.value);
        this.submittedWiz1 = true;

        if(!this.wiz1Form.value.notif_email && !this.wiz1Form.value.notif_telegram) {
          this.error.notificaciones = true;
        } else {
          this.error.notificaciones = false;
          this.cdr.detectChanges();
        }

        if(this.wiz1Form.value.pais == 207 && !validateSpanishId(this.wiz1Form.value.cif)) {
          this.error.cif = true;
        } else {
          this.error.cif = false;
          this.cdr.detectChanges();
        }

        if (this.wiz1Form.invalid || this.error.notificaciones || this.error.cif) {
          console.log(this.wiz1Form);
          const controls = this.wiz1Form.controls;
          Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
          );
          this.cdr.detectChanges();
          wizard.stop();
          return;
        } else {
          var pais = this.paisesHash[this.wiz1Form.value.pais];
          this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono, lang: this.translate.currentLang})
            .pipe(first())
            .subscribe(
              data => {
                console.log(data);
              },
              error => {
                console.log(error);
            });
        }

        if(this.wiz1Form.value.actividad != 'Funerarias') {
          this.step2.ofertante = true;
          this.step2.proveedor = false;
        } else {
          this.step2.ofertante = false;
          this.step2.proveedor = true;
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 2){
        var valid = true;
        this.error.telegramCode = false;
        if(this.wiz1Form.value.notif_telegram) {
          this.error.telegram = true;
          valid = false;
        } else {
          this.error.telegram = false;
          this.cdr.detectChanges();
        }
        this.cdr.detectChanges();
        if (!valid) {
          wizard.stop();
        } else {
          this.loading = true;
          wizard.stop();
          this.cdr.detectChanges();
          this.userService.checkSMS({email: this.currentUser.email, code: this.stepMovil.sms})
            .pipe(first())
            .subscribe(
              data => {
                console.log(data);

                if(this.wiz1Form.value.notif_telegram) {
                  this.userService.checkTelegram({code: this.stepMovil.telegram})
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log(data);
                      this.loading = false;
                      wizard.start();
                      wizard.goTo(3);
                      wizard.goTo(3);
                      this.cdr.detectChanges();
                    },
                    error => {
                      this.loading = false;
                      this.error.telegramCode = true;
                      console.log(error);
                      this.errorTelegramSwal.fire();
                      this.cdr.detectChanges();
                  });
                  this.cdr.detectChanges();
                } else {
                  this.loading = false;
                  this.cdr.detectChanges();
                  wizard.goTo(3);
                  wizard.goTo(3);
                }
              },
              error => {
                this.loading = false;
                this.error.smsCode = true;
                console.log(error);
                this.errorSMSSwal.fire();
                this.cdr.detectChanges();
            });

        }
      } else if(wizard.getStep() == 3){
        //step2 = {ofertante: false, ofertanteConfirm: false, proveedor: false, proveedorTarifa: null, proveedorPago: null};
        //errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};
        if(!this.step2.ofertante && !this.step2.proveedor) {
          this.errors2.option = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.option = false;

        if(this.step2.ofertante && !this.step2.ofertanteConfirm) {
          this.errors2.ofertanteTos = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.ofertanteTos = false;

        if(this.step2.proveedor && this.step2.proveedorTarifa == null) {
          this.errors2.proveedorTarifa = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorTarifa = false;

        if(this.step2.proveedor && this.step2.proveedorPago == null) {
          this.errors2.proveedorPago = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorPago = false;

        /*for(var i = 0; i < this.tarifas.length; i++) {
          if(this.tarifas[i].id == this.step2.proveedorTarifa){
            this.tarifa = this.tarifas[i];
            console.log("TARIFA", this.tarifa);
            break;
          }
        }*/

        if(this.step2.proveedor == false) {
          //this.wiz3Oculto = true;
          wizard.goTo(4);
          wizard.goTo(4);
        } else {
          //this.wiz3Oculto = false;
          var valid = true;
          console.log(this.provinciasSel);
          for(var i = 0; i < this.provinciasSel.length; i++) {
            if(!this.provinciasSel[i].val) {
              valid = false;
              this.provinciasSel[i].error = true;
            } else {
              this.provinciasSel[i].error = false;
            }
          }
          for(var i = 0; i < this.paisesSel.length; i++) {
            if(!this.paisesSel[i].val) {
              valid = false;
              this.paisesSel[i].error = true;
            } else {
              this.paisesSel[i].error = false;
            }
          }
          this.cdr.detectChanges();
          if (!valid) {
            wizard.stop();
          } else {
            wizard.goTo(4);
            wizard.goTo(4);
          }
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 4) {
        //Validar IBAN si no es Funeraria
        if(this.wiz1Form.value.actividad != 'Funerarias') {
          if(!this.step3.titular) {
            this.errors3.titular = true;
            wizard.stop();this.cdr.detectChanges();
            return;
          } else this.errors3.titular = false;
          if(this.ibanForm.invalid) {
            wizard.stop();this.cdr.detectChanges();
            return;
          }
        }
      }
		});

		wizard.on('beforePrev', (wizardObj) => {
			// wizardObj.stop();
			/*if(wizard.getStep() == 4 && !this.step2.proveedor) {
				wizard.goTo(2);
			}*/
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
  }

  /**
   * Validación de los datos iniciales de la empresa
   * Cambio de página al validar.
   */
  validarEmpresa() {
    console.log(this.wiz1Form.value);
    this.submittedWiz1 = true;

    if(!this.wiz1Form.value.notif_email && !this.wiz1Form.value.notif_telegram) {
      this.error.notificaciones = true;
    } else {
      this.error.notificaciones = false;
      this.cdr.detectChanges();
    }

    if(this.wiz1Form.value.pais == 207 && !validateSpanishId(this.wiz1Form.value.cif)) {
      this.error.cif = true;
    } else {
      this.error.cif = false;
      this.cdr.detectChanges();
    }

    var validLoc = true;
    if(this.wiz1Form.value.actividad == 'Funerarias') {
      //this.wiz3Oculto = false;
      console.log(this.provinciasSel);
      for(var i = 0; i < this.provinciasSel.length; i++) {
        if(!this.provinciasSel[i].val) {
          validLoc = false;
          this.provinciasSel[i].error = true;
        } else {
          this.provinciasSel[i].error = false;
        }
      }
      for(var i = 0; i < this.paisesSel.length; i++) {
        if(!this.paisesSel[i].val) {
          validLoc = false;
          this.paisesSel[i].error = true;
        } else {
          this.paisesSel[i].error = false;
        }
      }
      this.cdr.detectChanges();
    }

    if (this.wiz1Form.invalid || this.error.notificaciones || this.error.cif || !validLoc) {
      console.log(this.wiz1Form);
      const controls = this.wiz1Form.controls;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.cdr.detectChanges();
      return;
    } else {
      var pais = this.paisesHash[this.wiz1Form.value.pais];
      this.mensajeReenviado = false;
      this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono, lang: this.translate.currentLang})
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
        });
        if(this.wiz1Form.value.notif_telegram) {
          this.userService.checkPhoneTelegram({telefono: this.wiz1Form.value.telefono, lang: this.translate.currentLang})
          .pipe(first())
          .subscribe(
            data => {
            },
            error => {
          });
        }
    }

    if(this.wiz1Form.value.actividad != 'Funerarias') {
      this.step2.ofertante = true;
      this.step2.proveedor = false;
    } else {
      this.step2.ofertante = false;
      this.step2.proveedor = true;
    }

    if(this.tarifaPar) {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    } else {
      this.wizard.goTo(3);
      this.wizard.goTo(3);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
    this.cdr.detectChanges();
  }

  /**
   * Selección de modo de plataforma para adecuar las tarifas.
   * @param modo Modo de plataforma a activar/desactivar
   */
  selectModo(modo: any) {
    console.warn(modo);
    if(modo == "WFN") {
      this.modo.wfn = !this.modo.wfn;
      //Si true activamos las tarifas con precio

    } else {
      this.modo.wfp = !this.modo.wfp;
      //Si true y wfn false quitamos los precios y mostramos solo los nombres

    }
    this.cdr.detectChanges();
  }

  errorTelegram = []; 
  errorTelegramCode = [];

  trackByFn(index: any, item: any) {
    return index;
  }

  /**
   * Añade un input de cuenta de telegram al formulario
   */
  addTelegram() {
    this.stepMovil.telegram.push("");
    this.errorTelegram = []; 
    this.errorTelegramCode = [];
    this.cdr.detectChanges();
  }

  /**
   * Elimina un input de cuenta de telegram del formulario
   */
  removeTelegram() {
    this.stepMovil.telegram.splice(this.stepMovil.telegram.length-1, 1);
  }

  /**
   * Valida que los códigos de telegram y el SMS sean correctos.
   * Cambio de página al validar.
   */
  validarValidacion(){
    var valid = true;
    this.error.telegramCode = false;
    if(this.wiz1Form.value.notif_telegram) {
      var errorTel = false;
      this.errorTelegram = [];
      for(let codigo of this.stepMovil.telegram) {
        if(codigo == "") {
          this.errorTelegram.push(true);
          errorTel = true;
        } else this.errorTelegram.push(false);
      }
      this.cdr.detectChanges();
      if(errorTel) valid = false;
    } else {
      this.error.telegram = false;
      this.cdr.detectChanges();
    }
    this.cdr.detectChanges();
    if (!valid) {
      return;
    } else {
      this.loading = true;
      this.cdr.detectChanges();
      this.userService.checkSMS({email: this.currentUser.email, code: this.stepMovil.sms})
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);

            var checkTelegram = function (index: any) {
              console.warn(index);
              this.userService.checkTelegram({code: this.stepMovil.telegram[index], lang: this.translate.currentLang})
              .pipe(first())
              .subscribe(
                data => {
                  this.errorTelegramCode.push(false);
                  if(index+1 == this.stepMovil.telegram.length) {
                    this.loading = false;
                    if(this.tarifaPar) {
                      this.wizard.goTo(3);
                      this.wizard.goTo(3);
                    } else {
                      this.wizard.goTo(4);
                      this.wizard.goTo(4);
                    }
                    
                    setTimeout(() => {
                      KTUtil.scrollTop();
                    }, 500);
                    this.cdr.detectChanges();
                  } else {
                    checkTelegram(index+1);
                  }
                },
                error => {
                  this.loading = false;
                  this.errorTelegramCode.push(true);
                  this.errorTelegramSwal.fire();
                  this.cdr.detectChanges();
              });
            }.bind(this);

            if(this.wiz1Form.value.notif_telegram) {
              this.errorTelegramCode = [];
              checkTelegram(0);
              this.cdr.detectChanges();
            } else {
              this.loading = false;
              this.cdr.detectChanges();
              if(this.tarifaPar) {
                this.wizard.goTo(3);
                this.wizard.goTo(3);
              } else {
                this.wizard.goTo(4);
                this.wizard.goTo(4);
              }
              setTimeout(() => {
                KTUtil.scrollTop();
              }, 500);
            }
          },
          error => {
            this.loading = false;
            this.error.smsCode = true;
            console.log(error);
            this.errorSMSSwal.fire();
            this.cdr.detectChanges();
        });

    }
  }

  mensajeReenviado = false;
  /**
   * Envia la orden al servidor de reenviar el SMS de validación
   */
  reenviarSMS() {
    var pais = this.paisesHash[this.wiz1Form.value.pais];
    this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono, reenvio: true, lang: this.translate.currentLang})
        .pipe(first())
        .subscribe(
          data => {
            this.mensajeReenviado = true;
            this.cdr.detectChanges();
          },
          error => {
            console.log(error);
        });
  }

  /**
   * Aseguradoras: Valida los datos de la cuenta bancaria.
   * Funerarias: Valida la información introducida de los certificados.
   * Cambio de página al validar.
   */
  validarCertPago(){
    if(this.wiz1Form.value.actividad != 'Funerarias') {
      if(!this.step3.titular) {
        this.errors3.titular = true;
        this.cdr.detectChanges();
        return;
      } else this.errors3.titular = false;
      if(this.ibanForm.invalid) {
        this.cdr.detectChanges();
        return;
      }
      if(!this.step3.facturacion) {
        this.errors3.facturacion = true;
        this.cdr.detectChanges();
        return;
      } else this.errors3.facturacion = false;
    } else {  //Funerarias - Validar certificados
      var error = false;
      for(let punt of this.puntuaciones) {
        if(punt.valor != null && (punt.valor < -100 || punt.valor > 100)) {
          punt.error = true;
          error = true;
        } else punt.error = false;
      }
      if(this.puntuacionOtro && this.puntuacionOtroValor != null && (this.puntuacionOtroValor < -100 || this.puntuacionOtroValor > 100)) {
        this.puntuacionOtroError = true;
        error = true;
      } else this.puntuacionOtroError = false;
  
      this.cdr.detectChanges();
      if(error) return;
    }

    if(this.tarifaPar) {
      this.wizard.goTo(4);
      this.wizard.goTo(4);
    } else {
      this.wizard.goTo(5);
      this.wizard.goTo(5);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Vuelve a la página de información personal.
   */
  volverValidacion(){
    if(this.tarifaPar) {
      this.wizard.goTo(1);
      this.wizard.goTo(1);
    } else {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Vuelve a la página de validación
   */
  volverCertPago(){
    if(this.tarifaPar) {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    } else {
      this.wizard.goTo(3);
      this.wizard.goTo(3);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Vuelve a la página de datos bancarios o certificados.
   */
  volverConfirmacion(){
    if(this.currentUser.pais.id == 142 && this.wiz1Form.value.actividad != 'Funerarias') {
      if(this.tarifaPar) {
        this.wizard.goTo(2);
        this.wizard.goTo(2);
      } else {
        this.wizard.goTo(3);
        this.wizard.goTo(3);
      }
    } else {
      if(this.tarifaPar) {
        this.wizard.goTo(3);
        this.wizard.goTo(3);
      } else {
        this.wizard.goTo(4);
        this.wizard.goTo(4);
      }
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Evento de cambio de tarifa para preparar la localización
   * @param tarifa Tarifa a la que se cambia.
   */
  onChangeTarifa(tarifa){
    console.log("onChangeTarifa()", tarifa);
    this.tarifa = tarifa;

    this.step2.proveedorTarifa = tarifa.id;
    this.step2.proveedorPago = this.vistaTarifas;
    /*this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.value.pais) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.wiz1Form.value.pais, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }*/

    this.wizard.goTo(2);
    this.wizard.goTo(2);

    if(this.currentUser.pais && this.currentUser.pais.id) {
      this.wiz1Form.get('pais').setValue(this.currentUser.pais.id);
      this.wiz1Form.get('provincia').setValue(null);
      this.onChangePais({target: {value: this.currentUser.pais.id}});
    } else if(this.currentUser.pais) {
      this.wiz1Form.get('pais').setValue(this.currentUser.pais);
      this.wiz1Form.get('provincia').setValue(null);
      this.onChangePais({target: {value: this.currentUser.pais}});
    }

    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  /**
   * Continúa a la segunda página de aseguradoras.
   */
  continuarAseguradora(){
    this.wizard.goTo(2);
    this.wizard.goTo(2);

    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }
  
  /**
   * Lanza el evento de cambios en el componente
   */
  modelChange() {
    this.cdr.detectChanges();
  }


	/**
	 * Guarda la información introducida en el formulario y lleva al usuario a la confirmación.
   */
	onSubmit() {
		if(this.loading) return;

		this.loading = true;
    this.cdr.detectChanges();

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.wiz1Form.value;
		
		form.id = currentUser.id;

    form.ofertante = this.step2.ofertante;
    form.proveedor = this.step2.proveedor;
    form.codigo_telegram = this.stepMovil.telegram;
    form.avisos = "inmediato";
    if(this.step3.iban) form.iban = this.step3.iban;
    if(this.step3.titular) form.iban_titular = this.step3.titular;
    if(this.step3.facturacion) form.facturacion_aseg = this.step3.facturacion;
    if(this.currentUser.pais.id == 142) form.divisa = 'MXN'
    else form.divisa = 'EUR';
    form.wfn = this.modo.wfn;
    form.wfp = this.modo.wfp;

    if(form.proveedor) {
      //form.certificados = this.certificados;
      form.puntuaciones = this.puntuaciones;
      if(this.puntuacionOtro && this.puntuacionOtroValor) {
        form.puntuaciones.push({codigo: this.puntuacionOtro, valor: this.puntuacionOtroValor})
      }
    }

		this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          currentUser.empresa = form.empresa;
          currentUser.actividad = form.actividad;
          currentUser.cif = form.cif;
          currentUser.cp = form.cp;
          currentUser.pais = form.pais;
          currentUser.domicilio = form.domicilio;
          currentUser.licencia = form.licencia;
          currentUser.ofertante = form.ofertante;
          currentUser.proveedor = form.proveedor;
          currentUser.poblacion = form.poblacion;
          currentUser.provincia = form.provincia;
          currentUser.status = form.status;
          currentUser.telefono2 = form.telefono2;
          currentUser.wfn = form.wfn;
          currentUser.wfp = form.wfp;

          if(form.proveedor) {
            var now = moment();
            var end = moment();
            var precio = null
            if(this.step2.proveedorPago == "anual") {
              end.add(1, 'years');
              precio = this.tarifasHash[this.step2.proveedorTarifa].precioAnual;
            } else if(this.step2.proveedorPago == "mensual") {
              end.add(1, 'months');
              precio = this.tarifasHash[this.step2.proveedorTarifa].precioMensual;
            }

            var provincias = [];
            var paises = [];

            for(var i = 0; i < this.provinciasSel.length; i++) {
              provincias.push(this.estadosHash[this.provinciasSel[i].val]);
            }
            for(var i = 0; i < this.paisesSel.length; i++) {
              paises.push(this.paisesHash[this.paisesSel[i].val]);
            }

            var alta = "";
            if(this.step2.ofertante) alta += "Ofertante";
            if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
            if(this.step2.proveedor) alta += "Proveedor";
            alta += " de traslados y servicios funerarios";

            var tarifa = "";
            var tarifaStripe = null;
            if(this.step2.proveedor && this.step2.proveedorTarifa) {
              tarifa += this.tarifasHash[this.step2.proveedorTarifa].nombre;
              if(this.step2.proveedorPago == 'mensual') {
                tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioMensual + " €/" + this.step2.proveedorPago + ")";
                tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeMensual;
              } else {
                tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioAnual + " €/" + this.step2.proveedorPago + ")";
                tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeAnual;
              }
            } else {
              tarifa = "-";
            }

            var fecha = end.format("DD/MM/YYYY");

            /*this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: tarifa, fecha: fecha, hash: currentUser.hashUser})
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK MAIL", data);
              },
              error => {
                console.error(error);
                this.loading = false;
              });*/

            
            if(this.wiz1Form.value.notif_telegram) {
              this.userService.confirmacionTelegram({code: this.stepMovil.telegram, lang: this.translate.currentLang})
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.log(error);
                });
            }
            
            // Crear Suscripción
            var susc = null;
            if(this.modo.wfn) {
              susc = {
                startDate: now.toDate(),
                facturacion: this.step2.proveedorPago,
                renovable: true,
                provincias: JSON.stringify(provincias),
                paises: JSON.stringify(paises),
                tarifa: this.step2.proveedorTarifa,
                precio: precio,
                user: this.authenticationService.currentUserValue.id,
                pagado: false,
                stripeProd: tarifaStripe
              };
            } else {
              susc = {
                startDate: now.toDate(),
                facturacion: null,
                renovable: true,
                provincias: JSON.stringify(provincias),
                paises: JSON.stringify(paises),
                tarifa: this.step2.proveedorTarifa,
                precio: null,
                user: this.authenticationService.currentUserValue.id,
                pagado: false,
                stripeProd: null
              };
            }
            
            console.log(susc);
            this.suscriptionService.post(susc)
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK", data);
                currentUser.tarifa = data;
                this.authenticationService.changeUser(currentUser);
                this.router.navigate(['/confirmacion']);
              },
              error => {
                console.error(error);
                this.loading = false;
              });
          } else {
            var alta = "";
            if(this.step2.ofertante) alta += "Ofertante";
            if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
            if(this.step2.proveedor) alta += "Proveedor";
            alta += " de traslados y servicios funerarios";
            /*this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: null, fecha: null, hash: currentUser.hashUser})
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK MAIL", data);
              },
              error => {
                console.error(error);
                this.loading = false;
              });*/

              
            if(this.wiz1Form.value.notif_telegram) {
              this.userService.confirmacionTelegram({code: this.stepMovil.telegram, lang: this.translate.currentLang})
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.log(error);
                });
            }
            this.authenticationService.changeUser(currentUser);
            this.router.navigate(['/confirmacion']);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
        });
	}

	/**
	 * Valida la información de los formularios.
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorWiz1(controlName: string, validationType: string): boolean {
		const control = this.wiz1Form.controls[controlName];
		if (!control || !this.submittedWiz1) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  
  //--------------- STEP VALIDACION MOVIL ---------------

  stepMovil = {sms: null, telegram: [""]};

  //--------------- STEP 2 ---------------

  step2 = {ofertante: false, ofertanteConfirm: true, proveedor: false, proveedorTarifa: null, proveedorPago: null};

  errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};

  tarifa: any;

  //--------------- STEP 2b ---------------

  provinciasSel = [];
  paisesSel = [];

  //--------------- STEP 3 ----------------

  ibanForm = null;
  step3 = {titular: null, iban: null, facturacion: null};
  errors3 = {titular: false, iban: false, ibanFormat: false, facturacion: false};

  puntuaciones = [
    {texto_es: "Introduce la puntuación % NPS certificada", texto_en: "Enter the certified NPS % score", codigo: "% NPS", valor: null, error: false},
    {texto_es: "Introduce la puntuación NPS sectorial de tu país", texto_en: "Enter your country's sectorial NPS score", codigo: "NPS", valor: null, error: false}
  ]
  puntuacionOtro = null;
  puntuacionOtroValor = null;
  puntuacionOtroError = false;

  certificados = [];

  filesCert: File[] = [];
  newcert = {nombre: null, validez: null};
  errorCert = {nombre: false}
  loadingCert = false;

  msg_cert = {
    'emptyMessage': 'No hay certificaciones',
    'totalMessage': 'total'
  };

  // --------- CERTIFICACIONES ---------

  /**
   * Evento de añadir un nuevo documento al formulario.
   * Borra el array e inserta el documento.
   * @param event Información del evento
   */
  onSelect(event) {
    console.log(event);
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.filesCert = [];
    this.filesCert.push(...event.addedFiles);

    console.log(event.addedFiles[0]);
  }

  /**
   * Elimina el documento del array pendiente de subir.
   * @param event Información del evento
   */
  onRemove(event) {
    console.log(event);
    this.filesCert.splice(this.filesCert.indexOf(event), 1);
  }

  /**
   * Sube el certificado a la base de datos.
   */
  subirCertificado() {
    if(this.loadingCert) return;
    if(this.filesCert.length == 0) {
      this.errorNotFileSwal.fire();
      return;
    }
    if(!this.newcert.nombre) {
      this.errorCert.nombre = true;
      this.cdr.detectChanges();
      return;
    } else {
      this.errorCert.nombre = false;
      this.cdr.detectChanges();
    }
    this.loadingCert = true;
    this.cdr.detectChanges();
    var doc = this.filesCert[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = function (e:any) {
      console.log(myReader);

      var newDoc = {contenido: myReader.result, nombre: this.newcert.nombre, name: doc.name, fecha_subida: new Date(), fecha_validez: this.newcert.validez, estado: "REVISION", user: this.currentUser.id};

      this.certificadoService.post(newDoc)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);

            data.fecha_subidaStr = moment(data.fecha_subida).format("DD/MM/YYYY HH:mm");
            if(data.fecha_validez) data.fecha_validezStr = moment(data.fecha_validez).format("DD/MM/YYYY");
            this.certificados = [...this.certificados, data];
            this.loadingCert = false;
            this.confirmationFileSwal.fire();
            this.filesDocs = [];
            this.filesCert = [];
            this.newcert = {nombre: null, validez: null};
            this.errorCert = {nombre: false}
            this.cdr.detectChanges();
          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }.bind(this)
    myReader.readAsDataURL(doc);
  }

  loadingBorrar = false;
  docBorrar = null;

  /**
   * Confirmación de borrado de certificado
   * @param doc Certificado a borrar
   */
  borrarCert(doc: any) {
    this.docBorrar = doc;
    this.questionDeleteFileSwal.fire();
  }

  /**
   * Proceso de borrado de certificado.
   */
  borrarCert1() {
    this.docBorrar.loadingBorrar = true;
    this.cdr.detectChanges();
    this.certificadoService.delete(this.docBorrar.id)
    .subscribe(data => {
      console.log("OK", data);
      for(var i = 0; i < this.certificados.length; i++) {
        if(this.certificados[i].id == this.docBorrar.id){
          this.certificados.splice(i, 1);
          var datos = [...this.certificados];
          this.certificados = [];
          this.certificados = datos;
          this.cdr.detectChanges();
          break;
        }
      }
      this.confirmationDeleteFileSwal.fire();
      this.loadingBorrar = false;
      this.cdr.detectChanges();
    });
  }




}
