// Angular
import { Injectable } from '@angular/core';
// Tranlsation
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, UserService } from '../../../../_services';
import { first } from 'rxjs/operators';

export interface Locale {
	lang: string;
	// tslint:disable-next-line:ban-types
	data: Object;
}

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	// Private properties
	private langIds: any = [];

	/**
	 * Service Constructor
	 *
	 * @param translate: TranslateService
	 */
	constructor(private translate: TranslateService,
		private authenticationService: AuthenticationService,
    	private userService: UserService) {
		// add new langIds to the list
		this.translate.addLangs(['es']);

		// this language will be used as a fallback when a translation isn't found in the current language
		this.translate.setDefaultLang('es');
	}

	/**
	 * Load Translation
	 *
	 * @param args: Locale[]
	 */
	loadTranslations(...args: Locale[]): void {
		const locales = [...args];

		locales.forEach(locale => {
			// use setTranslation() with the third argument set to true
			// to append translations instead of replacing them
			this.translate.setTranslation(locale.lang, locale.data, true);

			this.langIds.push(locale.lang);
		});

		// add new languages to the list
		this.translate.addLangs(this.langIds);
	}

	/**
	 * Setup language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		if (lang) {
			this.translate.use(this.translate.getDefaultLang());
			this.translate.use(lang);
			localStorage.setItem('language', lang);

			var currentUser = this.authenticationService.currentUserValue;

			if(currentUser) {
				var modif = {id: currentUser.id, lang: lang};
				this.userService.update(modif)
					.pipe(first())
					.subscribe(
					data => {
						
					},
					error => {
						console.error(error);
					});
			}
		}
	}

	/**
	 * Returns selected language
	 */
	getSelectedLanguage(): any {
		return localStorage.getItem('language') || this.translate.getDefaultLang();
	}
}
