import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de centros WFP
 */
@Injectable()
export class CentroService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro`);
    }

    getAllActivos() {
      return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?borrado=0`).toPromise();
    }
 
    getByCP(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?where={"borrado":0,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`);
    }
 
    getByCPAsyncFuneraria(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?where={"borrado":0,"funeraria":1,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getByCPAsyncTanatorio(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?where={"borrado":0,"funeraria":0,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getByCPGrupo(cpDesde: any, cpHasta: any, grupo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?where={"borrado":0,"grupo":"${grupo}","cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getB2BByCP(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_centro?where={"activo":1,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`);
    }
 
    getB2BByCPFunerariaAsync(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_centro?where={"activo":1,"funeraria":1,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getB2BByCPTanatorioAsync(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_centro?where={"activo":1,"funeraria":0,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getB2BByCPGrupo(cpDesde: any, cpHasta: any, grupo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/b2b_centro?where={"activo":1,"grupo":"${grupo}","cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`).toPromise();
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/wfp_centro/${id}`);
    }
    
    /**
     * Realiza una petición `POST` para guardar un centro BOREA
     * @param fune Contenido del centro
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(centro: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/wfp_centro`, centro, httpOptions).toPromise();
    }
    
    /**
     * Realiza una petición `POST` para guardar un centro B2B
     * @param fune Contenido del centro
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postB2B(centro: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/b2b_centro`, centro, httpOptions).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un centro BOREA
     * @param cert Contenido del centro a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(centro: any) {
        return this.http.patch<any>(`${environment.apiUrl}/wfp_centro/${centro.id}`, centro).toPromise();
    }

    /**
     * Realiza una petición `PATCH` para actualizar un centro B2B
     * @param cert Contenido del centro a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updateB2B(centro: any) {
        return this.http.patch<any>(`${environment.apiUrl}/b2b_centro/${centro.id}`, centro).toPromise();
    }

    /**
     * Realiza una petición `DELETE` para borrar un centro B2B
     * @param id Identificador del centro a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteB2B(id: any) {
        return this.http.delete(`${environment.apiUrl}/b2b_centro/${id}`).toPromise();
    }
}