import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { LocalizacionService, AuthenticationService, StateService, LicitaService, UserService, NotificacionService, CertificadoService, PrecioService, SolicitudService, CentroService, B2bService, PaymentService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

import { TranslationService } from '../../../core/_base/layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxHtmlEditorComponent } from 'devextreme-angular';

// Declare ga function as ambient
declare var ga:Function;
declare var gtag:Function;



declare function initDropdown(): any;
/**
 * Componente que contiene el proceso de lanzar un pago respecto a un presupuesto ya creado.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-admin-pago-nuevo',
  templateUrl: './admin-pago-nuevo.component.html',
  styleUrls: ['./admin-pago-nuevo.component.scss']
})
export class AdminPagoNuevoComponent implements OnInit {

  presupuesto = null;
  currentUser: any;
  notifEmail = false;
  notifSMS = false;
  codigoPresupuesto = null;

  loading = false;
  loadingPresupuesto = false;
  loadingVerPres = false;
  pago = { tarjeta: true, paypal: false, klarna: false, sepa: false }
  

  /** Variables globales */
  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('presupuestoSwal', {static: false}) private presupuestoSwal: SwalComponent;
  @ViewChild('pagoSwal', {static: false}) private pagoSwal: SwalComponent;
  @ViewChild('solicitudSwal', {static: false}) private solicitudSwal: SwalComponent;
  @ViewChild('emailSwal', {static: false}) private emailSwal: SwalComponent;


  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private solicitudService: SolicitudService,
    private paymentService: PaymentService,
    private locationSt: LocationStrategy,
		private route: ActivatedRoute,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      history.pushState(null, null, window.location.href);

      // check if back or forward button is pressed.
      this.locationSt.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
	  }

  /**
   * Método que se lanza en el inicio del componente y carga los formularios y los maestros necesarios.
   * Comprueba si hay login y si hay licitación abierta.
   */
	ngOnInit() {
    console.warn("<<<<<<<<INIT>>>>>>>>");

    this.route.paramMap.subscribe(params => {
      if(params.get("presupuesto")) {
        this.codigoPresupuesto = params.get("presupuesto");
        this.cdr.detectChanges();
        this.cdr.detectChanges();
        this.validarPresupuesto();
      }

    });
  }

  ngOnDestroy() {
    console.log("DESTROY");
    this.cdr.detach(); // do this
  }

  async cambioPresupuesto() {
    this.presupuesto = null;
    this.cdr.detectChanges();
  }

  async validarPresupuesto() {
    if(this.loadingPresupuesto) return;

    this.loadingPresupuesto = true;
    this.cdr.detectChanges();

    let solRes = await this.solicitudService.getByPresupuesto(this.codigoPresupuesto);
    console.warn(solRes);

    if(solRes.length == 0) {
      this.solicitudSwal.fire();
      this.loadingPresupuesto = false;
      this.cdr.detectChanges();
      return;
    }

    this.presupuesto = solRes[0];
    if(this.presupuesto.pago && this.presupuesto.pagoEstado == "CANCELADO") {
      this.presupuesto.pago = false;
      this.presupuesto.pagoUrl = null;
    }
    this.loadingPresupuesto = false;
    this.cdr.detectChanges();
    this.cdr.detectChanges();

    if(this.presupuesto.pago) this.prepararEmail();



    //if(){ tarjeta: false, paypal: false, klarna: false, sepa: false }


    
  }


  async verPresupuesto() {
    if(this.loadingVerPres) return;
    this.loadingVerPres = true;
    this.cdr.detectChanges();
    let pres = await this.solicitudService.getFicheroPresupuesto(this.presupuesto.presupuesto);

    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(pres.file) + "'></iframe>"
    )
    this.loadingVerPres = false;
    this.cdr.detectChanges();
  }

  async nuevoPago() {
    if(!this.presupuesto) {
      this.presupuestoSwal.fire();
      return;
    }

    if(!this.pago.tarjeta && !this.pago.paypal && !this.pago.klarna && !this.pago.sepa) {
      this.pagoSwal.fire();
      return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    let formasPago = [];//tarjeta: true, paypal: false, klarna: false, sepa: false
    if(this.pago.tarjeta) formasPago.push("card");
    if(this.pago.paypal) formasPago.push("paypal");
    if(this.pago.klarna) formasPago.push("klarna");
    if(this.pago.sepa) formasPago.push("sepa_debit");

    this.paymentService.createPayment(this.presupuesto, formasPago).then(async function(data) {
      console.warn(data);

      this.presupuesto.pago = true;
      this.presupuesto.pagoEstado = "PENDIENTE";
      this.presupuesto.pagoStripe = data.paymentId;
      this.presupuesto.pagoUrl = data.url.replace("https://buy.stripe.com", "https://pago.borea.es");

      let solObj = {
        id: this.presupuesto.id,
        pago: this.presupuesto.pago,
        pagoEstado: this.presupuesto.pagoEstado,
        pagoStripe: this.presupuesto.pagoStripe,
        pagoUrl: this.presupuesto.pagoUrl,
        pagoFechaCreacion: new Date()
      }
      await this.solicitudService.updateSolicitud(solObj);

      this.loading = false;
      this.cdr.detectChanges();

      this.prepararEmail();
    }.bind(this));
  }

  selEmail() {
    this.notifEmail = !this.notifEmail;
    this.cdr.detectChanges();
  }

  selSMS() {
    this.notifSMS = !this.notifSMS;
    this.cdr.detectChanges();
  }




  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
   thousands_separators(num)
   {
     if(num){ 
       var num_parts = num.toFixed(2).split(".");
       num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
       if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
       else return num_parts[0];
       
     } else {
       return '0';
     }
     
   }

  emailContent = "";

  prepararEmail () {
    var hora = moment().hour();
    var saludo = "";

    if(hora >= 6 && hora <= 12) saludo = "Buenos días"
    else if(hora >= 13 && hora <= 20) saludo = "Buenas tardes"
    else saludo = "Buenas noches";

    this.emailContent = saludo + ", D/Dña " + this.presupuesto.nombre + ",<br>" + 
    "Muchas gracias por su contacto con BOREA.<br>" + 
    "Le enviamos el enlace para completar el pago de la despedida que ha escogido. Además, le enviamos adjunto el presupuesto de nuevo para que pueda revisarlo en detalle. Si tiene cualquier problema, nuestro número de teléfono es 683 67 39 57.<br>" +
    "<b><a href='" + this.presupuesto.pagoUrl + "?prefilled_email=" + this.presupuesto.email + "'>Completar pago - Presupuesto " + this.presupuesto.presupuesto + "</a></b><br>";
    
    this.emailContent += "Atentamente,<br>" +
    "[TU NOMBRE] de BOREA";

    this.cdr.detectChanges();
    this.cdr.detectChanges();
  }

  // Envio EMAIL
  loadingEmail = false;
  emailEnviado = false;
  textHTML = "";

  async sendEmail() {
    if(this.textHTML.includes("[TU NOMBRE]")) {
      this.emailSwal.fire();
      return;
    }

    this.loadingEmail = true;
    this.cdr.detectChanges();

    let adjuntos = [];
    adjuntos.push({
      nombre: this.presupuesto.presupuesto,
      codigo: this.presupuesto.presupuesto,
      id: this.presupuesto.id
    })

    let mail = {
      mensaje: this.textHTML,
      email: this.presupuesto.email,
      adjuntos: adjuntos,
      tipo: this.presupuesto.tipoServ
    }

    await this.solicitudService.envioPagoEmail(mail);

    this.loadingEmail = false;
    this.emailEnviado = true;
    this.cdr.detectChanges();
  }

  htmlChanged(event) {
    this.textHTML = event.value;
  }

  // Envio SMS
  loadingSMS = false;
  smsEnviado = false;

  async sendSMS() {
    if(this.loadingSMS) return;
    this.loadingSMS = true;
    this.cdr.detectChanges();
    let texto = "Completa el pago de la despedida escogida (Pres. " + this.presupuesto.presupuesto + ") pulsando en el siguiente enlace: " + this.presupuesto.pagoUrl;

    let sms = {
      mensaje: texto,
      telefono: this.presupuesto.telefono,
      tipo: this.presupuesto.tipoServ
    }

    await this.solicitudService.envioPagoSMS(sms);

    this.smsEnviado = true;
    this.loadingSMS = false;
    this.cdr.detectChanges();
    
  }
  

}
