import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de notificaciones.
 * @author Informática Integral Vasca
 */
@Injectable()
export class NotificacionService {
    constructor(private http: HttpClient) { }


    /**
     * Realiza una petición `POST` para enviar una petición de ayuda
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    sendSolicitud(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/nueva_solicitud`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para enviar una petición de ayuda
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    sendAyuda(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/ayuda`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la creación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    nueva_licitacion(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/nueva_licitacion`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la validación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    licitacion_validada(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/licitacion_validada`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la no-adjudicación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    no_adjudicados(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/notificacion/no_adjudicados`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar  un nuevo ticket
     * @param info Contenido del ticket
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
     nuevo_ticket(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/notificacion/nuevo_ticket`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar un nuevo mensaje
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
     nuevo_mensaje(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/notificacion/nuevo_mensaje`, info, httpOptions);
    }
}