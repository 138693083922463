import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LicitaService } from '../../../_services';
import moment from 'moment';
import { interval } from 'rxjs';

@Component({
  selector: 'kt-admin-estatisticas',
  templateUrl: './admin-estatisticas.component.html',
  styleUrls: ['./admin-estatisticas.component.scss']
})
export class AdminEstatisticasComponent implements OnInit {

  constructor(private licitaService: LicitaService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.cargarRegistro();
    this.interval = setInterval(() => { 
      this.cargarRegistro(); 
    }, 30000);
  }

  ngOnDestroy(){
    clearInterval(this.interval);
    //if(this.interval) this.interval.unsubscribe();
  }

  showXAxis = true;
  showYAxis = true;
  gradient2 = false;
  showLegend2 = true;
  showXAxisLabel = true;
  xAxisLabel = 'Items A';
  showYAxisLabel = true;
  rotateXAxisTicks = true;
  yAxisLabel = 'Items B';
  timeline: boolean = true;

  estadisticas = {
    licitacionesActivasWFN: null,
    licitacionesActivasWFP: null,
    licitacionesHoras:null,
    pujasHoras: null,
    listaPujas: [],
    listaLicitaciones: [],
    listaConexiones: [],
    arrayHoras: [],
    
  };
  interval: any;

  colorScheme2 = {
    domain: ['#007A53']
  };

  tagsGraph: any[] = [];
  tagsGraph2: any[] = [];
  tagsGraph3: any[] = [];
  valuesGraph: any[] = [];
  valuesGraph2: any[] = [];

  cargarRegistro() {
    //0
    this.licitaService.getArrayHoras()
    .subscribe(arrayHoras =>{
      for(let i=0; i<16;i++){
        this.estadisticas.arrayHoras[i]=arrayHoras.arrayHoras[i];
      }
      this.cdr.detectChanges();
    });
    //1
    this.licitaService.getPujasGrafico()
      .subscribe(pujasGrafico => {
        for(let i=0;i<16;i++){
          this.estadisticas.listaPujas [i] = pujasGrafico.listaCuentas[i];
        }
        this.cdr.detectChanges();
        //1.1
        this.licitaService.getLicitacionesGrafico()
        .subscribe(licitacionesGrafico => {
          for(let i=0;i<16;i++){
            this.estadisticas.listaLicitaciones[i] = licitacionesGrafico.listaCuentas[i];
          }
          this.cdr.detectChanges();  
            //1.1.1 
          this.licitaService.getConexionesGrafico()
            .subscribe(conexiones => {
              for(let i=0;i<16;i++){
                this.estadisticas.listaConexiones[i] = conexiones.listaConexiones[i];
              }
              this.cdr.detectChanges();
              //1.1.1.1
              this.licitaService.getLicitacionesActivasWFP()
                .subscribe(licitacionesActivasWFP => {
                  this.estadisticas.licitacionesActivasWFP = licitacionesActivasWFP.licitacionesActivasWFPCount;
                  this.cdr.detectChanges();
                  
              });
              //1.1.1.2
              this.licitaService.getLicitacionesHoras()
                .subscribe(licitacionesHoras => {
                  this.estadisticas.licitacionesHoras = licitacionesHoras.licitacionesHora;
                  this.cdr.detectChanges();
              });
              //1.1.1.3
              this.licitaService.getPujasHoras()
                .subscribe(pujasHoras => {
                  this.estadisticas.pujasHoras = pujasHoras.pujasHora;
                  this.cdr.detectChanges();
              });
              //1.1.1.4
              this.licitaService.getLicitacionesActivas()
                .subscribe(licitacionesActivas => {
                  this.estadisticas.licitacionesActivasWFN = licitacionesActivas.licitacionesActivasCount;
                  this.cdr.detectChanges();
              });
              this.esperar();
            });
          });
      }); 
  }
  esperar(){
      var columnas = "";
      var enc = false;
      
      this.tagsGraph = [];
      this.tagsGraph3 = [];
      
      this.tagsGraph.push({
        value: "LICITACIONES",
        name: "LICITACIONES"
      });
      
      this.tagsGraph.push({
        value: "PUJAS",
        name: "PUJAS"
      });

      this.setGraphValues();
      
      this.tagsGraph3.push({
        value: "CONEXIONES",
        name: "CONEXIONES"
      });
      this.setGraph2Values();
  }

  setGraphValues(){
    for(let i = 0;i<16;i++){
      var fechaac = moment(this.estadisticas.arrayHoras[i]).format('HH:mm');
      this.valuesGraph[i] = {
        fecha: fechaac,
        "LICITACIONES": this.estadisticas.listaLicitaciones[i],
        "PUJAS": this.estadisticas.listaPujas[i]
      }
    }
  }
  setGraph2Values(){
    for(let i = 0;i<16;i++){
      var fechaac = moment(this.estadisticas.arrayHoras[i]).format('HH:mm');
      this.valuesGraph2[i] ={
        fecha: fechaac,
        "CONEXIONES": this.estadisticas.listaConexiones[i],
      }
    }
  }
  
  customizeTooltip(arg: any) {
    var items = arg.valueText.split("\n"),
      color = arg.point.getColor();
    items.forEach(function(item, index) {
      if(item.indexOf(arg.seriesName) === 0) {
        var element = document.createElement("span");

        element.textContent = item;
        element.style.color = color;
        element.className = "active";

        items[index] = element.outerHTML;
      }
    });
    return { text: "<b>" + arg.argumentText + "</b><br/>" + items.join("\n") };
  }

}
