import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../../_services';

@Component({
  selector: 'kt-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss']
})
export class AdminUsuariosComponent implements OnInit {

  currentUser: any;

  rows = [];
  originalRows = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadUsuarios();
  }



  loadUsuarios() {
    this.userService.getAll()
	    .subscribe(users => {
        
        this.originalRows = users;
        this.filterDatatable();
        this.cdr.detectChanges();
      });
  }

  filterDatatable(){
    /*GlobalVariables.filters[this.router.url].filters = this.filters;
    GlobalVariables.listadoPagina = "empleado";
    GlobalVariables.listado = this.originalRows;*/
    // assign filtered matches to the active datatable
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;

      if(item.status == "INVITADO") mostrar = false;
      
      /*if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase()) && !item.username.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.estado != "TODOS") {
        if(this.filters.estado == "ACTIVO" && !item.isActive) mostrar = false
        else if(this.filters.estado == "INACTIVO" && item.isActive) mostrar = false;
      }

      if(this.filters.rol != "TODOS" && item.role != this.filters.rol) mostrar = false;*/

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
  }

}
