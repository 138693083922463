// Aleman
export const locale = {
	lang: 'de',
	data: {
		MENU: {
			SEGUIMIENTO: 'Überwachung <br> meiner Angebote', 
            SEGUIMIENTO2: 'Überwachung meiner Angebote',
            EJECUCION: 'In <br> Ausführung',
            EJECUCION2: 'in Aktion',
            TODAS: 'Siehe alle <br> die Gebote',
            TODAS2: 'Siehe alle Gebote',
            CREAR: 'Erstellen <br> tend',
            CREAR2: 'Ausschreibung erstellen', 
            CREAR_NAC: 'Nationaler Dienst / Transfer',
            CREAR_INT: "Internationaler Transfer",
            CREAR_SERV: 'Create <br> service',
            CREAR2_SERV: 'Service erstellen',
            EXPLORAR: 'Erforschen Sie meine <br> Angebote', 
            EXPLORAR2: 'Erforsche meine Angebote',
            EXPLORAR_SERV: 'Erforschen Sie meine <br> Dienstleistungen',
            EXPLORAR2_SERV: 'Meine Dienste erkunden',
            PENDIENTES: 'Ausstehend <br> zu vergeben',
            PENDIENTES2: 'Ausstehend für die Auszeichnung',
            TODAS_LIC: 'Sehen Sie alle <br> Angebote', 
            TODAS_LIC2: 'Sehen Sie alle Ausschreibungen',
            CONTRATAR: 'MIETEN',
            PERFIL: 'Mein Profil',
            LOGOUT: 'Abmelden',
            MODO_WFN: 'Wfn <br> tenders', 
            MODO_WFN2: 'WFN -Tender',
            MODO_INT: 'Wfn <br> Internationale Transfers',
            MODO_INT2: 'WFN International Transfers',
            MODO_WFP: 'WFP <br> Individuen',
            MODO_WFP2: 'WFP speziell',
            MODO_PLANS: "WFP <br> Bestattungspläne",
            MODO_PLANS2: "WFP -Bestattungspläne",
            MODO_PETS: 'WFP <br> Haustiere',
            MODO_PETS2: "WFP Haustiere",

		},
		FOOTER: {
			CONTACTA: 'Kontaktiere uns',
			SEDES: 'Hauptquartier in: Madrid, Mexiko, Lissabon und Colonia. ',
			AVISO_LEGAL: 'Rechtswarnung ',
			POLITICA: 'Datenschutzrichtlinie'
		},
		HOME: {
			BIENVENIDA: 'Willkommen bei den WFN -Tendern',
			BIENVENIDA2: 'Willkommen im Bereich der WFP Tenders ',
			BIENVENIDA3: 'Willkommen im Bereich der BOREA -Anwendungen ',
			INVITADO1: 'Sie greifen im Gastmodus auf unsere Plattform zu. ',
			INVITADO2: 'Dies bedeutet, dass Sie die laufenden Ausschreibungen kennen, aber nicht für sie bieten oder neue veröffentlichen können. ',
			DEMO: 'Sie greifen auf unsere Demonstrationsplattform zu. Dies bedeutet, dass Sie alle Funktionen mit fiktiven Beispielen für Angebote ausprobieren können. ',
			CONTRATAR: 'Mieten Sie jetzt ',
			PAGO: 'Ausstehende Abonnementzahlung. ',
			PAGO2: 'Sie haben uns Ihre Abonnement -Zahlungsdaten noch nicht zur Verfügung gestellt. Drücken Sie die Taste, um auf die sichere Zahlungsplattform zuzugreifen. ',
			PLATAFORMA_PAGO: 'Sichere Zahlungsplattform '
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Anmeldung',
				BUTTON: 'Anmeldung',
				BIENVENIDA1: 'Willkommen zum Einstellungsprozess Ihres WFN -Ratens {{{tarifa}} ',
				BIENVENIDA1_SUB: 'Melden Sie sich an, um Ihr Konto zu diesem Satz zu verbinden. ',
				BIENVENIDA2: 'Willkommen im WFN -Einstellungsprozess ',
				BIENVENIDA2_SUB: 'Melden Sie sich an, um fortzufahren. ',
				FORGOT_BUTTON: 'Haben Sie Ihr Passwort vergessen?',
				REGISTER: 'Sie haben kein Konto? ',
				REGISTER_BUTTON: 'Melden Sie sich an',
				VOLVER: 'Kehren Sie zum Login zurück.'
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Passwort',
				CONFIRM_PASSWORD: 'Bestätige das Passwort'
			},
			FORGOT: {
				TITLE: 'Passwort wiederherstellen',
				DESC: 'Geben Sie dann den Code der empfangenen E -Mail und das neue Passwort ein.',
				SUCCESS: 'Ihr Passwort wurde korrekt wiederhergestellt. Melden Sie sich mit Ihren neuen Anmeldeinformationen an, um fortzufahren. ',
				INCORRECTO: 'Der eingeführte Wiederherstellungscode ist nicht korrekt. ',
				INVALID: 'Dieser Wiederherstellungsverbindung ist nicht gültig oder wurde bereits verwendet. ',
				LOGIN: 'ANMELDUNG',
				CODIGO: 'Wiederherstellungscode (4 Abbildungen) ',
				BUTTON: 'PASSWORT WIEDERHERSTELLEN',
				EMAIL: 'Eine Wiederherstellungs -Mail wurde an die E -Mail gesendet, die eingeführt wird, wenn sie im System registriert ist. Befolgen Sie seine Anweisungen, um fortzufahren. ',
			},
			REGISTER: {
				TITLE: 'Melden Sie sich an',
				BIENVENIDA1: 'Willkommen zum Einstellungsprozess Ihres WFN -Ratens {{{rate}} ',
				BIENVENIDA1_SUB: 'Zunächst erstellen wir ein Konto, das mit Ihrem Preis verbunden ist. ',
				BIENVENIDA2: 'Willkommen im WFN -Einstellungsprozess ',
				BIENVENIDA2_SUB: 'Zunächst werden wir ein Konto erstellen. ',
				EMAIL_CORP: 'Nur Unternehmens -E -Mail (z.B @your-company.com) sind akzeptiert',
				PAIS: 'Land',
				PAIS_SELECT: 'Land auswählen ... ',
				PERTENEZCO: 'Ich gehöre zu',
				PERTENEZCO_SELECT: 'Sektor auswählen ... ',
				SECTOR1: 'Beerdigungen ',
				SECTOR2: 'Versicherungsgesellschaften',
				SECTOR3: 'Rentenfonds',
				SECTOR4: 'Sicheres Bankgeschäft ',
				SECTOR5: 'Vorhersage- und Vorschriftenunternehmen ',
				SECTOR6: 'Botschaften ',
				SECTOR7: 'Gruppen mit Bestattungsabdeckung ',
				SECTOR8: 'Andere',
				BUTTON: 'Registrieren ',
				LOGIN: 'Haben Sie bereits ein Konto?',
				LOGIN_BUTTON: 'Anmeldung',
				SUCCESS: 'Korrekter Datensatz ',
				SUCCESS_SUB: 'Wir begrüßen Sie im World Funeral Net! ',
				SUCCESS_MAIL: 'Wir haben eine Bestätigungs -E -Mail an die Registrierungsadresse gesendet. Bitte geben Sie den Code ein oder klicken Sie auf den in der E -Mail enthaltenen Link, um auf alle Plattformfunktionen zuzugreifen. ',
				SUCCESS_MAIL2: 'Wir haben eine Bestätigungs -E -Mail an die Registrierungsadresse gesendet. Bitte geben Sie den Code ein oder klicken Sie auf den in der E -Mail enthaltenen Link, um Ihren Preis zu mieten. ',
				ERROR_CODE: 'Der eingeführte Code ist nicht korrekt. ',
				VALIDAR_BUTTON: 'Konto validieren ',
				GRACIAS: 'Vielen Dank für Ihr Vertrauen. '
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: 'Validierung Ihres Kontos, einen Moment bitte ... ',
				SUCCESS: 'Validiertes Konto ',
				SUCCESS_DESC: 'Ihr Konto wurde korrekt validiert. Wir werden den Rekord in ein paar Sekunden fortsetzen ... ',
				ERROR: 'Validierungsfehler ',
				ERROR_DESC: 'Ihr Validierungslink ist nicht korrekt. Überprüfen Sie Ihre E -Mails und verwenden Sie den letzten generierten Link. '
			},
			VALIDATION: {
				INVALID: '{{name}} ist nicht gültig ',
				REQUIRED: '{{name}} muss eingeführt werden ',
				MIN_LENGTH: '{{name}} Mindestlänge ist {{min}} ',
				AGREEMENT_REQUIRED: 'Es ist notwendig, die Allgemeinen Geschäftsbedingungen zu akzeptieren ',
				NOT_FOUND: 'Das geknackte {{Name}} ist nicht gefunden ',
				INVALID_LOGIN: 'Die eingegebenen Daten sind nicht korrekt. Versuchen Sie es erneut.',
				INACTIVE: 'Ihr Konto ist inaktiv. Überprüfen Sie Ihre E -Mails und klicken Sie auf den Validierungslink. ',
				REQUIRED_FIELD: 'Sie müssen dieses Feld ausfüllen ',
				MIN_LENGTH_FIELD: 'Die Mindestgröße dieses Feldes ist:',
				MAX_LENGTH_FIELD: 'Die maximale Größe dieses Feldes ist:',
				INVALID_FIELD: 'Dieses Feld ist nicht gültig ',
				MATCH: 'Das Passwort und die Bestätigung stimmen nicht überein ',
				INVALID_REGISTER: 'Die eingeführte E -Mail ist bereits registriert. Versuchen Sie es erneut oder melden Sie sich an. '
			}
		},
		DEMO: {
			LOADING: 'Vorbereitung der Plattform, einen Moment bitte ... ',
			BIENVENIDA: 'Wir begrüßen Wfn ',
			SECTOR: 'Wählen Sie den Sektor aus, zu dem Sie auf die Demonstrationsplattform zugreifen können. ',
			FUNERARIA: 'Ich bin Bestattungsunternehmen ',
			ASEGURADORA: 'Ich bin Versicherung, Gesellschaft oder Kollektiv ',
			HOME: {
				STEP1: 'Willkommen bei WFN! Dies ist die Demonstrationsplattform. Wir werden die Funktionen der Plattform entdecken und dann können Sie damit interagieren und die Tests durchführen, die Sie benötigen. ',
				STEP2: 'Dies ist das Hauptmenü. Von hier aus können Sie auf die verschiedenen verfügbaren Seiten zugreifen. ',
				STEP3: 'Hier können Sie die Angebote überwachen, an denen Sie teilgenommen haben. ',
				STEP4: 'Hier sind die Gebote, die Ihnen zugewiesen sind. In ihnen können Sie die Siegerinformationen konsultieren und Rückverfolgbarkeit ausfüllen. ',
				STEP5: 'Schließlich wird hier die vollständige Liste der verfügbaren Ausschreibungen angezeigt. Gehen wir zu dieser Seite, um sie im Detail zu sehen. ',
				STEP6: 'Sie können Ihre Angebote in diesem Menü anbieten. Sie werden seine Details einfügen und in der Liste veröffentlicht. Sie können es am Ende dieser Demonstration versuchen. ',
				STEP7: 'Sobald Sie erstellt haben, finden Sie alle Ihre Angebote auf dieser Seite. ',
				STEP8: 'Wenn eine Ausschreibung fertig ist, finden Sie alle, die anhängig sind, um ein Bestattungsunternehmen auf diesem Bildschirm zuzuweisen. ',
				STEP9: 'Hier sind die Ausschreibungen, die Sie bereits einem Bestattungsunternehmen zugeordnet haben und die Ausführung sind. In ihnen können Sie die Informationen des Bestattungsunternehmens konsultieren und ihre Rückverfolgbarkeit sehen. ',
				STEP10: 'Schließlich wird hier die vollständige Liste der verfügbaren Ausschreibungen angezeigt. Gehen wir zu dieser Seite, um sie im Detail zu sehen. '
			},
			LICIT: {
				STEP1: 'Willkommen bei WFN! Dies ist die Demonstrationsplattform. Wir werden die Funktionen der Plattform entdecken und dann können Sie damit interagieren und die Tests durchführen, die Sie benötigen. ',
				STEP2: 'Jede "Karte", die Sie sehen, ist ein Ausschreibung, der von einem Versicherer oder Kollektiv eingestellt ist. In IT finden Sie alle Informationen und Details der Dienste, z. B. der Standort, die verbleibende Zeit, den aktuellen Betrag, den Dienststatus usw. ',
				STEP3: 'Im oberen Bereich finden Sie die Ausschreibungsreferenz neben dem Datum der Erstellung. Ebenso können Sie sie jederzeit beseitigen (die Teilnehmer werden benachrichtigt) und bearbeiten, wenn sie noch nicht daran teilgenommen haben. Wenn das Angebot verlassen ist, können Sie es auch sobald fertiggestellt werden. ',
				STEP4: 'Im oberen Bereich finden Sie die Referenz, die das System neben dem Datum der Erstellung zugewiesen hat. ',
				STEP5: "Dies sind die Leistungen, die in einer Ausschreibung erbracht werden müssen. Wenn Sie darauf klicken, können Sie deren allgemeine Details sehen. Die Dienste mit dem Symbol <b style='color: red'>+</b> darauf hinweisen, dass der Bieter ein bestimmtes Detail angegeben hat",
				STEP6: 'Der Ort, an dem der Dienst oder der Ursprung und das Ziel in den Transfers unten angegeben sind, wird unten angegeben.',
				STEP7: 'Dies ist die verbleibende Zeit für das Ende des Angebots und damit die Zeit zum Bieten. ',
				STEP8: 'In diesem Raum beobachten Sie den aktuellen Zustand des Angebots. Dies kann geöffnet sein, ausstehend, ausstehend, ausgeführt, fertiggestellt, geschlossen oder verlassen. ',
				STEP9: 'Schließlich finden Sie den aktuellen Betrag des Angebots und können auf das Detail Ihrer Gebote zugreifen oder registriert sind. ',
				STEP10: 'Sobald eine Ausschreibung zugewiesen wurde, können Sie den Fortschritt der Rückverfolgbarkeit und die Details des zugegebenen Bestattungsunternehmens sehen. ',
				STEP11: 'Darüber hinaus sehen Sie im folgenden Menü das Detail der Rückverfolgbarkeit, die die Beerdigung ausfüllt. ',
				STEP12: 'In diesem Raum beobachten Sie den aktuellen Zustand des Angebots. Dies kann offen sein (Gewinn/Verlust/Registrieren), in der Ausführung, fertiggestellt oder verlassen. ',
				STEP13: 'Schließlich finden Sie den aktuellen Betrag des Angebots und können sich dafür anbieten oder sich anmelden. ',
				STEP14: 'Sobald Sie eine Ausschreibung zugewiesen haben, können Sie die Details der Preisverleihung sehen. ',
				STEP15: 'Wenn sich die Ausschreibung in der Ausführung befindet, können Sie die Rückverfolgbarkeitsinformationen des Dienstes ausfüllen. ',
				STEP16: 'Sie können Karten jederzeit mit den verschiedenen verfügbaren Filtern anpassen. ',
				STEP17: 'Das ist alles. Denken Sie daran, dass Sie auf der Demonstrationsplattform sind. Sie können mit den verfügbaren Ausschreibungen interagieren, neue erstellen und alle Tests durchführen, die Sie benötigen. ',
				STEP18: 'Das ist alles. Denken Sie daran, dass Sie auf der Demonstrationsplattform sind. Sie können mit den verfügbaren Angeboten interagieren, sich mit ihnen anmelden und alle Tests durchführen, die Sie benötigen. '
			}
		},
		CONFIRMACION: {
			LOADING: 'Bearbeitung von Einstellungen ... ',
			SUCCESS: 'Einstellung abgeschlossen ',
			SUCCESS_SUB: 'Sie werden in wenigen Sekunden auf die Plattform umgeleitet ... ',
			PENDING: 'Ausstehende Kontobestätigung ',
			PENDING_SUB: 'Vor dem Fortsetzung muss Ihr Konto von der Organisation bestätigt werden. Die Organisation behält sich das Zulassungsrecht vor, wir werden ihren Eintrag in 24 bis 48 Stunden bewerten und den Einstellungsprozess beenden. ',
			PAGO: 'Zugriff auf Zahlungsbestätigung ... '
		},
		PAGO: {
			LOADING: 'Zugriff auf eine sichere Plattform ... ',
			RESULT_LOADING: 'Abonnementdaten laden ... ',
			METODO: 'Auswahl der Zahlungsmethode ',
			METODO_SUB: 'Wählen Sie Ihre Zahlungsmethode aus, um die Aktivierung Ihres Kontos zu beenden. ',
			METODO_TARJETA: 'Ich bezahle mit Kreditkarte ',
			METODO_SEPA: 'Zahlung mit Bankdomie (SEPA) ',
			SEPA_TITLE: 'Zahlung mit Bankdomize - SEPA ',
			SEPA_NOMBRE: 'Namen einstellen ',
			SEPA_IBAN: 'Kontonummer (IBAN) ',
			SEPA_CODIGO: 'Promotion -Code ',
			SEPA_ERRORCOD: 'Der eingeführte Code ist nicht korrekt ',
			SEPA_BUTTON: 'Wfuneralnet abonnieren ',
			SEPA_LOADING: 'Daten verarbeiten ... ',
			SEPA_LEGAL: 'Durch die Bereitstellung Ihrer Zahlungsinformationen und die Bestätigung dieser Zahlung autorisieren Sie WFuneralNet und Stripe, unseren Zahlungsdienstleister, um Anweisungen an Ihre Bank zu senden, um den Betrag auf Ihrem Konto und Ihrer Bank zu laden, um den Betrag in Ihrem Konto gemäß diesen Anweisungen zu laden. Im Rahmen Ihrer Rechte haben Sie das Recht auf Erstattung Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Ihre Rechte werden in einer Erklärung erläutert, die Sie von Ihrer Bank erhalten können. Sie erklären sich damit einverstanden, Benachrichtigungen über zukünftige Gebühren bis zu 2 Tage vor ihrer Eintritt zu erhalten. ',
			SUCCESS: 'Ihr Abonnement wurde korrekt aktiviert. ',
			SUCCESS_SUB: 'Sie können die Plattform mit all ihrer Funktionalität verwenden. Klicken Sie auf die folgende Schaltfläche, um auf den Start zuzugreifen. ',
			SUCCESS_BUTTON: 'Beginnen Sie mit dem Weltbestattungsnetz ',
			CANCEL: 'Sie haben den Abonnementprozess storniert. ',
			CANCEL_SUB: 'Wenn ein Fehler aufgetreten ist oder es erneut versuchen möchte, klicken Sie auf die nächste Schaltfläche, um auf die sichere Plattform zuzugreifen. ',
			CANCEL_BUTTON: 'Weiter zur sicheren Zahlungsplattform ',
			WFP: 'Es wird keine WFP -Position erfolgen, bis der Service durchgeführt wird. Sie zahlen 10% des Preises, zu dem das Angebot vergeben wurde. '
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra zu Ihrer Maßnahme ',
				INVITADO: 'Die Filter sind im Gastkonto deaktiviert. ',
				CONTRATAR: 'Mieten',
				REFERENCIA: 'Referenz',
				SERVICIO: 'Service',
				LOC_ORIGEN: 'Originortort ',
				LOC_DESTINO: 'Zielort ',
				TODOS: 'Alle',
				PAIS: 'Land',
				COMUNIDAD: 'Autonome Gemeinschaft ',
				ESTADO: 'Zustand',
				FECHA_DESDE: 'Stammen aus',
				FECHA_HASTA: 'Datum bis ',
				PRECIO_MIN: 'Preis min. ',
				PRECIO_MAX: 'Maximaler Preis. ',
				ESTADO_LIC: 'Ausschreibungszustand ',
				ESTADOS: {
					PENDIENTE_INI: 'Anfangen ausstehend ',
					ABIERTA: 'Offen ',
					PENDIENTE_ADJ: 'Ausstehende Auszeichnung ',
					ADJ_TRAM: 'Vergeben. in Bearbeitung',
					ADJ_PEN: 'Vergeben. und ausstehend ',
					EJEC: 'In Arbeit',
					COMPLETADA: 'Vollendet ',
					CERRADA: 'Geschlossen',
					DESIERTA: 'Verlassen '
				},
				REESTABLECER: 'Wiederherstellbare Filter '
			},
			ORDEN: {
				ESTADO: 'Bestellung durch Gebotsstaat ',
				ESTADO_ABIERTAS: 'Zuerst offen zeigen ',
				ESTADO_PENDIENTE: 'Zeigen Sie zuerst an, ausstehend ',
				ESTADO_ADJUD: 'Show zuerst ausgezeichnet ',
				FECHA: 'By Start Datum bestellen ',
				FECHA_ASC: 'Nach oben',
				FECHA_DESC: 'Fallen ',
				TIEMPO: 'Bestellung für Zeit bis zur Fertigstellung ',
				TIEMPO_ASC: 'Nach oben',
				TIEMPO_DESC: 'Fallen '
			},
			LOADING: 'Ausschreibungen laden ... ',
			LOADING_AD: 'Ladedienste ... ',
			NOHAY: 'Es gibt keine Ausschreibungen, ',
			CARD: {
				ORIGEN: 'Herkunft',
				DESTINO: 'Ziel',
				TRASLADO: 'Transfer',
				TRASLADO_INT: 'Int. ',
				LOCALIZ: 'Standort',
				LOC_WFP1: 'Verstorbener Standort ',
				LOC_WFP2: 'Tanatorische Lage ',
				SIN_PRECIO: 'Im Gange',
				PUJA: 'Gebot',
				OFERTA: 'Angebot',
				PUJAR: 'Bieten Sie ',
				OFERTAR: 'Angebot ',
				LA: 'Der',
				TIME: 'Verbleibende Zeit für ',
				COMIENZA: 'Startet um',
				VER_ASEG: 'siehe Versicherung ',
				VER_FUN: 'Siehe Beerdigung ',
				VER_PART: 'Siehe besondere ',
				VER_DETALLES: 'siehe Einzelheiten',
				ESTADOS: {
					PENDIENTE_INI: 'Anfangen ausstehend ',
					ABIERTA: 'Offen ',
					INSCRITO: 'Angemeldet ',
					GANANDO: 'Gewinnen ',
					PERDIENDO: 'Verlust ',
					CONTU: 'mit Ihrem',
					PUJA: 'Gebot',
					OFERTA: 'Angebot',
					DE: 'von',
					PENDIENTE_ADJ: 'Ohrring',
					PENDIENTE_ADJ2: 'vergeben',
					CERRADA: 'Geschlossen',
					ADJ_TRAM: 'Vergabe in Bearbeitung ',
					ADJ_PEN: 'Vergeben und ',
					ADJ_PEN2: 'ausstehende Ausführung ',
					ADJ_PEN3: 'Bestätigung Pendient ',
					ADJ_PEN4: 'durch Beerdigung abgelehnt, ',
					PEND_ACEPT: 'Ausstehende Bestätigung',
					EJEC: 'In Aktion',
					COMPLETADA: 'Vollendet ',
					NO_ADJ: 'Nicht vergeben ',
					RECHAZADA: 'Abgelehnt ',
					DESIERTA: 'Verlassen '
				},
				PUJA_SALIDA: 'Puja Departas ',
				OFERTA_SALIDA: 'Ausgangsangebot ',
				PUJA_ACTUAL: 'Aktuelles Gebot ',
				OFERTA_ACTUAL: 'Aktuelles Gebot',
				PUJA_GANADORA: 'Gewinngebot ',
				OFERTA_GANADORA: 'Gewinnangebot ',
				PUJA_DESIERTA: 'Verlassenes Angebot ',
				OFERTA_DESIERTA: 'Wüsteangebot ',
				PUJA_ADJUDIC: 'Preisgekrönte Puja. ',
				OFERTA_ADJUDIC: 'Preisverleihung. ',
				PRECIO_FIJO: 'Festpreis',
				ASIG_DIRECTA: 'direkte Zuordnung',
				VER_PUJAS: 'Siehe Gebote ',
				VER_OFERTAS: 'Siehe Angebote ',
				INSCRITOS: 'Eingeschrieben ',
				ADJUDICAR: 'Zu vergeben ',
				TRAZABILIDAD: 'Rückverfolgbarkeit ',
				INSCRÍBETE: 'Registrieren ',
				ACEPTAR: 'AKZEPTIEREN',
				RECHAZAR: 'ABFALL',
				PUJAR2: 'Bieten Sie ',
				OFERTAR2: 'Angebot ',
				VOLVER_PUJAR: 'Wieder Pujar ',
				VOLVER_OFERTAR: 'Re -selbst ',
				IVA_INC: 'MwSt. nicht ausweisbar',
				HIST: {
					HISTORICO_PUJAS: 'Historisch der Angebote ',
					HISTORICO_OFERTAS: 'Historisch von Angeboten ',
					HISTORICO_INSC: 'Historisch registriert ',
					IMPORTE_SALIDA: 'Ausgangsmenge ',
					ACTUALIZAR: 'aktualisieren',
					TOTAL: 'Gesamt',
					FECHA: 'Datum',
					USUARIO: 'Benutzer',
					IMPORTE: 'Menge',
					DESIERTA: 'Wüstenbidding ',
					CREADOR: 'SCHÖPFER',
				},
				CERRAR: 'schließen',
				ASIG: {
					NOMBRE: 'Name',
					LICENCIA: 'Lizenz',
					DOMICILIO: 'Heim',
					TELEFONO: 'Telefon',
					EMAIL: 'Email',
					WEB: 'Website',
					CERTIFICACIONES: 'Zertifizierungen ',
					PUNTUACIONES: 'Bewertungen ',
					IMPORTE: 'Bietbetrag ',
					ADJUDICAR: 'Zu vergeben ',
					EXCLUIR: 'AUSSCHLIESSEN',
					EXCLUIR_TODAS: 'Alle ausschließen ',
					BLOQUEAR: 'Block Prov. ',
					VER_DETALLES: 'siehe Einzelheiten',
					CERRAR_DETALLES: 'Details schließen ',
					DETALLE_ASIG1: 'Der Versicherer behält sich das Recht vor, die Qualität der von The Funeral Home angebotenen Dienstleistungen zu schätzen. ',
					DETALLE_ASIG11: 'Der Einzelne behält sich das Recht vor, die Qualität der von der Beerdigung angebotenen Dienstleistungen zu bewerten. ',
					DETALLE_ASIG2: 'Es ist wichtig, dass Sie Ihr Profil mit Punktzahlen und Qualitätszertifikaten abschließen. ',
					DETALLE_ASIG_BTN: 'Vollständiges Profil ',
					REFERENCIA: 'Bieterreferenz. Wfn ',
					REFERENCIA2: 'Bieterreferenz. Schöpfer',
					SIGUIENTES_PUJAS: 'Folgende Gebote ',
					SIGUIENTES_OFERTAS: 'Folgende Angebote ',
					FUNERARIA: 'Bieter Beerdigung ',
					PARTICULAR: 'Informationen der Person '
				},
				PUJAS: {
					PUJA_RAPIDA: 'Schnelles Gebot ',
					OFERTA_RAPIDA: 'Schnelles Angebot ',
					PUJA_MANUAL: 'oder manuell Ihr Gebot einführen ',
					OFERTA_MANUAL: 'oder manuell Ihr Angebot vorstellen ',
					PUJA_WFP: 'Geben Sie Ihr Gebot ein, ',
					OFERTA_WFP: 'Geben Sie Ihr Angebot ein, ',
					PUJAR: 'Bieten Sie ',
					OFERTAR: 'Angebot ',
					AVISO: 'Warnung',
					PRORROGA3_PUJA: 'Das Angebot wird in 3 Minuten verlängert, wenn in der letzten Minute ein Bieten ist. ',
					PRORROGA3_OFERTA: 'Das Angebot wird in 3 Minuten verlängert, wenn er in der letzten Minute anbietet. ',
					PRORROGA1_PUJA: 'Das Angebot wird in 1 Minute verlängert, wenn in der letzten Minute ein Bieten ist ',
					PRORROGA1_OFERTA: 'Das Angebot wird in 1 Minute verlängert, wenn er in der letzten Minute anbietet. ',
					PROCESO_PUJA: 'Sein Angebot wird bearbeitet. Einen Moment bitte...',
					PROCESO_OFERTA: 'Ihr Angebot wird verarbeitet. Einen Moment bitte...',
					HISTORICO_PUJAS: 'Siehe historische Pujas ',
					HISTORICO_OFERTAS: 'Siehe historische Angebote ',
					FUNERARIA: 'Wählen Sie die Beerdigung ',
					FUNERARIA2: 'Ausgewähltes Bestattungsunternehmen '
				},
				TRAZA: {
					INCIDENCIA: 'Inzidenz im Service ',
					VALIDAR: 'BESTÄTIGEN',
					CANCELAR: 'STORNIEREN',
					FECHA: 'Datum',
					LUGAR: 'Ort'
				},
				SERVICIO: { //NUEVO WFP
					TIPO_TRASLADO: 'Übertragung Typ ',
					CARRETERA: 'Punkt zur Punktstraße',
					AEROPUERTO: 'Flughafen',
					INCLUIDOS: 'Dienste enthalten, ',
					TIPO_COMPLETO: 'Typ Serv. Vollständig',
					CREMACION: 'Einäscherung + Urne ',
					DESTFIN: 'Inhumation ',
					DETALLE_ESP: 'Spezifisches Servicedetail ',
					ESPEC_GEN: 'Allgemeine Servicespezifikationen ',
					TRASLADO1: 'Der Tod und das endgültige Ziel (Inhumation oder Verbrennung) treten an verschiedenen Orten auf. ',
					TRASLADO2: 'Gewohnheitselemente und Dienstleistungen der Übertragung:',
					TRASLADO3: 'Arche übertragen: In den Fällen, in denen die Mortuary Sanitary Police Regulations (RPSM) dies vorschreiben, muss es zusätzlich mit einem Luftfiltergerät oder einem anderen Gerät ausgestattet sein, um den Innen- und Außendruck auszugleichen.',
					TRASLADO4: 'Luftfracht in Ihrem Fall, KMS. Durch Straße und spezifische Verfahren und Verfahren. ',
					WFP_TIPO: 'Typology Service ',
					WFP_TIPO_CREMA: 'Einäscherung',
					WFP_TIPO_INHUM: 'Inhumation ',
					WFP_CEREM: 'Zeremonie',
					WFP_CIVIL: 'Bürgerlich',
					WFP_RELIG: 'Religiös ',
					WFP_CATERING: 'Num. Der Menschen ',
					WFP_CORONAS: 'Coronas ',
					WFP_OTROS: 'Andere Spezifikationen ',
					WFP_MULTIPLE: 'Multiple Tod ',
					WFP_PERSONAS: 'Menschen'
				}
			},
			SWAL: { 
				CANCELAR: 'Stornieren',
				registerSwal: 'Notwendige Einstellung ',
				registerSwal_TEXT: 'Dieser Inhalt ist nicht mit einem Gastkonto verfügbar. Mieten Sie einen Preis, um fortzufahren. ',
				registerSwal_BTN1: 'Mieten',
				suscriptionSwal: 'Nicht verfügbares Angebot ',
				suscriptionSwal_TEXT: 'Dieser Inhalt ist mit Ihrem Abonnement nicht verfügbar. Überprüfen Sie Ihren Zugriff auf Ihren Zugriff. ',
				suscriptionSwal_BTN1: 'Mein Profil',
				confirmationSwal: 'Korrektes Angebot ',
				confirmationSwal_TEXT: 'Ihr Betrag wurde korrekt angezeigt.',
				errorSwal: 'Falsche Menge ',
				errorSwal_TEXT: 'Die Menge muss niedriger sein als der Strom des Angebots. ',
				errorNegativeSwal_TEXT: 'Der Gebotsbetrag kann nicht negativ sein. ',
				error2Swal_TEXT: 'Die Höhe des Angebots hat sich geändert und ist Ihrem Vorschlag nun unterlegen. Versuchen Sie es erneut mit einer anderen Menge. ',
				confirmaSwal: 'Bestätigung',
				confirmaSwal_TEXT1: 'Bestätigen Sie die Ausschreibung für {{importeLicitar}} {{divisa}}? ',
				confirmaSwal_TEXT2: 'Bestätigen Sie die Registrierung für das Angebot? Sie erhalten eine Benachrichtigung, wenn Sie am Ende ausgewählt werden. ',
				confirmaSwal_BTN1: 'Ja, ich bestätige ',
				confirmaElegidoSuccessSwal: 'Benutzer korrekt ausgewählt. ',
				confirmaElegidoSuccessSwal_TEXT: 'Der Gewinner wurde korrekt zugewiesen. Sie können seine Details unten sehen. ',
				confirmaElegidoSwal_TEXT1: 'Bestätigen Sie {{empresa}} als Gewinner des Angebots? ',
				confirmaSaltarSwal_TEXT1: 'Bestätigen Sie das Ausschluss von {{empresa}} als Gewinner des Angebots und ablehnen Sie es in zukünftigen Tendern ab? Es gibt keine Ausgaben mehr, das Angebot bleibt verlassen. Dieser Teilnehmer wird Ihre Ausschreibungen nicht sehen',
				confirmaSaltarSwal_TEXT2: 'Bestätigen Sie das Ausschluss von {{empresa}} als Gewinner des Angebots und ablehnen Sie es in zukünftigen Tendern ab? Dieser Teilnehmer wird Ihre Angebote nicht wieder sehen. Ausschluss {pasadas}}/2 ',
				confirmaSaltarSwal_TEXT3: 'Bestätigen Sie, {{empresa}} als Gewinner des Angebots auszuschließen? Es gibt keine mehr Ausnahmen, das Tender bleibt verlassen. ',
				confirmaSaltarSwal_TEXT4: 'Bestätigen Sie, {{empresa}} als Gewinner des Angebots auszuschließen? Sie können nicht zu diesem Benutzer zurückkehren. Ausschluss {pasadas}}/2 ',
				confirmaSaltarSwal_TEXT5: 'Bestätigen Sie das Ausschluss von {{empresa}} als Gewinner des Angebots und ablehnen Sie es in zukünftigen Tendern ab? Es gibt keine Teilnehmer mehr, das Angebot bleibt verlassen. Dieser Teilnehmer wird Ihre Ausschreibungen nicht sehen',
				confirmaSaltarSwal_TEXT6: 'Bestätigen Sie das Ausschluss von {{empresa}} als Gewinner des Angebots und ablehnen Sie es in zukünftigen Tendern ab? Dieser Teilnehmer wird Ihre Angebote nicht wieder sehen. ',
				confirmaSaltarSwal_TEXT7: 'Bestätigen Sie, alle Teilnehmer auszuschließen? Das Tender wird verlassen bleiben. ',
				confirmaSaltarSuccessSwal1: 'Teilnehmer korrekt ausgeschlossen. ',
				confirmaSaltarSuccessSwal2: 'Richtig blockierter Teilnehmer. ',
				confirmaSaltarSuccessSwal_TEXT1: 'Der Teilnehmer wurde ausgeschlossen. Denken Sie daran, dass Sie nur zwei Mal mit Tend ausschließen können. ',
				confirmaSaltarSuccessSwal_TEXT2: 'Der Teilnehmer wurde ausgeschlossen. Das Tender wurde verlassen. ',
				confirmaSaltarSuccessSwal_TEXT3: 'Der Ausschreibungsteilnehmer wurde ausgeschlossen. ',
				confirmaSaltarSuccessSwal_TEXT4: 'Der Teilnehmer wurde ausgeschlossen und wird Ihre Ausschreibungen nicht wieder sehen. Das Tender wurde verlassen. ',
				confirmaSaltarSuccessSwal_TEXT5: 'Der Teilnehmer wurde ausgeschlossen und wird Ihre Ausschreibungen nicht wieder sehen. ',
				confirmaTrazaSwal: 'Trace -Bestätigung ',
				confirmaTrazaSwal_TEXT: 'Möchten Sie diese Spur bestätigen? Der Beader wird informiert. ',
				confirmaTrazaSwal_BTN1: 'Ja, ich bestätige ',
				errorTrazaSwal: 'Trace -Fehler ',
				errorTrazaSwal_TEXT: 'Sie müssen einen Service -Kommentar eingeben, indem Sie eine Inzidenz darin haben. ',
				errorTrazaSwal_TEXT2: 'Sie müssen das Datum des Dienstes eingeben. ',
				errorTrazaSwal_TEXT3: 'Sie müssen alle Informationen über den Ort des Dienstes eingeben. ',
				confirmaTrazaSuccessSwal: 'Trace ordnungsgemäß bestätigt. ',
				confirmaTrazaSuccessSwal_TEXT: 'Die ausgewählte Spur wurde korrekt bestätigt. ',
				cancelaTrazaSwal: 'Spurenstornierung ',
				cancelaTrazaSwal_TEXT: 'Möchten Sie diese Spur stornieren? Alle Informationen werden gelöscht. ',
				cancelaTrazaSwal_BTN1: 'Ja, ich bestätige ',
				cancelaTrazaSuccessSwal: 'Storniertes Zeichnen richtig. ',
				cancelaTrazaSuccessSwal_TEXT: 'Die ausgewählte Spur wurde korrekt abgesagt. ',
				borrarSwal: 'Zart beseitigen ',
				borrarSwal_TEXT: 'Sind Sie sicher, dass Sie dieses Angebot beseitigen? Sie werden seinen Inhalt nicht wiederherstellen können. ',
				borrarSwal_BTN1: 'Ja, löschen ',
				borrarConfirmSwal: 'Ordnungsgemäß ausgestrahlt ',
				borrarConfirmSwal_TEXT: 'Das Angebot wurde korrekt beseitigt. ',
				conexionSwal: 'Verbindungsgrenze übertroffen ',
				conexionSwal_TEXT: 'Die Anzahl der verfügbaren gleichzeitigen Verbindungen wurde überschritten. Schließt einige der aktiven Sitzungen, um fortzufahren. ',
				errorFileSwal: 'Falsches Format ',
				errorFileSwal_TEXT: 'Das Dokument muss im PDF -Format hochgeladen werden ',
				questionDeleteFileSwal: "Dokument löschen",
				questionDeleteFileSwal_TEXT: '"Sind Sie mit Sicherheit dieses Dokument löschen? Sie können sich nicht erholen.',
				questionFusionarSwal: "Merge Services",
				questionFusionarSwal_TEXT: '"Sind Sie sicher, diese Dienste zusammenzuführen? Sie können den ursprünglichen Dienst nicht wiederherstellen und Ihre Daten, Dokumente und Kommentare werden kombiniert.',
				questionFusionarSwal_BTN1: "Ja, verschmelzen", 
				questionDeleteComentarioSwal: "Borrar Comentario",
				questionDeleteComentarioSwal_TEXT: "¿Estás seguro de borrar este comentario? No se podrá recuperar.",

				confirmacionAcepSwal: 'Service akzeptieren ',
				confirmacionAcepSwal_TEXT: 'Nehmen Sie den Service mit Sicherheit an? Sie werden es später nicht ändern können. ',
				confirmacionAcepSwal_BTN1: "Ja, akzeptieren",
				confirmacionRechSwal: 'Service ablehnen ',
				confirmacionRechSwal_TEXT: 'Sind Sie sicher, den Dienst abzulehnen? Sie werden es später nicht wiederherstellen können. ',
				confirmacionRechSwal_BTN1: "Ja, ablehnen"
			},
			TUTORIAL: 'SCHAUEN SIE SICH DIE ANLEITUNG NOCHMAL AN'
		},
		GLOBAL: {
			PAGO: 'Ausstehende Abonnementzahlung. ',
			PAGO_SUB: 'Sie haben uns Ihre Abonnement -Zahlungsdaten noch nicht zur Verfügung gestellt. Drücken Sie die Taste, um auf die sichere Zahlungsplattform zuzugreifen. ',
			PAGO_BUTTON: 'Sichere Zahlungsplattform ',
			errorModoSwal: 'Plattform nicht verfügbar ',
			errorModoSwal_TEXT: 'Dieser Inhalt ist mit Ihrem Abonnement nicht verfügbar. '
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} ist nicht gültig ',
				REQUIRED: '{{name}} muss eingeführt werden ',
				MIN_LENGTH: '{{name}} Mindestlänge ist {{min}} ',
				AGREEMENT_REQUIRED: 'Es ist notwendig, die Allgemeinen Geschäftsbedingungen zu akzeptieren ',
				NOT_FOUND: 'Das geknackte {{name}} ist nicht gefunden ',
				INVALID_LOGIN: 'Die eingegebenen Daten sind nicht korrekt. Versuchen Sie es erneut.',
				REQUIRED_FIELD: 'Sie müssen dieses Feld ausfüllen ',
				MIN_LENGTH_FIELD: 'Die Mindestgröße dieses Feldes ist:',
				MAX_LENGTH_FIELD: 'Die maximale Größe dieses Feldes ist:',
				INVALID_FIELD: 'Dieses Feld ist nicht gültig ',
				MATCH: 'Das Passwort und die Bestätigung stimmen nicht überein ',
				INVALID_REGISTER: 'Die eingeführte E -Mail ist bereits registriert. Versuchen Sie es erneut oder melden Sie sich an. '
			}
		},
		CREAR: {
			TITULO: 'Tender erstellen ',
			TITULO_NAC: 'Nationaler Dienst / Transfer ',
			TITULO_INT: 'Internationale Übertragung ',
			TITULO_EDIT: 'Gebote bearbeiten ',
			REF: 'Kundenreferenz ',
			REF2: '(wird nicht öffentlich gezeigt) ',
			REF_ERROR: 'Die bereits im System registrierte Referenz. ',
			TIPO: 'Art von Tender ',
			ESTANDAR: 'Standardausschreibung. ',
			ESTANDAR_DESC: 'Geben Sie eine Ausgabebestie ein. Beerdigungen werden nach unten versehen, um den Service oder die Übertragung zu gewinnen. ',
			FIJO: 'Ausschreibung zu einem Festpreis. ',
			FIJO_DESC: 'Der Service wird dem etablierten Preis unter allen Bestattungsunternehmen vergeben, die ihn angefordert haben. ',
			AD: 'Direkte Aufgabe. ',
			AD_DESC: 'Der Service wird automatisch an das Bestattungsunternehmen nach dem Kriterium der Wahl und dem vorab festgelegten Betrag vergeben. ',
			FECHA_INI: 'Start/Stunde Datum ',
			FECHA_FIN: 'Fertigstellung Datum/Stunde ',
			FECHA_ERROR: 'Das Enddatum kann nicht vor dem Startdatum vorliegen. ',
			OPT_TRASLADO: 'Ausschreibung nationaler Transfer ',
			OPT_SERVICIO: 'Ausschreibungsservice ',
			IMPORTE: 'Ausgangsmenge ',
			IMPORTE_ERROR1: 'Der Betrag der Übertragung kann nicht negativ sein.',
			IMPORTE_ERROR2: 'Der Betrag der Übertragung muss weniger als 100.000 ',
			DETALLE: 'Spezifisches Servicedetail ',
			ORIGEN: 'HERKUNFT',
			PAIS: 'Land',
			COMUNIDAD: 'Autonome Gemeinschaft ',
			ESTADO: 'Zustand',
			POBLACION: 'Bevölkerung',
			DESTINO: 'ZIEL',
			KMS: 'Km zum Ziel ',
			OPCIONAL: '(Optional)',
			LOCALIZACION: 'STANDORT',
			DESTINO_TRASLADO: '(Übertragungsziel) ',
			PENDIENTE_DESTINO: 'Ausstehend wählen Sie Übertragungsziel ',
			SERVICIO_ERROR: 'Sie müssen mindestens einen Service für die Ausschreibung auswählen ',
			IMPORTE_ERROR3: 'Der Betrag der Übertragung kann nicht negativ sein.',
			IMPORTE_ERROR4: 'Der Betrag der Übertragung muss weniger als 100.000 ',
			INCLUIDO_EN: 'Enthalten in ',
			INCLUIDO_EN2: 'Rundumservice',
			SELECT_OPCION: 'Wähle eine Option ',
			OPCION_ERROR: 'Sie müssen eine Option auswählen ',
			OTROS_OPCION: 'Wählen Sie andere Dienste aus, die enthalten sind, ',
			CREACION: 'Schöpfungsformat ',
			UNIDO: 'Gleiches Angebot. ',
			UNIDO_DESC: 'Die Übertragung und Dienstleistungen werden in derselben Ausschreibung angeboten. Das ausgezeichnete Bestattungsunternehmen wird für beide gleich sein. ',
			SEPARADO: 'Getrennte Ausschreibungen. ',
			SEPARADO_DESC: 'Die Übertragung und Dienstleistungen werden in getrennten Angeboten angeboten. Das ausgezeichnete Bestattungsunternehmen kann in beiden unterschiedlich sein. ',
			BOTON: 'Gebote hinzufügen ',
			BOTON_EDIT: 'Gebote bearbeiten ',
			SWAL: {
				confirmationAddSwal: 'Tender hinzugefügt ',
				confirmationAddSwal_TEXT: 'Die ausgewählten Dienste wurden korrekt zur Ausschreibungsliste hinzugefügt ',
				confirmationEditSwal: 'Bearbeitete Angebote ',
				confirmationEditSwal_TEXT: 'Das Angebot wurde korrekt bearbeitet ',
				errorLicitacionSwal: 'Mit Geboten bieten ',
				errorLicitacionSwal_TEXT: 'Diese Ausschreibung hat bereits Gebote erhalten. Es ist nicht möglich, Änderungen vorzunehmen. Sie können es löschen und erneut erstellen. ',
				errorTipoLicitacionSwal: 'Tippen Sie aus der Auswahl ',
				errorTipoLicitacionSwal_TEXT: 'Sie müssen eine Art von Ausschreibung auswählen, bevor Sie fortfahren. ',
				errorInternacionalSwal: 'Fehler in Ursprung/Ziel ',
				errorInternacionalSwal_TEXT: 'Die Herkunfts- und Zielländer müssen anders sein. '
			}
		},
		START: {
			PASOS: {
				DATOS: 'Datenvertragsdaten ',
				VALIDACION: 'Datenvalidierung',
				ADICIONAL: 'Weitere Informationen',
				PAGO: 'Zahlungsdaten ',
				CONFIRMACION: 'Bestätigung',
				TARIFA: 'Ratenauswahl ',
				TARIFA2: 'Bewertungsinformationen '
			},
			TARIFA_LOC: 'Geben Sie den Standort des Tarifantrags an ',
			TARIFA_LOC_WFP: 'Geben Sie den geografischen Umfang Ihrer Dienstleistungen an',
			CANALES: 'Ich möchte über die folgenden Kanäle benachrichtigt werden: <i>(mindestens ein)</i>',
			PLATAFORMAS: 'Ich möchte auf die folgenden Plattformen zugreifen ',
			WFN: 'Erweitern Sie Ihr Bestattungsunternehmen, indem Sie auf Hunderte von Tendern zugreifen, die von Versicherern, Unternehmen und Gruppen veröffentlicht wurden. ',
			WFP: 'Erweitern Sie Ihr Bestattungsunternehmen, indem Sie Hunderte von individuellen Anfragen zugreifen. ',
			INFO: 'Mehr Info.',
			VOLVER: 'ZURÜCKKEHREN',
			CONTINUAR: 'WEITERMACHEN',
			VALIDACION: 'Datenvalidierung',
			SMS: {
				TITULO: 'Mobiltelefonvalidierung ',
				MENSAJE: 'Wir haben ein SMS mit einem Bestätigungscode an das Telefon {{telefono}} gesendet. Geben Sie es unten ein. ',
				CODIGO: 'Bestätigungscode',
				CODIGO_ERROR: 'Der eingeführte Code ist nicht gültig ',
				REENVIAR: 'Vorwärtscode ',
				REENVIADO: 'Weitergeleitete Nachricht'
			},
			CERTIFICADOS: 'Zusätzliche Informationen - Zertifikate und Punt. Qualität',
			PAGO: 'Bankendaten eingeben ',
			CONFIRMACION: 'Bestätigung',
			PERSONAL: 'Persönliche Angaben',
			TARIFA: 'Gebühr',
			SUSCRIPCION: 'Abonnement',
			AMBITO: 'Geografischer Bereich, ',
			WFP_RESUMEN: 'Zahlen Sie 10% des Preises, zu dem das Angebot ausgezeichnet wurde. ',
			ALTA: 'Hoch wie ',
			OFERTANTE: 'Angebot ',
			PROVEEDOR: 'Anbieter',
			SERVICIOS: 'von Transfers und Trauerfeiern ',
			DATOS_PAGO: 'Zahlungsdaten ',
			CONFIRMAR: 'BESTÄTIGEN',
			CONFIRMAR_PAGAR: 'Bestätigen und bezahlen ',
			WFP_PAGO: 'Im nächsten Schritt werden Sie nach den Kreditkartendaten fragen, bei denen keine Gebühr erhoben wird, solange kein Dienst in WFP (Einzelpersonen) erfolgt. ',
			SWAL: {
				errorSMSSwal: 'Falscher SMS -Code ',
				errorSMSSwal_TEXT: 'Der von SMS gesendete Telefonvalidierungscode ist falsch. Überprüfen Sie es und stellen Sie es erneut vor. ',
				errorTelegramSwal: 'Falscher Telegrammcode ',
				errorTelegramSwal_TEXT: 'Der eingeführte Validierungscode von Telegram ist falsch. Überprüfen Sie es und stellen Sie es erneut vor. '
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Abonnement bis ',
			GUARDAR: 'Informationen speichern ',
			DATOS: {
				TITULO: 'Mein Profil',
				TITULO2: 'Nutzerinformation',
				EMAIL: 'Email',
				EMPRESA: 'Unternehmen/Kollektiv ',
				EMPRESA2: 'Name des Unternehmens oder der Gruppe',
				ACTIVIDAD: 'Aktivität',
				LICENCIA: 'Lizenz Nr. ',
				PAIS: 'Land',
				COMUNIDAD: 'Autonome Gemeinschaft/Staat ',
				DIRECCION: 'Adresse',
				POBLACION: 'Bevölkerung',
				CP: 'CP ',
				MOVIL: 'Mobiltelefon',
				NOTIFICACIONES: 'Benachrichtigungen ',
				TELEFONO: 'Telefon',
				WEB: 'Website',
			},
			SUSCRIPCION: {
				TITULO: 'Abonnement',
				NO_HAY: 'Es gibt kein aktives Abonnement ',
				CAMBIO: 'Verarbeitung Änderung der Zahlungsmethode ... ',
				TARIFA: 'Gebühr',
				FECHA_INICIO: 'Startdatum',
				FECHA_FIN: 'Endtermin',
				PROX_RENOV: 'Nächste Renovierung',
				FACTURACION: 'Abrechnung ',
				PRECIO: 'Preis',
				LOCALIZACION: 'Ort der Rate ',
				ESTADO: 'Zinsstatus ',
				RENOV_AUTO: 'Automatische Erneuerung',
				RENOV_CANCEL: 'Abgesagte Erneuerung ',
				PROX_CAMBIO: 'Nächste Änderung der Rate ',
				FECHA_EFECT: 'Datum des Inkrafttretens ',
				LOCALIZACION2: 'Standort',
				CANCELAR_CAMBIO: 'Änderung abbrechen ',
				CAMBIO_PROCESO: 'Verarbeitungsstornierung ... ',
				METODO_PAGO: 'Bezahlverfahren',
				EXPIRACION: 'Ablauf',
				PAGO_CAMBIO: 'Bezahlungsmethode ändern ',
				PAGO_LOADING: 'Zahlungsplattform laden ... ',
				BTN_LOCALIZACION: 'Den Ort wechseln ',
				BTN_TARIFA: 'Änderungsrate ',
				BTN_SUSC: 'ABONNEMENT BEENDEN',
				BTN_CANCEL: 'Verarbeitungsstornierung ... '
			},
			METODO_PAGO: {
				TITULO: 'Bezahlverfahren',
				TITULAR: 'Überschrift',
				FACTURACION: 'Abrechnung ',
				MENSUAL: 'Monatlich',
				LICITA: 'Durch Tender ',
				CAMBIAR: 'Zahlungsdaten ändern ',
				NOMBRE: 'Namen einstellen ',
				IBAN: 'Kontonummer (IBAN) '
			},
			PAGOS: {
				TITULO: 'Zahlungshistorie',
				TITULO2: 'Zahlungshistorie - Rechnungen ',
				IMPORTE: 'Menge',
				ESTADO: 'Zustand',
				VER: 'Sehen',
				DESCARGA: 'Herunterladen'
			},
			BLOQUEO: {
				TITULO: 'Sperrliste ',
				FECHA: 'Blockdatum '
			},
			CERTIFICACIONES: {
				TITULO: 'Meine Zertifizierungen und Bewertungen ',
				TITULO2: 'Zertifizierungen/Qualitätswerte des Unternehmens ',
				TITULO3: 'Fügen Sie eine neue Zertifizierung hinzu ',
				TITULO5: 'Zertifizierung aktualisieren ',
				TITULO4: 'Zertifizierungen ',
				NPS: 'NPS -Werte eingeben ',
				OTRO: 'Ein weiterer Qualitätsmessindex (Identifikation) ',
				OTRO1: 'Führt die Punktzahl % von',
				OTRO2: 'Führt den Punktzahl % eines anderen Index ein ',
				NOMBRE: 'Name',
				ESTADO: 'Zustand',
				SUBIDA: 'Datumserhöhung ',
				VALIDEZ: 'Datum Gültigkeit ',
				BORRAR: 'Löschen',
				BOTON: 'Zertifizierung hinzufügen ',
				BOTON2: 'Zertifizierung aktualisieren ',
				CANCELAR: 'Stornieren',
				GUARDAR: 'Zertifizierung speichern ',
				PROCESO: 'Bearbeitungszertifizierung ',
				ESTADO_CORRECTO: 'Richtig',
				ESTADO_REVISION: 'In Rezension ',
				ESTADO_ERROR: 'Ungültig',
				ESTADO_CADUCADO: 'Zeitlich ausgegeben ',
				ACTUALIZAR: 'Aktualisieren',
				SUBIR_ERR: 'Falsches Format ',
				SUBIR_ERR_TEXT: 'Die Zertifizierung muss im PDF -Format hochgeladen werden ',
				SUBIR_ERR2: 'Nicht ausgewählte Datei ',
				SUBIR_ERR2_TEXT: 'Sie müssen eine Datei im Feld der oberen Zone auswählen oder ziehen.',
				ARRASTRA: 'Ziehen oder klicken Sie hier, um Ihre Zertifizierung (PDF) hochzuladen.',
				SUBIR_OK: 'Gespeicherte Zertifizierung ',
				SUBIR_OK_TEXT: 'Die Zertifizierung wurde erfolgreich gerettet ',
				BORRAR_PREG: 'Zertifizierung löschen ',
				BORRAR_PREG_TEXT: 'Sind Sie sicher, diese Zertifizierung zu löschen? Es kann nicht wiederhergestellt werden. ',
				BORRAR_BOTON: 'Ja, löschen ',
				BORRAR_BOTON2: 'Stornieren',
				BORRAR_OK: 'Gelöschte Zertifizierung ',
				BORRAR_OK_TEXT: 'Die Zertifizierung wurde erfolgreich gelöscht. '
			},
            FUNERARIAS: {
                TITULO: 'Mein Begräbnis ',
				TITULO2: 'Bestattungsunternehmen von der Firma verwaltet ',
				TITULO3: 'Fügen Sie eine neue Beerdigung hinzu',
				TITULO4: 'Beerdigung aktualisieren ',
				BOTON: 'Beerdigung hinzufügen ',
				BOTON2: 'Beerdigung aktualisieren ',
				CANCELAR: 'Stornieren',
				GUARDAR: 'Beerdigung retten ',
				PROCESO: 'Bearbeitung von Beerdigung ',
				BORRAR: 'Löschen',
				ACTUALIZAR: 'Aktualisieren',
				NOMBRE: 'Name',
				SUBIR_OK: 'Rettete Bestattungsinstitut ',
				SUBIR_OK_TEXT: 'Das Bestattungsunternehmen hat erfolgreich gerettet ',
				BORRAR_PREG: 'Beerdigung löschen ',
				BORRAR_PREG_TEXT: 'Bist du mit Sicherheit dieses Bestattungsunternehmen? Es kann nicht wiederhergestellt werden. ',
				BORRAR_BOTON: 'Ja, löschen ',
				BORRAR_BOTON2: 'Stornieren',
				BORRAR_OK: 'Beerdigung gelöscht ',
				BORRAR_OK_TEXT: 'Das Bestattungsunternehmen wurde erfolgreich gelöscht. ',
				NOTIFICACIONES: 'Benachrichtigungen '
            },
			ALERTAS: {
				TITULO: 'Mein Warnsystem ',
				FRECUENCIA: 'Wählen Sie die Häufigkeit von Benachrichtigungen über neue Ausschreibungen aus. ',
				INMEDIATO: 'Sofort',
				MINUTOS: 'Alle 15 Minuten ',
				NUNCA: 'Niemals',
				MODO: 'Passen Sie die Art und Weise an, wie Sie über die Plattformnachrichten informiert werden. ',
				LLAMADA: 'Anruf',
				MODO_ERROR: 'Sie müssen mindestens eine Option auswählen ',
				TELEGRAM_TITULO: 'Aktivierung der Telegramm -Benachrichtigung ',
				TELEGRAM1: 'Befolgen Sie die folgenden Anweisungen, um Benachrichtigungen in der Telegrammanwendung zu aktivieren;',
				TELEGRAM2: '1. Öffnen Sie die <b> Telegrammanwendung </b> auf Ihrem Mobilgerät oder installieren Sie es im Anwendungsspeicher (App Store - Apple oder Google Play - Android) ',
				TELEGRAM3: '2. Suchen Sie in der Suchleiste oben nach dem Benutzer <b>WFuneralNetBot</b>. Alternative: Wenn Sie Telegram auf Ihrem aktuellen Gerät installiert haben, können Sie auf diesen Link zugreifen: <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/ WFuneralNetBot " target="_blank">https://t.me/WFuneralNetBot</a> und öffnen Sie die App.',
				TELEGRAM4: '3. Drücken Sie auf die Taste <B> Start, die angezeigt wird, wenn Sie den Benutzer in der Anwendung finden. ',
				TELEGRAM5: '4. Geben Sie den Validierungscode ein, der im Gespräch angezeigt wird. ',
				COD_DISPOSITIVO: 'Gerätecode ',
				COD_ERROR: 'Der eingeführte Code ist nicht gültig ',
				NOTIFICACIONES: 'Passen Sie die Benachrichtigungen an, die Sie von der Aktivität der Plattform erhalten. ',
				SMS_TITULO: 'Benachrichtigungskonfiguration von SMS',
				SMS1: 'Geben Sie die Telefonnummern ein, in denen Sie Benachrichtigungen für SMS erhalten möchten.',
				COD_SMS: 'SMS -Telefon ',
				LLAMADAS_TITULO: 'Benachrichtigungskonfiguration pro Telefonanruf ',
				LLAMADAS1: 'Geben Sie die Telefonnummern ein, in denen Sie Anrufe erhalten möchten. Auf diese Weise erhalten Sie nur Hinweise auf neue Ausschreibungen. ',
				LLAMADAS2: 'Legt die Arbeitsstunden fest, in der Sie Anrufe erhalten möchten. ',
				COD_LLAMADAS: 'Anrufe',
				LLAMADAS_DIA: 'Ohne Grenze von Stunden ',
				LLAMADAS_INI: 'Startzeit',
				LLAMADAS_FIN: 'Endzeit ',
				ZONAS: 'Wählen Sie die Bereiche aus, in denen die Ereignisse der Plattform benachrichtigt werden können. ',
				ZONAS_TODAS: 'Alle Bereiche benachrichtigen '
			},
			CAMBIO: {
				AVISO: 'WARNUNG: Die Standortänderung wird in den nächsten 48 Stunden wirksam.',
				BTN_CANCELAR: 'Änderung abbrechen ',
				BTN_LOC: 'Den Ort wechseln ',
				BTN_TARIFA: 'Änderungsrate ',
				BTN_PROCESO: 'Verarbeitungsänderung ... ',
				SELECCIONE_TARIFA: 'Wählen Sie die Rate ',
				PAGAR: 'Zu zahlender Betrag ',
				AVISO2: 'WARNUNG: Der neue Tarif gilt ab sofort.',
			},
			TODO_MUNDO: 'Alle',
			DISPOSITIVOS: {
				TITULO: 'Registrierte Geräte ',
				NOMBRE: 'Gerätename ',
				FECHA: 'Registrierungsdatum'
			},
			EVENTOS: {
				TITULO: 'Mis eventos',
				EVENTO: 'Evento',
				FECHA: 'Fecha evento',
				DETALLE: 'Detalle evento'

			},
			ENTIDAD: {
				TITULO: 'Körperschaft ',
				LOGO: 'Firmenlogo',
				PRESENTACION: 'Präsentationsbild ',
				ARRASTRA: 'Ziehen oder klicken Sie hier, um Ihr Bild hochzuladen. ',
				SUBIR: 'Bild speichern',
				BORRAR: 'Lösche Bild '
			},
			SWAL: {
				confirmationSwal: 'Daten gespeicherte',
				confirmationSwal_TEXT: 'Die Daten wurden korrekt gespeichert',
				borrarSwal: 'Block löschen',
				borrarSwal_TEXT: 'Möchten Sie diesen Block löschen?',
				borrarSwal_BTN1: 'Ja, löschen',
				borrarSwal_BTN2: 'Stornieren',
				borrarConfirmSwal: 'Richtig gelöschtes Blockieren',
				borrarConfirmSwal_TEXT: 'Die Blockade wurde korrekt gelöscht.',
				cancelarSuscripSwal: 'Abonnement beenden',
				cancelarSuscripSwal_TEXT: 'Sind Sie sicher, Ihr Abonnement zu kündigen? Sie können WFN -Dienste bis zum aktuellen Enddatum Ihres Tarifs fortsetzen. ',
				cancelarSuscripSwal_BTN1: 'Ja Abbrechen',
				cancelarSuscripSwal_BTN2: 'Stornieren',
				cancelarSuscripOkSwal: 'Ordnungsgemäß storniertes Abonnement',
				cancelarSuscripOkSwal_TEXT: 'Das Abonnement wurde ordnungsgemäß storniert. Sie können die Rate aus Ihrem Profil neu aktivieren. ',
				tarifaCambiadaSwal: 'Korrekte Änderung der Rate',
				tarifaCambiadaSwal_TEXT: 'Die Rate hat sich korrekt geändert.',
				tarifaCambiadaSwal_TEXT2: 'Ihr neuer Preis gilt sofort.',
				errorSwal: 'Falsche E-mail',
				errorSwal_TEXT: 'Die eingeführte E -Mail ist bereits im System registriert.',
				errorTelegramSwal: 'Falscher Telegrammcode',
				errorTelegramSwal_TEXT: 'Der eingeführte Validierungscode von Telegram ist falsch. Überprüfen Sie es und stellen Sie es erneut vor. ',
				cambioLocSwal: 'Position korrekt geändert',
				cambioLocSwal_TEXT: 'Der Standort neuer Zinsen wird in 48 Stunden wirksam sein ',
				borrarCambioSwal: 'Richtig gelöschte Veränderung ',
				borrarCambioSwal_TEXT: 'Die korrekt ausgewählte Änderung wurde storniert.',
				borrarDispSwal: 'Gerät löschen ',
				borrarDispSwal_TEXT: 'Möchten Sie dieses Gerät löschen?',
				borrarDispSwal_BTN1: 'Ja, löschen',
				borrarDispSwal_BTN2: 'Stornieren',
				borrarDispConfirmSwal: 'Löschen korrekt',
				borrarDispConfirmSwal_TEXT: 'Das Gerät wurde korrekt gelöscht.'
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Pläne und Preise für ',
			TITULO_FUN2: 'Bestattungsunternehmen ',
			SELECCIONA: 'Wählen Sie den Plan aus, der am besten zu Ihrem Bestattungsunternehmen passt. Sie haben immer die Flexibilität, Ihren Plan hochzuladen oder zu senken. ',
			TITULO_WFP: 'Pläne zu ',
			SELECCIONA_WFP: 'Wählen Sie das geografische Bereich aus, das am besten zu Ihrem Bestattungsunternehmen passt. ',
			SELECCIONA_WFP2: 'Nur in WFP zahlen Sie für die von Ihnen erbrachten Dienstleistungen, 10% des Preises, zu dem jeder Service vergeben wurde. ',
			SELECCIONA_WFN: 'In WFN zahlen Sie auf keinen Fall etwas. ',
			PAGO_ANUAL: 'JÄHRLICHE ZAHLUNG',
			AHORRO: 'Einsparungen 10%',
			PAGO_MENSUAL: 'MONATLICHE BEZAHLUNG',
			POPULAR: 'BEKANNTER',
			PUJA_EN: 'Puja in ',
			OFERTA_PARA: 'Angebot für',
			TARIFA1: 'alle',
			TARIFA2: 'das ganze Land',
			TARIFA3: '5 Gemeinschaften ',
			TARIFA4: 'eine Gemeinschaft',
			TARIFA1_MX: 'Mexiko',
			TARIFA2_MX: 'Mexiko, USA und Kanada ',
			TARIFA3_MX: 'alle',
			MES: 'Monat',
			AÑO: 'Jahr',
			IVA: 'Mehrwertsteuer',
			TAX: 'Steuer',
			OFERTA: 'Startangebot ',
			CONTRATAR: 'MIETEN',
			SELECCIONAR: 'WÄHLEN',
			TEXTO1: 'Zugang zu Informationen zu Tendern, die überall auf der Welt erzeugt werden. ',
			TEXTO2: 'Premium -Zugang zum internationalen monatlichen Bestattungsmagazin WfuneralNews. ',
			TEXTO3: 'Personalisierte Unterstützung unseres Teams. ',
			TEXTO4: 'Warnsystem auf Ihrem Handy oder E -Mail, Angeboten, Angeboten oder anderen relevanten Informationen, die Sie berücksichtigen. ',

			TITULO_ASEG1: 'Preise für ',
			TITULO_ASEG2: 'Versicherung, Unternehmen und Kollektive ',
			TITULO_ASEG3: 'WFN -Versicherung und Kollektive ',
			ASEG1: 'Legen Sie auf unserer Plattform Ihre Dienste oder Bestattungsübertragungen. ',
			ASEG2: 'Holen Sie sich den besten Preis für den Service. ',
			ASEG3: 'Zugang zu unserer Datenbank mit 60.000 Bestattungsunternehmen in 194 Ländern. ',
			ASEG4: 'Segmentierung für Kriterien für Qualität und Exzellenz (ISO, UNE, NPS).',
			ASEG5: 'Informationen über die Rückverfolgbarkeit des vertraglichen Dienstes erhalten. ',
			ASEG6: 'Zugang zu Informationen zu Tendern, die überall auf der Welt erzeugt werden. ',
			ASEG7: 'Premium -Zugang zum internationalen monatlichen Bestattungsmagazin WfuneralNews. ',
			ASEG8: 'Personalisierte Unterstützung unseres Teams. ',
			PAGANOS: 'Páganos zu uns nur wegen dessen, was angeheuert wurde. ',
			PRECIO: 'von dem Preis, zu dem das endgültige Angebot vergeben wurde. ',
			FUNERARIA: 'Sie zahlen direkt an die Beerdigung, den vertraglichen Dienst. ',
			GRATIS: 'FREI'
		},
		SOPORTE: {
			TIPO: {
				VENTA: 'Verkäufe',
				POSTVENTA: 'Post -Sales ',
				ADMINISTRACION: 'Verwaltung',
				SAT: 'Technischer Dienst',
				OTRO: 'Andere'
			},
			MENU: 'WFuneralNet-Unterstützung'
		},
		PETS: {
			CREAR: {
				TITULO:  "Erstellen Sie PETS -Service",
				CLIENTE:  "Kunde",
				CLIENTE_SELECT:  "Wählen Sie einen Kunden aus",
				EXPEDIENTE:  "Dateinummer",
				ORIGEN:  "Herkunft",
				ORIGEN_SELECT:  "Wählen Sie einen Ursprung",
				DATOS_PROP:  "Eigentümerdaten",
				DATOS_MASC:  "Haustierdaten",
				NOMBRE:  "Name",
				APELLIDO1:  "Lastname 1",
				APELLIDO2:  "Nachname 2",
				DNI:  "DNI/NIE/PASAPORTE",
				TELEFONO:  "Telefon",
				EMAIL:  "Email",
				DIRECCION_CONT:  "Kontakt Adresse",
				LOCALIDAD:  "Standort",
				PROVINCIA:  "Provinz",
				CP:  "C.P.",
				NUMERO:  "Nummer",
				PISO:  "Escal./piso/puerta",
				PESO:  "Gewicht",
				TIPO_ANIMAL:  "Tierart",
				CHIP:  "Kein Chip",
				FECHA_FALL:  "Todesdatum",
				LUGAR_FALL:  "Typ Fall.",
				LUGAR_FALL_SELECT:  "Wählen Sie einen Ort aus",
				LUGAR_FALL_TIPO:   {
					DOMICILIO:  "Heim",
					VETERINARIO:  "Tierarzt",
					REFUGIO:  "Unterschlupf",
					OTRO:  "Andere"
				},
				DIRECCION_FALL:  "Tod/Sammlung Adresse",
				INFO_SERV:  "Service Information",
				OBSERVACIONES:  "Beobachtungen",
				SUBMIT:  "Service erstellen",
				SWAL:   {
					confirmationAddSwal:  "Erstellter Dienst",
					confirmationAddSwal_TEXT:  "Referenz:  "
				}
			},
			PRESUPUESTO:   {
				DATOS_CLIENTE:  "Clientdaten",
				SERVICIO:  "Service",
				SERVICIO_BUSCAR:  "Suche nach Service",
				OPCIONAL:  "Optional",
				FIRMA:  "Elektronische Signatur aktivieren?",
				FIRMA_SI:  "Ja",
				FIRMA_NO:  "NEIN",
				LOCALIDAD_FALL:  "Ort des Todes",
				SEL_SERVICIOS:  "Auswahl der Dienstleistungen",
				PERSONALIZAR:  "Paket anpassen",
				RESTAURAR:  "Preise wiederherstellen",
				ADICIONALES:  "Zusätzliche Linien",
				CONCEPTO:  "Konzept",
				IMPUESTO:  "Steuer",
				IMPORTE:  "Menge",
				IMPORTE2:  "(Imp.inc.)",
				ADD_LINEA:  "Zeile hinzufügen",
				SUPLIDOS:  "Lieferungen",
				ADD_SUPLIDO:  "Versorgung hinzufügen",
				NOTAS:  "Zusätzliche Bemerkungen",
				TOTAL:  "Gesamt",
				TOTAL_PAQUETE:  "Ausgewähltes Paket",
				TOTAL_SERVICIOS:  "Zusatzleitungen",
				TOTAL_LINEAS:  "Zusätzliche Linien",
				TOTAL_SUPLIDOS:  "Lieferungen",
				SUBMIT:  "Budget erzeugen",
				RES:   {
					TITULO:  "Budget korrekt generiert",
					TEXTO:  "Das Budget -PDF wurde heruntergeladen. Sie haben auch dasselbe in der Liste der Dienste.",
					CODIGO:  "Budgetcode",
					BTN1:  "Neues Budget - neuer Kunde",
					BTN2:  "Neues Budget - gleicher Kunde",
					EMAIL:  "E -Mail an den Kunden senden",
					ADJUNTOS:  "Stellvertretende Budgets",
					PRESUPUESTO:  "Budget",
					IMPORTE:  "Menge",
					ENVIAR:  "E-MAIL SENDEN",
					ENVIADO:  "E -Mail richtig gesendet",
					EMAIL_TEXT1:  "Guten Morgen",
					EMAIL_TEXT2:  "Guten Tag",
					EMAIL_TEXT3:  "Gute Nacht",
					EMAIL_TEXT4:  "D/ms",
					EMAIL_TEXT5:  "Vielen Dank für Ihren Kontakt mit Borea.",
					EMAIL_TEXT6:  "Während wir telefoniert haben, senden wir unser Budget für den Abschied, den Sie gewählt haben.",
					EMAIL_TEXT7:  "Wir erinnern Sie daran, dass dieses Budget 90 Tage lang gültig ist, wenn Sie sich endgültig dazu entschließen, es zu unterzeichnen. Klicken Sie dazu auf den Signatur-Button und füllen Sie die erforderlichen Felder aus. Unsere Telefonnummer ist 683 67 39 57.",
					EMAIL_TEXT8:  "Wir erinnern Sie daran, dass dieses Budget 90 Tage lang gültig ist, wenn Sie sich endgültig dazu entschließen, es zu unterzeichnen. Um es an uns zu senden, können Sie denselben Weg (E-Mail) verwenden. Unsere Telefonnummer ist 683 67 39 57.",
					EMAIL_TEXT9:  "Aufrichtig",
					EMAIL_TEXT10:  "BOREA",
				},
				MODAL_PERS: {
					TITULO:  "Paketanpassung",
					NOMBRE:  "Name (Zeile)",
					DETALLE:  "Detail",
					IMPORTE:  "Betrag Services",
					IMPORTE2:  "(Imp.inc.)",
					CERRAR:  "Schließen",
				},
				MODAL_SERV: {
					TITULO:  "Wählen Sie einen Dienst aus",
					REFERENCIA:  "Referenz",
					FECHA:  "Datum",
					ORIGEN:  "Herkunft",
					SERVICIO:  "Service",
					LOCALIZACION:  "Standort",
					NOMBRE:  "Tiername",
					SELECCIONAR:  "Sealcut"
				},
				SWAL: {
					errorSwal:  "Es tut uns leid",
					errorSwal_TEXT:  "Die eingeführten Standortraten sind nicht verfügbar.",
					locSwal:  "Fehler",
					locSwal_TEXT:  "Sie müssen den Ort eingeben, um die Dienste zu visualisieren.",
					emailSwal:  "Fehler",
					emailSwal_TEXT:  "Sie müssen den Text <b> [Ihren Namen] </b> im unteren Bereich der E -Mail enthalten."
				}
			},
			RAZONES: {
				NOCONT:  "Blei nicht kontaktabel",
				FICTICIO:  "Fiktive Daten",
				INTERES:  "Ohne Interesse",
				SEGURO:  "Blei hat Todesversicherung",
				FANTASMA:  "Ghost (Kunde hört auf zu antworten)",
				COM_PRO:  "Wettbewerber - Servicevorschlag ist nicht zufrieden",
				COM_PRE:  "Wettbewerber - hoher Preis",
				COM_LEN:  "Konkurrent - langsam zu reagieren",
				COM_ASE:  "Konkurrent - Berater kommt persönlich",
				PP:  "Versicherungstod (PP) angeboten und verloren",
				PU:  "Single Cousin (PU) angeboten und verloren",
				SIN_GEO:  "Ohne die Möglichkeit des Dienstes - Mangel an geografischer Berichterstattung",
				SIN_SERV:  "Ohne die Möglichkeit des Dienstes - Mangel an Service",
				SIN_PLAN:  "Ohne die Möglichkeit des Dienstes - will Planung",
				ERROR:  "Fehlerhafter Anruf",
				OTRO:  "Ein anderer Grund",
			},
			TIPOS: {
				PERRO: "Hund",
				GATO: "Katze",
				OTROS: "Andere",
			},
			ORIGEN: {
				LLAMADA:  "Anruf",
				SOL_LLAMADA:  "Sonne,",
				FORMULARIO:  "Bilden",
				WHATSAPP:  "WhatsApp",
				CHAT:  "Plaudern",
				NO:  "Nicht verfügbar",
			},
			ORIGEN_MAYUS: {
				LLAMADA:  "ANRUF",
				SOL_LLAMADA:  "Sun. Call",
				FORMULARIO:  "BILDEN",
				WHATSAPP:  "WhatsApp",
				CHAT:  "Plaudern",
			},
			ESTADO: {
				PENDIENTE:  "Neuer Blei",
				RECHAZADA:  "Serv. Lost",
				PRESUPUESTAR:  "Pend. Budget",
				LLAMADA:  "Kundenanruf",
				ACEPTADA:  "Vorup. Signiert",
				ASIGNADA:  "Kundenvieh",
				COMPLETADA:  "Serv. Ausgeführt"
			},
			SERVICIOS: {
				CREMA_IND:  "Individuelle Einäscherung",
				CREMA_COL:  "Kollektive Einäscherung",
				ADICIONALES:  "Zusatzleitungen",
				EUTANASIA:  "Euthanasie und Low Chip",
				PSICO:  "Psychologische Aufmerksamkeit",
				VELATORIO:  "Verbrennungsanlage Wake",
				URGENCIA_INC:  "Dringlichkeit verbrennen",
				HUELLA:  "Fußabdruck des Haustieres",
				URGENCIA_VET:  "Tierarzt",
				REC_DESCOMP: "Sammlung in Zersetzung",
			},
			FILTROS: {
				REFERENCIA:  "Referenz",
				FECHA_DESDE:  "Stammen aus",
				FECHA_HASTA:  "Datum bis",
				LOCALIZACION:  "Standort",
				ORIGEN:  "Herkunft",
				TODOS:  "ALLE",
				PROVEEDOR:  "Anbieter",
				ESTADO:  "Zustand",
				OCULTO:  "Verborgenes zeigen",
				REINICIAR:  "Filter neu starten"
			},
			LISTADO: {
				TITULO:  "Liste der Dienstleistungen",
				ACTUALIZAR:  "Aktualisieren",
				VER:  "Siehe Filter",
				CERRAR:  "Enge Filter",
				EXPORTAR:  "Export",
				NO:  "Es gibt keine Dienste",
				CONTACTO:  "Kontaktname",
				FALLECIDO:  "Todesname",
				MASCOTA:  "Haustiername",
				NO_DISP:  "Nicht verfügbar",
				LOC_FALL:  "Verstorbener Ort",
				LOC_MAS:  "Haustierort",
				LOC_CONT:  "Kontaktort",
				LOC_IP:  "IP -Speicherort",
				LOC:  "Standort",
				SERVICIO:  "Service",
				PENDIENTE:  "Ohrring",
				TRASLADO:  "TRANSFER",
				SERV_IND:  "Crem.indiv.",
				SERV_COL:  "Crem.colec.",
				INFO_CLI:  "Kundeninformation",
				INFO_CONT:  "Kontaktinformationen",
				INFO_FALL:  "Daten des Verstorbenen",
				INFO_MASC:  "Haustierdaten",
				CREADOR:  "Schöpfer",
				DETALLES_VISIT:  "Details besuchen",
				VISIT_URL:  "URL",
				VISIT_IP_LOC:  "Ip loc.",
				VISIT_DISP:  "Disp.",
				VISIT_PC:  "PC",
				VISIT_MOVIL:  "Handy, Mobiltelefon",
				VISIT_TABLET:  "Tablette",
				VISIT_ORIGEN:  "Herkunft",
				VISIT_MEDIO:  "Halb",
				VISIT_CAMP:  "Glocke",
				VISIT_KW:  "KW",
				FUSION:  "Sicherungsdienste",
				EDICION:  "Auflage",
				COMENTARIOS:  "Kommentare",
				DOCUMENTOS:  "Unterlagen",
				COM_NUEVO:  "Neuer Kommentar",
				COM_PL:  "Kommentar des Dienstes",
				COM_ENVIAR:  "Kommentar senden",
				COM_LISTADO:  "Listenkommentare",
				COM_NO:  "Es gibt keine Kommentare",
				DOC_NUEVO:  "Neues Dokument",
				DOC_DROP:  "Drücken Sie das Dokument hier",
				DOC_NOMBRE:  "Dokumentname",
				DOC_LISTADO:  "Dokumente auflisten",
				DOC_NO:  "Es gibt keine Dokumente",
				DOC_BORRAR:  "Löschen",
				DOC_SUBIR:  "Dokumente hochladen",
				PRES_NUEVO:  "Neues Budget",
				PRES_LISTADO:  "Budgets auflisten",
				PRES_NO:  "Es gibt keine Budgets",
				ACEPTAR_PREGUNTA:  "Akzeptieren Sie den Service?",
				ACEPTAR_LOAD:  "Einen Moment...",
				ACEPTAR_SI:  "AKZEPTIEREN",
				ACEPTAR_NO:  "ABFALL",
				RECH1:  "Ablehnte Service",
				RECH2:  "Lost Service",
				RECH_MOTIVO:  "Grund für verlorenes Schließen",
				RECH_SEL:  "Wähle einen Grund",
				RECH_REACTIVAR:  "Reaktivieren Sie den Service",
				RECH_OCULTAR:  "Service verbergen",
				RECH_MOSTRAR:  "Show Service",
				RECH_FUSIONAR:  "Merge Service",
				RECH_FUSIONANDO:  "Schmelzdienste ...",
				SERV_DETALLE:  "Servicedetails",
				SERV_TIPO:  "Art der Leistung",
				SERV_PROV:  "Dienstleister",
				SERV_PROV_SEL:  "Einen Lieferanten auswählen",
				SERV_PRESTADO:  "Erbrachte Dienstleistung",
				FIRM_CONTROL:  "Anwendungssteuerung",
				FIRM_LOADING:  "Einen Moment...",
				FIRM_LLAMADA:  "Pend.Leadede Client",
				FIRM_PRES:  "Pend. Budget",
				FIRM_FIRMADO:  "Signiertes Budget",
				FIRM_CANCELAR:  "Service abbrechen",
				COMIEN_TRAZA:  "Service Rückverfolgbarkeit",
				COMIEN_LOADING:  "Einen Moment...",
				COMIEN_SI:  "Dienst starten",
				COMIEN_NO:  "Service abbrechen",
				TRAZAS: {
					TITULO:  "Rückverfolgbarkeit",
					TEXT1:  "Das Zentrum hat die Familie kontaktiert.",
					TEXT2:  "Daten und Anforderungen der Euthanasie",
					TEXT3:  "Euthanasie wurde durchgeführt.",
					TEXT4:  "Der Verstorbene wurde gesammelt oder geliefert.",
					TEXT5:  "Der Verstorbene hat das Zentrum erreicht.",
					TEXT6:  "Datum und Ort der Einäscherung/Abschied",
					TEXT7:  "Wake gemacht",
					TEXT8:  "Einäscherung durchgeführt",
					TEXT9:  "Asche und Dokumentation geliefert",
					TEXT10:  "Dokumentation geliefert",
					COMPLETADO:  "Gefüllt"
				},
				ESTADO_PEND:  "Bis zur Akzeptanz des Dienstes",
				ESTADO_RECH:  "Ablehnte Service",
				ESTADO_ASIG:  "Bis zum Dienst zu Allokation",
				CARGAR:  "Mehr Dienste laden",
				MODAL_FUSIONAR: {
					TITULO:  "Wählen Sie einen Dienst zum Zusammenführen (Ziel)",
					REFERENCIA:  "Referenz",
					FECHA:  "Datum",
					ORIGEN:  "Herkunft",
					SERVICIO:  "Service",
					LOCALIZACION:  "Standort",
					NOMBRE:  "Haustiername",
					ESTADO:  "Zustand",
					SELECCIONAR:  "Wählen"
				}
			}
		}
	}
};
