// Spain
export const locale = {
	lang: 'fr',
	data: {
		MENU: {
			SEGUIMIENTO: 'Surveillance<br>de mes offres',
			SEGUIMIENTO2: 'Dans<br>exécution',
			EJECUCION: 'En action',
			EJECUCION2: 'En action',
			TODAS: 'Voir tous<br>les offres',
			TODAS2: 'Voir toutes les offres',
			CREAR: 'Créer<br>Tender',
			CREAR2: 'Créer un tendre',
			CREAR_NAC: 'Service / Transfert national',
			CREAR_INT: 'Transfert international',
			CREAR_SERV: 'Créer<br>Service',
			CREAR2_SERV: 'Créer un service',
			EXPLORAR: 'Explorez mes<br offres',
			EXPLORAR2: 'Explorez mes offres',
			EXPLORAR_SERV: 'Explorez mes<br>services',
			EXPLORAR2_SERV: 'Explorez mes services',
			PENDIENTES: 'en attente<br>pour attribuer',
			PENDIENTES2: 'En attente de récompense',
			TODAS_LIC: 'Voir toutes<br>les offres',
			TODAS_LIC2: 'Voir toutes les offres',
			CONTRATAR: 'EMBAUCHER',
			PERFIL: 'Mon profil',
			LOGOUT: 'Fermer la session',
			MODO_WFN: 'WFN<br>tendes',
			MODO_WFN2: 'Tendeurs WFN',
			MODO_INT: 'WFN<br>transferts internationaux',
			MODO_INT2: 'WFN International Transferts',
			MODO_WFP: 'WFP<br>individus',
			MODO_WFP2: 'WFP particulier',
			MODO_PLANS: 'WFP<br>plans funéraires',
			MODO_PLANS2: 'Plans funéraires du PAM',
			MODO_PETS: 'WFP<br>animaux de compagnie',
			MODO_PETS2: 'WFP Petts',

		},
		FOOTER: {
			CONTACTA: 'Contactez-nous',
			SEDES: 'Siège social à: Madrid, Mexique, Lisbonne et Colonia.',
			AVISO_LEGAL: 'Avis juridique',
			POLITICA: 'Politique de confidentialité'
		},
		HOME: {
			BIENVENIDA: "Bienvenue dans les appels d'offres WFN",
			BIENVENIDA2: 'Bienvenue dans la région des offres WFP',
			BIENVENIDA3: 'Bienvenue dans le domaine des applications Borea',
			INVITADO1: 'Vous accédez à notre plate-forme en mode invité.',
			INVITADO2: "Cela signifie que vous pouvez connaître les appels d'offres en cours, mais pas pour les enchéris ou en publier de nouveaux.",
			DEMO: "Vous accédez à notre plate-forme de démonstration. Cela signifie que vous pouvez essayer toutes ses fonctionnalités avec des exemples fictifs d'offres.",
			CONTRATAR: 'Embaucher maintenant',
			PAGO: "Paiement d'abonnement en attente.",
			PAGO2: "Vous ne nous avez pas encore fourni vos données de paiement d'abonnement. Appuyez sur le bouton pour accéder à la plate-forme de paiement sûre.",
			PLATAFORMA_PAGO: 'PLATEFORME DE PAIEMENT SÉCURISÉE'
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Commencer la session',
				BUTTON: 'Commencer la session',
				BIENVENIDA1: "Bienvenue dans le processus d'embauche de votre taux WFN {{tarifa}}",
				BIENVENIDA1_SUB: 'Connectez-vous pour associer votre compte à ce taux.',
				BIENVENIDA2: "Bienvenue dans le processus d'embauche WFN",
				BIENVENIDA2_SUB: 'Connectez-vous pour continuer.',
				FORGOT_BUTTON: 'Vous avez oublié votre mot de passe?',
				REGISTER: "Vous n'avez pas de compte?",
				REGISTER_BUTTON: "S'inscrire",
				VOLVER: 'Retour à la connexion'
			},
			INPUT: {
				EMAIL: 'E-mail',
				PASSWORD: 'Mot de passe',
				CONFIRM_PASSWORD: 'Confirmer mot de passe'
			},
			FORGOT: {
				TITLE: 'Récupérer le mot de passe',
				DESC: "Ensuite, entrez le code de l'e-mail reçu et le nouveau mot de passe",
				SUCCESS: "Votre mot de passe a été correctement récupéré. Connectez-vous avec vos nouvelles informations d'identification pour continuer.",
				INCORRECTO: "Le code de récupération introduit n'est pas correct.",
				INVALID: "Ce lien de récupération n'est pas valide ou a déjà été utilisé.",
				LOGIN: 'COMMENCER LA SESSION',
				CODIGO: 'Code de récupération (4 chiffres)',
				BUTTON: 'RÉCUPÉRER LE MOT DE PASSE',
				EMAIL: "Un courrier de récupération a été envoyé à l'e-mail introduit s'il est enregistré dans le système. Suivez ses instructions pour continuer."
			},
			REGISTER: {
				TITLE: "S'inscrire",
				BIENVENIDA1: "Bienvenue dans le processus d'embauche de votre taux WFN {{tarifa}}",
				BIENVENIDA1_SUB: "Tout d'abord, nous créerons un compte associé à votre taux.",
				BIENVENIDA2: "Bienvenue dans le processus d'embauche WFN",
				BIENVENIDA2_SUB: "Tout d'abord, nous créerons un compte.",
				EMAIL_CORP: "Seulement les e-mails d'entreprise (par exemple @votre-entreprise.com) sont acceptés",
				PAIS: 'Pays',
				PAIS_SELECT: 'Choisissez le pays ...',
				PERTENEZCO: "J'appartiens à",
				PERTENEZCO_SELECT: 'Sélectionnez le secteur ...',
				SECTOR1: 'Funérailles',
				SECTOR2: "Les compagnies d'assurance",
				SECTOR3: 'Les fonds de pension',
				SECTOR4: 'Banque sûre',
				SECTOR5: 'Sociétés de prévision et de pré-économie',
				SECTOR6: 'Ambassades',
				SECTOR7: 'Groupes avec couverture funéraire',
				SECTOR8: 'Autres',
				BUTTON: 'Registre',
				LOGIN: 'Avez-vous déjà un compte?',
				LOGIN_BUTTON: 'Se connecter',
				SUCCESS: 'Enregistrement correct',
				SUCCESS_SUB: 'Nous vous souhaitons la bienvenue au World Funeral Net!',
				SUCCESS_MAIL:"Nous avons envoyé un e-mail de confirmation à l'adresse d'inscription. Veuillez saisir le code ou cliquez sur le lien inclus dans le courrier pour accéder à toutes les fonctionnalités de la plate-forme.",
				SUCCESS_MAIL2: "Nous avons envoyé un e-mail de confirmation à l'adresse d'inscription. Veuillez saisir le code ou cliquez sur le lien inclus dans le courrier pour poursuivre votre tarif.",
				ERROR_CODE: "Le code introduit n'est pas correct.",
				VALIDAR_BUTTON: 'Valider le compte',
				GRACIAS: 'Merci beaucoup pour votre confiance.'
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: "Valider votre compte, un instant s'il vous plaît ...",
				SUCCESS: 'Compte validé',
				SUCCESS_DESC: 'Votre compte a été validé correctement. Nous continuerons le record en quelques secondes ...',
				ERROR: 'Erreur de validation',
				ERROR_DESC: "Votre lien de validation n'est pas correct, vérifiez votre e-mail et utilisez le dernier lien généré.",
			},
			VALIDATION: {
				INVALID: "{{name}} n'est pas valide",
				REQUIRED: '{{name}} doit être introduit',
				MIN_LENGTH: '{{name}} La longueur minimale est {{min}}',
				AGREEMENT_REQUIRED: 'Il faut accepter les termes et conditions',
				NOT_FOUND: "Le {{name}} fissuré n'est pas trouvé",
				INVALID_LOGIN: 'Les données entrées ne sont pas correctes. Essayer à nouveau.',
				INACTIVE: 'Votre compte est inactif. Vérifiez votre e-mail et cliquez sur le lien de validation.',
				REQUIRED_FIELD: 'Vous devez remplir ce champ',
				MIN_LENGTH_FIELD: 'La taille minimale de ce champ est:',
				MAX_LENGTH_FIELD: 'La taille maximale de ce champ est:',
				INVALID_FIELD: "Ce champ n'est pas valide",
				MATCH: 'Le mot de passe et la confirmation ne correspondent pas',
				INVALID_REGISTER: "L'e-mail introduit est déjà enregistré. Essayez à nouveau ou connectez-vous."
			}
		},
		DEMO: {
			LOADING: "Préparer la plate-forme, un instant s'il vous plaît ...",
			BIENVENIDA: 'Nous accueillons WFN',
			SECTOR: 'Sélectionnez le secteur auquel vous appartenez pour accéder à la plate-forme de démonstration.',
			FUNERARIA: 'JE SUIS UN OPÉRATEUR FUNÉRAIRE',
			ASEGURADORA: 'JE SUIS UNE ASSURANCE, UNE ENTREPRISE OU UN COLLECTIF',
			HOME: {
				STEP1: 'Bienvenue à WFN! Ceci est la plate-forme de démonstration. Nous découvrirons les fonctionnalités de la plate-forme, puis vous pourrez interagir avec elle et effectuer les tests dont vous avez besoin.',
				STEP2: 'Ceci est le menu principal. De là, vous pouvez accéder aux différentes pages disponibles.',
				STEP3: 'Ici, vous pouvez surveiller les offres dans lesquelles vous avez participé.',
				STEP4: 'Voici les offres qui vous sont attribuées. En eux, vous pouvez consulter les informations gagnantes et remplir la traçabilité.',
				STEP5: "Enfin, la liste complète des appels d'offres disponibles est indiquée ici. Allons sur cette page pour les voir plus en détail.",
				STEP6: "Vous pouvez offrir vos offres dans ce menu. Vous insérez ses détails et serez publié dans la liste. Vous pouvez l'essayer à la fin de cette démonstration.",
				STEP7: 'Une fois créé, vous trouverez toutes vos offres sur cette page.',
				STEP8: "Lorsqu'une offre est terminée, vous pouvez trouver tous ceux qui sont en attente pour attribuer un salon funéraire sur cet écran.",
				STEP9: 'Voici les offres que vous avez déjà affectées à un salon funéraire et que vous êtes en exécution. En eux, vous pouvez consulter les informations du salon funéraire et voir leur traçabilité.',
				STEP10: "Enfin, la liste complète des appels d'offres disponibles est indiquée ici. Allons sur cette page pour les voir plus en détail."
			},
			LICIT: {
				STEP1: "Bienvenue à WFN! Ceci est la plate-forme de démonstration. Nous découvrirons les fonctionnalités de la plate-forme, puis vous pourrez interagir avec elle et effectuer les tests dont vous avez besoin.",
				STEP2: "Chaque 'carte' que vous voyez est un ensemble d'appel d'offres par un assureur ou un collectif. Vous y trouverez toutes les informations et les détails des services, tels que l'emplacement, le temps restant, le montant actuel, l'état du service, etc.",
				STEP3: "Dans la zone supérieure, vous trouverez la référence des enchères, à côté de la date de création. De même, vous pouvez les éliminer à tout moment (les participants seront informés) et les modifier lorsqu'ils n'y ont pas encore participé. Si l'offre est déserte, vous pouvez également la modifier une fois terminée.",
				STEP4: "Dans la zone supérieure, vous trouverez la référence que le système a attribué à l'appel d'offres, à côté de la date de création.",
				STEP5: "Ce sont les services à fournir dans une offre. En cliquant sur eux, vous pouvez voir leurs détails généraux. Les services avec l'icône <b style = <b style='color: red'>+</b> indique que le soumissionnaire a inclus un détail spécifique",
				STEP6: "L'emplacement dans lequel le service ou l'origine et la destination dans les transferts seront indiqués ci-dessous",
				STEP7: "C'est le temps restant pour la fin de l'appel d'offres et donc le temps de soumissionner.",
				STEP8: "Dans cet espace, vous observerez l'état actuel de l'appel d'offres. Cela peut être ouvert, en attente d'attribution, en attente d'exécution, terminé, fermé ou déserté.",
				STEP9: "Enfin, vous trouverez le montant actuel de l'appel d'offres et vous pouvez accéder au détail de vos offres ou enregistrés.",
				STEP10: "Une fois qu'un appel d'offres a été attribué, vous pouvez voir les progrès de la traçabilité et les détails du salon funéraire récompensé.",
				STEP11: "De plus, dans le menu suivant, vous pouvez voir le détail de la traçabilité que les funérailles rempliront.",
				STEP12: "Dans cet espace, vous observerez l'état actuel de l'appel d'offres. Cela peut être ouvert (gagner / perdre / enregistré), en cours, en exécution, terminé ou déserté.",
				STEP13: "Enfin, vous trouverez le montant actuel de l'appel d'offres et vous pourrez vous en enchérir ou vous inscrire.",
				STEP14: "Une fois que vous avez attribué un appel d'offres, vous pouvez voir les détails de la société de récompense.",
				STEP15: "Si l'appel d'offres est en exécution, vous pouvez remplir les informations de traçabilité du service.",
				STEP16: "Vous pouvez personnaliser les cartes à tout moment avec les différents filtres disponibles.",
				STEP17: "C'est tout. N'oubliez pas que vous êtes sur la plate-forme de démonstration. Vous pouvez interagir avec les appels d'offres disponibles, en créer de nouveaux et effectuer tous les tests dont vous avez besoin.",
				STEP18: "C'est tout. N'oubliez pas que vous êtes sur la plate-forme de démonstration. Vous pouvez interagir avec les appels d'offres disponibles, offrir ou vous inscrire avec eux et effectuer tous les tests dont vous avez besoin.",
			}
		},
		CONFIRMACION: {
			LOADING: "Traitement de l'embauche ...",
			SUCCESS: "Embauche terminée",
			SUCCESS_SUB: "Vous serez redirigé vers la plate-forme en quelques secondes ...",
			PENDING: "Confirmation du compte en attente",
			PENDING_SUB: "Avant de continuer, votre compte doit être confirmé par l'organisation. L'organisation se réserve le droit d'admission, nous évaluerons son entrée dans 24 à 48 h et pourrait mettre fin au processus d'embauche.",
			PAGO: "Accéder à la confirmation de paiement ...",
		},
		PAGO: {
			LOADING: "Accéder à une plate-forme sûre ...",
			RESULT_LOADING: "Chargement des données d'abonnement ...",
			METODO: "Sélection du mode de paiement",
			METODO_SUB: "Sélectionnez votre mode de paiement pour terminer l'activation de votre compte.",
			METODO_TARJETA: 'JE PAIE AVEC UNE CARTE DE CRÉDIT',
			METODO_SEPA: 'PAIEMENT AVEC DOMICILE BANCAIRE (SEPA)',
			SEPA_TITLE: 'Paiement avec domicile bancaire - SEPA',
			SEPA_NOMBRE: "Nom de la définition",
			SEPA_IBAN: "Numéro de compte (IBAN)",
			SEPA_CODIGO: "Code promotionnel",
			SEPA_ERRORCOD: "Le code introduit n'est pas correct",
			SEPA_BUTTON: "Abonnez-vous à wfuneralnet",
			SEPA_LOADING: "Données en cours ...",
			SEPA_LEGAL: "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez WfuneralNet et Stripe, notre fournisseur de services de paiement, pour envoyer des instructions à votre banque pour charger le montant de votre compte et votre banque pour charger le montant de votre compte conformément à ces instructions. Dans le cadre de vos droits, vous avez le droit de rembourser par votre banque en fonction des termes et conditions de votre accord avec votre banque. Vos droits sont expliqués dans un communiqué que vous pouvez obtenir de votre banque. Vous acceptez de recevoir des notifications de frais futurs jusqu'à 2 jours avant qu'ils ne se produisent.",
			SUCCESS: "Votre abonnement a été activé correctement.",
			SUCCESS_SUB: "Vous pouvez commencer à utiliser la plate-forme avec toutes ses fonctionnalités. Cliquez sur le bouton suivant pour accéder au démarrage.",
			SUCCESS_BUTTON: 'COMMENCEZ À UTILISER LE WORLD FUNERAL NET',
			CANCEL: "Vous avez annulé le processus d'abonnement.",
			CANCEL_SUB: "Si une erreur s'est produite ou si vous souhaitez réessayer, cliquez sur le bouton Suivant pour accéder à la plate-forme sûre.",
			CANCEL_BUTTON: 'CONTINUEZ À UNE PLATE-FORME DE PAIEMENT SÉCURISÉE',
			WFP: "Aucun poste WFP ne sera fait jusqu'à ce que le service soit effectué. Vous paierez 10% du prix auquel l'appel d'offres a été attribué."
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra à votre mesure',
				INVITADO: 'Les filtres sont désactivés dans le compte invité.',
				CONTRATAR: 'Embaucher',
				REFERENCIA: 'Référence',
				SERVICIO: 'Service',
				LOC_ORIGEN: "Emplacement d'origine",
				LOC_DESTINO: 'Lieu de destination',
				TODOS: 'Tous',
				PAIS: 'Pays',
				COMUNIDAD: 'Communauté autonome',
				ESTADO: 'État',
				FECHA_DESDE: 'Dater de',
				FECHA_HASTA: "Date jusqu'à",
				PRECIO_MIN: 'Prix ​​min.',
				PRECIO_MAX: 'Prix ​​maximum.',
				ESTADO_LIC: 'État tendre',
				ESTADOS: {
					PENDIENTE_INI: 'Commencer en attente',
					ABIERTA: 'Ouvrir',
					PENDIENTE_ADJ: 'Prix ​​en attente',
					ADJ_TRAM: 'Prix. en cours',
					ADJ_PEN: 'Prix. et en attente',
					EJEC: 'En action',
					COMPLETADA: 'Complété',
					CERRADA: 'Fermé',
					DESIERTA: 'Déserté'
				},
				REESTABLECER: 'Filtres réestibles'
			},
			ORDEN: {
				ESTADO: "Ordonnance en offrant l'état",
				ESTADO_ABIERTAS: "Montrez d'abord ouvert",
				ESTADO_PENDIENTE: "Montrer d'abord en attente",
				ESTADO_ADJUD: "Spectacle d'abord attribué",
				FECHA: 'Commande par date de début',
				FECHA_ASC: 'Vers le haut,',
				FECHA_DESC: 'Chute',
				TIEMPO: "Commander du temps jusqu'à l'achèvement",
				TIEMPO_ASC: 'Vers le haut,',
				TIEMPO_DESC: 'Chute'
			},
			LOADING: "Chargement des appels d'offres ...",
			LOADING_AD: 'Services de chargement ...',
			NOHAY: "Il n'y a pas d'offres",
			CARD: {
				ORIGEN: 'Origine',
				DESTINO: 'Destination',
				TRASLADO: 'Transfert',
				TRASLADO_INT: 'Int.',
				LOCALIZ: 'Emplacement',
				LOC_WFP1: 'Emplacement décédé',
				LOC_WFP2: 'Emplacement tanatoire',
				SIN_PRECIO: 'En cours',
				PUJA: 'offre',
				OFERTA: 'offre',
				PUJAR: 'enchérir',
				OFERTAR: 'offre',
				LA: 'La',
				TIME: 'Temps restant pour',
				COMIENZA: 'Commence à',
				VER_ASEG: "voir l'assurance",
				VER_FUN: 'voir funérailles',
				VER_PART: 'Voir en particulier',
				VER_DETALLES: 'voir les détails',
				ESTADOS: {
					PENDIENTE_INI: 'Commencer en attente',
					ABIERTA: 'Ouvrir',
					INSCRITO: 'Signé',
					GANANDO: 'Gagnant',
					PERDIENDO: 'Perdant',
					CONTU: 'avec ton',
					PUJA: 'offre',
					OFERTA: 'offre',
					DE: 'de',
					PENDIENTE_ADJ: "Boucle d'oreille",
					PENDIENTE_ADJ2: 'prix',
					CERRADA: 'Fermé',
					ADJ_TRAM: "Attribuer en cours",
					ADJ_PEN: "Récompensé et",
					ADJ_PEN2: 'Exécution en attente',
					ADJ_PEN3: 'Pendient de confirmation',
					ADJ_PEN4: "rejeté par les funérailles",
					PEND_ACEPT: 'En attente de confirmation',
					EJEC: 'En action',
					COMPLETADA: 'Complété',
					NO_ADJ: 'Non récompensé',
					RECHAZADA: 'Rejeté',
					DESIERTA: 'Déserté'
				},
				PUJA_SALIDA: 'Puja Departas',
				OFERTA_SALIDA: 'Offre de sortie',
				PUJA_ACTUAL: 'Bid actuel',
				OFERTA_ACTUAL: 'Bid actuel',
				PUJA_GANADORA: 'Enchère gagnante',
				OFERTA_GANADORA: 'Offre gagnante',
				PUJA_DESIERTA: 'Bid déserte',
				OFERTA_DESIERTA: 'Offre de désert',
				PUJA_ADJUDIC: 'Puja privé.',
				OFERTA_ADJUDIC: 'Offre de récompense.',
				PRECIO_FIJO: 'Prix fixe',
				ASIG_DIRECTA: 'Attribuer. direct',
				VER_PUJAS: 'VOIR LES OFFRES',
				VER_OFERTAS: 'VOIR LES OFFRES',
				INSCRITOS: 'INSCRIT',
				ADJUDICAR: 'ATTRIBUER',
				TRAZABILIDAD: 'TRAÇABILITÉ',
				INSCRÍBETE: 'REGISTRE',
				ACEPTAR: 'ACCEPTER',
				RECHAZAR: 'DÉCLIN',
				PUJAR2: 'ENCHÉRIR',
				OFERTAR2: 'OFFRE',
				VOLVER_PUJAR: 'REBONDIR',
				VOLVER_OFERTAR: 'ENCHÉRIR À NOUVEAU',
				IVA_INC: 'TVA non incluse',
				HIST: {
					HISTORICO_PUJAS: "Historique des offres",
					HISTORICO_OFERTAS: "Historique des offres",
					HISTORICO_INSC: "Historique de enregistré",
					IMPORTE_SALIDA: 'Montant de sortie',
					ACTUALIZAR: 'mise à jour',
					TOTAL: 'Total',
					FECHA: 'Date',
					USUARIO: 'Utilisateur',
					IMPORTE: 'Montant',
					DESIERTA: 'Desert Bidding',
					CREADOR: 'CRÉATEUR',
				},
				CERRAR: 'cerrar',
				ASIG: {
					NOMBRE: 'Nom',
					LICENCIA: 'Licence',
					DOMICILIO: 'Maison',
					TELEFONO: 'Téléphone',
					EMAIL: 'E-mail',
					WEB: 'Page Web',
					CERTIFICACIONES: 'Certifications',
					PUNTUACIONES: 'Scores',
					IMPORTE: "Montant d'appel d'offres",
					ADJUDICAR: 'À attribuer',
					EXCLUIR: 'EXCLURE',
					EXCLUIR_TODAS: 'Exclure tout',
					BLOQUEAR: 'Block Prov.',
					VER_DETALLES: 'voir les détails',
					CERRAR_DETALLES: 'les détails fermés',
					DETALLE_ASIG1: "L'assureur se réserve le droit d'attribuer une valorisation de la qualité des services offerts par le salon funéraire.",
					DETALLE_ASIG11: "L'individu se réserve le droit d'arrivée valorisant la qualité des services offerts par les funérailles.",
					DETALLE_ASIG2: 'Il est important que vous complétiez votre profil avec des scores et des certificats de qualité.',
					DETALLE_ASIG_BTN: 'Profil complet',
					REFERENCIA: "Référence d'appel d'offres. Wfn",
					REFERENCIA2: "Référence d'appel d'offres. créateur",
					SIGUIENTES_PUJAS: 'Suivant les offres',
					SIGUIENTES_OFERTAS: 'Les offres suivant',
					FUNERARIA: 'Bidder Funeral',
					PARTICULAR: "Informations sur l'individu"
				},
				PUJAS: {
					PUJA_RAPIDA: 'Bid rapide',
					OFERTA_RAPIDA: 'Offre rapide',
					PUJA_MANUAL: 'ou introduire manuellement votre offre',
					OFERTA_MANUAL: 'ou présenter manuellement votre offre',
					PUJA_WFP: 'Entrez votre offre',
					OFERTA_WFP: 'Entrez votre offre',
					PUJAR: 'Enchérir',
					OFERTAR: 'Offre',
					AVISO: 'Avertissement',
					PRORROGA3_PUJA: "L'appel d'offres sera prolongé en 3 minutes lors de la soumission pendant la dernière minute",
					PRORROGA3_OFERTA: "L'appel d'offres sera prolongé en 3 minutes lors de l'offre pendant la dernière minute",
					PRORROGA1_PUJA: "L'appel d'offres sera prolongé en 1 minute lors de la candidature au cours de la dernière minute",
					PRORROGA1_OFERTA: "L'appel d'offres sera prolongé en 1 minute lors de l'offre pendant la dernière minute",
					PROCESO_PUJA: "Son offre est en cours de traitement. Un moment s'il vous plait...",
					PROCESO_OFERTA: "Votre offre est en cours de traitement. Un moment s'il vous plait...",
					HISTORICO_PUJAS: 'Voir les pujas historiques',
					HISTORICO_OFERTAS: 'Voir les offres historiques',
					FUNERARIA: 'Sélectionnez les funérailles',
					FUNERARIA2: 'Maison funéraire sélectionnée'
				},
				TRAZA: {
					INCIDENCIA: 'Incidence dans le service',
					VALIDAR: 'VALIDER',
					CANCELAR: 'ANNULER',
					FECHA: 'Date',
					LUGAR: 'Lieu'
				},
				SERVICIO: { //NUEVO WFP
					TIPO_TRASLADO: 'Type Transfer',
					CARRETERA: "Autoroute point à point",
					AEROPUERTO: 'Aéroport',
					INCLUIDOS: 'Services inclus',
					TIPO_COMPLETO: 'Type serv. Complet',
					CREMACION: 'Crémation + urn',
					DESTFIN: 'Inhumation',
					DETALLE_ESP: 'Détail de service spécifique',
					ESPEC_GEN: 'Spécifications générales de service',
					TRASLADO1: 'La mort et la destination finale (inhumation ou incinération) se produisent à différents endroits.',
					TRASLADO2: 'Éléments et services habituels du transfert:',
					TRASLADO3: "Arche transférée : Dans les cas où le Règlement de Police Sanitaire Mortuaire (RPSM) l'exige, il doit être en outre pourvu d'un dispositif de filtration de l'air ou d'un autre pour équilibrer la pression interne et externe.",
					TRASLADO4: 'Le fret aérien dans votre cas, KMS. Fabriqué par la route et les procédures et procédures spécifiques.',
					WFP_TIPO: 'Service de typologie',
					WFP_TIPO_CREMA: 'Incinération',
					WFP_TIPO_INHUM: 'Inhumation',
					WFP_CEREM: 'Cérémonie',
					WFP_CIVIL: 'Civil',
					WFP_RELIG: 'Religieux',
					WFP_CATERING: 'Num. Des gens',
					WFP_CORONAS:'Coronas ',
					WFP_OTROS: 'Autres spécifications',
					WFP_MULTIPLE: 'Mort multiple',
					WFP_PERSONAS: 'Personnes'
				}
			},
			SWAL: { 
				CANCELAR: 'Annuler',
				registerSwal: 'Embauche nécessaire',
				registerSwal_TEXT: "Ce contenu n'est pas disponible avec un compte invité. Embaucher un taux pour continuer.",
				registerSwal_BTN1: 'Embaucher',
				suscriptionSwal: 'Enchères non disponibles',
				suscriptionSwal_TEXT: "Ce contenu n'est pas disponible avec votre abonnement. Vérifiez votre tarif pour accéder.",
				suscriptionSwal_BTN1: 'Mon profil',
				confirmationSwal: "Correction d'offre",
				confirmationSwal_TEXT: "Votre montant a été correctement offert",
				errorSwal: 'Montant incorrect',
				errorSwal_TEXT: "Le montant doit être inférieur au courant de l'appel d'offres.",
				errorNegativeSwal_TEXT: "Le montant de l'appel d'offres ne peut pas être négatif.",
				error2Swal_TEXT: "Le montant de l'appel d'offres a changé et est désormais inférieur à votre proposition. Essayez à nouveau avec un autre montant.",
				confirmaSwal: 'Confirmation',
				confirmaSwal_TEXT1: "Confirmez-vous l'appel d'offres pour {{importeLicitar}} {{divisa}}?",
				confirmaSwal_TEXT2: "Confirmez-vous l'inscription à l'appel d'offres? Vous recevrez une notification si vous êtes choisi à la fin.",
				confirmaSwal_BTN1: 'Oui, je confirme',
				confirmaElegidoSuccessSwal: 'Utilisateur choisi correctement.',
				confirmaElegidoSuccessSwal_TEXT: 'Le gagnant a été attribué correctement. Vous pouvez voir ses détails ci-dessous.',
				confirmaElegidoSwal_TEXT1: "Confirmez-vous {{empresa}} en tant que gagnant de l'appel d'offres?",
				confirmaSaltarSwal_TEXT1: "Confirmez-vous à l'exclusion {{empresa}} en tant que gagnant de l'appel d'offres et en le rejetant dans les futures appels d'offres? Il n'y a plus de sorties, l'offre restera déserte. Ce participant ne verra pas vos offres,",
				confirmaSaltarSwal_TEXT2: "Confirmez-vous à l'exclusion {{empresa}} en tant que gagnant de l'appel d'offres et en le rejetant dans les futures appels d'offres? Ce participant ne reverra plus vos offres. Exclusion {pasadas}} / 2",
				confirmaSaltarSwal_TEXT3: "Confirmez-vous exclure {{empresa}} en tant que gagnant de l'appel d'offres? Il n'y a plus d'exusions, l'offre restera déserte.",
				confirmaSaltarSwal_TEXT4: "Confirmez-vous exclure {{empresa}} en tant que gagnant de l'appel d'offres? Vous ne pourrez pas revenir à cet utilisateur. Exclusion {pasadas}} / 2",
				confirmaSaltarSwal_TEXT5: "Confirmez-vous à l'exclusion {{empresa}} en tant que gagnant de l'appel d'offres et en le rejetant dans les futures appels d'offres? Il n'y a plus de participants, l'appel d'offres restera désert. Ce participant ne verra pas vos offres,",
				confirmaSaltarSwal_TEXT6: "Confirmez-vous à l'exclusion {{empresa}} en tant que gagnant de l'appel d'offres et en le rejetant dans les futures appels d'offres? Ce participant ne reverra plus vos offres.",
				confirmaSaltarSwal_TEXT7: "Confirmez-vous pour exclure tous les participants? L'appel d'offres restera déserte.",
				confirmaSaltarSuccessSwal1: 'Participant correctement exclu.',
				confirmaSaltarSuccessSwal2: 'Participant correctement bloqué.',
				confirmaSaltarSuccessSwal_TEXT1: "Le participant a été exclu. N'oubliez pas que vous ne pouvez exclure que 2 fois par tendre.",
				confirmaSaltarSuccessSwal_TEXT2: "Le participant a été exclu. L'appel d'offres a été déserte.",
				confirmaSaltarSuccessSwal_TEXT3: "Le participant d'appel d'offres a été exclu.",
				confirmaSaltarSuccessSwal_TEXT4: "Le participant a été exclu et ne reverra plus vos offres. L'appel d'offres a été déserte.",
				confirmaSaltarSuccessSwal_TEXT5: 'Le participant a été exclu et ne reverra plus vos offres.',
				confirmaTrazaSwal: 'Confirmation de trace',
				confirmaTrazaSwal_TEXT: 'Voulez-vous confirmer cette trace? Le perleur sera informé.',
				confirmaTrazaSwal_BTN1: 'Oui, je confirme',
				errorTrazaSwal: 'Trace Error',
				errorTrazaSwal_TEXT: 'Vous devez saisir un commentaire de service en ayant une incidence.',
				errorTrazaSwal_TEXT2: 'Vous devez saisir la date du service.',
				errorTrazaSwal_TEXT3: 'Vous devez saisir toutes les informations sur le lieu du service.',
				confirmaTrazaSuccessSwal: 'Trace confirmée correctement.',
				confirmaTrazaSuccessSwal_TEXT: 'La trace sélectionnée a été confirmée correctement.',
				cancelaTrazaSwal: 'Trace annulation',
				cancelaTrazaSwal_TEXT: 'Voulez-vous annuler cette trace? Toutes les informations seront supprimées.',
				cancelaTrazaSwal_BTN1: 'Oui, je confirme',
				cancelaTrazaSuccessSwal: 'Dessin annulé correctement.',
				cancelaTrazaSuccessSwal_TEXT: 'La trace sélectionnée a été correctement annulée.',
				borrarSwal: "Éliminer l'appel d'offres",
				borrarSwal_TEXT: "Êtes-vous sûr d'éliminer cet appel d'offres? Vous ne pourrez pas récupérer son contenu.",
				borrarSwal_BTN1: 'Oui, supprimez',
				borrarConfirmSwal: "Tente d'offres correctement éliminée",
				borrarConfirmSwal_TEXT: "L'appel d'offres a été correctement éliminé.",
				conexionSwal: 'Limite de connexion dépassée',
				conexionSwal_TEXT: 'Le nombre de connexions simultanées disponibles a été dépassée. Ferme certaines des sessions actives pour continuer.',
				errorFileSwal: 'Format incorrect',
				errorFileSwal_TEXT: 'Le document doit être téléchargé au format PDF',
				questionDeleteFileSwal: "Supprimer le document",
				questionDeleteFileSwal_TEXT: "Êtes-vous sûr d'effacer ce document? Vous ne pouvez pas récupérer.",
				questionFusionarSwal: "Fusion Services",
				questionFusionarSwal_TEXT: "Êtes-vous sûr de fusionner ces services? Vous ne pourrez pas récupérer le service d'origine et vos données, documents et commentaires seront combinés.",
				questionFusionarSwal_BTN1: "Oui, fusionnez",
				questionDeleteComentarioSwal: "Borrar Comentario",
				questionDeleteComentarioSwal_TEXT: "¿Estás seguro de borrar este comentario? No se podrá recuperar.",

				confirmacionAcepSwal: 'Accepter le service',
				confirmacionAcepSwal_TEXT: "Êtes-vous sûr d'accepter le service? Vous ne pourrez pas le modifier plus tard.",
				confirmacionAcepSwal_BTN1: "Oui, acceptez",
				confirmacionRechSwal: 'Rejeter le service',
				confirmacionRechSwal_TEXT: 'Êtes-vous sûr de rejeter le service? Vous ne pourrez pas le récupérer plus tard.',
				confirmacionRechSwal_BTN1: "Oui, rejetez"
			},
			TUTORIAL: 'REGARDER À NOUVEAU LE TUTORIEL'
		},
		GLOBAL: {
			PAGO: "Paiement d'abonnement en attente.",
			PAGO_SUB: "Vous ne nous avez pas encore fourni vos données de paiement d'abonnement. Appuyez sur le bouton pour accéder à la plate-forme de paiement sûre.",
			PAGO_BUTTON: 'Plateforme de paiement sécuritaire',
			errorModoSwal: 'Plate-forme non disponible',
			errorModoSwal_TEXT: "Ce contenu n'est pas disponible avec votre abonnement.",
		},
		INPUT: {
			VALIDATION: {
				INVALID: "{{name}} n'est pas valide",
				REQUIRED: '{{name}} doit être introduit',
				MIN_LENGTH: '{{name}} La longueur minimale est {{min}}',
				AGREEMENT_REQUIRED: 'Il faut accepter les termes et conditions',
				NOT_FOUND: "Le {{name}} fissuré n'est pas trouvé",
				INVALID_LOGIN: 'Les données entrées ne sont pas correctes. Essayer à nouveau.',
				REQUIRED_FIELD: 'Vous devez remplir ce champ',
				MIN_LENGTH_FIELD: 'La taille minimale de ce champ est:',
				MAX_LENGTH_FIELD: 'La taille maximale de ce champ est:',
				INVALID_FIELD: "Ce champ n'est pas valide" ,
				MATCH: "Le mot de passe et la confirmation ne correspondent pas",
				INVALID_REGISTER: "L'e-mail introduit est déjà enregistré. Essayez à nouveau ou connectez-vous."
			}
		},
		CREAR: {
			TITULO: 'Créer un tendre',
			TITULO_NAC: 'Service / transfert national',
			TITULO_INT: 'Transfert international',
			TITULO_EDIT: 'Modifier les offres',
			REF: 'Référence du client',
			REF2: '(ne sera pas présenté publiquement)',
			REF_ERROR: 'La référence déjà enregistrée dans le système.',
			TIPO: "Type d'appel d'offres",
			ESTANDAR: 'Tendère standard.',
			ESTANDAR_DESC: 'Entrez une figure de sortie. Les funérailles demanderont pour gagner le service ou le transfert.',
			FIJO: 'Offre à un prix fixe.',
			FIJO_DESC: "Le service sera attribué au prix établi parmi toutes les salons funéraires qui l'ont demandé.",
			AD: 'Affectation directe.',
			AD_DESC: 'Le service sera automatiquement attribué au salon funéraire en fonction du critère de choix et du montant préétabli.',
			FECHA_INI: 'Date de début / heure',
			FECHA_FIN: "Date d'achèvement / heure",
			FECHA_ERROR: 'La date de fin ne peut pas être avant la date de début.',
			OPT_TRASLADO: 'Tender transfert national',
			OPT_SERVICIO: "Service d'appel d'offres",
			IMPORTE: 'Montant de sortie',
			IMPORTE_ERROR1: 'Le montant du transfert ne peut pas être négatif',
			IMPORTE_ERROR2: 'Le montant du transfert doit être inférieur à 100 000',
			DETALLE: 'Détail de service spécifique',
			ORIGEN: 'ORIGINE',
			PAIS: 'Pays',
			COMUNIDAD: 'Communauté autonome',
			ESTADO: 'État',
			POBLACION: 'Ville',
			DESTINO: 'DESTINATION',
			KMS: 'Kms à la destination',
			OPCIONAL: '(facultatif)',
			LOCALIZACION: 'EMPLACEMENT',
			DESTINO_TRASLADO: '(Transfer Destination)',
			PENDIENTE_DESTINO: 'En attente de la destination de transfert',
			SERVICIO_ERROR: "Vous devez sélectionner au moins un service à l'appel",
			IMPORTE_ERROR3: 'Le montant du transfert ne peut pas être négatif',
			IMPORTE_ERROR4: 'Le montant du transfert doit être inférieur à 100 000',
			INCLUIDO_EN: 'Inclus dans',
			INCLUIDO_EN2: 'Service complet',
			SELECT_OPCION: 'Choisir une option',
			OPCION_ERROR: 'Vous devez sélectionner une option',
			OTROS_OPCION: "Sélectionnez d'autres services à inclure",
			CREACION: 'Format de création',
			UNIDO: 'Même tendre.',
			UNIDO_DESC: "Le transfert et les services seront offerts dans le même appel d'offres. Le salon funéraire récompensé sera le même pour les deux.",
			SEPARADO: "Des appels d'offres.",
			SEPARADO_DESC: 'Le transfert et les services seront offerts dans des offres distinctes. Le salon funéraire récompensé peut être différent dans les deux.',
			BOTON: 'Ajouter des offres',
			BOTON_EDIT: 'Modifier les offres',
			SWAL: {
				confirmationAddSwal: "Ajout d'appel d'offres",
				confirmationAddSwal_TEXT: "Les services sélectionnés ont été ajoutés correctement à la liste des appels d'offres",
				confirmationEditSwal: "Editorise d'offres",
				confirmationEditSwal_TEXT: "L'appel d'offres a été correctement édité",
				errorLicitacionSwal: 'Enchère avec des offres',
				errorLicitacionSwal_TEXT: "Cet appel d'offres a déjà reçu des offres. Il n'est pas possible de faire des modifications, vous pouvez le supprimer et le réaliser à nouveau.",
				errorTipoLicitacionSwal: 'Sélection de type',
				errorTipoLicitacionSwal_TEXT: "Vous devez sélectionner un type d'appel d'offres avant de continuer.",
				errorInternacionalSwal: "Erreur d'origine / destination",
				errorInternacionalSwal_TEXT: "Les pays d'origine et de destination doivent être différents."
			}
		},
		START: {
			PASOS: {
				DATOS: 'Données contractuelles',
				VALIDACION: 'La validation des données',
				ADICIONAL: 'Information complémentaire',
				PAGO: 'Données de paiement',
				CONFIRMACION: 'Confirmation',
				TARIFA: 'Sélection des taux',
				TARIFA2: 'Tariser les informations'
			},
			TARIFA_LOC: "Indiquez l'emplacement de la demande de tarif",
			TARIFA_LOC_WFP: 'Indiquez la portée géographique de vos services',
			CANALES: 'Je veux être informé par les canaux suivants: <i>(au moins un)</i>',
			PLATAFORMAS: 'Je veux accéder aux plates-formes suivantes,',
			WFN: "Développez votre entreprise funéraire en accédant à des centaines d'offres publiées par des assureurs, des entreprises et des groupes.",
			WFP: 'Développez votre entreprise funéraire en accédant à des centaines de demandes individuelles.',
			INFO: "Plus d'informations.",
			VOLVER: 'REVENIR',
			CONTINUAR: 'CONTINUER',
			VALIDACION: 'La validation des données',
			SMS: {
				TITULO: 'Validation du téléphone mobile',
				MENSAJE: 'Nous avons envoyé un SMS au téléphone {{telefono}} avec un code de confirmation. Entrez-le ci-dessous.',
				CODIGO: 'Code de confirmation',
				CODIGO_ERROR: "Le code introduit n'est pas valide",
				REENVIAR: 'Code transféré',
				REENVIADO: 'Message transféré'
			},
			CERTIFICADOS: 'Informations supplémentaires - Certificats et botté de dégagement. qualité',
			PAGO: "Entrez les données bancaires",
			CONFIRMACION: 'Confirmation',
			PERSONAL:'Information personnelle',
			TARIFA: 'Frais',
			SUSCRIPCION: 'Abonnement',
			AMBITO: 'Portée géographique',
			WFP_RESUMEN: "Payez 10% du prix auquel l'appel d'offres a été attribué.",
			ALTA: 'Haut comme',
			OFERTANTE: 'Offre',
			PROVEEDOR: 'Fournisseur',
			SERVICIOS: 'des transferts et des services funéraires',
			DATOS_PAGO: 'Données de paiement',
			CONFIRMAR: 'CONFIRMER',
			CONFIRMAR_PAGAR: 'Confirmer et payer',
			WFP_PAGO: "À l'étape suivante, nous vous demanderons les données de la carte de crédit, dans lesquelles aucune charge ne sera effectuée tant qu'aucun service n'est effectué dans WFP (individus).",
			SWAL: {
				errorSMSSwal: 'Code SMS incorrect',
				errorSMSSwal_TEXT: 'Le code de validation téléphonique envoyé par SMS est incorrect. Revisez-le et introduisez-le à nouveau.',
				errorTelegramSwal: 'Code télégramme incorrect',
				errorTelegramSwal_TEXT: 'Le code de validation de télégramme introduit est incorrect. Revisez-le et introduisez-le à nouveau.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: "Abonnement jusqu'à",
			GUARDAR: 'Enregistrer les informations',
			DATOS: {
				TITULO: 'Mon profil',
				TITULO2: "Informations de l'utilisateur",
				EMAIL: 'E-mail',
				EMPRESA: 'Entreprise / collectif',
				EMPRESA2: 'Entreprise ou "nom" collectif',
				ACTIVIDAD: 'Activité',
				LICENCIA: 'Licence nº',
				PAIS: 'Pays',
				COMUNIDAD: 'Communauté / État autonome',
				DIRECCION: 'Adresse',
				POBLACION: 'Ville',
				CP: 'Cp ',
				MOVIL: 'Téléphone portable',
				NOTIFICACIONES: 'Notifications',
				TELEFONO: 'Téléphone',
				WEB: 'Page Web',
			},
			SUSCRIPCION: {
				TITULO: 'Abonnement',
				NO_HAY: "Il n'y a pas d'abonnement actif",
				CAMBIO: 'Traitement du changement de mode de paiement ...',
				TARIFA: 'Frais',
				FECHA_INICIO: 'La date de début',
				FECHA_FIN: 'Date de fin',
				PROX_RENOV: 'Renouvellement à venir',
				FACTURACION: 'Facturation',
				PRECIO: 'Prix',
				LOCALIZACION: 'Emplacement du taux',
				ESTADO: 'ÉTAT-ÉTAT',
				RENOV_AUTO: 'Renouvellement automatique',
				RENOV_CANCEL: 'Renouvellement annulé',
				PROX_CAMBIO: 'Prochain variation du taux',
				FECHA_EFECT: 'Date effective',
				LOCALIZACION2: 'Emplacement',
				CANCELAR_CAMBIO: 'Annuler le changement',
				CAMBIO_PROCESO: "Traitement d'annulation...",
				METODO_PAGO: 'Procédé de paiement',
				EXPIRACION: 'Expiration',
				PAGO_CAMBIO: 'Changer la méthode de paiement',
				PAGO_LOADING: 'Plate-forme de paiement de chargement...',
				BTN_LOCALIZACION: 'CHANGER DE LIEU',
				BTN_TARIFA: 'TAUX DE CHANGE',
				BTN_SUSC: "ANNULER L'ABONNEMENT",
				BTN_CANCEL: "TRAITEMENT DE L'ANNULATION..."
			},
			METODO_PAGO: {
				TITULO: 'Procédé de paiement',
				TITULAR: 'Gros titre',
				FACTURACION: 'Facturation',
				MENSUAL: 'Mensuel',
				LICITA: 'Par tendre',
				CAMBIAR: 'Modifier les données de paiement',
				NOMBRE: 'Nom de la définition',
				IBAN: 'Numéro de compte (IBAN)'
			},
			PAGOS: {
				TITULO: 'Historique de paiement',
				TITULO2: 'Historique des paiements - factures',
				IMPORTE: 'Montant',
				ESTADO: 'État',
				VER: 'Regarder',
				DESCARGA: 'Télécharger'
			},
			BLOQUEO: {
				TITULO: 'Liste de verrouillage',
				FECHA: 'Date de blocage'
			},
			CERTIFICACIONES: {
				TITULO: "Mes certifications et mes scores",
				TITULO2: "Certifications / scores de qualité de l'entreprise",
				TITULO3: 'Ajouter une nouvelle certification',
				TITULO5: 'Mettre à jour la certification',
				TITULO4: 'Certifications',
				NPS: 'Entrez les scores NPS',
				OTRO: 'Un autre indice de mesure de qualité (identification)',
				OTRO1: 'Introduit le score% de',
				OTRO2: "Introduit le score% d'un autre indice",
				NOMBRE: 'Nom',
				ESTADO: 'État',
				SUBIDA: 'Augmentation de la date',
				VALIDEZ: 'Validité de date',
				BORRAR: 'Supprimer',
				BOTON: 'Ajouter la certification',
				BOTON2: 'Mettre à jour la certification',
				CANCELAR: 'Annuler',
				GUARDAR: 'Sauver la certification',
				PROCESO: 'Certification de traitement',
				ESTADO_CORRECTO: "C'est Correct",
				ESTADO_REVISION: 'En revue',
				ESTADO_ERROR: 'Non valable',
				ESTADO_CADUCADO: 'Fin du temps',
				ACTUALIZAR: 'Mise à jour',
				SUBIR_ERR: 'Format incorrect',
				SUBIR_ERR_TEXT: 'La certification doit être téléchargée au format PDF ',
				SUBIR_ERR2: 'Fichier non sélectionné',
				SUBIR_ERR2_TEXT: 'Vous devez sélectionner ou faire glisser un fichier dans la zone de zone supérieure ',
				ARRASTRA: 'Faites glisser ou cliquez ici pour télécharger votre certification (PDF) ',
				SUBIR_OK: "Certification enregistrée",
				SUBIR_OK_TEXT: "La certification a été sauvée avec succès",
				BORRAR_PREG: 'Supprimer la certification',
				BORRAR_PREG_TEXT: "Êtes-vous sûr d'effacer cette certification? Il ne peut pas être récupéré.",
				BORRAR_BOTON: 'Oui, supprimez',
				BORRAR_BOTON2: 'Annuler',
				BORRAR_OK: 'Certification supprimée',
				BORRAR_OK_TEXT: 'La certification a été supprimée avec succès.'
			},
            FUNERARIAS: {
                TITULO: 'Mon funéraire',
				TITULO2: 'Salon funéraire géré par la société',
				TITULO3: 'Ajouter un nouveau funéraille',
				TITULO4: 'Mettre à jour les funérailles',
				BOTON: 'Ajouter les funérailles',
				BOTON2: 'Mettre à jour les funérailles',
				CANCELAR: 'Annuler',
				GUARDAR: 'Sauver les funérailles',
				PROCESO: 'Traitement des funérailles',
				BORRAR: 'Supprimer',
				ACTUALIZAR: 'Mise à jour',
				NOMBRE: 'Nom',
				SUBIR_OK: 'Sauvé la Maison funéraire',
				SUBIR_OK_TEXT: 'Le salon funéraire a sauvé avec succès',
				BORRAR_PREG: 'Effacer les funérailles',
				BORRAR_PREG_TEXT: "Êtes-vous sûr d'effacer ce salon funéraire? Il ne peut pas être récupéré.",
				BORRAR_BOTON: 'Oui, supprimez',
				BORRAR_BOTON2: 'Annuler',
				BORRAR_OK: 'Funérailles supprimées',
				BORRAR_OK_TEXT: 'Le salon funéraire a été supprimé avec succès.',
				NOTIFICACIONES: 'Notifications'
            },
			ALERTAS: {
				TITULO: "Mon système d'alerte",
				FRECUENCIA: 'Sélectionnez la fréquence de réception des notifications de nouvelles offres.',
				INMEDIATO: 'Immédiat',
				MINUTOS: "Toutes les 15 minutes",
				NUNCA: 'Jamais',
				MODO: 'Ajustez la façon dont vous serez informé des messages de la plate-forme.',
				LLAMADA: 'Appel téléphonique',
				MODO_ERROR: 'Vous devez sélectionner au moins une option',
				TELEGRAM_TITULO: 'Activation de la notification télégramme',
				TELEGRAM1: "Suivez les instructions suivantes pour activer les notifications dans l'application Telegram",
				TELEGRAM2: "1. Ouvrez la <b> Application Telegram </b> sur votre appareil mobile ou installez-le à partir du magasin d'applications (App Store - Apple ou Google Play - Android)",
				TELEGRAM3: '2. Recherchez l\'utilisateur <b>WFUNERALNETBOT</b> dans la barre de recherche de la zone supérieure. Alternative: Si Telegram est installé sur votre appareil actuel, vous pouvez accéder à ce lien <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" ciblez ="_blank">https://t.me/WFuneralNetBot</a> et ouvrez l\'application.',
				TELEGRAM4: "3. Appuyez sur le bouton <b> Démarrer qui apparaîtra lorsque vous trouverez l'utilisateur dans l'application.",
				TELEGRAM5: '4. Entrez le code de validation qui apparaîtra dans la conversation.',
				COD_DISPOSITIVO: 'Code de périphérique',
				COD_ERROR: "Le code introduit n'est pas valide",
				NOTIFICACIONES: "Personnalise les notifications que vous recevrez de l'activité de la plate-forme",
				SMS_TITULO: 'Configuration de notification de SMS',
				SMS1: 'Entrez les numéros de téléphone dans lesquels vous souhaitez recevoir des notifications pour SMS',
				COD_SMS: 'Téléphone sms',
				LLAMADAS_TITULO: 'Configuration de la notification par appel téléphonique',
				LLAMADAS1: 'Entrez les numéros de téléphone dans lesquels vous souhaitez recevoir des appels téléphoniques. Par ce moyen, vous ne recevrez que des avis de nouvelles offres.',
				LLAMADAS2: "Établit la gamme d'heures pendant lesquelles vous souhaitez recevoir des appels.",
				COD_LLAMADAS: 'Appels téléphoniques',
				LLAMADAS_DIA: "Sans limite d'heures",
				LLAMADAS_INI: 'Heure de début',
				LLAMADAS_FIN: 'Heure de fin',
				ZONAS: 'Sélectionnez les domaines dans lesquels informer les événements de la plate-forme.',
				ZONAS_TODAS: 'Informer tous les domaines'
			},
			CAMBIO: {
				AVISO: 'AVERTISSEMENT: Le changement de lieu sera effectif dans les prochaines 48 heures.',
				BTN_CANCELAR: 'Annuler le changement',
				BTN_LOC: 'Changer de lieu',
				BTN_TARIFA: 'Taux de change',
				BTN_PROCESO: 'Traitement du changement ...',
				SELECCIONE_TARIFA: 'Sélectionnez le taux',
				PAGAR: 'Montant à payer',
				AVISO2: "AVERTISSEMENT: Le nouveau taux s'appliquera immédiatement."
			},
			TODO_MUNDO: 'Tout le monde',
			DISPOSITIVOS: {
				TITULO: 'Appareils enregistrés',
				NOMBRE: "Nom de l'appareil",
				FECHA: "Date d'inscription"
			},
			EVENTOS: {
				TITULO: 'Mes événements',
				EVENTO: 'Événement',
				FECHA: "Date de l'événement",
				DETALLE: 'Événement détaillé'

			},
			ENTIDAD: {
				TITULO: "Entité d'entreprise",
				LOGO: "Logo d'entreprise",
				PRESENTACION: 'Image de présentation',
				ARRASTRA: 'Faites glisser ou cliquez ici pour télécharger votre image',
				SUBIR: 'Sauvegarder image',
				BORRAR: "Supprimer l'image"
			},
			SWAL: {
				confirmationSwal: 'Données enregistrées',
				confirmationSwal_TEXT: 'Les données ont été enregistrées correctement',
				borrarSwal: 'Supprimer le bloc',
				borrarSwal_TEXT: 'Voulez-vous supprimer ce bloc?',
				borrarSwal_BTN1: 'Oui, supprimez',
				borrarSwal_BTN2: 'Annuler',
				borrarConfirmSwal: 'Blocage correctement supprimé',
				borrarConfirmSwal_TEXT: 'Le blocus a été supprimé correctement.',
				cancelarSuscripSwal: 'Annuler l\'abonnement',
				cancelarSuscripSwal_TEXT: 'Êtes-vous sûr d\'annuler votre abonnement? Vous pouvez continuer à utiliser les services WFN jusqu\'à la date de fin actuelle de votre taux.',
				cancelarSuscripSwal_BTN1: 'Oui, annuler',
				cancelarSuscripSwal_BTN2: 'Annuler',
				cancelarSuscripOkSwal: 'Abonnement correctement annulé',
				cancelarSuscripOkSwal_TEXT: 'L\'abonnement a été correctement annulé. Vous pouvez réactiver le taux de votre profil.',
				tarifaCambiadaSwal: 'Changement de taux correct',
				tarifaCambiadaSwal_TEXT: 'Le taux a correctement changé',
				tarifaCambiadaSwal_TEXT2: 'Votre nouveau taux s\'appliquera immédiatement.',
				errorSwal: 'Mauvaise adresse mail',
				errorSwal_TEXT: 'L\'e-mail introduit est déjà enregistré dans le système.',
				errorTelegramSwal: 'Code télégramme incorrect',
				errorTelegramSwal_TEXT: 'Le code de validation de télégramme introduit est incorrect. Revisez-le et introduisez-le à nouveau.',
				cambioLocSwal: 'L\'emplacement a changé correctement',
				cambioLocSwal_TEXT: 'Son nouvel emplacement de taux sera efficace en 48 heures.',
				borrarCambioSwal: 'Changement correctement effacé',
				borrarCambioSwal_TEXT: 'Le changement sélectionné correctement a été annulé.',
				borrarDispSwal: 'Supprimer l\'appareil',
				borrarDispSwal_TEXT: 'Voulez-vous supprimer cet appareil?',
				borrarDispSwal_BTN1: 'Oui, supprimez',
				borrarDispSwal_BTN2: 'Annuler',
				borrarDispConfirmSwal: 'Supprimer correctement supprimé',
				borrarDispConfirmSwal_TEXT: 'L\'appareil a été supprimé correctement.'
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Plans et prix pour',
			TITULO_FUN2: 'opérateurs funéraires',
			SELECCIONA: 'Sélectionnez le plan qui convient le mieux à votre entreprise de funérailles. Vous aurez toujours la flexibilité de télécharger ou de réduire votre plan.',
			TITULO_WFP: 'Prévoit de',
			SELECCIONA_WFP: 'Sélectionnez le champ géographique qui convient le mieux à votre entreprise de funérailles.',
			SELECCIONA_WFP2: 'Ce n\'est que dans le PAM que vous paierez pour les services que vous créez, 10% du prix auquel chaque service a été attribué.',
			SELECCIONA_WFN: 'Dans WFN, vous ne paierez en aucun cas.',
			PAGO_ANUAL: 'PAIEMENT ANNUEL',
			AHORRO: 'Économies 10%',
			PAGO_MENSUAL: 'PAIEMENT MENSUEL',
			POPULAR: 'PLUS POPULAIRE',
			PUJA_EN: 'Puja in',
			OFERTA_PARA: 'Offrir à',
			TARIFA1: 'tout le monde',
			TARIFA2: 'tout le pays',
			TARIFA3: '5 communautés',
			TARIFA4: 'une communauté',
			TARIFA1_MX: 'Mexique',
			TARIFA2_MX: 'Mexique, États-Unis et Canada',
			TARIFA3_MX: 'tout le monde',
			MES: 'mois',
			AÑO: 'année',
			IVA: 'Vat',
			TAX: 'Impôt',
			OFERTA: 'Offre de lancement',
			CONTRATAR: 'EMBAUCHER',
			SELECCIONAR: 'SÉLECTIONNER',
			TEXTO1: 'Accès aux informations sur les appels d\'offres produits partout dans le monde.',
			TEXTO2: 'Premium Access to International Monthly Funeral Magazine WfuneralNews.',
			TEXTO3: 'Soutien personnalisé de notre équipe.',
			TEXTO4: 'Système d\'alerte sur votre mobile ou par e-mail, offres, offres ou autres informations pertinentes que vous considérez.',

			TITULO_ASEG1: 'Prix pour',
			TITULO_ASEG2: 'assurance, sociétés et collectifs',
			TITULO_ASEG3: 'Assurance et collection WFN',
			ASEG1: 'Atterrir sur notre plateforme vos services ou transferts funéraires.',
			ASEG2: 'Obtenez le meilleur prix pour le service.',
			ASEG3: 'Accès à notre base de données de 60 000 salles funéraires dans 194 pays.',
			ASEG4: 'Segmentation des critères de qualité et d\'excellence (ISO, UNE, NPS).',
			ASEG5: 'Ont des informations sur la traçabilité du service contractuel.',
			ASEG6: 'Accès aux informations sur les appels d\'offres produits partout dans le monde.',
			ASEG7: 'Premium Access to International Monthly Funeral Magazine WfuneralNews.',
			ASEG8: 'Soutien personnalisé de notre équipe.',
			PAGANOS: 'Páganos à nous juste à cause de ce qui a été embauché.',
			PRECIO: 'du prix auquel l\'appel d\'offres final a été attribué.',
			FUNERARIA: 'Vous payez directement aux funérailles le service contractuel.',
			GRATIS: 'GRATUIT'
		},
		SOPORTE: {
			TIPO: {
				VENTA: 'Ventes',
				POSTVENTA: 'Après ventes',
				ADMINISTRACION: 'Administration',
				SAT: 'Service technique',
				OTRO: 'Autre'
			},
			MENU: 'Assistance WFuneralNet'
		},
		PETS: {
			CREAR: {
				TITULO: "Créer un service pour animaux de compagnie",
				CLIENTE: "Client",
				CLIENTE_SELECT: "Sélectionnez un client",
				EXPEDIENTE: "Numéro de dossier",
				ORIGEN: "Origine",
				ORIGEN_SELECT: "Sélectionnez une origine",
				DATOS_PROP: "Données du propriétaire",
				DATOS_MASC: "Données pour animaux de compagnie",
				NOMBRE: "Nom",
				APELLIDO1: "Lastname 1",
				APELLIDO2: "Nom de famille 2",
				DNI: "DNI/NIE/PASAPORTE",
				TELEFONO: "Téléphone",
				EMAIL: "E-mail",
				DIRECCION_CONT: "Adresse de contact",
				LOCALIDAD: "Localité",
				PROVINCIA: "Province",
				CP: "C.P.",
				NUMERO: "Le numéro",
				PISO: "Escal./piso/puerta",
				PESO: "Poids",
				TIPO_ANIMAL: "Type d'animal",
				CHIP: "N ° Chip",
				FECHA_FALL: "Date de mort",
				LUGAR_FALL: "Type tombe.",
				LUGAR_FALL_SELECT: "Sélectionnez un endroit",
				LUGAR_FALL_TIPO: {
					DOMICILIO: "Maison",
					VETERINARIO: "Vétérinaire",
					REFUGIO: "Abri",
					OTRO: "Autre"
				},
				DIRECCION_FALL: "Adresse de mort / collection",
				INFO_SERV: "Des informations de service",
				OBSERVACIONES: "Observations",
				SUBMIT: "Créer un service",
				SWAL: {
					confirmationAddSwal: "Service créé",
					confirmationAddSwal_TEXT: "Référence:"
				}
			},
			PRESUPUESTO: {
				DATOS_CLIENTE: "Données du client",
				SERVICIO: "Service",
				SERVICIO_BUSCAR: "Recherche de service",
				OPCIONAL: "facultatif",
				FIRMA: "Activer la signature électronique?",
				FIRMA_SI: "Ouais",
				FIRMA_NO: "Non",
				LOCALIDAD_FALL: "Localité de la mort",
				SEL_SERVICIOS: "Sélection de services",
				PERSONALIZAR: "Personnalisation du package",
				RESTAURAR: "Restaurer les prix",
				ADICIONALES: "Lignes supplémentaires",
				CONCEPTO: "Concept",
				IMPUESTO: "Impôt",
				IMPORTE: "Montant",
				IMPORTE2: "(Imp.Inc.)",
				ADD_LINEA: "Ajouter une ligne",
				SUPLIDOS: "Fournitures",
				ADD_SUPLIDO: "Ajouter une alimentation",
				NOTAS: "Notes complémentaires",
				TOTAL: "Total",
				TOTAL_PAQUETE: "Package sélectionné",
				TOTAL_SERVICIOS: "Services supplémentaires",
				TOTAL_LINEAS: "Lignes supplémentaires",
				TOTAL_SUPLIDOS: "Fournitures",
				SUBMIT: "GÉNÉRER UN BUDGET",
				RES: {
					TITULO: "Budget généré correctement",
					TEXTO: "Le Budget PDF a été téléchargé. Vous avez également la même chose dans la liste des services.",
					CODIGO: "Code budgétaire",
					BTN1: "NOUVEAU BUDGET - NOUVEAU CLIENT",
					BTN2: "NOUVEAU BUDGET - MÊME CLIENT",
					EMAIL: "Envoyer un e-mail au client",
					ADJUNTOS: "Budgets adjoints",
					PRESUPUESTO: "Devis",
					IMPORTE: "Montant",
					ENVIAR: "Envoyer un e-mail",
					ENVIADO: "E-mail envoyé correctement",
					EMAIL_TEXT1: "Bonjour",
					EMAIL_TEXT2: "Bonsoir",
					EMAIL_TEXT3: "Bonne nuit",
					EMAIL_TEXT4: "D / ms",
					EMAIL_TEXT5: "Merci beaucoup pour votre contact avec Borea.",
					EMAIL_TEXT6: "Comme nous avons parlé au téléphone, nous envoyons notre budget pour les adieux que vous avez choisis.",
					EMAIL_TEXT7: "Nous vous rappelons que ce devis est valable 90 jours si vous décidez finalement de le signer. Pour cela, cliquez sur le bouton signature et remplissez les champs demandés. Notre numéro de téléphone est le 683 67 39 57.",
					EMAIL_TEXT8: "Nous vous rappelons que ce devis est valable 90 jours si vous décidez finalement de le signer. Pour nous l'envoyer vous pouvez utiliser ce même moyen (email). Notre numéro de téléphone est le 683 67 39 57.",
					EMAIL_TEXT9: "Cordialement",
					EMAIL_TEXT10: "par BOREA",
				},
				MODAL_PERS: {
					TITULO: "Package Packing",
					NOMBRE: "Nom (ligne)",
					DETALLE: "Détail",
					IMPORTE: "Montant des services",
					IMPORTE2: "(Imp.inc.)",
					CERRAR: "Fermer",
				},
				MODAL_SERV: {
					TITULO: "Sélectionnez un service",
					REFERENCIA: "Référence",
					FECHA: "Date",
					ORIGEN: "Origine",
					SERVICIO: "Service",
					LOCALIZACION: "Emplacement",
					NOMBRE: "Nom d'animal",
					SELECCIONAR: "Sealcut"
				},
				SWAL: {
					errorSwal: "Nous sommes désolés",
					errorSwal_TEXT: "Les tarifs de localisation introduits ne sont pas disponibles.",
					locSwal: "Erreur",
					locSwal_TEXT: "Vous devez saisir l'emplacement pour visualiser les services.",
					emailSwal: "Erreur",
					emailSwal_TEXT: "Vous devez personnaliser le texte <b> [VOTRE NOM] </b> inclus dans la zone inférieure de l'e-mail."
				}
			},
			RAZONES: {
				NOCONT: "Plomb non contactable",
				FICTICIO: "Données fictives",
				INTERES: "Sans intérêt",
				SEGURO: "Le plomb a une assurance mort",
				FANTASMA: "Ghost (le client cesse de répondre)",
				COM_PRO: "Concurrent - La proposition de service ne satisfait pas",
				COM_PRE: "Concurrent - prix élevé",
				COM_LEN: "Concurrent - lent à répondre",
				COM_ASE: "Concurrent - Le conseiller vient en personne",
				PP: "La mort d'assurance (PP) offerte et perdue",
				PU: "Cousin célibataire (PU) offert et perdu",
				SIN_GEO: "Sans la possibilité de servir - manque de couverture géographique",
				SIN_SERV: "Sans la possibilité de servir - manque de service",
				SIN_PLAN: "Sans la possibilité de servir - veut planifier",
				ERROR: "Appel erroné",
				OTRO: "Une autre raison",
			},
			TIPOS: {
				PERRO: "Chien",
				GATO: "Chat",
				OTROS: "Autres",
			},
			ORIGEN: {
				LLAMADA: "Appel",
				SOL_LLAMADA: "Soleil.Appel",
				FORMULARIO: "Formulaire",
				WHATSAPP: "WhatsApp",
				CHAT: "Chat",
				NO: "Non disponible",
			},
			ORIGEN_MAYUS: {
				LLAMADA: "APPEL",
				SOL_LLAMADA: "SOLEIL. APPEL",
				FORMULARIO: "FORMULAIRE",
				WHATSAPP: "WHATSAPP",
				CHAT: "CHAT",
			},
			ESTADO: {
				PENDIENTE: "Nouveau lead",
				RECHAZADA: "Serv. Lost",
				PRESUPUESTAR: "Pend. Budget",
				LLAMADA: "Appel client",
				ACEPTADA: "Présup. Signé",
				ASIGNADA: "Client bétail",
				COMPLETADA: "Serv. Pergé"
			},
			SERVICIOS: {
				CREMA_IND: "Crémation individuelle",
				CREMA_COL: "Crémation collective",
				ADICIONALES: "Services supplémentaires",
				EUTANASIA: "Euthanasie et Low Chip",
				PSICO: "Attention psychologique",
				VELATORIO: "Wake de l'incinérateur",
				URGENCIA_INC: "Urgence incinérante",
				HUELLA: "Empreinte de l'animal",
				URGENCIA_VET: "Urgence vétérinaire",
				REC_DESCOMP: "Collection en décomposition",
			},
			FILTROS: {
				REFERENCIA: "Référence",
				FECHA_DESDE: "Dater de",
				FECHA_HASTA: "Date jusqu'à",
				LOCALIZACION: "Emplacement",
				ORIGEN: "Origine",
				TODOS: "TOUS",
				PROVEEDOR: "Fournisseur",
				ESTADO: "État",
				OCULTO: "Show Hidden",
				REINICIAR: "Redémarrer les filtres"
			},
			LISTADO: {
				TITULO: "Liste des services",
				ACTUALIZAR: "Mise à jour",
				VER: "Voir les filtres",
				CERRAR: "Fermer des filtres",
				EXPORTAR: "Exporter",
				NO: "Il n'y a pas de services",
				CONTACTO: "Nom du contact",
				FALLECIDO: "Nom de la mort",
				MASCOTA: "Nom d'animal domestique",
				NO_DISP: "Non disponible",
				LOC_FALL: "Emplacement décédé",
				LOC_MAS: "Emplacement des animaux de compagnie",
				LOC_CONT: "Emplacement de contact",
				LOC_IP: "Emplacement IP",
				LOC: "Emplacement",
				SERVICIO: "Service",
				PENDIENTE: "Boucle d'oreille",
				TRASLADO: "TRANSFERT",
				SERV_IND: "CREM.INDIV.",
				SERV_COL: "CREM.COLEC.",
				INFO_CLI: "Informations client",
				INFO_CONT: "Coordonnées",
				INFO_FALL: "Données du défunt",
				INFO_MASC: "Données pour animaux de compagnie",
				CREADOR: "Créateur",
				DETALLES_VISIT: "Détails de visite",
				VISIT_URL: "URL",
				VISIT_IP_LOC: "IP loc.",
				VISIT_DISP: "Disp.",
				VISIT_PC: "PC",
				VISIT_MOVIL: "Mobile",
				VISIT_TABLET: "Tablette",
				VISIT_ORIGEN: "Origine",
				VISIT_MEDIO: "Moitié",
				VISIT_CAMP: "Cloche",
				VISIT_KW: "KW",
				FUSION: "Fuse Services",
				EDICION: "Édition",
				COMENTARIOS: "Commentaires",
				DOCUMENTOS: "Documents",
				COM_NUEVO: "Nouveau commentaire",
				COM_PL: "Commentaire du service",
				COM_ENVIAR: "Envoyer des commentaires",
				COM_LISTADO: "Liste des commentaires",
				COM_NO: "Il n'y a pas de commentaires",
				DOC_NUEVO: "Nouveau document",
				DOC_DROP: "Appuyez ou faites glisser le document ici",
				DOC_NOMBRE: "Nom du document",
				DOC_LISTADO: "Listing des documents",
				DOC_NO: "Il n'y a pas de documents",
				DOC_BORRAR: "Supprimer",
				DOC_SUBIR: "Télécharger des documents",
				PRES_NUEVO: "Nouveau budget",
				PRES_LISTADO: "Listing Budgets",
				PRES_NO: "Il n'y a pas de budget",
				ACEPTAR_PREGUNTA: "Acceptez-vous le service?",
				ACEPTAR_LOAD: "Un moment...",
				ACEPTAR_SI: "ACCEPTER",
				ACEPTAR_NO: "DÉCLIN",
				RECH1: "Service rejeté",
				RECH2: "Service perdu",
				RECH_MOTIVO: "Raison de la fermeture perdue",
				RECH_SEL: "Sélectionnez une raison",
				RECH_REACTIVAR: "RÉACTIVER LE SERVICE",
				RECH_OCULTAR: "MASQUER LE SERVICE",
				RECH_MOSTRAR: "AFFICHER LE SERVICE",
				RECH_FUSIONAR: "SERVICE DE FUSION",
				RECH_FUSIONANDO: "Fesing Services ...",
				SERV_DETALLE: "Détails du service",
				SERV_TIPO: "Type de service",
				SERV_PROV: "Fournisseur de services",
				SERV_PROV_SEL: "Sélectionnez un fournisseur",
				SERV_PRESTADO: "Service fourni",
				FIRM_CONTROL: "Contrôle des applications",
				FIRM_LOADING: "Un moment...",
				FIRM_LLAMADA: "APPEL CLIENT EN ATTENTE",
				FIRM_PRES: "EN ATTENTE. BUDGET",
				FIRM_FIRMADO: "BUDGET SIGNÉ",
				FIRM_CANCELAR: "ANNULER LE SERVICE",
				COMIEN_TRAZA: "Traceabilité des services",
				COMIEN_LOADING: "Un moment...",
				COMIEN_SI: "DÉMARRER LE SERVICE",
				COMIEN_NO: "ANNULER LE SERVICE",
				TRAZAS: {
					TITULO: "Traçabilité",
					TEXT1: "Le centre a contacté la famille.",
					TEXT2: "Données et exigences vérifiées de l'euthanasie",
					TEXT3: "L'euthanasie a été réalisée.",
					TEXT4: "Le défunt a été collecté ou livré.",
					TEXT5: "Le défunt a atteint le centre.",
					TEXT6: "Date et lieu de crémation / adieu",
					TEXT7: "Wake Made",
					TEXT8: "Crémation jouée",
					TEXT9: "Les cendres et la documentation sont livrées",
					TEXT10: "Documentation livrée",
					COMPLETADO: "Rempli"
				},
				ESTADO_PEND: "Acceptation en attente du service",
				ESTADO_RECH: "Service rejeté",
				ESTADO_ASIG: "Alliation en attente du service",
				CARGAR: "CHARGEZ PLUS DE SERVICES",
				MODAL_FUSIONAR: {
					TITULO: "Sélectionnez un service pour fusionner (destination)",
					REFERENCIA: "Référence",
					FECHA: "Date",
					ORIGEN: "Origine",
					SERVICIO: "Service",
					LOCALIZACION: "Emplacement",
					NOMBRE: "Nom d'animal domestique",
					ESTADO: "État",
					SELECCIONAR: "Sélectionner"
				}
			}
		}
	}
};
