import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, SolicitudService, UserService } from '../../../_services';
import moment from 'moment';
import { DxPopupComponent } from 'devextreme-angular';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { interval, Subscription, Observable } from 'rxjs';
import{ GlobalVariables } from '../../../_common/global-variables';
import { Router } from '@angular/router';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as ExcelJS from 'exceljs/dist/exceljs';

import * as logoFile from './logo.js';
import { first } from 'rxjs/operators';


@Component({
  selector: 'kt-admin-wfp',
  templateUrl: './admin-wfp.component.html',
  styleUrls: ['./admin-wfp.component.scss']
})
export class AdminWfpComponent implements OnInit {

  @ViewChild("popupDetalle", { static: false }) popupDetalle: DxPopupComponent

  currentUser: any;

  rows = [];
  originalRows = [];
  puntosMapa = [];
  vista = "solicitudes";
  altura;
  tipo = [];
  estados = [];
  loadingExportar = false;
  solicitudHash = {};
  solicitudes = [];
  solicitud = null;

  filtrosOpen = false;
  dropdownSettings = null;
  status = true;

  fallo = false;

  constructor(
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      this.dropdownSettings = {
        searchPlaceholderText: "'Buscar'",
        noDataAvailablePlaceholderText: "'No hay datos disponibles'",
        enableCheckAll: "true",
        allowSearchFilter: "true",
        textField: "nombre",
        idField: "id",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Desseleccionar todo",
        itemsShowLimit: 3
      }
    }


  filtrar(){
    this.fallo = false;
    this.detalles = [];
    this.filterDatatable();
    this.cdr.detectChanges();
  }

  actualizar(){
    this.loadUsuarios();
    this.detalles = [];
    this.fallo = false;
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.loadUsuarios();
    console.log(this.rows);
    this.detalles = [];
    this.altura = window.innerHeight-200 + 'px';
    this.mapbox.accessToken = environment.mapBoxToken;
    this.vista == "solicitudes";
    if(GlobalVariables.filters[this.router.url]) {
      this.filters = GlobalVariables.filters[this.router.url].filters;
      this.filtrosOpen = GlobalVariables.filters[this.router.url].open;
    } else {
      GlobalVariables.filters[this.router.url] = {
        filters: this.filters,
        open: this.filtrosOpen
      }
    }
    /*for(let i = 0; i< this.originalRows.length;i++){
      this.verDetalleSinPopup(this.originalRows[i]); 
    }*/
  }
  filters = {
    fechaDesde: null,
    fechaHasta: null,
    email: null,
    telefono: null,
    estado: null,
    nombre: null,
    localizacion: null,
    traslado: null,
    oculta: null,
    localizacionFallecido: null,
    tipo: null,
    velatorio: null,
    flores: null,
    personalizado: null,
    importeDesde: null,
    importeHasta: null,
  }
  resetFilters() {
    this.fallo = false;
    this.detalles = [];
    this.filters = {
      fechaDesde: null,
      fechaHasta: null,
      email: null,
      telefono: null,
      estado: null,
      nombre: null,
      localizacion: null,
      traslado: null,
      oculta: null,
      localizacionFallecido: null,
      tipo: null,
      velatorio: null,
      flores: null,
      personalizado: null,
      importeDesde: null,
      importeHasta: null,
    }
    this.filterDatatable();
  }

  loadUsuarios() {
    this.solicitudService.getAll()
	    .subscribe(solicitudes => {

        for(let solic of solicitudes) {
          solic.fechaStr = moment(solic.createdAt).format("DD/MM/YY HH:mm");
          if(solic.email && solic.email.includes("@contacto.com")) {
            solic.email = "-";
          }
        }
        
        this.originalRows = solicitudes;
        this.filterDatatable();
        this.cdr.detectChanges();
      });
    console.log(this.rows);
  }

  async exportar() {
    
    if(this.loadingExportar) return;
    
    
    this.loadingExportar = true;
    this.cdr.detectChanges();
    this.cdr.detectChanges();

    let procesados = 0;

    for(let row of this.rows) {
      this.solicitudService.getServicios(row.id)
	    .subscribe(servicios => {
        let servStr = "";
        for(let serv of servicios) {
          servStr += serv.servicio.nombre + " (" + this.thousands_separators(serv.importe) + "€), "
        }
        servStr = servStr.slice(0, -2);
        row.serviciosStr = servStr;

        procesados++;
        if(procesados == this.rows.length) {
          this.continuarExportar();
        }
      });
    }
  }

  async continuarExportar() {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Informe de Solicitudes');
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 32;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 50;
    worksheet.getColumn(7).width = 8;
    worksheet.getColumn(8).width = 50;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 8;
    worksheet.getColumn(11).width = 8;
    worksheet.getColumn(12).width = 8;
    worksheet.getColumn(13).width = 14;
    worksheet.getColumn(14).width = 8;
    worksheet.getColumn(15).width = 8;


    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, 'A2:B4');

    const cell = worksheet.getCell('D2');
    cell.value = 'Informe - Solicitudes';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('D4').value = 'Fecha Informe: ';
    worksheet.getCell('D4').font = { bold: true };
    worksheet.getCell('E4').value = moment().format("DD/MM/YYYY HH:mm");

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    let titleRow = worksheet.addRow(['Fecha', 'Email', 'Telefono', 'Estado', 'Nombre', 'Loc.Ceremonia', 'Traslado', 'Loc.Fallecido','Tipo','Velatorio','Flores','Person.','Importe','Servicios solicitados', 'Oculta']);
    // Set font, size and style in title row.
    titleRow.font = { bold: true };
    for(let row of this.rows) {
      if(row.oculta == false){
        row.ocultaStr = "NO"
      }
      else{
        row.ocultaStr = "SI"
      }
      
      if(row.traslado == false){
        row.trasladoStr = "NO";
      }
      else{
        row.trasladoStr = "SI";
      }
      
      if(row.velatorio == false){
        row.velatorioStr = "NO";
      }
      else{
        row.velatorioStr = "SI";
      }

      if(row.flores == false){
        row.floresStr = "NO";
      }
      else{
        row.floresStr = "SI";
      }

      if(row.personalizado == false){
        row.personalizadoStr = "NO";
      }
      else{
        row.personalizadoStr = "SI";
      }       
      
      worksheet.addRow([
        row.fechaStr, 
        row.email, 
        row.telefono, 
        row.status, 
        row.nombre, 
        row.localizacion, 
        row.trasladoStr, 
        row.localizacionFallecido,
        row.tipo,row.velatorioStr,
        row.floresStr,
        row.personalizadoStr,
        this.thousands_separators(row.importe),
        row.serviciosStr,
        row.ocultaStr
      ]);
    }
    

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Solicitudes-' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  detalleVuelta = null;
  detalles = [];
  listaTotal: any[];
  /*cargarNombres(){
    let nombres = [];
    for(let i = 0 ;)
    for(let j = 0 ; i<this.detalles[i].length;j++){
      nombres.push(this.detalles[i][j].servicio.nombre);
      console.log(this.detalles[i][j].servicio.nombre);
    }
  }*/
  verDetalleSinPopup(row: any) { 
    this.solicitudService.getServicios(row.id)
	    .subscribe(servicios => {
        this.detalleVuelta = servicios;
        this.detalles.push(this.detalleVuelta);
        if(this.detalles[this.rows.length-1] != null){
          this.fallo = true;
        }
        console.log(this.fallo);
        this.cdr.detectChanges();
      });



      /*this.solicitudService.getServicios(row.id)
	    .subscribe(servicios => {
        console.warn(servicios);

        this.detalle = row;

        this.detalle.servicios = servicios;
        console.log(this.detalle.servicios);
        

        this.popupDetalle.instance.repaint();
        this.popupVisible = true;
        this.cdr.detectChanges();
      });*/
  }
  
  filterDatatable(){
    GlobalVariables.filters[this.router.url].filters = this.filters;
    //GlobalVariables.listadoPagina = "solicitud";
    //GlobalVariables.listado = this.originalRows;
    //this.fallo = false;
    this.detalles = [];
    // assign filtered matches to the active datatable
    /*if(this.status && this.vista == "mapa"){
      this.status = false;
      console.log(this.status);
      this.loadMapa();
    }*/
    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != ""){
        if(item.nombre == null) mostrar=false;
        if(item.nombre != null){
          if(!item.nombre.toLowerCase().includes( this.filters.nombre.toLowerCase())){
            mostrar = false;
          }
        }
      }
      if(this.filters.email &&  this.filters.email != null && this.filters.email != ""){
        if(item.email == null) mostrar=false;
        if(item.email != null){
          if(!item.email.toLowerCase().includes( this.filters.email.toLowerCase())){
            mostrar = false;
          }
        }
      }
      if(this.filters.telefono &&  this.filters.telefono != null && this.filters.telefono != ""){
        if(item.telefono == null) mostrar=false;
        if(item.telefono != null){
          if(!item.telefono.toLowerCase().includes( this.filters.telefono.toLowerCase())){
            mostrar = false;
          }
        }
      }
      if(this.filters.localizacion &&  this.filters.localizacion != null && this.filters.localizacion != ""){
        if(item.localizacion == null) mostrar=false;
        if(item.localizacion != null){
          if(isNaN(this.filters.localizacion)){
            if(!item.localizacion.toLowerCase().includes(this.filters.localizacion.toLowerCase())){
              mostrar = false;
            }
          }
          else{
            if(item.cp != parseInt(this.filters.localizacion, 10)){
              mostrar = false;
            }
          }
        }
      }
      if(this.filters.traslado && this.filters.traslado != null && this.filters.traslado != ""){
        if(item.traslado == null) mostrar = false;
        if(item.traslado != null){
          if(item.traslado == 0 && this.filters.traslado== true){
            mostrar = false;
          }
          else if(item.traslado == 1 && this.filters.traslado == false){
            mostrar = false;
          }
        }
      }
      if(this.filters.fechaDesde && this.filters.fechaDesde != null && this.filters.fechaDesde != ""){
        if(item.createdAt == null) mostrar = false;
        if(item.createdAt < moment(this.filters.fechaDesde.valueOf())){
          mostrar = false;
        }
      }
      if(this.filters.fechaHasta && this.filters.fechaHasta != null && this.filters.fechaHasta != ""){
        if(item.createdAt == null) mostrar = false;
        if(item.createdAt > moment(this.filters.fechaHasta.valueOf())){
          mostrar = false;
        }
      }

      if(this.filters.estado && this.filters.estado != null && this.filters.estado!= ""){
        if(item.status == null) mostrar = false;
        if(item.status != null) {
          if(this.filters.estado != "TODOS") {
            if(this.filters.estado.length == 3){}
            else if(this.filters.estado.length == 2){
              if(!item.status.toLowerCase().includes(this.filters.estado[0].nombre.toLowerCase()) && !item.status.toLowerCase().includes(this.filters.estado[1].nombre.toLowerCase())){
                mostrar = false;
              }
            }
            else{
              if(!item.status.toLowerCase().includes(this.filters.estado[0].nombre.toLowerCase())){
                mostrar = false;
              }
            }
          }
        }
      }
      
      if(this.filters.localizacionFallecido && this.filters.localizacionFallecido != null && this.filters.localizacionFallecido != "") {
        var estado = false;
        if(item.localizacionFallecido == null)mostrar=false;
        if(item.cpFallecido == null)mostrar=false;
        if(item.cpFallecido !=null){
          if(item.cpFallecido == this.filters.localizacionFallecido){
            estado = true;
          }
        }
        if(item.localizacionFallecido != null){

          if(item.localizacionFallecido.toLowerCase().includes(this.filters.localizacionFallecido.toLowerCase())){

            estado = true;
          }
        }
        if(estado != true){
          mostrar= false;
        }
      }

      if(this.filters.importeDesde && this.filters.importeDesde!=null && this.filters.importeDesde > 0){
        if(item.importe == null)mostrar=false;
        if(item.importe != null){
          if(parseInt(item.importe) < this.filters.importeDesde){
            mostrar=false;
          }
        }
      }
      if(this.filters.importeHasta && this.filters.importeHasta!=null && this.filters.importeHasta > 0){
        if(item.importe == null)mostrar=false;
        if(item.importe != null){
          if(parseInt(item.importe) > this.filters.importeHasta){
            mostrar=false;
          }
        }
      }

      if(this.filters.velatorio && this.filters.velatorio != null && this.filters.velatorio !="" ){
        if(item.velatorio == null)mostrar=false;
        if(item.velatorio !=null && this.filters.velatorio!="TODOS"){
          if(item.velatorio == 0 && this.filters.velatorio==true){
            mostrar = false;
          }else if(item.velatorio ==1 && this.filters.velatorio==false){
            mostrar = false;

          }
        }
      }

      if(this.filters.flores && this.filters.flores != null && this.filters.flores !="" ){
        if(item.flores == null)mostrar=false;
        if(item.flores !=null && this.filters.flores!="TODOS"){
          if(item.flores == 0 && this.filters.flores==true){
            mostrar = false;
          }else if(item.flores ==1 && this.filters.flores==false){
            mostrar = false;

          }
        }
      }
      /*oculta*/

      if(this.filters.oculta == null){
        if(item.oculta == 1){
          mostrar = false;
        }
      }
      
      if(this.filters.oculta != null){
        if(item.oculta == 1 && this.filters.oculta == false){
          mostrar = false;
        }
      }
      if(this.filters.personalizado && this.filters.personalizado != null && this.filters.personalizado !="" ){
        if(item.personalizado == null)mostrar=false;
        if(item.personalizado !=null && this.filters.personalizado!="TODOS"){
          if(item.personalizado == 0 && this.filters.personalizado==true){
            mostrar = false;
          }else if(item.personalizado ==1 && this.filters.personalizado==false){
            mostrar = false;

          }
        }
      }
      if(this.filters.tipo && this.filters.tipo != null && this.filters.tipo !=""){
        if(item.tipo == null)mostrar=false;
        if(item.tipo !=null && this.filters.tipo !="TODOS"){
          if(this.filters.tipo.length == 2){}
          else{
            if(!item.tipo.toLowerCase().includes(this.filters.tipo[0].nombre.toLowerCase())){
              mostrar = false;
            }
          }

        }


      }

      
      return mostrar;
    }.bind(this));
    
    //console.log(this.rows);
    //console.log(this.listaTotal);
    this.cdr.detectChanges();

    if(this.vista == "mapa") {
      this.verMapa();
    }
  }

  cambiarOculto(row: any) {
    let estado = true;
    if(row.oculta) estado = false;
    
    let objUpd = {
      id: row.id,
      oculta: estado
    }

    row.oculta = estado;
    this.cdr.detectChanges();
    this.solicitudService.update(objUpd)
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
          console.error(error);
        });
  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
   {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
      else return num_parts[0];
       
    } else {
      return '0';
    }
     
  }
  verMapa(){
    this.loadMapa();
    this.popupVisible = false;    
  }
  verSolicitudes(){
    this.vista="solicitudes";
    this.popupVisible = false;
    this.cdr.detectChanges();

  }

  verFiltros(){
    this.filtrosOpen = !this.filtrosOpen;
    GlobalVariables.filters[this.router.url].open = this.filtrosOpen;
    this.cdr.detectChanges();     
    this.tipo = [
        {id: 1, nombre: "Entierro" },
        {id: 0, nombre: "Incineracion"}
    ];
    this.estados = [
      {id: 1, nombre: "ENVIADO"},
      {id: 2, nombre: "PROCESO"},
      {id: 3, nombre: "FORMULARIO"}
    ];
  }

  mapbox = (mapboxgl as typeof mapboxgl);
  style = `mapbox://styles/mapbox/streets-v11`;

  map: mapboxgl.Map;
  licit = null;

  loadMapa() {
    this.cdr.detectChanges();
    this.puntosMapa = [];

    for(let row of this.rows) {
      let solic = row;
      let icon = "https://cdn-icons-png.flaticon.com/512/684/684908.png";
      this.puntosMapa.push({
          latitud: solic.lat,
          longitud: solic.long,
          obj: solic,
          estado: solic.status
      }) 
    }

    this.loadMapaVista();
  }


  loadMapaVista() {
    this.vista = "mapa";
    this.cdr.detectChanges();

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 6,
      center: [-3.7029039398210277, 40.42735844594665]
    });
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('draw.create', function (e) {
      if (e.features.length && e.features[0].geometry.type == 'Point') {
        var center = e.features[0].geometry.coordinates;
        //map.setCenter(center);
        this.map.easeTo({center: center});
      }
    })

    for(let punto of this.puntosMapa) {
      console.log("entro puntos")
      //if(punto.longitud && punto.latitud) {
        /*const marker1 = new mapboxgl.Marker()
        .setLngLat([punto.longitud, punto.latitud])
        .addTo(this.map);*/

        let marker;
        if(punto.estado == "PROCESO"){
          marker = new mapboxgl.Marker({ color: "#FF0000" })

          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        }
        else if(punto.estado == "ENVIADO") {
          marker = new mapboxgl.Marker({ color: "#66FF00" })

          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        } else {
          marker = new mapboxgl.Marker({ color: "#333254" })

          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        }
        /*
        }
        */

        //CLICK EN EL BOTON
        
        marker.getElement().addEventListener('click', (e) => {
          console.warn(e);
          
          console.warn(punto);
          this.licit = punto.obj;
          this.verDetalle(punto.obj);
          this.popupVisible = true;

        });
      //}
    }
    
    this.map.on('click', (e) => {
      console.warn(e);
    });

  }
  // DETALLES

  detalle = null;
  popupVisible = false;
  
  verDetalle(row: any) {
    this.solicitudService.getServicios(row.id)
	    .subscribe(servicios => {
        console.warn(servicios);

        this.detalle = row;

        this.detalle.servicios = servicios;
        console.log(this.detalle.servicios);
        

        this.popupDetalle.instance.repaint();
        this.popupVisible = true;
        this.cdr.detectChanges();
      });
  }

  cerrarDetalle() {
    this.popupVisible = false;
  }
  

}
