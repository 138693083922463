import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { AuthenticationService } from './authentication.service';
 
/**
 * Contiene los métodos para la gestión de pagos.
 * @author Informática Integral Vasca
 */
@Injectable()
export class PaymentService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) { }

    /**
     * Realiza una petición `POST` para crear una nueva sesión de pago checkout en STRIPE
     * @param priceId Identificador de la tarifa
     * @param customerId Identificador del cliente
     * @param pais Identificador del pais
     * @returns Devuelve el resultado de la operación
     */
    createCheckoutSession(priceId: any, customerId: any, pais: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                priceId: priceId,
                customer: customerId,
                pais: pais
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `POST` para crear un nuevo cliente STRIPE
     * @param email Email del cliente
     * @param name Nombre del cliente
     * @param phone Teléfono del cliente
     * @returns Devuelve el resultado de la operación
     */
    createCustomer(email: any, name: any, phone: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/createCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                email: email,
                name: name,
                phone: phone
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `POST` para crear un proceso de suscripción
     * @param customerId Identificador STRIPE del cliente
     * @returns Devuelve el resultado de la operación
     */
    createSetupIntent(customerId: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-setup-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                customer: customerId
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `POST` para crear manualmente una suscripción
     * @param priceId Identificador de la tarifa
     * @param customerId Identificador STRIPE del cliente
     * @param coupon Identificador del cupon a aplicar (si hay)
     * @param pais Identificador del país
     * @returns Devuelve el resultado de la operación
     */
    createSubscription(priceId: any, customerId: any, coupon: any, pais: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-subscription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                priceId: priceId,
                customer: customerId,
                coupon: coupon,
                pais: pais
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `GET` para obtener el detalle de una sesión checkout de STRIPE
     * @param session Identificador de la sesión
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getSession(session: any) {
        return this.http.get<any>(`${environment.apiUrl}/session?session=${session}`);
    }

    /**
     * Realiza una petición `GET` para obtener el detalle de una suscripción de STRIPE
     * @param subscription Identificador de la suscripción
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getSubscription(subscription: any) {
        return this.http.get<any>(`${environment.apiUrl}/subscription?subscription=${subscription}`);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una suscripción
     * @param suscrip Identificador de la suscripción
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updateSubscription(suscrip: any) {
        return this.http.patch<any>(`${environment.apiUrl}/subscription`, suscrip);
    }

    /**
     * Realiza una petición `GET` para obtener el detalle del método de pago de STRIPE
     * @param session Identificador del método de pago
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getPaymentMethod(payment: any) {
        return this.http.get<any>(`${environment.apiUrl}/paymentmethod?payment=${payment}`);
    }

    /**
     * Realiza una petición `GET` para obtener el listado de facturas de STRIPE
     * @param session Identificador del cliente STRIPE
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getInvoices(customerId: any) {
        return this.http.get<any>(`${environment.apiUrl}/invoices?customerId=${customerId}`);
    }

    /**
     * Realiza una petición `GET` para obtener el listado de facturas de WFN
     * @param session Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getFacturas(user: any) {
        return this.http.get<any>(`${environment.apiUrl}/facturas?IdUsuario=${user}`);
    }

    /**
     * Realiza una petición `GET` para obtener una factura de WFN
     * @param session Identificador de la factura
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getFactura(id: any) {
        return this.http.get<any>(`${environment.apiUrl}/factura?id=${id}`);
    }

    
    /**
     * Realiza una petición `POST` para cambiar el método de pago con tarjeta
     * @param customerId Identificador del cliente
     * @param subscriptionId Identificador de la suscripción
     * @returns Devuelve el resultado de la operación
     */
    createSetupCheckoutSession(customerId: any, subscriptionId: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-setup-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                subscription: subscriptionId,
                customer: customerId
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `POST` para crear proceso de pago aseguradoras MX con tarjeta
     * @param customerId Identificador del cliente
     * @returns Devuelve el resultado de la operación
     */
    createSetupCheckoutSessionAseg(customerId: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-setup-checkout-session-aseg`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                customer: customerId
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `POST` para crear proceso de pago WFP
     * @param customerId Identificador del cliente
     * @param tipo Identificador del método de pago
     * @returns Devuelve el resultado de la operación
     */
    createSetupCheckoutSessionWFP(customerId: any, tipo: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-setup-checkout-session-wfp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                customer: customerId,
                tipo: tipo
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `PATCH` para actualizar el método de pago
     * @param change Información a modificar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    setPaymentMethod(change: any) {
        return this.http.patch<any>(`${environment.apiUrl}/paymentmethod`, change);
    }

    /**
     * Realiza una petición `GET` para obtener una previsualización de la tarifa al hacer un cambio
     * @param subscription Identificador de la suscripción
     * @param price Identificador de la tarifa
     * @param customer Identificador del cliente STRIPE
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getProrrationPreview(subscription: any, price: any, customer: any) {
        return this.http.get<any>(`${environment.apiUrl}/prorrationpreview?subscriptionId=${subscription}&priceId=${price}&customerId=${customer}`);
    }



    /**
     * Realiza una petición `POST` para crear un nuevo link de pago para BOREA
     * @param presupuesto Objeto que contiene la información de la solicitud de presupuesto
     * @param formasPago Array que contiene las formas de pago
     * @returns Devuelve el resultado de la operación
     */
    createPayment(presupuesto: any, formasPago: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/createPayment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                presupuesto: presupuesto,
                formasPago: formasPago
            })
        }).then(function(result) {
            return result.json();
        });
    }

    /**
     * Realiza una petición `PATCH` para actualizar una suscripción
     * @param suscrip Identificador de la suscripción
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    updatePayment(plink: any, params: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/updatePayment`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                plink: plink,
                params: params
            })
        }).then(function(result) {
            return result.json();
        });
    }
}