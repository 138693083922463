import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { columnsTotalWidth, NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthenticationService, StateService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService, CertificadoService, PaymentService, B2bService, SolicitudService, CentroService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';

import { interval, Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import{ GlobalVariables } from '../../../_common/global-variables';
import * as introJs from 'intro.js/intro.js';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import * as ExcelJS from 'exceljs/dist/exceljs';

import * as logoFile from './logo.js';
import * as logoPets from './logoPets.js';
import * as logoBorea from './logoBorea.js';

/**
 * Componente que gestiona y muestra el área de licitaciones
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-licitaciones',
  templateUrl: './licitaciones.component.html',
  styleUrls: ['./licitaciones.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)',
        'transform-style': 'revert',
        '-webkit-transform': 'translateZ(1000px)'
      })),
      transition('default => flipped', [
        animate('200ms')
      ]),
      transition('flipped => default', [
        animate('200ms')
      ])
    ])
  ]
})
export class LicitacionesComponent implements OnInit {

  /** Variables globales */
  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('errorNegativeSwal', {static: false}) private errorNegativeSwal: SwalComponent
  @ViewChild('confirmaSwal', {static: false}) private confirmaSwal: SwalComponent
  @ViewChild('error2Swal', {static: false}) private error2Swal: SwalComponent
  @ViewChild('registerSwal', {static: false}) private registerSwal: SwalComponent
  @ViewChild('registerValidarSwal', {static: false}) private registerValidarSwal: SwalComponent
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent
  @ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  @ViewChild('content8', {static: false}) private modalLicit: NgbModal
  @ViewChild('contentEditar', {static: false}) private modalEditar: NgbModal
  @ViewChild('confirmaElegidoSwal', {static: false}) private confirmaElegidoSwal: SwalComponent
  @ViewChild('confirmaElegidoSuccessSwal', {static: false}) private confirmaElegidoSuccessSwal: SwalComponent
  @ViewChild('confirmaSaltarSwal', {static: false}) private confirmaSaltarSwal: SwalComponent
  @ViewChild('confirmaSaltarSuccessSwal', {static: false}) private confirmaSaltarSuccessSwal: SwalComponent
  @ViewChild('suscriptionSwal', {static: false}) private suscriptionSwal: SwalComponent
  @ViewChild('conexionSwal', {static: false}) private conexionSwal: SwalComponent
  @ViewChild('errorTrazaSwal', {static: false}) private errorTrazaSwal: SwalComponent
  @ViewChild('errorTraza2Swal', {static: false}) private errorTraza2Swal: SwalComponent
  @ViewChild('errorTraza3Swal', {static: false}) private errorTraza3Swal: SwalComponent
  @ViewChild('borrarSwal', {static: false}) private borrarSwal: SwalComponent
  @ViewChild('borrarConfirmSwal', {static: false}) private borrarConfirmSwal: SwalComponent
  @ViewChild('questionDeleteFileSwal', {static: false}) private questionDeleteFileSwal: SwalComponent
  @ViewChild('questionDeleteComentarioSwal', {static: false}) private questionDeleteComentarioSwal: SwalComponent
  @ViewChild('questionFusionarSwal', {static: false}) private questionFusionarSwal: SwalComponent
  @ViewChild('confirmacionAcepSwal', {static: false}) private confirmacionAcepSwal: SwalComponent
  @ViewChild('confirmacionRechSwal', {static: false}) private confirmacionRechSwal: SwalComponent
  @ViewChild('errorSAPSwal', {static: false}) private errorSAPSwal: SwalComponent
  @ViewChild('content9', {static: false}) private modalNegocio: NgbModal
  @ViewChild('content10', {static: false}) private modalCentro: NgbModal
  //
  @ViewChild('importe', {static: false}) private importeField: ElementRef
  @ViewChild('tableLicitaciones', {static: false}) private tableLicitaciones: any
  @ViewChildren('tooltip') tooltips;
  @ViewChild('panelCards', null) panelCards: ElementRef;


  // Array de provincias españolas
  provincias = [
    {id: 0, nombre: ""},
    {id: 1, nombre: "Araba"},
    {id: 2, nombre: "Albacete"},
    {id: 3, nombre: "Alicante"},
    {id: 4, nombre: "Almería"},
    {id: 5, nombre: "Ávila"},
    {id: 6, nombre: "Badajoz"},
    {id: 7, nombre: "Baleares"},
    {id: 8, nombre: "Barcelona"},
    {id: 9, nombre: "Burgos"},
    {id: 10, nombre: "Cáceres"},
    {id: 11, nombre: "Cádiz"},
    {id: 12, nombre: "Castellón"},
    {id: 13, nombre: "Ciudad Real"},
    {id: 14, nombre: "Córdoba"},
    {id: 15, nombre: "A Coruña"},
    {id: 16, nombre: "Cuenca"},
    {id: 17, nombre: "Girona"},
    {id: 18, nombre: "Granada"},
    {id: 19, nombre: "Guadalajara"},
    {id: 20, nombre: "Gipuzkoa"},
    {id: 21, nombre: "Huelva"},
    {id: 22, nombre: "Huesca"},
    {id: 23, nombre: "Jaén"},
    {id: 24, nombre: "León"},
    {id: 25, nombre: "Lleida"},
    {id: 26, nombre: "La Rioja"},
    {id: 27, nombre: "Lugo"},
    {id: 28, nombre: "Madrid"},
    {id: 29, nombre: "Málaga"},
    {id: 30, nombre: "Murcia"},
    {id: 31, nombre: "Navarra"},
    {id: 32, nombre: "Ourense"},
    {id: 33, nombre: "Asturias"},
    {id: 34, nombre: "Palencia"},
    {id: 35, nombre: "Las Palmas"},
    {id: 36, nombre: "Pontevedra"},
    {id: 37, nombre: "Salamanca"},
    {id: 38, nombre: "Santa Cruz de Tenerife"},
    {id: 39, nombre: "Cantabria"},
    {id: 40, nombre: "Segovia"},
    {id: 41, nombre: "Sevilla"},
    {id: 42, nombre: "Soria"},
    {id: 43, nombre: "Tarragona"},
    {id: 44, nombre: "Teruel"},
    {id: 45, nombre: "Toledo"},
    {id: 46, nombre: "Valencia"},
    {id: 47, nombre: "Valladolid"},
    {id: 48, nombre: "Bizkaia"},
    {id: 49, nombre: "Zamora"},
    {id: 50, nombre: "Zaragoza"},
    {id: 51, nombre: "Ceuta"},
    {id: 52, nombre: "Melilla"}
  ];
  
  introJS = introJs();

  vista = 'listado';

  eventSusc: any;
  userSusc: any;
  currentUser: any;
  demoCompletada = false;
  servicios: any;
  serviciosHash = {};

  filtrosReducidos = false;
  columnas = null;

  participaciones = {};
  participacionesFun = {};
  participacionesImporte = {};

  filtrosOpen = true;
  loadingInit = true;

  rows = [];
  originalRows = [];
  temp = [];

  subscription: Subscription;

  paises: any;
  estados: any;
  estadosFuneraria = [];
  estadosOrigen = []
  estadosDestino = [];
  paisOrigen = null;
  paisDestino = null;
  trazabilidad = [];
  trazabilidadHash = {};
  pagosPendientes = false;
  suscripcionActiva = true;

  modoPlataforma = null;
  comentario = null;
  comentarioInterno = false;

  constructor(public router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private countryService: CountryService,
    private licitaService: LicitaService,
    private userService: UserService,
    private b2bService: B2bService,
    private solicitudService: SolicitudService,
    private notificacionService: NotificacionService,
    private certificadoService: CertificadoService,
    private paymentService: PaymentService,
    private centroService: CentroService,
    private http: HttpClient,
    public translate: TranslateService,
    private ngZone: NgZone,
    private trazabilidadService: TrazabilidadService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      this.provincias.sort((a,b) => a.nombre.localeCompare(b.nombre));

      this.introJS.setOptions({
        nextLabel: "Continuar",
        prevLabel: "Volver",
        skipLabel: "Salir",
        doneLabel: "Finalizar",
        hintButtonLabel: "OK",
        exitOnEsc: false,
        exitOnOverlayClick: false,
        showStepNumbers: false,
        showBullets: false,
        hidePrev: true,
        hideNext: false,
        scrollToElement: true
      });
      
  }

  /**
   * Va al componente indicado
   * @param commands Componente al que navegar
   */
  navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  /**
   * Evento que detecta cuando la pantalla cambia de tamaño
   * @param event Evento de resize
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var size = window.innerHeight;
    this.filtrosReducidos = size < 850;
    this.cdr.detectChanges();
    this.calcularColumnas();
  }

  /**
   * Calcula cuantas columnas mostrar en base al ancho de la pantalla
   */
  calcularColumnas() {
    var width = this.panelCards.nativeElement.offsetWidth;
    if(width > 1700) this.columnas = 5
    else if(width > 1250) this.columnas = 4
    else if(width > 870) this.columnas = 3
    else if(width > 540) this.columnas = 2
    else this.columnas = 1;
  }

  contieneProvincia(provincia: any) {
    return this.provincias.some(item => item.nombre == provincia);
  }

  /**
   * Método que se lanza en el inicio del componente.
   * Conecta el socket y prepara todos los datos para su mostrado
   */
  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.filtrosReducidos = window.innerHeight < 850;
    this.cdr.detectChanges();

    if(localStorage.getItem('filtros')) GlobalVariables.filters["licitaciones"] = JSON.parse(localStorage.getItem('filtros'));
    if(localStorage.getItem('orden')) GlobalVariables.orden = JSON.parse(localStorage.getItem('orden'));
    if(localStorage.getItem('ordenMostrar')) this.ordenMostrar = JSON.parse(localStorage.getItem('ordenMostrar'));

    this.modoPlataforma = GlobalVariables.modoPlataforma;

    this.ordenMostrar.estado = false;
    this.ordenMostrar.inicio = false;
    this.ordenMostrar.fin = false;

    this.userSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      }
      if (msg.verb == "destroyed") {
        if(msg.previous.bloqueado.id == this.authenticationService.currentUserValue.id) {
          for(let bloqueo of this.currentUser.bloqueadosMi) {
            if(bloqueo.id == msg.id) {
              const index = this.currentUser.bloqueadosMi.indexOf(bloqueo, 0);
              if (index > -1) {
                this.currentUser.bloqueadosMi.splice(index, 1);
              }
              this.filterDatatable();
              break;
            }
          }
        }
        
      } else if(msg.verb == "created"){
        if(msg.data.bloqueado.id == this.authenticationService.currentUserValue.id) {
          if(!this.currentUser.bloqueadosMi) this.currentUser.bloqueadosMi = [];
          msg.data.bloqueado = msg.data.bloqueado.id;
          msg.data.user = msg.data.user.id;
          this.currentUser.bloqueadosMi.push(msg.data);
          this.filterDatatable();
        } 
      }
    }

    self.io.socket.on('user_bloqueo', this.userSusc.bind(this));

    var loadedUser = function(user) {
    }
    self.io.socket.get('/user_bloqueo', loadedUser.bind(this));

    this.userService.getById(this.authenticationService.currentUserValue.id)
    .subscribe(user => {
      console.warn(user);

      this.currentUser = user;


      if(this.currentUser.demo && this.router.url === '/licitaciones') {
        var steps = [
          { //" + this.translate.instant('DEMO.HOME.STEP') + "
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP1') + "</h4>"
          },
          { 
            element:'#step-DEMO_01',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP2') + "</h4>",
            position: 'right'
          }
        ];
        if(this.currentUser.ofertante) {
          steps.push({ 
            element:'#step-DEMO_01-sup',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP3') + "</h4>",
            position: 'right'
          });
        } else {
          steps.push({ 
            element:'#step-DEMO_01-sup',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP4') + "</h4>",
            position: 'right'
          });
        }

        steps.push({ 
          element:'#step-DEMO_01-serv',
          intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP5') + "</h4>",
          position: 'right'
        });
        steps.push({ 
          element:'#step-DEMO_01-loc',
          intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP6') + "</h4>",
          position: 'right'
        });
        steps.push({ 
          element:'#step-DEMO_01-time',
          intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP7') + "</h4>",
          position: 'right'
        });

        if(this.currentUser.ofertante) {
          steps.push({ 
            element:'#step-DEMO_01-estado',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP8') + "</h4>",
            position: 'right'
          });
          steps.push({ 
            element:'#step-DEMO_01-boton',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP9') + "</h4>",
            position: 'right'
          });
          steps.push({ 
            element:'#step-DEMO_03-traza',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP10') + "</h4>",
            position: 'left'
          });
          steps.push({ 
            element:'#step-DEMO_03-boton',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP11') + "</h4>",
            position: 'left'
          });
        } else {
          steps.push({ 
            element:'#step-DEMO_01-estado',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP12') + "</h4>",
            position: 'right'
          });
          steps.push({ 
            element:'#step-DEMO_01-boton',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP13') + "</h4>",
            position: 'right'
          });
          steps.push({ 
            element:'#step-DEMO_03-traza',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP14') + "</h4>",
            position: 'left'
          });
          steps.push({ 
            element:'#step-DEMO_03-boton',
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP15') + "</h4>",
            position: 'left'
          });
        }

        steps.push({ 
          element:'#step-filtros',
          intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP16') + "</h4>",
          position: 'right'
        });

        if(this.currentUser.ofertante) {
          steps.push({ 
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP17') + "</h4>"
          });
        } else {
          steps.push({ 
            intro: "<h4>" + this.translate.instant('DEMO.LICIT.STEP18') + "</h4>"
          });
        }

        //HINTS
        var hints = [
          { 
            element:'#step-DEMO_01',
            hint: "<h4>Esto es una licitación. En ella encontrarás los detalles de los servicios, su localización, el tiempo restante y el importe actual.</h4>",
            hintPosition: 'top-left'
          },
          { 
            element:'#step-DEMO_01-serv',
            hint: "<h4>Estos son los servicios incluidos en una licitación. Al pulsar sobre ellos podrás ver su detalle. Los servicios con el icono <b style='color: red'>+</b> indican que el licitador ha incluido un detalle específico</h4>",
            hintPosition: 'middle-left'
          },
          { 
            element:'#step-DEMO_02-serv',
            hint: "<h4>Esto es un servicio que incluye un traslado. Al pulsar en el icono podrás ver el detalle del mismo.</h4>",
            hintPosition: 'middle-middle'
          },
          { 
            element:'#step-DEMO_01-loc',
            hint: "<h4>Aquí se indica la localización en la que se realizará el servicio o el origen y destino en los traslados</h4>",
            hintPosition: 'middle-left'
          },
          { 
            element:'#step-DEMO_01-time',
            hint: "<h4>Este es el tiempo restante para que finalice la licitación.</h4>",
            hintPosition: 'middle-middle'
          }
        ];

        if(this.currentUser.ofertante) {
          hints.push({ 
            element:'#step-DEMO_01-estado',
            hint: "<h4>En este espacio observarás el estado actual de la licitación. Esta puede estar abierta, pendiente de adjudicación, pendiente de ejecución, completada, cerrada o desierta.</h4>",
            hintPosition: 'middle-middle'
          });
          hints.push({ 
            element:'#step-DEMO_01-boton',
            hint: "<h4>Aqui encontrarás el importe actual de la licitación y podrás acceder al detalle de sus pujas o inscritos.</h4>",
            hintPosition: 'bottom-middle'
          });
          hints.push({ 
            element:'#step-DEMO_03-traza',
            hint: "<h4>Una vez se haya asignado una licitación podrás ver el progreso de la trazabilidad y los detalles de la funeraria adjudicada.</h4>",
            hintPosition: 'middle-middle'
          });
          hints.push({ 
            element:'#step-DEMO_03-boton',
            hint: "<h4>Además, en el siguiente menu podrás ver el detalle de la trazabilidad que rellenará la funeraria.</h4>",
            hintPosition: 'bottom-middle'
          });
        } else {
          hints.push({ 
            element:'#step-DEMO_01-estado',
            hint: "<h4>En este espacio observarás el estado actual de la licitación. Esta puede estar abierta (ganando/perdiendo/inscrito), en trámite, en ejecución, completada o desierta.</h4>",
            hintPosition: 'middle-middle'
          });
          hints.push({ 
            element:'#step-DEMO_01-boton',
            hint: "<h4>Aqui encontrarás el importe actual de la licitación y podrás pujar o inscribirte en la misma.</h4>",
            hintPosition: 'bottom-middle'
          });
          hints.push({ 
            element:'#step-DEMO_03-traza',
            hint: "<h4>Una vez te hayan asignado una licitación podrás ver los detalles de la empresa adjudicadora.</h4>",
            hintPosition: 'middle-middle'
          });
          hints.push({ 
            element:'#step-DEMO_03-boton',
            hint: "<h4>Si la licitación se encuentra en ejecución podrás rellenar la información de trazabilidad del servicio.</h4>",
            hintPosition: 'bottom-middle'
          });
        }

        hints.push({ 
          element:'#step-filtros',
          hint: "<h4>Podrás personalizar la vista de cards en todo momento con los diferentes filtros disponibles.</h4>",
          hintPosition: 'top-left'
        });

        this.introJS.setOptions({
          steps: steps,
          hints: hints
        });
      }

      if(GlobalVariables.filters["licitaciones"]) {
        this.filters = GlobalVariables.filters["licitaciones"].filters;
        /*this.filters.estadoMostrar = false,
        this.filters.estados = {
          pendienteInicio: false,
          abierta: false,
          pendienteAdjudicacion: false,
          adjudicacionTramite: false,
          adjudicadaPendiente: false,
          ejecucion: false,
          completada: false,
          cerrada: false,
          desierta: false
        }*/
        this.filtrosOpen = GlobalVariables.filters["licitaciones"].open;
        this.servicios = GlobalVariables.filters["licitaciones"].servicios;

        this.orden = GlobalVariables.orden;
      } else {
        var pais = "TODOS";
        /*if(this.currentUser.proveedor) {
          pais = this.currentUser.pais.id;
        }*/
        this.filters = {
          servicio: "TODOS", 
          paisOrigen: pais,
          provinciaOrigen: "TODOS", 
          paisDestino: pais, 
          provinciaDestino: "TODOS",
          fechaInicio: null,
          fechaFin: null,
          precioMin: null,
          precioMax: null,
          estado: "TODOS",
          //NUEVOS
          referenciaMostrar: false,
          referencia: null,
          servicioMostrar: false,
          servicios: [],
          servicioTraslado: false,
          servicioTrasladoInt: false,
          comunidadOrigenMostrar: false,
          comunidadDestinoMostrar: false,
          paisOrigenMostrar: false,
          paisDestinoMostrar: false,
          fechaDesdeMostrar: false,
          fechaHastaMostrar: false,
          precioMinMostrar: false,
          precioMaxMostrar: false,
          estadoMostrar: false,
          estados: {
            pendienteInicio: false,
            abierta: false,
            pendienteAdjudicacion: false,
            adjudicacionTramite: false,
            adjudicadaPendiente: false,
            ejecucion: false,
            completada: false,
            cerrada: false,
            desierta: false
          }
        }
        GlobalVariables.filters["licitaciones"] = {
          filters: this.filters,
          open: this.filtrosOpen
        }
        localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
        //if(this.currentUser.actividad == "Funerarias") {
          this.orden = {estado: "ABIERTA-PENDIENTE-ADJUDICADA", inicio: "", fin: ""};
        //} else {
        //  this.orden = {estado: "PENDIENTE-ADJUDICADA-ABIERTA", inicio: "", fin: ""};
        //}        
      }

      if(window.innerWidth < 576) this.filtrosOpen = false;
      this.cdr.detectChanges();

      if(this.currentUser.proveedor && this.currentUser.suscripciones.length > 0) {
        var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
        if(!suscrip.pagado) {
          this.pagosPendientes = true;
          this.currentUser.suscripcion = suscrip;
          this.currentUser.suscripcion.provincias = [{}];
          this.currentUser.suscripcion.paises = [{}];
          this.cdr.detectChanges();
        } else {
          suscrip.startDateStr = moment(suscrip.startDate).format("DD/MM/YYYY");
          this.currentUser.suscripcion = suscrip;
          try {
              if(this.currentUser.suscripcion.provincias) this.currentUser.suscripcion.provincias = JSON.parse(this.currentUser.suscripcion.provincias);
              if(this.currentUser.suscripcion.paises) this.currentUser.suscripcion.paises = JSON.parse(this.currentUser.suscripcion.paises);
            } catch (e) {
          }

          ///////////////////

          if(suscrip.stripeSusc) {
            this.paymentService.getSubscription(suscrip.stripeSusc)
              .subscribe(
                subscription => {
                  if(subscription.status != "active") {
                    this.suscripcionActiva = false;
                  }
                });
          }

          ///////////////////
        }
      }

      if(this.modoPlataforma != "WFN-AD") {
        this.loadPaises();
        this.loadProvincias();
        this.getParticipaciones();
      }
      this.loadTrazabilidad();
      
      var cbkServicios = function(res) {
        console.warn(res);
        for(var i = 0; i < res.length; i++){
          res[i].val = false;
          res[i].comentarios = null;
          res[i].importeSalida = null;
          res[i].error = {importeSalida: false};
          res[i].mostrar = false;
          this.serviciosHash[res[i].id+""] = res[i];
        }
        this.servicios = res;
        this.servicios.sort(function (a, b) {
          if (a.orden < b.orden) {
            return -1;
          } else if (a.orden > b.orden) {
            return 1;
          }
          return 0;
        }.bind(this));
      }

      var cargado = false;

      if(this.modoPlataforma == "WFN-AD") {
        this.loadLicitacionesB2B();
        this.loadCentros();
      }

      var connectSails = function(){
        if(!self.io.socket) {
          return;
        }
        if(self.io.socket._isConnecting) {
          return;
        }

        self.io.socket.get('/servicio', cbkServicios.bind(this));

        if(this.modoPlataforma != "WFN-AD") {
          this.loadLicitaciones()
        }

        //CONTROL DE CONCURRENCIA - Funerarias
        if(this.currentUser.proveedor) {
          self.io.socket.post('/conexion', {user: this.currentUser.id});
          self.io.socket.on('conexion', this.eventConexiones.bind(this));
        }

        if(this.modoPlataforma != "WFN-AD") {
          const source = interval(1000);
          this.subscription = source.subscribe(val => this.procesoCountdown());
        }
        
      }

      if(!self.io.socket) {

        var reintentar = function(){
          if(!self.io.socket) {
            setTimeout(() => { 
              var fn2 = reintentar.bind(this);
              fn2();
            }, 1000);
          } else {
            if(self.io.socket.isConnected()) {
              var fn = connectSails.bind(this);
              fn();
            } else {
              const socket = self.io.socket;
      
              self.io.socket.on('connect', function onConnect(){
                setTimeout(() => { 
                  if(self.io.socket) {
                    var fn = connectSails.bind(this);
                    fn();
                  } else {
                    self.io.socket = socket;
                    var fn = connectSails.bind(this);
                    fn();
                  }
                }, 500);
              }.bind(this));
              //self.io.socket.on('connect', connectSails.bind(this));
            }   
          }
        }

        setTimeout(() => { 
          var fn2 = reintentar.bind(this);
          fn2();
        }, 1000);


        return;
      }

      if(self.io.socket.isConnected()) {
        var fn = connectSails.bind(this);
        fn();
      } else {
        const socket = self.io.socket;

        self.io.socket.on('connect', function onConnect(){
          setTimeout(() => { 
            if(self.io.socket) {
              var fn = connectSails.bind(this);
              fn();
            } else {
              self.io.socket = socket;
              var fn = connectSails.bind(this);
              fn();
            }
          }, 500);
        }.bind(this));
        //self.io.socket.on('connect', connectSails.bind(this));
      }    
    
    
    });
  }

  /**
   * Método que saca al usuario del componente cuando llega un evento de usuarios máximos.
   */
  eventConexiones(){
    this.conexionSwal.fire();
    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }
  
  /**
   * Método llamado cuando se reactiva la vista.
   * Calcula las columnas.
   */
  ngAfterViewInit() {
    this.calcularColumnas();
  }

  /**
   * Carga las participaciones del usuario en todas las licitaciones.
   */
  getParticipaciones() {
    this.licitaService.getAllByUser(this.currentUser.id)
    .subscribe(licitas => {
      for(var i = 0; i < licitas.length; i++) {
        this.participaciones[licitas[i].licitacion+""] = true;
        if(licitas[i].funeraria) this.participacionesFun[licitas[i].licitacion+""] = licitas[i].funeraria;
        if(!this.participacionesImporte[licitas[i].licitacion+""] || this.participacionesImporte[licitas[i].licitacion+""] > licitas[i].importe) this.participacionesImporte[licitas[i].licitacion+""] = licitas[i].importe;
      }
      this.filterDatatable();
			this.cdr.detectChanges();
		});
  }
  
  proceso = 0;
  /**
   * Proceso que se lanza cada segundo para actualizar la cuenta atras
   * y los estados mostrados de las diferentes cards.
   */
  procesoCountdown(){
    for(var i = 0; i < this.rows.length; i++) {
      var msStart = moment(this.rows[i].startDate).diff();
      

      if(msStart > 0){
        this.rows[i].estado = "ABRIENDO";
        var d = moment.duration(msStart);
        var days = Math.floor(d.asDays());
        var daysStr = days + "";    
        if(this.currentUser.status == 'ADMIN') {
          if(days > 0) this.rows[i].days = daysStr + " d";
        } else this.rows[i].days = daysStr + " días";
        var hours = Math.floor(d.asHours()) - (days*24);
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + " h " + moment.utc(msStart).format("mm") + " min " + moment.utc(msStart).format("ss") + " s";
        this.rows[i].countdown = s;
      } else {
        var ms = moment(this.rows[i].endDate).diff();
      
        if(ms < 0){
          if(!this.rows[i].validada){
            this.rows[i].estado = "CERRADA_PEND";
          } else {
            this.rows[i].estado = "CERRADA";
          }
          if(this.rows[i].abierta) {
            if(this.currentRow && this.currentRow.id == this.rows[i].id) {
              this.currentRow = null;
              this.row = null; 
              this.scrollUp();
            }
            this.rows[i].abierta = false;
            
            if(this.currentUser.status == 'ADMIN') this.rows[i].days = "0 d"
            else this.rows[i].days = "0 días";
            this.rows[i].countdown = "00 h 00 min 00 s";
          }
          if(!this.rows[i].validada && !this.rows[i].ultimaLicitacion) {
            this.rows[i].validada = true; //La ponemos como desierta aunque no lo esté aún por el sistema.
          }
        } else {
          if(ms < 60000 && this.rows[i].tipo != "WFP") this.rows[i].ultimoMinuto = true
          else this.rows[i].ultimoMinuto = false;
          this.rows[i].estado = "ABIERTA";
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          if(this.currentUser.status == 'ADMIN') {
            if(days > 0) this.rows[i].days = daysStr + " d";
          } else this.rows[i].days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          this.rows[i].countdown = s;
        }
      }
      if(!this.rows[i].state) this.rows[i].state = "default";


      
    }
    if(this.proceso == 0 || this.proceso == 2 || this.proceso % 5 == 0) {
      if(this.proceso == 0) {
        this.loadingInit = false;
        this.filterDatatable();
        this.calcularColumnas();
        
      } else if(this.proceso == 2) {
        this.filterDatatable();
        var usuario = this.authenticationService.currentUserValue;
        if(this.currentUser.demo && this.router.url === '/licitaciones' && !usuario.demoCompletada) {
          this.introJS.onchange(function(targetElement) {
            var step = this.introJS._currentStep;
            console.warn(step);
            if(step == 1 || step == 10) {
              setTimeout(function(){ window.scrollTo(0,0); }.bind(this), 500);
            }
          }.bind(this));

          this.introJS.start();

          usuario.demoCompletada = true;
          this.demoCompletada = true;
          this.authenticationService.changeUser(usuario);
          //this.introJS.addHints();
        } else if(this.currentUser.demo && usuario.demoCompletada) {
          this.demoCompletada = true;
        }
      }
      this.orderDatatable();
      this.cdr.detectChanges();
    }
    this.proceso++;
    this.cdr.detectChanges();
  }

  /**
   * Lanza el tutorial y controla sus eventos.
   */
  lanzarTutorial() {
    this.introJS.onchange(function(targetElement) {
      var step = this.introJS._currentStep;
      console.warn(step);
      if(step == 1 || step == 10) {
        setTimeout(function(){ window.scrollTo(0,0); }.bind(this), 500);
      }
    }.bind(this));
    
    this.introJS.start();
  }

  /**
   * Carga las licitaciones en base a la vista actual y se suscribe a sus 
   * cambios para actualizar la vista en pantalla (precios, edicion, etc.)
   */
  loadLicitaciones() {
    // - EVENTOS -
    this.eventSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      } else if (msg.verb == "created"){
        if(msg.data.demo) return;
        if(this.modoPlataforma == "WFN" && msg.data.tipo == "WFP") return;
        if(this.modoPlataforma == "WFP" && msg.data.tipo != "WFP") return;

        msg.data.estado = "ABIERTA";
        msg.data.fecha = moment(msg.data.startDate).format("DD/MM/YYYY");
        msg.data.fechaFin = moment(msg.data.endDate).format("DD/MM/YYYY HH:mm");
        if(msg.data.ultimaLicitacion){
          msg.data.precio = msg.data.ultimaLicitacion.importe;
        } else {
          msg.data.precio = msg.data.importeSalida;
        }
        var ms = moment(msg.data.endDate).diff();
      
        if(ms < 0){
          msg.data.estado = "CERRADA";
          msg.data.days = "0 días";
          msg.data.countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          if(this.currentUser.status == 'ADMIN') {
            if(days > 0) msg.data.days = daysStr + " d";
          } else msg.data.days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          msg.data.countdown = s;
        }

        //Comprobamos si pertenece a nuestra suscripción
        if(this.currentUser.proveedor && this.router.url.includes('/licitaciones') && this.currentUser.id != msg.data.creador.id && this.currentUser.suscripcion) {
          var enc = false;
          var enc2 = false;
          if(this.currentUser.suscripcion.paises.length > 0) {
            for(let pais of this.currentUser.suscripcion.paises) {
              if(pais.id == msg.data.paisOrigen.id) enc = true;
              if(msg.data.paisDestino && pais.id == msg.data.paisDestino.id) enc2 = true;
            }
          } else if(this.currentUser.suscripcion.provincias.length > 0) {
            for(let provincia of this.currentUser.suscripcion.provincias) {
              if(provincia.id == msg.data.provinciaOrigen.id) enc = true;
              if(msg.data.provinciaDestino && provincia.id == msg.data.provinciaDestino.id) enc2 = true;
            }
          } else {
            enc = true;
            enc2 = true;
          }
          if(msg.data.tipo == "SERVICIO") enc2 = true;
          if(enc && enc2) msg.data.suscripcion = true;
        } else {
          msg.data.suscripcion = true;
        }
        msg.state = "default";

        this.originalRows = [...this.originalRows, msg.data];
        this.filterDatatable();
        this.cdr.detectChanges();
      } else if(msg.verb == "updated"){
        if(msg.data.ultimaLicitacion){
          var loadLicit = function(res){
            for(var i = 0; i < this.originalRows.length; i++){
              if(this.originalRows[i].id == msg.data.id){
                this.originalRows[i].precio = res.importe;
                this.originalRows[i].ultimaLicitacion = res;
                this.originalRows[i].ultimaLicitacion.user = res.user.id;
                this.filterDatatable();
                break;
              }
            }
          }
          self.io.socket.get('/licita/'+msg.data.ultimaLicitacion, loadLicit.bind(this));
        } else if(msg.data.borrada) { //Licitación eliminada
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              this.originalRows[i].borrada = true;
              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.validada || msg.data.trazas) {  //Si se ha validado una licitación. La cargamos de nuevo.
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
                if(msg.data.validada) {
                  if(msg.data.licitacionElegida) this.originalRows[i].licitacionElegida = resData.licitacionElegida;
                  if(msg.data.usuarioElegido) this.originalRows[i].usuarioElegido = resData.usuarioElegido;
                  this.originalRows[i].validada = true;
                }
                if(msg.data.trazas) this.originalRows[i].trazas = resData.trazas;

                if(this.originalRows[i].trazas) { //Esto es que ya está validado
                  var trazas = JSON.parse(this.originalRows[i].trazas);
                  this.originalRows[i].trazabilidad = [];
                  this.cdr.detectChanges();
                  var comp = 0;
                  if(this.row && this.row.id == msg.data.id) this.trazas = [];
                  for(let traza in trazas){
                    if(trazas[traza].completado) {
                      comp++;
                    }
                    trazas[traza].codigo = traza;
                    if(this.row && this.row.id == msg.data.id) this.trazas.push(trazas[traza]);
                    this.originalRows[i].trazabilidad.push(trazas[traza]);
                  }
                  this.originalRows[i].trazas_comp = comp;
                }
                this.filterDatatable();
              }.bind(this));


              break;
            }
          }
        } else if(msg.data.endDate && !msg.data.importeSalida) { //Si se ha alargado el tiempo
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              this.originalRows[i].endDate = msg.data.endDate;


              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.id && msg.data.importeSalida) {
          self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
            resData.estado = "ABIERTA";
            resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
            resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
            if(resData.ultimaLicitacion){
              resData.precio = resData.ultimaLicitacion.importe;
            } else {
              resData.precio = resData.importeSalida;
            }

            //Comprobamos si pertenece a nuestra suscripción
            if(this.currentUser.proveedor && this.router.url.includes('/licitaciones') && this.currentUser.id != resData.creador.id && this.currentUser.suscripcion) {
              var enc = false;
              var enc2 = false;
              if(this.currentUser.suscripcion.paises.length > 0) {
                for(let pais of this.currentUser.suscripcion.paises) {
                  if(pais.id == resData.paisOrigen.id) enc = true;
                  if(resData.paisDestino && pais.id == resData.paisDestino.id) enc2 = true;
                }
              } else if(this.currentUser.suscripcion.provincias.length > 0) {
                for(let provincia of this.currentUser.suscripcion.provincias) {
                  if(provincia.id == resData.provinciaOrigen.id) enc = true;
                  if(resData.provinciaDestino && provincia.id == resData.provinciaDestino.id) enc2 = true;
                }
              } else {
                enc = true;
                enc2 = true;
              }
              if(resData.tipo == "SERVICIO") enc2 = true;
              if(enc && enc2) resData.suscripcion = true;
            } else {
              resData.suscripcion = true;
            }

            for(var i = 0; i < this.originalRows.length; i++){
              if(this.originalRows[i].id == msg.data.id){
                this.originalRows[i] = resData;
              }
            }
            this.filterDatatable();
          }.bind(this));
        } else if(typeof msg.data.validadaUsuario !== 'undefined' ) {  //Si la funeraria ha aceptado o rechazado un servicio
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              this.originalRows[i].validadaUsuario = msg.data.validadaUsuario;
              this.cdr.detectChanges();

              break;
            }
          }
        }
      }
    }

    this.originalRows = [];

    var loadedLicitaciones = function(resData){
      //this.originalRows = resData;
      //this.originalRows.concat(resData);
      this.originalRows.push(...resData);
      
      for(var i = 0; i < this.originalRows.length; i++) {
        this.originalRows[i].estado = "ABIERTA";
        this.originalRows[i].fecha = moment(this.originalRows[i].startDate).format("DD/MM/YYYY");
        this.originalRows[i].fechaFin = moment(this.originalRows[i].endDate).format("DD/MM/YYYY HH:mm");
        if(this.originalRows[i].ultimaLicitacion){
          this.originalRows[i].precio = this.originalRows[i].ultimaLicitacion.importe;
        } else {
          this.originalRows[i].precio = this.originalRows[i].importeSalida;
        }
        var ms = moment(this.originalRows[i].endDate).diff();
      
        if(ms < 0){
          this.originalRows[i].estado = "CERRADA";
          this.originalRows[i].days = "0 días";
          this.originalRows[i].countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.originalRows[i].countdown = s;
        }

        //Comprobamos si pertenece a nuestra suscripción
        if(this.currentUser.proveedor && this.router.url.includes('/licitaciones') && this.currentUser.id != this.originalRows[i].creador.id && this.currentUser.suscripcion) {
          var enc = false;
          var enc2 = false;
          if(this.currentUser.suscripcion.paises.length > 0) {
            for(let pais of this.currentUser.suscripcion.paises) {
              if(pais.id == this.originalRows[i].paisOrigen.id) enc = true;
              if(this.originalRows[i].paisDestino && pais.id == this.originalRows[i].paisDestino.id) enc2 = true;
            }
          } else if(this.currentUser.suscripcion.provincias.length > 0) {
            for(let provincia of this.currentUser.suscripcion.provincias) {
              if(provincia.id == this.originalRows[i].provinciaOrigen.id) enc = true;
              if(this.originalRows[i].provinciaDestino && provincia.id == this.originalRows[i].provinciaDestino.id) enc2 = true;
            }
          } else {
            enc = true;
            enc2 = true;
          }
          if(this.originalRows[i].tipo == "SERVICIO") enc2 = true;
          if(enc && enc2) this.originalRows[i].suscripcion = true;
        } else {
          this.originalRows[i].suscripcion = true;
        }
        this.originalRows[i].state = "default";

        if(this.originalRows[i].trazas) { //Esto es que ya está validado
          var trazas = JSON.parse(this.originalRows[i].trazas);
          this.originalRows[i].trazabilidad = [];
          this.cdr.detectChanges();
          var comp = 0;
          for(let traza in trazas){
            if(trazas[traza].completado) {
              comp++;
            }
            trazas[traza].codigo = traza;
            this.originalRows[i].trazabilidad.push(trazas[traza]);
          } 
          this.originalRows[i].trazas_comp = comp;
        }

        if(this.originalRows[i].validada && this.originalRows[i].pujas && this.originalRows[i].licitacionElegida) {
          //Cogemos el precio del elegido, por si es superior a la última.
          this.originalRows[i].precio = this.originalRows[i].licitacionElegida.importe;
        }
      }
      this.filterDatatable();
      this.cdr.detectChanges();
    }

    if(!this.currentUser.demo) {
      if(this.modoPlataforma == "WFN") {
        self.io.socket.get('/licitacion', {demo: false, tipo: { '!=' : "WFP" }}, loadedLicitaciones.bind(this));
      } else if(this.modoPlataforma == "WFP") {
        self.io.socket.get('/licitacion', {demo: false, tipo: "WFP"}, loadedLicitaciones.bind(this));
      }
    } else {
      //self.io.socket.get('/licitacion', {demo: true, usuarioDemo: this.currentUser.id}, loadedLicitaciones.bind(this));

      if(this.modoPlataforma == "WFN") {
        self.io.socket.get('/licitacion', {demo: true, tipo: { '!=' : "WFP" }, usuarioDemo: this.currentUser.id}, loadedLicitaciones.bind(this));
      } else if(this.modoPlataforma == "WFP") {
        self.io.socket.get('/licitacion', {demo: true, tipo: "WFP", usuarioDemo: this.currentUser.id}, loadedLicitaciones.bind(this));
      }
    }

    /*if(this.router.url === '/licitaciones') {
      self.io.socket.get('/licitacion', {abierta: 1}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 0}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 1, licitacionElegida: { '!=' : null }}, loadedLicitaciones.bind(this));
    } else {
      self.io.socket.get('/licitacion', loadedLicitaciones.bind(this));
    }*/

    self.io.socket.on('licitacion', this.eventSusc.bind(this));
  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
      else return num_parts[0];
      /*var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return num_parts.join(".");*/
    } else {
      return '';
    }
    
  }

  /**
   * Carga el maestro de trazas.
   */
  loadTrazabilidad(){
		this.trazabilidadService.getAll()
	    .subscribe(trazas => {
        this.trazabilidad = trazas;
        this.trazabilidad.sort(function (a, b) {
          if (a.orden < b.orden) {
            return -1;
          } else if (a.orden > b.orden) {
            return 1;
          }
          return 0;
        }.bind(this)); 
        this.cdr.detectChanges();
        for(var i = 0; i < trazas.length; i++){
          this.trazabilidadHash[trazas[i].codigo+""] = trazas[i];
        }
      });
  }

  /**
   * Carga el maestro de comunidades/estados
   */
  loadProvincias(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        this.cdr.detectChanges();

        this.countryChangedOrigen();
        this.countryChangedDestino();
      });
  }

  /**
   * Carga el maestro de paises
   */
  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
		});
	}

  /**
   * Evento de cierre del componente.
   * Cierra las suscripciones y la conexión al socket.
   */
  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
    if(self.io.socket) {
      self.io.socket.off('licitacion', this.eventSusc);
      self.io.socket.off('user', this.userSusc);
      self.io.socket.off('conexion');
    }
    this.introJS.hideHints();
  }

  /**
   * Lleva al usuario al proceso de registro
   */
  registrarme() {
    //this.router.navigate(['/start']);
    this.ngZone.run(() => this.router.navigate(['/start'])).then();
  }

  /**
   * Lleva al usuario a su perfil
   */
  perfil() {
    //this.router.navigate(['/perfil']);
    this.ngZone.run(() => this.router.navigate(['/perfil'])).then();
  }

  /** ----- FILTROS ----- **/

  filters = null;

  /**
   * Evento de cambio de filtro de pais de origen
   */
  countryChangedOrigen() {
    this.estadosOrigen = [];
    if(this.filters.paisOrigen != "TODOS") {
      for(var i = 0; i < this.estados.length; i++) {
        if(this.estados[i].countryId == this.filters.paisOrigen) this.estadosOrigen.push(this.estados[i]);
      }
    }
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  /**
   * Evento de cambio de filtro de pais de destino
   */
  countryChangedDestino() {
    this.estadosDestino = [];
    if(this.filters.paisDestino != "TODOS") {
      for(var i = 0; i < this.estados.length; i++) {
        if(this.estados[i].countryId == this.filters.paisDestino) this.estadosDestino.push(this.estados[i]);
      }
    }
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  /**
   * Muestra el panel de filtros si el usuario tiene permisos para ello
   */
  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    GlobalVariables.filters["licitaciones"].open = this.filtrosOpen;
    localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
    this.cdr.detectChanges();
    if(this.currentUser.status == "INVITADO" && this.filtrosOpen){
      this.registerSwal.fire();
    } else if(this.currentUser.status == "PENDIENTE" && this.filtrosOpen){
      this.registerValidarSwal.fire();
    }
    this.calcularColumnas();
  }

  /**
   * Comprueba si el usuario es invitado y muestra un error si lo es.
   */
  checkInvitado() {
    if(this.currentUser.status == "INVITADO" && this.filtrosOpen){
      this.registerSwal.fire();
    } else if(this.currentUser.status == "PENDIENTE" && this.filtrosOpen){
      this.registerValidarSwal.fire();
    }
  }

  /**
   * Filtra el listado de licitaciones en base a los filtros seleccionados.
   */
  filterDatatable(){
    if(this.modoPlataforma == "WFN-AD") return;
    this.rows = [];
    this.cdr.detectChanges();
    GlobalVariables.filters["licitaciones"].filters = this.filters;
    GlobalVariables.filters["licitaciones"].servicios = this.servicios;

    GlobalVariables.orden = this.orden;

    localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
    localStorage.setItem('orden', JSON.stringify(GlobalVariables.orden));
    localStorage.setItem('ordenMostrar', JSON.stringify(this.ordenMostrar));
    console.warn(this.router);

    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      if(item.borrada) mostrar = false;
      
      if(this.router.url.includes('/mislicitaciones')){
        if(item.creador.id != this.currentUser.id && !this.participaciones[item.id]) mostrar = false;
      } else if(this.router.url.includes('/trazabilidad')){
        if(item.creador.id == this.currentUser.id || (item.creador.id != this.currentUser.id && item.validada && item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id)) {
          //mostrar = false;
        } else {
          mostrar = false
        }
        if(!item.trazabilidad) mostrar = false;
        if(item.estado != 'CERRADA') mostrar = false;
      } else if(this.router.url.includes('/pendientes')){
        if(item.abierta || item.validada || item.creador.id != this.currentUser.id) mostrar = false;
      } else if(this.router.url.includes('/licitaciones')){
        if(item.validada && !item.ultimaLicitacion && !this.currentUser.demo) mostrar = false;
      }

      if(this.currentUser.proveedor && this.currentUser.pais.id != 207 && (this.router.url == '/licitaciones' || this.router.url == '/trazabilidad' || this.router.url == '/mislicitaciones')) {
        if(item.tipo == "TRASLADO" && item.paisOrigen.id != item.paisDestino.id) {
          mostrar = false;
        }
      } else if(this.currentUser.proveedor && this.currentUser.pais.id != 207 && (this.router.url == '/licitaciones/int' || this.router.url == '/trazabilidad/int' || this.router.url == '/mislicitaciones/int')) {
        if(item.tipo != "TRASLADO" || item.paisOrigen.id == item.paisDestino.id) {
          mostrar = false;
        }
      }

      if(this.currentUser.bloqueadosMi)
        for(let bloqueo of this.currentUser.bloqueadosMi) {
          if(bloqueo.bloqueado == this.currentUser.id && item.creador.id == bloqueo.user && !this.participaciones[item.id]) {
            mostrar = false;
          }
        }

      if(this.currentUser.proveedor && this.currentUser.autorizadosMi && item.creador.listaBlanca) {
        var enc = false;
        for(let autoriz of this.currentUser.autorizadosMi) {
          if(autoriz.autorizado == this.currentUser.id && item.creador.id == autoriz.user) {
            enc = true;
          }
        }
        if(!enc) mostrar = false;
      } else if(this.currentUser.proveedor && !this.currentUser.autorizadosMi && item.creador.listaBlanca) {
        mostrar = false;
      }
        

      //Servicios
      var sel = false; //Comprobamos si hay alguno seleccionado
      if(this.servicios) {
        for(let serv_orig of this.servicios) {
          if(serv_orig.mostrar) sel = true;
        }
        if(this.filters.servicioTraslado || this.filters.servicioTrasladoInt) sel = true;
        if(sel) {
          if(item.tipo == "TRASLADO" && item.paisOrigen.id == item.paisDestino.id && !this.filters.servicioTraslado) mostrar = false;
          if(item.tipo == "TRASLADO" && item.paisOrigen.id != item.paisDestino.id && !this.filters.servicioTrasladoInt) mostrar = false;
          if(item.tipo == "SERVICIO" || item.tipo == "AMBOS" || item.tipo == "WFP") {
            var enc = false;
            for (let servicio of item.servicios) {
              for(let serv_orig of this.servicios) {
                if(serv_orig.mostrar && servicio.id == serv_orig.id) enc = true;
              }
            }
            if(item.tipo == "AMBOS" && item.paisOrigen.id == item.paisDestino.id && this.filters.servicioTraslado) enc = true;
            if(item.tipo == "AMBOS" && item.paisOrigen.id != item.paisDestino.id && this.filters.servicioTrasladoInt) enc = true;
            if(!enc) mostrar = false;
          }
        }
      }
      
      if(this.filters.referencia != null && this.filters.referencia != "" && !item.referencia.toUpperCase().includes(this.filters.referencia.toUpperCase()) 
          && !item.referenciaCliente.toUpperCase().includes(this.filters.referencia.toUpperCase())) mostrar = false;

      if(item.tipo == "TRASLADO" || item.tipo == "AMBOS" || item.tipo == "WFP") {
        if(this.filters.paisOrigen != "TODOS" && item.paisOrigen.id != this.filters.paisOrigen) mostrar = false;
        if(this.filters.provinciaOrigen != "TODOS" && item.provinciaOrigen.id != this.filters.provinciaOrigen) mostrar = false;

        if(this.filters.paisDestino != "TODOS" && item.paisDestino && item.paisDestino.id != this.filters.paisDestino) mostrar = false;
        if(this.filters.provinciaDestino != "TODOS" && item.provinciaDestino && item.provinciaDestino.id != this.filters.provinciaDestino) mostrar = false;
      } else if(item.tipo == "SERVICIO") {
        //if(this.filters.paisOrigen != "TODOS") mostrar = false;

        if(this.filters.paisDestino != "TODOS" && item.paisOrigen && item.paisOrigen.id != this.filters.paisDestino) mostrar = false;
        if(this.filters.provinciaDestino != "TODOS" && item.provinciaOrigen && item.provinciaOrigen.id != this.filters.provinciaDestino) mostrar = false;
      }

      
      
      if(this.filters.fechaInicio && this.filters.fechaInicio != null) {
        var ini = moment(this.filters.fechaInicio).unix();
        var orig = moment(item.startDate).unix();
        if(orig < ini) mostrar = false;
      }

      if(this.filters.fechaFin && this.filters.fechaFin != null) {
        var fin = moment(this.filters.fechaFin).unix();
        var orig = moment(item.startDate).unix();
        if(orig > fin) mostrar = false;
      }

      if(this.filters.precioMin != null && this.filters.precioMin > -1 && item.precio < this.filters.precioMin) mostrar = false;
      if(this.filters.precioMax != null && this.filters.precioMax > 0 && item.precio > this.filters.precioMax) mostrar = false;

      if(this.currentUser.proveedor && item.ad && !this.participaciones[item.id]) mostrar = false;

      //Estados
      var sel = false; //Comprobamos si hay alguno seleccionado
      for(let estado in this.filters.estados) {
        if(this.filters.estados[estado]) sel = true;
      }
      if(sel) {
        var estados = this.filters.estados;
        var enc = false;
        if(estados.pendienteInicio && item.estado == 'ABRIENDO') enc = true;
        if(estados.abierta && item.estado == 'ABIERTA') enc = true;
        if(estados.pendienteAdjudicacion && !item.abierta && !item.validada && item.creador.id == this.currentUser.id) enc = true;
        if(estados.adjudicacionTramite && !item.abierta && !item.validada && item.creador.id != this.currentUser.id && this.participaciones[item.id]) enc = true;
        if(estados.adjudicadaPendiente && item.estado == 'CERRADA' && item.creador.id == this.currentUser.id && item.licitacionElegida && (!item.trazabilidad || item.trazas_comp != item.trazabilidad.length)) enc = true;
        if(estados.ejecucion && item.estado == 'CERRADA' && item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id && item.licitacionElegida && (!item.trazabilidad || item.trazas_comp != item.trazabilidad.length)) enc = true;
        if(estados.completada && item.estado == 'CERRADA' && (item.creador.id == this.currentUser.id || (item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id)) && item.licitacionElegida && item.trazabilidad && item.trazas_comp == item.trazabilidad.length) enc = true;
        if(estados.cerrada && !item.abierta && !item.validada && item.creador.id != this.currentUser.id && !this.participaciones[item.id]) enc = true;
        if(estados.cerrada && item.estado == 'CERRADA' && item.creador.id != this.currentUser.id && item.licitacionElegida && (!this.participaciones[item.id] || this.participaciones[item.id] && (!item.usuarioElegido || item.usuarioElegido.id != this.currentUser.id))) enc = true;
        if(estados.desierta && item.estado == 'CERRADA' && item.validada && !item.licitacionElegida) enc = true;
        if(!enc) mostrar = false;
      }

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
    
    var temp = [...this.rows];
    this.rows = [];
    this.rows = temp;
    
    this.orderDatatable();
    this.cdr.detectChanges();
  }

  /**
   * Ordena el listado de licitaciones en base a los filtros seleccionados
   */
  orderDatatable(){
    if(this.orden.estado == "PENDIENTE-ADJUDICADA-ABIERTA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 4;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 5;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 7;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 7;
        /*adjudicadaPendiente*/ if(a.estado == 'CERRADA' && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 6;
        /*ejecucion*/ if(a.estado == 'CERRADA' && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 6;
        /*completada*/ if(a.estado == 'CERRADA' && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 6;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(a.estado == 'CERRADA' && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(a.estado == 'CERRADA' && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 4;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 5;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 7;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 7;
        /*adjudicadaPendiente*/ if(b.estado == 'CERRADA' && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 6;
        /*ejecucion*/ if(b.estado == 'CERRADA' && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 6;
        /*completada*/ if(b.estado == 'CERRADA' && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 6;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(b.estado == 'CERRADA' && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(b.estado == 'CERRADA' && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.estado == "ABIERTA-PENDIENTE-ADJUDICADA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 6;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 7;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 5;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 5;
        /*adjudicadaPendiente*/ if(a.estado == 'CERRADA' && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 4;
        /*ejecucion*/ if(a.estado == 'CERRADA' && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 4;
        /*completada*/ if(a.estado == 'CERRADA' && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 4;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(a.estado == 'CERRADA' && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(a.estado == 'CERRADA' && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 6;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 7;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 5;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 5;
        /*adjudicadaPendiente*/ if(b.estado == 'CERRADA' && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 4;
        /*ejecucion*/ if(b.estado == 'CERRADA' && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 4;
        /*completada*/ if(b.estado == 'CERRADA' && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 4;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(b.estado == 'CERRADA' && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(b.estado == 'CERRADA' && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.estado == "ADJUDICADA-PENDIENTE-ABIERTA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 4;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 5;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 6;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 6;
        /*adjudicadaPendiente*/ if(!a.abierta && a.validada && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 7;
        /*ejecucion*/ if(!a.abierta && a.validada && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 7;
        /*completada*/ if(!a.abierta && a.validada && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 7;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(!a.abierta && a.validada && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(!a.abierta && a.validada && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 4;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 5;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 6;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 6;
        /*adjudicadaPendiente*/ if(!b.abierta && b.validada && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 7;
        /*ejecucion*/ if(!b.abierta && b.validada && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 7;
        /*completada*/ if(!b.abierta && b.validada && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 7;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(!b.abierta && b.validada && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(!b.abierta && b.validada && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.inicio == "ASC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.startDate).diff();
        var msB = moment(b.startDate).diff();
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.inicio == "DESC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.startDate).diff();
        var msB = moment(b.startDate).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.fin == "ASC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA < 0 && msB > 0) return 1;
        if(msB < 0 && msA > 0) return -1;
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.fin == "DESC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    }
  }

  /**
   * Reinicia los filtros al estado por defecto.
   */
  resetFilters(){
    var pais = "TODOS";
    /*if(this.currentUser.proveedor) {
      pais = this.currentUser.pais.id;
    }*/
    this.filters = {
      servicio: "TODOS", 
      paisOrigen: pais, 
      provinciaOrigen: "TODOS", 
      paisDestino: pais, 
      provinciaDestino: "TODOS",
      fechaInicio: null,
      fechaFin: null,
      precioMin: null,
      precioMax: null,
      estado: "TODOS",
      //NUEVOS
      referenciaMostrar: false,
      referencia: null,
      servicioMostrar: false,
      servicioTraslado: false,
      servicioTrasladoInt: false,
      comunidadOrigenMostrar: false,
      comunidadDestinoMostrar: false,
      paisOrigenMostrar: false,
      paisDestinoMostrar: false,
      fechaDesdeMostrar: false,
      fechaHastaMostrar: false,
      precioMinMostrar: false,
      precioMaxMostrar: false,
      estadoMostrar: false,
      estados: {
        pendienteInicio: false,
        abierta: false,
        pendienteAdjudicacion: false,
        cerrada: false,
        adjudicacionTramite: false,
        adjudicadaPendiente: false,
        ejecucion: false,
        completada: false,
        desierta: false
      }
    }
    this.countryChangedOrigen();
    this.countryChangedDestino();

    for(let serv_orig of this.servicios) {
      serv_orig.mostrar = false;
    }
    this.filterDatatable();
  }

  // ------- ORDEN ---------

  orden = {estado: "", inicio: "", fin: ""};
  ordenMostrar = {estado: false, estadoSelect: true, inicio: false, inicioSelect: false, fin: false, finSelect: false}

  /**
   * Ordena el listado en base a su estado
   * @param orden Orden a aplicar al estado
   */
  ordenarEstado(orden){
    this.orden = {estado: orden, inicio: "", fin: ""};
    this.ordenMostrar = {estado: true, estadoSelect: true, inicio: false, inicioSelect: false, fin: false, finSelect: false};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  /**
   * Ordena el listado en base a su inicio
   * @param orden Orden a aplicar al inicio
   */
  ordenarInicio(orden){
    this.orden = {estado: "", inicio: orden, fin: ""};
    this.ordenMostrar = {estado: false, estadoSelect: false, inicio: true, inicioSelect: true, fin: false, finSelect: false};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  /**
   * Ordena el listado en base a su fin
   * @param orden Orden a aplicar al fin
   */
  ordenarFin(orden){
    this.orden = {estado: "", inicio: "", fin: orden};
    this.ordenMostrar = {estado: false, estadoSelect: false, inicio: false, inicioSelect: false, fin: true, finSelect: true};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  // ------- MODAL - No utilizado -------

  referenciaCliente = null;
  tipo = null;
  traslado = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;

  fechaInicio: any;
  fechaFin: any;
  error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  provinciaOrigen = null;
  poblacionOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;
  

  loading = false;

  submitLicit() {
    //COMPROBAR SERVICIOS
    //res[i].error = {importeSalida: false, pais: false, estado: false, poblacion: false, tipo: false, km: false}
    var errors = false;
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.km){
        this.error.km = true;
        errors = true;
      } else {
        this.error.km = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
      }
    }
    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }

    if(this.tipo == "CON") {
      this.tipo = true;
    } else {
      this.tipo = false;
    }

    var continuarGuardado = function () {
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          servicios.push(this.servicios[i]);
        }
      }
      if(servicios.length == 0) {
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationAddSwal.fire();
        this.cdr.detectChanges();

        return;
      }
      var poblacion = "";
      var provincia = null;
      var pais = null;
      if(this.traslado) {
        poblacion = this.poblacionDestino;
        provincia = this.provinciaDestino;
        pais = this.paisDestino;
      } else {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
      }

      var licit = {
        referencia: this.currentUser.id + "" + moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: "",
        creador: this.currentUser.id,
        poblacionDestino: "",
        provinciaDestino: null,
        paisDestino: null,
        km: 0,
        tipoTraslado: "",
        servicios: servicios,
        tipo: "SERVICIO"
      }
      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
        resData.suscripcion = true;
        this.originalRows = [...this.originalRows, resData];
        this.filterDatatable();
        this.cdr.detectChanges();

        for(var i = 0; i < this.servicios.length; i++){
          this.servicios[i].val = false;
          this.servicios[i].comentarios = null;
          this.servicios[i].importeSalida = null;
          this.servicios[i].error = {importeSalida: false};
        }

        var serviciosStr = "";
        for(var i = 0; i < resData.servicios.length; i++) {
          var servicio = resData.servicios[i];
          serviciosStr += servicio.nombre;
          if(i < resData.servicios.length-1) serviciosStr += ", ";
        }
        
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationAddSwal.fire();

        //Envio mail/telegram confirmación
        var mail = {
          usuario: this.currentUser.id, 
          referencia: resData.referencia, 
          importe: resData.importeSalida, 
          servicios: serviciosStr,
          origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
          destino: null,
          fechaFin: moment(resData.endDate).format("DD/MM/YYYY HH:mm")
        }

        this.notificacionService.nueva_licitacion(mail)
        .pipe(first())
        .subscribe(
          data => {
            
          },
          error => {
            console.error(error);
        });
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    }
    
    //GUARDAR
    if(this.traslado) {
      var licit = {
        referencia: this.currentUser.id + ""+ moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: this.importeTraslado || 0,
        poblacionOrigen: this.poblacionOrigen,
        provinciaOrigen: this.provinciaOrigen,
        paisOrigen: this.paisOrigen,
        comentarios: this.comentariosTraslado || "",
        creador: this.currentUser.id,
        poblacionDestino: this.poblacionDestino,
        provinciaDestino: this.provinciaDestino,
        paisDestino: this.paisDestino,
        km: this.km,
        tipoTraslado: this.tipoTraslado,
        tipo: "TRASLADO"
      }
      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
        resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
        resData.suscripcion = true;
        this.originalRows = [...this.originalRows, resData];
        this.filterDatatable();
        this.cdr.detectChanges();
        var fn = continuarGuardado.bind(this);
        fn();

        //Envio mail/telegram confirmación
        var mail = {
          usuario: this.currentUser.id, 
          referencia: resData.referencia, 
          importe: resData.importeSalida, 
          servicios: "TRASLADO - " + resData.km + " km",
          origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
          destino: resData.poblacionDestino + " (" + resData.provinciaDestino.name + " - " + resData.paisDestino.name + ")",
          fechaFin: resData.fechaFin
        }

        this.notificacionService.nueva_licitacion(mail)
        .pipe(first())
        .subscribe(
          data => {
            
          },
          error => {
            console.error(error);
        });
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));

      
    } else {
      var fn = continuarGuardado.bind(this);
      fn();
    }    
  }

  // ----- LICITAR ------

  /** Mensajes a mostrar en el historial de licitaciones */
  my_messages = {
    'emptyMessage': 'Licitación Desierta',
    'totalMessage': 'total'
  };
  /** Mensajes a mostrar en el listado de trazas - ANTIGUO */
  msg_trazas = {
    'emptyMessage': 'No hay trazas',
    'totalMessage': 'total'
  };

  /** Variables para gestionar una licitación */
  row: any;
  currentRow: any;
  rowsLicit = [];
  trazas = null;

  /** Versión antigua */
  /*onSelect(event: any) {
    if(event.type == "click"){
      if(this.currentUser.status == "ACTIVO"){
        event.cellElement.blur();
        if(!event.row.suscripcion) {
          this.suscriptionSwal.fire();
          return;
        }
        this.row = event.row;
        if(this.row.trazas) {
          var trazas = JSON.parse(this.row.trazas);
          this.trazas = [];
          for(let traza in trazas){
            trazas[traza].codigo = traza;
            this.trazas.push(trazas[traza]);
          }
        } else {
          this.trazas = [];
        }
        this.currentRow = this.row;
        this.vista = 'detalle';
        this.cdr.detectChanges();
        KTUtil.scrollTop();
        this.loadLicita();
        this.importeLicitar = null;
        if(this.importeField) this.importeField.nativeElement.focus();
      } else if(this.currentUser.status == "INVITADO"){
        this.registerSwal.fire();
      } else if(this.currentUser.status == "PENDIENTE"){
        this.registerValidarSwal.fire();
      }
      
    }

    this.licitacionGanadora = null;
    this.licitacionesPasadas = 0;
    this.importeLicitar = null;
    this.ultima = false;
  }*/

  /**
   * Apertura de detalle de una licitación (desplegable inferior).
   * Carga la información correspondiente dependiendo del estado de la traza.
   * Panel de pujas, trazas, etc.
   * @param row Licitación a mostrar el detalle
   */
  async selectLicit(row: any) {
    var abierto = false;
    if(this.row) abierto = true;
    if(abierto && (this.currentUser.status == "ADMIN" || this.modoPlataforma == "WFN-AD") && this.currentRow && this.currentRow.id == row.id) {
      this.currentRow = false;
      this.cdr.detectChanges();
      return;
    }

    if(!row.suscripcion && this.modoPlataforma != "WFN-AD") {
      this.suscriptionSwal.fire();
      return;
    }    
    if(this.currentUser.status == "INVITADO" || !this.suscripcionActiva) {
      this.registerSwal.fire();
      return;
    }
    this.row = row;
    if(this.modoPlataforma != "WFN-AD") {
      if(this.row.trazas) {
        var trazas = JSON.parse(this.row.trazas);
        this.trazas = [];
        for(let traza in trazas){
          trazas[traza].codigo = traza;
          trazas[traza].orden = this.trazabilidadHash[trazas[traza].codigo].orden;
          if(this.trazabilidadHash[traza].lugar && !trazas[traza].completado) {
            //Pre-rellenamos los campos con el nombre de la empresa o la funeraria.
            if(this.row.licitacionElegida.funeraria) {
              for(let funeraria of this.currentUser.funerarias) {
                if(funeraria.id == this.row.licitacionElegida.funeraria) {
                  trazas[traza].lugar = funeraria.nombre;
                  trazas[traza].direccion = funeraria.domicilio;
                  trazas[traza].poblacion = funeraria.poblacion;
                  trazas[traza].cp = funeraria.cp;
                  trazas[traza].pais = funeraria.pais;
                  trazas[traza].provincia = funeraria.provincia;
                  trazas[traza].provincias = [];
                  this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
                  break;
                }
              }
            } else {
              trazas[traza].lugar = this.currentUser.empresa;
              trazas[traza].direccion = this.currentUser.domicilio;
              trazas[traza].poblacion = this.currentUser.poblacion;
              trazas[traza].cp = this.currentUser.cp;
              trazas[traza].pais = this.currentUser.pais.id;
              trazas[traza].provincia = this.currentUser.provincia.id;
              trazas[traza].provincias = [];
              this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
            }
          }

          this.trazas.push(trazas[traza]);
        }     
        this.trazas.sort(function (a, b) {
          if (a.orden < b.orden) {
            return -1;
          } else if (a.orden > b.orden) {
            return 1;
          }
          return 0;
        }.bind(this));
      } else {
        this.trazas = [];
      }
    }
    
    this.currentRow = this.row;
    //this.vista = 'detalle';
    this.cdr.detectChanges();
    //KTUtil.scrollTop();
    this.importeLicitar = null;
    if(this.importeField) this.importeField.nativeElement.focus();
    this.funerariaLicitar = null;
    if(this.row.tipo == "WFP" && this.participacionesFun[this.row.id+""]) this.funerariaLicitar = this.participacionesFun[this.row.id+""];
    this.licitacionGanadora = null;
    this.licitacionesPasadas = 0;
    this.importeLicitar = null;
    this.ultima = false;
    this.loadLicita();
    this.cdr.detectChanges();

    if(!abierto && this.currentUser.status != "ADMIN" && this.modoPlataforma != "WFN-AD") {
      window.scroll({
        top: window.scrollY + 300,
        behavior: 'smooth'
      });
    }

    if(this.modoPlataforma == "WFN-AD") {
      if(this.currentRow.trazas) {
        let completadas = 0;
        for(let traza of this.currentRow.trazas) {
          if(traza.completado) completadas++;
        }
        this.currentRow.trazas_comp = completadas;
      }
      this.currentRow.vistaDetalle = "comentarios";
      this.currentRow.documentos = [];
      this.currentRow.fusionados = [];
      this.currentRow.comentarios = [];
      this.vistaDetalleMovil = 1;
      // Carga comentarios
      let coms = await this.b2bService.getComentarios(this.currentRow.id);

      let comentarios = [];
      for(let com of coms) {
        com.fechaStr = moment(com.fecha).format("DD/MM/YYYY HH:mm");
        if(this.currentUser.status == "ADMIN" || !com.interno) comentarios.push(com);
      }
      this.currentRow.comentarios = comentarios;
       



      // Carga documentos
      let docs = await this.b2bService.getDocumentos(this.currentRow.id);

      let documentos = [];
      for(let doc of docs) {
        doc.fechaStr = moment(doc.fecha).format("DD/MM/YYYY HH:mm");
        if(this.currentUser.status == "ADMIN" || !doc.interno) documentos.push(doc);
      }

      this.currentRow.documentos = documentos;

      // Carga presupuestos
      let press = await this.solicitudService.getByB2BSolicitud(this.currentRow.id);
      console.warn(press);
      for(let pres of press) {
        pres.fechaStr = moment(pres.fecha).format("DD/MM/YYYY HH:mm");
      }
      this.currentRow.presupuestos = press;

      // Carga fusionados
      let fusion = await this.b2bService.getFusionados(this.currentRow.id);
      console.warn(fusion);
      for(let fus of fusion) {
        fus.fechaStr = moment(fus.fechaEntrada).format("DD/MM/YYYY HH:mm");
      }
      this.currentRow.fusionados = fusion;

      this.cdr.detectChanges();
    }
     
  }

  /**
   * Carga las comunidades que le corresponden a una traza.
   * @param pais Pais del que cargar las comunidades
   * @param row Objeto de la traza a la que cargar las comunidades
   */
  loadProvinciasTraza(pais: any, row: any) {
    row.provincias = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == pais) row.provincias.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  /**
   * Carga las certificaciones de la funeraria elegida
   * @param licit Objeto de la licitación de la que cargar la info.
   */
  cargarCertElegido(licit: any) {
    if(licit.usuarioElegido) {
      this.certificadoService.getByUser(licit.usuarioElegido.id)
        .subscribe(data => {

          licit.usuarioElegido.certificados = [];

          for(let doc of data) {
            if(doc.estado == "CORRECTO") licit.usuarioElegido.certificados.push(doc);
          }
          this.cdr.detectChanges();
        });
    }
  }

  /**
   * Carga los certificados de un participante
   * @param licit Objeto de la puja
   */
  cargarCertParticipantes(licit: any) {
    this.certificadoService.getByUser(licit.user.id)
      .subscribe(data => {

        licit.user.certificados = [];

        for(let doc of data) {
          if(doc.estado == "CORRECTO") licit.user.certificados.push(doc);
        }
        
        this.cdr.detectChanges();
      });
  }

  /**
   * Hace scroll hasta la zona superior de la pantalla.
   */
  scrollUp() {
    window.scroll({
      top: window.scrollY - 300,
      behavior: 'smooth'
    });
  }

  importeLicitar = null;
  funerariaLicitar = null;

  /**
   * Puja rápida. Comprueba que es correcta y muestra confirmación.
   * @param cantidad Cantidad a rebajar el importe actual
   */
  confirmarLicitacionRapida(cantidad: any){
    var importe = this.row.precio - cantidad;

    if(importe < 0) {
      this.errorNegativeSwal.fire();
      return;
    }

    this.importeLicitar = importe;
    var divisa = "€";
    if(this.row.divisa != "EUR") divisa = this.row.divisa;
    if(this.currentUser.id == this.row.creador.id){ //No usado, siempre va al else
      this.confirmaSwal.text = "¿Confirmas el cambio de precio de la licitación por " + this.importeLicitar + " " + divisa + " ?";
    } else {
      this.confirmaSwal.text = this.translate.instant('LICIT.SWAL.confirmaSwal_TEXT1', {importeLicitar: this.importeLicitar, divisa: divisa});
      //this.confirmaSwal.text = "¿Confirmas la licitación por " + this.importeLicitar + " " + divisa + " ?";
    }

    this.confirmaSwal.fire();
  }

  /**
   * Puja de importe personalizado. Comprueba que es correcta y muestra confirmación.
   */
  confirmarLicitacion() {
    if(this.importeLicitar == null) return;
    if(this.row.precio && this.importeLicitar >= this.row.precio && this.currentUser.id != this.row.creador.id){
      this.errorSwal.fire();
      return;
    }

    if(this.importeLicitar < 0) {
      this.errorNegativeSwal.fire();
      return;
    }

    var divisa = "€";
    if(this.row.divisa != "EUR") divisa = this.row.divisa;
    if(this.currentUser.id == this.row.creador.id){ //No usado, siempre va al else
      this.confirmaSwal.text = "¿Confirmas el cambio de precio de la licitación por " + this.importeLicitar + " " + divisa + " ?";
    } else {
      this.confirmaSwal.text = this.translate.instant('LICIT.SWAL.confirmaSwal_TEXT1', {importeLicitar: this.importeLicitar, divisa: divisa});
      //this.confirmaSwal.text = "¿Confirmas la licitación por " + this.importeLicitar + " " + divisa + " ?";
    }

    this.confirmaSwal.fire();
  }

  /**
   * Puja sin importe. Licitación precio fijo.
   * Comprueba que es correcta y muestra confirmación.
   * @returns 
   */
  confirmarLicitacionSinPuja() {
    if(this.currentUser.status == "INVITADO") {
      this.registerSwal.fire();
      return;
    }
    if(!this.row.suscripcion) {
      this.suscriptionSwal.fire();
      return;
    }
    this.confirmaSwal.text = this.translate.instant('LICIT.SWAL.confirmaSwal_TEXT2');
    
    this.confirmaSwal.fire();
  }

  loadingPuja = false;

  /**
   * Envia la licitación al servidor y muestra su resultado
   */
  licitar() {
    if(this.row.pujas && this.row.precio && this.importeLicitar >= this.row.precio && this.currentUser.id != this.row.creador.id){
      this.error2Swal.fire();
      return;
    }
    this.loadingPuja = true;

    /* SIN PUJA */
    if(!this.row.pujas) {
      this.importeLicitar = this.row.precio;

      var lic = {
        user: this.currentUser.id,
        importe: this.importeLicitar,
        licitacion: this.row.id,
        fecha: new Date(),
        nula: false
      }
      var completado = function(resData){
        console.warn(resData);
        var edit = {
          ultimaLicitacion: resData.id
        }
        var completadoLicit = function(resLicit){
          this.confirmationSwal.fire();
          this.importeLicitar = null;
          this.loadingPuja = false;
          this.row.precio = resLicit.importe;
          resData.user = resData.user.id;
          this.row.ultimaLicitacion = resData;
          this.participaciones[this.row.id+""] = true;
          if(!this.participacionesImporte[this.row.id+""] || this.participacionesImporte[this.row.id+""] > resData.importe) this.participacionesImporte[this.row.id+""] = resData.importe;
          //if(this.router.url === '/mislicitaciones') {
            this.row = null; this.currentRow = null;
          //}
          //else this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();//this.router.navigate(['/mislicitaciones']);
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].precio = resData.importe;
              this.originalRows[i].ultimaLicitacion = null;
              this.originalRows[i].ultimaLicitacion = resData;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

        self.io.socket.post('/notificacion/nueva_inscripcion', {licita: resData, licitacion: this.row});
      }
      self.io.socket.post('/licita', lic, completado.bind(this));
    } else {/* CON PUJA */
      var licit = {
        user: this.currentUser.id,
        importe: this.importeLicitar,
        licitacion: this.row.id,
        funeraria: null
      }
      if(this.row.tipo == "WFP") {
        licit.funeraria = this.funerariaLicitar;
      }
      var completado = function(resData){
        if(resData.error && resData.error.message == "Importe incorrecto") {
          this.error2Swal.fire();
          this.loadingPuja = false;
          this.cdr.detectChanges();
        } else {
          this.confirmationSwal.fire();
          this.importeLicitar = null;
          this.loadingPuja = false;
          
          this.participaciones[this.row.id+""] = true;
          if(licit.funeraria) this.participacionesFun[this.row.id+""] = licit.funeraria;
          
          if(!this.participacionesImporte[this.row.id+""] || this.participacionesImporte[this.row.id+""] > resData.importe) this.participacionesImporte[this.row.id+""] = resData.importe;
          this.scrollUp(); this.row = null; this.currentRow = null;
          this.cdr.detectChanges();
        }
      }
      self.io.socket.post('/postLicitacion', {cola: licit}, completado.bind(this));
    }
    

  }

  licitacionGanadora = null;
  licitacionesPasadas = 0;
  rowsLicitCompleta = [];

  /**
   * Carga las participaciones de una licitación.
   */
  loadLicita() {
    this.rowsLicit = [];
    this.rowsLicitCompleta = [];
    this.licitaService.getAllByLicitacion(this.row.id)
    .subscribe(licitas => {
      this.rowsLicitCompleta = licitas;
      var hashUsers = {}
      if(!this.row.abierta) {
        for(let licita of licitas) {
          if(!hashUsers[licita.user.id+""]) {
            this.rowsLicit.push(licita);
            hashUsers[licita.user.id+""] = true;
          }
        }
      } else {
        this.rowsLicit = licitas;
      }
      
      var ms1 = moment(this.row.endDate).diff();
      if(ms1 < 0 && this.rowsLicit.length > 0){
        this.licitacionGanadora = this.rowsLicit[0];
      }

      for(var i = 0; i < this.rowsLicit.length; i++) {
        this.rowsLicit[i].fecha = moment(this.rowsLicit[i].fecha).format("DD/MM/YYYY");
        var ms = moment(this.rowsLicit[i].licitacion.endDate).diff();

        if(ms <= 0 && this.row.validada && this.row.licitacionElegida && this.row.licitacionElegida.id == this.rowsLicit[i].id && this.currentUser.id == this.row.creador.id){
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa;
          this.rowsLicit[i].elegida = true;
        } else {
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa.substring(0, 3) + "*****";
        }
        
        if(ms1 < 0) this.cargarCertParticipantes(this.rowsLicit[i]);       
      }
			this.cdr.detectChanges();
		});
  }

  licitacionConfirmar = null;

  /**
   * Lanza la confirmación para elegir una participación
   * @param licit Participación a elegir
   */
  seleccionarLicitacion(licit: any) {
    this.confirmaElegidoSwal.text = this.translate.instant('LICIT.SWAL.confirmaElegidoSwal_TEXT1', {empresa: licit.user.empresa});
    this.licitacionConfirmar = licit;
    this.confirmaElegidoSwal.fire();
  }

  /**
   * Proceso de selección de una licitación.
   * Carga las trazas que correspondan y notifica la validación.
   */
  seleccionarLicitacion1() {
    var trazas = {};

    for(let traza of this.trazabilidad) {
      if(this.currentUser.pais.id == 142 && traza.grupo != "MX") continue
      else if(this.currentUser.pais.id != 142 && traza.grupo == "MX") continue;

      if(traza.grupo)
      if(this.row.tipo == "TRASLADO") {
        if(traza["TRASLADO"] == true){
          trazas[traza.codigo] = {
            completado: false,
            fecha: null,
            texto: "",
            fechaTxt: null
          }
        }
      } else {
        for(let serv of this.row.servicios) {
          if(serv.abreviatura == "SVFUN") {
            if(traza[serv.tipo] == true || traza["TANAT"] == true || traza["FLOR"] == true || traza["COCHE"] == true || traza["GEST"] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            }
          } 
          if(serv.abreviatura == "OTROS") {
            if(traza[serv.abreviatura] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            } else {
              var enc = false;
              for(let otro of serv.detalleOtros) {
                if(otro.valor && traza.codigo.includes(otro.codigo)) {
                  trazas[traza.codigo] = {
                    completado: false,
                    fecha: null,
                    texto: "",
                    fechaTxt: null
                  }
                  break;
                }
              }
              if(enc) break;
            }
          } else {
            if(traza[serv.abreviatura] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            }
          }
          
        }
      }
    }

    var edit = {
      licitacionElegida: this.licitacionConfirmar.id,
      validada: true,
      usuarioElegido: this.licitacionConfirmar.user.id,
      trazas: JSON.stringify(trazas)
    }
    var completadoLicit = function(resLicit){
      this.confirmaElegidoSuccessSwal.fire();
      
      this.row.validada = true;
      this.row.licitacionElegida = resLicit.licitacionElegida;
      this.row.usuarioElegido = resLicit.usuarioElegido;
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        trazas[traza].orden = this.trazabilidadHash[trazas[traza].codigo].orden;
        if(this.trazabilidadHash[traza].lugar && !trazas[traza].completado) {
          //Pre-rellenamos los campos con el nombre de la empresa o la funeraria.
          if(this.row.licitacionElegida.funeraria) {
            for(let funeraria of this.currentUser.funerarias) {
              if(funeraria.id == this.row.licitacionElegida.funeraria) {
                trazas[traza].lugar = funeraria.nombre;
                trazas[traza].direccion = funeraria.domicilio;
                trazas[traza].poblacion = funeraria.poblacion;
                trazas[traza].cp = funeraria.cp;
                trazas[traza].pais = funeraria.pais;
                trazas[traza].provincia = funeraria.provincia;
                trazas[traza].provincias = [];
                this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
                break;
              }
            }
          } else {
            trazas[traza].lugar = this.currentUser.empresa;
            trazas[traza].direccion = this.currentUser.domicilio;
            trazas[traza].poblacion = this.currentUser.poblacion;
            trazas[traza].cp = this.currentUser.cp;
            trazas[traza].pais = this.currentUser.pais.id;
            trazas[traza].provincia = this.currentUser.provincia.id;
            trazas[traza].provincias = [];
            this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
          }
        }
        this.trazas.push(trazas[traza]);
      }       

      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].licitacionElegida = resLicit.licitacionElegida;
          this.originalRows[i].validada = resLicit.validada;
          this.originalRows[i].usuarioElegido = resLicit.usuarioElegido;
          this.originalRows[i].trazas = resLicit.trazas;
          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }

      if(this.row.trazas) { //Esto es que ya está validado
        var trazas = JSON.parse(this.row.trazas);
        this.row.trazabilidad = [];
        this.cdr.detectChanges();
        var comp = 0;
        for(let traza in trazas){
          if(trazas[traza].completado) {
            comp++;
          }
          trazas[traza].codigo = traza;
          this.row.trazabilidad.push(trazas[traza]);
        } 
        this.row.trazas_comp = comp;
      }

      this.scrollUp(); this.row = null; this.currentRow = null;
      this.cdr.detectChanges();

      //Envio mail/telegram confirmación
      this.notificacionService.licitacion_validada({licitacion: resLicit})
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
          console.error(error);
      });

      this.notificacionService.no_adjudicados({licitacion: resLicit})
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
          console.error(error);
      });
    }
    self.io.socket.patch('/licitacion/' + this.licitacionConfirmar.licitacion.id, edit, completadoLicit.bind(this));
  }

  ultima = false;
  listanegra = null;
  fija = false;

  /**
   * Lanza la confirmación para saltar una participación
   * @param listanegra Meter al usuario en la lista negra o no
   */
  saltarLicitacion(listanegra: any) {
    this.fija = false;
    if(listanegra) {
      this.listanegra = this.licitacionGanadora.user;
      if(this.licitacionesPasadas + (this.row.pasadas || 0) > 2 || this.rowsLicit.length <= this.licitacionesPasadas+1) {
        this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT1', {empresa: this.licitacionGanadora.user.empresa});
        this.ultima = true;
      } else {
        this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT2', {empresa: this.licitacionGanadora.user.empresa, pasadas: (this.licitacionesPasadas+ (this.row.pasadas || 0)+1)});
      }
    } else {
      this.listanegra = null;
      if(this.licitacionesPasadas + (this.row.pasadas || 0) > 2 || this.rowsLicit.length <= this.licitacionesPasadas+1) {
        this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT3', {empresa: this.licitacionGanadora.user.empresa});
        this.ultima = true;
      } else {
        this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT4', {empresa: this.licitacionGanadora.user.empresa, pasadas: (this.licitacionesPasadas+ (this.row.pasadas || 0)+1)});
      }
    }

    this.confirmaSaltarSwal.fire();
  }

  licitFijaSaltar = null;

  /**
   * Lanza la confirmación para saltar una participación a precio fijo.
   * @param licit Participación a saltar
   */
  saltarLicitacionFija(licit) {
    this.fija = true;
    this.licitFijaSaltar = licit;
    this.listanegra = licit.user;
    if(this.rowsLicit.length <= 1) {
      this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT5', {empresa: licit.user.empresa});
      this.ultima = true;
    } else {
      this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT6', {empresa: licit.user.empresa});
    }

    this.confirmaSaltarSwal.fire();
  }

  /**
   * Lanza la confirmación para saltar todas las participaciones y dejar la licitación como desierta.
   */
  saltarLicitacionTodas() {
    this.listanegra = null;
    this.ultima = true;
    this.confirmaSaltarSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSwal_TEXT7');
    
    this.confirmaSaltarSwal.fire();
  }

  /**
   * Proceso de saltar una licitación
   */
  saltarLicitacion1() {
    if(!this.fija) {
      if(this.ultima == true) {
        var edit = {
          licitacionElegida: null,
          validada: true,
          usuarioElegido: null,
        }
        var completadoLicit = function(resLicit){
          this.row.validada = true;
          this.row.licitacionElegida = null;
          this.row.usuarioElegido = null;
          
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].validada = resLicit.validada;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
          this.confirmaSaltarSuccessSwal.title = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal1');
          this.confirmaSaltarSuccessSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal_TEXT2');
          this.confirmaSaltarSuccessSwal.fire();
          this.scrollUp(); this.row = null; this.currentRow = null;
          this.cdr.detectChanges();

          this.notificacionService.no_adjudicados({licitacion: resLicit})
          .pipe(first())
          .subscribe(
            data => {
              
            },
            error => {
              console.error(error);
          });
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

      } else {
        var licitacionActual = this.licitacionGanadora;
        this.licitacionesPasadas++;

        this.licitacionGanadora = this.rowsLicit[this.licitacionesPasadas];

        for(let licita of this.rowsLicitCompleta) {
          if(licita.user.id == licitacionActual.user.id) {
            var editLicita = {id: licita.id, nula: true};
            this.licitaService.update(editLicita)
              .pipe(first())
              .subscribe(
                data => {
                },
                error => {
                  console.error(error);
                });
          }
        }

        var completadoLicit = function(resLicit){

        }

        if(this.rowsLicit.length < this.licitacionesPasadas+1 + (this.row.pasadas || 0)) {
          this.licitacionesPasadas = 99;
        }

        this.row.ultimaLicitacion = this.licitacionGanadora;
        this.row.precio = this.licitacionGanadora.importe;
        var editContador = {
          pasadas: this.licitacionesPasadas + (this.row.pasadas || 0),
          ultimaLicitacion: this.licitacionGanadora.id
        }
        this.row.pasadas = this.licitacionesPasadas + (this.row.pasadas || 0);
        self.io.socket.patch('/licitacion/' + this.row.id, editContador, completadoLicit.bind(this));

        this.confirmaSaltarSuccessSwal.title = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal1');
        this.confirmaSaltarSuccessSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal_TEXT3');
        this.confirmaSaltarSuccessSwal.fire();
        this.cdr.detectChanges();
      }
    } else {
      if(this.ultima == true) {
        var edit = {
          licitacionElegida: null,
          validada: true,
          usuarioElegido: null,
        }
        var completadoLicit = function(resLicit){
          this.row.validada = true;
          this.row.licitacionElegida = null;
          this.row.usuarioElegido = null;
          
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].validada = resLicit.validada;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
          this.confirmaSaltarSuccessSwal.title = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal2');
          this.confirmaSaltarSuccessSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal_TEXT4');
          this.confirmaSaltarSuccessSwal.fire();
          this.scrollUp(); this.row = null; this.currentRow = null;
          this.cdr.detectChanges();

          this.notificacionService.no_adjudicados({licitacion: resLicit})
          .pipe(first())
          .subscribe(
            data => {
              
            },
            error => {
              console.error(error);
          });
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

      } else {
        var licitacionActual = this.licitFijaSaltar;

        for(let licita of this.rowsLicitCompleta) {
          if(licita.user.id == licitacionActual.user.id) {
            var editLicita = {id: licita.id, nula: true};
            this.licitaService.update(editLicita)
              .pipe(first())
              .subscribe(
                data => {
                  this.loadLicita();
                },
                error => {
                  console.error(error);
                });
          }
        }

        this.confirmaSaltarSuccessSwal.title = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal2');
        this.confirmaSaltarSuccessSwal.text = this.translate.instant('LICIT.SWAL.confirmaSaltarSuccessSwal_TEXT5');
        this.confirmaSaltarSuccessSwal.fire();
        this.cdr.detectChanges();
      }
    }    

    //Lo añadimos a la lista negra del usuario - TODO, comprobar que no esté ya
    if(this.listanegra != null) {
      var bloqueo = {
        user: this.currentUser.id,
        bloqueado: this.listanegra.id,
        fecha: new Date()
      }
      this.userService.postBloqueo(bloqueo)
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
            console.error(error);
            this.loading = false;
        });
    }
    
  }

  traza = null;

  /**
   * Confirma la traza seleccionada
   * @param traza Traza a validar.
   */
  confirmarTraza(traza: any){
    this.traza = traza;
    if(this.trazabilidadHash[traza.codigo].fecha && !traza.fechaInput) {
      this.errorTraza2Swal.fire();
    } else if(this.trazabilidadHash[traza.codigo].lugar && (!traza.lugar || !traza.direccion || !traza.poblacion || !traza.cp || !traza.pais || !traza.provincia)) {
      this.errorTraza3Swal.fire();
    } else if(traza.incidencia && !traza.texto) {
      this.errorTrazaSwal.fire();
    } else {
      this.confirmarTraza1();
    }
  }

  /**
   * Cancela la traza seleccionada
   * @param traza Traza a cancelar.
   */
  cancelarTraza(traza: any){
    this.traza = traza;
    this.cancelarTraza1();
  }

  /**
   * Confirma la traza seleccionada
   */
  confirmarTraza1(){
    var trazas = JSON.parse(this.row.trazas);

    trazas[this.traza.codigo].completado = true;
    trazas[this.traza.codigo].fecha = moment().format("DD/MM/YYYY HH:mm");    
    if(this.traza.fechaInput) {
      trazas[this.traza.codigo].fechaInput = this.traza.fechaInput;
      trazas[this.traza.codigo].fechaInputStr = moment(this.traza.fechaInput).format("DD/MM/YYYY HH:mm");
    }
    if(this.traza.lugar) trazas[this.traza.codigo].lugar = this.traza.lugar;
    if(this.traza.direccion) trazas[this.traza.codigo].direccion = this.traza.direccion;
    if(this.traza.poblacion) trazas[this.traza.codigo].poblacion = this.traza.poblacion;
    if(this.traza.cp) trazas[this.traza.codigo].cp = this.traza.cp;
    if(this.traza.pais) trazas[this.traza.codigo].pais = this.traza.pais;
    if(this.traza.provincia) trazas[this.traza.codigo].provincia = this.traza.provincia;
    trazas[this.traza.codigo].texto = this.traza.texto;
    trazas[this.traza.codigo].incidencia = this.traza.incidencia;

    var edit = {
      trazas: JSON.stringify(trazas)
    }
    var completadoLicit = function(resLicit){
      //this.confirmaTrazaSuccessSwal.fire();
      
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      this.cdr.detectChanges();
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        trazas[traza].orden = this.trazabilidadHash[trazas[traza].codigo].orden;
        if(this.trazabilidadHash[traza].lugar && !trazas[traza].completado) {
          //Pre-rellenamos los campos con el nombre de la empresa o la funeraria.
          if(this.row.licitacionElegida.funeraria) {
            for(let funeraria of this.currentUser.funerarias) {
              if(funeraria.id == this.row.licitacionElegida.funeraria) {
                trazas[traza].lugar = funeraria.nombre;
                trazas[traza].direccion = funeraria.domicilio;
                trazas[traza].poblacion = funeraria.poblacion;
                trazas[traza].cp = funeraria.cp;
                trazas[traza].pais = funeraria.pais;
                trazas[traza].provincia = funeraria.provincia;
                trazas[traza].provincias = [];
                this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
                break;
              }
            }
          } else {
            trazas[traza].lugar = this.currentUser.empresa;
            trazas[traza].direccion = this.currentUser.domicilio;
            trazas[traza].poblacion = this.currentUser.poblacion;
            trazas[traza].cp = this.currentUser.cp;
            trazas[traza].pais = this.currentUser.pais.id;
            trazas[traza].provincia = this.currentUser.provincia.id;
            trazas[traza].provincias = [];
            this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
          }
        }
        this.trazas.push(trazas[traza]);
      } 

      var temp = [...this.trazas];
      this.trazas = [];
      this.cdr.detectChanges();
      this.trazas = temp;
      
      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].trazas = resLicit.trazas;
          if(this.originalRows[i].trazas) {
            var trazas = JSON.parse(this.originalRows[i].trazas);
            this.originalRows[i].trazabilidad = [];
            this.cdr.detectChanges();
            var comp = 0;
            for(let traza in trazas){
              if(trazas[traza].completado) {
                comp++;
              }
              trazas[traza].codigo = traza;
              this.originalRows[i].trazabilidad.push(trazas[traza]);
            } 
            this.originalRows[i].trazas_comp = comp;
          }

          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
      
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  /**
   * Cancela la traza seleccionada
   */
  cancelarTraza1(){
    var trazas = JSON.parse(this.row.trazas);

    trazas[this.traza.codigo].completado = false;
    trazas[this.traza.codigo].fecha = null;
    trazas[this.traza.codigo].texto = null;

    var edit = {
      trazas: JSON.stringify(trazas)
    }  
    var completadoLicit = function(resLicit){
      //this.cancelaTrazaSuccessSwal.fire();
      
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      this.cdr.detectChanges();
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        if(this.trazabilidadHash[traza].lugar && trazas[traza].pais && !trazas[traza].completado) {
          this.loadProvinciasTraza(trazas[traza].pais, trazas[traza]);
        }
        this.trazas.push(trazas[traza]);
      }
      var temp = [...this.trazas];
      this.trazas = [];
      this.cdr.detectChanges();
      this.trazas = temp;
      this.cdr.detectChanges();
      
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].trazas = resLicit.trazas;
          if(this.originalRows[i].trazas) {
            var trazas = JSON.parse(this.originalRows[i].trazas);
            this.originalRows[i].trazabilidad = [];
            this.cdr.detectChanges();
            var comp = 0;
            for(let traza in trazas){
              if(trazas[traza].completado) {
                comp++;
              }
              trazas[traza].codigo = traza;
              this.originalRows[i].trazabilidad.push(trazas[traza]);
            } 
            this.originalRows[i].trazas_comp = comp;
          }

          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  borrarLoading = false;

  /**
   * Confirmación de borrado de licitación
   */
  borrar() {
    if(this.borrarLoading) return;
    this.borrarSwal.fire();
  }

  /**
   * Proceso de borrado de licitación.
   * Notifica a los participantes.
   */
  borrar1() {
    this.borrarLoading = true;
    this.cdr.detectChanges();
    var edit = {
      borrada: true,
      abierta: false
    }
    var completadoLicit = function(resLicit){
      //Mandamos mail a todos los participantes:

      var destino = null;
      if(this.row.tipo == "TRASLADO") destino = this.row.poblacionDestino + " (" + this.row.provinciaDestino.name + " - " + this.row.paisDestino.name + ")";
      var servicios = "";
      if(this.row.tipo == "TRASLADO") servicios = "TRASLADO"
      else {
        for(var i = 0; i < this.row.servicios.length; i++) {
          servicios += this.row.servicios[i].nombre; ""
          if(i < this.row.servicios.length-1) servicios += ", ";
        }
      }

      self.io.socket.post('/notificacion/licitacion_cancelada', {licitacion: this.row});

      /*this.rowsLicit = [];
      this.licitaService.getAllByLicitacion(this.row.id)
      .subscribe(licitas => {
        this.rowsLicit = licitas;

        var emailsHash = {};
        for(let licit of this.rowsLicit) {
          if(licit.user != this.currentUser.id && !emailsHash[licit.user.email]) {
            //Enviar mail
            var divisa = "€";
            if(licit.divisa != "EUR") divisa = licit.divisa;

            this.http.post(`${environment.apiUrl}/mailcancelar`, {
              destinatario: licit.user.email, 
              referencia: this.row.referencia, 
              importe: this.row.importeSalida, 
              divisa: divisa, 
              servicios: servicios,
              origen: this.row.poblacionOrigen + " (" + this.row.provinciaOrigen.name + " - " + this.row.paisOrigen.name + ")", 
              destino: destino
            }).pipe(first())
              .subscribe(
                data => {
                  
                },
                error => {
                  console.error(error);
                  this.loading = false;
                });
            emailsHash[licit.user.email] = true;
          }
        }

      });*/

      this.borrarConfirmSwal.fire();
      this.borrarLoading = false;
      this.participaciones[this.row.id+""] = false;
      this.participacionesImporte[this.row.id+""] = null;
      this.participacionesFun[this.row.id+""] = null
      //if(this.router.url === '/mislicitaciones') this.vista = 'listado'
      //else this.router.navigate(['/mislicitaciones']);
      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].borrada = true;
          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  /**
   * Cambia la vista al componente de edición
   * @param row Objeto de la licitación a editar.
   */
  editar(row) {
    this.ngZone.run(() => this.router.navigate(['/editar/' + row.id])).then();
  }

  /*** NUEVO DISEÑO ***/

  /**
   * Muestra el detalle del servicio seleccionado.
   * @param serv Servicio a mostrar
   * @param licit Licitación sobre la que mostrar el detalle
   */
  detalleServicio(serv, licit) {
    if(this.translate.currentLang == "es") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Traslado", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    } else if(this.translate.currentLang == "en") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre_en, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle_en, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Transfer", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    } else if(this.translate.currentLang == "de") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre_de, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle_de, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Transfer", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    } else if(this.translate.currentLang == "fr") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre_fr, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle_fr, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Transfer", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    }

    licit.state = "flipped";
    this.cdr.detectChanges();

    setTimeout(() => { 
      licit.mostrarDetalleServicio = true;
      this.cdr.detectChanges();
    }, 100);

    
  }

  /**
   * Oculta el detalle del servicio mostrado
   * @param licit Licitación a la que ocultar el detalle
   */
  ocultarServicio(licit: any) {
    licit.mostrarDetalleServicio = false;
    licit.state='default';
    this.cdr.detectChanges();
  }

  /**
   * Oculta el detalle de la aseguradora
   * @param licit Licitación a la que ocultar el detalle
   */
  ocultarAseguradora(licit: any) {
    licit.mostrarAseguradora = false;
    licit.state='default';
    this.cdr.detectChanges();
  }

  /**
   * Muestra el detalle de la aseguradora.
   * @param licit Licitación sobre la que mostrar el detalle
   */
  mostrarAseguradora(licit: any) {
    licit.mostrarAseguradora = true;
    licit.state='flipped';
    this.cdr.detectChanges();
  }

  /**
   * Cierra la sesión del usuario y le lleva al registro.
   */
  contratar() {
    this.authenticationService.contratar();
  }

  aceptarLicit(estado: any) {
    if(estado == true) {  // Aceptada
      this.confirmacionAcepSwal.fire();
    } else {  // Rechazada
      this.confirmacionRechSwal.fire();
    }
  }

  aceptarLicit1(estado: any) {
    var edit = {
      validadaUsuario: estado
    }  
    var completadoLicit = function(resLicit){
      
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

    for(var i = 0; i < this.originalRows.length; i++){
      if(this.originalRows[i].id == this.row.id){
        this.originalRows[i].validadaUsuario = estado;
        
        this.cdr.detectChanges();
        this.filterDatatable();
        break;
      }
    }
  }

  /******* MODO ADMIN *******/

  irPerfil(user: any) {
    this.ngZone.run(() => this.router.navigate(['/perfil/user/' + user])).then();
  }

  nuevoTicket(user: any) {
    console.warn(user);
    this.ngZone.run(() => this.router.navigate(['/admin-soporte/nuevo/' + user])).then();
  }

  /******* MODO B2B *******/
  limitRows = 20;
  aumento = 10;
  filteredRows = [];
  files: File[] = [];
  proveedoresPets = [];
  proveedoresPetsHash = {};
  proveedores = [];
  proveedoresHash = {};
  documento = null;
  documentoInterno = false;
  loadingCert = false;
  modoB2B = null;
  filtersB2B = { 
    referencia: null, telefono: null, fechaDesde: null, fechaHasta: null, localizacion: null, servicio: null, proveedor: "TODOS", origen: "TODOS", cliente: "TODOS",
    estadoPendiente: true, estadoRechazada: true, estadoBorrar: false, estadoAceptada: true, estadoAsignada: true, estadoCompletada: true, oculto: false, orden: "FECHA DESC",
    //PETS
    estadoLlamada: true, estadoPresupuestar: true
  };
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  verFiltrosB2B = false;
  vistaDetalleMovil = 1;

  vistaFiltrosB2B(vista: any) {
    this.verFiltrosB2B = vista;
    localStorage.setItem('verFiltrosB2B-' + this.modoB2B, JSON.stringify(this.verFiltrosB2B));
    this.cdr.detectChanges();
  }
  
  async vistaDetalleB2B(vista: any) {
    this.currentRow.vistaDetalle = vista;
    this.cdr.detectChanges();
  }

  async loadLicitacionesB2B() {
    this.filtrosOpen = false;
    this.loadingInit = true;
    this.currentRow = null;
    let servicios = [];
    this.originalRows = [];
    this.rows = [];
    this.filteredRows = [];
    this.cdr.detectChanges();
    console.warn(this.currentUser);
    if(this.currentUser.status == "ADMIN" || this.currentUser.b2b_tipo == "TODO") {
      console.warn(this.router.url);
      if(this.router.url == "/admin-licitaciones") {
        this.modoB2B = "WFN";
        let serviciosTemp = await this.b2bService.getAllOrigenPage500("WFN", 0);
        servicios = servicios.concat(serviciosTemp);

        while (serviciosTemp.length == 500) {
          serviciosTemp = await this.b2bService.getAllOrigenPage500("WFN", servicios.length);
          servicios = servicios.concat(serviciosTemp);
        }
      } else if(this.router.url == "/admin-licitaciones-pets") {
        this.modoB2B = "PETS";
        let serviciosTemp = await this.b2bService.getAllOrigenPage500("PETS", 0);
        servicios = servicios.concat(serviciosTemp);

        while (serviciosTemp.length == 500) {
          serviciosTemp = await this.b2bService.getAllOrigenPage500("PETS", servicios.length);
          servicios = servicios.concat(serviciosTemp);
        }
      } else if(this.router.url == "/admin-licitaciones-fp") {
        this.modoB2B = "FP";
        let serviciosTemp = await this.b2bService.getAllOrigenPage500("FP", 0);
        servicios = servicios.concat(serviciosTemp);

        while (serviciosTemp.length == 500) {
          serviciosTemp = await this.b2bService.getAllOrigenPage500("FP", servicios.length);
          servicios = servicios.concat(serviciosTemp);
        }
      } else if(this.router.url == "/admin-licitaciones-borea") {
        this.modoB2B = "BOREA";
        let serviciosTemp = await this.b2bService.getAllOrigenPage1000("BOREA", 0);
        servicios = servicios.concat(serviciosTemp);

        while (serviciosTemp.length == 1000) {
          serviciosTemp = await this.b2bService.getAllOrigenPage1000("BOREA", servicios.length);
          servicios = servicios.concat(serviciosTemp);
        }

        let servAut = await this.b2bService.getAllIrisBoreaCompletados();
        console.warn(servAut);
        servicios = servicios.concat(servAut); 



      }
    } else if(this.currentUser.actividad == "WFN-AD" && this.currentUser.ofertante) {
      this.modoB2B = this.currentUser.b2b_tipo;
      servicios = await this.b2bService.getAllUserCliente(this.currentUser.id);

      for(let aut of this.currentUser.autorizados) {
        let servAut = await this.b2bService.getAllUserCliente(aut.autorizado);
        console.warn(servAut);
        servicios = servicios.concat(servAut); 
      }
    } else if(this.currentUser.actividad == "WFN-AD" && this.currentUser.proveedor) {
      this.modoB2B = this.currentUser.b2b_tipo;
      servicios = await this.b2bService.getAllProveedor(this.currentUser.id);
    } else if(this.currentUser.actividad == "WFN-AD") {
      this.modoB2B = this.currentUser.b2b_tipo;
      let serviciosTemp = await this.b2bService.getAllOrigenPage500(this.modoB2B, 0);
      servicios = servicios.concat(serviciosTemp);

      while (serviciosTemp.length == 500) {
        serviciosTemp = await this.b2bService.getAllOrigenPage500(this.modoB2B, servicios.length);
        servicios = servicios.concat(serviciosTemp);
      }
    }

    servicios.sort((a,b) => (a.fechaEntrada > b.fechaEntrada) ? -1 : ((b.fechaEntrada > a.fechaEntrada) ? 1 : 0))

    if(this.modoB2B != "PETS") await this.loadProveedores()
    else if(this.modoB2B == "PETS") await this.loadProveedoresPets();

    for(let serv of servicios) {
      if(serv.fechaEntrada) serv.fechaStr = moment(serv.fechaEntrada).format("DD/MM/YYYY HH:mm");
      if(serv.fechaCierre) serv.fechaCierreStr = moment(serv.fechaCierre).format("DD/MM/YYYY HH:mm");
      if(serv.updatedAt) serv.updatedAtStr = moment(serv.updatedAt).format("DD/MM/YYYY HH:mm");
      if(serv.fallecidoFecha) serv.fallecidoFechaStr = moment(serv.fallecidoFecha).format("DD/MM/YYYY");
      serv.marcado = false;
      
      if(serv.userAsignado) { // Solo para PETS
        //serv.userAsignado = serv.userAsignado.id;
        serv.userAsignadoObj = this.proveedoresPetsHash[serv.userAsignado];
      }

      this.originalRows.push(serv);

      if(!serv.petsServicios && serv.origen == "PETS") serv.petsServicios = {}
      if(!serv.serviciosAdd && serv.origen != "PETS") serv.serviciosAdd = {}
/*
      //https://borea.es/es/servicios-funerarios?
      kw=precio%20funeral&
      cpn=20120242965&
      utm_feeditemid=&
      utm_device=m&
      utm_term=precio%20funeral&
      utm_source=google&
      utm_medium=ppc&
      utm_campaign=Madrid+%7C+new&
      hsa_cam=20120242965&
      hsa_grp=149254156979&
      hsa_mt=p&hsa_src=g&
      hsa_ad=664068286521&
      hsa_acc=4607900556&
      hsa_net=adwords&
      hsa_kw=precio%20funeral&
      hsa_tgt=kwd-310546389491&
      hsa_ver=3&
      gad_source=1&
      gclid=Cj0KCQiA2eKtBhDcARIsAEGTG42yEIomaEHQ1HEAdk_Bu4Gc8VzpK7wUv2RG3Zv1bFZpAj2XNzTNif4aAnQEEALw_wcB
      */

      if(serv.url) {
        const url = new URL(serv.url);
        serv.kw = url.searchParams.get("kw");
        serv.gad = url.searchParams.get("gad_source") || url.searchParams.get("gad");
        serv.utm_device = url.searchParams.get("utm_device");
        serv.utm_source = url.searchParams.get("utm_source");
        serv.utm_medium = url.searchParams.get("utm_medium");
        serv.utm_campaign = url.searchParams.get("utm_campaign");
        serv.utm_term = url.searchParams.get("utm_term");

        url.search = '';
        serv.href = url.href;
      }
    }

    let filters = localStorage.getItem('filtrosB2B-' + this.modoB2B);
    let verFiltros = localStorage.getItem('verFiltrosB2B-' + this.modoB2B);

    if(filters) {
      this.filtersB2B = JSON.parse(filters);
      if(!this.filtersB2B.proveedor || !this.filtersB2B.origen || !this.filtersB2B.cliente || !this.filtersB2B.orden) this.resetFiltersB2B();
    }

    if(verFiltros) {
      if(verFiltros == "true") this.verFiltrosB2B = true;
    }


    this.loadingInit = false;
    
    this.filterDatatableB2B();
    this.cdr.detectChanges();
  }

  async loadProveedores() {
    this.proveedores = await this.userService.getB2B(this.modoB2B);
    for(let item of this.proveedores) {
      this.proveedoresHash[item.id + ""] = item;
    }
  }

  async loadProveedoresPets() {
    this.proveedoresPets = await this.userService.getB2BPetsProv();
    for(let item of this.proveedoresPets) {
      this.proveedoresPetsHash[item.id + ""] = item;
    }
    this.cdr.detectChanges();
  }

  async enviarComentario() {
    if(!this.comentario) return;

    let comentario = {
      nombre: this.currentUser.empresa,
      contenido: this.comentario,
      fecha: new Date(),
      fechaStr: moment().format("DD/MM/YYYY HH:mm"),
      solicitud: this.currentRow.id,
      interno: this.comentarioInterno
    }

    this.currentRow.comentarios.push(comentario);
    this.cdr.detectChanges();

    await this.b2bService.postComentario(comentario);

    let solUpdate = {
      id: this.currentRow.id,
      estado: this.currentRow.estado
    }
    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;

    this.comentario = null;
    this.comentarioInterno = false;
    this.cdr.detectChanges();
  }

  comBorrar = null;
  //questionDeleteFileSwal
  borrarComentario(com: any) {
    this.comBorrar = com;
    this.questionDeleteComentarioSwal.fire();
  }

  async borrarComentario1() {
    await this.b2bService.deleteComentario(this.comBorrar.id);

    // Carga comentarios
    let coms = await this.b2bService.getComentarios(this.currentRow.id);

    let comentarios = [];
    for(let com of coms) {
      com.fechaStr = moment(com.fecha).format("DD/MM/YYYY HH:mm");
      if(this.currentUser.status == "ADMIN" || !com.interno) comentarios.push(com);
    }
    this.currentRow.comentarios = comentarios;
    this.cdr.detectChanges();

    let solUpdate = {
      id: this.currentRow.id,
      estado: this.currentRow.estado
    }
    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
  }

  loadingAceptar = false;
  async aceptarServicio(estado: any) {
    this.loadingAceptar = true;
    this.cdr.detectChanges();
    this.currentRow.razonPerdido = "";
    this.currentRow.formaPago = "";
    if(this.currentRow.origen != "PETS" && this.currentRow.origen != "BOREA") {
      let solUpdate = {
        id: this.currentRow.id,
        estado: estado ? "ACEPTADA" : "RECHAZADA",
        //fechaAceptado: new Date()
      } as any;

      if(estado) {
        solUpdate.fechaCierre = null;
        if(this.currentRow.estado == "RECHAZADA") {
          solUpdate.estado = "PENDIENTE";
          solUpdate.fechaAceptado = null;
        } else {
          solUpdate.fechaAceptado = new Date();
        }
      } else {
        solUpdate.fechaCierre = new Date();
      }
  
      let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
      this.currentRow.estado = resUpdate.estado;

      // Pendiente: ACTUALIZAR EN PANTALLA CUANDO LA FECHA DE CIERRE CAMBIE
      //this.currentRow.fechaCierre = resUpdate.fechaCierre;
      //this.currentRow.fechaAceptado = resUpdate.fechaAceptado;

    } else if(this.currentRow.origen == "PETS" || this.currentRow.origen == "BOREA") {
      let solUpdate = {
        id: this.currentRow.id,
        estado: estado ? "PRESUPUESTAR" : "RECHAZADA",
        tipoSiniestro: estado && this.currentRow.origen == "BOREA" ? "DEC" : null,
        causaSiniestro: estado && this.currentRow.origen == "BOREA" ? "FTO" : null,
      } as any;
      if(solUpdate.estado == "RECHAZADA") solUpdate.fechaCierre = new Date()
      else solUpdate.fechaCierre = null;
  
      let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
      this.currentRow.estado = estado ? "PRESUPUESTAR" : "RECHAZADA";
      this.currentRow.tipoSiniestro = resUpdate.tipoSiniestro;
      this.currentRow.causaSiniestro = resUpdate.causaSiniestro;

      if(solUpdate.fechaCierre) this.currentRow.fechaCierreStr = moment(solUpdate.fechaCierre).format("DD/MM/YYYY HH:mm");
    }
    

    this.loadingAceptar = false;
    
    //this.currentRow = resUpdate;
    //console.warn(resUpdate);
    this.cdr.detectChanges();

  }

  async presupuestoFirmado() {
    this.loadingAceptar = true;
    this.cdr.detectChanges();
    if(this.currentRow.origen == "PETS" || this.currentRow.origen == "BOREA") {
      let solUpdate = {
        id: this.currentRow.id,
        estado: "ACEPTADA",
        fechaAceptado: new Date()
      }
  
      let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
      this.currentRow.estado = "ACEPTADA";
    }
    

    this.loadingAceptar = false;
    
    //this.currentRow = resUpdate;
    //console.warn(resUpdate);
    this.cdr.detectChanges();

  }

  async llamadaCliente() {
    this.loadingAceptar = true;
    this.cdr.detectChanges();
    if(this.currentRow.origen == "PETS" || this.currentRow.origen == "BOREA") {
      let solUpdate = {
        id: this.currentRow.id,
        estado: "LLAMADA",
        fechaAceptado: null
      }
  
      let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
      this.currentRow.estado = "LLAMADA";
    }
    

    this.loadingAceptar = false;
    
    //this.currentRow = resUpdate;
    //console.warn(resUpdate);
    this.cdr.detectChanges();

  }

  async detallesChange() {
    let solUpdate = {
      id: this.currentRow.id,
      tipo: this.currentRow.tipo,
      petsServicios: this.currentRow.petsServicios,
      serviciosAdd: this.currentRow.serviciosAdd,
      userAsignado: this.currentRow.userAsignado,
      servicioPrestado: this.currentRow.servicioPrestado,
      traslado: this.currentRow.traslado,
      proveedor: this.currentRow.proveedor,
      velacionTipo: this.currentRow.velacionTipo,
      velacionLugar: this.currentRow.velacionLugar,
      velacionLocalidad: this.currentRow.velacionLocalidad,
      velacionProvincia: this.currentRow.velacionProvincia,
      velacionObservaciones: this.currentRow.velacionObservaciones,
      sepelioLugar: this.currentRow.sepelioLugar,
      sepelioLocalidad: this.currentRow.sepelioLocalidad,
      sepelioProvincia: this.currentRow.sepelioProvincia,
      sepelioObservaciones: this.currentRow.sepelioObservaciones,
      razonPerdido: this.currentRow.razonPerdido,
      formaPago: this.currentRow.formaPago,
      importePago: this.currentRow.importePago,
      tipoSiniestro: this.currentRow.tipoSiniestro,
      causaSiniestro: this.currentRow.causaSiniestro,
      funerariaObservaciones: this.currentRow.funerariaObservaciones
    }
    this.cdr.detectChanges();
    this.cdr.detectChanges();

    if(this.currentRow.userAsignado) {
      this.currentRow.userAsignadoObj = this.proveedoresPetsHash[this.currentRow.userAsignado];
    }

    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
  }

  async comenzarServicio() {
    this.loadingAceptar = true;
    this.cdr.detectChanges();
    let solUpdate = {
      id: this.currentRow.id,
      estado: "ASIGNADA",
      trazas: null,
      fechaGanado: new Date()
    }

    let trazas = [];

    if(this.currentRow.origen != "PETS") {
      trazas = [
        {
          nombre: "La funeraria ha contactado con la familia.",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }, 
        {
          nombre: "Inicio de desplazamiento para recogida del fallecido",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }, 
        {
          nombre: "Se ha recogido al fallecido",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }, 
        {
          nombre: "El fallecido ha llegado al tanatorio",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }, 
        {
          nombre: "El fallecido está preparado para la apertura de la sala del tanatorio",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }, 
        {
          nombre: "Fecha y lugar del funeral establecido",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        }
      ]
  
      if(this.currentRow.tipo == "CREMACION") {
        trazas.push({
          nombre: "El fallecido se traslada al crematorio",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        })
        trazas.push({
          nombre: "Cremación realizada",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
        trazas.push({
          nombre: "Cenizas entregadas",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        })
      } else {
        trazas.push({
          nombre: "El fallecido se traslada al cementerio",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
  
        trazas.push({
          nombre: "Inhumación realizada",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        })
      }
    } else if(this.currentRow.origen == "PETS") {
      trazas.push({
        nombre: "El centro ha contactado con la familia.",
        fecha: null,
        fechaStr: null,
        completado: false,
        incidencia: false,
        texto: null
      });
      if(this.currentRow.petsServicios.eutanasia) {
        trazas.push({
          nombre: "Datos y requisitos de eutanasia verificados",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
        trazas.push({
          nombre: "Se ha realizado la eutanasia.",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
      }
      trazas.push({
        nombre: "Se ha recogido o entregado el fallecido.",
        fecha: null,
        fechaStr: null,
        completado: false,
        incidencia: false,
        texto: null
      });
      trazas.push({
        nombre: "El fallecido ha llegado al centro.",
        fecha: null,
        fechaStr: null,
        completado: false,
        incidencia: false,
        texto: null
      });
      trazas.push({
        nombre: "Fecha y lugar de cremación/despedida establecidos",
        fecha: null,
        fechaStr: null,
        completado: false,
        incidencia: false,
        texto: null
      });
      if(this.currentRow.petsServicios.velatorio) {
        trazas.push({
          nombre: "Velatorio realizado",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
      }
      trazas.push({
        nombre: "Cremación realizada",
        fecha: null,
        fechaStr: null,
        completado: false,
        incidencia: false,
        texto: null
      });
      if(this.currentRow.tipo == "INDIVIDUAL") {
        trazas.push({
          nombre: "Cenizas y documentación entregadas",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        })
      } else {
        trazas.push({
          nombre: "Documentación entregada",
          fecha: null,
          fechaStr: null,
          completado: false,
          incidencia: false,
          texto: null
        });
      }      
    }
    
    solUpdate.trazas = trazas;
    this.currentRow.estado = "ASIGNADA";
    this.currentRow.trazas = trazas;
    this.currentRow.trazas_comp = 0;

    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
    this.loadingAceptar = false;
    this.cdr.detectChanges();
    
    
  }

  async confirmarTrazaB2B(row: any) {
    

    this.currentRow.trazas_comp++;

    for(let traza of this.currentRow.trazas) {
      if(traza.nombre == row.nombre) {
        traza.completado = true;
        traza.fecha = new Date();
        traza.fechaStr = moment().format("DD/MM/YYYY HH:mm");
      }
    }

    let solUpdate = {
      id: this.currentRow.id,
      trazas: this.currentRow.trazas,
      estado: "ASIGNADA"
    } as any;

    if(this.currentRow.trazas_comp == this.currentRow.trazas.length) {
      solUpdate.estado = "COMPLETADA";
      solUpdate.fechaCierre = new Date();
      if(solUpdate.fechaCierre) this.currentRow.fechaCierreStr = moment(solUpdate.fechaCierre).format("DD/MM/YYYY HH:mm");
      this.currentRow.estado = "COMPLETADA";
      this.cdr.detectChanges();
    }
    
    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
    this.cdr.detectChanges();
    this.cdr.detectChanges();


  }

  async cancelarTrazaB2B(row: any) {
    

    this.currentRow.trazas_comp--;

    for(let traza of this.currentRow.trazas) {
      if(traza.nombre == row.nombre) {
        traza.completado = false;
        traza.fecha = null;
        traza.fechaStr = null;
      }
    }

    let solUpdate = {
      id: this.currentRow.id,
      trazas: this.currentRow.trazas,
      estado: "ASIGNADA",
      fechaCierre: null
    }
    this.currentRow.estado = "ASIGNADA";
    this.cdr.detectChanges();

    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;

    this.cdr.detectChanges();
  }

  editRow = null;

  async editarLicitB2B() {
    this.currentRow.edicion = true;
    this.editRow = JSON.parse(JSON.stringify(this.currentRow));
    //if(this.editRow.userCliente) this.editRow.userCliente = this.editRow.userCliente.id;
    console.warn(this.editRow);
    this.cdr.detectChanges();
  }

  async guardarLicitB2B() {
    //this.currentRow = JSON.parse(JSON.stringify(this.editRow));
    this.currentRow.edicion = false;

    this.cdr.detectChanges();
    let solUpdate = {
      id: this.editRow.id,
      expediente: this.editRow.expediente,
      source: this.editRow.source,
      poliza: this.editRow.poliza,
      contactoNombre: this.editRow.contactoNombre,
      contactoApellido1: this.editRow.contactoApellido1,
      contactoApellido2: this.editRow.contactoApellido2,
      petsDNIProp: this.editRow.petsDNIProp,
      contactoDNI: this.editRow.contactoDNI,
      contactoTelefono: this.editRow.contactoTelefono,
      contactoMail: this.editRow.contactoMail,
      contactoCalle: this.editRow.contactoCalle,
      contactoLocalidad: this.editRow.contactoLocalidad,
      contactoProvincia: this.editRow.contactoProvincia,
      contactoNumero: this.editRow.contactoNumero,
      contactoPuerta: this.editRow.contactoPuerta,
      contactoCP: this.editRow.contactoCP,
      fallecidoNombre: this.editRow.fallecidoNombre,
      fallecidoApellido1: this.editRow.fallecidoApellido1,
      fallecidoApellido2: this.editRow.fallecidoApellido2,
      fallecidoDNI: this.editRow.fallecidoDNI,
      fallecidoFecha: this.editRow.fallecidoFecha,
      fallecidoTipoLugar: this.editRow.fallecidoTipoLugar,
      fallecidoCalle: this.editRow.fallecidoCalle,
      fallecidoLocalidad: this.editRow.fallecidoLocalidad,
      fallecidoProvincia: this.editRow.fallecidoProvincia,
      fallecidoNumero: this.editRow.fallecidoNumero,
      fallecidoPuerta: this.editRow.fallecidoPuerta,
      fallecidoCP: this.editRow.fallecidoCP,
      petsPeso: this.editRow.petsPeso,
      petsTipoAnimal: this.editRow.petsTipoAnimal,
      observaciones: this.editRow.observaciones,
    }

    let res = await this.b2bService.updateSolicitud(solUpdate);

    console.warn(res);

    this.currentRow.expediente = this.editRow.expediente;
    this.currentRow.source = this.editRow.source;
    this.currentRow.poliza = this.editRow.poliza;
    this.currentRow.contactoNombre = this.editRow.contactoNombre;
    this.currentRow.contactoApellido1 = this.editRow.contactoApellido1;
    this.currentRow.contactoApellido2 = this.editRow.contactoApellido2;
    this.currentRow.petsDNIProp = this.editRow.petsDNIProp;
    this.currentRow.contactoDNI = this.editRow.contactoDNI;
    this.currentRow.contactoTelefono = this.editRow.contactoTelefono;
    this.currentRow.contactoMail = this.editRow.contactoMail;
    this.currentRow.contactoCalle = this.editRow.contactoCalle;
    this.currentRow.contactoLocalidad = this.editRow.contactoLocalidad;
    this.currentRow.contactoProvincia = this.editRow.contactoProvincia;
    this.currentRow.contactoNumero = this.editRow.contactoNumero;
    this.currentRow.contactoPuerta = this.editRow.contactoPuerta;
    this.currentRow.contactoCP = this.editRow.contactoCP;
    this.currentRow.fallecidoNombre = this.editRow.fallecidoNombre;
    this.currentRow.fallecidoApellido1 = this.editRow.fallecidoApellido1;
    this.currentRow.fallecidoApellido2 = this.editRow.fallecidoApellido2;
    this.currentRow.fallecidoDNI = this.editRow.fallecidoDNI;
    this.currentRow.fallecidoFecha = this.editRow.fallecidoFecha;
    if(this.currentRow.fallecidoFecha) this.currentRow.fallecidoFechaStr = moment(this.currentRow.fallecidoFecha).format("DD/MM/YYYY");
    this.currentRow.fallecidoTipoLugar = this.editRow.fallecidoTipoLugar;
    this.currentRow.fallecidoCalle = this.editRow.fallecidoCalle;
    this.currentRow.fallecidoLocalidad = this.editRow.fallecidoLocalidad;
    this.currentRow.fallecidoProvincia = this.editRow.fallecidoProvincia;
    this.currentRow.fallecidoNumero = this.editRow.fallecidoNumero;
    this.currentRow.fallecidoPuerta = this.editRow.fallecidoPuerta;
    this.currentRow.fallecidoCP = this.editRow.fallecidoCP;
    this.currentRow.petsPeso = this.editRow.petsPeso;
    this.currentRow.petsTipoAnimal = this.editRow.petsTipoAnimal;
    this.currentRow.observaciones = this.editRow.observaciones;
    this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = res.updatedAt;

    // Comprobamos si hay cambio de cliente proveedor. Recalcular código
    if(this.currentRow.userCliente && this.editRow.userCliente != this.currentRow.userCliente) {
      let objEdit:any = {
        id: this.currentRow.id,
        cliente: this.proveedoresHash[this.editRow.userCliente].b2b_cliente,
        userCliente: this.editRow.userCliente
      }

      let codigo = await this.b2bService.generarCodigo(objEdit.cliente);
      objEdit.referencia = codigo.codigo;
      objEdit.numero = codigo.numero;
      objEdit.anyo = codigo.anyo;

      let resUpdate = await this.b2bService.updateSolicitud(objEdit);

      this.currentRow.userCliente = objEdit.userCliente;//this.proveedoresHash[this.editRow.userCliente];
      this.currentRow.cliente = objEdit.cliente;
      this.currentRow.referencia = objEdit.referencia;
      this.currentRow.numero = objEdit.numero;
      this.currentRow.anyo = objEdit.anyo;    
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
    }
    this.editRow = null;
    this.cdr.detectChanges();
  }


  async resetFiltersB2B() {
    this.filtersB2B = { referencia: null, telefono: null, fechaDesde: null, fechaHasta: null, localizacion: null, servicio: null, proveedor: "TODOS", origen: "TODOS",
      estadoPendiente: true, estadoRechazada: true, estadoBorrar: false, estadoAceptada: true, estadoAsignada: true, estadoCompletada: true, oculto: false,
      estadoLlamada: true, estadoPresupuestar: true, cliente: "TODOS", orden: "FECHA DESC" };
      this.filterDatatableB2B();
  }

  async filterDatatableB2B() {
    localStorage.setItem('filtrosB2B-' + this.modoB2B, JSON.stringify(this.filtersB2B));
    console.warn(this.filtersB2B);
    await this.orderDatatableB2B();
    this.rows = [];

    console.warn(this.proveedores);

    this.filteredRows = this.originalRows.filter(function(item){
      var mostrar = true;

      if(this.filtersB2B.referencia && this.filtersB2B.referencia != null && this.filtersB2B.referencia != "") {
        if(!item.referencia.toUpperCase().includes(this.filtersB2B.referencia.toUpperCase())) mostrar = false;
      }

      if(this.filtersB2B.telefono && this.filtersB2B.telefono != null && this.filtersB2B.telefono != "") {
        if(!item.contactoTelefono || !item.contactoTelefono.toUpperCase().includes(this.filtersB2B.telefono.toUpperCase())) mostrar = false;
      }

      if(this.filtersB2B.fechaDesde && moment(this.filtersB2B.fechaDesde) > moment(item.fechaEntrada)) mostrar = false;
      if(this.filtersB2B.fechaHasta && moment(this.filtersB2B.fechaHasta).endOf('day') < moment(item.fechaEntrada)) mostrar = false;
  
      if(this.filtersB2B.localizacion && this.filtersB2B.localizacion != null && this.filtersB2B.localizacion != "") {
        let strFall = ((item.fallecidoLocalidad || '') + (item.fallecidoProvincia || '') + (item.contactoLocalidad || '') + (item.contactoProvincia || '')).toUpperCase();
        if(!strFall.includes(this.filtersB2B.localizacion.toUpperCase())) mostrar = false;
      }
      if(!this.filtersB2B.estadoPendiente && item.estado == "PENDIENTE") mostrar = false;
      if(!this.filtersB2B.estadoRechazada && item.estado == "RECHAZADA") mostrar = false;
      if(!this.filtersB2B.estadoBorrar && item.estado == "BORRAR") mostrar = false;
      if(!this.filtersB2B.estadoAceptada && item.estado == "ACEPTADA") mostrar = false;
      if(!this.filtersB2B.estadoAsignada && item.estado == "ASIGNADA") mostrar = false;
      if(!this.filtersB2B.estadoCompletada && item.estado == "COMPLETADA") mostrar = false;
      if(this.modoB2B == "PETS" || this.modoB2B == "BOREA") {
        if(!this.filtersB2B.estadoLlamada && item.estado == "LLAMADA") mostrar = false;
        if(!this.filtersB2B.estadoPresupuestar && item.estado == "PRESUPUESTAR") mostrar = false;
      }

      if(this.currentUser.status == "ACTIVO" && this.currentUser.actividad == "WFN-AD" && this.currentUser.proveedor) {
        if(item.estado != "ASIGNADA" && item.estado != "COMPLETADA") mostrar = false;
      }

      if(this.filtersB2B.proveedor != "TODOS") {
        if(item.proveedor != this.filtersB2B.proveedor) mostrar = false;
      }

      if(this.filtersB2B.cliente != "TODOS") {
        if(item.userCliente != this.filtersB2B.cliente) mostrar = false;
      }

      if(this.filtersB2B.origen != "TODOS") {
        if(item.source != this.filtersB2B.origen) mostrar = false;
      }

      if(!this.filtersB2B.oculto && item.oculto) mostrar = false;

      if(item.fusion) mostrar = false;

      return mostrar;
    }.bind(this));

    for(var i = 0; i < this.limitRows && i < this.filteredRows.length; i++) {
      this.rows.push(this.filteredRows[i]);
    }
    this.cdr.detectChanges();
  }

  /**
   * Ordena el listado de licitaciones en base a los filtros seleccionados
   */
  async orderDatatableB2B(){
    /**
     * <option value="FECHA DESC">Fecha Creación Desc.</option>
      <option value="FECHA ASC">Fecha Creación Asc.</option>
      <option value="ACTIV DESC">Última actividad Desc.</option>
      <option value="ACTIV ASC">Última actividad Asc.</option>
      <option value="CIERRE DESC">Fecha cierre Desc.</option>
      <option value="CIERRE ASC">Fecha cierre Asc.</option>
     */
    if(this.filtersB2B.orden == "FECHA DESC") {
      this.originalRows.sort(function (a, b) {
        var msA = moment(a.fechaEntrada).diff();
        var msB = moment(b.fechaEntrada).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.id).localeCompare(b.id);
      }.bind(this));
    } else if(this.filtersB2B.orden == "FECHA ASC") {
      this.originalRows.sort(function (a, b) {
        var msA = moment(a.fechaEntrada).diff();
        var msB = moment(b.fechaEntrada).diff();
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.id).localeCompare(b.id);
      }.bind(this));
    } else if(this.filtersB2B.orden == "ACTIV DESC") {
      this.originalRows.sort(function (a, b) {
        var msA = moment(a.updatedAt).diff();
        var msB = moment(b.updatedAt).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.id).localeCompare(b.id);
      }.bind(this));
    } else if(this.filtersB2B.orden == "ACTIV ASC") {
      this.originalRows.sort(function (a, b) {
        var msA = moment(a.updatedAt).diff();
        var msB = moment(b.updatedAt).diff();
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.id).localeCompare(b.id);
      }.bind(this));
    } else if(this.filtersB2B.orden == "CIERRE DESC") {
      this.originalRows.sort(function (a, b) {
        var msA = a.fechaCierre ? moment(a.fechaCierre).diff()*-1 : 0;
        var msB = b.fechaCierre ? moment(b.fechaCierre).diff()*-1 : 0;
        if (msA === msB) {
          return 0;
        }
        if (msA === 0) {
          return 1;
        }
        if (msB === 0) {
          return -1;
        }
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
      }.bind(this));
    } else if(this.filtersB2B.orden == "CIERRE ASC") {
      this.originalRows.sort(function (a, b) {
        var msA = a.fechaCierre ? moment(a.fechaCierre).diff()*-1 : 0;
        var msB = b.fechaCierre ? moment(b.fechaCierre).diff()*-1 : 0;
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.id).localeCompare(b.id);
      }.bind(this));
    }
  }

  async loadMore() {
    this.limitRows += this.aumento;
    this.filterDatatableB2B();
  }

  /**
   * Evento de nuevo documento
   * @param event Información del fichero
   */
  onSelect(event) {
    if(event.addedFiles[0].type != "application/pdf"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);
    this.cdr.detectChanges();
  }

  /**
   * Evento de documento borrado
   * @param event Información del fichero
   */
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.cdr.detectChanges();
  }

  /**
   * Guarda o actualiza el certificado en base de datos
   */
  async subirDocumento() {
    if(this.loadingCert) return;
    if(this.files.length == 0) {
      this.errorNotFileSwal.fire();
      return;
    }
    if(!this.documento) return;
    this.loadingCert = true;
    this.cdr.detectChanges();
    var doc = this.files[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = async function (e:any) {

      var newDoc = {contenido: myReader.result, comentario: this.documento, interno: this.documentoInterno, nombre: doc.name, fecha: new Date(), solicitud: this.currentRow.id};

      let docRes = await this.b2bService.postDocumento(newDoc);

      this.loadingCert = false;
      this.files = [];
      this.documento = null;
      this.documentoInterno = false;
      this.cdr.detectChanges();

      if(!this.currentRow.documentos) {
        this.currentRow.documentos = [];
      }
      docRes.fechaStr = moment(docRes.fecha).format("DD/MM/YYYY HH:mm");
      this.currentRow.documentos = [docRes, ...this.currentRow.documentos];
      this.cdr.detectChanges();     

      let solUpdate = {
        id: this.currentRow.id,
        estado: this.currentRow.estado
      }
      let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
      this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = resUpdate.updatedAt;
    }.bind(this)
    myReader.readAsDataURL(doc);
  }

  async downloadFile(row: any) {
    let doc = await this.b2bService.getDocumentoContenido(row.id);

    var element = document.createElement('a');
    element.setAttribute('href', doc.contenido);
    element.setAttribute('download', doc.nombre);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  docBorrar = null;
  //questionDeleteFileSwal
  borrarDoc(doc: any) {
    this.docBorrar = doc;
    this.questionDeleteFileSwal.fire();
  }

  async borrarDoc1() {
    await this.b2bService.deleteDocumento(this.docBorrar.id);
    
    let docs = await this.b2bService.getDocumentos(this.currentRow.id);

    for(let doc of docs) {
      doc.fechaStr = moment(doc.fecha).format("DD/MM/YYYY HH:mm");
    }
    this.currentRow.documentos = docs;
    this.cdr.detectChanges();

    let solUpdate = {
      id: this.currentRow.id,
      estado: this.currentRow.estado
    }
    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
  }

  loadingExportar = false;

  async exportarB2B() {
    
    if(this.loadingExportar) return;
  
    this.loadingExportar = true;
    this.cdr.detectChanges();
    this.cdr.detectChanges();

    let procesados = 0;

    if(this.modoB2B == 'PETS') {
      this.continuarExportarPETS();
    } else if(this.modoB2B == 'BOREA') {
      this.continuarExportarBOREA();
    } else {
      this.continuarExportar();
    }

  }

  async continuarExportar() {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Listado de Servicios');
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 30;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 60;
    worksheet.getColumn(20).width = 30;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 20;
    worksheet.getColumn(23).width = 20;
    worksheet.getColumn(24).width = 20;
    worksheet.getColumn(25).width = 20;
    worksheet.getColumn(26).width = 20;
    worksheet.getColumn(27).width = 20;


    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, 'A2:C4');

    const cell = worksheet.getCell('E2');
    cell.value = 'Informe - Servicios WFN';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('E4').value = 'Fecha Informe: ';
    worksheet.getCell('E4').font = { bold: true };
    worksheet.getCell('F4').value = moment().format("DD/MM/YYYY HH:mm");

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    let titleRow = worksheet.addRow(['Referencia', 'Fecha', 'Estado', 'Tipo', 'Traslado', 'Poliza','Expediente','Nombre Cont.','Telefono Cont.','Mail Cont.','Localiz. Cont.', 'Direcc. Cont.', 'Nombre Fall.', 'DNI Fall.', 'Fecha Fall.', 'Lugar Fall.', 'Localiz. Fall.', 'Direcc. Fall.', 'Observaciones', 'Servicio Prestado', 'Velación Tipo', 'Velación Lug.', 'Velación Loc.', 'Velación Obs.', 'Sepelio Lug.', 'Sepelio Loc.', 'Sepelio Obs.']);
    // Set font, size and style in title row.
    titleRow.font = { bold: true };
    for(let row of this.filteredRows) {
      
      worksheet.addRow([
        row.referencia, 
        row.fechaStr, 
        row.estado, 
        row.tipo || 'Pendiente', 
        row.traslado ? 'SI' : 'NO', 
        row.poliza, 
        row.expediente, 
        (row.contactoNombre || '') + ' ' + (row.contactoApellido1 || '') + ' ' + (row.contactoApellido2 || ''),
        row.contactoTelefono,
        row.contactoMail,
        (row.contactoLocalidad || '') + '. ' + (row.contactoProvincia || ''),
        (row.contactoCalle || '') + ' ' + (row.contactoNumero || '') + ' ' + (row.contactoPuerta || ''),
        row.fallecidoNombre ? (row.fallecidoNombre + ' ' + row.fallecidoApellido1 + ' ' + (row.fallecidoApellido2 || '')) : 'No disponible',
        row.fallecidoDNI,
        row.fallecidoFechaStr,
        row.fallecidoTipoLugar,
        (row.fallecidoLocalidad || '') + '. ' + (row.fallecidoProvincia || ''),
        (row.fallecidoCalle || '') + ' ' + (row.fallecidoNumero || '') + ' ' + (row.fallecidoPuerta || ''),
        row.observaciones,
        row.servicioPrestado,
        row.velacionTipo,
        row.velacionLugar,
        (row.velacionLocalidad || '') + '. ' + (row.velacionProvincia || ''),
        row.velacionObservaciones,
        row.sepelioLugar,
        (row.sepelioLocalidad || '') + '. ' + (row.sepelioProvincia || ''),
        row.sepelioObservaciones
      ]);
    }
    

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  async continuarExportarPETS() {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Listado de Servicios');
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 12;
    worksheet.getColumn(8).width = 12;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 18;
    worksheet.getColumn(11).width = 18;
    worksheet.getColumn(12).width = 22;
    worksheet.getColumn(13).width = 25;
    worksheet.getColumn(14).width = 25;
    worksheet.getColumn(15).width = 25;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 10;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 30;
    worksheet.getColumn(23).width = 30;
    worksheet.getColumn(24).width = 30;
    worksheet.getColumn(25).width = 60;
    worksheet.getColumn(26).width = 30;
    worksheet.getColumn(27).width = 15;
    worksheet.getColumn(28).width = 15;
    worksheet.getColumn(29).width = 15;
    worksheet.getColumn(30).width = 15;
    worksheet.getColumn(31).width = 15;
    worksheet.getColumn(32).width = 15;
    worksheet.getColumn(33).width = 20;
    worksheet.getColumn(34).width = 60;
    worksheet.getColumn(35).width = 20;
    worksheet.getColumn(36).width = 20;
    worksheet.getColumn(37).width = 20;
    worksheet.getColumn(38).width = 20;


    let logo = workbook.addImage({
      base64: logoPets.logoBase64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, 'A2:C4');

    const cell = worksheet.getCell('E2');
    cell.value = 'Informe - Servicios BOREA PETS';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('E4').value = 'Fecha Informe: ';
    worksheet.getCell('E4').font = { bold: true };
    worksheet.getCell('F4').value = moment().format("DD/MM/YYYY HH:mm");

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    let titleRow = worksheet.addRow(['Referencia', 'Fecha', 'Estado', 'Origen', 'Serv.Indiv.', 'Serv.Colec.', 'Presupuesto', 'Expediente','Nombre Cont.','DNI Cont.','Telefono Cont.','Mail Cont.','Localidad Cont.','Provincia Cont.', 'Direcc. Cont.', 'Nombre Mascota', 'Peso Masc.', 'Tipo Masc.', 'Nº Chip', 'Fecha Fall.', 'Lugar Fall.', 'Localidad Fall.', 'Provincia Fall.', 'Direcc. Fall.', 'Observaciones', 'Motivo Perdido', 'Serv.Eutanasia', 'Serv.Velatorio', 'Serv.Huella', 'Serv.Psico.', 'Serv.Urg.Vet.', 'Serv.Urg.Inc.', 'Proveedor', 'Servicio prestado', 'Localidad IP', 'Provincia IP', 'Forma Pago', 'Importe Final']);
    // Set font, size and style in title row.
    titleRow.font = { bold: true };
    for(let row of this.filteredRows) {

      let estado = "";
      if(row.estado == "PENDIENTE") estado = "Nuevo Lead"
      else if(row.estado == "RECHAZADA") estado = "Serv.Perdido"
      else if(row.estado == "PRESUPUESTAR") estado = "Pend.Presupuestar"
      else if(row.estado == "LLAMADA") estado = "Llamada Cliente"
      else if(row.estado == "ACEPTADA") estado = "Presup.Firmado"
      else if(row.estado == "ASIGNADA") estado = "Serv. En Curso"
      else if(row.estado == "COMPLETADA") estado = "Serv.Realizado";

      let source = "";
      if(row.source == "LLAMADA") source = "Llamada"
      else if(row.source == "SOL_LLAMADA") source = "Sol.Llamada"
      else if(row.source == "WHATSAPP") source = "Whatsapp"
      else if(row.source == "FORMULARIO") source = "Formulario"
      else if(row.source == "CHAT") source = "Chat"
      else source = "No disponible";

      let motivo = "";
      if(row.razonPerdido) motivo = this.translate.instant('PETS.RAZONES.' + row.razonPerdido);

      let presupuestos = await this.solicitudService.getByB2BSolicitud(row.id);

      let localidadIP, provIP = null;
      if(row.ipLoc) {
        let locIP = row.ipLoc.split('.');
        localidadIP = locIP[0];
        provIP = locIP[2].replace(' ', '');
      }

      worksheet.addRow([
        row.referencia, 
        row.fechaStr, 
        estado || 'No disponible', 
        source || 'No disponible',
        row.tipo && row.tipo == "INDIVIDUAL" ? "SI" : "NO",
        row.tipo && row.tipo == "COLECTIVA" ? "SI" : "NO",
        presupuestos && presupuestos.length > 0 ? "SI" : "NO",
        row.expediente || 'No disponible', 
        (row.contactoNombre || 'No disponible') + ' ' + (row.contactoApellido1 || '') + ' ' + (row.contactoApellido2 || ''),
        row.petsDNIProp || 'No disponible',
        row.contactoTelefono || 'No disponible',
        row.contactoMail || 'No disponible',
        row.contactoLocalidad || 'No disponible',
        row.contactoProvincia || 'No disponible',
        (row.contactoCalle || 'No disponible') + ' ' + (row.contactoNumero || '') + ' ' + (row.contactoPuerta || ''),
        row.fallecidoNombre ? (row.fallecidoNombre) : 'No disponible',
        row.petsPeso || 'No disponible',
        row.petsTipoAnimal || 'No disponible',
        row.fallecidoDNI || 'No disponible',
        row.fallecidoFechaStr || 'No disponible',
        row.fallecidoTipoLugar || 'No disponible',
        row.fallecidoLocalidad || 'No disponible',
        row.fallecidoProvincia || 'No disponible',
        (row.fallecidoCalle || 'No disponible') + ' ' + (row.fallecidoNumero || '') + ' ' + (row.fallecidoPuerta || ''),
        row.observaciones || 'No disponible',
        motivo || 'No disponible',
        row.petsServicios.eutanasia ? "SI" : "NO",
        row.petsServicios.velatorio ? "SI" : "NO",
        row.petsServicios.huella ? "SI" : "NO",
        row.petsServicios.psico ? "SI" : "NO",
        row.petsServicios.urgenciaVet ? "SI" : "NO",
        row.petsServicios.urgenciaInc ? "SI" : "NO",
        row.userAsignadoObj ? row.userAsignadoObj.empresa : 'No disponible',
        row.servicioPrestado || 'No disponible',
        localidadIP || 'No disponible',
        provIP || 'No disponible',
        row.formaPago || 'No disponible',
        row.importePago || 'No disponible',
      ]);
    }
    

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  async continuarExportarBOREA() {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Listado de Servicios');

    worksheet.getColumn(1).width = 13;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 12;
    worksheet.getColumn(8).width = 12;
    worksheet.getColumn(9).width = 12;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 25;
    worksheet.getColumn(12).width = 22;
    worksheet.getColumn(13).width = 22;
    worksheet.getColumn(14).width = 25;
    worksheet.getColumn(15).width = 25;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 25;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 18;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 22;
    worksheet.getColumn(23).width = 22;
    worksheet.getColumn(24).width = 22;
    worksheet.getColumn(25).width = 60;
    worksheet.getColumn(26).width = 30;
    worksheet.getColumn(27).width = 15;
    worksheet.getColumn(28).width = 15;
    worksheet.getColumn(29).width = 15;
    worksheet.getColumn(30).width = 15;
    worksheet.getColumn(31).width = 15;
    worksheet.getColumn(32).width = 15;
    worksheet.getColumn(33).width = 40;
    worksheet.getColumn(34).width = 20;
    worksheet.getColumn(35).width = 50;
    worksheet.getColumn(36).width = 20;
    worksheet.getColumn(37).width = 20;
    worksheet.getColumn(38).width = 20;
    worksheet.getColumn(39).width = 20;


    let logo = workbook.addImage({
      base64: logoBorea.logoBase64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, 'A2:C4');

    const cell = worksheet.getCell('E2');
    cell.value = 'Informe - Servicios BOREA';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('E4').value = 'Fecha Informe: ';
    worksheet.getCell('E4').font = { bold: true };
    worksheet.getCell('F4').value = moment().format("DD/MM/YYYY HH:mm");

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    let titleRow = worksheet.addRow(['Referencia', 'Fecha', 'Aceptado', 'Ganado', 'Cierre', 'Estado', 'Origen', 'Tipo Serv.', 'Presupuesto', 'Expediente','Nombre Cont.','DNI Cont.','Telefono Cont.','Mail Cont.',
    'Localidad Cont.','Provincia Cont.', 'Direcc. Cont.', 'Nombre Fall.', 'DNI Fall.', 'Fecha Fall.', 'Lugar Fall.', 'Localidad Fall.', 'Provincia Fall.', 'Direcc. Fall.', 
    'Observaciones', 'Motivo Perdido', 'Serv.Traslado', 'Serv.Velatorio', 'Serv.Flores', 'Serv.Repatriación', 'Serv.Despedida', 'Judicial', 'Servicio prestado', 'Creador', 'URL', 'Localidad IP', 'Provincia IP', 'Forma Pago', 'Importe Final']);
    // Set font, size and style in title row.
    titleRow.font = { bold: true };
    for(let row of this.filteredRows) {

      let estado = "";
      if(row.estado == "PENDIENTE") estado = "Nuevo Lead"
      else if(row.estado == "RECHAZADA") estado = "Serv.Perdido"
      else if(row.estado == "PRESUPUESTAR") estado = "Pend.Presupuestar"
      else if(row.estado == "LLAMADA") estado = "Llamada Cliente"
      else if(row.estado == "ACEPTADA") estado = "Presup.Firmado"
      else if(row.estado == "ASIGNADA") estado = "Serv. En Curso"
      else if(row.estado == "COMPLETADA") estado = "Serv.Realizado";

      let source = "";
      if(row.source == "LLAMADA") source = "Llamada"
      else if(row.source == "SOL_LLAMADA") source = "Sol.Llamada"
      else if(row.source == "WHATSAPP") source = "Whatsapp"
      else if(row.source == "FORMULARIO") source = "Formulario"
      else if(row.source == "CHAT") source = "Chat"
      else source = "No disponible";

      let motivo = "";
      if(row.razonPerdido) motivo = this.translate.instant('PETS.RAZONES.' + row.razonPerdido);

      let presupuestos = await this.solicitudService.getByB2BSolicitud(row.id);

      let localidadIP, provIP = null;
      if(row.ipLoc) {
        let locIP = row.ipLoc.split('.');
        localidadIP = locIP[0];
        provIP = locIP[2].replace(' ', '');
      }

      let fechaAcep = null;
      if(row.fechaAceptado) fechaAcep = moment(row.fechaAceptado).format("DD/MM/YYYY HH:mm");
      let fechaGan = null;
      if(row.fechaGanado) fechaGan = moment(row.fechaGanado).format("DD/MM/YYYY HH:mm");
      let fechaCie = null;
      if(row.fechaCierre) fechaCie = moment(row.fechaCierre).format("DD/MM/YYYY HH:mm");

      worksheet.addRow([
        row.referencia, 
        row.fechaStr, 
        fechaAcep, 
        fechaGan, 
        fechaCie, 
        estado || 'No disponible', 
        source || 'No disponible',
        row.tipo,
        presupuestos && presupuestos.length > 0 ? "SI" : "NO",
        row.expediente || 'No disponible', 
        (row.contactoNombre || 'No disponible') + ' ' + (row.contactoApellido1 || '') + ' ' + (row.contactoApellido2 || ''),
        row.contactoDNI || 'No disponible',
        row.contactoTelefono || 'No disponible',
        row.contactoMail || 'No disponible',
        row.contactoLocalidad || 'No disponible',
        row.contactoProvincia || 'No disponible',
        (row.contactoCalle || 'No disponible') + ' ' + (row.contactoNumero || '') + ' ' + (row.contactoPuerta || ''),
        (row.fallecidoNombre || 'No disponible') + ' ' + (row.fallecidoApellido1 || '') + ' ' + (row.fallecidoApellido2 || ''),
        row.fallecidoDNI || 'No disponible',
        row.fallecidoFechaStr || 'No disponible',
        row.fallecidoTipoLugar || 'No disponible',
        row.fallecidoLocalidad || 'No disponible',
        row.fallecidoProvincia || 'No disponible',
        (row.fallecidoCalle || 'No disponible') + ' ' + (row.fallecidoNumero || '') + ' ' + (row.fallecidoPuerta || ''),
        row.observaciones || 'No disponible',
        motivo || 'No disponible',
        row.serviciosAdd && row.traslado ? "SI" : "NO",
        row.serviciosAdd && row.serviciosAdd.velatorio ? "SI" : "NO",
        row.serviciosAdd && row.serviciosAdd.flores ? "SI" : "NO",
        row.serviciosAdd && row.serviciosAdd.repatriacion ? "SI" : "NO",
        row.serviciosAdd && row.serviciosAdd.despedida ? "SI" : "NO",
        row.serviciosAdd && row.serviciosAdd.judicial ? "SI" : "NO",
        row.servicioPrestado || 'No disponible',
        row.creador || 'No disponible',
        row.url || 'No disponible',
        localidadIP || 'No disponible',
        provIP || 'No disponible',
        row.formaPago || 'No disponible',
        row.importePago || 'No disponible',
      ]);
    }
    

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Informe-Servicios-' + '_' + moment().format("DDMMYYYYHHmmss") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExportar = false;
      this.cdr.detectChanges();
    });
  }

  async viewPresupuesto(row: any) {
    if(row.loading) return;
    row.loading = true;
    this.cdr.detectChanges();
    let pres = await this.solicitudService.getFicheroPresupuesto(row.presupuesto);

    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(pres.file) + "'></iframe>"
    )
    row.loading = false;
    this.cdr.detectChanges();
  }

  async crearPago(row: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['admin-pago-nuevo/' + row.presupuesto]);
    });
  }

  async marcarFirmado(row: any) {
    if(row.loading) return;
    row.loading = true;
    this.cdr.detectChanges();

    for await(let pres of this.currentRow.presupuestos) {
      await this.solicitudService.updateSolicitud({id: pres.id, firmaEstado: null});
    }

    await this.solicitudService.updateSolicitud({id: row.id, firmaEstado: "FIRMADO"});

    this.currentRow.importePago = row.importe;

    if(this.currentRow.origen == "BOREA") {
      if(row.traslado) this.currentRow.traslado = true
      else this.currentRow.traslado = false;

      this.currentRow.tipo = row.tipo;

      if(row.velatorio) this.currentRow.serviciosAdd.velatorio = true
      else this.currentRow.serviciosAdd.velatorio = false;
      if(row.flores) this.currentRow.serviciosAdd.flores = true
      else this.currentRow.serviciosAdd.flores = false;
    }


    if(row.tanatorioId) {
      for(let tanat of this.tanatorios) {
        if(tanat.id == row.tanatorioId) {
          this.currentRow.velacionLugar = tanat.nombre;
          this.currentRow.velacionLocalidad = tanat.poblacion;
          this.currentRow.velacionCP = tanat.cp;
          this.currentRow.tanatorio = tanat.id;
          break;
        }
      }
    }

    let solUpdate = {
      id: this.currentRow.id,
      velacionLugar: this.currentRow.velacionLugar,
      velacionLocalidad: this.currentRow.velacionLocalidad,
      velacionCP: this.currentRow.velacionCP,
      tanatorio: this.currentRow.tanatorio,
    } as any;

    if(row.crematorioId) {
      for(let crema of this.crematorios) {
        if(crema.id == row.crematorioId) {
          this.currentRow.sepelioLugar = crema.nombre;
          this.currentRow.sepelioLocalidad = crema.poblacion;
          this.currentRow.sepelioCP = crema.cp;
          this.currentRow.crematorio = crema.id;

          solUpdate.sepelioLugar = this.currentRow.sepelioLugar;
          solUpdate.sepelioLocalidad = this.currentRow.sepelioLocalidad;
          solUpdate.sepelioCP = this.currentRow.sepelioCP;
          solUpdate.crematorio = this.currentRow.crematorio;
          break;
        }
      }
    }
    let res = await this.b2bService.updateSolicitud(solUpdate);

    this.detallesChange();
    
    row.firmaEstado = "FIRMADO";

    row.loading = false;
    this.cdr.detectChanges();
  }

  nuevoPresupuesto(row: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      if(row.origen == 'PETS') {
        this.router.navigate(['admin-pets-presupuesto/' + row.id]);
      } else if(row.origen == 'BOREA' || (row.proveedor && row.proveedor == "BOREA")) {
        this.router.navigate(['admin-wfp-presupuesto/' + row.id]);
      } else {
        this.router.navigate(['admin-b2b-presupuesto/' + row.id]);
      }
    });
  }

  cambioVistaDetalleMovil(vista: number) {
    this.vistaDetalleMovil = vista;
    this.cdr.detectChanges();
  }

  async borrarServicio() {
    //this.loadingAceptar = true;
    this.currentRow.estado = "BORRAR";   
    this.cdr.detectChanges();

    let solUpdate = {
      id: this.currentRow.id,
      estado: "BORRAR"
    } as any;

    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;

    //this.loadingAceptar = false;
    
    this.cdr.detectChanges();

  }

  async ocultarServicioB2B(estado: any) {
    this.cdr.detectChanges();
    this.currentRow.oculto = estado;
    this.cdr.detectChanges();

    let solUpdate = {
      id: this.currentRow.id,
      oculto: estado
    }

    let resUpdate = await this.b2bService.updateSolicitud(solUpdate);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;
    
    this.cdr.detectChanges();
    this.filterDatatableB2B();
  }

  negociosFusionar = [];

  async fusionar() {
    this.negociosFusionar = [];
    for(let row of this.filteredRows) {
      if(row.id != this.currentRow.id) this.negociosFusionar.push(row);
    }
    window.scrollTo(0,3);
    this.modalService.open(this.modalNegocio, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
  }

  objFusionar = null;
  loadingFusion = false;

  async fusionarServicio(row: any) {
    this.objFusionar = row;
    this.questionFusionarSwal.fire();
  }

  async fusionarServicio1() {
    this.modalService.dismissAll();
    this.loadingFusion = true;
    this.cdr.detectChanges();

    // Fusionar comentarios

    for await(let com of this.currentRow.comentarios) {
      await this.b2bService.updateComentario({
        id: com.id,
        solicitud: this.objFusionar.id
      })
    }

    // Fusionar documentos

    for await(let doc of this.currentRow.documentos) {
      await this.b2bService.updateDocumento({
        id: doc.id,
        solicitud: this.objFusionar.id
      })
    }

    // Fusionar presupuestos

    for await(let pres of this.currentRow.presupuestos) {
      await this.solicitudService.updateSolicitud({
        id: pres.id,
        b2bSolicitud: this.objFusionar.id
      })
    }

    // Fusionar información

    let objUpdate = { id: this.objFusionar.id } as any;

    if(!this.objFusionar.expediente) objUpdate.expediente = this.currentRow.expediente;
    if(!this.objFusionar.poliza) objUpdate.poliza = this.currentRow.poliza;
    if(!this.objFusionar.contactoNombre) objUpdate.contactoNombre = this.currentRow.contactoNombre;
    if(!this.objFusionar.contactoApellido1) objUpdate.contactoApellido1 = this.currentRow.contactoApellido1;
    if(!this.objFusionar.contactoApellido2) objUpdate.contactoApellido2 = this.currentRow.contactoApellido2;
    if(!this.objFusionar.petsDNIProp) objUpdate.petsDNIProp = this.currentRow.petsDNIProp;
    if(!this.objFusionar.contactoTelefono) objUpdate.contactoTelefono = this.currentRow.contactoTelefono;
    if(!this.objFusionar.contactoMail) objUpdate.contactoMail = this.currentRow.contactoMail;
    if(!this.objFusionar.contactoCalle) objUpdate.contactoCalle = this.currentRow.contactoCalle;
    if(!this.objFusionar.contactoLocalidad) objUpdate.contactoLocalidad = this.currentRow.contactoLocalidad;
    if(!this.objFusionar.contactoProvincia) objUpdate.contactoProvincia = this.currentRow.contactoProvincia;
    if(!this.objFusionar.contactoNumero) objUpdate.contactoNumero = this.currentRow.contactoNumero;
    if(!this.objFusionar.contactoPuerta) objUpdate.contactoPuerta = this.currentRow.contactoPuerta;
    if(!this.objFusionar.contactoCP) objUpdate.contactoCP = this.currentRow.contactoCP;
    if(!this.objFusionar.fallecidoNombre) objUpdate.fallecidoNombre = this.currentRow.fallecidoNombre;
    if(!this.objFusionar.fallecidoApellido1) objUpdate.fallecidoApellido1 = this.currentRow.fallecidoApellido1;
    if(!this.objFusionar.fallecidoApellido2) objUpdate.fallecidoApellido2 = this.currentRow.fallecidoApellido2;
    if(!this.objFusionar.fallecidoDNI) objUpdate.fallecidoDNI = this.currentRow.fallecidoDNI;
    if(!this.objFusionar.fallecidoFecha) objUpdate.fallecidoFecha = this.currentRow.fallecidoFecha;
    if(!this.objFusionar.fallecidoTipoLugar) objUpdate.fallecidoTipoLugar = this.currentRow.fallecidoTipoLugar;
    if(!this.objFusionar.fallecidoCalle) objUpdate.fallecidoCalle = this.currentRow.fallecidoCalle;
    if(!this.objFusionar.fallecidoLocalidad) objUpdate.fallecidoLocalidad = this.currentRow.fallecidoLocalidad;
    if(!this.objFusionar.fallecidoProvincia) objUpdate.fallecidoProvincia = this.currentRow.fallecidoProvincia;
    if(!this.objFusionar.fallecidoNumero) objUpdate.fallecidoNumero = this.currentRow.fallecidoNumero;
    if(!this.objFusionar.fallecidoPuerta) objUpdate.fallecidoPuerta = this.currentRow.fallecidoPuerta;
    if(!this.objFusionar.fallecidoCP) objUpdate.fallecidoCP = this.currentRow.fallecidoCP;
    if(!this.objFusionar.petsPeso) objUpdate.petsPeso = this.currentRow.petsPeso;
    if(!this.objFusionar.petsTipoAnimal) objUpdate.petsTipoAnimal = this.currentRow.petsTipoAnimal;
    if(!this.objFusionar.observaciones) objUpdate.observaciones = this.currentRow.observaciones;

    await this.b2bService.updateSolicitud(objUpdate);
    
    // Marcar como fusionado y finalizar

    let objUpdate2 = {
      id: this.currentRow.id,
      fusion: this.objFusionar.id
    }

    let resUpdate = await this.b2bService.updateSolicitud(objUpdate2);
    this.currentRow.updatedAtStr = moment(resUpdate.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = resUpdate.updatedAt;

    this.loadingFusion = false;
    this.cdr.detectChanges();

    this.loadLicitacionesB2B();


  }

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (!document.hidden && this.modoPlataforma == "WFN-AD" && !this.currentRow && !this.loadingInit) {
      this.loadLicitacionesB2B();
    }
  }

  // ------- MODAL CENTROS BOREA -------

  tipoModal = "";
  filtersCentros = {nombre: "", domicilio: "", poblacion: "", cp: "", albia: false, provincia: null};
  centros = [];
  allCentros = [];
  tanatorios = [];
  crematorios = [];
  funerarias = [];



  async loadCentros() {
    this.cdr.detectChanges();
    this.tanatorios = [];
    this.crematorios = [];
    this.funerarias = [];
    let centros = await this.centroService.getAllActivos();

    for(let centro of centros) {
      if(centro.tanatorio) {
        this.tanatorios.push(centro);
      }
      if(centro.crematorio) {
        this.crematorios.push(centro);
      }
      if(centro.funeraria) {
        this.funerarias.push(centro);
      }
    }
    
    this.cdr.detectChanges();
  }

  openDialog(tipo) {
    window.scrollTo(0,1);
    this.modalService.open(this.modalCentro, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
    this.tipoModal = tipo;
    this.resetFiltersCentros();
    if(this.currentRow.fallecidoCP) {
      this.filtersCentros.provincia = this.currentRow.fallecidoCP.substring(0, 2);
    } else if(this.currentRow.fallecidoProvincia) {
      for(let prov of this.provincias) {
        if(prov.nombre == this.currentRow.fallecidoProvincia) this.filtersCentros.provincia = prov.id;
      }
    }
    this.filterDatatableCentros();
  }

  selectFuneraria() {
    this.allCentros = this.funerarias;
    this.filterDatatableCentros();
    this.openDialog("funeraria");
  }

  selectTanatorio() {
    this.allCentros = this.tanatorios;
    this.filterDatatableCentros();
    this.openDialog("tanatorio");
  }

  selectCrematorio() {
    this.allCentros = this.crematorios;
    this.filterDatatableCentros();
    this.openDialog("crematorio");
  }

  async selectCentro(row: any) {
    if(this.tipoModal == "tanatorio") {
      this.currentRow.velacionLugar = row.nombre;
      this.currentRow.velacionLocalidad = row.poblacion;
      this.currentRow.velacionCP = row.cp;
      this.currentRow.tanatorio = row.id;

      let solUpdate = {
        id: this.currentRow.id,
        velacionLugar: this.currentRow.velacionLugar,
        velacionLocalidad: this.currentRow.velacionLocalidad,
        velacionCP: this.currentRow.velacionCP,
        tanatorio: this.currentRow.tanatorio,
      } as any;


      if(this.currentRow.tipo == 'CREMACION'){
        if(row.crematorio) {
          this.currentRow.sepelioLugar = row.nombre;
          this.currentRow.sepelioLocalidad = row.poblacion;
          this.currentRow.sepelioCP = row.cp;
          this.currentRow.crematorio = row.id;
        } else if(row.centroRelacionado.crematorio) {
          this.currentRow.sepelioLugar = row.centroRelacionado.nombre;
          this.currentRow.sepelioLocalidad = row.centroRelacionado.poblacion;
          this.currentRow.sepelioCP = row.centroRelacionado.cp;
          this.currentRow.crematorio = row.centroRelacionado.id;
        }

        solUpdate.sepelioLugar = this.currentRow.sepelioLugar;
        solUpdate.sepelioLocalidad = this.currentRow.sepelioLocalidad;
        solUpdate.sepelioCP = this.currentRow.sepelioCP;
        solUpdate.crematorio = this.currentRow.crematorio;
      }

      let res = await this.b2bService.updateSolicitud(solUpdate);

      this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = res.updatedAt;
    } else if(this.tipoModal == "crematorio") {  // crematorio
      this.currentRow.sepelioLugar = row.nombre;
      this.currentRow.sepelioLocalidad = row.poblacion;
      this.currentRow.sepelioCP = row.cp;
      this.currentRow.crematorio = row.id;

      let solUpdate = {
        id: this.currentRow.id,
        sepelioLugar: this.currentRow.sepelioLugar,
        sepelioLocalidad: this.currentRow.sepelioLocalidad,
        sepelioCP: this.currentRow.sepelioCP,
        crematorio: this.currentRow.crematorio,
      } as any;

      let res = await this.b2bService.updateSolicitud(solUpdate);

      this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = res.updatedAt;
    } else if(this.tipoModal == "funeraria") {  // crematorio
      this.currentRow.funerariaLugar = row.nombre;
      this.currentRow.funerariaLocalidad = row.poblacion;
      this.currentRow.funerariaCP = row.cp;
      this.currentRow.funeraria = row.id;

      let solUpdate = {
        id: this.currentRow.id,
        funerariaLugar: this.currentRow.funerariaLugar,
        funerariaLocalidad: this.currentRow.funerariaLocalidad,
        funerariaCP: this.currentRow.funerariaCP,
        funeraria: this.currentRow.funeraria,
      } as any;

      let res = await this.b2bService.updateSolicitud(solUpdate);

      this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
      this.currentRow.updatedAt = res.updatedAt;
    }
    this.modalService.dismissAll();
  }

  async deleteTanatorio() {
    this.currentRow.velacionLugar = null;
    this.currentRow.velacionLocalidad = null;
    this.currentRow.velacionProvincia = null;
    this.currentRow.velacionCP = null;
    this.currentRow.tanatorio = null;

    let solUpdate = {
      id: this.currentRow.id,
      velacionLugar: this.currentRow.velacionLugar,
      velacionLocalidad: this.currentRow.velacionLocalidad,
      velacionProvincia: this.currentRow.velacionProvincia,
      velacionCP: this.currentRow.velacionCP,
      tanatorio: this.currentRow.tanatorio,
    }

    let res = await this.b2bService.updateSolicitud(solUpdate);

    this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = res.updatedAt;
  }

  async deleteCrematorio() {
    this.currentRow.sepelioLugar = null;
    this.currentRow.sepelioLocalidad = null;
    this.currentRow.sepelioProvincia = null;
    this.currentRow.sepelioCP = null;
    this.currentRow.crematorio = null;

    let solUpdate = {
      id: this.currentRow.id,
      sepelioLugar: this.currentRow.sepelioLugar,
      sepelioLocalidad: this.currentRow.sepelioLocalidad,
      sepelioProvincia: this.currentRow.sepelioProvincia,
      sepelioCP: this.currentRow.sepelioCP,
      crematorio: this.currentRow.crematorio,
    }

    let res = await this.b2bService.updateSolicitud(solUpdate);

    this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = res.updatedAt;
  }

  async deleteFuneraria() {
    this.currentRow.funerariaLugar = null;
    this.currentRow.funerariaLocalidad = null;
    this.currentRow.funerariaCP = null;
    this.currentRow.crematorio = null;

    let solUpdate = {
      id: this.currentRow.id,
      funerariaLugar: this.currentRow.funerariaLugar,
      funerariaLocalidad: this.currentRow.funerariaLocalidad,
      funerariaCP: this.currentRow.funerariaCP,
      funeraria: this.currentRow.funeraria,
    }

    let res = await this.b2bService.updateSolicitud(solUpdate);

    this.currentRow.updatedAtStr = moment(res.updatedAt).format("DD/MM/YYYY HH:mm");
    this.currentRow.updatedAt = res.updatedAt;
  }

  filterDatatableCentros(){
    this.centros = this.allCentros.filter(function(item){
      var mostrar = true;

      if(this.filtersCentros.nombre && this.filtersCentros.nombre != null && this.filtersCentros.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filtersCentros.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filtersCentros.domicilio && this.filtersCentros.domicilio != null && this.filtersCentros.domicilio != "") {
        if(!item.domicilio.toLowerCase().includes(this.filtersCentros.domicilio.toLowerCase())) mostrar = false;
      }

      if(this.filtersCentros.poblacion && this.filtersCentros.poblacion != null && this.filtersCentros.poblacion != "") {
        if(!item.poblacion.toLowerCase().includes(this.filtersCentros.poblacion.toLowerCase())) mostrar = false;
      }

      if(this.filtersCentros.cp && this.filtersCentros.cp != null && this.filtersCentros.cp != "") {
        if(!(item.cp + "").toLowerCase().includes(this.filtersCentros.cp.toLowerCase())) mostrar = false;
      }

      if(this.filtersCentros.albia) {
        if(!item.albia || item.grupo) mostrar = false;
      }

      if(this.filtersCentros.provincia) {
        let cpIni = parseInt(this.filtersCentros.provincia) * 1000;
        let cpFin = (parseInt(this.filtersCentros.provincia) + 1) * 1000 - 1;

        if(parseInt(item.cp) < cpIni || parseInt(item.cp) > cpFin) mostrar = false;
      }

      if(this.tipoModal == "crematorio" && this.tanatorio && this.tanatorio.grupo && item.grupo && item.grupo != this.tanatorio.grupo) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
    this.cdr.detectChanges();
  }

  resetFiltersCentros() {
    this.filtersCentros = {nombre: "", domicilio: "", poblacion: "", cp: "", albia: false, provincia: null};
    this.filterDatatableCentros();
  }

  // ---------- EXPORTAR SAP

  async exportarSAP() {
    if(this.currentRow.loadingSAP) return;
    this.cdr.detectChanges();
    let requeridos = "";
    let row = this.currentRow;

    if(!row.tanatorio && !row.crematorio) requeridos += "Proveedor del servicio<br>";
    if(!row.servicioPrestado) requeridos += "Servicio prestado<br>";
    if(!row.fallecidoFecha) requeridos += "Fecha fallecimiento<br>";
    if(!row.tipoSiniestro) requeridos += "Tipo siniestro<br>";
    if(!row.causaSiniestro) requeridos += "Causa siniestro<br>";

    if(!row.fallecidoNombre) requeridos += "Nombre fallecido<br>";
    if(!row.fallecidoApellido1) requeridos += "Apellido1 fallecido<br>";
    if(!row.fallecidoDNI) requeridos += "DNI/NIE/Pas. fallecido<br>";
    if(!row.contactoNombre) requeridos += "Nombre contacto<br>";
    if(!row.contactoApellido1) requeridos += "Apellido1 contacto<br>";
    if(!row.contactoDNI) requeridos += "DNI/NIE/Pas. contacto<br>";

    if(requeridos != "") {
      this.errorSAPSwal.html = requeridos;
      this.errorSAPSwal.fire();
      return;
    }
    this.currentRow.loadingSAP = true;

    let res = await this.b2bService.postSAP({solicitudId: row.id, proveedor: row.tanatorio || row.crematorio});

    console.warn(res);

    if(res.resultado == "OK") {
      this.currentRow.estadoSAP = 1;
    } else {
      this.errorSAPSwal.title = "Error en la exportación";
      let errors = "";
      for(let err of res.errores) {
        errors += err + "<br>";
      }
      this.errorSAPSwal.html = errors;
      this.errorSAPSwal.fire();
    }

    let res2 = await this.b2bService.postSAP({solicitudId: row.id, proveedor: row.funeraria});
    console.warn(res2);

    this.currentRow.loadingSAP = false;   
    this.cdr.detectChanges(); 
  }

}
