import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import {AuthenticationService, UserService } from '../../../_services';
import * as moment from 'moment';
import * as introJs from 'intro.js/intro.js';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../../_common/global-variables';

/**
 * Componente que gestiona la página inicial de la plataforma.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /** Variables globales */
  currentUser: any;
  pagosPendientes = false;
  
  introJS = introJs();

  modoPlataforma = null;

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private ngZone: NgZone,
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) { 
      this.introJS.setOptions({
        nextLabel: "Continuar",
        prevLabel: "Volver",
        skipLabel: "Salir",
        doneLabel: "Finalizar",
        hintButtonLabel: "OK",
        exitOnEsc: false,
        exitOnOverlayClick: false,
        showStepNumbers: false,
        showBullets: false,
        hidePrev: true,
        hideNext: false,
        scrollToElement: true
      });
    }


  /**
   * Método que se lanza en el inicio del componente, carga el usuario y comprueba si hay pagos pendientes
   */
  ngOnInit() {
    console.warn("INIT!!!!!");
    this.currentUser = this.authenticationService.currentUserValue;
    var token = this.currentUser.token;
    var demoCompletada = false;
    if(this.currentUser.demoCompletada) demoCompletada = true;

    this.userService.getById(this.currentUser.id)
    .subscribe(user => {
      this.currentUser = user;
      this.currentUser.token = token;
      this.currentUser.demoCompletada = demoCompletada;
      this.cdr.detectChanges();
      this.authenticationService.changeUser(this.currentUser);

      if(this.currentUser.proveedor && this.currentUser.suscripciones.length > 0) {
        var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
        if(!suscrip.pagado) {
          this.pagosPendientes = true;
          this.cdr.detectChanges();
        }
      }

      this.modoPlataforma = GlobalVariables.modoPlataforma;
      this.cdr.detectChanges();

    });

  }

  /**
   * Cierra la sesión del usuario y le lleva al registro.
   */  
  contratar() {
    this.authenticationService.contratar();
  }
}
