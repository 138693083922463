// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
//import { AuthGuard } from './core/auth';
import { AuthGuard, StartGuard } from './_helpers';
//WFN Imports
import { HomeComponent } from './views/pages/home/home.component';
import { StartComponent } from './views/pages/start/start.component';
import { LicitacionesComponent } from './views/pages/licitaciones/licitaciones.component';
import { LicitacionNuevaComponent } from './views/pages/licitacion-nueva/licitacion-nueva.component';
import { LicitacionEditarComponent } from './views/pages/licitacion-editar/licitacion-editar.component';
import { SuscripcionComponent } from './views/pages/suscripcion/suscripcion.component';
import { ConfirmacionComponent } from './views/pages/confirmacion/confirmacion.component';
import { PagoComponent } from './views/pages/pago/pago.component';
import { AdminUsuariosComponent } from './views/pages/admin-usuarios/admin-usuarios.component';
import { AdminInteractivoComponent } from './views/pages/admin-interactivo/admin-interactivo.component';
import { AdminEstatisticasComponent } from './views/pages/admin-estatisticas/admin-estatisticas.component';
import { AdminSoporteComponent } from './views/pages/admin-soporte/admin-soporte.component';
import { AdminWfpComponent } from './views/pages/admin-wfp/admin-wfp.component';
import { AdminEstadisticasWfpComponent } from './views/pages/admin-estadisticas-wfp/admin-estadisticas-wfp.component';
import { AdminBlogWfpComponent } from './views/pages/admin-blog-wfp/admin-blog-wfp.component';
import { AdminWfpPresupuestoComponent } from './views/pages/admin-wfp-presupuesto/admin-wfp-presupuesto.component';
import { ServiciosNuevoComponent } from './views/pages/servicios-nuevo/servicios-nuevo.component';
import { ServiciosNuevoPetsComponent } from './views/pages/servicios-nuevo-pets/servicios-nuevo-pets.component';
import { AdminB2bClientesComponent } from './views/pages/admin-b2b-clientes/admin-b2b-clientes.component';
import { AdminB2bProveedoresComponent } from './views/pages/admin-b2b-proveedores/admin-b2b-proveedores.component';
import { AdminPetsPresupuestoComponent } from './views/pages/admin-pets-presupuesto/admin-pets-presupuesto.component';
import { AdminPetsCementerioComponent } from './views/pages/admin-pets-cementerio/admin-pets-cementerio.component';
import { AdminPagoNuevoComponent } from './views/pages/admin-pago-nuevo/admin-pago-nuevo.component';
import { AdminPagoListadoComponent } from './views/pages/admin-pago-listado/admin-pago-listado.component';
import { AdminB2bPresupuestoComponent } from './views/pages/admin-b2b-presupuesto/admin-b2b-presupuesto.component';
import { AdminWfpLocalizacionComponent } from './views/pages/admin-wfp-localizacion/admin-wfp-localizacion.component';
import { AdminPetsLocalizacionComponent } from './views/pages/admin-pets-localizacion/admin-pets-localizacion.component';
import { AdminB2bLocalizacionComponent } from './views/pages/admin-b2b-localizacion/admin-b2b-localizacion.component';
import { AdminInformeMensualComponent } from './views/pages/admin-informe-mensual/admin-informe-mensual.component';

/**
 * Fichero de enrutado de la plataforma.
 * Se define el componente asociado, los parámetros (:tarifa) en caso de haberlos 
 * y las clases auxiliares que pueden activar (comprobar autenticación, estado usuario, etc.).
 * @author Informática Integral Vasca
 */
const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{ 
				path: 'start', 
				component: StartComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'start/:tarifa', 
				component: StartComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'home', 
				component: HomeComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'crear', 
				component:  LicitacionNuevaComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'crear-traslado-int', 
				component:  LicitacionNuevaComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'crear-servicio', 
				component:  ServiciosNuevoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'crear-servicio-pets', 
				component: ServiciosNuevoPetsComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'editar/:id', 
				component: LicitacionEditarComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'licitaciones', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'licitaciones/:modo', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'mislicitaciones', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'mislicitaciones/:modo', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'pendientes', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'trazabilidad', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'trazabilidad/:modo', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil/user/:user', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil/user/:user/:tab', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil/:tab', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'confirmacion', 
				component: ConfirmacionComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'pago', 
				component: PagoComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'pago/:estado', 
				component: PagoComponent,
				canActivate: [AuthGuard]
			},
			// -- ADMIN --
			{ 
				path: 'admin-licitaciones', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-usuarios', 
				component: AdminUsuariosComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-interactivo', 
				component: AdminInteractivoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-estadisticas', 
				component: AdminEstatisticasComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-estadisticas-wfp', 
				component: AdminEstadisticasWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-soporte', 
				component: AdminSoporteComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-soporte/nuevo/:usuario', 
				component: AdminSoporteComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp', 
				component: AdminWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-blog-wfp', 
				component: AdminBlogWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp-presupuesto', 
				component: AdminWfpPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp-presupuesto/:id', 
				component: AdminWfpPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-b2b-clientes', 
				component: AdminB2bClientesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-b2b-proveedores', 
				component: AdminB2bProveedoresComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pets-presupuesto', 
				component: AdminPetsPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pets-presupuesto/:id', 
				component: AdminPetsPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pets-cementerio', 
				component: AdminPetsCementerioComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-licitaciones-pets', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-licitaciones-fp', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-licitaciones-borea', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pago-nuevo', 
				component: AdminPagoNuevoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pago-nuevo/:presupuesto', 
				component: AdminPagoNuevoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pago-listado', 
				component: AdminPagoListadoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-b2b-presupuesto', 
				component: AdminB2bPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-b2b-presupuesto/:id', 
				component: AdminB2bPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp-localizacion', 
				component: AdminWfpLocalizacionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-pets-localizacion', 
				component: AdminPetsLocalizacionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-b2b-localizacion', 
				component: AdminB2bLocalizacionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-informe-mensual', 
				component: AdminInformeMensualComponent,
				canActivate: [AuthGuard, StartGuard]
			},

			// --------
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
