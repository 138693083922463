import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService } from '../../../../_services';

declare var Stripe: any;

@Component({
  selector: 'kt-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.scss']
})
export class ValidarComponent implements OnInit {

  //stripe;

  constructor(private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef) { 
    }

  hash = null;
  email = null;
  correcto = true;
  validada = false;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params["hash"];
      this.email = params["email"];

      var user = {hash: this.hash, email: this.email};

      this.userService.validar(user)
        .pipe(first())
        .subscribe(
          data => {
            this.validada = true;
            this.cdr.detectChanges();

            localStorage.setItem('currentUser', JSON.stringify(data));
            this.authenticationService.currentUserSubject.next(data);

            self.io.sails.headers = {
              'Authorization': `Bearer ${data["token"]}`
            };
            self.io.socket = self.io.sails.connect();

            setTimeout(()=>{       
              if(data['tarifaTemp']) {
                this.router.navigate(['/start/' + data['tarifaTemp']]);
              } else {
                //this.router.navigate(['/home']);
                this.router.navigate(['/start']);
              }
            }, 2000);

          },
          error => {
            console.error(error);
            this.correcto = false;
            this.cdr.detectChanges();
        });

    })
    
  }

}
